import React from 'react';
//Components
import { Button, Row, Skeleton } from "antd";
import { SimpleEmpty } from "@cardoai/components";
import DetailsFooter from "./footer";
//Icons
import { FolderViewOutlined } from "@ant-design/icons";
//Helpers
import { formatLimitResponse } from "../../../../../../../../../spv/components/management/notes_section/limits";
import { Analytics, downloadClientFile, generateUrl, useQuery } from "../../../../../../../../../../helpers";
import { useAuthorization } from "../../../../../../../../../../utils/hooks"
//Constants
import { actionKey } from "../../../../../../../../../../constants";
import viewConfig from "../../../../../../../../../../constants/config";


interface IDetailsView {
  data?: any,
  vehicleId?: any,
  record?: any,
  status?: any,
  onCancel?: any,
  getData?: any,
  vehicleIdentifier?: any,
  proposalId?: any
  vehicle?: any
  isMCSVehicle?: boolean | undefined
}

const prepareDetailsUrl = (isMCS: boolean | undefined, vehicleId: any, proposalId: any) => {
  return isMCS ? generateUrl(`/mcs/proposal_limits/general_limits`, {
    vehicle_id: vehicleId,
    proposal_id: proposalId
  }) : `/main/notes_manager/${proposalId}/proposal/?vehicle_id=${vehicleId}`;
}

const DetailsView = (props: IDetailsView) => {
  const {isMCSVehicle, vehicleIdentifier, vehicleId, record, status, onCancel, getData, proposalId, vehicle} = props;

  const {data} = useQuery({ url: prepareDetailsUrl(isMCSVehicle, vehicleId, proposalId) });

  const authorization: any = useAuthorization();

  if (!data)
    return <Skeleton/>

  const limits = data.limits;
  const allFiles = data.files;
  const notes = data.notes;

  const hasFiles = allFiles && allFiles.length

  let LimitsContent = <div/>;

  if (!limits || !limits.length)
    LimitsContent = <SimpleEmpty/>

  if (viewConfig.hasOwnProperty(vehicleIdentifier)) {
    const type = "notesManager";
    const finalData = data ? formatLimitResponse(data) : null;
    const {component: Component, ...rest} = viewConfig[vehicleIdentifier].notesManager;
    LimitsContent =
      <Component data={finalData} type={type} collapsible vehicleIdentifier={vehicleIdentifier} proposalId={proposalId}
                 vehicleId={vehicleId} status={status} vehicle={vehicle} record={record} {...rest}/>
  }


  const handleViewFile = () => {
    //Function to download multiple files
    for (let i = 0; i < allFiles.length; i++) {
      const url = allFiles[i].file;
      const fileName = allFiles[i].filename;
      downloadClientFile({
        link: true,
        url: url,
        filename: fileName,
      })
    }

    Analytics.dispatchEvent({
      category: 'User',
      action: 'Clicked `View files` at Loan Purchase Details'
    });
  }

  const canApproveProposal = authorization.canPerformAction(actionKey.approveProposal, vehicleId)
  const canRejectProposal = authorization.canPerformAction(actionKey.rejectProposal, vehicleId)
  const canApproveOrReject = canApproveProposal || canRejectProposal

  return (
    <>
      <div>
        {LimitsContent}
      </div>
      {canApproveOrReject &&
        <DetailsFooter
          vehicleId={vehicleId}
          status={status}
          notes={notes}
          onCancel={onCancel}
          record={record}
          getData={getData}
          canApprove={canApproveProposal}
          canReject={canRejectProposal}
        />}
      <Row justify='end' className="mt16">
        <Button type='primary' disabled={!hasFiles} onClick={handleViewFile}
                icon={<FolderViewOutlined/>}>
          View Files
        </Button>
      </Row>
    </>
  )
}


export default DetailsView;