import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const DocumentsManagement = () => {
  return (
    <>
      <CustomImage
        alt="Document Management Full View"
        src={assets.images.documentsModule.fullView}
        description="Document Management allows Investors to access 24/7 to transaction related documents.
            Investors can select the relevant SPV in which he is subscribed to by selecting from the list at the top."
      />
      <div>
        <DefinitionList
          header="Each transaction’s documentation set is organized in 4 areas:"
          data={[
            {
              term: 'Deal Documents (Contract management)',
              explanation: 'securitisation prospectus, indenture, and other legal documentation'
            },
            {
              term: 'Other Documents',
              explanation: 'other documents relevant for Investors of the transaction'
            },
            {
              term: 'CARDO AI reports',
              explanation: 'transaction reports produced by CARDO AI Securitisation Platform'
            },
            {
              term: 'Other reports',
              explanation: 'transaction reports not generated by CARDO AI Securitisation Platform'
            },
          ]}
        />
      </div>
    </>
  );
};

export default DocumentsManagement;
