import React from 'react';

const GetColorDelayed = ({record}:any) => {

  const daysDelayed = record.days_delayed
  if (daysDelayed <= 5) return <div className="green">{daysDelayed}</div>
  else if ( 15 > daysDelayed ) return <div className="orange">{daysDelayed}</div>
  else if (daysDelayed > 15) return <div className="red" >{daysDelayed}</div>
};

export default GetColorDelayed;