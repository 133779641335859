import React from 'react';
//Components
import {Row, Col} from "antd";

const Segment = ({config, value, maxWidth, onChange,isFar}: any) => {
    return (
        <Row style={{
            height: 36,
            borderRadius: 8,
            maxWidth: maxWidth,
            border: '1px solid #E3DDE6'
        }}>
            {config.map((record: any) => {
                const active = record.key === value;

                const styles: any = {};

                if (active) {
                    styles.color = '#2D72EA';
                    styles.backgroundColor = '#EAF1FD';
                }


                return (
                    <Col xs={isFar ? 8 : 12}
                         onClick={() => {
                             onChange(record.key)
                         }}
                         style={{
                             color: '#736974',
                             borderRadius: 8,
                             fontWeight: 400,
                             display: 'flex',
                             cursor: 'pointer',
                             alignItems: 'center',
                             justifyContent: 'center',
                             transition: 'all .5s',
                             ...styles
                         }}>
                        {record.label}
                    </Col>
                )
            })}
        </Row>
    );
};

export default Segment;