import React from 'react';
//Components
import { Col, Row } from "antd";
import { FlatList } from "@cardoai/components";
//Styles
import Container from "./styles";
//Configuration
import { statisticsFields } from "../management/common/header/statistics/config";


interface Props {
  singleVehicleView: any,
  vehicles: any
}

const Header = (props: Props) => {
  const {singleVehicleView, vehicles} = props;

  let servicerData = statisticsFields().servicer(false);

  return (
    <Container gutter={16} align="middle" justify="space-between" style={{flexWrap: 'nowrap'}}>
      <Col xs={6}>
        <Row align="middle" gutter={8}>
          <Col>
            <h2>{singleVehicleView ? vehicles[0].vehicle_name : 'Servicers'}</h2>
          </Col>
        </Row>
      </Col>
      <Col xs={18}>
        <FlatList
          data={null}
          marginBottom={0}
          elementWidth={125}
          displayValues={false}
          containerClassName='flat-list-vehicle-header'
          config={servicerData}
          childrenContainerClassName='flat-list-vehicle-header'/>
      </Col>
    </Container>
  );
};

export default Header;
