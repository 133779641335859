import React from "react";
//Google Analytics
import { Analytics } from "../../../../../helpers";
//Custom Components
import SyncView from "./sync_view";
import withModal from "../../with_modal";
import SelectSyncType from "./select_sync_type";
//Helpers
import { DEFAULT_MODAL_WIDTH } from "../../configurations";


interface IImportDataTape {
  vehicle?: any,
  vehicleId?: any,
  makeRequest?: any,
  title?: string,
  key?: string,
  handleUnmount?: any,
  handleCloseModal?: any,
  changeModalTitle?: any,
  allowModalClose?: any,
  changeModalWidth?: any,
  setWidthAsContent?: any
}

const ImportDataTape = (props: IImportDataTape) => {

  const {vehicle, vehicleId} = props;

  const [selectedSyncType, setSelectedSyncType] = React.useState(null);

  const onTypeChange = (type: any) => () => {
    setSelectedSyncType(type);
    Analytics.dispatchEvent({
      category: 'User',
      action: 'Selected Sync Type',
      label: type
    });
  }

  return (
    <>
      {!selectedSyncType && <SelectSyncType vehicle={vehicle} vehicleId={vehicleId} onTypeChange={onTypeChange}/>}
      {selectedSyncType && <SyncView {...props} type={selectedSyncType}/>}
    </>
  );
};

export default withModal({
  useButton: true,
  defaultWidth: DEFAULT_MODAL_WIDTH,
  modalTitle: 'Import Files'
})(ImportDataTape);