//Other Libs
import { createGlobalStyle } from "styled-components";
//Constants
import { theme } from "./colors";

export default createGlobalStyle<any>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  html,
  body,
  html a {
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  img {
    -webkit-user-drag: none;
  }

  p {
    margin: 0
  }

  /********** Add Your Global RTL CSS Here **********/
  /* Popover */
  html[dir='rtl'] .ant-popover {
    text-align: right;
  }

  /* Ecommerce Card */
  html[dir='rtl'] .isoCardInfoForm .ant-input {
    text-align: right;
  }

  /* Modal */
  html[dir='rtl'] .has-success.has-feedback:after,
  html[dir='rtl'] .has-warning.has-feedback:after,
  html[dir='rtl'] .has-error.has-feedback:after,
  html[dir='rtl'] .is-validating.has-feedback:after {
    left: 0;
    right: auto;
  }

  html[dir='rtl'] .ant-modal-close {
    right: inherit;
    left: 0;
  }

  html[dir='rtl'] .ant-modal-footer {
    text-align: left;
  }

  html[dir='rtl'] .ant-modal-footer button + button {
    margin-left: 0;
    margin-right: 8px;
  }

  html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
    margin-right: 42px;
  }

  html[dir='rtl'] .ant-btn > .anticon + span,
  html[dir='rtl'] .ant-btn > span + .anticon {
    margin-right: 0.5em;
  }

  html[dir='rtl'] .ant-btn-loading span {
    margin-left: 0;
    margin-right: 0.5em;
  }

  html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
    padding-left: 25px;
    padding-right: 29px;
  }

  html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
    margin-right: -14px;
    margin-left: 0;
  }

  .pointerCursor {
    cursor: pointer !important;
  }

  .disabledCursor {
    cursor: not-allowed !important;
  }

  /* Spacing */

  .mb0 {
    margin-bottom: 0 !important;
  }

  .mb4 {
    margin-bottom: 4px !important;
  }


  .mt4 {
    margin-top: 4px !important;
  }

  .mt8 {
    margin-top: 8px !important;
  }

  .ml4 {
    margin-left: 4px !important;
  }

  .ml8 {
    margin-left: 8px !important;
  }

  .m16 {
    margin-left: 16px !important;
  }

  .mr8 {
    margin-right: 8px !important;
  }

  .mb8 {
    margin-bottom: 8px !important;
  }

  .mb12 {
    margin-bottom: 12px;
  }

  .mt16 {
    margin-top: 16px !important;
  }

  .mb16 {
    margin-bottom: 16px !important;
  }
  .mb24 {
    margin-bottom: 24px !important;
  }
  .mt24 {
    margin-top: 24px !important;
  }

  .mt32 {
    margin-top: 32px !important;
  }

  .mt48 {
    margin-top: 48px !important;
  }

  .ml48 {
    margin-left: 48px !important;
  }

  .mt64 {
    margin-top: 64px !important;
  }

  .p8 {
    padding: 8px !important;
  }

  .p16 {
    padding: 16px !important;
  }

  .pl24 {
    padding-left: 24px !important;
  }

  .ptb-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .p-8 {
    padding: 8px !important;
  }

  .borderTop {
    border-top: 1px solid ${theme.colors.mercury};
  }

  .borderLeft {
    border-left: 1px solid ${theme.colors.mercury};
  }

  .borderRight {
    border-right: 1px solid ${theme.colors.mercury};
  }

  .borderBottom {
    border-bottom: 1px solid ${theme.colors.mercury};
  }

  .noWrap {
    white-space: nowrap !important;
  }

  .flexNoWrap {
    flex-wrap: nowrap;
  }

  .textDark {
    color: rgba(0, 0, 0, 0.75) !important;
  }

  .textWrap {
    white-space: nowrap;
    overflow: hidden;
    min-width: 120px;
    max-width: 200px;
    text-overflow: ellipsis;
  }

  .ant-tooltip {
    z-index: 1000 !important;
  }

  .flexDisplay {
    display: flex;
  }

  .flexCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .rowDirection {
    display: flex;
    flex-direction: row !important;
  }

  .rowDirectionReverse {
    display: flex;
    flex-direction: row-reverse !important;
  }

  .columnDirection {
    display: flex;
    flex-direction: column !important;
  }

  .columnDirectionReverse {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .normal {
    font-weight: normal !important;
    font-style: normal !important;
  }

  .bold {
    font-weight: bold !important;
  }

  .bolder {
    font-weight: 500 !important;
  }

  .lighter {
    font-weight: 300 !important;
  }

  .italic {
    font-style: italic;
  }

  .underlined {
    text-decoration: underline;
  }

  .justified {
    text-align: justify;
  }

  .textLeft {
    text-align: left !important;
  }

  .textCenter {
    text-align: center !important;
  }

  .textRotate {
    writing-mode: vertical-rl !important;
  }

  .sticky {
    top: 0;
    background-color: ${theme.colors.white};
    position: sticky;
  }

  .textRight {
    text-align: right !important;
  }

  .fullWidth {
    width: 100% !important;
  }

  .fullHeight {
    height: 100% !important;
  }

  .pointerCursor {
    cursor: pointer;
  }

  .pointerDisabled {
    pointer-events: none;
  }

  .pointerZoomIn {
    cursor: zoom-in;
  }

  .pointerZoomOut {
    cursor: zoom-out;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap !important;
  }

  table.bordered {
    border-collapse: collapse;
    width: 100%;
  }

  table.bordered th {
    font-weight: 500;
  }

  table.bordered th, table.bordered td {
    padding: 4px;
    text-align: left;
    border-bottom: 1px solid ${theme.palette.grayscale[13]};
  }


  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    /* This is more usable for users trying to click it. */
    background-color: rgba(100, 100, 100, 0.1);
    -webkit-border-radius: 100px;
  }

  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.4); /* Some darker color when you click it */
    -webkit-border-radius: 100px;
  }

  .defaultLineHeight {
    line-height: 1em !important;
  }

  .small {
    font-size: 12px !important;
  }

  .alignLeft {
    text-align: left !important;
  }

  .alignRight {
    text-align: right !important;
  }

  .noMargin {
    margin: 0 !important;
  }

  .noPadding {
    padding: 0 !important;
  }

  .noBorder {
    border: none !important;
  }

  .box {
    border: 1px solid ${theme.colors.greyshade1};
    border-radius: 4px;
    padding: 0 8px;
  }

  .page-title {
    margin-top: 0;
    margin-bottom: 0 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-weight: 500 !important;
  }

  .page-sub-title {
    margin-top: 0 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    margin-bottom: 2px !important;
    font-weight: 500 !important;
  }

  .small {
    font-size: 12px;
  }

  .x-small {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0px;
  }

  .primary {
    color: ${theme.colors.primary} !important;
  }

  .primaryLight {
    color: ${theme.colors.primaryLight} !important;
  }
  .red {
    color:${theme.colors.red} !important;
  }
  .green {
    color:${theme.colors.green} !important;
  }
  .orange {
    color:${theme.colors.orange} !important;
  }
  .whiteText {
    color: ${theme.colors.white} !important;
  }

  .greenText {
    color: ${theme.colors.success} !important;
  }

  .redText {
    color: ${theme.colors.error} !important;
  }

  .warningText {
    color: ${theme.colors.warning} !important;
  }

  .greyText {
    color: ${theme.colors.silver} !important
  }

  .overflow-x {
    overflow-x: auto;
  }

  .size12 {
    font-size: 12px;
  }

  .size16 {
    font-size: 16px !important;
  }

  .smallModal .ant-modal-body {
    padding: 16px !important;
  }

  .smallModal .ant-modal-confirm-content {
    margin-left: 0 !important;
    margin-top: 16px !important;
  }

  .smallModal .ant-modal-confirm-btns {
    margin-top: 16px !important;
  }

  .smallModal-confirm .ant-modal-confirm-btns {
    display: none !important;
  }

  .w100 {
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .w150 {
    max-width: 150px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .w200 {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .verticalMiddle {
    vertical-align: middle !important
  }

  @keyframes cellPlaceholder {
    0% {
      background: ${theme.palette.border[10]};
    }

    30% {
      background: ${theme.palette.border[5]};
    }

    50% {
      background: ${theme.palette.border[10]};
    }

    80% {
      background: ${theme.palette.border[5]};
    }

    100% {
      background: ${theme.palette.border[10]};
    }
  }

  .inlineBlock {
    display: inline-block !important;
  }

  .displayNone {
    display: none !important;
  }

  .ant-collapse-borderless {
    background-color: ${theme.colors.white} !important;
  }

  .floatR {
    float: right;
  }


  @keyframes blinkIn {
    50% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes focusIn {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }

  @keyframes focusInExpand {
    0% {
      letter-spacing: -0.5em;
      -webkit-transform: translateZ(-800px);
      transform: translateZ(-800px);
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes focusOutExpand {
    0% {
      -webkit-filter: blur(0.01);
      filter: blur(0.01);
    }
    100% {
      -webkit-filter: blur(12px) opacity(0%);
      filter: blur(12px) opacity(0%);
    }
  }

  .greyBackground {
    background-color: rgba(0, 0, 0, .05);
  }

  .fw400 {
    font-weight: 400;
  }

  .w70 {
    width: 70px;
  }

  .h50 {
    height: 50px;
  }

  .helperText {
    font-size: 11px;
    font-style: italic;
    color: rgba(0, 0, 0, .5);
  }

  .maxHeight400 {
    max-height: 400px;
    overflow-y: auto;
  }

  .flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-popover-inner-content {
    padding: 12px !important;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 13px !important;
  }

  #nprogress .spinner {
    display: none !important;
  }

  .relativePosition {
    position: relative;
  }


  .site-badge-count-static .ant-badge-count {
    color: ${theme.palette.grayscale[12]};
    background-color: ${theme.colors.white};
    box-shadow: 0 0 0 1px ${theme.palette.grayscale[2]} inset;
  }

  .highlight_text {
    background-color: ${theme.colors.highlight};
    transition: 0.5s ease-in-out;
  }

  .files-dropzone {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .files-dropzone-active {
    background-color: ${theme.colors.bluecolumbia};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .change_log_notification-class .ant-notification-notice-description {
    margin-top: 32px;
  }
  .mb10{
    margin-bottom: 10px;
  }
`;

export const transition = (timing = 0.3) => {
  return `
      -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;
}