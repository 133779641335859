import { Input } from "antd";

export const passwordConfig: any = [
  {
    label: "Current Password",
    key: "currentPassword",
    Component: Input.Password
  },
  {
    label: "New Password",
    key: "newPassword",
    Component: Input.Password
  },
  {
    label: "Confirm Password",
    key: "confirmPassword",
    Component: Input.Password
  }
]