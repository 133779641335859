import React, { useState } from 'react';
//Components
import { Button, Col, Input, Row, Space } from "antd";
import { OperationButton, RadioGroup } from "@cardoai/components";

const {TextArea} = Input;

const feedBackChoices = [
  {
    key: 'confusing_info',
    label: 'This information is confusing'
  },
  {
    key: 'incorrect_info',
    label: 'The information is incorrect'
  },
  {
    key: 'other',
    label: 'Other'
  }
]


const NegativeMessageBox = ({changeFeedback}: any) => {
  const [response, setResponse] = useState('confusing_info');

  const handleNegativeResponse = (key: React.SetStateAction<string>) => {
    setResponse(key);
  }

  const showTextArea = response === "other";

  return (
    <Space direction='vertical'>
      <RadioGroup
        type="radio"
        value={response}
        entries={feedBackChoices}
        onChange={handleNegativeResponse}
      />
      {showTextArea && <TextArea/>}
      <Row justify="end" align="middle" gutter={16}>
        <Col>
          <Button onClick={changeFeedback(null)} className="floatR">Go Back</Button>
        </Col>
        <Col>
          <OperationButton type="submit" buttonType='primary' onClick={() => {
          }} displayIcon={false}/>
        </Col>
      </Row>

    </Space>
  );
};

export default NegativeMessageBox;