import React from "react";
//Components
import { Input, Select } from "antd";

interface IDocumentType {
  type: any,
  options: any,
  onChange: any,
  placeholder?: string
}

const DocumentType = (props: IDocumentType) => {
  const {type, options, onChange, placeholder = "Document Type"} = props;

  const handleChange: any = (e: { target: { value: any; }; }) => {
    switch (type) {
      case "input":
        return onChange(e.target.value);
      case "selection":
        return onChange(e)
    }
  }

  switch (type) {
    case "input":
      return (
        <Input
          className="table-input"
          placeholder={`Enter ${placeholder}`}
          onChange={handleChange}/>
      );
    case "selection":
      return (
        <Select className="fullWidth" placeholder={`Select ${placeholder}`} onChange={handleChange}>
          {Object.keys(options).map(option => {
            const value = options[option];
            return (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            )
          })}
        </Select>
      )
    default:
      return null;
  }
}


export default DocumentType;