import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Sector = () => {
  return (
    <>
      <div>
        <p className="bold">
          Shows the distribution of the loans of the SPV by sector (based on the available sector classification).
        </p><br/>
        <p>
          Clicking on a Section on the graph or on the list on the right, the User is redirected to the Loans Management
          module with the list of all the loans with the parameters selected (i.e. Advanced Filters set for the relevant
          SPV and Sector, see Loans Management for additional details) – by clicking on Go Back <img
          src={assets.images.others.goBack} alt="Go Back Button"/>
          (top left) the User is redirected to the SPV Management/Sector tab.
        </p>
      </div>
      <CustomImage
        alt="SPV Management Collateral Diversification Sector"
        src={assets.images.spvModule.sector}
      />
    </>
  );
};

export default Sector;
