import React from 'react';
//Components
import { Button, Row } from "antd";
//Icons
import { DownloadOutlined } from "@ant-design/icons";
//Custom Components
import { DownloadAsCSV, SmartTable } from "../../../../components";
//Configuration
import staticData from "./guarantees.json";
import { columnGroups, config } from "./config";

const NPLGuarantee = ({onDownload}: any) => {
  return (
    <>
      <Row className="mr8" align="middle" justify="end">
        <DownloadAsCSV onDownload={onDownload(staticData, "Guarantees")}>
          <Button icon={<DownloadOutlined/>} size="small">Download</Button>
        </DownloadAsCSV>
      </Row>
      <SmartTable groupingPosition="start" allowGrouping height={700} columnGroups={columnGroups} columns={config}
                  externalData={staticData}/>
    </>
  );
};

export default NPLGuarantee;