import React from 'react';
//Components
import { Button, Col, Popconfirm, Radio, Row } from "antd";
//Icons
import { FileExcelOutlined } from "@ant-design/icons";
//Helpers
import { SEPARATOR } from "../../../types";

const ChooseSeparator = ({onChange, value}: any) => {
  return (
    <>
      <Row align="middle">
        <Col>
          Select the decimal separator.
        </Col>
      </Row>
      <Row align="middle">
        <Col>
          <Radio.Group onChange={onChange} value={value} size="small">
            <Radio value={SEPARATOR.comma}>Comma</Radio>
            <Radio value={SEPARATOR.dot}>Dot</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </>
  )
}


interface IDownloadAsCSV {
  onDownload: (query?: string, params?: any) => void
  loading?: boolean,
  disabled?: boolean,
  children?: any
}

const DownloadAsCSV = (props: IDownloadAsCSV) => {

  const {onDownload, loading, disabled, children} = props;

  const [decimalSeparator, setDecimalSeparator] = React.useState(SEPARATOR.comma);

  const changeDecimalSeparator = (event: any) => {
    setDecimalSeparator(event.target.value);
  }

  const downloadFile = () => {
    const query = `&decimal_seperator=${decimalSeparator}`
    if (onDownload)
      onDownload(query, {decimalSeparator});
  }

  return (
    <Popconfirm title={<ChooseSeparator onChange={changeDecimalSeparator} value={decimalSeparator}/>}
                onConfirm={downloadFile} placement="left">
      {children || <Button disabled={disabled} loading={loading} icon={<FileExcelOutlined/>} shape="circle"/>}
    </Popconfirm>
  );
};

export default DownloadAsCSV;