import React from "react";
//Components
import { Tabs } from "@cardoai/components";
import { Collateral, LoanHeader } from "../components";
import LoanTimeline from "../../timeline/loan_timeline";
import CompanyDetails from "../../company_details";
//Configurations
import { Analytics, getNested } from "../../../../../helpers";
import { TAB_KEYS } from "../../../config";

interface ILoanSections {
  sectors: any,
  data: any,
  loanId: any
}

const LoanSections = (props: ILoanSections) => {
  const {sectors, data, loanId} = props;

  const onTabChange = (key: any) => {
    Analytics.dispatchEvent({
      category: 'User',
      action: `Changed tabs at Loan Details for loan: ${loanId}`,
      label: key
    });
  }

  return (
    <section>
      <LoanHeader currency={data.currency} data={data}/>
      <Tabs
        data={data}
        tabsHeight={550}
        onChange={onTabChange}
        config={[
          {
            label: "Timeline",
            key: TAB_KEYS.Timeline,
            content: LoanTimeline,
            props: {
              loanId: getNested(data, "id"),
              loanDetails: data,
            }
          },
          {
            label: "Debtor",
            key: TAB_KEYS.Debtor,
            props: {
              visible: true,
              sectors: sectors,
              companyId: getNested(data, "company_id"),
            },
            content: CompanyDetails
          },
          {
            label: "Collateral",
            key: TAB_KEYS.Collateral,
            content: Collateral,
            props: {
              collateralData: getNested(data, "collateral"),
            }
          }
        ]}/>
    </section>
  )
};

export default LoanSections;