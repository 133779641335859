//Components
import { TableActions } from "./components";
//Helpers
import { Text } from "../../../../helpers";
//Configurations
import { defaultColumns, dynamicColumns } from "./config";
import config from "../../../../constants/config";

export const getExtraTableColumns = (vehicleIdentifier: any) => {
  if (config.hasOwnProperty(vehicleIdentifier))
    return config[vehicleIdentifier].notesManager.tableColumns;

  return []
}

export const prepareTabularData = (data: any[]) => {
  /**
   * This function intends to iterate through the BE data and return a new data structure by also extracting the
   * amounts in each currency for the records. This is because there are two columns with currencies that need to
   * display information, however smart_table structure doesn't support showing it unless we modify it.
   *
   * @params: data -> BE data
   */

  const newStructure: any[] = [];
  data.forEach((record: any) => {
    let newRecord = {...record};
    for (let key in record) {
      if (record.hasOwnProperty(key) && key === "collateral_amounts") {
        const currencies = record[key];
        Object.keys(currencies).forEach(currency => {
          const value = currencies[currency].amount;
          newRecord = {...newRecord, [currency]: value}
        })
      }
    }
    newStructure.push(newRecord);
  })
  return newStructure;
}

export const prepareColumnGroups = (extraColumns: any) => {
  const groups = [
    {
      key: "General",
      styles: {
        background: "#f7f7f7",
        borderColor: "#fff",
        color: 'rgba(0, 0, 0, 0.65)'
      },
    },
  ];

  if (extraColumns.find((key: any) => key.startsWith('senior')))
    groups.push({
      key: "Senior",
      styles: {
        background: "#ebebeb",
        borderColor: "#fff",
        color: "rgba(0, 0, 0, 0.65)"
      }
    })

  if (extraColumns.find((key: any) => key.startsWith('junior')))
    groups.push({
      key: "Junior",
      styles: {
        background: "#dedede",
        borderColor: "#fff",
        color: "rgba(0, 0, 0, 0.65)"
      }
    })

  groups.push({
    key: " ",
    styles: {
      background: "#d1d1d1",
      borderColor: "#fff",
      color: 'rgba(0, 0, 0, 0.65)'
    },
  })

  return groups;
}

export const prepareTableColumns = (extraColumns: any) => {
  const columns: any = [...defaultColumns];

  dynamicColumns.forEach((column: any) => {
    if (extraColumns.includes(column.key))
      columns.push(column);
  })

  columns.push({
    key: "actions",
    label: Text("Actions"),
    group: " ",
    sortable: true,
    Component: TableActions,
  });

  return columns;
};