import React from 'react';
//Components
import { BoldText } from "@cardoai/components"
//Custom Components
import { SmartChart } from "../../../../../../components";
//Colors
import { theme } from "../../../../../../assets/theme/colors";
//Helpers
import { formatters } from "../../../../../../helpers";

const colors = theme.palette.SectorBlueColors;

const CollateralPieChart = ({categoryName, data}: any) => {
  return (
    <>
      <BoldText className="textCenter">{categoryName}</BoldText>
      <SmartChart
        data={data.filter((i: any) => i.name !== "Total")}
        height={300}
        showLegend={false}
        colors={colors}
        valueEntity="balance"
        labelEntity="name"
        types={['pie']}
        format={formatters.thousands}
      />
    </>
  );
};

export default CollateralPieChart;