import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Performance = () => {
  return (
    <>
      <div>
        <p>
          The left side of the section displays the timely evolution of the delinquency in the portfolio.
          The User can select the time range to be shown, get additional information by hovering on the stacks or
          change the data from % to amount by clicking on the € icon under the submenu <i>Show by</i>.
        </p>
      </div>
      <CustomImage
        alt="SPV Management Collateral Performance"
        src={assets.images.spvModule.performance}
      />
      <div>
        <DefinitionList
          header="The right side shows a graph with the evolution of 4 different measure (that can be selected from the highlighted dropdown menu):"
          data={[
            {
              term: 'Default Ratio',
              explanation: 'percentage of defaulted loans on the total Current Balance'
            },
            {
              term: 'Delinquency Ratio (DPD>30)',
              explanation: 'percentage of loan with over 30 Days Past Due on total Current Balance'
            },
            {
              term: 'Cumulative Default Ratio',
              explanation: 'percentage of cumulative default on the Initial Balance of the collateral pool'
            },
            {
              term: 'Cumulative Losses',
              explanation: 'cumulative losses recorded on the loans in the collateral_section'
            },
          ]}
        />
        <CustomImage
          fullWidth={false}
          alt="Loss Chart"
          src={assets.images.spvModule.lossChart}
          description="Where available, each metric is compared with relevant contractual triggers/thresholds
              (or an early warning level). The User can interact with the graph changing the time span displayed,
              the graphical visualisation as well as the dataset by clicking on the arrow next to the chart title."
        />
      </div>
    </>
  );
};

export default Performance;
