import React from 'react';
//Components
import Particles from "../particles_container";
import { theme } from "../../../../assets/theme/colors";

interface ILayout {
  children?: any,
  backgroundColor?: any,
  colorPrimary?: any,
  colorSecondary?: any,
  overlayTextColor?: any,
  showCookieConsent?: any,
}

const Layout = (props: ILayout) => {
  const {children, backgroundColor, colorPrimary, colorSecondary, overlayTextColor, showCookieConsent} = props;

  const contentStyle: any = {
    ['--primary-clr']: colorPrimary,
    ['--secondary-clr']: colorSecondary,
    ['--text-clr']: overlayTextColor,
    ['--background-clr']: backgroundColor
  }
  if (showCookieConsent)
    contentStyle.minHeight = 400

  return (
    <>
      <Particles/>
      <div className="container">
        <div className="login" id="login" style={contentStyle}>
          {children}
        </div>
      </div>
    </>
  )
};

Layout.defaultProps = {
  backgroundColor: theme.colors.white,
  colorPrimary: theme.colors.primary,
  colorSecondary: theme.colors.white,
  overlayTextColor: theme.colors.white,
}

export default Layout;
