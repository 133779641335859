//Helpers
import { formatters, Text } from "../../../../helpers";

export const loanHeader = (currency: any) => [
  {
    label: Text("Loan\n ID"),
    key: "platform_loan_id",
  },
  {
    label: Text("Performance\nStatus"),
    key: "performance_status",
  },
  {
    label: Text("Company\nName"),
    key: "company_name",
  },
  {
    label: Text("Investment\nAmount"),
    key: "investment_amount",
    format: (value: any) => formatters.currency({value, currency}),
  },
  {
    label: Text("Current\nBalance"),
    key: "current_balance",
    format: (value: any) => formatters.currency({value, currency}),
  },
  {
    label: Text("Loan\nType"),
    key: "loan_type",
  },
  {
    label: Text("Loan\nRating"),
    key: "loan_rating",
  },
  {
    label: Text("Issue\nDate"),
    key: "issue_date",
    format: formatters.date
  },
  {
    label: Text("Loan \nAge (yrs)"),
    key: "loan_age",
    format: formatters.thousands
  },
  {
    label: Text("Maturity\nDate"),
    key: "maturity_date",
    format: formatters.date
  },
  {
    label: Text("Residual\nMaturity (yrs)"),
    key: "remaining_term",
    format: formatters.thousands
  },
  {
    label: Text("Realized\nMaturity (yrs)"),
    key: "realized_term",
    format: formatters.thousands
  },
  {
    label: Text("Days\nDelinquent"),
    key: "days_delinquent",
    format: formatters.thousands
  },
  {
    label: Text("Loan\nGross Return"),
    key: "loan_gross_rate",
    format: formatters.percent
  },
  {
    label: Text("Cumulated\nLoss"),
    key: "write_off_amount",
    format: (value: any) => formatters.currency({value, currency}),
  },
];