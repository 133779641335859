import Inventory from "./components_far_integration/inventory";
import Acquisition from "./components_far_integration/acquisition";

const configFarIntegration = [
  {
    key: 'inventory',
    label: 'Inventory',
    component: Inventory
  },
  {
    key: 'acquisition',
    label: 'Acquisition',
    component: Acquisition
  },
]

export {
  configFarIntegration
}