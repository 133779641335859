export const groupType: any = {
  "generalAccount": "General Account",
  "excessCashAccount": "Excess Cash Account",
  "disposalAccount": "Disposal Account",
  "registrationTaxReserveAccount": "Registration Tax Reserve Account"
}

export const tableConfig: any = [
  {
    label: "General Balance Bank",
    key: "general_balance_bank",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Balance Calculated",
    key: "general_balance_calculated",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Account Clearance",
    key: "general_account_clearance",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Further Amount Payment",
    key: "general_further_amount_payment",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Balance After Drawn",
    key: "general_balance_after_drawn",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Amount Spent",
    key: "general_amount_spent",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Bank Charges",
    key: "general_bank_charges",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Other Costs",
    key: "general_other_costs",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Financed Costs",
    key: "general_financed_costs",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Property Acquisition",
    key: "general_property_acquisition",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Amount Cashed In",
    key: "general_amount_cashed_in",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "General Balance Final",
    key: "general_balance_final",
    groups: [
      groupType.generalAccount,
    ]
  },
  {
    label: "Excess Cash Account Balance",
    key: "excess_cash_account_balance",
    groups: [
      groupType.excessCashAccount,
    ]
  },
  {
    label: "Excess Balance Calculated",
    key: "excess_cash_balance_calculated",
    groups: [
      groupType.excessCashAccount,
    ]
  },
  {
    label: "Excess Cash Account Clearance",
    key: "excess_cash_account_clearance",
    groups: [
      groupType.excessCashAccount,
    ]
  },
  {
    label: "Excess Further Amount Payment",
    key: "excess_cash_further_amount_payment",
    groups: [
      groupType.excessCashAccount,
    ]
  },
  {
    label: "Excess Balance After Draw",
    key: "excess_cash_balance_after_draw",
    groups: [
      groupType.excessCashAccount,
    ]
  },
  {
    label: "Excess Amount Spent",
    key: "excess_cash_amount_spent",
    groups: [
      groupType.excessCashAccount,
    ]
  },
  {
    label: "Excess Balance Final",
    key: "excess_cash_balance_final",
    groups: [
      groupType.excessCashAccount,
    ]
  },
  {
    label: "Disposal Balance Bank",
    key: "disposal_account_balance",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Disposal Balance Calculated",
    key: "disposal_balance_calculated",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Disposal Account Replenishment",
    key: "disposal_account_replenishment",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Disposal Account Clearance",
    key: "disposal_account_clearance",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Disposal Further Amount Payment",
    key: "disposal_further_amount_payment",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Disposal Balance After Draw",
    key: "disposal_balance_after_draw",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Disposal Amount Spent",
    key: "disposal_amount_spent",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Disposal Amount Cashed In",
    key: "disposal_amount_cashed_in",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Disposal Balance Final",
    key: "disposal_balance_final",
    groups: [
      groupType.disposalAccount,
    ]
  },
  {
    label: "Registration Balance Bank",
    key: "registration_tax_reserve_account_balance",
    groups: [
      groupType.registrationTaxReserveAccount,
    ]
  },
  {
    label: "Registration Balance Calculated",
    key: "registration_tax_balance_calculated",
    groups: [
      groupType.registrationTaxReserveAccount,
    ]
  },
  {
    label: "Registration Further Amount Payment",
    key: "registration_tax_further_payment_amount",
    groups: [
      groupType.registrationTaxReserveAccount,
    ]
  },
  {
    label: "Registration Balance After Draw",
    key: "registration_tax_reserve_balance_after_draw",
    groups: [
      groupType.registrationTaxReserveAccount,
    ]
  },
  {
    label: "Registration Amount Spent",
    key: "registration_tax_reserve_amount_spent",
    groups: [
      groupType.registrationTaxReserveAccount,
    ]
  },
  {
    label: "Registration Amount Cashed In",
    key: "registration_tax_reserve_amount_cashed_in",
    groups: [
      groupType.registrationTaxReserveAccount,
    ]
  },
  {
    label: "Registration Balance Final",
    key: "registration_tax_reserve_balance_final",
    groups: [
      groupType.registrationTaxReserveAccount,
    ]
  },
]

