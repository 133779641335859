export enum STATUSES {
    REQUESTED  = "Requested",
}

export const STATUSES_ITEMS: any = {
    Requested : {
        backgroundColor: "orange",
        label:"Pending",
    },
    Rejected : {
        backgroundColor: "red",
        label:"Rejected"
    },
    Accepted : {
        backgroundColor: "green",
        label:"Approved"
    },
}