import React from 'react';
//Components
import NewLoanLimits from "./new_loans_limits"
import MainIndustryLimits from "./main_industry";
import DrawdownLimits from "./drawdown_limits";
import { Panel } from "@cardoai/components";
//Helpers
import { generateUrl, useQuery } from "../../../../../../../../../../helpers";
import { Skeleton } from "antd";

const LIMIT_CATEGORY = {
  existingLoans: "Existing Loans",
  newLoans: "New Loans",
  mainIndustry: "Main Industry",
  seniorDrawdown: "Senior Notes Draw Down",
  juniorDrawdown: "Junior Notes Draw Down"
}

const AspireProposalLimits = (props: any) => {
  const {vehicle, url, vehicleId, proposalId} = props;

  const {data} = useQuery({
    url: generateUrl(`/compartment_c/proposal_limits/general_limits`, {
      vehicle_id: vehicleId,
      proposal_id: proposalId
    })
  })

  if (!data)
    return <Skeleton/>

  const limitsData = data.limits;
  const currency = vehicle.base_currency;

  return (
    <>
      <Panel defaultOpen header="New Loan Eligibility Criteria Check">
        <NewLoanLimits
          currency={currency}
          existingLoansData={limitsData[LIMIT_CATEGORY.existingLoans]}
          newLoansData={limitsData[LIMIT_CATEGORY.newLoans]}/>
        <MainIndustryLimits currency={currency} data={limitsData[LIMIT_CATEGORY.mainIndustry]}/>
      </Panel>
      <Panel header="Drawdown">
        <DrawdownLimits
          currency={currency}
          seniorData={limitsData[LIMIT_CATEGORY.seniorDrawdown]}
          juniorData={limitsData[LIMIT_CATEGORY.juniorDrawdown]}
        />
      </Panel>
    </>
  );
};

export default AspireProposalLimits;