import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Structure = () => {
  return (
    <>
      <CustomImage
        alt="SPV Management Notes Structure View"
        src={assets.images.spvModule.structure}
        description="This section provides a view on the structure of the Notes comparing the performance status of the Collateral portfolio
            (in terms of delinquencies) with the structure/ranks of the different Notes, indicating for any tranche of Notes the current attachment point."
      />
      <div>
        <DefinitionList
          header="For any tranche of Notes the section shows:"
          data={[
            {
              term: 'Current Balance',
              explanation: 'current outstanding balance of the tranche'
            },
            {
              term: 'Original Balance',
              explanation: 'original balance of the tranche (calculated as sum of all advances made)'
            },
            {
              term: 'Current OC (%)',
              explanation: 'current overcollateralization as % of the tranche'
            },
            {
              term: 'Original OC (%)',
              explanation: 'original overcollateralization of the tranche'
            },
            {
              term: 'Factor',
              explanation: 'repayment factor as % (ratio between Initial Balance and Current Balance of the Tranche)'
            },
            {
              term: 'Coupon',
              explanation: 'coupon paid during the last collection period (do not include residual payments applied on junior tranches)'
            },
            {
              term: 'Ratings',
              explanation: 'public rating of the tranches (as per any of the main rating agency)'
            },
            {
              term: 'Shortfall',
              explanation: 'eventual shortfall of the tranche during the last collection period (difference between inflow from collateral’s ' +
                'collections and outflow to Noteholders)'
            },

          ]}
        />

        <CustomImage
          description="The graph on the right shows the evolution of the repayment factor of each tranche of Notes, highlighting with
                different colours the type of payments recorded at any reporting date (hovering on the point on the graph, the User can get
                additional information on the specific payment). On the Payment Waterfall graph, the User can switch
                the view between the Factor (repayment factor) and Balance. The button on the top right,
                Open Fullscreen, allows the user to view the whole Structure tab in the fullscreen of the device."
          alt="Waterfall Graph"
          fullWidth={false}
          isStyled={false}
          src={assets.images.spvModule.waterfall}
        />
      </div>
    </>
  );
};

export default Structure;
