import React, { useEffect, useState } from "react";
//Components
import { Col, Row } from "antd";
import { SmartChart } from "../../../../components";
import ChartSelect from "./components/chart_select";
//Helpers
import { formatters } from "../../../../helpers";
import { getFinalCharts, getValue } from "./helpers";

interface IDiversification {
  data?: any;
  queryStr?: string | undefined;
  usesMenu?: any;
  showLegend?: any;
  chartSize?: boolean;
  chartHeight?: number;
  displayRating?: boolean | undefined;
  minSelectionSize?: any;
  maxSelectionSize?: any;
  cacheSelectionEntity?: any;
  currency?: any;
}

const Diversification = (props: IDiversification) => {
  const {
    data,
    usesMenu,
    showLegend,
    chartSize,
    chartHeight,
    displayRating,
    minSelectionSize,
    maxSelectionSize,
    cacheSelectionEntity,
    currency
  } = props;
  //Change Label on the chart based on VDR user
  const charts: any = getFinalCharts();
  const [selectedCharts, setSelectedCharts] = useState<any>(() => {
    const getCachedCharts = localStorage.getItem(cacheSelectionEntity);

    const cachedCharts = getCachedCharts && JSON.parse(getCachedCharts);

    //Convert data to array
    const dataArr = Object.entries(data);

    //Filter data from empty items
    const noEmptyData = dataArr.filter((item: any) => {
      if (item[1] && item[1].length > 0) {
        return item;
      }
    });

    let defaultCharts: any = [];

    for (
      let index = 0;
      index <
      Math.min(
        maxSelectionSize,
        !noEmptyData.length || noEmptyData.length === 1
          ? charts.length
          : noEmptyData.length
      );
      index++
    ) {
      let chart;

      if (!noEmptyData.length || noEmptyData.length === 1) {
        chart = charts[index].key;
      } else {
        chart = noEmptyData[index] && noEmptyData[index][0];
      }

      defaultCharts.push(chart);
    }

    return defaultCharts;
  });

  const chartsOrder = (chartsRecords: any, order: any) => {
    chartsRecords.sort((a: any, b: any) => {
      return order?.indexOf(a.key) > order?.indexOf(b.key) ? 1 : -1;
    });
    return chartsRecords;
  };

  //Sort charts based on selectedCharts order
  const chartsSorted = chartsOrder(charts, selectedCharts);

  const hiddenCharts = charts
    .filter((chart: any) => !selectedCharts.includes(chart.key))
    .map((record: any) => record.key);

  if (!displayRating && !hiddenCharts.includes("loan_rating"))
    hiddenCharts.push("loan_rating");

  useEffect(() => {
    onStorageChange();
  }, [selectedCharts]);

  const onStorageChange = () =>
    localStorage.setItem(cacheSelectionEntity, JSON.stringify(selectedCharts));

  const isChartVisible = (key: any) => selectedCharts.includes(key);

  //Custom formatter
  const getCustomFormatter = (entities: any, chartType: any) => {
    const isCircular = ["doughnut", "pie"].includes(chartType);

    if (entities.distribution === "count") return formatters.thousandsShorted;

    if (entities.distribution === "percentage") {
      return (value: any) => {
        if (isCircular && value <= 0.1)
          return formatters.percentOnePrecision(value);
        return formatters.percentNoPrecision(value);
      };
    }
    return (value: any) => formatters.currency({ value, currency });
  };

  return (
    <div className="diversification-content">
      <ChartSelect
        options={charts}
        hidden={hiddenCharts}
        selected={selectedCharts}
        minSize={minSelectionSize}
        maxSize={maxSelectionSize}
        onChange={setSelectedCharts}
      />
      <Row gutter={8}>
        {chartsSorted
          .filter((chart: any) => isChartVisible(chart.key))
          .map((chart: any) => {
            return (
              <Col
                xs={24}
                lg={chartSize ? 8 : 12}
                className="mt16"
                key={chart.key}
              >
                <SmartChart
                  allowSnapshot
                  allowFullscreen
                  allowCSVDownload
                  allowDistribution
                  usesMenu={usesMenu}
                  hoverOnLegend={false}
                  height={chartHeight}
                  title={chart.label}
                  getValue={getValue}
                  data={data[chart.key]}
                  labelEntity={chart.labelEntity}
                  showLegend={showLegend}
                  allowGrouping={chart.allowGrouping}
                  maxLegendItems={chart.maxLegendItems}
                  sortAsc={chart.sortAsc}
                  computeOthers={chart.allowGrouping}
                  customFormatter={getCustomFormatter}
                />
              </Col>
            );
          })}
      </Row>
      <Row
        justify="end"
        className="mt16"
        style={{ fontStyle: "italic", fontSize: 10 }}
      >
        Minimum selections are {minSelectionSize} and maximum selections are{" "}
        {maxSelectionSize}
      </Row>
    </div>
  );
};
Diversification.defaultProps = {
  usesMenu: true,
  showLegend: true,
  chartSize: false,
  chartHeight: 200,
  minSelectionSize: 2,
  maxSelectionSize: 10,
  displayRating: true,
  cacheSelectionEntity: "management_general_pie_charts",
};

export default Diversification;