//Other Libraries
import styled from "styled-components";
//Constants
import { theme } from "../../../../../../../../assets/theme/colors";

export const View = styled.div`
  min-width: 160px;
  border: 1px solid ${theme.colors.mercury};

  .ant-input-number {
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: 1px solid ${theme.colors.mercury};
  }

  .svgIcon {
    font-size: 16px;
    margin-left: 5px;
    margin-top: 5px;

    :hover {
      transition: all 1s;
      transform: scale(1.1);
    }
  }
`;

export const Text = styled.div`
  font-weight: 400;
  margin-bottom: 2px;
`;
