import React from 'react';
//Components
import { Col, Row } from 'antd';
//Custom Components
import { ContentView, TreeView } from "../components";
//Utils
import { isVDRInvestor } from "../../../helpers";
import { HDRHelpRoutes, HDRHelpTOC } from "../configurations";

const contentHeight = {
  height: window.innerHeight - 140,
  overflow: 'auto'
};

const HDRHelp = () => {

  const getTree = () => {
    if (!isVDRInvestor())
      return HDRHelpTOC;

    const temp: any = [];
    HDRHelpTOC.forEach((record: any) => {
      if (record.key !== 'hdr-syncs') {
        if (record.key === 'hdr-home') {
          temp.push({
            ...record,
            children: []
          })
        } else {
          temp.push(record)
        }
      }
    })
    return temp;
  }

  return (
    <Row gutter={0}>
      <Col span={6}>
        <TreeView
          style={contentHeight}
          paths={{
            defaultPath: '/hdr-documentation',
            homePath: '/hdr-documentation/hdr-home',
          }}
          toc={getTree()}
          invalidKeys={["", "hdr-documentation"]}
        />
      </Col>
      <Col span={18}>
        <ContentView style={contentHeight} routeConfig={HDRHelpRoutes}/>
      </Col>
    </Row>
  );
};

export default HDRHelp;