import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";

export const disableStartDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today
  return current && current > moment().endOf("day");
};

export const disableEndDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before tomorrow
  return current && current < moment().startOf("day");
};
