import React from 'react';
//Other Libs
import styled from "styled-components";
//Icons
import pdfImage from "../../../../../assets/images/files/pdf.png"
import wordImage from "../../../../../assets/images/files/word.png"
import excelImage from "../../../../../assets/images/files/excel.png"
//Components
import { Col, Row, Skeleton } from 'antd';
import { SimpleEmpty } from "@cardoai/components";

//Constants
interface IFile {
  file?: any,
  link?: any,
  source?: any,
  displaySource?: any,
  entity?: any
}

const fileImages: any = {
  pdf: pdfImage,
  docx: wordImage,
  xlsx: excelImage
};
//Acceptable file types
const fileTypes = [
  'pdf',
  'docx',
  'xlsx'
];

//Custom Helpers
function getFileConfig(file: { split: (arg0: string) => any; forEach: (arg0: (str: any, index: any) => void) => void; length: number; }) {
  let fileName = "", fileType = "";
  file = file.split('.');
  file.forEach((str, index) => {
    if (index === file.length - 1)
      fileType = str;
    else
      fileName += str;
  });

  return {
    fileName,
    fileType
  }
}

//Styles
const FilesWrapper = styled.div`
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const FileWrapper = styled.div`
  text-align: center;
  cursor: pointer;

  .secondaryText {
    color: rgba(0, 0, 0, 0.45)
  }

  :hover {
    p {
      transition: 1s color;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8)
    }
  }
`;

const File = (props: IFile) => {
  const {file, link, source, displaySource, entity} = props;
  const {fileName, fileType} = getFileConfig(file);
  const validFile = fileTypes.includes(fileType.toLowerCase());

  let fileIcon = "file";
  if (validFile)
    fileIcon = fileImages[fileType.toLowerCase()];

  function redirect() {
    window.location.assign(link);
  }

  return (
    <Col xs={24} lg={4} onClick={redirect}>
      <FileWrapper title={fileName}>
        <img alt="File" src={fileIcon} width={65} height={65}/>
        <p className="secondaryText">Type: {entity}</p>
        {displaySource && <p className="secondaryText">Source: {source}</p>}
      </FileWrapper>
    </Col>
  )
};

const Files = (props: { data: any; displaySource: any; }) => {
  const {data, displaySource} = props;

  if (!data)
    return <Skeleton/>;

  if (!data.length)
    return <SimpleEmpty centered description="No Files "/>;

  return (
    <FilesWrapper>
      <Row gutter={8}>
        {data.map((file: { file: any; filename: any; source: any; file_type: any; }) =>
          <File
            link={file.file}
            file={file.filename}
            source={file.source}
            entity={file.file_type}
            displaySource={displaySource}
          />
        )}
      </Row>
    </FilesWrapper>
  );
};
Files.defaultProps = {
  displaySource: true
};

export default Files;