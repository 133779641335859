import React from 'react';
//Custom Components
import { Graph } from "../../../../../../../../../../../components";
//Helpers
import { theme } from "../../../../../../../../../../../assets/theme/colors";
import { formatters } from "../../../../../../../../../../../helpers";

const prepareGraphData = (data: any) => {
  const labels: any = [];
  const datasets: any = [];

  data.forEach((record: any) => {
    labels.push(formatters.date(record.reference_date));
    datasets.push(record.realized);
  })

  return {
    labels, datasets
  }
}

const getChartFormatter = (formatter: any, currency: any) => {
  switch (formatter) {
    case "Amount":
      return (value: any) => formatters.currency({value, currency})
    case "Percentage":
      return formatters.percentOnePrecision;
    default:
      return formatters.default
  }
}

const ChartContent = ({data, formatter, currency, threshold}: any) => {

  const {labels, datasets} = prepareGraphData(data);

  return (
    <Graph
      usesMenu
      beginAtZero
      height={300}
      allowSnapshot
      labels={labels}
      types={["bar", "line"]}
      extraStyle={{top: -20}}
      format={getChartFormatter(formatter, currency)}
      annotations={[{label: "Threshold", value: threshold, enabled: true}]}
      dataSets={[{backgroundColor: theme.colors.malibu, label: "Realized", data: datasets}]}
    />
  )
};

export default ChartContent;