import React from 'react';
//Components
import { Button, Col, Row, Tooltip } from "antd";
//CardoUI Components
import { FileUpload } from "@cardoai/components";
//Icons
import { DeleteOutlined } from '@ant-design/icons';
//Custom Styles
import Styles from "./styles";
//Helpers
import notifications from "../../notifications";

interface Props {
  file?: any,
  onChange: (param: any) => any,
  multiple?: any,
  onFileChange?: any,
  accept?: string | string[],
  label?: string,
  onClear: any,
  required: any,
  type?: string,
}

const Upload = (props: Props) => {
  const {file, accept, multiple, onChange, onClear, required} = props;

  const handleFileChange = (files: any) => {
    const file = files[0];
    if (file && file.extension) {
      const formattedExtension = `.${file.extension.toLowerCase()}`
      if (formattedExtension !== accept && !accept?.includes(formattedExtension)) {
        notifications.warning(`This file type is not allowed. It should be ${accept}`);
      } else
        onChange(file)
    }
  };

  const label = file ? `${file.name} has been uploaded` : "Choose file"

  const uploadConfig = {
    required: required,
    accept: accept,
    multiple: false,
    placeholder: label,
    onChange: handleFileChange
  }

  return (
    <Row gutter={8}>
      <Col xs={file ? 22 : 24} className="fullWidth">
        <FileUpload {...uploadConfig}>
          <Styles>
            {label}
          </Styles>
        </FileUpload>
      </Col>
      {file && <Col xs={2}>
        <Tooltip title={multiple ? "remove files" : "remove file"}>
          <Button onClick={onClear} danger icon={<DeleteOutlined/>}/>
        </Tooltip>
      </Col>}
    </Row>
  );
};

export default Upload;

Upload.defaultProps = {
  multiple: false
}
