import React from "react";
//Components
import { Card, Col, Row } from "antd";
//Helpers
import { formatters, validateValue } from "../../../../helpers";

interface Props {
  countryMode: any,
  visible: boolean,
  data: any,
  position: any,
  ratingLabel: any
  currency?: any
  labels?: string[]
}

const DEFAULT_LABELS = ({countryMode, ratingLabel}: any) => [
  {
    key: "name",
    label: countryMode ? "Country" : "Region"
  },
  {
    key: "balance",
    label: "Current Balance",
    format: "currency"
  },
  {
    key: "gwar_open",
    label: "WAR",
    format: formatters.percent
  },
  {
    key: "wam",
    label: "WAM",
    format: formatters.years
  },
  {
    key: "avg_rating",
    label: ratingLabel
  }
]

const ToolTip = (props: Props) => {
  const {
    countryMode,
    visible,
    data,
    position,
    ratingLabel,
    currency,
    labels = ["name", "balance", "gwar_open", "wam", "war", "avg_rating"]
  } = props;

  const allInfo = DEFAULT_LABELS({
    countryMode,
    ratingLabel
  }).filter((label: any) => labels?.includes(label.key))

  if (!visible || !data)
    return null;

  return (
    <Card size="small" style={position}>
      <Row className="columnDirection">
        {allInfo.map((info: any) => {
          const format = info.format;
          let formattedValue = validateValue(data[info.key]);

          if (format) {
            if (format === "currency")
              formattedValue = formatters.currency({value: data[info.key], currency})
            else
              formattedValue = validateValue(data[info.key], format)
          }

          return (
            <Col>
              {info.label} : <span className="bolder">{formattedValue}</span>
            </Col>
          )
        })}
      </Row>
    </Card>
  )
};

ToolTip.defaultProps = {
  countryMode: true,
}

export default ToolTip;
