import React from 'react';
import { useToggle } from '@cardoai/utils';
import { Button, Col, Popover, Row } from 'antd';
import MoreIcon from '../../../../../../../icons/more';
import Filters from '../../../../../../../design/filters';
import { useQuery } from '../../../../../../../../../helpers';
import DownloadIcon from '../../../../../../../icons/download';
import ChooseColumns from '../../../../../../../design/choose_columns';
import ColumnChooserIcon from '../../../../../../../icons/column_chooser';
import SmartSearch from '../../../../../../../design/smart_search';

const Actions = ({ groups, filters, updateFilters, setGroups, handleDownload }: any) => {
  const [moreVisible, toggleMore]: any = useToggle(false);
  const [columnsChooser, toggleColumnsChooser]: any = useToggle(false);
  const filterOptions = useQuery({ url: `/main/real_estate/real_estate_filter_options/` });
  return (
    <Row align='bottom' gutter={8}>
      <Col>
        <SmartSearch
          width={450}
          onChange={(event: any) => {
            updateFilters(event);
          }}
          config={[
            {
              type: 'input',
              defaultOpen: true,
              key: 'property_id_m',
              label: 'Property ID (M)',
              defaultValue: filters.property_id_m,
            }
          ]} />
      </Col>
      <Col>
        <Button
          type='link'
          onClick={handleDownload}
          ghost icon={<DownloadIcon />} />
      </Col>
      <Col>
        <Popover
          trigger='click'
          open={columnsChooser}
          placement='bottomRight'
          onOpenChange={toggleColumnsChooser}
          content={(
            <ChooseColumns
              value={groups}
              onChange={setGroups}
              onClose={toggleColumnsChooser}
              config={[
                { key: 'total', label: 'Total Costs' },
                { key: 'pp', label: 'PP' },
                { key: 'financed_costs', label: 'Financed Costs' },
                { key: 'other_costs', label: 'Other Costs' },
              ]}
            />
          )}>
          <Button type='link' ghost icon={<ColumnChooserIcon active={!!columnsChooser} />} />
        </Popover>
      </Col>
      <Col>
        <Popover
          trigger='click'
          showArrow={false}
          open={moreVisible}
          placement='bottomRight'
          onOpenChange={toggleMore}
          content={(
            <Filters
              onChange={(event: any) => {
                toggleMore();
                updateFilters(event);
              }}
              config={[
                {
                  multiple: true,
                  type: 'select',
                  key: 'country',
                  label: 'Country',
                  defaultValue: filters.country,
                  options: filterOptions?.data?.country,
                },
              ]} />
          )}
        >
          <Button type='link' ghost icon={<MoreIcon />} />
        </Popover>
      </Col>
    </Row>
  );
};

export default Actions;