import React from 'react';
//Styles
import { StyledDiv, Table } from "../../../../styles";
//Helpers
import { formatVehicleLimits } from "../../../../helpers";
import { formatters, MathOp } from "../../../../../../../../../../helpers";
//HOC
import withLimitDownload from "../../../../components/hoc/with_limit_download";

interface Props {
  data: Array<any>,
  height?: any,
  currency?: any,
}

const PortfolioCheck = (props: Props) => {
  const {data, height, currency} = props;

  let capitalSum = 0;
  let npvSum = 0;

  return (
    <StyledDiv height={height}>
      <Table className="fullWidth">
        <thead>
        <tr>
          <th/>
          <th className="textCenter primary">Notional Outstanding Capital</th>
          <th className="textCenter primary">Valuation</th>
          <th className="textCenter primary">Net Portfolio Value (NPV)</th>
        </tr>
        </thead>
        <tbody>
        {data.map((entry: any, index: number) => {
          const {name, fulfillment, fulfillment_percent, value, value_percent, formatter}: any = entry;

          const parsedFulfillment = Number(fulfillment);
          const parsedNpv: any = MathOp.multiply(parsedFulfillment, Number(value_percent));

          capitalSum += parsedFulfillment;
          npvSum += parsedNpv;

          return (
            <tr key={index}>
              <td className="bold pl24">{name}</td>
              <td
                className="textCenter">{formatVehicleLimits(fulfillment, fulfillment_percent, formatter, currency)}</td>
              <td className="textCenter">{formatVehicleLimits(value, value_percent, formatter, currency)}</td>
              <td className="textCenter bold">{formatters.currency({value: parsedNpv, currency})}</td>
            </tr>
          )
        })}
        <tr>
          <td className="primary bold pl24">Total</td>
          <td className="textCenter bold primary">{formatters.currency({value: capitalSum, currency})}</td>
          <td/>
          <td className="textCenter bold primary">{formatters.currency({value: npvSum, currency})}</td>
        </tr>
        </tbody>
      </Table>
    </StyledDiv>
  );
};

export default withLimitDownload(PortfolioCheck)({
  title: 'Portfolio Check',
  prepareData: function (data: Array<any>) {
    const newStructure = [];
    let sum = {
      capitalSum: 0,
      npvSum: 0
    }
    data.forEach(record => {
      const name = record.name;
      const notionalCapital = Number(record.fulfillment);
      const valuation = Number(record.value_percent);
      const parsedNpv: any = MathOp.multiply(notionalCapital, valuation);
      sum.capitalSum += notionalCapital;
      sum.npvSum += parsedNpv;
      newStructure.push({
        "Name": name,
        "Notional Outstanding Capital": notionalCapital,
        "Valuation": valuation,
        "Net Portfolio Value (NPV)": parsedNpv
      })
    })
    newStructure.push({
      "Name": "Total",
      "Notional Outstanding Capital": sum.capitalSum,
      "Valuation": "",
      "Net Portfolio Value (NPV)": sum.npvSum
    })


    return newStructure;
  }
});

