// Helper functions
import { formatters } from "../../../helpers";

export const showPaginationTotal = (total: any, range: any) => `${range[0]}-${range[1]} of ${total} records`;

export const getColumnSortingName = (column: any) => column.sortingName || column.key;

export const checkColumnSorting = (sortingFields: any, column: any) => {
  const columnSortingName = getColumnSortingName(column);

  // Check if there is an ascending sorting for the column
  let columnIndex = sortingFields.indexOf(columnSortingName);
  if (columnIndex !== -1)
    return {
      direction: "asc",
      index: columnIndex
    };
  // Check if there is a descending sorting for the column
  columnIndex = sortingFields.indexOf(`-${columnSortingName}`);
  if (columnIndex !== -1)
    return {
      direction: "desc",
      index: columnIndex
    };

  return null;
};

export function lowerCase(str: any) {
  if (!str)
    return str;

  if (typeof str === "string")
    return str.toLowerCase();

  return str;
}

export const formatValue = (value: any, format: any, rest?: any) => {
  if (!format)
    return value;

  if (typeof format === "string")
    return formatters[format](value);

  else if (typeof format === "function")
    return format(value, rest);
};

class Compare {
  static strictEqual(value: any, term: any) {
    return lowerCase(value) === lowerCase(term)
  }

  static includes(value: any, term: any) {
    switch (typeof value) {
      case "string":
        return lowerCase(value).includes(lowerCase(term))
      case "number":
        return term && term !== "" ? value === Number(term) : false;
      default:
        return false
    }
  }

  static starts(value: any, term: any) {
    return lowerCase(value).startsWith(lowerCase(term))
  }

}

export const prepareResults = (data: any, columns: any, groupBy: any, searchTerm: any) => {
  const records = data.records;

  function compareResult(value: any) {
    return Compare.includes(value, searchTerm)
  }

  if (searchTerm)
    searchTerm = searchTerm.trim()

  if (!searchTerm)
    return records;

  if (!groupBy)
    return records.filter((record: any) => {
      const values: any = [];
      Object.keys(record).forEach(key => {
        let value = record[key];
        const column = columns.find((col: any) => col.key === key);
        if (column && column.grouping) {
          value = formatValue(value, column.format);
          values.push(value)
        }
      });
      return values.some((value: any) => compareResult(value))
    });

  const column = columns.find((col: any) => col.key === groupBy);

  return records.filter((record: any) => {
    let value = record[groupBy];

    if (!value)
      return false;

    value = formatValue(value, column.format);

    return compareResult(value)
  });
};

export const getWidthStyle = (width: any) => ({
  maxWidth: width,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});
