//Helpers
import { generateUrl } from "../../../../helpers";

function extractNavigator(navigation: { state: any; }, index: number) {
  const state = navigation.state;
  if (state && state.length > index)
    return state[index];
  return {
    key: null,
    label: null
  };
}

function extractVehicle(navigation: { state: any; }) {
  return extractNavigator(navigation, 0);
}

function extractSection(navigation: { state: any; }) {
  return extractNavigator(navigation, 1);
}

function extractSectionTab(navigation: { state: any; }) {
  return extractNavigator(navigation, 2);
}

function extractSectionNestedTab(navigation: { state: any; }) {
  return extractNavigator(navigation, 3);
}

export const NAVIGATOR_HELPER = {
  extractNavigator,
  extractVehicle,
  extractSection,
  extractSectionTab,
  extractSectionNestedTab
}
export const prepareVehicleContentUrl = (url: string) => {
  // @ts-ignore
  return ({params, props}) => {
    const finalQuery = params || {};
    const queryStr = props.queryStr;

    if (props.vehicleId)
      finalQuery.vehicle_id = props.vehicleId;
    const finalUrl = generateUrl(url, finalQuery);
    return queryStr ? finalUrl + queryStr : finalUrl
  }
};
export const sectionType = {
  notes: "notes",
  collateral: 'collateral'
};

