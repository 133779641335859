import React, { useEffect } from 'react';
//Components
//Custom Components
import SideLogo from "./side_logo";
import LoginContent from "./login_content";
import ContactUsContent from "./contact_us_content";

const LoginForm = (props: any) => {
  const {onLogin,loading} = props;

  const handleSubmitLogin = (event: any) => {
    event.preventDefault();

    function formValue(key: any) {
      const reference: any = document.getElementById(key);
      if (reference)
        return reference.value;
    }

    onLogin({
      username: formValue("user_name"),
      password: formValue("password")
    });
  };

  useEffect(() => {
    const mainButton: any = document.getElementById('main-button');
    const secondaryButton: any = document.getElementById('secondary-button');
    const container: any = document.getElementById('login');
    secondaryButton.addEventListener('click', () => {
      container.classList.add('login--active')
    });

    mainButton.addEventListener('click', () => {
      container.classList.remove('login--active')
    })
  }, []);


  return (
    <>
      <ContactUsContent/>
      <LoginContent
        loading={loading}
        onLogin={handleSubmitLogin}/>
      <SideLogo/>
    </>
  )
};

export default LoginForm;