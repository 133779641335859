import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Loans = () => {
  return (
    <CustomImage
      alt="SPV Management Collateral Loans"
      src={assets.images.spvModule.loans}
      description="Loans section shows the full list of Loans of the Collateral. The User can Search or Filters
        loans and data can be downloaded in Excel format (See Loans Management for additional details on functionalities)."
    />
  );
};

export default Loans;
