import { useQuery } from "../../../helpers";
import queryString from "query-string";

export const useGetTopics = (vehicleId: any, search: any) => {
  return useQuery({
    deps: [search, vehicleId],
    url: () => `common/topic/?page=1&page_size=60&pk=${vehicleId}&model=vehicle&search=${search}`,
  });
}

export const useGetMessages = (page: any, pageSize: any, vehicleId: any, topicId: any, status?: any, parentMessageId?: any,) => {
  const queryParams: any = {
    page: page,
    page_size: pageSize,
    model: 'vehicle',
    pk: vehicleId,
    topic: topicId,
    parent_message: parentMessageId,
  };

  if (status != undefined && status !== '')
    queryParams['status'] = status;

  return useQuery({
    deps: [page, pageSize, vehicleId, topicId, status, parentMessageId],
    url: () => `/common/message/?${queryString.stringify(queryParams)}`,
  })
}
