import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../../assets";

const HDRIRRGraph = () => {

  const description =
    <div>
      IRR Graph<br/>
      IRR Graph and Summary data, due to the nature of their heavy calculations,
      are stored in a Cache layer on each synchronization request, reducing the response time from seconds to tens of
      milliseconds.
    </div>

  return (
    <Row style={{paddingLeft: '20px'}}>
      <Col sm={22}>
        <CustomImage
          description={description}
          alt="Default Distribution"
          src={assets.images.HDRModule.irrGraph}
        />
      </Col>
    </Row>
  );
};
export default HDRIRRGraph;