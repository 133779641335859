import React from 'react';
//Components
import { Card } from 'antd';
import { CustomTable, SimpleEmpty } from "@cardoai/components";
//Custom Helpers
import { humanString } from "../../../../../../helpers";

//Custom Helpers
function getTableConfig(data: any) {
  let dataSets: any = {};
  let labels = data.date;
  const entities = data.value;

  function prepareDate(date: string) {
    const formattedDate = date.split('-')[0];
    return parseInt(formattedDate);
  }


  //Compute Rows
  for (let entity in entities) {
    if (entities.hasOwnProperty(entity)) {
      dataSets[entity] = {};
      entities[entity].forEach((entry: { date: any; value: any; }) => {
        const {date, value} = entry;
        if (date) {
          const formattedData = prepareDate(date);
          dataSets[entity][formattedData] = value;
        }
      })
    }
  }

  return {labels, dataSets}
}

const FinancialStatement = (props: { data: any; }) => {
  const {data} = props;

  if (!data)
    return <SimpleEmpty/>;

  const {labels, dataSets} = getTableConfig(data);

  if (!labels.length)
    return <SimpleEmpty description="No Data for Financial Statement"/>;


  return (
    <Card size='small'>
      <p className="title">Financial Statement (Summary):</p>
      <CustomTable centerData centerHeaders size='small' maxHeight={480}>
        <thead>
        <tr>
          <th style={{textAlign: 'left', width: 200}}>Fields</th>
          {labels.map((label: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => (
            <th>{label}</th>)
          )}
        </tr>
        </thead>
        <tbody>
        {Object.entries(dataSets).map(([entity, dataSet]: any) => {
          function getValue(label: string | number) {
            let value = dataSet[label];
            if (value === "INF")
              return "∞";
            return value
          }

          return (
            <tr key={entity}>
              <td style={{textAlign: 'left', width: 200}}>{humanString(entity)}</td>
              {labels.map((label: string | number) => <td>{getValue(label)}</td>)}
            </tr>
          )
        })}
        </tbody>
      </CustomTable>
    </Card>
  );
};

export default FinancialStatement;