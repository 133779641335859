import React from 'react';
//Other libs
import styled from "styled-components";
//Utils
import { useToggle } from "@cardoai/utils";
//Components
import { Row, Col, Select, Empty, Divider, Skeleton, Tag } from 'antd';
import Table from "./table";
import Container from "../../create/components/container";
import { useQuery } from "../../../../../../helpers";
import { useProposal } from "../../../../context";

const ContainerSelect = styled.div`
  position: relative;

  .ant-select-selection-overflow-item {
    display: none !important;
  }

  .custom-placeholder {
    width: 100%;
    padding: 0 8px;
    position: absolute;
    top: 6px;
    left: 0;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    z-index: 12;
  }
`
const ContainerStyle = styled.div`
  .ant-col.ant-col-xs-24{
    overflow: hidden;
  }
  .ant-divider-horizontal{
    border-width: 3px 0 0;
  }
`

const SpvLimits = ({footer}: any) => {
  const {vehicleId, proposalId} = useProposal();
  const [open, toggle] = useToggle(false);
  const [selected, setSelected] = React.useState<any>([]);

  const {data} = useQuery({
    query: {
      vehicle_id: vehicleId,
      proposal_id: proposalId,
    },
    url: `/casavo/notes_manager/concentration_limits/?`,
    onSuccess: (response: any) => {
      const keys = Object.keys(response)
      setSelected([keys[0]])
    }
  })

  if (!data)
    return <Skeleton active/>

  const handleClose = (removedTag: string) => {
    const newTags = selected.filter((tag:any) => tag !== removedTag);
    setSelected(newTags);
  };

  return (
    <ContainerStyle>
    <Container{...footer}>
      <Row gutter={[8, 16]}>
        <Col xs={24}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Row align="middle" gutter={16}>
                <Col style={{fontWeight: 500}}>
                  Limits
                </Col>
                <Col>
                  <ContainerSelect onClick={toggle}>
                    <Select
                      open={open}
                      showArrow
                      mode="multiple"
                      value={selected}
                      showSearch={false}
                      onChange={setSelected}
                      style={{
                        minWidth: 240,
                        borderRadius: 8,
                        cursor: "pointer"
                      }}>
                      {Object.keys(data).map((o: any) => <Select.Option
                        key={o}>{o}</Select.Option>)}
                    </Select>
                    <span className="custom-placeholder">
                                                    Select Group/s
                                                </span>
                  </ContainerSelect>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              {selected.map((key: any) =>
                <Tag
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleClose(key);
                }}
              >
                {key}
              </Tag>)}
            </Col>
            <Col xs={24}>
              <Divider style={{width: '100%', marginTop: 0}}/>
            </Col>
          </Row>
        </Col>
        {selected.length ? selected.map((key: any, index: number) => {
          const isLast = index === selected.length;
          return (
            <>
              <Col xs={24}>
                <Table data={data[key]}/>
              </Col>
              {!isLast && <Divider/>}
            </>
          )
        }) : (
          <Col xs={24}>
            <Row justify="center">
              <Col>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
    </ContainerStyle>
  );
};

export default SpvLimits;