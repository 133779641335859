import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../../assets";

const HDRLosses = () => {

  const description =
    <div>
      Losses<br/>
      In this section, a chart representation of actual and expected losses is displayed. The user, as in Summary
      Statistics, can filter by year of investment and actual/expected/both types of losses.
    </div>

  return (
    <Row style={{paddingLeft: '20px'}}>
      <Col sm={22}>
        <CustomImage
          description={description}
          alt="Default Distribution"
          src={assets.images.HDRModule.losses}
        />
      </Col>
    </Row>
  );
};

export default HDRLosses;