const CASHFLOWTYPE: any = {
  interest: 'INTEREST',
  capital: 'REPAYMENT CAPITAL',
  fees: 'LATE FEES'
};


function initializeDatasets() {
  return {
    [CASHFLOWTYPE.capital]: [],
    [CASHFLOWTYPE.interest]: [],
    [CASHFLOWTYPE.fees]: []
  };
}

const datasets: any = {
  expected: initializeDatasets(),
  realized: initializeDatasets(),
};


function initializeSum() {
  return {
    [CASHFLOWTYPE.interest]: 0,
    [CASHFLOWTYPE.capital]: 0,
    [CASHFLOWTYPE.fees]: 0
  };
}

function initializeRecord() {
  return {
    expected: initializeSum(),
    realized: initializeSum(),
  };
}


export {
  datasets,
  CASHFLOWTYPE,
  initializeSum,
  initializeRecord,
  initializeDatasets
}