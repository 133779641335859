import React from 'react';
//Components
import { Button, Col, Row, Skeleton, Spin, Tooltip } from "antd";
//Custom Components
import ImportDataTape from "./import_data_tape";
import { SmartTable } from "../../../../components";
import RemoveCollateralSyncs from "../remove_collateral_syncs";
//Icons
import { ReloadOutlined } from "@ant-design/icons";
//Hooks
import { useInterval } from "@cardoai/utils";
import { useQuery } from "../../../../helpers";
import { useAuthorization } from "../../../../utils/hooks";
//Constants
import { actionKey } from "../../../../constants";

interface Props {
  columns?: any,
  vehicleId?: any,
  vehicle?: any,
  title?: any,
  vehicleName?:any,
  vehicleIdentifier?:any
}


const SyncTable = (props: Props) => {
  const {columns, vehicleId, vehicle, title,vehicleName,vehicleIdentifier} = props;

  const tableRequest = useQuery({
    url: `/main/sync_request/?vehicle_id=${vehicleId}`,
    onSuccess: (response: any) => {
      updateDataOnStatusChange(response)
    },
    deps: [vehicleId]
  });

  const authorization = useAuthorization();
  const intervalRef: any = React.useRef({open: true});
  const canRemoveSyncs = authorization.canPerformAction(actionKey.removeCollateralSyncs, vehicleId) && authorization.user.is_staff;


  const updateTable = () => {
    tableRequest.fetch();
  };
  const startInterval = () => {
    intervalRef.current.open = true;
  }
  const closeInterval = () => {
    intervalRef.current.open = false;
  }

  const updateDataOnStatusChange = (data: any) => {
    let shouldCancelInterval = true;
    for (let record of data) {
      if (record.status === 'In Progress' || record.status === "DHC In Progress") {
        shouldCancelInterval = false;
        break;
      }
    }
    if (shouldCancelInterval)
      closeInterval();
    else
      startInterval();
  }

  useInterval(() => {
    if (intervalRef.current.open)
      updateTable();
  }, 10000)

  const getValidDates = () => {
    const tableData = tableRequest.data;
    return tableData
      .filter((record: any) => ["Sync Collateral", "Sync Collateral Simulation"].includes(record.sync_request_type))
      .map((record: any) => record.reference_date);
  }

  if (!tableRequest.data)
    return <Skeleton/>

  return (
    <>
      <Row justify="end" className="mb8">
        <Col>
          <Tooltip title="Refresh" placement='right'>
            <Button size='small' onClick={updateTable}>
              <ReloadOutlined/>
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <Spin spinning={tableRequest.loading}>
        <SmartTable
          allowGrouping
          maxHeight={600}
          columns={columns}
          vehicle={vehicle}
          vehicleId={vehicleId}
          groupBy={"sync_request_type"}
          externalData={tableRequest.data}
          renderCallbacks={{makeRequest: updateTable}}
        />
      </Spin>
      <ImportDataTape vehicle={vehicle} vehicleId={vehicleId} title={title} makeRequest={updateTable}/>
      {canRemoveSyncs &&
        <RemoveCollateralSyncs vehicleName={vehicleName} validDates={getValidDates()} vehicleId={vehicleId} onUpdateTable={updateTable} vehicleIdentifier={vehicleIdentifier}/>}
    </>
  );
};

export default SyncTable;
