//Constants
import { blue, cyan } from "@ant-design/colors";

export const delayChartDatasets = [
  {
    label: "DPD 1-29",
    identifier: "late_1_29_balance",
    backgroundColor: cyan[2],
    stack: 1,
  },
  {
    label: "DPD 30-59",
    identifier: "late_30_59_balance",
    backgroundColor: cyan[4],
    stack: 1,
  },
  {
    label: "DPD 60-89",
    identifier: "late_60_89_balance",
    backgroundColor: cyan[6],
    stack: 1,
  },
  {
    label: "DPD 90-119",
    identifier: "late_90_119_balance",
    backgroundColor: blue[4],
    stack: 1,
  },
  {
    label: "DPD 120+",
    identifier: "late_120_balance",
    backgroundColor: blue[6],
    stack: 1,
    vdr: true,
  }
];

export const redirectionMapOfDatasets: any = {
  "DPD 1-29": "1 - 29 Days Delinquent",
  "DPD 30-59": "30 - 59 Days Delinquent",
  "DPD 60-89": "60 - 89 Days Delinquent",
  "DPD 90-119": "90 - 119 Days Delinquent",
  "DPD 120+": ">= 120 Days Delinquent",
}