import { formatters } from '../../../../../../../../helpers';

export const groupedColumnsFar: any = {
  'property_information': [
    {
      label: 'Property ID (M)',
      key: 'property_id_m',
      align: 'center',
    },
    {
      label: '# Units',
      key: 'units',
      align: 'center',
      render: (value: any, ) => Math.floor(value)
    },
    {
      label: 'Municipality',
      key: 'municipality',
      align: 'center',
    },
    {
      label: 'Metropolitan Area',
      key: 'metropolitan_area',
      align: 'center',
    },
    {
      label: 'Country',
      key: 'country',
      align: 'center',
    },
    {
      label: 'Deal Type',
      key: 'deal_type',
      align: 'center',
    },
    {
      label: 'Deed Type',
      key: 'deed_type',
      align: 'center',
    },
  ],
  'pp': [
    {
      label: 'Purchase Price',
      key: 'purchase_price',
      align: 'center',
    },
  ],
  'financed_costs': [
    {
      label: 'Down-Payment',
      key: 'down_payment',
      align: 'center',
    },
    {
      label: 'Outstanding Purchase Price',
      key: 'outstanding_purchase_price',
      align: 'center',
    },
    {
      label: 'Purchase Price-Surplus',
      key: 'purchase_price_surplus',
      align: 'center',
    },
    {
      label: 'Renovation Company',
      key: 'renovation_company',
      align: 'center',
    },
    {
      label: 'Renovation Partner',
      key: 'renovation_partner',
      align: 'center',
    },
    {
      label: 'Extra Renovation Buyer',
      key: 'extra_renovation_buyer',
      align: 'center',
    },
    {
      label: 'Extra Renovation Structural',
      key: 'extra_renovation_structural',
      align: 'center',
    },
    {
      label: 'Notary at Acquisition - CR',
      key: 'notary_at_acquisition_cr',
      align: 'center',
    },
    {
      label: 'Notary at Acquisition - RE',
      key: 'notary_at_acquisition_re',
      align: 'center',
    },
    {
      label: 'Notary at Acquisition - Gamma',
      key: 'notary_at_acquisition_gamma',
      align: 'center',
    },
    {
      label: 'Transfer Tax',
      key: 'transfer_tax',
      align: 'center',
    },
    {
      label: 'Insurance',
      key: 'insurance',
      align: 'center',
    },
  ],
  'other_costs': [
    {
      label: 'Property Management',
      key: 'property_management',
      align: 'center',
    },
    {
      label: 'Due Diligence',
      key: 'due_diligence',
      align: 'center',
    },
    {
      label: 'Notary at Sale - CR',
      key: 'notary_at_sale_cr',
      align: 'center',
    },
    {
      label: 'Notary at Sale - RE',
      key: 'notary_at_sale_re',
      align: 'center',
    },
    {
      label: 'Notary at Sale - Gamma',
      key: 'notary_at_sale_gamma',
      align: 'center',
    },
    {
      label: 'Agency fee at Acquisition',
      key: 'agency_fee_at_acquisition',
      align: 'center',
    },
    {
      label: 'Agency fee at Sale',
      key: 'agency_fee_at_sale',
      align: 'center',
    },
    {
      label: 'Other Costs',
      key: 'other_costs',
      align: 'center',
    },
  ],
  'total': [
    {
      label: 'New Borrowing Base',
      key: 'theta_new_borrowing_base',
      align: 'center',
    },
    {
      label: 'LTC Senior',
      key: 'ltc_senior',
      align: 'center',
      render: (value: any) => formatters.percent(value),
    },
    {
      label: 'LTC Mezzanine',
      key: 'ltc_mezzanine',
      align: 'center',
      render: (value: any) => formatters.percent(value),
    },
    {
      label: 'Total Senior Borrowing Base',
      key: 'total_senior_borrowing_base',
      align: 'center',
    },
    {
      label: 'Total Mezzanine Borrowing Base',
      key: 'total_mezzanine_borrowing_base',
      align: 'center',
    },
    {
      label: 'Total Junior Borrowing Base',
      key: 'total_junior_borrowing_base',
      align: 'center',
    },
    {
      label: 'Total New Purchase Price',
      key: 'theta_total_new_purchase_price',
      align: 'center',
    },
    {
      label: 'Total New Financed Costs',
      key: 'theta_total_new_financed_costs',
      align: 'center',
    },
    {
      label: 'Total New Financed Amounts',
      key: 'total_new_financed_amounts',
      align: 'center',
    },
    {
      label: 'Total New Other Costs',
      key: 'theta_total_new_other_costs',
      align: 'center',
    },
    {
      label: 'Total New Agency Property Allocated Ledger Amount',
      key: 'total_new_agency_property_allocated_ledger_amount',
      align: 'center',
    },
  ],
};