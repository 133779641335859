import React from 'react';
//Components
import { message } from "antd";
import Input from "../../input";

const ContactUsContent = () => {

  const onContactUs = (event: any) => {
    event.preventDefault();
    message.info("Under development! Please reach out to securitization@cardoai.com");
  }

  return (
    <div className="inner-container contact-us">
      <form autoComplete="off" onSubmit={onContactUs}>
        <h1 className="title">JOIN US</h1>
        <p className="subtitle">Enter your information below to request a demo</p>
        <span className="label">Full Name</span>
        <Input type="text" required placeholder=""/>
        <span className="label">Email</span>
        <Input required placeholder=""/>
        <span className="label">Company</span>
        <Input required placeholder=""/>
        <div className="button-container">
          <a className="button" id="main-button">
            Login
          </a>
          <button className="button button--active">
            Contact us
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUsContent;