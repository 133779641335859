//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../../assets/theme/colors";

const UploadLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 180px;
  height: 100%;
  text-align: center;
  border: 1px dashed ${theme.palette.grayscale[2]};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: border-color .3s;
  transition: border-color .3s;

  :hover {
    border-color: ${theme.colors.primary};
  }
`

const containerStyles = {
  layout: {
    padding: 8,
    paddingTop: 20,
    minHeight: 340
  },
  form: {
    marginTop: 4
  },
  upload: {
    marginTop: 8,
  },
  icon: {
    fontSize: 32,
    marginBottom: 4,
    color: theme.colors.blue
  }
}


const fileStyles: any = {
  layout: {
    padding: 8,
    marginTop: 8,
    minHeight: 64,
    borderRadius: 4,
    cursor: 'default',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grayscale[2]}`,
  },
  file: {
    fontSize: 20,
    color: theme.colors.mineshaft
  },
  delete: {
    fontSize: 14,
    color: theme.colors.mineshaft,
    cursor: 'pointer'
  },
  text: {
    color: theme.colors.mineshaft,
    fontSize: 14,
    maxWidth: 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}


export {
  fileStyles,
  UploadLayout,
  containerStyles
}