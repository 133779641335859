import React from 'react';
import { Input } from "antd";

const InputOperator = (props: any) => {
  return (
    <Input
      {...props}
      placeholder="Enter Value"
      onChange={(e: any) => props.onChange(e.target.value)}
    />
  );
};

export default InputOperator;