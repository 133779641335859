import React from 'react';
//Other Libs
import clone from "clone";
import { Parser } from "json2csv";
import html2canvas from "html2canvas";
//Theme and Styling
import { useTheme } from "styled-components";
import { theme } from "../../../../../assets/theme/colors";
//Components
import { Col, message, Row, Skeleton } from "antd";
//Custom Components
import Layout from "./layout";
import ChartHeader from "./chart_header";
import Graph from "../../multiple/graph";
import ChartVisualisation from "./visualisation";
import { ChartContent } from "../../../../index";
import { SimpleEmpty } from "@cardoai/components"
//Custom Helpers
import { copyArray, downloadCSVFile, formatters, sort } from "../../../../../helpers";
import { createGradient, defaultOptions, prepareSheet, sortItems } from "./helpers";
import { withFullscreen } from "../../../../../utils";
import { getElementAtEvent, } from 'react-chartjs-2';
import 'chart.js/auto';
//Plugins
import annotation from 'chartjs-plugin-annotation';
import dataLabels from 'chartjs-plugin-datalabels';
import dragPoints from 'chartjs-plugin-dragdata'

interface SmartChartProps {
  types?: any,
  height?: any,
  data?: any,
  title?: any,
  displayLabels?: any,
  format?: any,
  customFormatter?: any,
  legendSize?: any,
  fixedDimensions?: any,
  showLegend?: any,
  customHeight?: any,
  initialEntities?: any,
  hoverOnLegend?: any,
  onClick?: any,
  sortAsc?: any,
  fillLine?: any,
  labelEntity?: any,
  valueEntity?: any,
  getLabel?: any,
  getValue?: any,
  maxLegendItems?: any,
  displayTreeLabels?: any,
  colors?: any,
  innerDatasets?: any,
  usesMenu?: any,
  allowGrouping?: any,
  allowSnapshot?: any,
  allowFullscreen?: any,
  allowCSVDownload?: any,
  allowDistribution?: any,
  allowExcelDownload?: any,
  fullscreen?: any,
  openFullscreen?: any,
  fullscreenHeight?: any,
  onExcelDownload?: any
}

const SmartChart = (props: SmartChartProps) => {

  //General Props
  const {
    types, height, data, title, displayLabels, format, customFormatter, legendSize, fixedDimensions,
    showLegend, customHeight, initialEntities = {}, hoverOnLegend, onClick, sortAsc, fillLine,
    labelEntity, valueEntity, getLabel, getValue, maxLegendItems, displayTreeLabels, colors, innerDatasets,
    onExcelDownload
  } = props;

  //Props for the Fullscreen
  const {fullscreen, openFullscreen, fullscreenHeight} = props;

  //Props for the Menu Options
  const {
    usesMenu, allowGrouping, allowSnapshot, allowFullscreen, allowCSVDownload, allowDistribution,
    allowExcelDownload
  } = props;

  const theme: any = useTheme();
  const reference = React.useRef();
  const multipleChartOptions = Array.isArray(types) && types.length > 1;

  const prepareInitialSelection = () => {
    return {
      chart: types[0],
      grouping: allowGrouping,
      distribution: initialEntities.distribution,
    }
  }

  const [selected, setSelected] = React.useState(prepareInitialSelection());
  const {chart: chartType, distribution, grouping: computeOthers} = selected;
  const distributionEntities = {distribution};


  const Chart_Helpers = {
    isTreeChart: function () {
      return chartType === 'tree'
    },
    isCircular: function () {
      return ['doughnut', 'pie'].includes(chartType);
    },
    displayLabels: function () {
      if (displayLabels) {
        if (Chart_Helpers.isTreeChart())
          return false;
        return 'auto';
      }
      return false
    },
    getValidOptions: function () {
      if (multipleChartOptions && types.length === 1)
        return [];
      else if (multipleChartOptions)
        return types;
      else
        return undefined;
    },
    chartFormatter: function () {

      function customPercentageFormatter(value: any) {
        if (Chart_Helpers.isCircular() && value <= 0.1)
          return formatters.percent(value, 1);
        return formatters.percentNoPrecision(value);
      }

      let chartFormat = formatters.default;

      if (customFormatter) {
        chartFormat = customFormatter(distributionEntities, chartType);
      } else if (format) {
        if (format.name === 'percentNoPrecision')
          chartFormat = customPercentageFormatter;
        else
          chartFormat = format;
      }
      return chartFormat;
    },
    visibleLegend: function () {
      return showLegend && Chart_Helpers.isCircular();
    },
    handleChartClick: function (event: any) {
      const element = getElementAtEvent(reference.current, event);

      if (onClick && element.length) {
        if (!element.length) return;
        const {index} = element[0];

        const currentLabel = data[index][labelEntity]
        return (typeof onClick === "function") ? onClick(currentLabel) : null;
      }
    },
    handleLegendClick: function (label: any) {
      if (!onClick)
        return null;
      return (typeof onClick === "function") ? onClick(label) : null;
    },
    onSnapshotDownload: function () {
      const chartElement: any = document.getElementById("chart-content");
      message.info("Download in progress. Please wait...");
      setTimeout(() => {
        html2canvas(chartElement, {
          ignoreElements: (element) => {
            return element.className === "ant-col menu-actions";
          }
        }).then(function (canvas) {
          const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
          const a = document.createElement("a");
          a.setAttribute("download", `Screenshot.png`);
          a.setAttribute("href", image);
          a.click();
        });
      }, 500)
    },
    onCSVDownload: function () {
      const fileData = prepareSheet(data);
      try {
        const parser = new Parser();
        const csv = parser.parse(fileData);
        downloadCSVFile({title: title || "File", file: csv});
      } catch (e) {
        console.error('export error');
      }
    }
  };

  let chartData = data;
  const chartLabels: any = [];
  const chartDataset: any = [];
  const chartColors: any = [];
  const chartFormatter: any = Chart_Helpers.chartFormatter();

  //Default chart Options
  const chartOptions: any = {
    plugins: {
      datalabels: {
        display: Chart_Helpers.displayLabels(),
        color: theme.darkMode ? "#fafafa" : '#595959',
        formatter: (value: any) => {
          return chartFormatter(value)
        },
        font: {
          size: 12,
          style: 'normal',
        },
        clamp: false,
        align: 'end',
        anchor: 'end',
        padding: {
          top: 3,
          bottom: 3,
          right: 3,
          left: 3,
        }
      },
      outlabels: {
        display: false
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function () {
            return '';
          },
          label: function (tooltipItem: any) {
            const {label, raw} = tooltipItem;
            return `${label}: ${chartFormatter(raw)}`;
          },
        },
        titleFontSize: 12,
        bodyFontSize: 12,
        displayColors: true
      },
    },
    ...defaultOptions,
  }
  /*Custom Modification of chart Options */
  if (!Chart_Helpers.isCircular()) {
    chartOptions.scales = {
      x: {
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: false
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false
        },
        ticks: {
          callback: (value: any) => {
            if (typeof value !== "string")
              return chartFormatter(value);
            return value;
          },
        },
      }
    }
    chartOptions.elements = {
      line: {
        fill: fillLine,
        tension: .5,
        backgroundColor: "rgba(244, 144, 128, 0.8)"
      },
      point: {
        radius: 0
      }
    };
    chartOptions.plugins.datalabels.display = false;
  }

  if (sortAsc)
    chartData = sortItems(data, getValue, valueEntity, distributionEntities);


  function retrieveValue(record: any) {
    return getValue ? getValue(record, distributionEntities) : record[valueEntity];
  }

  function retrieveLabel(record: any) {
    return getLabel ? getLabel(record) : record[labelEntity];
  }

  function retrieveColor(index: any) {
    return index < colors.length ? colors[index] : null;
  }

  chartData.forEach((record: any, index: any) => {
    const value = retrieveValue(record);
    const label = retrieveLabel(record);
    const color = retrieveColor(index);

    function populateDataSet(value: any, label: any) {
      chartDataset.push(value);
      chartLabels.push(label)
      chartColors.push(color);
    }

    /*Make computations for all records*/
    populateDataSet(value, label);
  })

  const getChartData = () => {
    let entity = "data";

    if (Chart_Helpers.isTreeChart())
      entity = 'tree';

    let records = copyArray(chartDataset);

    const treeFormat = entity === 'tree' && displayTreeLabels;

    if (treeFormat) {
      records = chartDataset.map((value: any, index: any) => ({
        value: value,
        title: chartLabels[index]
      }));
      sort(records, 'value');
    }

    const dataSet: any = {};
    dataSet[entity] = records;

    if (treeFormat) {
      dataSet.groups = ['title'];
      dataSet.key = 'value';
      dataSet.fontColor = '#fff'
      dataSet.fontSize = 10;
      dataSet.spacing = 0.1;
      dataSet.borderWidth = 2
    }

    if (chartType === 'line') {
      dataSet.pointBackgroundColor = chartColors;
      dataSet.backgroundColor = createGradient();
    } else {
      dataSet.backgroundColor = chartColors;
      dataSet.hoverBackgroundColor = chartColors;
    }

    const config = {
      labels: chartLabels,
      datasets: [dataSet]
    };

    if (Chart_Helpers.isTreeChart())
      delete config.labels;

    return config;
  };

  const chartMenuOptions = {
    allowDistribution,
    allowGrouping,
    allowSnapshot,
    allowFullscreen,
    allowExcelDownload,
    allowCSVDownload,
  };

  const chartDownloadOptions = {
    onExcelDownload: onExcelDownload,
    onSnapshotDownload: Chart_Helpers.onSnapshotDownload,
    onCSVDownload: Chart_Helpers.onCSVDownload
  };

  const fullscreenHeightAdjusted = 8 / 10 * fullscreenHeight

  const chartFullScreenOptions = {
    fullscreen,
    fullscreenHeightAdjusted,
    onFullscreen: openFullscreen
  }

  const Chart_Dimensions = {
    size: function () {
      return 24 - legendSize;
    },
    height: function () {
      return Chart_Helpers.isCircular() ? height : height + customHeight;
    },
    standard: function () {
      return Chart_Helpers.visibleLegend() && !fullscreen
    }
  };

  const layoutStyle: any = {};

  if (fixedDimensions) {
    layoutStyle.display = "flex";
    layoutStyle.flexDirection = "row";

    chartOptions.maintainAspectRatio = false;
    chartOptions.responsive = true;
  }

  const fullScreenOptions = clone(chartOptions);
  fullScreenOptions.plugins.datalabels.font.size = 18;
  fullScreenOptions.plugins.tooltip.bodyFontSize = 18;
  fullScreenOptions.plugins.tooltip.titleFontSize = 18;

  if (innerDatasets && !data)
    return <Skeleton active/>;

  if (innerDatasets && !data.length)
    return <SimpleEmpty centered height={285} description={title ? `No Data for ${title}` : 'No Data'}/>;

  let SmartChartContent;

  if (innerDatasets) {
    SmartChartContent = (
      <Layout center={fullscreen} ref={reference} style={layoutStyle}>
        <ChartHeader
          chartTitle={title}
          usesMenu={usesMenu}
          selected={selected}
          onChange={setSelected}
          menuOptions={chartMenuOptions}
          downloadOptions={chartDownloadOptions}
          fullscreenOptions={chartFullScreenOptions}
          chartMenu={Chart_Helpers.getValidOptions()}/>
        <Row gutter={8}>
          <Col lg={24}>
            <ChartContent fixedDimensions={fixedDimensions}
                          height={fullscreen ? fullscreenHeightAdjusted : Chart_Dimensions.height()}>
              <ChartVisualisation
                data={data}
                type={chartType}
                reference={reference}
                identifier={"chart-content"}
                fullscreenMode={fullscreen}
                chartConfig={getChartData()}
                fixedDimensions={fixedDimensions}
                height={Chart_Dimensions.height()}
                chartSize={Chart_Dimensions.size()}
                isCircular={Chart_Helpers.isCircular()}
                onChartClick={Chart_Helpers.handleChartClick}
                plugins={[annotation, dataLabels, dragPoints]}
                onLegendClick={Chart_Helpers.handleLegendClick}
                options={!fullscreen ? chartOptions : fullScreenOptions}
                legendConfigurations={{
                  showLegend,
                  hoverOnLegend,
                  computeOthers,
                  maxLegendItems,
                  formatter: chartFormatter
                }}
                {...chartFullScreenOptions}
              />
            </ChartContent>
          </Col>
        </Row>
      </Layout>
    )
  } else {
    SmartChartContent = <Graph {...props}/>
  }

  return SmartChartContent;
}

SmartChart.defaultProps = {
  data: [],
  title: "",
  height: 240,
  format: formatters.default,
  types: ['doughnut'],
  displayLabels: true,
  legendSize: 12,
  fixedDimensions: false,
  showLegend: true,
  customHeight: 50,
  hoverOnLegend: true,
  fillLine: true,
  sortAsc: true,
  maxLegendItems: 6,
  displayTreeLabels: true,
  colors: theme.palette.diversificationChartColors,
  allowGrouping: false,
  allowSnapshot: false,
  allowFullscreen: false,
  allowCSVDownload: false,
  allowDistribution: false,
  allowExcelDownload: false,
  innerDatasets: true,
  initialEntities: {
    distribution: 'percentage'
  },
}
export default withFullscreen(SmartChart);