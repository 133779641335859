import React from 'react';
//Custom Components
import { DefinitionList } from "../../../components";

const Glossary = () => {
  return (
    <DefinitionList
      header='The Glossary provides a guide to the fields on the Loans Management table.'
      data={[
        {term: 'Current Balance', explanation: 'Principal balance of the loan at reporting date'},
        {term: 'Loans #', explanation: 'Total number of loans (including both Open and Closed loans)'},
        {term: 'Outstanding #', explanation: 'Number of loans outstanding (Open loans only)'},
        {
          term: 'WAM (years)',
          explanation: 'Average residual time to maturity of the loans weighted by Current Balance'
        },
        {term: 'WALA (years)', explanation: 'Average Loan age (from inception) weighted by Current Balance '},
        {term: 'WAR (%)', explanation: 'Average Loan Gross Return weighted by Current Balance'},
        {term: 'Late #', explanation: 'Number of loans with at least 1 Days Past Due'},
        {term: 'Late Amount', explanation: 'Total Current Balance of Late loans '},
        {term: 'DPD (1 - 29)', explanation: 'Total Current Balance of loans with 1 - 29 Days Past Due'},
        {term: 'DPD (30 - 59)', explanation: 'Total Current Balance with 30 - 59 Days Past Due'},
        {term: 'DPD (60 - 89)', explanation: 'Total Current Balance with 60 - 89 Days Past Due'},
        {term: 'DPD (80 - 129)', explanation: 'Total Current Balance with 90 - 119 Days Past Due'},
        {term: 'DPD (120+)', explanation: 'Total Current Balance with >= 120 Days Past Due'},
        {term: 'Avg. Realized Maturity/DSO', explanation: 'Average Realized Maturity / DSO weighted by Loan Amount'},
        {term: 'SPV', explanation: 'Name of the SPV/Transaction managing the loan'},
        {term: 'Loan ID', explanation: 'ID of the loan as received by servicer/originator'},
        {
          term: 'Debtor Name', explanation: 'Business Name of the debtor (Borrowe for loan based transactions, ' +
            'Buyer in case of trade receivable related transactnios)'
        },
        {term: 'Loan Status', explanation: ''},
        {term: 'Loan Type', explanation: 'Status of the Loan: Open/Closed'},
        {term: 'Sector', explanation: 'Sector of the Borrower (based on available sector classification)'},
        {term: 'Country', explanation: 'Debtor Country'},
        {term: 'Region', explanation: 'Debtor Region'},
        {term: 'City', explanation: 'Debtor City'},
        {term: 'Issue Date', explanation: 'Original issue date of the loan'},
        {term: 'Loan Age (years)', explanation: 'Loan age (from Issue Date to Reporting Date) '},
        {term: 'Original Balance', explanation: 'Loan amount at Issue Date'},
        {term: 'Pool Addition Date', explanation: 'Date in which the loan has been purchased to the SPV'},
        {
          term: 'Investment Amount', explanation: 'Loan principal balance acquired by SPV at Pool Addition Date ' +
            '(not considering the price/discount)Loan principal balance acquired by SPV at ' +
            'Pool Addition Date (not considering the price/discount)'
        },
        {term: 'Price', explanation: 'Price (in %) paid by the SPV to acquire the loan'},
        {term: 'Maturity date', explanation: 'Maturity date of the loan (as per last Modification, if any)'},
        {term: 'performance status', explanation: 'performance status of the loan at reporting date'},
        {
          term: 'Residual Maturity (years)',
          explanation: 'Residual maturity of the loan - years to the Maturity Date'
        },
        {
          term: 'Avg. Residual Maturity (years)', explanation: 'Weighted Average Residual Maturity of the loan ' +
            '(based on the applicable Amortisation Plan)'
        },
        {term: 'DPD', explanation: 'Days Past Due - number of days after the maturity date'},
        {term: 'Current Balance', explanation: 'Principal balance of the loan at reporting date'},
        {
          term: 'Loan Gross Return',
          explanation: 'Gross interest rate of the loan (including fees and price) for the SPV'
        },
        {term: 'Coupon', explanation: 'Expected Interest rate to be paid to the SPV during the reporting period'},
        {term: 'Rating', explanation: 'Rating of the loan'},
        {
          term: 'Primary collateral',
          explanation: 'Description of the Primary collateral_section (i.e. most relevant one in ' +
            'case of multiple collaterals) securing the Loan (include both real assets and guarantees)'
        },
        {
          term: 'Primary collateral coverage (%)',
          explanation: 'Primary collateral coverage as % of current loan balance'
        },
        {term: 'Modified Date', explanation: 'Date of the last modification of the loan'},
        {term: 'Modified Type', explanation: 'Type of modification applied at the last modification date:\n'},
        {term: 'Modification Reason', explanation: 'Reason of the modification'},
        {
          term: 'Pre-Modification performance status',
          explanation: 'performance status of the loan before the modification'
        },
        {term: 'Modification End Date', explanation: 'Date on which the modification ended / will end'},
        {
          term: 'Number of Times Modified',
          explanation: 'Number of time the loan has been modified (0 = never modified)'
        },
        {term: 'Seller Name', explanation: 'Name of the seller'},
        {term: 'Seller VAT', explanation: 'VAT of the seller'},
        {
          term: 'Closing date',
          explanation: 'Date on which the loan has been closed (either fully paid or in write-off)'
        },
        {
          term: 'Realized Maturity / DSO (years)',
          explanation: 'Realized maturity of the loan from Issue Date to Closing Date'
        },
        {term: 'Payment delays (days)', explanation: 'Payment Delays from Original Maturity to Closing Date'},
        {term: 'Cumulated Installment Amount', explanation: 'Cumulated instalments since Pool Addition Date'},
        {
          term: 'Cumulated Installment Amount - Principal',
          explanation: 'Cumulated principal instalments since Pool Addition Date'
        },
        {
          term: 'Cumulated Installment Amount - Interest',
          explanation: 'Cumulated interest instalments since Pool Addition Date'
        },
        {term: 'Avg. Historical Balance', explanation: 'Average historical amount of the loan'},
        {term: 'Gross Return', explanation: 'Realized Gross Return (Closed loans only)'},
        {term: 'Cumulated Loss', explanation: 'Cumulated amount of losses recorded'},
        {term: 'First Arrears Date', explanation: 'Date on which first arrear has been recorded'},
        {
          term: 'Arrears Balance',
          explanation: 'Total amount of arrears (cumulated), sum of Capital Arrears and Interest Arrears'
        },
        {term: 'Capital Arrears', explanation: 'Amount capital arrears (cumulated)'},
        {term: 'Interest Arrears', explanation: 'Amount of interest arrears (cumulated)'},
      ]}
    />
  );
};

export default Glossary;
