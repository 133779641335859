import React from 'react';
//Custom Components
import { CustomImage, MessageBox, Section } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const OtherOptions = () => {
  return (
    <>
      <div>
        <CustomImage
          src={assets.images.loansModule.otherOptions}
          alt="Loans Other Options"
        /><br/>
        <Section
          header="Excel Download"
          body="The User can also download all the information shown by the module (with or without filters selected)
          as an Excel file format. Depending on the number of loans selected, the download is executed
          instantaneously (file can be found in the default download folder) or generated by the system and made
          available with a link sent to the email associated to the Users."
        />
        <br/>
        <CustomImage
          alt="Arrange Columns"
          isStyled={false}
          src={assets.images.loansModule.arrangeColumns}
          description="The user can arrange the position of the columns (the whole category or even columns inside a specific category).
            He can remove (and add them later on) the category or specific columns inside a category if he does not want them to be shown in the table."
        />
        <br/>
        <Section
          header="Sorting"
          body="The User can sort loans in ascending or descending order. Sorting order can be based on each column and
          also combination of columns (e.g. descending sorting by Sector and ascending sorting by Current Balance)."
        />
        <br/>
        <MessageBox
          title="Note"
          description="The order of sorting affects the visualization. For example, choosing the descending order by
          City will be the primary sorting field and the other ascending/descending fields will be dependent on the initial one."/>
      </div>
    </>
  );
};

export default OtherOptions;
