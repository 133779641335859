//Components
import { EmojiBreach } from "../../../../../../../../../../components";
import FourDigitLetterInfo from "./four_digit_letter_ateco_information";
//Helpers
import { formatters } from "../../../../../../../../../../helpers";

const baseColumns = (currency: any, currencyPrecision: number = 0) => [
  {
    key: "value",
    label: "Threshold",
    format: (value: any) => formatters.currency({value, currency, precision: currencyPrecision}),
  },
  {
    key: "fulfillment",
    label: "Realized",
    format: (value: any) => formatters.currency({value, currency, precision: currencyPrecision}),
  },
  {
    key: "broken",
    label: "Check",
    Component: EmojiBreach,
  },
  {
    key: "available_amount",
    label: "Available",
    format: (value: any) => formatters.currency({value, currency, precision: currencyPrecision}),
  },
];

export const ratingLimitsColumns = (firstColumnLabel: any, currency: any) => [
  {
    key: "name",
    label: firstColumnLabel,
  },
  ...baseColumns(currency),
];

export const ATECOLimitsColumns = (firstColumnLabel: any, currency: any, vehicleId:any, proposalId:any,urlParam:any) => [
  {
    key: "name",
    label: firstColumnLabel,
  },
  ...baseColumns(currency),
  {
    key: 'actions',
    label: 'Action',
    currency: currency,
    vehicleId: vehicleId,
    proposalId: proposalId,
    urlParam:urlParam,
    Component: FourDigitLetterInfo,
  },
];

export const fourDigitLetterATECOInfoColumns = (currency: any) => [
  {
    key: 'four_digit_ateco',
    label: 'Four Digit Ateco',
    align: 'center',
  },
  ...baseColumns(currency, 2),
];

const Description = ({children}: any) => {
  return (
    <p style={{maxWidth: 500}}>
      {children}
    </p>
  )
}

export const ATECOContent = (
  <Description>
    L’Importo Dovuto dei Crediti ricompresi nel Portafoglio Aggregato, inclusi quelli ricompresi nel Portafoglio
    offerto in cessione, dovuta da Debitori:
    <ul>
      <li> Appartenenti alla medesima categoria ATECO, a partire dalla prima Data di Valutazione dei Limiti di Acquisto
        (inclusa) successiva alla Data di Valutazione in cui l’Importo Dovuto del Portafoglio Aggregato è pari o
        superiore
        ad Euro 20.000.000,00 non sia superiore al 25% del Importo Dovuto del Portafoglio Aggregato alla relativa Data
        di
        Valutazione;
      </li>
    </ul>

  </Description>
);

export const RatingContent = (
  <Description>
    L’Importo Dovuto dei Crediti ricompresi nel Portafoglio Aggregato, inclusi quelli ricompresi nel Portafoglio
    offerto in cessione, dovuta da Debitori:
    <ul>
      <li>Aventi Classe di rating CARDO pari a BB o B, a partire dalla prima Data di Valutazione dei Limiti di Acquisto
        (inclusa) successiva alla Data di Valutazione in cui l’Importo Dovuto del Portafoglio Aggregato è pari o
        superiore ad Euro 20.000.000,00 non sia superiore, per ciascuna delle due classi di rating considerate
        separatamente, al 30% dell’Importo Dovuto del Portafoglio Aggregato alla relativa Data di Valutazione;
      </li>
      <li>Aventi Classe di rating CARDO pari a CCC non sia superiore al 5% dell’Importo Dovuto del Portafoglio Aggregato
        comprensivo del Portafoglio offerto in cessione alla relativa Data di Valutazione, tenuto conto che:
        <ul>
          <li>A partire dalla Prima Data di Valutazione e fino alla Data di Valutazione in cui l’importo Dovuto del
            Portafoglio Aggregato sia inferiore ad Euro 10.000.000,00, l’esposizione massima verso Debitori con Classe
            di Rating CCC non sia superiore ad Euro 200.000;
          </li>
          <li>A partire dalla Data di Valutazione in cui l’importo Dovuto del Portafoglio Aggregato sia superiore o
            uguale ad Euro 10.000.000,00 fino alla Data di Valutazione in cui l’importo Dovuto del Portafoglio Aggregato
            sia pari ad Euro 20.000.000,00 l’esposizione massima verso Debitori con Classe di Rating CCC non sia
            superiore ad Euro 500.000.
          </li>
        </ul>
      </li>
    </ul>
  </Description>
);
