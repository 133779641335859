//Formatters
import { formatters } from "../../../../helpers";

export const mainStatistics = (currency: any) => [
  {
    key: "reference_date",
    label: "Reference Date",
    format: formatters.date
  },
  {
    key: "last_proposal_date",
    label: "Last Proposal Date",
    format: formatters.date
  },
  {
    key: "aggregate_portfolio",
    label: "Aggregate Portfolio",
    format: (value: any) => formatters.currency({value, currency})
  },
  {
    key: "cumulated_loan_drawdown",
    label: "Cumulated Loan drawdown",
    format: (value: any) => formatters.currency({value, currency})
  },
  {
    key: "available_funds_remaining",
    label: "Available Funds Remaining",
    format: (value: any) => formatters.currency({value, currency})
  }
];