//Components
import Table from "./table";
//Helpers
import { withData } from "../../../../utils";

export default withData({
  extraParams: ["queryStr", "vehicleId", "selectedLender"],
  urlFormatter: (config: any) => {
    const {props} = config;
    const {vehicleId, queryStr, selectedLender} = props;
    let url = '/main/debtor/top_debtors/?';

    if (queryStr)
      url = `${url}${queryStr}`;
    if (vehicleId)
      url = `${url}&vehicle_id=${vehicleId}`
    if (selectedLender)
      url = `${url}&lender=${selectedLender}`

    return url;
  }
})(Table);
