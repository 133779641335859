//Utils
import { withData } from "../../../../utils";
//Custom Components
import ChartContent from "./chart_content";
//Helpers
import { isEmpty } from "../../../../helpers"

export default withData({
  extraParams: ["queryStr", "vehicleId", "selectedLender"],
  formatResponse: (response: any) => {
    if (!isEmpty(response))
      response.loan_rating.reverse()
    return response;
  },
  urlFormatter: (config: any) => {
    const {props} = config;
    const {vehicleId, queryStr, selectedLender} = props;
    let url = '/main/loan_distribution/loan_diversification/?'

    if (queryStr)
      url = `${url}${queryStr}`;
    if (vehicleId)
      url = `${url}&vehicle_id=${vehicleId}`
    if (selectedLender)
      url = `${url}&lender=${selectedLender}`

    return url;
  },
})(ChartContent)

