import React from 'react';
//Icons
import { DownloadOutlined } from "@ant-design/icons";
//Components
import { Button, Row } from "antd";
//Custom Components
import { DownloadAsCSV, SmartTable } from "../../../../components";
//Configuration
import staticData from "./borrowers.json";
import { columnGroups, config } from "./config";


const NPLCounterparty = ({onDownload}: any) => {

  return (
    <>
      <Row className="mr8" align="middle" justify="end">
        <DownloadAsCSV onDownload={onDownload(staticData, "Borrower")}>
          <Button icon={<DownloadOutlined/>} size="small">Download</Button>
        </DownloadAsCSV>
      </Row>
      <SmartTable allowGrouping height={700} columnGroups={columnGroups} columns={config}
                  externalData={staticData} groupingPosition="start"/>
    </>
  );
};

export default NPLCounterparty;