import React from 'react';
//Components
import { Row, Tag } from "antd";
import { InfoTag } from "@cardoai/components";
//Config
import { config } from "./config";


const Status = ({record}: any) => {
  const status = record.status;

  if (!config[status])
    return status;

  const settings = config[record.status];

  return (
    <Row justify='center'>
      <Tag color={settings.color}>
        {record.status}
      </Tag>
      <InfoTag title={settings.explanation}/>
    </Row>

  );
};

export default Status;