import React from 'react';
//Components
import { Collapse, Input, Switch } from "antd";
//Custom Components
import { AssetDetails, FundSelection } from "./components";
import { FlatList } from "@cardoai/components";
//Dummy Data
import { COLLATERAL, fundSelection, headerStatistics } from "./config";
//Colors
import { SearchOutlined } from "@ant-design/icons";
import { theme } from "../../assets/theme/colors";

const {Panel} = Collapse;

const CollateralManagement = () => {

  const [searchInput, setSearchInput] = React.useState<any>('');
  const [visibleAssets, setVisibleAssets] = React.useState<any>(COLLATERAL);

  const searchById = (event: any) => {
    setSearchInput(event.target.value)
  }

  const updateAssetList = () => {
    const updatedList: any = [];
    COLLATERAL.filter((asset: any) => {
      const id = asset.id.toString();
      if (id.indexOf(searchInput) > -1)
        updatedList.push(asset);
    })
    setVisibleAssets(updatedList);
  }

  React.useEffect(() => {
    updateAssetList();
  }, [searchInput])

  return (
    <>
      <FundSelection data={fundSelection}/>
      <div className="mb12">
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Main Statistics" key="1"
                 extra={<Switch onClick={(_, event: any) => event.stopPropagation()} checkedChildren="Outstanding"
                                unCheckedChildren="Closed" defaultChecked/>}>
            <FlatList
              size='medium'
              elementWidth={180}
              scrollable={false}
              data={headerStatistics.data}
              config={headerStatistics.config}
              background={theme.colors.geekblue2}
            />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Asset Details" key="1"
                 extra={<Input suffix={<SearchOutlined/>} defaultValue={searchInput}
                               onClick={(e) => e.stopPropagation()} onChange={searchById}
                               placeholder="Search by Property/Borrower ID"
                               style={{width: 300}}/>}>
            <AssetDetails visibleAssets={visibleAssets}/>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};


export default CollateralManagement;