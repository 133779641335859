import React from 'react';
//Components
import { Spin } from "antd";
import { CustomTable } from "@cardoai/components";

interface Props {
  loading?: boolean,
  fullHeight?: any,
  head?: any,
  body?: any,
  children?: any,
  getData?: any,
  id?: any
}

const Layout = (props: Props) => {
  const {loading, fullHeight, head, body, children, ...rest} = props;
  return (
    <Spin size='large' spinning={loading}>
      <CustomTable fullHeight={fullHeight} {...rest}>
        <thead>{head}</thead>
        <tbody>{body}</tbody>
      </CustomTable>
      {children}
    </Spin>
  );
};

export default Layout;
