//Helpers
import { formatters } from "../../../../helpers";

export const DOD_Columns: any = (currency: any) => {
  return [
    {
      key: "debtor_id",
      label: "Debtor ID",
      align: 'center',
    },
    {
      key: "debtor_name",
      label: "Debtor Name",
      align: 'center',
    },
    {
      key: "debtor_status",
      label: "Debtor status",
      align: 'center',
    },
    {
      key: "debtor_cf",
      label: "Debtor CF",
      align: 'center',
    },
    {
      key: "past_due_days",
      label: "Past Due Days",
      align: 'center',
      sortable: true,
      format: formatters.thousandsShorted
    },
    {
      key: "cure_period_days",
      label: "Cure Period Days",
      align: 'center',
      sortable: true,
      format: formatters.thousandsShorted
    },
    {
      key: "loan_current_balance",
      label: "Loan Current Balance",
      align: 'center',
      format: (value: any) => formatters.currency({value, currency})
    },
    {
      key: "maturity_date",
      label: "Maturity Date",
      align: 'center',
      format: formatters.date
    },
    {
      key: "evente_suspensione",
      label: "Suspension Events",
      align: 'center',
    },
    {
      key: "suspensione_descp",
      label: "Suspension Description",
      align: 'center',
    },
    {
      key: "reference_date",
      label: "Reference Date",
      align: 'center',
      format: formatters.date
    },
    {
      key: "occurrence_dates_cure_period",
      label: "Occurrence Dates Cure Period",
      align: 'center',
    },
    {
      key: "occurrence_dates_sofferenza",
      label: "Occurrence Dates Default",
      align: 'center',
    }
  ];
}