import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const MainStatistics = () => {
  return (
    <>
      <CustomImage
        alt="Analytics Main Statistics"
        src={assets.images.analyticsModule.aggregatedStatistics}
      />
      <br/>
      <div>
        <DefinitionList
          header="This section shows the following statistics:"
          data={[
            {
              term: 'Current Balance',
              explanation: 'total current balance of the loans'
            },
            {
              term: 'Loans Outstanding #',
              explanation: 'number of loans outstanding'
            },
            {
              term: 'Average Loan Size',
              explanation: 'average size of the loans'
            },
            {
              term: 'WAR (%)',
              explanation: 'Weighted Average Return (including contractual coupon, fees and purchase price), weighted by current balance'
            },
            {
              term: 'WAM (years)',
              explanation: 'Weighted Average Residual Maturity calculated with reference of the maturity date (as eventually revised / changed), weighted by current balance'
            },
            {
              term: 'Average Delay',
              explanation: 'average days of delay of the loans in the perimeter vs the current maturity (as eventually modified or expected) '
            },
            {
              term: 'Avg Delay vs Original Maturity',
              explanation: 'average days of delay of the loans in the perimeter vs the original maturity (not considering eventual modification/expectations)'
            },
            {
              term: 'Delinquency Ratio',
              explanation: '% of delinquent loans (1 or more days delinquent) of the perimeter'
            },
            {
              term: 'Gross Default Ratio',
              explanation: 'total gross defaulted amounts on the initial balance of the relevant perimeter'
            },
            {
              term: 'Conditional Default Ratio',
              explanation: 'total defaulted amounts, netted by recoveries amounts, on the initial balance of the relevant perimeter'
            },
            {
              term: '3m Prepayments Ratio',
              explanation: 'prepayment ratio of the relevant perimeter over the last 3 reported months'
            },
            {
              term: 'LTV',
              explanation: 'loan to value of the relevant perimeter secured with real assets'
            },
          ]}
        >
        </DefinitionList>
      </div>
    </>
  );
};

export default MainStatistics;
