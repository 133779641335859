import {formatters} from "../../../../../../../../../../helpers";
import {EmojiBreach} from "../../../../../../../../../../components";

export const columns: any = (currency: any) => {
  return [
    {
      key: "debtor_name",
      label: "Debtor Name",
      width: 200,
      align: "center",
    },
    {
      key: "value",
      label: "Threshold",
      width: 200,
      align: "center",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    {
      key: "fulfillment",
      label: "Realized",
      width: 200,
      align: "center",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    {
      key: "broken",
      label: "Check",
      width: 200,
      align: "center",
      Component: EmojiBreach,
    },
    {
      key: "available_amount",
      label: "Available Amount",
      width: 200,
      align: "center",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    // {
    //   key: "realized_amount",
    //   label: "Realized Amount",
    //   width: 200,
    //   align: "center",
    //   format: (value: any) => formatters.currency({value, precision: 0, currency})
    // },
  ];
}
