import React from "react"
import BreachWithNotes from "./breach_with_notes";

// Components
export enum CRITERIA_BREACH {
  "COMPLIANT" = "no",
  "WAVERED" = "waivered",
  "BROKEN" = "yes"
}

const tableCriteria: any = (vehicleId: any, proposalId: any, status: any) => {
  return [
    {
      label: 'Transaction ID',
      key: 'transaction_id',
      align: "center"
    },
    {
      label: 'Location',
      key: 'location_breach',
      render: (value: any, tableData: any) => {
        return <BreachWithNotes status={status} proposalId={proposalId} transactionId={tableData.record.transaction_id}
                                criteriaType="location_breach" vehicleId={vehicleId}
                                collateralLogId={tableData.record.collateral_log_id}
                                waiveredValue={value} refreshData={tableData.getData}/>
      }
    },
    {
      label: 'Cadastral Category',
      key: 'cadastral_category_breach',
      render: (value: any, tableData: any) => {
        return <BreachWithNotes status={status} proposalId={proposalId} transactionId={tableData.record.transaction_id}
                                criteriaType="cadastral_category_breach" vehicleId={vehicleId}
                                collateralLogId={tableData.record.collateral_log_id}
                                waiveredValue={value} refreshData={tableData.getData}/>
      }
    },
    {
      label: 'Due Diligence',
      key: 'due_diligence_breach',
      render: (value: any, tableData: any) => {
        return <BreachWithNotes status={status} proposalId={proposalId} transactionId={tableData.record.transaction_id}
                                criteriaType="due_diligence_breach" vehicleId={vehicleId}
                                collateralLogId={tableData.record.collateral_log_id}
                                waiveredValue={value} refreshData={tableData.getData}/>
      }
    },
    {
      label: 'Vacant Property',
      key: 'vacant_property_breach',
      render: (value: any, tableData: any) => {
        return <BreachWithNotes status={status} proposalId={proposalId} transactionId={tableData.record.transaction_id}
                                criteriaType="vacant_property_breach" vehicleId={vehicleId}
                                collateralLogId={tableData.record.collateral_log_id}
                                waiveredValue={value} refreshData={tableData.getData}/>
      }
    },
    {
      label: 'Renovation Budget',
      key: 'renovation_budget_breach',
      render: (value: any, tableData: any) => {
        return <BreachWithNotes status={status} proposalId={proposalId} transactionId={tableData.record.transaction_id}
                                criteriaType="renovation_budget_breach" vehicleId={vehicleId}
                                collateralLogId={tableData.record.collateral_log_id}
                                waiveredValue={value} refreshData={tableData.getData}/>
      }

    },
    {
      label: 'Purchase Price',
      key: 'purchase_price_breach',
      render: (value: any, tableData: any) => {
        return <BreachWithNotes status={status} proposalId={proposalId} transactionId={tableData.record.transaction_id}
                                criteriaType="purchase_price_breach" vehicleId={vehicleId}
                                collateralLogId={tableData.record.collateral_log_id}
                                waiveredValue={value} refreshData={tableData.getData}/>
      },
    },
    {
      label: 'Seller & Rejection',
      key: 'seller_and_rejection_breach',
      render: (value: any, tableData: any) => {
        return <BreachWithNotes status={status} proposalId={proposalId} transactionId={tableData.record.transaction_id}
                                criteriaType="seller_and_rejection_breach" vehicleId={vehicleId}
                                collateralLogId={tableData.record.collateral_log_id}
                                waiveredValue={value} refreshData={tableData.getData}/>
      },
    },
  ]
}

export const prepareCriteriaTableConfig = (param: string, vehicleId: any, proposalId: any, status: any) => {
  return {
    entity: "criteria",
    columns: tableCriteria(vehicleId, proposalId, status),
    endpoints: {
      tableUrl: `/casavo/${param}/eligibility_criteria_limits/`,
      downloadUrl: `/casavo/${param}/download_eligibility_criteria_limits/?`
    },
  }
}
