//Colors
import { theme } from "../../../../assets/theme/colors";
//Helpers
import { formatters } from "../../../../helpers";

export const config: any = [
  // {key: "portfolio", width: 150, label: "Portfolio", group: "General", align: "center"},
  {key: "originator", width: 100, label: "Originator", group: "General", grouping: true},
  // {key: "count_originator", width: 150, label: "Count Originator", group: "General", grouping: true},
  {
    key: "cutoff_date",
    label: "Cutoff Date",
    group: "General",
    grouping: true,
    width: 100,
    format: formatters.safeDate,
  },
  {key: "id_borrower", width: 100, label: "ID Borrower", group: "Borrower Details", grouping: true},
  {key: "borrower_name", width: 350, label: "Borrower Name", group: "Borrower Details", grouping: true},
  {key: "npe_type", label: "NPE Type", group: "Borrower Details", grouping: true},
  {
    key: "utp_to_npl_before_sale",
    width: 150,
    label: "UTP to become NPL before the sale (Y/N)",
    group: "Borrower Details"
  },
  {key: "borrower_counterparty_type", label: "Counterparty Type", group: "Borrower Details", grouping: true},
  // {key: "borrower_type", label: "Borrower Type", group: "Borrower Details"},
  {key: "legal_type_borrower", width: 150, label: "Legal Type of Borrower", group: "Borrower Details"},
  {key: "date_incorporation", label: "Date of Incorporation", group: "Borrower Details",format:formatters.safeDate},
  {key: "vat_code", label: "VAT code", group: "Borrower Details"},
  {
    key: "external_credit_rating",
    width: 150,
    label: "External Credit Rating at Origination",
    group: "Borrower Details"
  },
  {key: "borrower_ranking", label: "Borrower Ranking", group: "Borrower Details"},
  {key: "address", label: "Address", group: "Location",width:200},
  {key: "city", label: "City", group: "Location", grouping: true,width: 200},
  {key: "region", label: "Region", group: "Location", grouping: true},
  {key: "area", label: "Area", group: "Location", grouping: true},
  {key: "country", label: "Country", group: "Location", grouping: true},
  {key: "postcode", label: "Postcode", group: "Location"},
  {key: "ateco_code", label: "ATECO code", group: "Borrower Type"},
  {key: "enterprise_size", label: "Enterprise Size", group: "Borrower Type"},
  {key: "gbv_nr_loans", label: "# of Loans", group: "GBV Details"},
  // {key: "gbv_date", label: "GBV Date", group: "GBV Details"},
  {
    key: "gbv_total_gbv_update",
    format: formatters.thousands,
    width: 150,
    label: "Total GBV up to date",
    group: "GBV Details"
  },
  {
    key: "gbv_secured_gbv_update",
    format: formatters.thousands,
    width: 150,
    label: "Secured GBV up to date",
    group: "GBV Details"
  },
  {
    key: "gbv_unsecured_update",
    format: formatters.thousands,
    width: 150,
    label: "Unsecured GBV up to date",
    group: "GBV Details"
  },
  {key: "re_nr_assets", label: "# of Assets", group: "RE Assets"},
  {
    key: "re_total_assets_value",
    format: formatters.thousands,
    width: 150,
    label: "Total Assets Value",
    group: "RE Assets"
  },
  {
    key: "re_capped_asset_value",
    format: formatters.thousands,
    width: 150,
    label: "Capped Asset Value",
    group: "RE Assets"
  },
  {key: "interim_cashflow", format: formatters.thousands, width: 150, label: "Interim Cashflow", group: "Cash Flow"},
  // {key: "nr_personal_guarantees", width: 150, label: "# of Personal Guarantees", group: "Legal Engagement"},
  {key: "legal_procedure_type", width: 150, label: "Legal Procedure Type", group: "Legal Engagement", grouping: true},
  {key: "rge_procedure", label: "RGE Procedure", group: "Legal Engagement",width: 200},
  {
    key: "legal_seniority",
    format: formatters.percent,
    label: "Seniority on legal procedure or SoW",
    group: "Legal Engagement"
  },
  {key: "court", label: "Court", group: "Legal Engagement", grouping: true,    width: 150},
  {key: "stage_procedure", width: 150, label: "Stage Procedure", group: "Legal Engagement", grouping: true},
  {key: "personal_guarantees", width: 150, label: "Personal Guarantees (Y/N)", group: "Legal Engagement"},
  {key: "flag_special_situation", label: "Flag Special Situation", group: "Legal Engagement", grouping: true},
  {key: 'notes', label: 'Notes', width: 200, group: "Additional Information", grouping: true},
  // {key: "hdr", width: 150, label: "HDR (x)", group: "Legal Engagement", grouping: true},
  // {key: "gbv_for_the_median", width: 150, label: "gbv for the median", group: "Legal Engagement", grouping: true},
]

export const columnGroups: any = [
  {
    key: "General",
    styles: {
      background: theme.palette.border[2],
      borderColor: theme.colors.white,
      color: 'rgba(0, 0, 0, 0.65)'
    },
  },
  {
    key: "Borrower Details",
    styles: {
      background: theme.palette.border[6],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Location",
    styles: {
      background: theme.palette.border[5],
      borderColor: theme.colors.white,
      color: 'rgba(0, 0, 0, 0.65)'
    },
  },
  {
    key: "Borrower Type",
    styles: {
      background: theme.palette.border[6],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "GBV Details",
    styles: {
      background: theme.palette.border[7],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "RE Assets",
    styles: {
      background: theme.palette.border[8],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Cash Flow",
    styles: {
      background: theme.palette.border[9],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Legal Engagement",
    styles: {
      background: theme.palette.border[1],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Additional Information",
    styles: {
      background: theme.palette.border[8],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
];
