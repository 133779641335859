import React from "react";
//Components
import { Popconfirm } from "antd";
//Icons
import { DeleteOutlined } from "@ant-design/icons";

export const DeleteMessage = ({onDelete, item}: any) => {

  const confirmLabel = item.child_messages_count > 0 ? "Deleting this message will also delete its replies. Are you sure you want to continue?" : "Are you sure to delete this message?"

  const onConfirmDelete = () => {
    onDelete(item.id)
  }

  return (
    <Popconfirm placement="right" title={confirmLabel} onConfirm={onConfirmDelete}>
      <span className="pointerCursor"><DeleteOutlined/> Delete</span>
    </Popconfirm>
  )
}