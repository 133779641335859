//Helpers
import { formatters } from "../../../../../helpers";

export const tableConfig = (currency: any) => {
  return [
    {
      label: "Distribution",
      format: formatters.percent,
      key: "ratio"
    },
    {
      label: "Current Balance",
      format: (value: any) => formatters.currency({value, currency}),
      key: "balance"
    },
    {
      label: "WAR",
      format: formatters.percent,
      key: "gwar_open"
    },
    {
      label: "WAM",
      format: formatters.years,
      key: "wam"
    },
    {
      label: "Average Rating",
      format: formatters.default,
      key: "avg_rating",
    }
  ]
}