import React, {Component} from 'react';
//Other libraries
import produce from "immer";
//Components
import {message} from "antd";
//Custom Components
import Table from "./table";
import Header from "./header";
import Footer from "./footer";
//Helpers
import {callApi, isEmpty, storage,} from "../../../helpers";
import {checkColumnSorting, getColumnSortingName} from "./helpers"
//Constants
import {DEFAULT_GROUP} from "./config";

import {IColumn, SmartTableProps, SmartTableState} from "./interface";

class SmartTable extends Component<SmartTableProps, SmartTableState> {
    static defaultProps = {
        dataId: "id",
        usePagination: true,
        simpleFetch: false,
        callOnMount: true,
        pageSize: 50,
        initialSort: [],
        allowGrouping: false,
        selectGrouping: true,
        useFilters: true,
        cancelPreviousCalls: false,
        showTooltipOnCell: true,
        sortType: 'multiple'
    };

    constructor(props: any) {
        super(props);
        this.state = {
            page: 1,
            pageSize: props.pageSize,
            loading: false,
            data: SmartTable.initialData(),
            selected: [],
            sortingFields: props.initialSort,  // ['id','-key','amount'],
            expandRowIndex: null,
            searchValue: "",
            availableColumns: [],
            groupBy: props.groupBy || null,
            filteredGroups: this.initializeFilteredGroups(),
        };
    }

    static initialData = () => {
        return {
            count: 0,
            records: null
        }
    };

    getCacheFilterRules = () => {
        const cacheEntity = this.props.cacheEntity;
        return storage.get(cacheEntity);
    };

    setCacheFilterRules = (results: any) => {
        const cacheEntity = this.props.cacheEntity;
        storage.set(cacheEntity, results)
    };

    clearCacheFilterRules = () => {
        const cacheEntity = this.props.cacheEntity;
        storage.clear(cacheEntity)
        message.info("Table has been reset to default view since new changes are detected.")
    }

    checkCacheFilterRules = () => {
        let valid = true;
        const columns: any = this.getColumns() || [];
        const families: any = this.getFamilies() || [];
        const cachedFamilies = this.getCacheFilterRules() || [];
        if (families.length !== cachedFamilies.length) {
            valid = false;
        } else {
            for (let family of families) {
                const cachedFamily = cachedFamilies.find((f: any) => f.key === family.key);
                if (!cachedFamily) {
                    valid = false;
                    break;
                } else {
                    const familyColumns = columns.filter((c: any) => c.group === family.key);
                    const cachedColumns = cachedFamily.columns;
                    if (familyColumns.length !== cachedColumns.length) {
                        valid = false;
                        break;
                    } else {
                        for (let col of familyColumns) {
                            const cachedCol = cachedColumns.find((c: any) => c.key === col.key);
                            if (!cachedCol) {
                                valid = false;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return valid;
    };

    initializeFilteredGroups = () => {
        const cache = this.getCacheFilterRules();

        const validCache = this.checkCacheFilterRules();

        const groups = [];
        if (validCache) {
            const families: any = this.getFamilies();
            const columnsGroup: any = this.getColumns();
            for (let record of cache) {
                const family = families.find((f: { key: any; }) => f.key === record.key);
                const columns: any = columnsGroup.filter((col: any) => col.group === family.key);
                groups.push({
                    ...family,
                    label: family.key,
                    selected: record.selected,
                    columns: record.columns.map((cacheCol: { key: any; selected: any; }) => {
                        const column = columns.find((c: any) => c.key === cacheCol.key);
                        return {
                            ...column,
                            selected: cacheCol.selected
                        }
                    })
                })
            }
            return groups;
        } else {
            if (cache)
                this.clearCacheFilterRules();
            const groups: any = [];
            this.getFamilies().forEach((group: any) => {
                // @ts-ignore
                const columns: any = this.getColumns().filter((col: any) => col.group === group.key);
                groups.push({
                    ...group,
                    selected: true,
                    label: group.key,
                    columns: columns.map((col: any) => {
                        return {
                            ...col,
                            selected: true
                        }
                    })
                })
            });
            return groups;
        }
    }

    getFamilies = () => {
        return this.props.columnGroups || DEFAULT_GROUP
    };

    existFamilies = () => {
        return !!this.props.columnGroups;
    }

    getColumns = () => {
        const {columnGroups, columns} = this.props;
        if (!columns)
            return [];

        if (!columnGroups) { // @ts-ignore
            return columns.map(col => ({...col, group: DEFAULT_GROUP[0].key}))
        }

        return columns;
    }

    resetSorting() {
        this.setState({sortingFields: []});
    }

    resetGroup = () => {
        this.setState({
            expandRowIndex: null
        })
    };

    componentDidMount() {
        const {externalData, callOnMount} = this.props;
        if (!externalData && callOnMount)
            this.getData();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        // If filters change, reset the page and then retrieve the data again
        if (prevProps.url !== this.props.url || prevProps.filters !== this.props.filters)
            this.setState({page: 1}, this.getData);

        if (prevState.groupBy !== this.state.groupBy)
            this.resetGroup();

        if (prevProps.columns !== this.props.columns)
            this.setState({filteredGroups: this.initializeFilteredGroups()});
    }

    setLoading = (value: any) => {
        this.setState({loading: value})
    };

    setData = (newData: any) => {
        this.setState({
            data: newData
        });
    }

    getData = () => {
        const {page, pageSize, sortingFields} = this.state;
        const {filters, url, columns, useFilters, onGetData, cancelPreviousCalls, onDataUpdate}: any = this.props;

        if (onGetData) {
            return onGetData({
                page,
                pageSize,
                filters,
                cancelPreviousCalls,
                setData: this.setData,
                setLoading: this.setLoading
            })
        }


        let query = `page=${page}&page_size=${pageSize}`;
        if (filters)
            query += "&" + filters;
        // Verify that the sorting fields are valid
        // The sorting field may not be a column of the table anymore
        if (!isEmpty(sortingFields)) {
            let validSortingFields: any[] = [];

            sortingFields.forEach((sortingField: any) => {
                columns.forEach((column: any) => {
                    const columnSortingName = getColumnSortingName(column);
                    if (sortingField === columnSortingName || sortingField === `-${columnSortingName}`)
                        validSortingFields.push(sortingField);
                })
            });
            query += "&order=" + validSortingFields.join(',');
        }

        let superURl = url;

        if (useFilters)
            superURl = superURl + `?${query}`;

        callApi({
            url: superURl,
            setLoading: this.setLoading,
            onSuccess: (response: any) => {
                let stateData: any = {
                    count: null,
                    records: null
                }

                if (Array.isArray(response)) {
                    stateData.records = response;
                } else {
                    stateData.count = response.count;
                    stateData.records = response.results;
                }
                if (onDataUpdate)
                    onDataUpdate(stateData);

                this.setState({data: stateData})
            },
            cancelPreviousCalls: cancelPreviousCalls
        })
    };

    getRecords = () => {
        return this.state.data.records;
    }

    getCount = () => {
        const {data} = this.state;
        if (!data)
            return data;
        return data.count;
    }

    onPageChange = (newPage: any) => {
        this.setState({page: newPage}, this.getData);
    };

    onPageSizeChange = (currentPageSize: number, newPageSize: number) => {
        this.setState(produce(draft => {
            draft.pageSize = newPageSize;
            if (currentPageSize * newPageSize > draft.data.count)
                draft.page = 1;
        }), this.getData)
    };

    onColumnSort = (column: IColumn) => {

        this.setState(produce(draft => {
            const columnSortingName = getColumnSortingName(column);
            const columnSorting: any = checkColumnSorting(draft.sortingFields, column);

            // If not applicable , convert to ascending
            if (!columnSorting)
                draft.sortingFields.push(columnSortingName);
            // If ascending, convert to descending
            else if (columnSorting.direction === "asc")
                draft.sortingFields[columnSorting.index] = `-${columnSortingName}`;
            // If descending, remove sorting for the column
            else if (columnSorting.direction === "desc")
                draft.sortingFields.splice(columnSorting.index, 1);

            if (this.props.sortType === 'single')
                draft.sortingFields = draft.sortingFields.filter((value: any) => value.replace('-', '') === columnSortingName);

        }), this.onSortUpdate)
    };

    onSortUpdate = () => {
        const {externalData, onClientSort} = this.props;
        if (!!externalData) {
            if (!!onClientSort)
                onClientSort(this.state.sortingFields);
        } else {
            this.getData();
        }
    }

    prepareGroups = () => {
        const {filteredGroups} = this.state;
        const totalGroups = [];
        for (let group of filteredGroups) {
            if (!group.selected)
                continue;

            const groupRecord = this.getFamilies().find((g: any) => g.key === group.key);

            totalGroups.push(groupRecord);
        }
        return totalGroups;
    }

    prepareColumns = () => {
        const {groupBy, filteredGroups} = this.state;
        const {allowGrouping} = this.props;
        /*filter Now based on the filtering*/
        const totalColumns: any = [];

        if (filteredGroups.length) {
            for (let group of filteredGroups) {
                if (group.selected) {
                    for (let col of group.columns) {
                        if (col.selected) {
                            // @ts-ignore
                            const colRecord: any = this.getColumns().find((c: any) => c.key === col.key && group.key === c.group);
                            if (colRecord)
                                totalColumns.push(colRecord);
                        }
                    }
                }
            }
        }

        if (!allowGrouping || !groupBy)
            return totalColumns;

        const newColumns = [];

        const groupColumn = totalColumns.find((col: any) => col.key === groupBy);

        if (groupColumn)
            newColumns.push(groupColumn);

        totalColumns.forEach((column: any) => {
            if (column && column.key !== groupBy)
                newColumns.push(column);
        });

        return newColumns;
    };

    onExpand = (index: number) => () => {
        const triggerExpandEvent = this.props.onExpand;

        this.setState(prevState => {
            return {
                ...prevState,
                expandRowIndex: prevState.expandRowIndex === index ? null : index
            }
        }, () => {
            if (triggerExpandEvent)
                triggerExpandEvent();
        })
    };

    onGroupChange = (event: any) => {
        this.setState({
            searchValue: "",
            groupBy: event === "none" ? null : event,
        })
    };

    onSearchInputChange = (event: any) => {
        this.setState({
            searchValue: event.target.value
        })
    };

    updateFiltering = (state: any) => {
        this.setState({
            filteredGroups: state
        }, () => {
            this.setCacheFilterRules(state)
        })
    };

    getHeaderParams = (cols: any) => {
        const {loading, groupBy, searchValue, filteredGroups} = this.state;
        const {headerTransformTableConfig, allowGrouping, selectGrouping} = this.props;

        const properties: any = {
            columns: cols,
            groupByValue: groupBy,
            searchValue: searchValue,
            filteredGroups: filteredGroups,
            onGroupChange: this.onGroupChange,
            updateFiltering: this.updateFiltering,
            onSearchChange: this.onSearchInputChange
        };

        if (headerTransformTableConfig) {
            properties.headerTransformTableConfig = {
                ...headerTransformTableConfig,
                showGroupHeader: !this.existFamilies()
            }
        }

        if (selectGrouping && (allowGrouping && !loading))
            properties.headerGroupingConfig = true;

        return properties;
    }

    getSelectedColumnKeys = () => {
        const finalColumns = this.prepareColumns();
        return finalColumns.map((column: any) => column.download_key || column.key);
    }

    render() {
        const {
            header,
            extra,
            height,
            dataId,
            filters,
            getData,
            onClick,
            visibleReload,
            headerTransformTable,
            headerTransformTableModalView,
            columnGroups,
            externalData,
            allowGrouping,
            selectGrouping,
            renderCallbacks,
            groupingPosition = "end",
            ...rest
        } = this.props;

        const {
            page,
            pageSize,
            data,
            loading,
            groupBy,
            searchValue,
            sortingFields,
            expandRowIndex,
        } = this.state;

        const finalData = externalData ? {records: this.props.externalData} : data;

        const allowFooter = data.count;

        const finalColumns = this.prepareColumns();
        const finalGroups = this.prepareGroups();

        return (
            <>
                <Header groupingPosition={groupingPosition} extra={extra} {...this.getHeaderParams(finalColumns)}>
                    {header}
                </Header>
                <Table
                    {...rest}
                    height={height}
                    dataId={dataId}
                    data={finalData}
                    filters={filters}
                    groupBy={groupBy}
                    loading={loading}
                    onClick={onClick}
                    columns={finalColumns}
                    onExpand={this.onExpand}
                    getData={getData ? getData : this.getData}
                    searchValue={searchValue}
                    onSort={this.onColumnSort}
                    columnGroups={finalGroups}
                    externalData={externalData}
                    allowGrouping={allowGrouping}
                    sortingFields={sortingFields}
                    expandRowIndex={expandRowIndex}
                    renderCallbacks={renderCallbacks}>
                    <Footer
                        page={page}
                        data={data}
                        visible={allowFooter}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                        onPageSizeChange={this.onPageSizeChange}/>
                </Table>
            </>
        );
    }
}

export default SmartTable;
