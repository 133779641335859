import React from "react";
//Other Libs
import cx from "classnames";
//Components
import { CustomTable, Panel, SimpleEmpty } from "@cardoai/components";
//Helpers
import {callApi, formatters, isEmpty} from "../../../../../../../../../../helpers";
import {WarningOutlined} from "@ant-design/icons";
import {useAuthorization} from "../../../../../../../../../../utils/hooks";
import {Tooltip} from "antd";

const DrawdownSection = ({limits, currency,proposalId, vehicleId,record}: any , index: number) => {
  const auth =  useAuthorization();

  const onResetPaf = () =>{
    callApi({
      url: `main/notes_manager/reset_cumulative_paf/?vehicle_id=${vehicleId}&proposal_id=${proposalId}`,
      method: 'PATCH',
      successMessage:"Reset successfully!"
    })
  }

  return (
    <Panel
      key={index}
      defaultOpen
      collapsible={false}>
      <CustomTable>
        <thead>
        <tr>
          <th colSpan={2}>{limits.category}</th>
        </tr>
        </thead>
        <tbody>
        {!isEmpty(limits) ? limits.records.map((limit: any, index: number) => {

          const {name, fulfillment, formatter} = limit;
          const formattedValue = formatter === "Amount" ? formatters.currency({
            value: fulfillment,
            currency
          }) : fulfillment;
          return (
            <tr key={index}>
              <td className={cx("alignLeft", {bold: index === 3})}>{name}</td>
              <td className={cx("alignRight", {bold: index === 3})}>
                <Tooltip
                title="The current proposal occur after a QTD. Therefore, it may be necessary to set to 0 the Available Funds Remaining of the previous proposal. Do you want to proceed with the Reset?"
              >
                  {auth.role === "EqualizerServicer" && index ===2 && record?.reset_paf && <WarningOutlined onClick={onResetPaf} style={{marginRight: 10}}/> }
                </Tooltip>
                {formattedValue || "-"}
                </td>
            </tr>
          )

        }) : <SimpleEmpty/>}
        </tbody>
      </CustomTable>
    </Panel>
  );

}

export default DrawdownSection;