//Helpers
import { formatters } from "../../../../../../../helpers";

export const config: any = {
  mainTable: [
    {
      key: "senior_note",
      label: "Senior Note #",
    },
    {
      key: "sgd_subscribed",
      label: "Amount Subscribed",
      format: formatters.thousands,
    },
    {
      key: "subfund",
      label: "SubFund",
    },
    {
      key: "subscription_date",
      label: "Subscription Date",
      format: formatters.date
    },
    {
      key: "reconciliation_period_end",
      label: "Reconciliation Period End",
      format: formatters.date
    },
    {
      key: "rate",
      label: "Rate",
      format: formatters.percentNoPrecision,
    },
    {
      key: "yr_base",
      label: "Year Base",
    },
    {
      key: "gross_accrued_interest",
      label: "Gross Account Int.",
      format: formatters.thousands,
    },
    {
      key: "retained_init",
      label: "Retained Int.",
      format: formatters.thousands,
    },
    {
      key: "net_account_init",
      label: "To Be Retained",
      format: formatters.thousands,
    },
  ],
  asset_sale_columns: [
    {
      key: "asset_name",
      label: "",
      format: formatters.default,
      width: 200,
    },
    {
      key: "retained_costs",
      label: "Retained Costs",
    },
    {
      key: "net_accrued_interest",
      label: "To Be Retained",
    },
  ]
}
