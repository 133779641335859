//Other Libs
import Nprogress from "nprogress";
/*External Services*/
import {
  assignLoaderInterceptor,
  assignStartInterceptor,
  assignSuccessInterceptor,
  createCallApi,
  createClient,
  createController,
  createDownload,
  createUseQuery
} from "@cardoai/utils";
import notifications from "../../../components/notifications"
/*Internal Services*/
import { authStorage } from "../../../helpers";
import { collectionManagementBaseUrl, serverBaseUrl } from "../../../settings";

export const client = createClient({
  baseURL: serverBaseUrl,
});
export const controller = createController({client});

export const callApi = createCallApi({
  client: client,
  controller: controller,
  notification: notifications,
  baseURL: collectionManagementBaseUrl
})

export const downloadClientFile = createDownload({
  client: client
})

export const useQuery = createUseQuery({
  client: client,
  controller: controller,
  notification: notifications,
  baseURL: collectionManagementBaseUrl
});

/*Interceptors*/

assignStartInterceptor({
  client: client,
  getToken: authStorage.getAccessToken
})

assignSuccessInterceptor({
  client: client
})

assignLoaderInterceptor({
  client: client,
  end: Nprogress?.done,
  start: Nprogress?.start
})