import React from 'react';
//Other Libs
import clone from "clone";
//Components
import { Button, Col, Divider, Modal, Row } from "antd";
import { Graph } from "../../../../../../../../components";
import { Break, CustomTable, NumberInput } from "@cardoai/components";
//Helpers
import { theme } from "../../../../../../../../assets/theme/colors";
import { formatters, MathOp } from "../../../../../../../../helpers";

interface ITable {
  title?: string,
  values?: any,
  options?: any,
  onChange?: any,
  min?: any
}

const Table = (props: ITable) => {
  const {title, values, options, onChange, min} = props;
  const columns = options.map((option: any) => option.label);

  return (
    <CustomTable>
      <tbody>
      <tr>
        <td>Period</td>
        {columns.map((value: any) => <td key={value} className="textCenter bolder">{value}</td>)}
      </tr>
      <tr>
        <th>{title}</th>
        {columns.map((column: any) => {
          const value = values[column].value;
          return (
            <td key={column}>
              <NumberInput
                centered
                min={min}
                max={100}
                size='small'
                value={value}
                type='percent'
                normalizeValue
                fullWidth={false}
                onChange={onChange(column)}/>
            </td>
          )
        })}
      </tr>
      </tbody>
    </CustomTable>
  );
}


const prepareValues = (options: any[], fields: any) => {
  const config: any = {};

  options.forEach(option => {
    const key = option.label;
    const hasInitialValue = fields && fields[key] !== null;
    config[key] = {
      key: key,
      hasInitialValue: hasInitialValue,
      value: hasInitialValue ? fields[key] : 0,
    }
  })
  return config;
}

const ModalContent = (props: any) => {
  const {title, onCancel, onOk, updateFields, options, fields, min} = props;
  const [values, setValues] = React.useState(prepareValues(options, fields));

  const handleChange = (column: string | number) => (value: any) => {
    setValues((prevState: any) => {
      const cloneState = clone(prevState);
      const record = cloneState[column];
      record.value = value;
      return cloneState;
    })
  }

  const handleDrag = ({value, label}: any) => {
    const normalizedValue = MathOp.round(value, 2);
    return handleChange(label)(normalizedValue);
  }

  const handleConfirm = () => {
    /*Construct Server Data*/
    const config: any = {};


    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        const record = values[key];
        config[key] = record.value
      }
    }

    updateFields(config);
    onOk();
  }

  const handleCancel = () => {
    onCancel();
  }

  const configurations: any = {
    labels: [],
    dataSets: [
      {
        data: [],
        label: title,
        backgroundColor: theme.colors.blue
      }
    ]
  };

  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      const record = values[key];
      configurations.labels.push(key);
      configurations.dataSets[0].data.push(record.value);
    }
  }


  return (
    <>
      <Table
        min={min}
        title={title}
        values={values}
        options={options}
        onChange={handleChange}/>
      <Break size='xLarge'/>
      <Graph
        height={500}
        draggable={true}
        onDrag={handleDrag}
        limits={
          {
            y: {
              min: min / 100,
              max: 1
            }
          }
        }
        beginAtZero={min === 0}
        displayLegend={false}
        types={['line']}
        format={formatters.percent}
        {...configurations}
      />
      <Divider/>
      <Row gutter={8} justify="end">
        <Col>
          <Button onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={handleConfirm}>
            Ok
          </Button>
        </Col>
      </Row>
    </>
  )

};

interface IChartUpdateModal {
  min?: any,
  title?: string,
  fields?: any,
  visible?: any,
  options?: any,
  onOk?: any,
  onCancel?: any,
  updateFields?: any
}

const ChartUpdateModal = (props: IChartUpdateModal) => {
  return (
    <Modal
      {...props}
      footer={null}
      destroyOnClose
      className="fullWidth">
      <Break size='large'/>
      {props.visible && <ModalContent {...props}/>}
    </Modal>
  );
};

ChartUpdateModal.defaultProps = {
  title: "Conditional Default Rate"
};

export default ChartUpdateModal;
