const GENERAL_HELP_ASSETS_CONFIG = {
  images: {
    dashboardModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/dashboard_fullview2.png',
      spvDetails: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/dashboard_spv.png',
      geography: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/dashboard_geography2.png',
      performance: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/dashboard_performance.png'
    },
    spvModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_fullview2.png',
      notesFullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_notes2.png',
      structure: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_notes_structure2.png',
      waterfall: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_notes_structure_waterfallchart2.png',
      notesCashFlow: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_notes_payments2.png',
      limits: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_notes_limits2.png',
      collateralFullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_collateral.png',
      collateralConcentration: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/collateral_concentration.png',
      collateralCashFlow: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_collateral_cashflows.png',
      performance: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_collateral_performance.png',
      lossChart: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loss_chart_dropdown.png',
      diversification: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_collateral_diversification_breakdown.png',
      geography: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_collateral_diversification_geography.png',
      sector: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_collateral_diversification_sector.png',
      debtor: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_collateral_diversification_debtor.png',
      loans: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_collateral_loans.png',
      accounts: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_notes_accounts.png'
    },
    loansModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_fullview2.png',
      aggregatedStatistics: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_aggragated_stats2.png',
      allFilters: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_filters.png',
      otherOptions: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_otherOptions.png',
      amortizationPlan: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_amortization_plan.png',
      amortizationGraph: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_amortization_graph.png',
      eventsRealized: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_events_realized.png',
      eventsExpected: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_events_expected.png',
      debtor: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_debtor_view.png',
      collateral: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_collateral.png',
      arrangeColumns: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_arrangeColumns.png'
    },
    analyticsModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/analytics_fullview2.png',
      aggregatedStatistics: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/analytics_main_stats.png',
      delays: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/analytics_loss.png',
      concentration: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/analytics_concentration.png',
      contributors: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/analytics_contributors.png',
      dod: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/analytics_dod.png'
    },
    documentsModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/documents_fullview.png',
      download: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/downoad_documents.png',
      actions: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/other-options-document.png',
      manageDocuments: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/manage_documents.png',
      importDocument: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/documents_servicer.png',
      upload: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/documents_upload_view.png',
      uploadControls: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/documents_upload_confirmation.png',
      uploadProgress: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/upload_progress.png'

    },
    notesManagerModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/notesManager_fullview.png',
      loanPurchaseDetails: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/notesManager_details.png'
    },
    hyperDataRoomModule: {
      fullView: ''
    },
    others: {
      goBack: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/go_back_button.png',
      switch: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/spv_switch_button2.png',
      filterLoans: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loans_filter_button.png',
      login: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/login_page.png',
      mainImage: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/primaryImage.png',
      deleteButton: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/delete_button.png',
      downloadButton: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/download_button.png',
      publishButton: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/publish_button.png',
      unPublishButton: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/unpublish_button.png'
    }
  }
}


export default GENERAL_HELP_ASSETS_CONFIG;