import React from 'react';
//Components
import { Modal } from "antd";
import { LoanSections } from "./components";
//Utils
import { withData } from "../../../../utils";

interface ILoanDetails {
  visible?: any,
  sectors?: any,
  loanId?: any,
  onClose: () => any

}

const LoansContent = withData({
  urlFormatter: function (config: any) {
    const {props: {loanId}} = config;
    return `/main/sme_loan/${loanId}/`
  },
  identifierParent: "loans",
  identifierChild: "loanId",
  usesDateFilters: false,
  usesCache: true,
  showIntervals: true,
  initialState: {}
})(LoanSections);

const LoanDetails = (props: ILoanDetails) => {
  const {visible, sectors, loanId, onClose} = props;

  if (!visible)
    return null;

  return (
    <Modal
      width={1500}
      footer={null}
      destroyOnClose
      visible={visible}
      onCancel={onClose}
      style={{top: 55}}
      closable={false}>
      <LoansContent
        loanId={loanId}
        sectors={sectors}/>
    </Modal>
  );
}

export default LoanDetails;