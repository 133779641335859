import React from "react";
//Components
import { Tag } from "antd";
import Details from "./details"
//Helpers
import { formatters, formatThousands } from "../../../../helpers";

const TagView = (value: any) => {
  return (
    <Tag>
      {value ? value.toUpperCase() : value}
    </Tag>
  )
}
const CurrencyView = (props: { record: any; }) => {
  const record = props.record;
  const value = record.balance;
  const abbr = record.currency;

  let symbol;

  if (abbr === 'EUR')
    symbol = '€'
  else if (abbr === 'USD')
    symbol = '$'

  return `${symbol}${formatThousands(value)}`
};

export const columns: any = [
  {
    key: "identifier",
    label: "Identifier",
    align: "center"
  },
  {
    key: "type",
    label: "Type",
    render: TagView
  },
  {
    key: "status",
    label: "Status",
    render: TagView
  },
  {
    key: "borrower",
    label: "Borrower Name"
  },
  {
    key: "seller",
    label: "Seller Name"
  },
  {
    key: "balance",
    label: "Balance",
    Component: CurrencyView,
  },
  {
    key: "wip",
    label: "Wip",
    format: formatters.percentOnePrecision
  },
  {
    key: "days_to_complete",
    label: "Days to complete",
  },
  {
    key: "completed_date",
    label: "Completed Date",
    format: formatters.date
  },
  {
    key: "issue_date",
    label: "Issue Date",
    format: formatters.date
  },
  {
    key: "",
    label: "",
    Component: Details
  }
];

export const filters = {
  searchType: [
    {
      key: 'identifier__icontains',
      label: 'Identifier'
    },
    {
      key: 'borrower__icontains',
      label: 'Borrower'
    },
    {
      key: 'seller__icontains',
      label: 'Seller'
    }
  ],
  status: [
    {
      key: 'Completed',
      label: 'Completed'
    },
    {
      key: 'Pending',
      label: 'Pending'
    },
    {
      key: 'Ready To Be Completed',
      label: 'Ready To Be Completed'
    },
    {
      key: 'Wip',
      label: 'Wip'
    }
  ],
  type: [
    {
      key: 'Credit Note',
      label: 'Credit Note'
    },
    {
      key: 'Debit Note',
      label: 'Debit Note'
    },
    {
      key: 'Invoice',
      label: 'Invoice'
    },
    {
      key: 'Payment Injunction',
      label: 'Payment Injunction'
    }
  ]
}
