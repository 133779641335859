import React, { useState } from "react";
//Components
import { Button, Col, Row, Tabs } from "antd";
//Custom Components
import LTCTable from "./ltc_table";
import CasavoLimits from "../../index";
import DrawdownAndWaterfall from "./drawdown_and_waterfall";
//Helpers
import { downloadClientFile, createFileName } from "../../../../../../../../../../helpers";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import Accounts from "./accounts";

const TabPane = Tabs.TabPane;

const CasavoProposalLimits = (props: any) => {
  const {vehicleId, type, vehicle, vehicleIdentifier, proposalId, record, status} = props;
  const [loading, setLoading] = useState<boolean>(false);

  const currency = vehicle.base_currency;

  const onDownload = () => {
    downloadClientFile({
      url: `/casavo/collateral/${proposalId}/download_facility_report/?&vehicle_id=${vehicleId}`,
      filename: createFileName(`facility_report_${record.proposed_date}`),
      setLoading,
    });
  }

  return (
    <>
      <Row justify="end">
        <Col>
          <Button
            size="small"
            type="primary"
            onClick={onDownload}
            disabled={moment(record.proposed_date) < moment("2022-06-05")}
            loading={loading}
            icon={<DownloadOutlined/>}
          >
            Download FAR
          </Button>
        </Col>
      </Row>
      <Tabs>
        <TabPane key="drawdown_and_waterfall" tab="Drawdown and Waterfall">
          <DrawdownAndWaterfall currency={currency} vehicleId={vehicleId} proposalId={proposalId}/>
        </TabPane>
        <TabPane key="concentration_limits_and_eligibility_criteria" tab="Concentration Limits and Eligibility Criteria">
          <CasavoLimits
            type={type}
            status={status}
            currency={currency}
            vehicleId={vehicleId}
            proposalId={proposalId}
            vehicleIdentifier={vehicleIdentifier}
          />
        </TabPane>
        <TabPane key="ltc_and_outstanding_borrowing_base" tab="LTC and Outstanding Borrowing Base">
          <LTCTable  currency={currency} proposalId={proposalId} vehicleId={vehicleId}/>
        </TabPane>
        <TabPane key="accounts" tab="Accounts">
          <Accounts currency={currency} proposalId={proposalId} vehicleId={vehicleId} id={record.id}/>
        </TabPane>
      </Tabs>
    </>
  )
}
export default CasavoProposalLimits;
