import React from 'react';
import {Row, Col} from "antd";
import Actions from "./actions";
import queryString from "query-string";
import { groupedColumnsFar } from './config';
import {useProposal} from "../../../../../../context";
import SmartTable from "../../../../../../design/table";
import {useTableFilters} from "../../../../../../design/table/helpers";
import {createFileName, downloadClientFile, useQuery} from "../../../../../../../../helpers";

const Acquisition = (props: any) => {
  const {vehicleId, proposalId, fetchProposal} = useProposal();
  const {filters, updateFilters, updateFilter, setPage} = useTableFilters();
  const [groups, setGroups] = React.useState<string[]>(["property_information", "total"])

  const acquisition = useQuery({
    url: () => {
      const query = queryString.stringify({
        ...filters,
        page_size: 16,
        // proposal_id: proposalId,
        vehicle_id: vehicleId,
      })
      return `/casavo/collateral/${proposalId}/acquisitions/?${query}&order=theta_new_borrowing_base`
    },
    deps: [filters]
  });

  const handleDownload = () => {
    downloadClientFile({
      filename: createFileName("ltc_and_bb", "csv"),
      url: `/casavo/collateral/acquisitions/?${queryString.stringify({
        ...filters,
        vehicle_id: vehicleId,
        proposal_id: proposalId
      })}`,
    })
  }

  const memoizedColumns = React.useMemo(() => {
    const columns: any = [];

    for (let group of groups)
      groupedColumnsFar[group].forEach((col: any) => columns.push(col))

    return [
      ...columns
    ]
  }, [groups])


  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Row justify="end">
          <Col>
            <Actions
              groups={groups}
              filters={filters}
              setGroups={setGroups}
              updateFilter={updateFilter}
              updateFilters={updateFilters}
              handleDownload={handleDownload}/>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <SmartTable
              pagination={{
                pageSize: 16,
                current: filters.page,
                showSizeChanger: false,
                total: acquisition?.data?.count,
                onChange: (event: any) => setPage(event)
              }}
              columns={memoizedColumns}
              loading={acquisition?.loading}
              dataSource={acquisition?.data?.results}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Acquisition;