//Components
import { Status } from "./components";
//Helpers
import { formatters, Text } from "../../../../helpers";

export const STATUS_TYPE: any = {
  'Awaiting_Submission': 'Awaiting For Submission',
  'Awaiting_Approval': 'Awaiting Approval',
  'Processing': 'Processing',
  'Approved': 'Approved',
  'Completed': 'Completed',
  'Rejected': 'Rejected',
  'Deleted': 'Deleted',
  'Awaiting_User_Input': 'Awaiting User Input'
}

export const defaultColumns = [
  {
    key: "proposed_date",
    label: Text("Purchase proposed\n date"),
    group: "General",
    align: 'center',
    sortable: true,
    format: formatters.date,
  },
  {
    key: "status",
    label: Text("Status"),
    sortable: true,
    width: 100,
    group: "General",
    Component: Status,
    grouping: true
  },
  {
    key: "approval_date",
    label: Text("Approval\n date"),
    sortable: true,
    width: 100,
    group: "General",
    format: formatters.date,
  },
  {
    key: "completed_date",
    label: Text("Completed on"),
    group: "General",
    format: formatters.date,
    sortable: true,
    noWrap: true,
  },
  {
    key: "funding_date",
    label: Text("Funding Date"),
    format: formatters.date,
    group: "General",
    sortable: true,
  },
];

export const dynamicColumns = [
  {
    key: "senior_eur",
    label: Text("EUR"),
    sortable: true,
    format: formatters.thousands,
    group: "Senior",
    noWrap: true,
  },
  {
    key: "senior_usd",
    label: Text("USD"),
    sortable: true,
    format: formatters.thousands,
    group: "Senior",
    noWrap: true,
  },
  {
    key: "senior_sgd",
    label: Text("SGD"),
    sortable: true,
    format: formatters.thousands,
    group: "Senior",
    noWrap: true,
  },
  {
    key: "junior_eur",
    label: Text("EUR"),
    sortable: true,
    format: formatters.thousands,
    group: "Junior",
  },
  {
    key: "junior_usd",
    label: Text("USD"),
    sortable: true,
    format: formatters.thousands,
    group: "Junior",
  },
  {
    key: "junior_sgd",
    label: Text("SGD"),
    sortable: true,
    format: formatters.thousands,
    group: "Junior",
  },
];

