import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const Access = () => {

  const description: any = <span>
    Cardo AI Securitisation Platform can be accessed at <a
    href="https://securitization.cardoai.com/signin">securitization.cardoai.com</a>
  </span>

  return (
    <CustomImage
      description={description}
      alt="Login Page"
      src={assets.images.others.login}
    />
  );
};

export default Access;
