import React, { useEffect } from "react";
//Other Libs
import queryString from "query-string";
//Components
import { Skeleton } from "antd";
//Custom Components
import UserInputFields from "./user_input_fields";
import SelectOriginator from "./select_originator";
//Helpers
import { callApi } from "../../../../helpers";
import { MODAL_TITLE } from "../configurations";

interface ISynchronization {
  //props
  vehicle?: any,
  vehicleId?: any,
  makeRequest?: any,
  type?: any,
  //other props
  defaultDatePicker?: string,
  syncTypeParam?: string,
  urlIdentifier?: string,
  //modal
  title?: string,
  key?: string,
  handleUnmount?: () => void,
  handleCloseModal?: any,
  changeModalTitle?: (param?: any) => void,
  allowModalClose?: any,
  changeModalWidth?: any,
  setWidthAsContent?: () => void,
}

const Synchronization = (props: ISynchronization) => {
  const {
    vehicle, vehicleId, makeRequest, type, defaultDatePicker, syncTypeParam, urlIdentifier,
    setWidthAsContent, handleCloseModal, handleUnmount, changeModalWidth, allowModalClose, changeModalTitle
  } = props;

  const [fields, setFields] = React.useState(null);
  const [settings, setSettings] = React.useState(null);
  const [loadingFields, setLoadingFields] = React.useState(false);

  useEffect(() => {
    requestFields();
  }, []);

  const onSelect = (result: any) => {
    setSettings(result);
    if (result) {
      const modalTitle: any = MODAL_TITLE.selected + " for " + result.originator_label;
      changeModalTitle && changeModalTitle(modalTitle);
    }
  };

  const onSuccess = (results: any) => {
    setFields(results);
    if (results.length === 1)
      onSelect(results[0]);
  };

  const requestFields = () => {
    const params = {
      vehicle_id: vehicleId,
      request_type: syncTypeParam
    }
    callApi({
      url: `/main/originator_instance/${vehicleId}/report_options/?${queryString.stringify(params)}`,
      onSuccess: onSuccess,
      setLoading: setLoadingFields,
    })
  };

  if (loadingFields || !fields)
    return <Skeleton/>;

  if (settings)
    return (
      <UserInputFields
        type={type}
        settings={settings}
        vehicle={vehicle}
        vehicleId={vehicleId}
        syncTypeParam={syncTypeParam}
        urlIdentifier={urlIdentifier}
        makeRequest={makeRequest}
        handleUnmount={handleUnmount}
        allowModalClose={allowModalClose}
        defaultDatePicker={defaultDatePicker}
        changeModalTitle={changeModalTitle}
        handleCloseModal={handleCloseModal}
        setWidthAsContent={setWidthAsContent}
        changeModalWidth={changeModalWidth}
      />
    );

  return (
    <SelectOriginator fields={fields} onSelect={onSelect} changeModalTitle={changeModalTitle}/>
  );

};

export default Synchronization;