import React from "react";
//Constants
import { theme } from "../../../../../../assets/theme/colors";

const STATUS_COLOR: any = {
  'Awaiting For Submission': theme.colors.darkyellow,
  'Awaiting Approval': theme.colors.lightyellow,
  'Processing': theme.colors.orange,
  'Approved': theme.colors.lime,
  'Completed': theme.colors.green,
  'Rejected': theme.colors.lightred,
  'Deleted': theme.colors.red
}

const Status = ({record}: any) => {

  return (
    <div style={{
      display: "flex",
      borderRadius: 2,
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colors.white,
      backgroundColor: STATUS_COLOR[record.status] || theme.colors.beige
    }}>
      {record.status}
    </div>
  )
};

export default Status;