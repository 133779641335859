import React from 'react';
//Other Libs
import produce from "immer";
import { useNavigate } from "react-router-dom";
//Components
import { Skeleton, Space } from "antd";
//Custom Components
import Error from "./error";
import Header from "./header";
import Footer from "./footer";
import withModal from "../with_modal";
import UploadLoader from "../../../../components/upload_files/upload_loader";
//CardoUI Components
import { Tabs } from "@cardoai/components";
//Constants
import { Analytics, callApi, getUrl, MathOp } from "../../../../helpers";
//Constants
import config from '../sync_table/config';
import { STATUS_TYPE } from "../sync_table/main_table_view/status/config";

const prepareConfig = (data: any) => {
  return config.syncReports.header.main.map(record => {
    let value = data[record.key];

    let componentConfig: any = {}

    if (record.key === "status")
      componentConfig.record = data;
    else
      componentConfig = record.componentConfig;

    return {
      ...record,
      value: value,
      componentConfig
    }
  })
};

interface Props {
  vehicleId?: any,
  documentId: any,
  requestReports: () => any,
  handleCloseModal: () => any,
  setWidthAsContent: () => any,
  changeModalTitle: (param?: any) => any,
}

export const DataHealthCheck = (props: Props) => {

  const navigate = useNavigate();
  const {vehicleId, setWidthAsContent, documentId, handleCloseModal, requestReports} = props;


  const [data, setData] = React.useState<any>(null);
  const [form, setForm] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [saving, setSaving] = React.useState(false);
  const [savingMode, setSavingMode] = React.useState(false);


  React.useEffect(() => {
    fetchResults()
  }, []);

  const fetchResults = () => {
    callApi({
      url: `/main/sync_request/${documentId}/?vehicle_id=${vehicleId}&`,
      setLoading,
      onSuccess: ((response: any) => {
        const files = response.files;
        const initialForm: any = {};
        files && files.forEach((record: any) => {
          initialForm[record.file_type] = {
            override: null,
            url: record.file,
            name: record.filename,
            type: record.file_type
          }
        });
        setData(response);
        setForm(initialForm)
      }),
      onFinish: () => setLoading(false)
    })
  };

  const updateTable = () => {
    requestReports();
    navigate("/syncs");
  };

  const enableSavingMode = () => {
    if (!savingMode)
      setSavingMode(true);
  }

  const onSave = () => {
    setSaving(true);
    setWidthAsContent()
    props.changeModalTitle('Uploading Files')
    const body = new FormData();

    for (let type in form) {
      if (form.hasOwnProperty(type)) {
        const field = form[type];
        if (field.override)
          body.append(type, field.override)
      }
    }

    callApi({
      url: getUrl(`/main/sync_request/${documentId}/?vehicle_id=${vehicleId}&`),
      method: 'PUT',
      body: body,
      dataType: 'json',
      onUploadProgress: function (progressEvent: any) {
        const percentage: any = MathOp.divide(progressEvent.loaded, progressEvent.total) * 100;
        setPercent(Math.round(percentage));
      },
      onSuccess: () => {
        handleCloseModal();
        updateTable();
      },
      onFinish: () => {
        setLoading(false);
      }
    })

  };

  const onFileChange = (type: any, file: any) => {
    enableSavingMode();
    setForm(produce(form, (draft: any) => {
      draft[type].override = file;
    }))
  };

  if (loading || !data || !form)
    return <Skeleton active/>;

  const reports = data.data_health_report || {};

  let tabsConfig = config.syncReports.tabs;

  if (data.status === STATUS_TYPE.files_received)
    tabsConfig = tabsConfig.filter(tab => tab.key !== "details");

  const status = data.status;

  const editFiles = [
    // STATUS_TYPE.done,
    STATUS_TYPE.sync_failed,
    STATUS_TYPE.health_check_failed
  ].includes(status);

  const enableSave = editFiles && savingMode;

  const fileType = data.sync_request_type;

  if (saving) {
    return (
      <UploadLoader percent={percent}/>
    )
  }

  const onTabChange = (key: any) => {
    Analytics.dispatchEvent({
      category: 'User',
      action: 'Changed tabs at Data Health Check',
      label: key
    });
  }

  return (
    <Space
      size="large"
      direction="vertical"
      className="fullWidth">
      {data.task_description && (
        <Error
          status={data.status}
          description={data.task_description}/>
      )}
      <Tabs
        data={data}
        form={form}
        reports={reports}
        config={tabsConfig}
        editFiles={editFiles}
        onChange={onTabChange}
        onFileChange={onFileChange}
        extraContent={<Header config={prepareConfig(data)}/>}/>
      <Footer
        id={documentId}
        status={status}
        onSave={onSave}
        loading={saving}
        fileType={fileType}
        vehicleId={vehicleId}
        enableSave={enableSave}
        makeRequest={updateTable}/>
      {data.status === STATUS_TYPE.sync_failed && (
        <Error
          status={data.status}
          description={data.task_description}/>
      )}
    </Space>
  )
};

export default withModal({
  useButton: false,
  defaultWidth: 1200,
  defaultVisible: true,
  modalTitle: 'Data Health Check'
})(DataHealthCheck);
