import {formatters} from "../../../../../../../../../../../helpers";

export const enum BB_TYPE {
    STANDARD = 'standard',
    JUNIOR = 'junior',
    SENIOR = 'senior',
}

const tableLTC: any[] = [
    {
        label: 'Real Estate ID',
        key: 'real_estate_id',
        align: "center"
    },
    {
        label: 'Transaction ID',
        key: 'transaction_id',
        align: "center"
    },
    {
        label: 'LTC Senior',
        key: 'ltc_senior',
        align: "center",
        format: formatters.percent,
        bb_type: BB_TYPE.STANDARD,
    },
    {
        label: 'Total Borrowing Base',
        key: 'total_borrowing_base',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.STANDARD,
    },
    {
        label: 'Outstanding Borrowing Base (Reconciled)',
        key: 'outstanding_borrowing_base_reconcile',
        align: "center",
        format: formatters.thousands,
        info: 'Computed based on actual cash flows.',
        bb_type: BB_TYPE.STANDARD,
    },
    {
        label: 'Outstanding Borrowing Base (Overall)',
        key: 'outstanding_borrowing_base_overall',
        align: "center",
        format: formatters.thousands,
        info: 'Computed based on both actual and expected cash flows',
        bb_type: BB_TYPE.STANDARD,
    },
    {
        label: 'Delta Outstanding Borrowing Base',
        key: 'delta_outstanding_borrowing_base',
        align: "center",
        format: formatters.thousands,
        info: 'Shows the expected increase of the actual borrowing base',
        bb_type: BB_TYPE.STANDARD,
    },
    {
        label: 'Borrowing Base Released',
        key: 'borrowing_base_released',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.STANDARD,
    },
    {
        label: 'Total Senior Borrowing Base',
        key: 'total_senior_borrowing_base',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.SENIOR,
    },
    {
        label: 'Outstanding Senior Borrowing Base (Reconciled)',
        key: 'outstanding_senior_borrowing_base_reconciled',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.SENIOR,
    },
    {
        label: 'Outstanding Senior Borrowing Base (Overall)',
        key: 'outstanding_senior_borrowing_base_overall',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.SENIOR,
    },
    {
        label: 'Delta Outstanding Senior Borrowing Base',
        key: 'delta_outstanding_senior_borrowing_base',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.SENIOR,
    },
    {
        label: 'Total Junior Borrowing Base',
        key: 'total_junior_borrowing_base',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.JUNIOR,
    },
    {
        label: 'Outstanding Junior Borrowing Base (Reconciled)',
        key: 'outstanding_junior_borrowing_base_reconciled',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.JUNIOR,
    },
    {
        label: 'Outstanding Junior Borrowing Base (Overall)',
        key: 'outstanding_junior_borrowing_base_overall',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.JUNIOR,
    },
    {
        label: 'Delta Outstanding Junior Borrowing Base',
        key: 'delta_outstanding_junior_borrowing_base',
        align: "center",
        format: formatters.thousands,
        bb_type: BB_TYPE.JUNIOR,
    },
];

export const config: any = {
    entity: "LTCTable",
    columns: tableLTC,
    endpoints: {
        tableUrl: '/casavo/notes_manager/ltc_and_bb/',
        downloadUrl: '/casavo/notes_manager/download_ltc_and_bb/?'
    }
}
