export function getInitialSelection(data: any): any {
  const state: any = {};
  for (let record of data) {
    const vehicleId = record.id;
    state[vehicleId] = false;
  }
  return state;
}

export function cloneState(object: any) {
  return Object.assign({}, object);
}