import React from "react";
//Helpers
import { formatters, Text } from "../../../helpers";
//Custom Components
import { InfoTag } from "@cardoai/components";

export default (currency: any) => {
  return [
    {
      key: "reference_date",
      label: Text("Reference\nDate"),
      format: formatters.date
    },
    {
      key: "current_loan_balance",
      label: Text("Current\nBalance"),
      format: (value: any) => formatters.currency({value, currency})
    },
    {
      key: "loans_outstanding",
      label: Text("Loans\nOutstanding #"),
      format: formatters.thousandsShorted
    },
    {
      key: "avg_ticket_size",
      label: Text("Average \nLoan Size"),
      format: (value: any) => formatters.currency({value, currency})
    },
    {
      key: "gwar",
      label: Text("WAR\n(%)"),
      format: formatters.percent
    },
    {
      key: "wam",
      label: Text("WAM\n(yrs)"),
      format: formatters.years
    },
    {
      key: "average_delay",
      label: <InfoTag showInfo toolTipPlacement="bottom"
                      title="Statistic calculated only for closed loans">{Text("Average\n Delay")}</InfoTag>,
      format: formatters.days
    },
    {
      key: "average_delay_vs_original_maturity",
      label: <InfoTag showInfo toolTipPlacement="bottom"
                      title="Statistic calculated only for closed loans">{Text("Avg Delay vs\nOriginal Maturity")}</InfoTag>,
      format: formatters.days,
    },
    {
      key: "delinquency_ratio",
      label: <InfoTag showInfo toolTipPlacement="bottom"
                      title=" Delinquency Ratio is calculated only for loans with days past due greater than 30">{Text("Delinquency\nRatio")}</InfoTag>,
      format: formatters.percent,
    },
    {
      key: "gross_default_ratio",
      label: Text("Gross\nDefault Ratio"),
      format: formatters.percent,
    },
    {
      key: "conditional_default_ratio",
      label: Text("Conditional\nDefault Ratio"),
      format: formatters.percent,
    },
    {
      key: "prepayment_ratio",
      label: Text("3m \nPrepayments Ratio"),
      format: formatters.percent,
    },
    {
      key: "ltv",
      label: Text("LTV"),
      format: formatters.percent,
    },
  ]
};