import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../assets";

const HDRLoansManagement = () => {

  const description =
    <div style={{textAlign: 'justify'}}>
      The Loans Management module allows the user to browse through the portfolio, and access different statistics
      and detailed information down to loan level information. The user can filter by Lender, Investment Date,
      loan ID or company name, as well as apply filters by multiple columns if needed, and download that information
      in .csv format.
    </div>

  return (
    <Row>
      <Col sm={24}>
        <CustomImage
          description={description}
          alt="Access Page"
          src={assets.images.loansManagementModule.fullView}
        />
      </Col>
    </Row>
  );
};

export default HDRLoansManagement;