import React, { useState } from 'react';
//Components
import Table from "../../../../components/tables/smart_table";
import LoanDetails from '../loan_details/loan_details';
//Constants
import { cacheEntity, columns } from "./config";
import { theme } from "../../../../assets/theme/colors";

interface ILoansTable {
  loans?: any,
  height: number | string | undefined,
  extraHeight?: number | string | undefined,
  header?: any,
  externalData?: any,
  sectors?: any,
  filters?: any,
  reference?: any,
  hiddenColumns?: any,
  availableGroups?: any,
}

const LoansTable = (props: ILoansTable) => {
  const {
    header,
    filters,
    hiddenColumns,
    height,
    extraHeight,
    sectors,
    reference,
    externalData,
    availableGroups
  } = props;

  const [activeLoanId, setActiveLoanId] = useState(null);

  const showLoanDetails = (loanId: any) => () => {
    setActiveLoanId(loanId);
  };

  const closeLoanDetails = () => {
    setActiveLoanId(null);
  };

  const availableColumns: any = [];
  columns.forEach(column => {
    const validGroup = availableGroups.find((group: { key: string; }) => group.key === column.group);
    if (validGroup) {
      const key = column.key;
      const validColumn = !hiddenColumns.includes(key);
      if (validColumn)
        availableColumns.push(column);
    }
  });

  return (
    <>
      <Table
        centerData
        centerHeaders
        ref={reference}
        header={header}
        height={height}
        size='small'
        filters={filters}
        url="/main/sme_loan/"
        cacheEntity={cacheEntity}
        extraHeight={extraHeight}
        columns={availableColumns}
        onClick={showLoanDetails}
        cancelPreviousCalls={true}
        externalData={externalData}
        tableId="loans-table-config"
        columnGroups={availableGroups}
        headerTransformTableConfig={{
          modalView: true,
        }}
      />
      <LoanDetails
        sectors={sectors}
        loanId={activeLoanId}
        visible={!!activeLoanId}
        onClose={closeLoanDetails}/>
    </>
  );
};

LoansTable.defaultProps = {
  externalData: false,
  height: "62vh",
  hiddenColumns: [],
  hiddenGroups: [],
  availableGroups: [
    {
      key: "General",
      styles: {
        background: theme.palette.border[5],
        borderColor: theme.colors.white,
        color: 'rgba(0, 0, 0, 0.65)'
      },
    },
    {
      key: "Collateral",
      styles: {
        background: theme.palette.border[2],
        borderColor: theme.colors.white,
        color: "rgba(0, 0, 0, 0.65)"
      },
    },
    {
      key: "Modification",
      styles: {
        background: theme.palette.border[6],
        borderColor: theme.colors.white,
        color: "rgba(0, 0, 0, 0.65)"
      },
    },
    {
      key: "Seller",
      styles: {
        background: theme.palette.border[7],
        borderColor: theme.colors.white,
        color: 'rgba(0, 0, 0, 0.65)'
      },
    },
    {
      key: "Realized",
      styles: {
        background: theme.palette.border[8],
        borderColor: theme.colors.white,
        color: "rgba(0, 0, 0, 0.65)"
      },
    },
    {
      key: "Delays",
      styles: {
        background: theme.palette.border[9],
        borderColor: theme.colors.white,
        color: "rgba(0, 0, 0, 0.65)"
      },
    },

  ]
};

export default LoansTable;
