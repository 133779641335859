import React from 'react';
//Components
import { Tabs } from "antd"
import Entity from "./entity";

interface Props {
  originators: any
}

const CollectionManagement = ({originators}: Props) => {
  return (
    <Tabs defaultActiveKey={'1'}>
      {originators.map((originator: any, index: any) => {
        const entity = originator.collection_management_configs.identifier;
        return (
          <Tabs.TabPane
            key={index + 1}
            tab={originator.name}>
            <Entity entity={entity}/>
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  );
};

export default CollectionManagement;
