import React from "react";
//Other Libs
import queryString from "query-string";
//Components
import { Button, Col, Comment, Row, Select, Space, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
//Custom Components
import { ReplyMessage } from "./reply_message";
import { DeleteMessage } from "./delete_message";
import { WriteMessage } from "./write_message";
import { ViewReplies } from "./view_replies";
//Helpers
import { callApi, downloadClientFile, formatDatetime } from "../../../../helpers";
import notifications from "../../../../components/notifications";
import { useAuthorization } from "../../../../utils/hooks";
import { actionKey } from "../../../../constants";
//Custom Helpers
import { STATUS_COLORS, STATUSES } from "../helpers";

const {Option} = Select;

const childStyle = {
  border: 'solid #ccc 1px',
  padding: '0px 16px',
  backgroundColor: '#fcfcfc',
  margin: '12px 0px',
};

export const SingleMessage = ({item, currentUserId, vehicleId, topicId, fetchMessages}: any) => {

  const {canPerformAction}: any = useAuthorization();
  const hasMessageStatus = !!item.status;
  const canUpdateStatusMessage = canPerformAction(actionKey.updateMessage, vehicleId)

  const [showReplyBox, setShowReplyBox] = React.useState<boolean>(false);
  const [showChildReplies, setShowChildReplies] = React.useState(false);
  const [childrenMessages, setChildrenMessages] = React.useState([]);
  const [messageStatus, setMessageStatus] = React.useState(item.status);

  React.useEffect(() => {
    if (showChildReplies)
      getChildReplies();
  }, [showChildReplies]);

  const toggleReplyBox = () => {
    setShowReplyBox(prevState => !prevState)
  }

  const toggleChildReplies = () => {
    setShowChildReplies(prevState => !prevState)
  }

  const getChildReplies = () => {
    const queryParams = {
      page: 1,
      page_size: 50,
      model: 'vehicle',
      pk: vehicleId,
      topic: topicId,
      parent_message: item.id
    }
    callApi({
      url: `common/message/?${queryString.stringify(queryParams)}`,
      onSuccess: (response: any) => setChildrenMessages(response.results),
    })
  }

  const onDeleteMessage = (id: any) => {
    callApi({
      url: `/common/message/${id}/?&model=vehicle&pk=${vehicleId}&topic=${topicId}`,
      method: "delete",
      onSuccess: () => {
        fetchMessages();
        notifications.success(`This message has been deleted.`);
      }
    })
  }

  const onChangeStatus = (value: any) => {
    setMessageStatus(value)
    changeMessageStatus(value)
  }

  const changeMessageStatus = (value:any) => {
    const form = new FormData();
    form.append("status", value);

    callApi({
      url: `common/message/${item.id}/?pk=${vehicleId}&model=vehicle`,
      method: "PATCH",
      body: form,
      onSuccess: () => {
        fetchMessages()
        notifications.success(`The status has been updated`);
      }
    })
  }

  const download = (url: string, name: string) => () => {
    downloadClientFile({
      url: url,
      filename: name,
      link: true,
      target: '_blank'
    });
  }

  return (
    <div style={item.parent_message == null ? childStyle : undefined}>
      <Comment
        actions={[]}
        author={item.user_name}
        content={
          <>
            <div>{item.message}</div>
            {item.files.length > 0 &&
              <Row gutter={[8, 8]}>
                {item.files?.map((file: any, index: number) => (
                  <Col key={String(index)}>
                    <Button
                      className='mt8'
                      icon={<DownloadOutlined/>}
                      onClick={download(file.file, file.file_name)}
                    >
                      {file.file_name}
                    </Button>
                  </Col>
                ))}
              </Row>}
          </>}
        datetime={
          <Row align="middle" justify="start" gutter={16}>
            <Col>
              {formatDatetime(item.created_at)}
            </Col>
            {!item.parent_message && <Col>
              ID: {item.id}
            </Col>}
            {(hasMessageStatus && !canUpdateStatusMessage) && <Col>
              <Tag color={STATUS_COLORS[item.status]}>{item.status}</Tag>
            </Col>}
            {(hasMessageStatus && canUpdateStatusMessage) &&
              <Col>
                <Select value={messageStatus} onChange={onChangeStatus} size="small" style={{fontSize: 12, width: 95}}>
                  {[STATUSES.INITIAL, STATUSES.ONGOING, STATUSES.RESOLVED].map((status: any) => (
                    <Option style={{fontSize: 12}} key={status} value={status}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </Col>}
          </Row>}
      />
      <Row justify="start" align="middle" gutter={12} style={{color: "#bfbfbf", marginLeft: 20}}>
        <Col>
          <ReplyMessage onClick={toggleReplyBox}/>
        </Col>
        <Col>
          <ViewReplies item={item} onClick={toggleChildReplies}/>
        </Col>
        {currentUserId === item.user &&
          <Col>
            <DeleteMessage item={item} onDelete={onDeleteMessage}/>
          </Col>}
      </Row>
      <Row justify="start" align="middle" gutter={12} style={{marginLeft: 30}}>
        <Space direction='vertical' className="fullWidth mt8 mb8 mr8">
          {showChildReplies &&
            <div>
              {childrenMessages.map((childMessage: any) => (
                <SingleMessage
                  key={childMessage.id}
                  item={childMessage}
                  currentUserId={currentUserId}
                  vehicleId={vehicleId}
                  topicId={topicId}
                  fetchMessages={fetchMessages}
                />
              ))}
            </div>}
          {showReplyBox &&
            <div style={{width: 600}}>
              <WriteMessage
                vehicleId={vehicleId}
                topicId={topicId}
                parentId={item.id}
                closeReplyBox={toggleReplyBox}
                fetchMessages={fetchMessages}
              />
            </div>}
        </Space>
      </Row>
    </div>
  );
}
