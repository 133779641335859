import React from "react";
//Components
import { Col, Row, Select } from "antd";

const {Option} = Select;

interface RatingSelectionProps {
  placeholder?: any,
  onChange?: any,
  ratings?: any,
  value?: any,
  min?: any,
  max?: any,
  singleValue?: any,
  rating?: any,
}

const RatingSelection = (props: RatingSelectionProps) => {
  const {placeholder, onChange, ratings, value, min, max, singleValue} = props;

  return (
    <>
      {!singleValue ? <Row gutter={16} align="middle" justify="space-between">
        {["min", "max"].map(element => {
          return (
            <Col xs={12}>
              <Row align="middle" justify="space-between">
                <Col xs={24} lg={8} className="bold">
                  {element.toUpperCase()}:
                </Col>
                <Col xs={24} lg={16}>
                  <Select
                    className="fullWidth"
                    placeholder={placeholder}
                    onChange={onChange(element)}
                    value={element === "min" ? min : max}>
                    {ratings.map(({id, label}: any, index: any) => {
                      const selectKey = `${id}-${label}-${index}`;
                      return <Option value={id} labelInValue key={selectKey}>{label}</Option>
                    })}
                  </Select>
                </Col>
              </Row>
            </Col>
          )
        })}
      </Row> : <Row align="middle" gutter={16}>
        <Col xs={24} lg={6} className="bold">
          Value:
        </Col>
        <Col xs={24} lg={18}>
          <Select
            value={value}
            className="fullWidth"
            placeholder={placeholder}
            onChange={onChange("value")}>
            {ratings.map(({id, label}: any, index: any) => {
              const selectKey = `${id}-${label}-${index}`;
              return <Option value={id} labelInValue key={selectKey}>{label}</Option>
            })}
          </Select>
        </Col>
      </Row>
      }
    </>)
}


export default RatingSelection;