import React, { useEffect } from 'react';
//Components
import { Skeleton } from "antd";
import { CustomTable as Table, SimpleEmpty } from "@cardoai/components";
//Config
import { client } from "../../config";


const Report = (props: any) => {
  const [data, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    getReport();
  }, []);

  const getParams = () => {
    const {state} = props;

    return {
      expression: state.expression,
      db_choice: state.db_choice,
      configs: {
        host: state.host,
        database: state.database,
        username: state.username,
        password: state.password,
        port: state.port ? Number(state.port) : null
      }
    }
  }

  const getReport = () => {
    setLoading(true);

    client({
      url: `/api/report/show_info`,
      data: getParams(),
      method: 'post',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      setData(response);
    }).finally(() => {
      setLoading(false);
    })
  };

  if (!data || loading)
    return <Skeleton active={true}/>

  if (!data.length)
    return <SimpleEmpty/>

  const header = data[0];

  const records: any = data.filter((_: any, index: any) => index !== 0);

  return (
    <Table maxHeight={420}>
      <thead>
      <tr>
        {header.map((i: any) => <th>{i}</th>)}
      </tr>
      </thead>
      <tbody>
      {records.map((i: any[]) => {
        return (
          <tr>
            {i.map(e => <td>{e}</td>)}
          </tr>
        )
      })}
      </tbody>
    </Table>
  );
};

export default Report;
