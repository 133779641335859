import React from 'react';
import SelectOperator from "../select";

const BooleanOperator = ({value, onChange, ...rest}: any) => (
    <SelectOperator
        {...rest}
        multiple={false}
        options={["Yes", "No"]}
        onChange={(key: any) => onChange(key === "Yes")}
        value={value === true ? "Yes" : value === false ? "No" : undefined}
    />
);
export default BooleanOperator;