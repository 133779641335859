import React from "react";
//Components
import { Col, Row } from "antd";
//Icons
import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
//Styles
import { fileStyles as styles } from "../../styles";

interface IFile {
  content?: any,
  onRemove?: any
}

const File = (props: IFile) => {
  const {content, onRemove} = props;
  return (
    <Row style={styles.layout} justify="space-between" onClick={(e) => e.stopPropagation()} title="">
      <Col>
        <Row gutter={8} align="bottom">
          <Col>
            <FileTextOutlined style={styles.file}/>
          </Col>
          <Col style={styles.text}>
            {content.name}
          </Col>
        </Row>
      </Col>
      <Col>
        <DeleteOutlined style={styles.delete} onClick={onRemove}/>
      </Col>
    </Row>
  )
}
export default File;