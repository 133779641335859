import { delayChartDatasets } from "./config";
import { formatters } from "../../../../helpers";

export const prepareConfig = (vdr = false) => {
  const config = vdr ? delayChartDatasets.filter((dataset: any) => !dataset.vdr) : delayChartDatasets;
  return config.map((dataset: any) => ({...dataset, data: []}));
}

export const getChartFormatter = (type: string, currency: any) => {
  return (type === 'percentage') ? formatters.percentNoPrecision : (value: any) => formatters.currency({
    value,
    currency
  });
}