import React from "react";
import { Checkbox, Modal, Space } from "antd";

interface Option {
  label: string,
  value: any,
}

interface Props {
  visible: boolean,
  options?: Option[],
  initialColumns?: any[],
  onConfirm?: (selected: any[]) => void,
  close?: () => void,
  style?: React.CSSProperties
}

const ModalGroupCheckBox = ({
                              visible,
                              options = [],
                              initialColumns = [],
                              style = { height: 400 },
                              onConfirm,
                              close
                            }: Props) => {

  const [selected, setSelected] = React.useState<any[]>(initialColumns);

  const onChange = (name: string) => (_: any) => {
    setSelected((prev: string[]) => {
      if (prev.includes(name)) return prev.filter((p: string) => p !== name);
      return [ ...prev, name ];
    });
  }

  const onOk = () => {
    if (onConfirm) onConfirm(selected);
    if (close) close();
  }

  return (
    <Modal
      title='Select Columns'
      visible={visible}
      onOk={onOk}
      onCancel={close}
    >
      <div style={{ ...style, maxHeight: 480, overflow: 'auto'}}>
        <Space direction='vertical' size='middle'>
          {options.map((option: Option, index:number) => (
            <Checkbox key={index} checked={selected.includes(option.value)} onChange={onChange(option.value)}>
              {option.label}
            </Checkbox>
          ))}
        </Space>
      </div>
    </Modal>
  );
}

export default ModalGroupCheckBox;
