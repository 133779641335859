import { GeneralHelpRoutes, GeneralHelpTreeStructure } from "./general_help";
import { HDRHelpRoutes, HDRHelpTreeStructure } from "./hdr_help";
import { prepareRoute, prepareRoutes } from "../helpers";

/*Prepare the routes for the General Help sections*/
const GeneralHelpTOC: any = GeneralHelpTreeStructure.map((route: any) => prepareRoute(GeneralHelpRoutes, route));
const GeneralHelpConfig: any = prepareRoutes(GeneralHelpTOC);

/*Prepare the routes for the HDR Help sections*/
const HDRHelpTOC: any = HDRHelpTreeStructure.map((route: any) => prepareRoute(HDRHelpRoutes, route));
const HDRHelpConfig: any = prepareRoutes(HDRHelpTOC);

export {
  GeneralHelpTOC,
  GeneralHelpRoutes,
  HDRHelpTOC,
  HDRHelpRoutes,
  GeneralHelpConfig,
  HDRHelpConfig
}