import React from "react";
//Components
import LimitSubGroup from "./limit";
//Other Libs
import { Button, Collapse } from "antd";
//Helpers
import { formatLimits } from "../../../../../helpers";
import withLimitDownload from "../../../../../components/hoc/with_limit_download";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadClientFile } from "../../../../../../../../../../../helpers";

const Panel = Collapse.Panel;
const OBB_KEY: string = 'Outstanding Borrowing Base';
const OBB_IDX: string = '4';

//Styles
const style = {
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#e3e3e3",
  width: "66.24%",
  fontSize: "16px"
}

interface HistoricalDownloadProps {
  vehicleId: string | number,
  proposalId: string | number,
}

const HistoricalDownload = ({ vehicleId, proposalId }: HistoricalDownloadProps) => {
  const onDownload = (event: any) => {
    event.stopPropagation();
    downloadClientFile({
      url: `/casavo/notes_manager/download_outstanding_borrowing_base/?vehicle_id=${vehicleId}&proposal_id=${proposalId}`,
      filename: "file.csv",
    });
  }
  return (
    <Button type="primary" icon={<DownloadOutlined/>} onClick={onDownload}>
      Historical Data - Download
    </Button>
  );
}

const showDownload = (groupName: string, activePanels: string | string[]): boolean => {
  return groupName === OBB_KEY && activePanels.includes(OBB_IDX);
}

interface Props {
  data?: any,
  currency?: any,
  vehicleId: string | number,
  proposalId: string | number,
}

const DrawndownAndWaterfallTable = ({ data, currency, vehicleId, proposalId }: Props) => {
  const [activePanels, setActivePanels] = React.useState<string | string[]>(["0"]);

  const setActiveKey = (key: string | string[]) => {
    setActivePanels(key);
  }

  const limitGroups: any [] = Object.keys(data);

  return (
    <Collapse ghost activeKey={activePanels} onChange={setActiveKey}>
      {limitGroups.map((groupName: any, index: any) => {
        const limitSubGroups: any [] = Object.keys(data[groupName]);
        const firstLimitGroup = index === 0;
        const panelHeader = firstLimitGroup ? "Drawdown and Waterfall" : groupName;
        return (
          <Panel
            key={index.toString()}
            header={<b>{panelHeader}</b>}
            extra={showDownload(groupName, activePanels) && <HistoricalDownload vehicleId={vehicleId} proposalId={proposalId}/>}
          >
            {firstLimitGroup && <div style={style}><b>{groupName}</b></div>}
            <LimitSubGroup
              firstLimitGroup={firstLimitGroup}
              limitSubGroup={limitSubGroups}
              currency={currency}
              data={data}
              groupName={groupName}
            />
          </Panel>
        );
      })}
    </Collapse>
  );
}

export default withLimitDownload(DrawndownAndWaterfallTable)({
  title: 'Drawdown And Waterfall',
  prepareData: (data: any) => {
    const categories = Object.keys(data)
    const newStructure: any = [];
    categories.map((category: any) => {
      const subCategories = Object.keys(data[category]);
      return subCategories.map((subCategory: any) => {
        const subGroupData: any = data[category][subCategory];
        {
          subGroupData.map(({limit_name, fulfillment, formatter}: any) => {
            newStructure.push({
              "CATEGORIES": category,
              "SUB CATEGORIES": subCategory,
              "WATERFALL VARIABLE": limit_name,
              "VALUE": formatLimits(fulfillment, formatter)
            })
          })
        }
      })
    })
    return newStructure;
  }
});
