//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../assets/theme/colors";
//Components
import { Col, Row } from "antd";
//Icons
import { UploadOutlined } from "@ant-design/icons";
//Custom Styles
const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 180px;
  height: 100%;
  text-align: center;
  //background: #fafafa;
  border: 1px dashed ${theme.palette.grayscale[2]};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: border-color .3s;
  transition: border-color .3s;

  :hover {
    border-color: ${theme.colors.primary};
  }
`
const iconStyle = {
  fontSize: 32,
  marginBottom: 4,
  color: theme.colors.blue
};

const UploadMessage = () => {
  return (
    <Layout>
      <Row className="columnDirection">
        <Col>
          <UploadOutlined style={iconStyle}/>
        </Col>
        <Col>
          <p>Click or drag files to this area for upload</p>
        </Col>
      </Row>
    </Layout>
  )
}

export default UploadMessage;