import React from 'react';
//Components
import { Modal } from "antd";
//Custom Components
import Details from "./views/details";
import InputTab from "./views/input_tab";
import FundingDate from "./views/funding";

const Content: any = {
  "details": {
    modalTitle: "Loan Purchase Details",
    modalWidth: '80%',
    Component: Details
  },
  "funding_date": {
    modalTitle: "Funding Date",
    modalWidth: 650,
    Component: FundingDate
  },
  "input_tab": {
    modalTitle: "Input Tab",
    modalWidth: 650,
    Component: InputTab
  }
}

const ModalContent = (props: any) => {

  const {viewType, visible, onCancel, vehicle, vehicleIdentifier, vehicleId, status, record, getData} = props;

  const isMCSVehicle = vehicleIdentifier === "mcs";

  const modalChild = Content.hasOwnProperty(viewType) && Content[viewType];

  if (!modalChild)
    return null;

  const {Component, modalTitle, modalWidth} = modalChild;

  return (
    <Modal title={modalTitle} visible={visible} onCancel={onCancel} footer={null} width={modalWidth}>
      <Component proposalId={record.id} vehicleId={vehicleId} record={record} getData={getData} onCancel={onCancel}
                 status={status} vehicle={vehicle} vehicleIdentifier={vehicleIdentifier} isMCSVehicle={isMCSVehicle}/>
    </Modal>
  );
};

export default ModalContent;