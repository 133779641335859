import React from 'react';
//Components
import { Input } from 'antd';
//Styles
import Styles from "./styles"
//Icons
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
//Constants
import { theme } from "../../../../assets/theme/colors";

//Custom Helpers
interface ICustomInput {
  type?: any,
  params?: any,
  required?: any,
  placeholder?: any,
  id?: any,
  iconRender?: any,
  style?: any,
  value?: any
}

const handleIconRender = (visible: any) => {
  return visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>;
};

const CustomInput = (props: ICustomInput) => {
  const {type, ...params} = props;

  const passwordInput: any = type === "password";

  const Component: any = passwordInput ? Input.Password : Input;

  if (passwordInput)
    params.iconRender = handleIconRender;

  params.style = {backgroundColor: theme.palette.grayscale[4]};

  return (
    <Styles>
      <Component  {...params}/>
    </Styles>
  );
};

export default CustomInput;

CustomInput.defaultProps = {
  type: "text"
};