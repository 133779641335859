import React from "react";
//Icons
import { MinusOutlined } from "@ant-design/icons";
//Helpers
import { formatters } from "../../../../../../../../../../../helpers";


const OtherCluster = ({data, currency}: any) => {
  return (
    <>
      {data.hasOwnProperty("realized") ?
        <div className="size12 bolder">Realized: {formatters.currency({
          value: data.realized,
          currency,
          precision: 0
        })}</div>
        : <MinusOutlined/>
      }
    </>
  )
}
export default OtherCluster;
