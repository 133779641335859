import React from 'react';
//Microservice
import Microservice from "./microservice";
//Redux Auth services
import { useGlobalApi } from "../../utils/hooks";

const CollectionManagement = () => {
  const {vehicles} = useGlobalApi();

  const originators = vehicles.filter((record: any) => record.collection_management_configs);

  return (
    <Microservice originators={originators}/>
  );
};


export default CollectionManagement;

