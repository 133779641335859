export const storage: any = {
  set(attr: string, record: string) {
    localStorage.setItem(attr, record);
  },
  get(attr: string) {
    try {
      return localStorage.getItem(attr);
    } catch (error) {
      return null
    }
  },
  clear(attr: string) {
    localStorage.removeItem(attr);
  },
  clearAll() {
    localStorage.clear();
  }
}