import React from 'react';
//Components
import { Checkbox, Col, Row } from "antd";
//Custom Components
import UploadButton from "../../../upload_button";
//Helpers
import { nonRequiredFields } from "../helpers";

const UploadFooter = (props: any) => {

  const {
    form, loading, percent, loanProposalCase, isCasavoVehicle, onCheckboxChange, paymentReportCase, onUpload
  } = props;

  const canUpload = () => {
    const keys = Object.keys(form);
    for (let key of keys)
      if (!nonRequiredFields.includes(key) && !form[key])
        return false;

    return true;
  };

  return (
    <>
      {loading && <UploadButton loading={loading} fileName="files" label="Send Files" percent={percent}/>}
      {!loading && <Row justify="space-between" className="mt16">
        <Col>
          <Row gutter={8}>
            {!paymentReportCase && <Col>
              <Checkbox checked={form.perform_dhc} onChange={onCheckboxChange("perform_dhc")}>
                Perform DHC
              </Checkbox>
            </Col>}
            {(loanProposalCase && !isCasavoVehicle) && <Col>
              <Checkbox checked={form.is_interest_payment} onChange={onCheckboxChange("is_interest_payment")}>
                Proceed with Notes Interest Calculation
              </Checkbox>
            </Col>}
          </Row>
        </Col>
        <Col>
          <UploadButton
            type="primary"
            fileName="files"
            label="Send Files"
            percent={percent}
            onClick={onUpload}
            disabled={!canUpload()}/>
        </Col>
      </Row>}
    </>
  );
};

export default UploadFooter;