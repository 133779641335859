import React from 'react';
//Components
import {Row, Col, Card} from "antd";
import SmartTable from "../../../../../design/table";
//Constants
import {columns} from "./config";

const Table = ({data}:any) => {
    return (
        <Row gutter={[8, 16]} style={{minHeight: 200}}>
            {Object.keys(data).map((title: any) => {
                const rows = []
                for (let subgroup in data[title]) {
                    let showSubgroup = true;
                    for (let row of data[title][subgroup]) {
                        rows.push({subgroup: subgroup, showSubgroup: showSubgroup, ...row})
                        showSubgroup = false
                    }
                }
                return (
                    <Col xs={24}>
                        <Card bordered={false} size="small" title={title}>
                            <SmartTable
                                dataSource={rows}
                                columns={columns}/>
                        </Card>
                    </Col>
                )
            })}
        </Row>

    );
};

export default Table;