import {message, notification} from "antd";
//Icons
import smallIcon from './assets/images/logo/small.png';
import largeIcon from './assets/images/logo/large.png';

notification.config({
    duration: 4,
    placement: "bottomRight"
});

message.config({
    maxCount: 3
});

const CURRENT_NAMESPACE = "equalizer";


const prod = {
    apiUrl: process.env.REACT_APP_SERVER_URL,
    idpUrl: process.env.REACT_APP_IDP_URL,
    collectionManagementUrl: process.env.REACT_APP_COLLECTION_MANAGEMENT_URL
};
const dev = {
    // apiUrl: 'http://localhost:8000',
    // apiUrl: 'http://192.168.1.158:8000/api',
    apiUrl: 'https://equalizer-backend-staging.service.cardoai.com/api',
    // apiUrl: 'https://equalizer-backend.service.cardoai.com/api',
    idpUrl: "https://idp-backend-staging.service.cardoai.com/api/",
    // idpUrl: "https://idp-backend.service.cardoai.com/api/",
    collectionManagementUrl: "https://collection-management-staging.service.cardoai.com/api/"
};

export const ENV = process.env.NODE_ENV;

const serverBaseUrl = process.env.NODE_ENV === 'development' ? dev.apiUrl : prod.apiUrl;
const idpBaseUrl = process.env.NODE_ENV === 'development' ? dev.idpUrl : prod.idpUrl;
const collectionManagementBaseUrl = process.env.NODE_ENV === 'development' ? dev.collectionManagementUrl : prod.collectionManagementUrl;


export default ENV === 'development' ? dev : prod;

const systemCurrency: any = {
    name: "Euro",
    abbreviation: "EUR",
    symbol: "€"
};

const siteConfig = {
    siteLogo: largeIcon,
    siteIcon: smallIcon,
    siteName: 'Securitization',
    footerText: `Securitization ©${new Date().getFullYear()}`,
};

export {
    siteConfig,
    idpBaseUrl,
    serverBaseUrl,
    systemCurrency,
    CURRENT_NAMESPACE,
    collectionManagementBaseUrl
};
