import React from "react";
//Other libs
import queryString from "query-string";
//Components
import { Skeleton } from "antd";
import { CustomTable, SimpleEmpty } from "@cardoai/components";
import TableHeaders from "./table_headers";
import TableBody from "./table_body";
//Constants
import { callApi } from "../../../../../../../../../helpers";

//Helper function to group records on same asset_name #
const groupCells = (data: any[]) => {
  const groupedRecords: any = {};

  function populateGroups() {
    for (let key in groupedRecords)
      if (groupedRecords.hasOwnProperty(key))
        data.forEach((column: any) => {
          if (column.asset_name === key)
            groupedRecords[key].push({...column});
        })
  }

  data.forEach((record: any) => {
    const groupingLabel = record.asset_name;
    if (!groupedRecords.hasOwnProperty(groupingLabel)) {
      groupedRecords[groupingLabel] = [];
    }
  })
  populateGroups();
  return groupedRecords;
}

interface Props {
  columns?: any,
  date?: any,
  vehicleId?: any,
  height?: any,
  currencyConfig?: any
}

const MainTable = (props: Props) => {
  const {columns, date, vehicleId, height, currencyConfig} = props;

  const [data, setData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const noDateSelected = date === "all"


  React.useEffect(() => {
    requestData();
  }, [vehicleId, date])

  const requestData = () => {
    const params: any = {
      vehicle_id: vehicleId,
    }

    if (!noDateSelected) {
      params.reference_date = date
    }

    const getCurrency = (data: any) => {
      const vehicleCurrencies = Object.keys(data);
      const currencyName = (typeof currencyConfig === "object") ? currencyConfig.abbreviation : currencyConfig;
      if (vehicleCurrencies.includes(currencyName)) {
        setData(data[currencyName])
      }
    }

    callApi({
      url: `/main/notes_manager/accounts/asset_sales/?${queryString.stringify(params)}`,
      onSuccess: (response: any) => getCurrency(response),
      onFinish: () => setLoading(false)
    })

  };


  if (!data || loading)
    return <Skeleton active/>

  const allRecords = data.records;

  if (!allRecords || !allRecords.length)
    return <SimpleEmpty height={height} description="No Data Available Yet"/>

  return (
    <CustomTable hover={false} size='small' className="mt16" maxHeight={700}>
      <TableHeaders columns={columns}/>
      <TableBody columns={columns} data={allRecords} preparedData={groupCells(allRecords)}/>
    </CustomTable>
  )
}

export default MainTable;
