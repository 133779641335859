const defaultTheme: any = {};

defaultTheme.colors = {
  primary: "#1890ff",
  primaryLight: "#40a9ff",
  primaryLighter: "#e6f7ff",
  primaryDarker: "#3A78F5",
  denim: "#126cbf",
  transparent: "transparent",
  white: "#fff",
  darkwhite: "#fafafa",
  lilywhite: "#e6f7ff",
  zircon: "#fafcff",
  silver: "#bfbfbf",
  mercury: "#e8e8e8",
  grey: "#8c8c8c",
  grey2: "#d8e1e5",
  greyshade1: "#ddd",
  dovegrey: "#666666",
  darkergrey: "#969597",
  darkgrey: "#424242",
  mineshaft: "#262626",
  black: "#000",
  lighterred: "#f44336",
  red: "#f5222d",
  volcano: "#fa541c",
  brown: "#A52A2A",
  googleplus: "#dd4b39",
  burgundy: "#9e002e",
  googleplusdarken: "#d73925",
  pomegranate: "#e14615",
  pomegranatedarker: "#CA3F13",
  amaranth: "#e0364c",
  darkorange: "#D17900",
  darkerorange: "#d95525",
  carrotorange: "#ed9416",
  orange: "#fa8c16",
  darkyellow: "#FEAC01",
  orangepeel: "#FF9800",
  supernova: "#f6cb06",
  gold: "#faad14",
  highlight: "#FFF176",
  paleyellow: "#fdf6df",
  yellow: "#fadb14",
  yellow2: "#FFCA28",
  yellowdarken: "#F2BD1B",
  energyyellow: "#f7d765",
  beige: "#fdcb6e",
  gin: "#e2efe3",
  darkgin: "#76b477",
  lime: "#a0d911",
  green: "#52c41a",
  green2: "#56a459",
  mossgreen: "#a1d99b",
  cyan: "#13c2c2",
  lightcyan: "#00cec9",
  blue: "#1890ff",
  pictonblue: "#52CCEB",
  malibu: "#69c0ff",
  darkenblue: "#3A78F5",
  toryblue: "#105EA8",
  darkblue: "#344e86",
  calypso: "#326b83",
  darkercalypso: "#2b5b80",
  facebookblue: "#3b5998",
  geekblue: "#2f54eb",
  geekblue2: "#d6e4ff",
  dogerblue: "#4990FF",
  dogerblue2: "#40a9ff",
  blueChill: "#1289A7",
  scienceBlue: "#096dd9",
  midnightblue: "#002766",
  bluecolumbia: "#91d5ff",
  cobaltBlue: "#0050b3",
  blueshade: "#3F51B5",
  bluedark: "#39435f",
  royalblue: "#3d31ee",
  lightblue: "#e6f7fe",
  aliceblue: "#f0f8ff",
  purple: "#722ed1",
  electricviolet: "#6534ff",
  lightpurple: "#9980FA",
  purple2: '#42299a',
  darkerpurple: '#9254de',
  purplejackson: "#1e3799",
  darkestpurple: "#422e62",
  indigo: "#4B0082",
  pink: "#FFC0CB",
  darkerpink: "#F75D81",
  magenta: "#eb2f96",
  lightmagenta: "#f759ab",
  lightred: "#ff4d4f",
  lightyellow: "#ffc069",
  lightgreen: "#a0d911",
  limegreen: "#7ED321",
  darkergreen: '#95de64',
  stronggreen: "#389e0d",
  mist: "#a5c7cd",
  teal: "#008080",
  lightteal: "#1E7482",
  darkteal: "#177483",
  darkerteal: "#14444d",


  warning: '#ffbf00', // 0: Warning
  success: '#00b16a', // 0: Success
  error: '#f5222d',   // 0: Error
}

defaultTheme.palette = {
  // Colors retrieved from https://flatuicolors.com/palette/us
  us: [
    '#00b894', // Mint Leaf
    '#00cec9', // Robin's Egg Blue
    '#74b9ff', // Green Darner Tail
    '#0984e3', // Electron Blue
    '#a29bfe', // Shy Moment
    '#6c5ce7', // Exodus fruit
    '#b2bec3', // Soothing breeze
    '#55efc4', // Light Greenish Blue
    '#81ecec', // Faded Poster
    '#fab1a0', //First Date
    '#ff7675', //Pink Glamour
    '#fd79a8', //Pico-8 Pink
    '#636e72', //American River
    '#fdcb6e', //Bright Yarrow
    '#e17055', //Orangeville
    '#d63031', //Chi-gong
    '#e84393', //Prunus Avium
    '#2d3436', //Dracula Orchid
    '#ffeaa7', //Sour Lemon
    '#dfe6e9', // City Lights
    '#3c6382', // Good Samaritan
    '#4a69bd', // Azraq Blue
    '#6a89cc', // Livid
    '#0a3d62', // Forest Blues
    '#ff6b6b',
    '#ee5253',
    '#5f27cd',
    '#341f97',
    '#48dbfb',
    '#0abde3',
    '#c8d6e5',
    '#ff9ff3',
    '#f368e0',
    '#00d2d3',
    '#01a3a4',
    '#feca57',
    '#ff9f43',
    '#54a0ff',
    '#2e86de',

  ],
  fr: [
    '#60a3bc', // Dupain
    '#82ccdd', // Spray
    '#079992', // Reef Encounter
    '#38ada9', // Waterfall
    '#78e08f', // Aurora Green
    '#b8e994', // Paradise Green,
    '#3c6382', // Good Samaritan
    '#4a69bd', // Azraq Blue
    '#6a89cc', // Livid
    '#0a3d62', // Forest Blues
  ],
  ca: [
    '#ff9ff3',
    '#f368e0',
    '#00d2d3',
    '#01a3a4',
    '#feca57',
    '#ff9f43',
    '#54a0ff',
    '#2e86de',
    '#ff6b6b',
    '#ee5253',
    '#5f27cd',
    '#341f97',
    '#48dbfb',
    '#0abde3',
    '#c8d6e5',
    '#8395a7',
    '#1dd1a1',
    '#10ac84',
    '#576574',
    '#222f3e'
  ],

  primary: [
    '#1890ff', // 0: Default
    '#3A78F5', // 1: Darken 4%
    '#3775F2', // 2: Darken 5%
    'rgba(68, 130, 255, 0.2)', // 3: Fade 20%
    '#4C8AFF', // 4: Lighten 3%
    'rgba(68, 130, 255, 0.75)', // 5: Fade 75%
    '#6AA8FF', // 6: Lighten 15%
    '#63A1FF', // 7: Lighten 12%
    '#3F7DFA', // 8: Darken 2%
    '#3369e7', // 9: Algolia color
    '#5896FF', // 10: Lighten 8%
    '#2b69e6', // 11:
    '#236cfe', // 12: darken 10%
    '#4d88ff', // 13: Lighten 5%
  ],

  secondary: [
    '#2d3446', // 0: DarkBlue
    '#f1f3f6', // 1: LightBluish
    '#788195', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F6F8FB', // 8: LighterBluish Lighten 2%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
  ],

  SectorBlueColors: [
    '#105EA8', //0
    '#2A78C2', //1
    '#4391DB', //2
    '#4da8e4', //3
    '#5DABF5', //4
    '#69c0ff', //5
    '#76C4FF', //6
    '#8FDDFF', //7
    '#bae7ff', //8
    '#97FFFF', //9
    '#A9F7FF', //10
    '#C3FFFF', //11
    '#DCFFFF', //12
    '#dbffff', //13
    '#dbffff',
    '#dbffff',
    '#dbffff',
    '#dbffff',
    '#dbffff',
    '#dbffff',
    '#dbffff',
  ],

  greenColors: [
    "#3F9886",
    "#4CA593",
    "#59B2A0",
    "#65BEAC",
    "#72CBB9",
    "#7FD8C6",
    "#8CE5D3",
    "#8CE5D3",
    "#8CE5D3",
    "#8CE5D3"
  ],

  structureChartColors: [
    "#4565f8",
    "#7babff",
    "#88C5FF",
    "#b3d7ff",
    "#cee6ff",
  ],

  diversificationChartColors: [
    '#2f33c7',
    "#4254f5",
    "#88C5FF",
    "#b0d1ff",
    "#3F9886",
    "#58B19F",
    "#72CBB9",
    "#674EC8",
    "#8067E1",
    "#8D74EE",
    "#9A81FB",
    "#A68DFF",
    "#B39AFF",
    "#C0A7FF",
    "#CDB4FF",
    "#D9C0FF",
    "#E6CDFF",
    "#F3DAFF",
    "#F3DAFF",
    "#F3DAFF",
    "#F3DAFF"
  ],

  grayscale: [
    '#bababa', // 0: GreyShade
    '#c1c1c1', // 1: GreyDark
    '#D8D8D8', // 2: Grey
    '#f1f1f1', // 3: GreyAlt
    '#F3F3F3', // 4: GreyLight
    '#fafafa', // 5: DarkWhite
    '#F9F9F9', // 6: DarkerWhite
    '#fcfcfc', // 7: #fff Darken 1%
    '#eeeeee', // 8:
    '#fbfbfb', // 9:
    '#f5f5f5', // 10:
    '#f7f8f9', // 11: today-highlight-bg
    '#999', // 12: Dusty Grey
    '#e0e0e0', // 13
  ],

  text: [
    '#323332', // 0: Heading
    '#595959', // 1: HeadingLight
    '#979797', // 2: Text
    '#797979', // 3: TextDark
    '#6a6c6a', // 4: Heading Lighten 22%
  ],

  border: [
    '#e9e9e9', // 0: Border
    '#d8d8d8', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)', // 4:
    '#f7f7f7', // 5
    '#dedede', // 6
    '#d1d1d1', // 7
    '#c4c4c4', // 8
    '#8c8c8c', // 9
    '#ececec', //10
  ],

  nivo: ['#d76445', '#f47560', '#e8c1a0', '#97e3d5', '#61cdbb', '#00b0a7'],
};

defaultTheme.fonts = {
  primary: 'Roboto, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export const theme = defaultTheme;