import React from 'react';
//Components
import { Row, Col, Tag } from "antd";
import HandShake from "../../../../icons/hand_shake";
import MultipleAvatars from "../../../../design/multiple_avatars";

const items = [
  {}, {}, {}, {}, {},
  {}, {}, {}, {}, {},
  {}, {}, {}, {}, {},
]

const RejectedFlow = () => {
  return (
    <Row
      align="middle"
      justify="space-between"
      style={{padding: 16, borderRadius: '12px', border: '1px solid #E3DDE6'}}>
      <Col xs={8}>
        <Row gutter={8}>
          <Col>
            <HandShake/>
          </Col>
          <Col>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Row gutter={8}>
                  <Col style={{fontWeight: 500}}>
                    Funding Proposal
                  </Col>
                  <Col>
                    <Tag style={{color: '#FF4C55'}} color="#FFE6E8">Rejected</Tag>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} style={{
                color: '#8F8796',
                fontWeight: 400,
                fontSize: 12
              }}>
                Submit founding proposal to the investor
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={16}>
        <Row justify="end" gutter={32}>
          <Col>
            <Row gutter={8} align="middle">
              <Col style={{
                color: '#FF4C55',
                fontWeight: 400
              }}>
                Rejected By:
              </Col>
              <Col style={{minWidth: 200}}>
                <MultipleAvatars radius={20} maxLimit={8} config={[...items, ...items]}/>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={8} align="middle">
              <Col style={{
                color: '#1EA977',
                fontWeight: 400
              }}>
                Accepted:
              </Col>
              <Col style={{minWidth: 200}}>
                <MultipleAvatars radius={20} maxLimit={5} config={items}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RejectedFlow;