import React from 'react';
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

const config: any = {
    "cure": {
        icon: (props: any) => <PlusOutlined style={{color: '#fff', fontSize: 11}}/>,
    },
    "waive": {
        icon: (props: any) => <MinusOutlined style={{fontSize: 11, color: '#fff', transform: 'rotate(45deg)'}}/>,
    }
}

const EligibilityAction = ({type, breached = false, ...rest}: any) => {
    const {icon: Icon} = config[type];

    return (
        <div style={{position: 'relative', cursor: 'pointer'}} {...rest}>
            <div style={{
                width: 24,
                height: 24,
                padding: 4,
                display: 'flex',
                borderRadius: '50%',
                alignItems: 'center',
                background: '#8F8796',
                boxSizing: 'border-box',
                justifyContent: 'center',
            }}>
                <Icon/>
            </div>
            {breached && (
                <div style={{
                    top: 0,
                    right: 0,
                    width: 8,
                    height: 8,
                    background: 'red',
                    borderRadius: '50%',
                    position: "absolute",
                    border: '1px solid #fff'
                }}/>
            )}
        </div>
    );
};

export default EligibilityAction;