import React from 'react';
//Google Analytics
import { Analytics } from "../../../../helpers";
//Components
import { Row } from "antd";
//Custom Components
import { RadioGroup } from "@cardoai/components";
import ContributorsChart from "./scale_chart/contributors_chart";

interface IContributors {
  queryStr?: string,
  displayRating?: boolean
}

const Contributors = (props: IContributors) => {
  const {queryStr, displayRating} = props;
  const [entity, setEntity] = React.useState('sector');

  const filterConfig = [
    {key: 'sector', label: 'Sector'},
    {key: 'loan_rating', label: "Loan Rating", disabled: true,},
    {key: 'region', label: 'Region'},
    {key: 'loan_type', label: 'Loan Type'}
  ];

  const ratingOption: any = filterConfig.find(record => record.key === "loan_rating");

  if (displayRating)
    ratingOption.disabled = false;

  const onEntityChange = (entityValue: any) => {
    setEntity(entityValue);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: 'Selected dataset at Contributors Chart',
      label: entityValue
    });
  }

  return (
    <>
      <Row justify="end">
        <RadioGroup value={entity} minWidth={140} direction='row' entries={filterConfig} onChange={onEntityChange}/>
      </Row>
      <ContributorsChart queryStr={queryStr} entity={entity}/>
    </>
  )
}

export default Contributors;


