import React from 'react';
import {Col, Row} from "antd";
import File from "../../../../../../design/file";
import {createFileName, downloadClientFile} from "../../../../../../../../helpers";
import {useProposal} from "../../../../../../context";

const Inputs = () => {
    const {proposal} = useProposal();

    return (
        <Row gutter={16}>
            <Col xs={11}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Row gutter={[8, 8]}>
                            <Col xs={24} style={{
                                fontSize: 20,
                                fontWeight: 600,
                                color: '#151219',
                            }}>
                                Filled Form
                            </Col>
                            <Col xs={24} style={{
                                fontWeight: 400,
                                color: '#8F8796'
                            }}>
                                Below you can download filled input form.
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <File
                            title="user_data_file"
                            onDownload={() => {
                                downloadClientFile({
                                    link: true,
                                    url: proposal.user_input_file,
                                    filename: createFileName('user_input_file', 'xlsx'),
                                });
                            }}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Inputs;