import React from 'react';
//Custom Components
import { CustomTable } from "@cardoai/components";
//Helpers
import { formatPercent, formatThousands } from "../../../../../helpers";
//Custom Components
import withLimitDownload
  from "../../../../spv/components/management/notes_section/limits/components/hoc/with_limit_download";

const LuzzattiHDRSummaryStats = ({data}: any) => {

  return (
    <div>
      <CustomTable hover height={600}>
        <thead style={{
          fontSize: 14,
          background: 'white',
          position: 'sticky',
          top: 0,
        }}>
        <th/>
        <th>All</th>
        <th>Secured</th>
        <th>Secured %</th>
        <th>Unsecured</th>
        <th>Unsecured %</th>
        </thead>
        <tbody>
        {data.map((category: any) => {
          const styles: any = {
            float: 'left'
          }
          return (
            <>
              <tr style={{backgroundColor: '#d9d9d9'}}>
                <td colSpan={6}>
                  <span style={styles}>{category.title}</span>
                </td>
              </tr>
              {category.rows.map((row: any) => {
                return (
                  <tr>
                    <th>{row.name}</th>
                    <th>{formatThousands(row.all,0) || "-"}</th>
                    <th>{formatThousands(row.secured,0) || "-"}</th>
                    <th>{formatPercent(row.secured_percentage, 0) || "-"}</th>
                    <th>{formatThousands(row.unsecured,0) || "-"}</th>
                    <th>{formatPercent(row.unsecured_percentage, 0) || "-"}</th>
                  </tr>
                )
              })}
            </>
          )
        })}
        </tbody>
      </CustomTable>
    </div>
  );
};

export default withLimitDownload(LuzzattiHDRSummaryStats)({
  title: "Summary Statistics",
  prepareData: (data: any[]) => {
    const finalData: any = [];
    data.forEach((category: any) => {
      category.rows.forEach((row: any) => {
        finalData.push({
          "Main Category": category.title,
          "Executive Summary": row.name.replace("#", "No."),  // '#' break CSV download
          "All": row['all'] || "",
          "Secured": row['secured'] || "",
          "Secured %": row['secured_percentage'] || "",
          "Unsecured": row['unsecured'] || "",
          "Unsecured %": row['unsecured_percentage'] || ""
        })
      })
    })
    return finalData;
  }
});