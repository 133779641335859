import React from 'react';
//Other Libs
import queryString from "query-string";
//Components
import { Card, Col, Input, Row, Select } from "antd";
import Header from "./header";
import { SmartTable } from "../../../../components";
//Constants
import { columns, filters } from "./config";
//Helpers
import { callApi } from "../api";
import { authStorage } from "../../../../helpers";


const contentStyle = {
  minWidth: 140
}

interface Props {
  entity?: any,
  type?: any,
  status?: any,
  onChange?: () => any
}

const Table = (props: Props) => {

  const {entity, type, status} = props;
  const [searchType, setSearchType] = React.useState<any>('identifier__icontains');

  const references = React.useRef<any>({
    table: React.useRef(),
    header: React.useRef()
  })

  const [state, setState] = React.useState<any>({
    search: null,
    type: null,
    status: null
  })

  const onUpdate = () => {
    for (let key in references.current) {
      if (references.current.hasOwnProperty(key)) {
        const reference = references.current[key];
        if (reference && reference.current)
          reference.current.getData();
      }
    }
  }

  const onGetData = (config: { page: any; pageSize: any; setLoading: any; setData: any; cancelPreviousCalls: any; filters: any; }) => {
    const {page, pageSize, setLoading, setData, cancelPreviousCalls, filters} = config;

    return callApi({
      url: `/entity/${entity}/loans/?offset=${page - 1}&limit=${pageSize}&${filters}`,
      setLoading: setLoading,
      onSuccess: (response: { count: any; results: any; }) => {
        setData({
          count: response.count,
          records: response.results
        })
      },
      cancelPreviousCalls: cancelPreviousCalls
    })
  }

  const onChange = (attr: string) => (event: any) => {
    return setState((prevState: any) => {
      if (attr === 'search')
        event = event.target.value;
      return {
        ...prevState,
        [attr]: event
      }
    })
  }

  const params: any = {
    role: authStorage.getRole()
  };

  for (let key in state) {
    const value = state[key];

    if (value) {
      if (key === 'search')
        params[searchType] = state[key];
      else
        params[key] = state[key];
    }
  }

  const query = queryString.stringify(params);

  return (
    <>
      <Card>
        <Header
          entity={entity}
          filters={query}
          ref={references.current.header}/>
      </Card>
      <br/>
      <Card size="small">
        <Row justify="end">
          <Col>
            <Row gutter={16}>
              <Col>
                <Row gutter={8}>
                  <Col>
                    <Select
                      allowClear
                      value={type}
                      style={contentStyle}
                      placeholder="Select Type"
                      onChange={onChange("type")}
                    >
                      {filters.type.map(type => {
                        return (
                          <Select.Option value={type.key}>
                            {type.label}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      allowClear
                      value={status}
                      style={contentStyle}
                      placeholder="Select Status"
                      onChange={onChange("status")}
                    >
                      {filters.status.map(status => {
                        return (
                          <Select.Option value={status.key}>
                            {status.label}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={8}>
                  <Col>
                    <Input
                      value={state.search}
                      placeholder="Search"
                      style={{minWidth: 280}}
                      onChange={onChange('search')}/>
                  </Col>
                  <Col>
                    <Select
                      value={searchType}
                      allowClear={false}
                      style={contentStyle}
                      placeholder="Search by"
                      onChange={setSearchType}>
                      {filters.searchType.map(search => {
                        return (
                          <Select.Option value={search.key}>
                            {search.label}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <SmartTable
          height={400}
          filters={query}
          columns={columns}
          onUpdate={onUpdate}
          onGetData={onGetData}
          cancelPreviousCalls={true}
          ref={references.current.table}
        />
      </Card>
    </>
  );
};

export default Table;
