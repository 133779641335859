import React from 'react';
//Components
import LoanBarChart from "./components/loan_bar_chart";
import LoanPieChart from "./components/loan_pie_chart";
import LoanStatsTable from "./components/loan_stats_table";


interface IViewAsTableAndBarAndPieCHart {
  categoryName?: any,
  data?: any,
  selectedView?: any,
}

const NPLLoanViews = (props: IViewAsTableAndBarAndPieCHart) => {

  const {categoryName, data, selectedView} = props;

  return (
    <div>
      {selectedView === "Bar" &&
      <LoanBarChart data={data} category={categoryName}/>}
      {selectedView === "Pie" &&
      <LoanPieChart category={categoryName} data={data}/>}
      {selectedView === "Table" &&
      <LoanStatsTable categoryName={categoryName} data={data}/>}
    </div>
  );
};

export default NPLLoanViews;