import React, {useEffect, useRef, useState} from 'react';
//Other Libs
import {produce} from "immer";
//Components
import {Button, Col, Row, Space, Spin} from "antd";
import {Break, Panel} from "@cardoai/components";
import Scenario from "./scenario";
import SimulationResults from "./simulation_results";
import Loader from "../../../../components/lottie_loader";
//Icons
import { SettingOutlined } from "@ant-design/icons";
//Helpers
import { FIELD } from "../../config";
import notifications from "../../../../components/notifications";
import { client, generateUrl, getRandomKey, useQuery } from "../../../../helpers";
//Styles
const styles = {
  button: {
    marginLeft: 4,
    minWidth: 120
  }
};
//Custom Helpers
const MAX_SIMULATIONS = 3;

const spinIndicator = <Loader/>;

const generateEmptyScenario = (type = "fixed", record = null) => {
  return {
    type: type,
    key: getRandomKey(),
    initialConfig: record,
  }
}

const initialScenario = generateEmptyScenario("fixed");

const Scenarios = (props: any) => {
  const {vehicleId} = props;

  const parentReference: any = useRef({});
  const [selected, setSelected] = useState(initialScenario.key);
  const simulationResultsReference: any = useRef(null)
  const [loadingResults, setLoadingResults] = useState(false);
  const [simulationResults, setSimulationResults] = useState<any>(null);
  const [createdScenarios, setCreatedScenarios] = useState([initialScenario]);

  useEffect(() => {
    const validKeys = createdScenarios.map(rec => rec.key);
    for (let key in parentReference.current) {
      const valid = validKeys.includes(key);
      if (!valid)
        delete parentReference.current[key];
    }
  }, [createdScenarios])

  const prepareGroupEndpoint = () => {
    return generateUrl('/lab/scenarios/get_selection_list', {vehicle_id: vehicleId})
  }

  const groupResponse = useQuery({url: prepareGroupEndpoint()});

  const applyRules = (fields: any, selected: any) => {
    const groupOptions = groupResponse.data;

    if (!groupOptions)
      return fields;

    return produce(fields, (draftState: any) => {
      const initialGroup = draftState[FIELD.group][0];
      const selectedGroup = groupOptions.find((record: any) => record.label === initialGroup.selection);

      /*Rule => Validate Days Delinquent Field*/
      if (!draftState[FIELD.daysDelinquent] || draftState[FIELD.daysDelinquent] < 30)
        draftState[FIELD.daysDelinquent] = 0;

      /*Rule => filter out non-selected records*/
      initialGroup.records = initialGroup.records.filter((record: any) => selected.includes(record.label))

      /*Rule => remove all record in case if the reset is selected*/
      if (selected.includes("All") && selected.length > 1) {

        const totalRecords = selectedGroup.records.length;

        if (totalRecords === selected.length)
          initialGroup.records = initialGroup.records.filter((record: any) => record.label !== "All");
      }

      /*Rule => Replace null values with zeros */
      const keysWithNoPeriod = ["recovery_lag", "recovery_rate"];

      for (let record of initialGroup.records) {
        const list = record.field_list;
        const label = record.label;
        const option = selectedGroup.records.find((rec: any) => rec.label === label);
        for (let key in list) {
          if (list.hasOwnProperty(key)) {
            if (!list[key]) {
              if (keysWithNoPeriod.includes(key)) {
                list[key] = 0;
              } else {
                const values: any = {};
                option.period.forEach((period: any) => values[period.label] = 0)
                list[key] = values
              }
            }
          }
        }
      }
    })
  }

  const scrollToResults = () => {
    simulationResultsReference && simulationResultsReference.current.scrollIntoView()
  }

  const handleRun = () => {
    const scenarios = [];

    setLoadingResults(true);

    for (let key in parentReference.current) {
      if (parentReference.current.hasOwnProperty(key)) {
        const config = parentReference.current[key];
        const scenario = applyRules(config.fields, config.selected);
        scenarios.push(scenario);
      }
    }

    if (!scenarios || loadingResults)
      return;

    // Function to fetch scenario result.
    const fetchScenario = async (scenario: any) => {
      return await client.post('/lab/scenarios/run_simulation/', {
        vehicle_id: vehicleId,
        scenarios: [scenario]
      })
    }

    // Iterates all scenarios and returns their result.
    const fetchScenarios = (scenarios: any) => {
      const requests = scenarios.map((scenario: any) => fetchScenario(scenario))
      return Promise.all(requests)
    }

    // Modify scenarios Result.
    fetchScenarios(scenarios).then(data => {
      const results = data.map((result, index) => {
        const currentResult = result[0];
        const name = currentResult.name || `Scenario ${index + 1}`;
        return {
          ...currentResult,
          name: name
        }
      });

      const invalidResults = [];

      results.forEach(record => {
        const name = record.name;
        const similar = results.filter(rec => rec.name === name);
        if (similar.length > 1)
          invalidResults.push(name);
      })

      if (invalidResults.length)
        return notifications.warning("You cannot use the same name for different scenarios.");

      setSimulationResults(results);
      scrollToResults();
      setLoadingResults(false);
    }).catch(error => {
      setLoadingResults(false);
    })

  };

  const handleCreate = () => {
    const scenario = generateEmptyScenario("create");
    updateScenarios(scenario);
  };

  const updateScenarios = (record: any) => {
    setCreatedScenarios(prevState => ([...prevState, record]))
    setSelected(record.key)
  };

  const handleReplicate = () => {
    if (!parentReference.current)
      return;

    const reference = parentReference.current[selected];
    const fields = reference.fields;

    let fieldName = fields.name;

    if (fieldName)
      fieldName += " — Copy";
    else
      fieldName = "scenario Name";

    const scenario = generateEmptyScenario("replicate", {...fields, name: fieldName});

    updateScenarios(scenario);
  };

  const handleClear = (key: any) => {
    const length = createdScenarios.length;
    const index = createdScenarios.findIndex(rec => rec.key === key);
    const nextIndex = index + 1;

    if (nextIndex < length) {
      const record = createdScenarios[nextIndex];
      setSelected(record.key)
    } else {
      const record = createdScenarios[nextIndex - 2];
      setSelected(record.key)
    }

    setCreatedScenarios(prevState => [...prevState].filter(record => record.key !== key)
    )
  };

  const allowCreating = createdScenarios.length < MAX_SIMULATIONS;

  const params = {
    vehicleId: vehicleId,
    onClear: handleClear,
    onReplicate: handleReplicate,
    allowCreating: allowCreating,
    groupOptions: groupResponse.data,
    loadingOptions: groupResponse.loading
  };

  const scenariosSelection = createdScenarios.map((record, index) => {
    const {key} = record;
    const text = `Scenario ${index + 1}`;
    const type = key === selected ? "primary" : "default";

    let style = {};

    if (index !== 0)
      style = styles.button;

    const onEdit = () => {
      setSelected(record.key);
    }

    return (
      <Button type={type} style={style} onClick={onEdit} key={text} className="fade-in">
        {text}
      </Button>
    )
  });

  const scenariosList = createdScenarios.map(record => {
    const {key, type, initialConfig} = record;

    const active = record.key === selected;

    const styles: any = {};

    if (!active)
      styles.display = "none";

    return (
      <section style={styles} key={key}>
        <Scenario
          {...params}
          scenarioKey={key}
          scenarioType={type}
          initialConfig={initialConfig}
          reference={parentReference.current}/>
      </section>
    )
  })

  return (
    <Spin
      size='large'
      indicator={spinIndicator}
      spinning={loadingResults}
      style={loadingResults ? {cursor: 'wait'} : {}}>
      <section style={{opacity: loadingResults ? 0.3 : 1}}>
        <Row justify="space-between">
          <Col>
            {scenariosSelection}
            <Button
              type={"dashed"}
              style={styles.button}
              onClick={handleCreate}
              disabled={!allowCreating}>
              Create New
            </Button>
            <Button
              type={"dashed"}
              style={styles.button}
              onClick={handleReplicate}
              disabled={!allowCreating}>
              Replicate
            </Button>
          </Col>
          <Col>
            <Button
              ghost
              type="primary"
              onClick={handleRun}
              style={styles.button}
              loading={loadingResults}>
              <Space>
                <SettingOutlined spin/>
                <span> {loadingResults ? "Running" : "Run Cashflow Simulation"}</span>
              </Space>
            </Button>
          </Col>
        </Row>
        <Break size='large'/>
        {scenariosList}
      </section>
      <Break size='large'/>
      {simulationResults && <Panel defaultOpen header="Simulation Results">
        <SimulationResults data={simulationResults}/>
        <div ref={simulationResultsReference}/>
      </Panel>}
    </Spin>
  )
};

export default Scenarios;