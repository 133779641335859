import React from 'react';
//Components
import { Card } from "antd";
import Documents from '../documents';
import { HorizontalCollapse } from "@cardoai/components";
//Config
import { config, DOCUMENT_IDENTIFIER } from "../../config";
import { actionKey } from "../../../../constants";
import { useAuthorization } from "../../../../utils/hooks";

interface IContracts {
  vehicle: any,
  vehicleId: any,
  collapseReference: any,
  triggerCollapse: any,
}

const Contracts = (props: IContracts) => {
  const {vehicle, vehicleId, collapseReference, triggerCollapse} = props;

  const authorization: any = useAuthorization();

  const dealDocuments = authorization.canPerformAction(actionKey.getDealDocuments, vehicleId) && (
    <Card size='small' title="Deal Documents" bordered={false}>
      <Documents
        vehicle={vehicle}
        vehicleId={vehicleId}
        title="Import New Deal Document"
        documentIdentifier={DOCUMENT_IDENTIFIER.deals}
        config={config[DOCUMENT_IDENTIFIER.deals]}
        triggerCollapse={triggerCollapse('left')}/>
    </Card>
  );

  const otherDocuments: any = authorization.canPerformAction(actionKey.getOtherDocuments, vehicleId) && (
    <Card size='small' title="Other Documents" bordered={false}>
      <Documents
        vehicle={vehicle}
        vehicleId={vehicleId}
        title="Import Other Document"
        documentIdentifier={DOCUMENT_IDENTIFIER.otherDocuments}
        config={config[DOCUMENT_IDENTIFIER.otherDocuments]}
        triggerCollapse={triggerCollapse('right')}
      />
    </Card>
  );

  return (
    <HorizontalCollapse
      ref={collapseReference}
      activePanel='both'
      nodes={[
        {
          text: "Deal Documents",
          content: dealDocuments
        },
        {
          text: "Other Documents",
          content: otherDocuments
        }
      ]}
    />
  );
};

export default Contracts;
