import { theme } from "../../../../../../../assets/theme/colors";
//Constants
export default [
  {
    label: "Principal and Interest",
    key: "principal_and_interest",
    color: theme.colors.blueChill
  },
  {
    label: "Interest",
    key: "interest",
    color: theme.colors.darkorange
  },
  {
    label: "Principal Only",
    key: "principal",
    color: theme.colors.darkblue
  },
  {
    label: "No Payment",
    key: "no_payment",
    color: theme.colors.lightpurple
  },
];