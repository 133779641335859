import React from "react";
//Components
import { Select, Skeleton } from "antd";
//Helpers
import { stopPropagation } from "../../../../helpers";

const {Option} = Select;

interface ISelectLender {
  onLenderSelect: any
  lenders: any
}

const SelectLender = (props: ISelectLender) => {
  const {onLenderSelect, lenders} = props;


  if (!lenders)
    return <Skeleton/>

  return (
    <Select onClick={stopPropagation} onChange={onLenderSelect} style={{width: 150}} placeholder="Filter by Lender">
      <Option key='none'>None</Option>
      {lenders?.map((lender: any) => (
        <Option key={lender}>
          {lender}
        </Option>
      ))
      }
    </Select>
  )
};


export default SelectLender;