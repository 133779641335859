import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../assets";

const HDRAccess = () => {

  const description =
    <div style={{textAlign: 'justify'}}>
      <div>
        The HDR Module is a part of the Securitization platform, and can be accessed through the main module by choosing
        the type of user who will access this information.
      </div>
      <br/>
      <div>
        Both ‘HDR Servicer' and ‘HDR Investor’ have access to the module and almost all
        respective information, with the difference that a HDR Investor doesn’t have access to debtor identification
        information which are part of the portfolio.
      </div>
      <br/>
      <div>
        The HDR environment consists of several modules; HDR Main space, Loans Management, Documentation,
        Synchronization.
      </div>
    </div>

  return (
    <Row>
      <Col sm={16}>
        <CustomImage
          description={description}
          alt="Access Page"
          src={assets.images.homeModule.access}
        />
      </Col>
    </Row>
  );
};

export default HDRAccess;
