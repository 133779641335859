import React from "react";
import { useWaiverContext } from "../../calculation_waiver/calculation_waiver_provider";

const UpdateCalculationWavierDisplay = ({ record }: any) => {
  const waiverContext: any = useWaiverContext();
  const loanExists = waiverContext.initialLoan.find(
    (l: any) => l.loan === record.loan
  );

  return <>{loanExists ? loanExists.exclusion_type : "-"}</>;
};

export default UpdateCalculationWavierDisplay;
