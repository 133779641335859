import React from "react";
//Other Libs
import styled from "styled-components";
//Components
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { theme } from "../../../../../assets/theme/colors";

//Constants
const Styles = styled.section`
  .fullHeight {
    height: 100%;
  }

  .title {
    font-weight: bolder;
  }

  .graphs-container {
    max-height: 600px;
    overflow-y: auto;
  }

  .graph-wrapper {
    margin: 4px;

    .ant-card-body {
      padding-bottom: 0;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .ant-card-head {
      background-color: ${theme.colors.mercury};

      .ant-card-head-wrapper {
        font-size: 14px;
        text-align: center;
      }
    }

    .info {
      margin-top: 24px;
    }
`;

//Helper Functions
const Financials = () => (
  <Styles>
    <SimpleEmpty centered description="No data"/>
  </Styles>
);

export default Financials;
