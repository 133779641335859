import React from "react";
//Other Libs
import produce from "immer";
//Components
import { Checkbox, Col, Row, Tag } from "antd";
import { Draggable } from "@cardoai/components";
//Custom Components
import { GroupCard } from "./styles";
//Helpers
import { splitRecords } from "../../../../../helpers";
//Constants
const styles: any = {
  width: '100%',
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 4,
  minHeight: 50,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
}

const WithCheckBox = (props: any) => {
  const content = (
    <Row align="middle" gutter={8}>
      <Col>
        <Checkbox {...props} />
      </Col>
      <Col>
        {props.label}
      </Col>
    </Row>
  )

  if (!props.useTag)
    return content;

  return (
    <Tag style={styles}>
      {content}
    </Tag>
  )
};

WithCheckBox.defaultProps = {
  useTag: true
}

const Title = (props: any) => {
  const {onClick, ...rest} = props;
  return (
    <div onClick={onClick} className="flexCenter">
      <WithCheckBox
        {...rest}
        useTag={false}/>
    </div>
  )
}

const Group = (props: any) => {
  const {group, showHeader, update} = props;

  let maxEntries = 5;

  if (group.key === "General")
    maxEntries = 4;

  const groupedColumns = splitRecords(group.columns, maxEntries);

  const findGroup = (records: any) => {
    return records.find((g: any) => g.key === group.key);
  }

  const findColumn = (record: any, key: any) => {
    return record.columns.find((c: any) => c.key === key)
  }

  const toggleSelected = (record: any) => {
    record.selected = !record.selected;
  }

  const findTotalSelected = (record: any) => {
    return record.columns.filter((c: any) => c.selected).length;
  }

  const onGroupUpdate = () => {
    update((previous: any) => {
      return produce(previous, (draft: any) => {
        const currentGroup = findGroup(draft);
        toggleSelected(currentGroup);
        currentGroup.columns.forEach((c: any) => c.selected = currentGroup.selected)
      })
    })
  };

  const onColumnUpdate = (col: any) => () => {
    if (col.disableSelection && col.selected)
      return;

    update((previous: any) => {
      return produce(previous, (draft: any) => {
        const currentGroup = findGroup(draft);
        currentGroup.selected = true;
        if (!col.selected) {
          currentGroup.columns.forEach((c: any) => {
            if (c.disableSelection) c.selected = true;
          })
        } else {
          if (!col.disableSelection) {
            const currentSelected = currentGroup.columns.filter((c: any) => c.selected && !c.disableSelection).length;
            currentGroup.columns.forEach((c: any) => {
              if (c.disableSelection && currentSelected <= 1) c.selected = false;
            })
          }
        }
        const column = findColumn(currentGroup, col.key);

        if (!col.disableSelection)
          toggleSelected(column);
        const totalSelected = findTotalSelected(currentGroup);
        if (!totalSelected)
          currentGroup.selected = false
      })
    })
  };

  const handleColumnChange = (start: any, end: any) => {
    update((previous: any) => {
      return produce(previous, (draft: any) => {
        const currentGroup = draft.find((g: any) => g.key === group.key);
        const findIndex = (key: any) => currentGroup.columns.findIndex((c: any) => c.key === key);
        const startIndex = findIndex(start);
        const endIndex = findIndex(end);
        let temp = currentGroup.columns[startIndex];
        currentGroup.columns[startIndex] = currentGroup.columns[endIndex];
        currentGroup.columns[endIndex] = temp;
      })
    })
  }

  let groupIntermediate = false;

  if (group.selected) {
    const totalSelected = findTotalSelected(group);
    if (totalSelected > 0)
      groupIntermediate = totalSelected !== group.columns.length;
  }

  const groupParams: any = {
    label: group.label,
    onClick: onGroupUpdate
  };

  if (groupIntermediate)
    groupParams.indeterminate = true
  else
    groupParams.checked = group.selected

  if (group.columns.length === 0)
    return null;

  return (
    <GroupCard
      size="small"
      title={showHeader && <Title {...groupParams}/>}>
      <Draggable nested={true} onChange={handleColumnChange}>
        <Row gutter={8}>
          {groupedColumns.map((cols: any, index: number) => {

            return (
              <Col key={index + "draggable"}>
                {
                  // @ts-ignore
                  <Row draggableChildren={true} style={{flexDirection: 'column'}}>
                    {cols.map((col: any) => {
                      const key = col.key;
                      const disabled = col.disableSelection;
                      return (
                        <Col
                          key={key}
                          style={styles}
                          onClick={onColumnUpdate(col)}>
                          <WithCheckBox
                            label={col.label}
                            disabled={disabled}
                            checked={col.selected}/>
                        </Col>
                      )
                    })}
                  </Row>
                }

              </Col>
            )
          })}
        </Row>
      </Draggable>
    </GroupCard>
  )
}

export default Group;