import React from 'react';
//Other Libs
//Icons
import { FileTextOutlined } from "@ant-design/icons";
//Components
import { Button, Col, Row } from "antd";
//Helpers
import { downloadArrayOfObjectsAsCSV, isEmpty } from "../../../../../../../../helpers";
import { getDelimiter } from "../../../../../../../npl_feature/helpers";
import { DownloadAsCSV } from "../../../../../../../../components";

const withLimitDownload = (Component: any) => (config: any) => (props: any) => {

  /**
   * This is a HOC that wraps the limit components in aspire mostly and adds the Download Excel button for each of them.
   * The HOC takes the "formattedData" from the configuration as the Excel is produced by FE not BE, and it might
   * look different from the table that shows the information.
   *
   * @params: Component -> the limits component being wrapped
   * @params: config -> configurations for the HOC
   * @params: props -> props of the Component being wrapped
   */

  const {prepareData, title} = config;
  const {data, ...rest} = props;

  const handleDownload = (_: any, {decimalSeparator}: any) => {
    const formattedData = prepareData(data, rest);

    try {
      const columnDelimiter = getDelimiter(decimalSeparator)
      downloadArrayOfObjectsAsCSV(formattedData, title, true, {
        columnDelimiter,
        decimalSeparator,
        canConvertDecimalSeparator: true
      });
    } catch (e) {
      console.error('export error', e);
    }
  }

  return (
    <>
      <Row justify='end' className="mb16">
        <Col>
          <DownloadAsCSV disabled={isEmpty((data))} onDownload={handleDownload}>
            <Button
              size='small'
              icon={<FileTextOutlined/>}
            />
          </DownloadAsCSV>
        </Col>
      </Row>
      <Component {...props}/>
    </>
  );
};

export default withLimitDownload;
