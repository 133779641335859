import React from 'react';
import queryString from "query-string";
import {useToggle, useDidUpdate} from "@cardoai/utils";
import Container from "../container";
import Apply from "./components/apply";
import Actions from "./components/actions";
import Checker from "./components/checker";
import {groupedColumns} from "./config";
import {statusName} from "../../../../../config";
import {useProposal} from "../../../../../context";
import Description from "./components/description";
import SmartTable from "../../../../../design/table";
import {notifications} from "../../../../../../../components";
import WarningModal from "../../../../../design/warning_modal";
import {useTableFilters} from "../../../../../design/table/helpers";
import {Button, Card, Checkbox, Row, Col, Modal, Popconfirm} from "antd";
import {callApi, createFileName, downloadClientFile, useQuery} from "../../../../../../../helpers";

const Table = ({footer}: any) => {
    const {inProgress, toggleProgress, vehicleId, proposalId, proposal, fetchProposal, isFar} = useProposal();
    const [state, setState] = React.useState<any[]>([]);
    const [selected, setSelected] = React.useState<any[]>([]);
    const [visibleWarning, toggleWarning] = useToggle(false);
    const {filters, updateFilter, updateFilters, setPage} = useTableFilters();
    const [status, setStatus] = React.useState<string | null>(null);
    const [visibleWaiverApply, toggleWaiverApply] = useToggle(false);
    const [loadingStatus, toggleLoadingStatus] = useToggle(false)

    const [groups, setGroups] = React.useState<string[]>([
        "Eligibility Criteria",
        "Additional Eligibility Criteria",
        "Alternative Eligibility Criteria"
    ])

    const eligibility = useQuery({
        url: () => {
            return `/casavo/notes_manager/eligibility_criteria_limits/?${queryString.stringify({
                ...filters,
                page_size: 10,
                vehicle_id: vehicleId,
                proposal_id: proposalId,
            })}`
        },
        deps: [filters]
    });

    useDidUpdate(() => {
        if (status === "cure" || status === "waive")
            updateFilter("eligibility_breach", true);
    }, [status])


    React.useEffect(() => {
        resetSelected();
        resetState();
    }, [status]);


    const cure = () => {
        callApi({
            method: 'post',
            setLoading: toggleLoadingStatus,
            body: {
                collateral_log_ids: selected
            },
            onSuccess: () => {
                resetStatus();
                resetSelected();
                fetchProposal();
                eligibility.fetch();
            },
            url: `/casavo/collateral/waiver-criteria/make_properties_curable/?vehicle_id=${vehicleId}`
        })
    };

    const waive = ({reason}: any) => {
        const body: any = [];

        selected.forEach((id: any) => {
            const record = eligibility?.data?.results?.find((item: any) => item.collateral_log_id === id);
            if (record) {
                for (let key in record) {
                    const value = record[key];
                    if (value === "yes") {
                        body.push({
                            reason: reason,
                            collateral_log: id,
                            criteria_type: key
                        })
                    }
                }
            }
        });

        state.forEach((record: any) => {
            const [id, key] = record.split('-');
            const current = body.find((item: any) => item.collateral_log == Number(id) && item.criteria_type === key);
            if (!current) {
                body.push({
                    reason: reason,
                    criteria_type: key,
                    collateral_log: Number(id)
                })
            }
        })


        callApi({
            body: body,
            method: 'post',
            setLoading: toggleLoadingStatus,
            onSuccess: () => {
                resetSelected();
                resetState();
                resetStatus();
                fetchProposal();
                eligibility.fetch();
                if (visibleWaiverApply)
                    toggleWaiverApply();
            },
            url: `/casavo/collateral/waiver-criteria/waive_breached_criterias/?vehicle_id=${vehicleId}`
        })
    }

    const resetSelected = () => setSelected([]);

    const resetState = () => setState([]);

    const resetStatus = () => setStatus(null);

    const computeWaterfall = () => {
        callApi({
            body: {},
            method: "post",
            setLoading: toggleProgress,
            onSuccess: async () => {
                await fetchProposal();
                footer.onNext(true);
            },
            url: `/casavo/notes_manager/${proposalId}/compute_waterfall/?vehicle_id=${vehicleId}`,
        })
    };

    const handleDeselectAll = () => {
        if (status === "waive")
            resetState();
        resetSelected();
    }

    const extractIdsFromState = () => {
        return state.map((item: any) => Number(item.split('-')[0]));
    }

    const getDescriptionCount = () => {
        if (status === "waive") {
            const temp: any = [];
            [
                ...selected,
                ...extractIdsFromState()].forEach((value: any) => {
                if (!temp.includes(value))
                    temp.push(value)
            })
            return temp.length
        } else if (status === "cure") {
            return selected.length;
        }
    };

    const onApply = () => {
        if (status === "waive") {
            toggleWaiverApply()
        } else if (status === "cure") {
            cure();
        }
    };

    const handleDownload = () => {
        downloadClientFile({
            filename: createFileName('eligibility_criteria_limits', 'xlsx'),
            url: `/casavo/notes_manager/download_eligibility_criteria_limits/?${queryString.stringify({
                ...filters,
                vehicle_id: vehicleId,
                proposal_id: proposalId,
            })}`,
        })
    }

    const handleWaiverClick = (id: any): any => {
        setState((previous: any) => {
            if (previous.includes(id))
                return previous.filter((item: any) => item !== id);
            return [...previous, id];
        })
    };

    const handleNext = () => {

        if (proposal.status !== statusName["Awaiting For Computation"]) {
            footer.onNext();
            return;
        }

        if (proposal?.warnings_on_submission)
            toggleWarning()
        else
            computeWaterfall();
    }

    const memoizedColumns = React.useMemo(() => {
        const columns: any = [];

        for (let group of groups)
            groupedColumns[group].forEach((col: any) => columns.push(col))

        return [
            {
                width: 150,
                "fixed": "left",
                key: 'curable_status',
                label: status === "waive" ? null : 'Curable Status',
                render: (value: any, record: any) => {
                    const ids = extractIdsFromState();
                    const checked = selected.includes(record.collateral_log_id);
                    return (
                        <Checkbox
                            checked={checked}
                            indeterminate={!checked && ids.includes(record.collateral_log_id)}
                            onChange={(): void => {
                                const canBeSelected = Object.values(record).some((v: any) => v === "yes");

                                if (!canBeSelected) {
                                    notifications.warning("In order to select a row you should a have at least one breached limit");
                                    return;
                                }

                                setSelected((previous: any) => {
                                    if (previous.includes(record.collateral_log_id))
                                        return previous.filter((id: any) => id != record.collateral_log_id);
                                    else
                                        return [...previous, record.collateral_log_id]
                                })
                            }}
                        />
                    )
                }
            },
            {
                "fixed": "left",
                label: 'Asset ID',
                key: 'transaction_id',
                width: 150
            },
            {
                width: 150,
                "fixed": "left",
                label: 'Status',
                key: 'property_ec_status'
            },
            ...columns.map((column: any) => {
                return {
                    width: 150,
                    ...column,
                    render: (value: any, record: any, index: number) => {
                        return (
                            <Checker
                                index={index}
                                value={value}
                                state={state}
                                column={column}
                                status={status}
                                vehicleId={vehicleId}
                                resetState={resetState}
                                onWaiverClick={handleWaiverClick}
                                collateralLogId={record.collateral_log_id}/>
                        )
                    }
                }
            })
        ].filter((_: any, index: any) => {
            return status ? true : index !== 0
        })
    }, [status, groups, selected, state]);


    const extraFooterProps: any = {
        nextLabel: "Next"
    };

    if (proposal?.status === statusName["Awaiting For Computation"])
        extraFooterProps.nextLabel = "Compute";

    return (
        <Container
            {...footer}
            {...extraFooterProps}
            onNext={handleNext}
            loading={inProgress}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Row justify="end">
                        <Col>
                            <Actions
                                isFar={isFar}
                                state={state}
                                groups={groups}
                                status={status}
                                filters={filters}
                                selected={selected}
                                setGroups={setGroups}
                                setStatus={setStatus}
                                updateFilter={updateFilter}
                                updateFilters={updateFilters}
                                handleDownload={handleDownload}/>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <SmartTable
                                pagination={{
                                    pageSize: 10,
                                    current: filters.page,
                                    showSizeChanger: false,
                                    total: eligibility?.data?.count,
                                    onChange: (event: any) => setPage(event)
                                }}
                                columns={memoizedColumns}
                                loading={eligibility?.loading}
                                dataSource={eligibility?.data?.results}
                            />
                        </Col>
                        <Col xs={24}>
                            <Card size="small">
                                <Row justify="space-between">
                                    <Col>
                                        <Description count={getDescriptionCount()}/>
                                    </Col>
                                    <Col>
                                        <Row gutter={16}>
                                            {!!status && (
                                                <Col>
                                                    <Button type="link" danger onClick={resetStatus}>
                                                        Cancel Operation
                                                    </Button>
                                                </Col>
                                            )}
                                            <Col>
                                                <Button disabled={!getDescriptionCount()} onClick={handleDeselectAll}>
                                                    Deselect All
                                                </Button>
                                            </Col>
                                            <Col>
                                                {status === "cure" && (
                                                    <Popconfirm
                                                        okText="Yes"
                                                        cancelText="No"
                                                        onConfirm={onApply}
                                                        title="Are you sure to apply these changes?">
                                                        <Button
                                                            type="primary"
                                                            loading={loadingStatus}
                                                            disabled={!getDescriptionCount()}>
                                                            Apply
                                                        </Button>
                                                    </Popconfirm>
                                                )}
                                                {status === "waive" && (
                                                    <Button
                                                        type="primary"
                                                        onClick={onApply}
                                                        loading={loadingStatus}
                                                        disabled={!getDescriptionCount()}>
                                                        Apply
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        footer={null}
                        open={visibleWaiverApply}
                        onCancel={toggleWaiverApply}
                        title="Eligibility Criteria Waiver">
                        <Apply
                            onCancel={toggleWaiverApply}
                            onSubmit={waive}
                            loading={loadingStatus}/>
                    </Modal>
                    <WarningModal
                        open={visibleWarning}
                        onOk={computeWaterfall}
                        onCancel={toggleWarning}
                        title={proposal?.warnings_on_submission}/>
                </Col>
            </Row>
        </Container>
    );
};

export default Table;