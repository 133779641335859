import React from 'react';
//Components
import { Button, Col, Row, Select } from "antd";
//Icons
import { CheckOutlined } from "@ant-design/icons";

const extract = (option: any, key: any) => typeof option === "object" ? option[key] : option

const Selection = ({options, multiple, minActive = 0, onChange, value, ...rest}: any) => (
  <Select
    {...rest}
    value={value || undefined}
    placeholder="Enter Value"
    onChange={(event: any) => {
      if (multiple && event.length < minActive)
        return;
      onChange(event);
    }}
    className="fullWidth"
    mode={multiple ? "multiple" : undefined}
    style={{borderRadius: 8}}>
    {options.map((option: any) => (
      <Select.Option key={extract(option, 'key')}>
        {extract(option, 'label')}
      </Select.Option>)
    )}
  </Select>
);


const Choices = ({value, multiple, onChange, minActive = 1, options = []}: any) => {
  return (
    <Row gutter={[8, 8]}>
      {options.map(((option: any) => {
        const key = extract(option, 'key');
        const label = extract(option, 'label');
        const active = multiple ? value && value.includes(key) : value === key
        return (
          <Col>
            <Button
              size="middle"
              type="link"
              shape="round"
              onClick={() => {

                if (multiple) {
                  if (value) {
                    if (!value.includes(key))
                      value = [...value, key]
                    else
                      value = value.filter((k: any) => k !== key)
                  } else {
                    value = [key]
                  }
                } else {
                  value = key;
                }

                if (multiple && value.length < minActive)
                  return;

                onChange(value);
              }}
              style={active ? {
                backgroundColor: '#F5F8FE',
                border: '1px solid #D5E3FB'
              } : {
                border: '1px solid #ddd',
                color: 'rgba(0,0,0,.65)'
              }}
              icon={active && <CheckOutlined/>}>
              {label}
            </Button>
          </Col>
        )
      }))}
    </Row>
  )
};

export default (props: any) => {
  const useChoices = props.options?.length <= 3;
  const Component = useChoices ? Choices : Selection;
  return <Component {...props} />
}


