import React, { createElement } from "react";
//Components
import { Tooltip } from "antd";
//Helpers & Configurations
import chartOptions from "../config";
import { formatters, Text } from "../../../../../../../helpers";
import { prepareCellContentStyle } from "./config";

interface Props {
  visible?: any,
  content?: any,
  width?: any,
  calculateHeight?: any,
  rowSpan?: any
}

const CollateralCell = (props: Props) => {
  const {visible,  content, width, calculateHeight, rowSpan} = props;
  if (!visible)
    return null;

  function generateProperties(key: string, value: number) {
    let childDetails = chartOptions.dataSets.collateral[key];

    if (!childDetails)
      childDetails = {};

    const visibleTooltip = value < 0.05;

    let title;

    if (visibleTooltip)
      title = Text(childDetails.tip + ": " + formatters.percent(value));
    else
      title = Text(childDetails.tip + ": \n" + formatters.percent(value));

    return {
      key: key,
      children: !visibleTooltip ? title : null,
      title: visibleTooltip ? title : null,
      style: {
        height: calculateHeight(value),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: childDetails.color,
      }
    }
  }


  const records = [];
  for (let key in content) {
    if (content.hasOwnProperty(key)) {
      const {title, ...properties} = generateProperties(key, content[key]);
      let record = createElement("div", properties);
      if (title)
        record = <Tooltip key={key} title={title}>{record}</Tooltip>;
      records.push(record);
    }
  }

  return (
    <td  rowSpan={rowSpan} className="fade-in" style={{width: width}}>
      <div  style={prepareCellContentStyle(false)}>
        {records}
      </div>
    </td>
  )
};


export default CollateralCell;