import React from 'react';
import { Row, Col, Switch } from "antd";


const Column = ({label, checked, onChange}: any) => {
  return (
    <Row gutter={8}>
      <Col>
        <Switch size="small" checked={checked} onClick={onChange}/>
      </Col>
      <Col>
        {label}
      </Col>
    </Row>
  )
}

const ChooseColumns = ({value = [], config, onChange}: any) => {
  return (
    <Row gutter={[8, 8]} style={{maxWidth: 300}}>
      <Col xs={24}>
        Show or Hide Columns
      </Col>
      <Col xs={24}>
        <Row gutter={[8, 8]}>
          {config.map((record: any) => {
            const key = record.key;
            return (
              <Col xs={24}>
                <Column
                  key={record.key}
                  label={record.label}
                  checked={value.includes(key)}
                  onChange={() => {
                    if (value.includes(key))
                      value = value.filter((v: any) => v !== key);
                    else
                      value = [...value, key];

                    if (value.length < 1)
                      return
                    onChange(value)
                  }}
                />
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default ChooseColumns;
