import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const Geography = () => {
  return (
    <>
      <div>
        <p className="bold">
          Shows the distribution of the loans of the SPV by Country and/or Region.
        </p>
        <p>
          Hovering on the map the User gets additional information on the Country/Region Exposure
          (Current Balance, WAR, WAM, Average Rating).
        </p><br/>
        <p>
          Clicking on a Country/Region on the map, the User is redirected to the Loans Management
          module with the list of all the loans with the parameters
          selected (i.e. Advanced Filters set for the relevant SPV and Country/Region, see
          Loans Management for additional details) –
          by clicking on the Go Back button (top left) <img alt="Back Button"
                                                            src={assets.images.others.goBack}/> the
          User is redirected back to the Dashboard.
        </p>
      </div>
      <div>
        <CustomImage
          alt="Dashboard Geography"
          fullWidth={true}
          src={assets.images.dashboardModule.geography}/>
      </div>
    </>
  );
};

export default Geography;
