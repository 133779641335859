import React from 'react';
import {formatters, useQuery} from "../../../../../../../../../../helpers";
import {EmojiBreach, SmartTable} from "../../../../../../../../../../components";
import {Col,Empty, Row} from "antd";
import {CustomTable} from "@cardoai/components";
import {columns} from "./config";
import logo from "../../../../../../../../../app/layout/sidebar/components/logo";

interface Props {
  vehicleId?: number,
  proposalId?: number,
  currency?: any,
  helveticaTabDemoLabel?:boolean,
  helveticaData?:any,
  record?:any
}


const HelveticaLimits = (props: Props) => {
  const {currency,helveticaData,record} = props;

  const helvetiaOutstandingAmount = helveticaData?.data?.helvetia_outstanding_amount;
  const helvetiaLimits = helveticaData?.data?.helvetia_limits;

  if (!helvetiaLimits) return <Empty/>

  const availableAmount = helvetiaOutstandingAmount?.value - helvetiaOutstandingAmount?.fulfillment;
  return (
    <>
     <Row >
       <Col span={24}>
         <CustomTable centerData={true} className="mt16 mb24">
           <thead>
           <tr>
             <th className="textCenter" style={{width: 200}}>Limit Name</th>
             <th className="textCenter" style={{width: 200}}>Threshold</th>
             <th className="textCenter" style={{width: 200}}>Realized</th>
             <th className="textCenter" style={{width: 200}}>Check</th>
             <th className="textCenter" style={{width: 200}}>Available Amount</th>
           </tr>
           </thead>
           <tbody>
           <tr>
             <td className="textCenter" style={{width: 200}}>
               {helvetiaOutstandingAmount?.name}
             </td>
             <td className="textCenter" style={{width: 200}}>
               {formatters.currency({value: helvetiaOutstandingAmount?.value, currency, precision: 0})}
             </td>
             <td className="textCenter" style={{width: 200}}>
               {formatters.currency({value: helvetiaOutstandingAmount?.fulfillment, currency, precision: 0})}
             </td>
             <td className="textCenter" style={{width: 200}}>
               <EmojiBreach record={helvetiaOutstandingAmount?.broken}/>
             </td>
             <td className="textCenter" style={{width: 200}}>
               {formatters.currency({value: availableAmount, currency, precision: 0})}
             </td>
           </tr>
           </tbody>
         </CustomTable>
       </Col>
     </Row>
      <Row >
        <Col span={24}>
          <SmartTable
            externalData={helvetiaLimits ?? []}
            columns={columns(currency)}
            height={window.innerHeight - 250}
          />
        </Col>
      </Row>

    </>
  );
};

export default HelveticaLimits;