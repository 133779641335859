import { SEPARATOR } from "../../../types";

export function getDelimiter(decimalSeparator: string) {
  switch (decimalSeparator) {
    case SEPARATOR.comma:
      return SEPARATOR.semicolon
    case SEPARATOR.dot:
      return SEPARATOR.comma
    default:
      return SEPARATOR.comma
  }
}