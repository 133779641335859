//Styled Components
import styled from "styled-components";
//Constants
import { theme } from "../../../../../../assets/theme/colors";

export const StyledCenter = styled.section<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => props.height && `min-height: ${props.height - 40}px;`};
  height: 100%;
`;

export default styled.div`
  width: 100%;

  .header {
    position: relative;
  }

  .header-title {
    text-align: center;
  }

  .header-extra {
    top: 0;
    right: 0;
    position: absolute;
  }

  .content {
    display: flex;
    flex-shrink: 1;
  }

  .center {
    text-align: center;
  }

  .mt8 {
    margin-top: 8px;
  }

  .graphIcon {
    font-size: 1em;
  }

  .icons {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .numberAvatar {
    padding: 8px;
    color: ${theme.colors.white};
    font-weight: 500;
    background-color: dimgrey;
  }

  .mr8 {
    margin-right: 8px !important;
  }
`;