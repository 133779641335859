import React from 'react';
//Styles
import Styled from "./styles";

interface Props {
  sizeSmall?: boolean,
  children?: any,
  height?: any
}

const LimitsTable = ({children, height, sizeSmall = true}: Props) => {
  return (
    <Styled height={height} sizeSmall={sizeSmall}>
      {children}
    </Styled>
  );
};

export default LimitsTable;