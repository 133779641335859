import React from 'react'
//Other libs
import styled from "styled-components";
//Components
import { Card } from "antd";
//Other Libs
import { CodeEditorEditable } from 'react-code-editor-editable'
//Css
import 'highlight.js/styles/github.css';

interface IEditor {
  value?: any,
  onChange?: (e: any) => any,

}

const Container = styled(Card)`
  .ant-card-body {
    padding: 0 !important;
  }

  .container-code-editor__qxcy .line-numbers__qxcy {
    padding-top: 0.65em !important;
  }

  .container-code-editor__qxcy {
    background-color: transparent;
  }
`

const Editor = (props: IEditor) => {
  const {value, onChange} = props;

  React.useEffect(() => {
    const element: any = document.querySelector(".code-editor__textarea__qxcy");
    element.maxLength = 9999999;
  }, []);

  return (
    <Container size="small" title="Code Editor">
      <CodeEditorEditable
        value={value || ''}
        height='55vh'
        width="100%"
        language='sql'
        tabSize={4}
        setValue={(e: any) => {
          if (onChange)
            onChange(e)
        }}
      />
    </Container>
  )
}

export default Editor