import React from 'react';
//Components
import { Alert, Skeleton } from "antd";
import { SmartTable } from "../../../../components";
//Utils
import { useQuery } from "../../../../helpers";
import { getExtraTableColumns, prepareColumnGroups, prepareTableColumns, prepareTabularData } from "./helpers";

const BANNER_MESSAGE = <span>To propose a new loan purchase, please go to <a href="/syncs">Syncs Module</a></span>;


interface INotesTable {
  vehicle?: any
  vehicleId?: any,
  height?: any,
  canProposeLoan?: boolean,
}

const NotesTable = (props: INotesTable) => {
  const {height, vehicleId, canProposeLoan, vehicle} = props;

  const {data, fetch} = useQuery({
    url: `/main/notes_manager/?vehicle_id=${vehicleId}`
  })

  if (!data)
    return <Skeleton/>

  const vehicleIdentifier = vehicle.identifier;

  const finalData = prepareTabularData(data);

  const extraColumns = getExtraTableColumns(vehicleIdentifier);

  return (
    <>
      {canProposeLoan && <Alert type="info" message={BANNER_MESSAGE} banner className="mb16"/>}
      <SmartTable
        allowGrouping
        getData={fetch}
        height={height}
        vehicle={vehicle}
        vehicleId={vehicleId}
        externalData={finalData}
        vehicleIdentifier={vehicleIdentifier}
        columns={prepareTableColumns(extraColumns)}
        columnGroups={prepareColumnGroups(extraColumns)}/>
    </>
  );
};

export default NotesTable;
