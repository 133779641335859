import React from 'react';
//Components
import { Avatar, Button, Col, Comment, Input, Row, Space } from "antd";
import Upload from "../../../../components/upload_files/upload";
//Icons
import { CloseOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
//Helpers
import { callApi } from "../../../../helpers";

const {TextArea} = Input;

export const WriteMessage = (props: any) => {

  const {vehicleId, topicId, fetchMessages, closeReplyBox, parentId} = props;

  const [uploadedFiles, setUploadedFiles] = React.useState<any[]>([]);
  const [messageText, setMessageText] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const onWriteMessage = (event: any) => {
    setMessageText(event.target.value)
  }

  const appendFile = (file: any) => setUploadedFiles((prev: any[]) => {
    return [ ...prev, file ];
  });

  const removeFile = (name: string) => () => {
    setUploadedFiles((prev: any[]) => prev.filter((file: any) => file.name !== name));
  }

  const clearUploadedFiles = () => {
    setUploadedFiles([]);
  }

  const onSendMessage = () => {
    const formData = new FormData();

    const bodyParams: any = {
      topic: topicId,
      message: messageText
    }

    formData.append('topic', topicId);
    formData.append('message', messageText);

    if (parentId) {
      bodyParams.parent_message = parentId;
      formData.append('parent_message', parentId);
    }

    uploadedFiles.forEach((file: any, i: number) => formData.append(file.name, file));

    callApi({
      url: `/common/message/?pk=${vehicleId}&model=vehicle`,
      method: "POST",
      dataType: 'form',
      body: formData,
      setLoading,
      onSuccess: () => {
        setMessageText('');
        fetchMessages();
        if (closeReplyBox) closeReplyBox();
        clearUploadedFiles();
      }
    });
  }

  return (
    <div>
      <Comment
        avatar={<Avatar icon={<UserOutlined/>}/>}
        content={
          <>
            <TextArea value={messageText} onChange={onWriteMessage} placeholder="Write a message..."
                      rows={closeReplyBox ? 1 : 2}/>

            {uploadedFiles.length > 0 &&
              <div className="mt8">
                <Row gutter={[8, 8]}>
                  {uploadedFiles.map((file: any) => (
                    <FilePreview key={file.id} file={file} onDelete={removeFile(file.name)}/>
                  ))}
                </Row>
              </div>}

            <Space className="mt8">
              <Button onClick={onSendMessage} loading={loading} type="primary" icon={<SendOutlined/>}>
                Send
              </Button>
              <Upload
                onChange={appendFile}
                accept={['.xlsx', '.xls', '.doc', '.docx', '.pdf']}
                onClear={clearUploadedFiles}
                required={false}
                multiple
              />
            </Space>
          </>}
      />
    </div>
  );
};

interface FilePreviewProps {
  file: any,
  onDelete: () => void
}

const FilePreview = ({ file, onDelete }: FilePreviewProps) => {
  return (
    <Col>
      <span style={{ border: '1px solid #ccc', padding: '4px 8px' }}>
        <span className="mr8">{file.name}</span>
        <CloseOutlined onClick={onDelete} />
      </span>
    </Col>
  );
}
