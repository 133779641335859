import React from 'react';
//Components
import { Line } from "react-chartjs-2";
import { SimpleEmpty } from "@cardoai/components";
//Custom Components
import { ChartTitle } from "../../../../../components";
//Constants
import { theme } from "../../../../../assets/theme/colors";
//Helpers
import { formatters, isEmpty } from "../../../../../helpers";

interface ICustomChart {
  title?: any,
  data?: any,
  cumulative?: any,
  tickFormat?: any,
  labelIdentifier?: any,
  datasetIdentifier?: any
}

const chartColors = theme.palette.us

const getGraphOptions = (labels: Array<any>, tickFormat = formatters.shortedDate) => (
  {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 10
        }
      },
      tooltip: {
        mode: 'nearest',
        callbacks: {
          label: function (tooltipItem: any) {
            const {label, raw} = tooltipItem;
            return `Value : ${formatters.percent(raw)}`;
          },
        }
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: true
        },
        ticks: {
          callback: (index: any) => {
            return tickFormat(labels[index]);
          }
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: true
        },
        ticks: {
          callback: (value: any) => {
            return formatters.percent(value);
          }
        },
      },
    },
    elements: {
      point: {
        radius: 2
      }
    }
  }
);

const sortAsc = (dates: any) => dates.sort((a: any, b: any) => new Date(a).valueOf() - new Date(b).valueOf())

const CustomChart = (props: ICustomChart) => {
  const {title, data, cumulative, labelIdentifier, tickFormat, datasetIdentifier} = props;

  const labels: any = [];

  const getLabel = (record: any) => {
    return record[labelIdentifier]
  };

  const getValue = (record: any) => {
    return record[datasetIdentifier]
  }

  for (let year in data) {
    if (data.hasOwnProperty(year)) {
      const records = data[year];
      records.forEach((record: any) => {
        const label = getLabel(record);

        if (!labels.includes(label))
          labels.push(label);
      })
    }
  }

  sortAsc(labels);

  const datasets = Object.keys(data).map((year, index) => {
    const records = data[year];

    const dataset: any = [];

    labels.forEach((label: any, index: any) => {
      let value = NaN;

      if (cumulative)
        value = index === 0 ? NaN : dataset[index - 1];

      if (!cumulative)
        value = 0

      const record = records.find((record: any) => getLabel(record) === label);

      if (record)
        value = getValue(record);

      dataset.push(value);
    })

    return {
      label: year,
      data: dataset,
      fill: false,
      borderColor: chartColors[index],
      cubicInterpolationMode: 'monotone',
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  })

  if (isEmpty(data))
    return <SimpleEmpty description={`No data for ${title}`}/>

  return (
    <>
      <ChartTitle>
        {title}
      </ChartTitle>
      <div style={{height: '300px'}}>
        <Line
          type='line'
          options={getGraphOptions(labels, tickFormat)}
          data={{labels: labels, datasets: datasets}}
        />
      </div>
    </>
  );
};

export default CustomChart;

CustomChart.defaultProps = {
  cumulative: true,
  labelIdentifier: 'defaulted_month',
}