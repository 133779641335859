import React from "react";
//Components
import { Col, Row } from "antd";
//Custom Components
import { DocumentIcon } from "./error_type";

const ErrorLabel = (props: any) => {
  return (
    <Row justify="center" align='middle' gutter={8}>
      <Col>
        <DocumentIcon type={props.type}/>
      </Col>
      <Col>
        {props.label}: {props.value}
      </Col>
    </Row>
  )
};

export default ErrorLabel;
