import React from 'react';
//Custom Components
import { CustomTable } from "@cardoai/components";
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const ManageDocuments = () => {
  return (
    <>
      <CustomImage
        alt="Manage Documents"
        fullWidth={false}
        src={assets.images.documentsModule.manageDocuments}
      />
      <div>
        <p>Once a Document is uploaded on the platform, it is available only for Servicer that can (all actions are
          subject to confirmation):</p><br/>
        <CustomTable centerData={false} centerHeaders={false}>
          <thead>
          <th>Action</th>
          <th>Description</th>
          </thead>
          <tbody>
          <tr>
            <td><img src={assets.images.others.downloadButton}/></td>
            <td><b>Download</b> – shows or download the document</td>
          </tr>
          <tr>
            <td><img src={assets.images.others.deleteButton}/></td>
            <td><b>Delete</b> – cancels the document from the document list (only for report not Published or
              Unpublished)
            </td>
          </tr>
          <tr>
            <td><img src={assets.images.others.publishButton}/></td>
            <td><b>Publish</b> – makes the document available to Investors</td>
          </tr>
          <tr>
            <td><img src={assets.images.others.unPublishButton}/></td>
            <td>
              <b>Unpublish</b> – hides the document to Investors and allows Servicer to delete or change it (only for
              documents
              already published)
            </td>
          </tr>
          </tbody>
        </CustomTable>
      </div>
    </>
  );
};

export default ManageDocuments;
