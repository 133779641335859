import React from 'react';
//Components
import { Tabs } from "antd";
import Inputs from "./components/inputs";
import Limits from "../../common/spv_limits";
import Accounts from "../../common/submit/components/accounts";
import Waterfall from "../../common/submit/components/waterfall";
import LtcAndOutstanding from "../../common/submit/components/ltc_and_outstanding";
import { useProposal } from '../../../../context';
import Table from '../../create/components/eligibility_criteria';
import EligibilityCriteriaStep from '../../create/components/eligibility_criteria';
import Inventory from '../../common/submit/components_far_integration/inventory';
import Acquisition from '../../common/submit/components_far_integration/acquisition';

const Finished = ({proposalDate}:any) => {
   const {isFar}= useProposal()

  const minDate = "2022-07-1";
  const maxDate = "2022-11-1"
  const disabledTabs =  proposalDate < minDate

  const disableMinValue =  proposalDate > minDate
  const disableMaxValue =  proposalDate < maxDate
  const tabsBetweenDisable = disableMinValue && disableMaxValue

  return (
    <Tabs>
      {!isFar &&
      <><Tabs.TabPane key='eligibility_criteria' tab='Eligibility Criteria'>
        <EligibilityCriteriaStep />
      </Tabs.TabPane><Tabs.TabPane key='inventory' tab='Inventory' disabled={disabledTabs}>
        <Inventory />
      </Tabs.TabPane><Tabs.TabPane key='acquisition' tab='Acquisition' disabled={disabledTabs || tabsBetweenDisable}>
        <Acquisition />
      </Tabs.TabPane><Tabs.TabPane key='input' tab='Input File' disabled={disabledTabs || tabsBetweenDisable}>
        <Inputs />
      </Tabs.TabPane></>
        }
      {isFar &&
      <><Tabs.TabPane key='waterfall' tab='Waterfall'>
        <Waterfall />
      </Tabs.TabPane><Tabs.TabPane key='limits' tab='Limits' disabled={disabledTabs}>
        <Limits />
      </Tabs.TabPane><Tabs.TabPane key='ltc_bb' tab='LTC & Outst.BB' disabled={disabledTabs || tabsBetweenDisable}>
        <LtcAndOutstanding />
      </Tabs.TabPane><Tabs.TabPane key='accounts' tab='Accounts' disabled={disabledTabs || tabsBetweenDisable}>
        <Accounts />
      </Tabs.TabPane><Tabs.TabPane key='inputs' tab='Input File' disabled={disabledTabs || tabsBetweenDisable}>
        <Inputs />
      </Tabs.TabPane></>}
        </Tabs>
  );
};

export default Finished;