import React from 'react';
//Other Libraries
import queryString from "query-string";
//Components
import { Row } from "antd";
import { SmartChart } from "../../../../../components";
import { OperationButton, SimpleEmpty } from "@cardoai/components";
//Helpers
import { Analytics, createFileName, downloadClientFile, formatters } from "../../../../../helpers";
import { CASHFLOWTYPE, datasets, initializeRecord, initializeSum } from './helpers'
//Constants
import { theme } from "../../../../../assets/theme/colors";

interface ILoanTimeLineChart {
  data: any,
  config: any,
  loanId: any,
  queryParams: any
}

const LoanTimeLineChart = (props: ILoanTimeLineChart) => {
    const {data, config, loanId, queryParams} = props;

    const records: any = {
      ...config
    };

    if (!data)
      return <SimpleEmpty description="No cash flow data available yet"/>

    for (let key in datasets) {
      if (datasets.hasOwnProperty(key)) {
        const dataset = data[key];

        for (let date in dataset) {
          if (dataset.hasOwnProperty(date)) {
            if (!records.hasOwnProperty(date)) {
              records[date] = initializeRecord();
            }

            const items = dataset[date];

            if (items.length) {
              const currentSum: any = initializeSum();
              items.forEach(
                (item: any) => (currentSum[item.cash_flow_type] += item.amount)
              );

              for (let type in records[date][key]) {
                records[date][key][type] += currentSum[type];
              }
            }
          }
        }
      }
    }


    const chartData = {
      labels: [],
      dataSets: [
        {
          label: "funding",
          type: 'bar',
          backgroundColor: theme.colors.gold,
          data: [],
          stack: "funding",
          group: "funding",
          hidden: true
        },
        {
          label: "Expected - Capital",
          type: 'bar',
          stack: "expected",
          group: CASHFLOWTYPE.capital,
          backgroundColor: theme.colors.lightcyan,
          data: [],
        },
        {
          label: "Expected - Interest",
          type: 'bar',
          stack: "expected",
          group: CASHFLOWTYPE.interest,
          backgroundColor: theme.colors.blue,
          data: []
        },
        {
          label: "Realized - Capital",
          type: 'bar',
          stack: "realized",
          group: CASHFLOWTYPE.capital,
          backgroundColor: theme.palette.us[0],
          data: [],
        },
        {
          label: "Realized - Interest",
          type: 'bar',
          stack: "realized",
          group: CASHFLOWTYPE.interest,
          backgroundColor: theme.colors.green,
          data: [],
        },
        {
          label: "Realized - Late Fees",
          type: 'bar',
          stack: "realized",
          group: CASHFLOWTYPE.fees,
          backgroundColor: theme.colors.orange,
          data: []
        },
        {
          label: "Expected - Late Fees",
          type: 'bar',
          stack: "expected",
          group: CASHFLOWTYPE.fees,
          backgroundColor: theme.colors.yellow,
          data: []
        }
      ]
    };

    const dates: any = Object.keys(records);
    // @ts-ignore
    const sortedDates = dates.sort((a: any, b: any) => new Date(a) - new Date(b));
    const sortedRecords: any = {};
    sortedDates.forEach((date: any) => sortedRecords[date] = records[date]);


    for (let date in sortedRecords) {
      if (sortedRecords.hasOwnProperty(date)) {
        const record = sortedRecords[date];
        // @ts-ignore
        chartData.labels.push(formatters.date(date));
        for (let type in record) {
          if (record.hasOwnProperty(type)) {
            if (type === "funding") {
              chartData.dataSets.forEach(dataset => {
                const value = dataset.group === "funding" ? record[type] : null
                // @ts-ignore
                dataset.data.push(value);
              });
            } else {
              // @ts-ignore
              chartData.dataSets[0].data.push(null)
              for (let key in record[type]) {
                if (record[type].hasOwnProperty(key)) {
                  const dataSet = chartData.dataSets.find(dataset => dataset.stack === type && dataset.group === key);
                  // @ts-ignore
                  dataSet.data.push(record[type][key]);
                }
              }
            }
          }
        }
      }
    }

    const downloadExcel = () => {
      const params = {...queryParams};
      params.id = loanId;
      const url = `/main/sme_loan/download_loan_cashflows/?${queryString.stringify(params)}`
      downloadClientFile({
        filename: createFileName("loan_timeline_information"),
        url: url
      })
      Analytics.dispatchEvent({
        category: 'Download',
        action: 'Downloaded Loans Timeline file',
      });
    };


    return (
      <>
        <Row gutter={8} justify='end'>
          <OperationButton type="fileDownload" size='small' onClick={downloadExcel}>
            Download Excel
          </OperationButton>
        </Row>
        <Row>
          <SmartChart
            stacked
            beginAtZero
            height={400}
            displayLegend
            displayLabels={false}
            innerDatasets={false}
            types={['bar']}
            format={formatters.currencyNoPrecision}
            {...chartData}
          />
        </Row>
      </>
    );
  }
;

export default LoanTimeLineChart;