import React from 'react';
//Configuration
import { config } from "./config";
//Components
import { Button, Row } from "antd";
//Icons
import { DownloadOutlined } from "@ant-design/icons";
//Json
import staticData from "./interim_cash_flows.json";
//Custom Components
import { DownloadAsCSV, SmartTable } from "../../../../components";

const NPLInterimCashflows = ({onDownload}: any) => {
  return (
    <>
      <Row className="mr8" align="middle" justify="end">
        <DownloadAsCSV onDownload={onDownload(staticData, "Interim Cashflows")}>
          <Button icon={<DownloadOutlined/>} size="small">Download</Button>
        </DownloadAsCSV>
      </Row>
      <SmartTable groupingPosition="start" allowGrouping height={700} columns={config}
                  externalData={staticData}/>
    </>
  );
};

export default NPLInterimCashflows;