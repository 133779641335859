import React, { useState } from 'react';
//Components
import { Col, Row } from "antd";
import { RadioGroup, SimpleEmpty } from "@cardoai/components";
import LoanTimeLineChart from "./chart/loan_time_line_chart";
import { AmortizationPlan, EventsTable } from "./tables";
//Helpers
import { radioConfig } from "./config";
import { withData } from "../../../../utils";
import { Analytics, generateUrl, isEmptyObject } from "../../../../helpers";

interface ILoanTimeline {
  data: any,
  loanDetails: any,
  loanId: any,
  queryParams: any
}

const LoanTimeline = (props: ILoanTimeline) => {
  const {data, loanDetails, loanId, queryParams} = props;
  const {pool_addition_date, investment_amount, currency} = loanDetails;

  /**
   * Adding the dataset of funding for each loan. This information is retrieved from the loan data and the negative sign
   * means it will show on the downside of the chart
   */
  const fundingInformation = {
    [pool_addition_date]: {
      funding: -(investment_amount)
    }
  }
  const showAmortizationPlan = !isEmptyObject(data.expected);

  const [visualisation, setVisualisation] = useState("amortization_plan");

  const handleVisualisationChange = (event: any) => {
    setVisualisation(event);
    Analytics.dispatchEvent({
      category: 'User',
      action: 'Changed visualisations at Loan Timeline',
      label: event
    });
  }

  const renderVisualisation = () => {
    switch (visualisation) {
      case "amortization_plan":
        return <AmortizationPlan
          data={data}
          currency={currency}
          visible={showAmortizationPlan}
          poolAdditionDate={pool_addition_date}
        />
      case "graph":
        return <LoanTimeLineChart
          data={data}
          loanId={loanId}
          queryParams={queryParams}
          config={fundingInformation}/>
      case "events":
        return <EventsTable currency={currency} data={data}/>;
      default:
        return <SimpleEmpty/>
    }
  }

  return (
    <section className="mt8">
      <Row justify='end'>
        <Col>
          <RadioGroup
            usesTooltip
            value={visualisation}
            entries={radioConfig}
            onChange={handleVisualisationChange}
          />
        </Col>
      </Row>
      <section className="mt8">
        {renderVisualisation()}
      </section>
    </section>
  );
};

export default withData({
  urlFormatter: function (config: any) {
    const {props: {loanId}, params} = config;
    return generateUrl(`/main/sme_loan/loan_cashflows`, {id: loanId, ...params});
  },
  extraParams: ["loanId", "queryStr"],
  usesDateFilters: true,
  showIntervals: true,
  endOfMonthFormat: false,
  fullDateRange: true,
  initialState: {
    interval: 'max',
    period: []
  }
})(LoanTimeline);
