//Other Libs
import styled from "styled-components";

export default styled.div`
  table {
    table-layout: fixed !important;

    .overflowTextTable {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .scrollable {
    overflow-x: auto !important;
  }

  h2.bold {
    font-size: inherit;
    font-weight: bold;
    margin-bottom: 0;
  }

  h2.italic {
    font-size: inherit;
    font-weight: 500;
    font-style: italic;
  }
`;