import { theme } from "../../../../../../assets/theme/colors";

export const STATUS_TYPE: any = {
  'files_received': 'files Received',
  'dhc_in_progress': 'DHC In Progress',
  'sync_failed': 'Sync Failed',
  'health_check_failed': 'Health Check Failed',
  'in_progress': 'In Progress',
  'done': 'Done',
  'ready': 'Ready'
};

export const config: any = {
  [STATUS_TYPE.files_received]: {
    color: theme.colors.warning,
    explanation: "Input files are received from the system and ready to perform DHC."
  },
  [STATUS_TYPE.dhc_in_progress]: {
    color: theme.colors.warning,
    explanation: "Input files are received from the system and ready to perform DHC."
  },
  [STATUS_TYPE.health_check_failed]: {
    color: theme.colors.error,
    explanation: "The process has halted since DHC investigated input files and retrieved ‘Blocking errors’. " +
      "For further details, press the button ‘details’ on the right."
  },
  [STATUS_TYPE.sync_failed]: {
    color: theme.colors.error,
    explanation: "Sync process has failed due to technical issue. Please reach out to " +
      "securitization@cardoai.com to report it."
  },
  [STATUS_TYPE.in_progress]: {
    color: theme.colors.geekblue,
    explanation: "Input files have been accepted and currently are being processed from the system."
  },
  [STATUS_TYPE.done]: {
    color: theme.colors.success,
    explanation: "The process has finished successfully."
  },
  [STATUS_TYPE.ready]: {
    color: theme.colors.green,
    explanation: "Input files have passed DHC controls and they are ready to be synchronized. " +
      "Press the button ‘Generate/Sync’ in order to continue with the respective process."
  }
};