//Helpers
import { formatters } from "../../../../../helpers";

export default [
  {
    label: "Loan Identifier",
    key: "identifier",
  },
  {
    label: "Borrower Name",
    key: "borrower",
  },
  {
    label: "Loan Type",
    key: "type",
  },
  {
    label: "Issue Date",
    key: "issue_date",
    format: formatters.date
  },
  {
    label: "Maturity Date",
    key: "maturity_date",
    format: formatters.date
  },
  {
    label: "Completed Date",
    key: "completed_date",
    format: formatters.date
  },
  {
    label: "Status",
    key: "status",
  },
];
