import React from "react";
//Components
import { Tag } from "antd";

const MissingColumns = ({data}: any) => {
  return (
    <div>
      <span className="bolder">Missing Column/s: </span>
      {data.map((item: string, index:number) => <Tag key={index} color="geekblue">{item}</Tag>)}
    </div>
  )
}

export default MissingColumns;