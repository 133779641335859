export enum STATUSES {
  INITIAL = "Initial",
  ONGOING = "Ongoing",
  RESOLVED = "Resolved",
}

export const STATUS_COLORS: any = {
  [STATUSES.INITIAL]: "orange",
  [STATUSES.ONGOING]: "blue",
  [STATUSES.RESOLVED]: "green",
}
