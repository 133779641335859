//Styles
export const styles: any = {
  container: {
    marginTop: 8,
    marginBottom: 16,
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    margin: 3,
    minWidth: 180,
    width: "10%"
  }
}