import CalculationWavierDisplay from "../create_waiver_request/calculation_wavier_display";
import CheckboxItem from "../create_waiver_request/checkbox_item";

export const columns: any = [
  {
      key: "checkbox_label",
      align: "center",
      width: 20,
      Component: CheckboxItem
  },
  {
    key: "platform_loan_id",
    label: "Loan ID",
    align: "center",
    width: 100
  },
  {
    key: "company",
    label: "Debtor Name",
    align: "center",
    width: 150
  },
  {
    key: "current_balance",
    label: "Current Balance",
    align: "center"
  },
  {
    key: "calculation_waiver",
    label: "Calculation Waiver",
    align: "center",
    Component: CalculationWavierDisplay
  },
]
