import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../../assets";

const HDRStratification = () => {

  const description =
    <div>
      Stratification <br/>
      In General Strats, the user can choose and filter out the type of statistic to be displayed, like: Residual
      Maturity, Loan Size, Region, Industry and so on.
      <div>
        For each graph the user can download the image, show in different chart types and show by value or %.
      </div> <br/>
      <div>
        In the Geography Section, the User can view the distribution of loans by regions and the
        respective information. If needed, the user can go more in depth if interested in details specifically to a
        region
        in particular. Same goes for the Sector module. Users can see how the distribution of the investment is done by
        industry, and can access detailed information for each industry if needed.
      </div>
      <br/>
      <div>
        In the Debtor field, the top 20 debtors of the portfolio are evaluated in order to evaluate what their
        respective weight and/or risk is.
      </div>
    </div>

  return (
    <Row style={{paddingLeft: '20px'}}>
      <Col sm={22}>
        <CustomImage
          description={description}
          alt="Default Distribution"
          src={assets.images.HDRModule.stratification}
        />
      </Col>
    </Row>
  );
};

export default HDRStratification;