import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const AggregatedStatistics = () => {
  return (
    <>
      <div>
        <CustomImage
          description="Statistics are calculated by default on the whole universe of loans included in the SPVs of the User."
          src={assets.images.loansModule.aggregatedStatistics}
          alt="Loans Aggregated Statistics"
        />
      </div>
      <br/>
      <div>
        <DefinitionList
          header="This section shows the following statistics:"
          data={[
            {
              term: 'Current Balance',
              explanation: 'total current balance of the loans'
            },
            {
              term: 'Total loans_management #',
              explanation: 'total number of loans (including outstanding and closed)'
            },
            {
              term: 'Loans Outstanding #',
              explanation: 'total number of outstanding loans (excluding closed)'
            },
            {
              term: 'WAM (years)',
              explanation: ' Weighted Average Residual Maturity calculated with reference of the maturity date (as eventually revised / changed), weighted by current balance'
            },
            {
              term: 'WALA (years)',
              explanation: 'Weighted Average Loan Age calculated with reference to the issue date of the loan, weighted by current balance'
            },
            {
              term: 'WAR (%)',
              explanation: 'Weighted Average Return (including contractual coupon, fees and purchase price), weighted by current balance'
            },
            {
              term: 'Late Loans #',
              explanation: 'total number of loans with payment delays'
            },
            {
              term: 'Late Amount',
              explanation: 'total current balance of loans with payment delays'
            },
            {
              term: 'DPD (1-29)',
              explanation: 'current balance of loans with payment delays within 1 and 29 days'
            },
            {
              term: 'DPD (30-59)',
              explanation: 'current balance of loans with payment delays within 30 and 59 days'
            },
            {
              term: 'DPD (60-89)',
              explanation: 'current balance of loans with payment delays within 60 and 89 days'
            },
            {
              term: 'DPD (90-119)',
              explanation: 'current balance of loans with payment delays within 90 and 119 days'
            },
            {
              term: 'DPD (120+)',
              explanation: 'current balance of loans with payment delays within 120 days and over'
            },
            {
              term: 'Average Realized Maturity / DSO (years)',
              explanation: 'average realized maturity of the loans, weighted by original amount'
            },
          ]}
        />
      </div>
      <div>
        <CustomImage
          description="The relevant universe of reference of the Statistics is affected by search criteria and can be
        customised by Search Output or applying Advanced Filtering Options (see. 6.2), i.e.
        statistics are recalculated on the basis of the filters selected."
          alt="Loans Filters"
          fullWidth={false}
          src={assets.images.others.filterLoans}
        />
      </div>
    </>
  );
};

export default AggregatedStatistics;
