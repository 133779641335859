import React from "react";
//Other Libs
import { Col, Row } from "antd";
//Components
import TableLimits from "./table";

interface Props {
  limitSubGroup?: any,
  firstLimitGroup?: any,
  currency?: any,
  groupName?: any,
  data?: any
}

const LimitSubgroup = (props: Props) => {
  const {limitSubGroup, firstLimitGroup, currency, groupName, data} = props

  return (
    <Row align="middle" justify={firstLimitGroup ? "center" : "start"} gutter={16}>
      {limitSubGroup.map((subGroupName: any, index:number) => {

        const subGroupData: any[] = data[groupName][subGroupName];
        return (
          <Col span={8} key={index}>
            <TableLimits data={subGroupData} subGroupName={subGroupName} currency={currency}/>
          </Col>
        )



      })}
    </Row>
  )
}

export default LimitSubgroup;