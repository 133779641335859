import React from 'react';
//Constants
import config, {COLUMNS_DETAILS} from "../../../sync_table/config";
//Components
import {Space, Tabs} from "antd";
//Custom Components
import {Panel} from "@cardoai/components";
//Custom Components
import Header from "../../header";
import MissingColumns from "./missing_columns";
import {SmartTable} from "../../../../../../components";
//Helpers
import {humanString} from "../../../../../../helpers";

const {TabPane} = Tabs;

//Custom Helpers
const prepareConfig = (title: any, data: any) => {
    return config.syncReports.header.details.map(record => {
        let value = data[record.key];
        if (record.key === "title")
            value = title;
        return {
            ...record,
            value: value
        }
    })
};

const Details = (props: any) => {
    const {data, title, defaultOpen} = props;

    const {total: totalWarning} = data.warning_errors;
    const {total: totalBlocking} = data.blocking_errors;
    const {columns: missingColumns, total: totalMissing} = data.missing_columns;

    const totalErrors = totalMissing + totalWarning + totalBlocking;

    return (
        <Space className="fullWidth" direction='vertical' size='middle'>
            <Panel
                defaultOpen={defaultOpen}
                collapsible={totalErrors !== 0}
                header={<Header config={prepareConfig(title, {totalWarning, totalBlocking, totalMissing})}/>}>

                <Tabs tabBarExtraContent={totalMissing > 0 && <MissingColumns data={missingColumns}/>}>
                    {["blocking_errors", "warning_errors"].map((typeOfError: string, index: number) => {
                        const results = data[typeOfError].errors;
                        const tableTitle = humanString(typeOfError);

                        if (!results || !results.length)
                            return null;

                        return <TabPane tab={tableTitle} key={index} className="mt8">
                            <SmartTable
                                key={index}
                                centered
                                height={200}
                                allowGrouping
                                groupBy="column"
                                selectGrouping={false}
                                externalData={results}
                                columns={COLUMNS_DETAILS}/>
                        </TabPane>
                    })}
                </Tabs>
            </Panel>
        </Space>
    );
};

export default Details;
