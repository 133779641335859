import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const DelinquenciesAndLoss = () => {
  return (
    <>
      <div>
        <p>
          The left side of the section displays the timely evolution of the delinquency (in terms of days past due) of
          the loan of the selected perimeter. The User can select the time range to be shown, get additional information
          by hovering on the stacks or change the data from % to amount by clicking on the € icon.
        </p>
      </div>
      <CustomImage
        alt="Analytics Delinquencies and Loss Curve"
        src={assets.images.analyticsModule.delays}
      />
      <div>
        <p>
          The right side shows a graph with the cumulative loss recorded on the loans in the relevant parameter.
          The User can interact with the graph changing the style or the time span displayed, as well as the dataset
          by clicking on the arrow in the chart title.
        </p>
      </div>
    </>
  );
};

export default DelinquenciesAndLoss;
