import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Debtor = () => {
  return (
    <CustomImage
      alt="SPV Management Collateral Diversification Debtor"
      src={assets.images.spvModule.debtor}
      description="The section shows the list of the Top 20 debtors in the Collateral with a summary of relevant information."
    />
  );
};

export default Debtor;
