import { isVDRInvestor, isVDRUser } from "../../../../helpers";

const chartConfig: any = [
  {
    key: 'duration',
    label: 'Residual Maturity',
    labelEntity: 'duration_range',
    sortAsc: false,
    maxLegendItems: 3,
    allowGrouping: true,
  },
  {
    key: 'ticket_size',
    label: 'Loan Size',
    allowGrouping: true,
    labelEntity: 'amount_range',
    maxLegendItems: 3,
    sortAsc: false,
  },
  {
    key: 'debtor_region',
    label: 'Debtor Region',
    labelEntity: 'region',
    maxLegendItems: 3,
    allowGrouping: true,
    hasRedirection: true,
  },
  {
    key: 'sector',
    label: 'Debtor Industry',
    labelEntity: 'sector',
    maxLegendItems: 3,
    allowGrouping: true,
    hasRedirection: true,
  },
  {
    key: 'asset_type',
    label: 'Asset Type',
    labelEntity: 'asset_type',
    maxLegendItems: 3,
    allowGrouping: true,
  },
  {
    key: 'loan_rating',
    labelEntity: 'loan_rating',
    label: 'Loan Rating',
    maxLegendItems: 3,
    allowGrouping: true,
    hasRedirection: true,
  },
  {
    key: 'loan_performance',
    label: 'Performance Status',
    labelEntity: 'performance_status',
    allowGrouping: true,
    maxLegendItems: 3,
    hasRedirection: true,
  },
  {
    key: 'loan_type',
    label: 'Loan Type',
    labelEntity: 'loan_type',
    maxLegendItems: 3,
    allowGrouping: true,
    hasRedirection: true,
  },
  {
    key: 'loan_currently_modified_stats',
    label: 'Currently Modified',
    labelEntity: 'modified',
    sortAsc: false,
    maxLegendItems: 3,
    allowGrouping: true,
  },
  {
    key: 'loan_ever_modified_stats',
    label: 'Ever Modified',
    labelEntity: 'modified',
    maxLegendItems: 3,
    allowGrouping: true,
    hasRedirection: true,
  },
  {
    key: 'interest_rate_type_distribution_stats',
    label: 'Interest Rate Type',
    labelEntity: 'interest_rate_type',
    maxLegendItems: 3,
    allowGrouping: true,
  },
  {
    key: 'delinquent_timeline',
    label: 'Delinquent Timeline',
    labelEntity: 'delinquent_timeline',
    allowGrouping: true,
    maxLegendItems: 3,
    sortAsc: false,
  },
]

const getValue = (record: any, state: any) => {

  if (state && state.distribution === 'percentage')
    return record.ratio;

  if (state && state.distribution === 'count')
    return record.count;

  return record.balance;
};

const getFinalCharts = () => {
  const VDRInvestorCharts = ['duration', 'ticket_size', 'debtor_region', 'sector', 'loan_rating'];
  const vdrUser = isVDRUser();
  const vdrInvestor = isVDRInvestor();

  let charts = [];
  for (let chartElement of chartConfig) {
    const chartLabel = (chartElement.key === "loan_rating" && vdrUser) ? "Pricing Cluster" : chartElement.label;

    if (vdrInvestor && !VDRInvestorCharts.includes(chartElement.key))
      continue;

    charts.push({...chartElement, label: chartLabel})
  }
  return charts;
}


export {
  chartConfig,
  getValue,
  getFinalCharts
}