//Selections
import { selections } from "./constants";
//Other Libraries
import clone from "clone";
import queryString from "query-string";
//Helpers
import { dateFormat, formatters } from "../../helpers";
//Additional Config
const queryConfig: any = {arrayFormat: 'comma'};

const filterFormatters: any = {
  dateFormat: function (value: any) {
    if (value) {
      return value.format("YYYY-MM-DD")
    } else {
      return "Invalid Date"
    }
  },
  joinFormat: function (value: any) {
    return value.join(',');
  },
  defaultFormat: function (value: any) {
    return formatters.default(value);
  },
  currencyFormat: function (value: any) {
    return formatters.thousands(value);
  },
  percentFormat: function (value: any) {
    return formatters.percent(value);
  },
  entriesFormat: function (values: any) {
    return `${values.length} selected`
  },
  optionsFormat: function (value: any, extra: any) {
    let finalOptions = extra.options;

    if (!finalOptions)
      return null;

    let identifier = "id", label = "label";

    if (extra.optionsLabelAttr)
      label = extra.optionsLabelAttr;

    if (extra.optionsValueAttr)
      identifier = extra.optionsValueAttr;

    if (Array.isArray(value)) {
      return value.map(id => {
        const record = finalOptions.find((option: any) => option[identifier] == id);
        return record ? record[label] : null;
      }).join(',')
    } else {
      const record = finalOptions.find((option: any) => option[identifier] == value);
      return record ? record[label] : null;
    }
  },
};

const filterParsers = {
  sent: function (value: any, type: any) {
    if (type)
      return filterFormatters[type](value);
    return value;
  },
  view: function (entry: any, config: any, options: any) {
    const {value, operator, min, max} = entry;
    const {label, formatter, formatOption, ...rest} = config;
    let finalOptions = null;

    if (formatOption)
      finalOptions = options[formatOption];

    const extra = {
      ...rest,
      options: finalOptions,
    };

    if (entry.hasOwnProperty("value")) {
      if (operator) {
        if (operator === "Between")
          return `${label} : ${formatter(min, extra)}  –  ${formatter(max, extra)}`;
        return `${label} ${operator} ${formatter(value, extra)}`;
      }
      return `${label} (${formatter(value, extra)})`;
    }
    return formatter(label, extra);
  }
};

const geographyFields = [
  {
    label: "Country",
    attr: "country",
    type: "checkbox",
    placeholder: "Specify countries",
    formatter: filterFormatters.entriesFormat,
    options: "countries",
    mode: "multiple"
  },
  {
    label: "Region",
    attr: "region",
    type: "checkbox",
    placeholder: "Specify regions",
    formatter: filterFormatters.defaultFormat,
    options: "regions",
    mode: "multiple"
  },
  {
    label: "City",
    attr: "city",
    type: "checkbox",
    placeholder: "Specify cities",
    formatter: filterFormatters.defaultFormat,
    options: "cities",
    mode: "multiple"
  },
];

const filterResults = (results: any, key: any) => {
  let records: any[] = [];
  results.forEach((item: any) => {
    const value = item[key];
    if (value !== null && !records.includes(value)) {
      records.push(value)
    }
  });
  return records.map(record => results.find((item: any) => item[key] === record))
}

const config: any = {
  initialValues: {
    search: '',
    //Loan tables filters
    loanStatus: {
      selected: false,
      value: null
    },
    assetClass: {
      selected: false,
      value: null
    },
    performanceStatus: {
      selected: false,
      value: []
    },
    loanType: {
      selected: false,
      value: []
    },
    country: {
      selected: false,
      value: []
    },
    selectedCompanies: {
      selected: false,
      value: []
    },
    selectedSellerVAT: {
      selected: false,
      value: []
    },
    selectedSeller: {
      selected: false,
      value: []
    },
    currentBalance: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    maturityDate: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    poolAdditionDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    investmentDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    daysDelinquent: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    loanGrossRate: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    priceLoan: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    loanRating: {
      min: null,
      max: null,
      value: null,
      operator: null,
      selected: false
    },
    loanRatingInception: {
      min: null,
      max: null,
      value: null,
      operator: null,
      selected: false
    },
    sector: {
      selected: false,
      value: []
    },
    selectedGroups: {
      selected: false,
      value: []
    },
    region: {
      selected: false,
      value: []
    },
    city: {
      selected: false,
      value: []
    },
    selectedVehicles: {
      selected: false,
      value: []
    },
    selectedLenders: {
      selected: false,
      value: []
    },
    modified: {
      selected: false,
      value: []
    },
    pd: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    companyRating: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    //Real Estate Table
    property_type: {
      selected: false,
      value: []
    },
    countryRealEstate: {
      selected: false,
      value: []
    },
    cityRealEstate: {
      selected: false,
      value: []
    },
    areaSQM: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    cadastral_category: {
      selected: false,
      value: []
    },
    // energy_performance_certificate_provider_name: {
    //   selected: false,
    //   value: []
    // },
    originalValuationAmount: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    collateral_valuation_method: {
      selected: false,
      value: []
    },
    // current_valuation_method: {
    //   selected: false,
    //   value: []
    // },
    // currentValuation: {
    //   selected: false,
    //   value: null,
    //   operator: null,
    //   min: null,
    //   max: null
    // },
    // property_status: {
    //   selected: false,
    //   value: []
    // },
    asset_status: {
      selected: false,
      value: []
    },
    property_form_of_title: {
      selected: false,
      value: []
    },
    renovationStartDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    renovationEndDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    purchasePrice: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    sqmPrice: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    acquisitionDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    deal_type: {
      selected: false,
      value: []
    },
    deed_type: {
      selected: false,
      value: []
    },
    commercializationStartDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    deadlineNotarizationDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    notarizationDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    expectedSalePrice: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    // expectedSaleDate: {
    //   selected: false,
    //   value: null,
    //   operator: 'Between',
    //   min: null,
    //   max: null
    // },
    salePrice: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    saleDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    cappexDD: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    //Transaction Table
    spvName: {
      selected: false,
      value: []
    },
    loanStatusTransaction: {
      selected: false,
      value: []
    },
    loanTypeTransaction: {
      selected: false,
      value: []
    },
    countryTransaction: {
      selected: false,
      value: []
    },
    cityTransaction: {
      selected: false,
      value: []
    },
    issueDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    loanAge: {
      selected: false,
      value: []
    },
    originalBalance: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    poolAdditionDateTransaction: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    investmentAmount: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    price: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    currentBalanceTransaction: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    referenceDate: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    loanPurpose: {
      selected: false,
      value: []
    },
    initialLoanPrice: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    //Loan-by-loan Section
    acquisitionDateLoan: {
      selected: false,
      value: null,
      operator: 'Between',
      min: null,
      max: null
    },
    renovationStartDateLoan: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    renovationEndDateLoan: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    commercializationStartDateLoan: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    // commercializationEndDateLoan: {
    //   selected: false,
    //   value: null,
    //   operator: "Between",
    //   min: null,
    //   max: null
    // },
    sqmLoan: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    sqmMLoan: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    location: {
      selected: false,
      value: []
    },
    metropolitanArea: {
      selected: false,
      value: []
    },
    cadastralCategory: {
      selected: false,
      value: []
    },
    unitsLoan: {
      selected: false,
      value: []
    },
    statusLoan: {
      selected: false,
      value: []
    },
    statusEc: {
      selected: false,
      value: []
    },
    intendedUse: {
      selected: false,
      value: []
    },
    dealTypeLoan: {
      selected: false,
      value: []
    },
    deedTypeLoan: {
      selected: false,
      value: []
    },
    purchasePriceLoan: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    dueDiligencePriceLoan: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    initialRenovationBudget: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    // purchasePriceSurplus: {
    //   selected: false,
    //   value: null,
    //   operator: null,
    //   min: null,
    //   max: null
    // },
    initialRenovationCosts: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    purchasePriceDDPrice: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    ecPurchasePriceToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    ecPurchasePriceSurplusToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    renovationCompanyCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    renovationPartnerCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    extraRenovationBuyerCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    extraRenovationStructuralCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    notaryAtAcquisitionCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    notaryAtSaleCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    transferTaxToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    insuranceCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    propertyManagementCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    marketingCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    dueDiligenceCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    agencyFeeAtAcquisition: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    agencyFeeAtSale: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    otherCostsToDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    boDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    boSalePrice: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    daysSinceBo: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    saleTime: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    cashInDate: {
      selected: false,
      value: null,
      operator: "Between",
      min: null,
      max: null
    },
    cashInSalePrice: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    expectedSalePriceLoan: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    totalExpectedSalePriceLoan: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    rbiActual: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    ltcSenior: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    totalBorrowingBase: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    totalSeniorBorrowingBase: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    },
    seniorOutstanding: {
      selected: false,
      value: null,
      operator: null,
      min: null,
      max: null
    }

  },
  configuration: {
    loans: {
      search: {
        placeholder: "Search Loan ID / Company Name"
      },
      entries: [
        {
          label: "SPV",
          attr: "selectedVehicles",
          type: "checkbox",
          placeholder: "Specify Vehicles",
          options: "vehicles",
          formatOption: "vehicles",
          optionsLabelAttr: "name",
          optionsValueAttr: "id",
          formatter: filterFormatters.optionsFormat,
          mode: "multiple"
        },
        {
          label: "Lenders",
          attr: "selectedLenders",
          type: "checkbox",
          placeholder: "Specify Lenders",
          options: "lenders",
          formatOption: "lenders",
          optionsLabelAttr: "name",
          optionsValueAttr: "id",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Company",
          type: "selectWithSearch",
          attr: "selectedCompanies",
          placeholder: "Search company by name...",
          mode: "multiple",
          searchUrl: "/main/company/simple_search/",
          searchAttr: "name",
          formatter: filterFormatters.entriesFormat,
        },
        {
          label: "Asset Class",
          attr: "assetClass",
          type: "checkbox",
          placeholder: "Specify Asset Class",
          options: selections.assetClass,
          formatter: filterFormatters.defaultFormat,
          mode: "single"
        },
        {
          label: "Performance Status",
          attr: "performanceStatus",
          type: "checkbox",
          placeholder: "Specify performance status",
          options: selections.loanPerformanceStatus,
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Loan Type",
          attr: "loanType",
          type: "checkbox",
          placeholder: "Specify Loan Type",
          options: selections.loanType,
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Sector",
          attr: "sector",
          type: "checkbox",
          placeholder: "Specify sectors",
          formatter: filterFormatters.entriesFormat,
          options: "sectors",
          mode: "multiple"
        },
        ...geographyFields,
        {
          label: "Current Balance",
          type: "operator",
          operatorType: "euro",
          attr: "currentBalance",
          formatter: filterFormatters.currencyFormat
        },
        {
          label: "Maturity date",
          type: "operator",
          operatorType: "date",
          attr: "maturityDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Days Delinquent",
          type: "operator",
          operatorType: "default",
          attr: "daysDelinquent",
          formatter: filterFormatters.defaultFormat,
        },
        {
          label: "Loan Gross Return",
          type: "operator",
          operatorType: "percent",
          operatorProps: {
            normalizeValue: true,
          },
          attr: "loanGrossRate",
          formatter: filterFormatters.percentFormat,
        },
        {
          label: "Price",
          type: "operator",
          operatorType: "percent",
          operatorProps: {
            normalizeValue: true,
          },
          attr: "priceLoan",
          formatter: filterFormatters.percentFormat,
        },
        {
          label: "Loan Rating",
          type: "operator",
          operatorType: "select",
          attr: "loanRating",
          formatOption: "ratings",
          placeholder: "Select Rating",
          formatter: filterFormatters.optionsFormat
        },
        {
          label: "Rating at Inception",
          type: "operator",
          operatorType: "select",
          attr: "loanRatingInception",
          formatOption: "ratings",
          placeholder: "Select Rating at inception",
          formatter: filterFormatters.optionsFormat
        },
        {
          label: "Pool Addition Date",
          type: "operator",
          operatorType: "date",
          attr: "poolAdditionDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Investment Date",
          type: "operator",
          operatorType: "date",
          attr: "investmentDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Loan Status",
          attr: "loanStatus",
          type: "checkbox",
          placeholder: "Specify Statuses",
          options: selections.loanStatus,
          formatter: filterFormatters.defaultFormat,
          mode: "single"
        },
        {
          label: "Seller Name",
          type: "selectWithSearch",
          attr: "selectedSeller",
          placeholder: "Search seller by name...",
          mode: "multiple",
          searchUrl: "/main/sme_loan/",
          optionLabelAttr: "seller_name",
          optionKeyAttr: "seller_name",
          searchAttr: "seller__name",
          searchIncludesCount: true,
          formatResponse: filterResults,
          formatter: filterFormatters.entriesFormat,
        },
        {
          label: "Seller VAT",
          type: "selectWithSearch",
          attr: "selectedSellerVAT",
          placeholder: "Search by seller VAT...",
          mode: "multiple",
          searchUrl: "/main/sme_loan/",
          optionLabelAttr: "seller_vat",
          optionKeyAttr: "seller_vat",
          searchAttr: "seller__vat",
          searchIncludesCount: true,
          formatResponse: filterResults,
          formatter: filterFormatters.entriesFormat,
        },
        {
          label: "Modified Loan",
          attr: "modified",
          type: "checkbox",
          placeholder: "Specify Modified",
          options: selections.modified,
          formatter: filterFormatters.defaultFormat,
          mode: "single"
        },
      ]
    },
    realEstate: {
      search: {
        placeholder: ""
      },
      entries: [
        {
          label: "Property Type",
          attr: "property_type",
          type: "checkbox",
          placeholder: "Specify Property Type",
          options: "property_type",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Country",
          attr: "countryRealEstate",
          type: "checkbox",
          placeholder: "Specify countries",
          formatter: filterFormatters.entriesFormat,
          options: "country",
          mode: "multiple"
        },
        {
          label: "City",
          attr: "cityRealEstate",
          type: "checkbox",
          placeholder: "Specify cities",
          formatter: filterFormatters.defaultFormat,
          options: "city",
          mode: "multiple"
        },
        {
          label: "Area SQM",
          type: "operator",
          operatorType: "default",
          attr: "areaSQM",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Cadastral Category",
          attr: "cadastral_category",
          type: "checkbox",
          placeholder: "Specify Cadastral Category",
          options: "cadastral_category",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        // {
        //   label: "Energy Performance Certificate Provider Names",
        //   attr: "energy_performance_certificate_provider_name",
        //   type: "checkbox",
        //   placeholder: "Specify Energy Performance Certificate Provider Names",
        //   options: "energy_performance_certificate_provider_name",
        //   formatter: filterFormatters.entriesFormat,
        //   mode: "multiple"
        // },
        {
          label: "Original Valuation Amount",
          type: "operator",
          operatorType: "default",
          attr: "originalValuationAmount",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Original Valuation Method",
          attr: "collateral_valuation_method",
          type: "checkbox",
          placeholder: "Specify Original Valuation Method",
          options: "collateral_valuation_method",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        // {
        //   label: "Current Valuation",
        //   type: "operator",
        //   operatorType: "default",
        //   attr: "currentValuation",
        //   formatter: filterFormatters.defaultFormat
        // },
        // {
        //   label: "Current Valuation Method",
        //   attr: "current_valuation_method",
        //   type: "checkbox",
        //   placeholder: "Specify Original Valuation Method",
        //   options: "collateral_valuation_method",
        //   formatter: filterFormatters.entriesFormat,
        //   mode: "multiple"
        // },
        // {
        //   label: "Property Status",
        //   attr: "property_status",
        //   type: "checkbox",
        //   placeholder: "Specify Property Status",
        //   options: "property_status",
        //   formatter: filterFormatters.entriesFormat,
        //   mode: "multiple"
        // },
        {
          label: "Asset Status",
          attr: "asset_status",
          type: "checkbox",
          placeholder: "Specify Asset Status",
          options: "asset_status",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Property Form of Title",
          attr: "property_form_of_title",
          type: "checkbox",
          placeholder: "Specify Property Form of Title",
          options: "property_form_of_title",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Renovation Start Date",
          type: "operator",
          operatorType: "date",
          attr: "renovationStartDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Renovation End Date",
          type: "operator",
          operatorType: "date",
          attr: "renovationEndDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Total Purchase Price",
          type: "operator",
          operatorType: "default",
          attr: "purchasePrice",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "SQM Price",
          type: "operator",
          operatorType: "default",
          attr: "sqmPrice",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Acquisition Date",
          type: "operator",
          operatorType: "date",
          attr: "acquisitionDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Deal Type",
          attr: "deal_type",
          type: "checkbox",
          placeholder: "Specify Deal Type",
          options: "deal_type",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Deed Type",
          attr: "deed_type",
          type: "checkbox",
          placeholder: "Specify Deed Type",
          options: "deed_type",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Commercialization Start Date",
          type: "operator",
          operatorType: "date",
          attr: "commercializationStartDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Deadline Notarization Date",
          type: "operator",
          operatorType: "date",
          attr: "deadlineNotarizationDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Notarization Date",
          type: "operator",
          operatorType: "date",
          attr: "notarizationDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Expected Sale Price",
          type: "operator",
          operatorType: "default",
          attr: "expectedSalePrice",
          formatter: filterFormatters.defaultFormat
        },
        // {
        //   label: "Expected Sale Date",
        //   type: "operator",
        //   operatorType: "date",
        //   attr: "expectedSaleDate",
        //   formatter: filterFormatters.dateFormat
        // },
        {
          label: "Sale Price BO",
          type: "operator",
          operatorType: "default",
          attr: "salePrice",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "BO Accepted Date",
          type: "operator",
          operatorType: "date",
          attr: "saleDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Capex / DD",
          type: "operator",
          operatorType: "percent",
          operatorProps: {
            normalizeValue: true,
          },
          attr: "cappexDD",
          formatter: filterFormatters.percentFormat,
        },
        ...geographyFields,
      ]
    },
    realEstateTransactions: {
      search: {
        placeholder: ""
      },
      entries: [
        {
          label: "Spv Name",
          attr: "spvName",
          type: "checkbox",
          placeholder: "Specify Spv Name",
          options: "spv_name",
          formatOption: "spvName",
          optionsLabelAttr: "name",
          optionsValueAttr: "id",
          formatter: filterFormatters.optionsFormat,
          mode: "multiple"
        },
        {
          label: "Loan Status",
          attr: "loanStatusTransaction",
          type: "checkbox",
          placeholder: "Specify Statuses",
          options: "loan_status",
          formatter: filterFormatters.defaultFormat,
          mode: "single"
        },
        {
          label: "Loan Type",
          attr: "loanTypeTransaction",
          type: "checkbox",
          placeholder: "Specify Loan Type",
          options: "loan_type",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Country",
          attr: "countryTransaction",
          type: "checkbox",
          placeholder: "Specify countries",
          formatter: filterFormatters.entriesFormat,
          options: "country",
          mode: "multiple"
        },
        {
          label: "City",
          attr: "cityTransaction",
          type: "checkbox",
          placeholder: "Specify cities",
          formatter: filterFormatters.defaultFormat,
          options: "city",
          mode: "multiple"
        },
        {
          label: "Issue Date",
          type: "operator",
          operatorType: "date",
          attr: "issueDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Loan Age",
          attr: "loanAge",
          type: "checkbox",
          placeholder: "Specify Loan Age",
          options: "loan_age",
          formatOption: "loanAge",
          optionsLabelAttr: "loan",
          optionsValueAttr: "id",
          formatter: filterFormatters.optionsFormat,
          mode: "multiple"
        },
        {
          label: "Original Balance",
          type: "operator",
          operatorType: "default",
          attr: "originalBalance",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Pool Addition Date",
          type: "operator",
          operatorType: "date",
          attr: "poolAdditionDateTransaction",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Investment Amount",
          type: "operator",
          operatorType: "date",
          attr: "investmentAmount",
          formatter: filterFormatters.defaultFormat
        },

        {
          label: "Price",
          type: "operator",
          operatorType: "percent",
          operatorProps: {
            normalizeValue: true,
          },
          attr: "price",
          formatter: filterFormatters.percentFormat,
        },
        {
          label: "Current Balance",
          type: "operator",
          operatorType: "euro",
          attr: "currentBalanceTransaction",
          formatter: filterFormatters.currencyFormat
        },
        {
          label: "Reference Date",
          type: "operator",
          operatorType: "date",
          attr: "referenceDate",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Loan Purpose",
          attr: "loanPurpose",
          type: "checkbox",
          placeholder: "Specify Loan Purpose",
          options: "loan_purpose",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Initial Loan Price",
          type: "operator",
          operatorType: "default",
          attr: "initialLoanPrice",
          formatter: filterFormatters.defaultFormat
        },
        ...geographyFields,
      ]
    },
    realEstateLoanByLoanSection: {
      search: {
        placeholder: " "
      },
      entries: [
        {
          label: "Acquisition Date",
          type: "operator",
          operatorType: "date",
          attr: "acquisitionDateLoan",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Renovation Start Date",
          type: "operator",
          operatorType: "date",
          attr: "renovationStartDateLoan",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Renovation End Date",
          attr: "renovationEndDateLoan",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Commercializati on Start Date",
          attr: "commercializationStartDateLoan",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        // {
        //   label: "Commercializati on End Date",
        //   attr: "commercializationEndDateLoan",
        //   type: "operator",
        //   operatorType: "date",
        //   formatter: filterFormatters.dateFormat
        // },
        {
          label: "SQM",
          type: "operator",
          operatorType: "default",
          attr: "sqmLoan",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "SQM(M)",
          type: "operator",
          operatorType: "default",
          attr: "sqmMLoan",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Location",
          attr: "location",
          type: "checkbox",
          placeholder: "Specify Location",
          options: "location",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Metropolitan Area",
          attr: "metropolitanArea",
          type: "checkbox",
          placeholder: "Specify Metropolitan Area",
          options: "metropolitan_area",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Cadastral Category",
          attr: "cadastralCategory",
          type: "checkbox",
          placeholder: "Specify Cadastral Category",
          options: "cadastral_category",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "# Units",
          attr: "unitsLoan",
          type: "checkbox",
          placeholder: "Specify Units",
          options: "units",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Status",
          attr: "statusLoan",
          type: "checkbox",
          placeholder: "Specify Status",
          options: "asset_status",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Status EC (ACTIVE or NOT ACTIVE)",
          attr: "statusEc",
          type: "checkbox",
          placeholder: "Specify Status EC (ACTIVE or NOT ACTIVE)",
          options: "status_ec",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Intended Use",
          attr: "intendedUse",
          type: "checkbox",
          placeholder: "Specify Intended Use",
          options: "intended_use",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Deal Type",
          attr: "dealTypeLoan",
          type: "checkbox",
          placeholder: "Specify Deal Type",
          options: "deal_type",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Deed Type",
          attr: "deedTypeLoan",
          type: "checkbox",
          placeholder: "Specify Deed Type",
          options: "deed_type",
          formatter: filterFormatters.entriesFormat,
          mode: "multiple"
        },
        {
          label: "Purchase Price",
          type: "operator",
          operatorType: "default",
          attr: "purchasePriceLoan",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Due Diligence Price",
          type: "operator",
          operatorType: "default",
          attr: "dueDiligencePriceLoan",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Initial Renovation Budget (Net)",
          type: "operator",
          operatorType: "default",
          attr: "initialRenovationBudget",
          formatter: filterFormatters.defaultFormat
        },
        // {
        //   label: "Purchase Price - Surplus",
        //   type: "operator",
        //   operatorType: "default",
        //   attr: "purchasePriceSurplus",
        //   formatter: filterFormatters.defaultFormat
        // },
        {
          label: "Initial Renovation Costs",
          type: "operator",
          operatorType: "default",
          attr: "initialRenovationCosts",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Purchase Price / DD Price (PP / DD)",
          type: "operator",
          operatorType: "default",
          attr: "purchasePriceDDPrice",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "EC Purchase Price to Date",
          attr: "ecPurchasePriceToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "EC Purchase Price-Surplus to Date",
          attr: "ecPurchasePriceSurplusToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Renovation Company Costs to Date",
          attr: "renovationCompanyCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Renovation Partner Costs to Date",
          attr: "renovationPartnerCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Extra Renovation Buyer Costs to Date",
          attr: "extraRenovationBuyerCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Extra Renovation Structural Costs to Date",
          attr: "extraRenovationStructuralCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Notary at Acquisition Costs to Date",
          attr: "notaryAtAcquisitionCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Notary at Sale Costs to Date",
          attr: "notaryAtSaleCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Transfer Tax to Date",
          attr: "transferTaxToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Insurance Costs to Date",
          attr: "insuranceCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Property Management Costs to Date",
          attr: "propertyManagementCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Marketing Costs to Date",
          attr: "marketingCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Due Diligence Costs to Date",
          attr: "dueDiligenceCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Agency fee at Acquisition",
          attr: "agencyFeeAtAcquisition",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Agency fee at Sale",
          attr: "agencyFeeAtSale",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Other Costs to Date",
          attr: "otherCostsToDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "BO Date",
          attr: "boDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "BO Sale Price",
          attr: "boSalePrice",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Days since BO",
          attr: "daysSinceBo",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Sale Time",
          attr: "saleTime",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Cash-in Date",
          attr: "cashInDate",
          type: "operator",
          operatorType: "date",
          formatter: filterFormatters.dateFormat
        },
        {
          label: "Cash-in Sale Price",
          attr: "cashInSalePrice",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Expected Sale Price",
          attr: "expectedSalePriceLoan",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Total Expected Sale Price",
          attr: "totalExpectedSalePriceLoan",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "RBI Actual (Sold only)",
          attr: "rbiActual",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "LTC Senior",
          attr: "ltcSenior",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Total Borrowing Base",
          attr: "totalBorrowingBase",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Total Senior Borrowing Base",
          attr: "totalSeniorBorrowingBase",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },
        {
          label: "Senior Outstanding",
          attr: "seniorOutstanding",
          type: "operator",
          operatorType: "default",
          formatter: filterFormatters.defaultFormat
        },


        ...geographyFields
      ]
    }
  }
};

const Validity = {
  validate(value: any) {
    if (Array.isArray(value))
      return value !== null && value.length !== 0;
    else
      return value !== null && value !== undefined;
  },
  validateArray(config: any) {
    return Validity.validate(config.value);
  },
  validateObject(valueConfig: any) {
    if (valueConfig.operator === "Between")
      return Validity.validate(valueConfig.min) && Validity.validate(valueConfig.max);
    else
      return Validity.validate(valueConfig.value);
  }
};

const validators: any = {
  checkbox: Validity.validateArray,
  operator: Validity.validateObject,
  selectWithSearch: Validity.validateArray
};

const checkValidity = (value: any, config: any) => {
  const type = config.type;
  if (validators.hasOwnProperty(type)) {
    const validator = validators[type];
    return validator(value);
  }
  return true
};

export function filterInvalidFields(currentFields?: any, entity?: any) {
  const invalidFields = [];

  for (let field in currentFields) {
    if (currentFields.hasOwnProperty(field)) {
      const {selected, ...valueConfig} = currentFields[field];

      if (selected) {
        const fields = config.configuration[entity].entries;
        const fieldConfig = fields.find((record?: any) => record.attr === field);
        const validFilter = checkValidity(valueConfig, fieldConfig);

        if (!validFilter)
          invalidFields.push(field);
      }
    }
  }
  const finalFields = clone(currentFields);
  invalidFields.forEach(field =>
    finalFields[field].selected = false
  );

  return finalFields;
}

export function produceFiltersState(entity?: any) {
  const filtersInitialState: any = {};
  const filterEntries = config.configuration[entity].entries;
  const searchConfig = config.configuration[entity].search;
  filterEntries.forEach((filter?: any) => {
    const {attr} = filter;
    filtersInitialState[attr] = config.initialValues[attr]
  });
  return {filtersInitialState, filterEntries, searchConfig};
}

export function prepareFilters(filters?: any, view?: any) {

  let params = {};

  let operatorParams = {};

  function updateSearchParam(params?: any, filterType?: any, serverEntity?: any) {
    if (filters.hasOwnProperty(filterType))
      params[serverEntity] = filters[filterType];
  }

  function updateParam(params?: any, filterType?: any, serverEntity?: any, type?: any) {
    if (filters.hasOwnProperty(filterType)) {
      const {selected, value} = filters[filterType];
      if (selected)
        params[serverEntity] = filterParsers.sent(value, type);
    }
  }

  function updateOperatorParam(params?: any, filterType?: any, serverEntity?: any, type?: any) {
    if (filters.hasOwnProperty(filterType)) {
      const filterEntry = filters[filterType];
      const {selected, value, min, max, operator} = filterEntry;
      let finalValue = value;
      if (filterType === "active") {
        params[serverEntity] = selected;
      } else {
        if (selected) {
          if (operator) {
            switch (operator) {
              case '=': {
                serverEntity = `${serverEntity}`;
                params[serverEntity] = filterParsers.sent(finalValue, type);
                break;
              }
              case '>': {
                serverEntity = `${serverEntity}__gt`;
                params[serverEntity] = filterParsers.sent(finalValue, type);
                break;
              }
              case '>=': {
                serverEntity = `${serverEntity}__gte`;
                params[serverEntity] = filterParsers.sent(finalValue, type);
                break;
              }
              case  '<': {
                serverEntity = `${serverEntity}__lt`;
                params[serverEntity] = filterParsers.sent(finalValue, type);
                break;
              }
              case  '<=': {
                serverEntity = `${serverEntity}__lte`;
                params[serverEntity] = filterParsers.sent(finalValue, type);
                break;
              }
              case 'Between': {
                if (min !== null && max !== null) {
                  serverEntity = `${serverEntity}__range`;
                  params[serverEntity] = [filterParsers.sent(min, type), filterParsers.sent(max, type)];
                }
                break;
              }
              default:
                break;
            }
          } else {
            if (filterEntry.hasOwnProperty("value"))
              params[serverEntity] = filterParsers.sent(finalValue, type);
            else
              params[serverEntity] = selected;
          }
        }
      }
    }
  }


  if (filters) {
    //Loan Table filters
    updateParam(params, "country", "country");
    updateParam(params, "assetClass", "asset_class");
    updateParam(params, "loanType", "loan_type");
    updateParam(params, "loanStatus", "loan_status");
    updateParam(params, "selectedCompanies", "company");
    updateParam(params, "selectedSeller", "seller__name", "joinFormat");
    updateParam(params, "selectedSellerVAT", "seller__vat", "joinFormat");
    updateParam(params, "performanceStatus", "performance_status");
    if (view === "loans") {
      updateParam(params, "region", "company__region_name");
      updateParam(params, "city", "company__city");
    }
    //Company Table filters
    updateParam(params, "sector", "sector");
    updateParam(params, "monitoringStatus", "monitoring_status");
    updateParam(params, "selectedVehicles", "spv_name");
    updateParam(params, "selectedLenders", "lender");
    updateParam(params, "pd", "probability_of_default");
    updateParam(params, "companyRating", "rating");
    updateSearchParam(params, "search", "search");
    //Loan Table filters
    updateOperatorParam(operatorParams, "loanRating", "last_report__platform_loan_rating__investorloanrating__value");
    updateOperatorParam(operatorParams, "loanRatingInception", "loan_rating_at_inception");
    updateOperatorParam(operatorParams, "loanGrossRate", "last_report__loan_gross_rate");
    updateOperatorParam(operatorParams, "priceLoan", "last_report__loan_price");
    updateOperatorParam(operatorParams, "currentBalance", "last_report__current_balance");
    updateOperatorParam(operatorParams, "daysDelinquent", "last_report__days_delinquent");
    updateOperatorParam(operatorParams, "maturityDate", "last_report__maturity_date", "dateFormat");
    updateOperatorParam(operatorParams, "poolAdditionDate", "pool_addition_date", "dateFormat");
    updateOperatorParam(operatorParams, "investmentDate", "investment_date", "dateFormat");
    updateParam(params, "modified", "modified");
    //Real Estate Table Filters
    updateParam(params, "property_type", "property_type");
    updateParam(params, "countryRealEstate", "country");
    updateParam(params, "cityRealEstate", "city");
    updateParam(params, "cadastral_category", "cadastral_category");
    // updateParam(params, "energy_performance_certificate_provider_name", "energy_performance_certificate_provider_name");
    updateParam(params, "collateral_valuation_method", "original_valuation_method");
    // updateParam(params, "current_valuation_method", "current_valuation_method");
    // updateParam(params, "property_status", "property_status");
    updateParam(params, "asset_status", "asset_status");
    updateParam(params, "property_form_of_title", "property_form_of_title");
    updateParam(params, "deal_type", "deal_type");
    updateParam(params, "deed_type", "deed_type");
    updateOperatorParam(params, "areaSQM", "net_square_metres");
    updateOperatorParam(params, "originalValuationAmount", "original_valuation_amount");
    // updateOperatorParam(params, "currentValuation", "current_valuation");
    updateOperatorParam(params, "renovationStartDate", "renovation_start_date", "dateFormat");
    updateOperatorParam(params, "renovationEndDate", "renovation_end_date", "dateFormat");
    updateOperatorParam(params, "purchasePrice", "purchase_price");
    updateOperatorParam(params, "sqmPrice", "sqm_price");
    updateOperatorParam(params, "acquisitionDate", "acquisition_date", "dateFormat");
    updateOperatorParam(params, "commercializationStartDate", "commercialization_start_date", "dateFormat");
    updateOperatorParam(params, "deadlineNotarizationDate", "deadline_notarization_date", "dateFormat");
    updateOperatorParam(params, "notarizationDate", "notarization_date", "dateFormat");
    updateOperatorParam(params, "expectedSalePrice", "expected_sale_price");
    // updateOperatorParam(params, "expectedSaleDate", "expected_sale_date");
    updateOperatorParam(params, "salePrice", "sale_price");
    updateOperatorParam(params, "saleDate", "sale_date");
    updateOperatorParam(params, "cappexDD", "cappex_dd");
    //Transaction Table
    updateParam(params, "spvName", "spv_name");
    updateParam(params, "loanStatusTransaction", "loan_status");
    updateParam(params, "loanTypeTransaction", "loan_type");
    updateParam(params, "countryTransaction", "country");
    updateParam(params, "cityTransaction", "city");
    updateParam(params, "issueDate", "issue_date");
    updateParam(params, "loanAge", "loan_age");
    updateParam(params, "originalBalance", "original_balance");
    updateOperatorParam(operatorParams, "poolAdditionDateTransaction", "pool_addition_date_transaction", "dateFormat");
    updateOperatorParam(operatorParams, "investmentAmount", "investment_amount");
    updateOperatorParam(params, "price", "price");
    updateOperatorParam(params, "currentBalanceTransaction", "current_balance");
    updateOperatorParam(operatorParams, "referenceDate", "reference_date", "dateFormat");
    updateOperatorParam(params, "loanPurpose", "loan_purpose");
    updateOperatorParam(params, "initialLoanPrice", "initial_loan_price");
    //Loan-by-loan Section
    updateOperatorParam(params, "acquisitionDateLoan", "acquisition_date", "dateFormat");
    updateOperatorParam(params, "renovationStartDateLoan", "renovation_start_date");
    updateOperatorParam(params, "renovationEndDateLoan", "renovation_end_date", "dateFormat");
    updateOperatorParam(params, "commercializationStartDateLoan", "commercialization_start_date", "dateFormat");
    // updateOperatorParam(params, "commercializationEndDateLoan", "commercialization_end_date", "dateFormat");
    updateOperatorParam(params, "sqmLoan", "sqm", "defaultFormat");
    updateOperatorParam(params, "sqmMLoan", "sqm_m", "defaultFormat");
    // updateParam(params, "location", "location");
    updateParam(params, "metropolitanArea", "metropolitan_area");
    updateParam(params, "cadastralCategory", "cadastral_category");
    updateParam(params, "unitsLoan", "units");
    updateParam(params, "statusLoan", "status");
    updateParam(params, "statusEc", "status_ec");
    updateParam(params, "intendedUse", "intended_use");
    updateParam(params, "dealTypeLoan", "deal_type");
    updateParam(params, "deedTypeLoan", "deed_type");
    updateOperatorParam(params, "purchasePriceLoan", "purchase_price", "defaultFormat");
    updateOperatorParam(params, "dueDiligencePriceLoan", "due_diligence_price", "defaultFormat");
    updateOperatorParam(params, "initialRenovationBudget", "initial_renovation_budget", "defaultFormat");
    // updateOperatorParam(params, "purchasePriceSurplus", "purchase_price_surplus", "defaultFormat");
    updateOperatorParam(params, "initialRenovationCosts", "initial_renovation_costs", "defaultFormat");
    updateOperatorParam(params, "purchasePriceDDPrice", "purchase_price_dd_price", "defaultFormat");
    updateOperatorParam(params, "ecPurchasePriceToDate", "ec_purchase_price_to_date", "dateFormat");
    updateOperatorParam(params, "ecPurchasePriceSurplusToDate", "ec_purchase_price_surplus_to_date", "dateFormat");
    updateOperatorParam(params, "renovationCompanyCostsToDate", "renovation_company_costs_to_date", "dateFormat");
    updateOperatorParam(params, "renovationPartnerCostsToDate", "renovation_partner_costs_to_date", "dateFormat");
    updateOperatorParam(params, "extraRenovationBuyerCostsToDate", "extra_renovation_buyer_costs_to_date", "dateFormat");
    updateOperatorParam(params, "extraRenovationStructuralCostsToDate", "extra_renovation_structural_costs_to_date", "dateFormat");
    updateOperatorParam(params, "notaryAtAcquisitionCostsToDate", "notary_at_acquisition_costs_to_date", "dateFormat");
    updateOperatorParam(params, "notaryAtSaleCostsToDate", "notary_at_sale_costs_to_date", "dateFormat");
    updateOperatorParam(params, "transferTaxToDate", "transfer_tax_to_date", "dateFormat");
    updateOperatorParam(params, "insuranceCostsToDate", "insurance_costs_to_date", "dateFormat");
    updateOperatorParam(params, "propertyManagementCostsToDate", "property_management_costs_to_date", "dateFormat");
    updateOperatorParam(params, "marketingCostsToDate", "marketing_costs_to_date", "dateFormat");
    updateOperatorParam(params, "dueDiligenceCostsToDate", "due_diligence_costs_to_date", "dateFormat");
    updateOperatorParam(params, "agencyFeeAtAcquisition", "agency_fee_at_acquisition", "defaultFormat");
    updateOperatorParam(params, "agencyFeeAtSale", "agency_fee_at_sale", "defaultFormat");
    updateOperatorParam(params, "otherCostsToDate", "other_costs_to_date", "dateFormat");
    updateOperatorParam(params, "boDate", "bo_date", "dateFormat");
    updateOperatorParam(params, "boSalePrice", "bo_sale_price", "defaultFormat");
    updateOperatorParam(params, "daysSinceBo", "days_since_bo", "dateFormat");
    updateOperatorParam(params, "saleTime", "sale_time", "dateFormat");
    updateOperatorParam(params, "cashInDate", "cash_in_date", "dateFormat");
    updateOperatorParam(params, "expectedSalePriceLoan", "cash_in_sale_price", "dateFormat");
    updateOperatorParam(params, "expectedSalePriceLoan", "expected_sale_price_loan", "defaultFormat");
    updateOperatorParam(params, "totalExpectedSalePriceLoan", "total_expected_sale_price_loan", "defaultFormat");
    updateOperatorParam(params, "rbiActual", "rbi_actual", "defaultFormat");
    updateOperatorParam(params, "ltcSenior", "ltc_senior", "defaultFormat");
    updateOperatorParam(params, "totalBorrowingBase", "total_borrowing_base", "defaultFormat");
    updateOperatorParam(params, "totalSeniorBorrowingBase", "total_senior_borrowing_base", "defaultFormat");
    updateOperatorParam(params, "seniorOutstanding", "senior_outstanding", "defaultFormat");
  }

  return `${queryString.stringify(params)}&${queryString.stringify(operatorParams, queryConfig)}`
}

export function isFilterActive(filters?: any) {
  let active = false;
  for (let filter in filters) {
    if (filters.hasOwnProperty(filter)) {
      if (filter !== "search") {
        const filterRecord = filters[filter];
        if (filterRecord && filterRecord.selected && !active) {
          active = true;
        }
      }
    }
  }
  return active;
}

export function getActiveFilters(filterEntries?: any, filters?: any, options?: any) {
  const activeFilters = [];

  for (let filter in filters) {
    let valid = true;
    if (filter !== "search" && filters.hasOwnProperty(filter)) {
      const filterEntry = filters[filter];
      if (filterEntry.hasOwnProperty("value")) {
        if (filterEntry.selected) {
          if (filterEntry.hasOwnProperty("operator")) {
            const operator = filterEntry.operator;
            if (operator) {
              if (operator === "Between") {
                if (!filterEntry.min || !filterEntry.max)
                  valid = false;
              } else {
                if (!filterEntry.value)
                  valid = false;
              }
            } else {
              valid = false
            }
          } else {
            if (!filterEntry.value)
              valid = false
          }
        } else {
          valid = false;
        }
      }
      const config = filterEntries.find((filterEntry?: any) => filterEntry.attr === filter);
      if (valid && config) {
        activeFilters.push({
          identifier: filter,
          label: filterParsers.view(filterEntry, config, options)
        })
      }
    }
  }
  return activeFilters;
}

export function removeHiddenFields(filters?: any, hiddenFields?: any) {
  const formattedFilters: any = {};
  for (let filter in filters) {
    if (filters.hasOwnProperty(filter) && !hiddenFields.includes(filter))
      formattedFilters[filter] = filters[filter];
  }
  return formattedFilters;
}

export function prepareSearchOptions(entity?: any, fields?: any) {
  const optionsFields: any = {};
  const entries = config.configuration[entity].entries;
  for (let field in fields) {
    if (fields.hasOwnProperty(field)) {
      const fieldConfig = entries.find((entry: any) => entry.attr === field);
      if (fieldConfig && fieldConfig.type === "selectWithSearch")
        optionsFields[field] = [];
    }
  }
  return optionsFields
}
