import React from 'react';
//Other Libs
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom"
//Services
import { history } from "./history";
//Css
import 'antd/dist/antd.css';
import "ka-table/style.css";
//Contexts
import ThemeProvider from "./assets/theme/context";
//Custom Components
import AppRoutes from "./routes";
import { subscribeToHistory } from "./helpers";
import withCacheService from "./utils/hocs/with_cache_service";
import { AuthenticationProvider } from "./utils/context/authentication_provider";

subscribeToHistory(history);

const App = () => (
  <BrowserRouter>
    <AuthenticationProvider>
      <ThemeProvider>
        <AppRoutes/>
      </ThemeProvider>
    </AuthenticationProvider>
  </BrowserRouter>
)

const AppContent = withCacheService(App);

ReactDOM.render(<AppContent/>, document.getElementById('root'));
