import React from "react";
//Components
import { Button, Col, Row, Select } from "antd";
import { NumberInput } from "@cardoai/components";
//Helpers
import { callApi } from "../../../../../../../../../../../helpers";
//Icons
import { DeleteOutlined } from "@ant-design/icons";

const {Option} = Select;

interface IApolloContent {
  index?: any,
  hideDeleteButton?: any,
  onFieldsChange?: any,
  onFieldsRemove?: any,
  funding?: any,
  vehicleId?: any
}

const ApolloContent = (props: IApolloContent) => {

  const {index, hideDeleteButton, onFieldsChange, onFieldsRemove, funding, vehicleId} = props;
  const {amount, fund_name, coupon} = funding;

  const [funds, setFunds] = React.useState<any>([]);

  React.useEffect(() => {
    callApi({
      url: `/main/investor/get_funds/?vehicle_id=${vehicleId}`,
      onSuccess: (response) => setFunds(response)
    })
  }, [])

  return (
    <Row gutter={8} align="middle" className="mt8 mb16">
      <Col>
        <div className="bold">Choose Fund:</div>
        <Select onChange={onFieldsChange('fund_name', index)} style={{width: 150}} placeholder="Select Fund"
                value={fund_name}>
          {funds.map((fund: any) => {
            return <Option key={fund.id} value={fund.name}>
              {fund.name}
            </Option>
          })}
        </Select>
      </Col>
      <Col>
        <span className="bold">Fund Amount: </span>
        <NumberInput type='thousands' placeholder="Enter Fund Amount" value={amount}
                     onChange={onFieldsChange('amount', index)}/>
      </Col>
      <Col>
        <span className="bold">Coupon Rate: </span>
        <NumberInput type='percent' placeholder="Enter Coupon" value={coupon}
                     onChange={onFieldsChange('coupon', index)}/>
      </Col>
      <Col className="mt16">
        {!hideDeleteButton &&
          <Button shape="round" size="small" onClick={onFieldsRemove(index)} icon={<DeleteOutlined/>} danger/>}
      </Col>
    </Row>
  )
};


export default ApolloContent;