const getNotionalSum = (data: any) => {
  let sum = 0;
  data.forEach((limit: any) => sum += parseFloat((limit.fulfillment)))
  return sum;
}

const isEligibleCriteria = (data: any) => {
  return data.every((record: any) => !!record.broken)
}

const filterRecords = (data: any, limitName = "New Loans Principal") => {
  return {
    newLoans: data.filter((record: any) => record.name !== limitName),
    newLoansPrincipal: data.find((record: any) => record.name === limitName) || {}
  }
}


export {
  getNotionalSum,
  isEligibleCriteria,
  filterRecords
}