import { formatters } from "../../../../../../../helpers";

export const reconciliation_table: any = [
  {
    key: "tranche_class",
    label: "Tranche",
    align: "center",
  },
  {
    key: "subscriber_name",
    label: "Subscriber",
    align: "center"
  },
  {
    key: "subscription_date",
    label: "Funding Date",
    format: formatters.date,
    align: "center"
  },
  {
    key: "funding_amount",
    label: "Funding Amount",
    format: formatters.thousands,
    align: "center"
  },
  {
    key: "currency_code",
    label: "Currency",
    align: "center"
  },
  {
    key: "coupon",
    label: "Coupon",
    format: formatters.percent,
    align: "center"
  },
  {
    key: "reconciliation_period_end",
    label: "Reconciliation Period End",
    format: formatters.date,
    align: "center"
  },
  {
    key: "gross_accrued_interest",
    label: "Gross Acc. Interest",
    format: formatters.thousands,
    align: "center"
  },
  {
    key: "retained_interest",
    label: "Retained Interest",
    format: formatters.thousands,
    align: "center"
  },
  {
    key: "net_accrued_interest",
    label: "Net Acc. Interest",
    format: formatters.thousands,
    align: "center"
  }
]
