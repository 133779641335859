import React from "react";
//Custom Components
import CircularChart from "../../circular_chart";
//Helpers
import { charts } from "../helpers";


interface ChartVisualisationProps {
  type?: any,
  isCircular?: any,
  onChartClick: (param?: any) => void,
  options?: any,
  chartConfig?: any,
  data: any,
  fullscreenMode?: any,
  fixedDimensions?: any,
  height?: any,
  chartSize?: any,
  onLegendClick: (param?: any) => void,
  legendConfigurations?: any,
  reference?: any,
  plugins?: any,
  identifier?: any
}

const ChartVisualisation = (props: ChartVisualisationProps) => {

  const {type, isCircular, onChartClick, options, chartConfig, reference, plugins, identifier, ...rest} = props;

  const Visualisation = charts[type]

  return isCircular ? <CircularChart Visualisation={Visualisation}
                                     onChartClick={onChartClick}
                                     options={options}
                                     reference={reference}
                                     plugins={plugins}
                                     identifier={identifier}
                                     chartConfig={chartConfig} {...rest}/> :
    <Visualisation id={identifier} ref={reference} data={chartConfig} options={options}
                   onClick={onChartClick} plugins={plugins}/>
};

export default ChartVisualisation;