import React from 'react';
//Components
import { Card } from "antd";
import Documents from "../documents";
import { BrandName, HorizontalCollapse } from "@cardoai/components";
//Hooks
import { useAuthorization } from "../../../../utils/hooks";
//Constants
import { actionKey } from "../../../../constants";
import { config, DOCUMENT_IDENTIFIER, PANEL_POSITION } from "../../config";

interface IReports {
  vehicle: any,
  vehicleId: any,
  collapseReference: any,
  triggerCollapse: any
}

const Reports = (props: IReports) => {
  const {vehicle, vehicleId} = props;

  const authorization: any = useAuthorization();
  const collapseReference = React.useRef();

  const triggerCollapse = (key: any) => () => {
    if (window.innerWidth < 1750)
      return;
    const collapseAPI: any = collapseReference && collapseReference.current;
    const position = PANEL_POSITION[key];
    collapseAPI.updateChildPanel(position);
  }

  const databaseReports = authorization.canPerformAction(actionKey.getCardoAIReports) && (
    <Card size='small' title={<BrandName children="Reports"/>} bordered={false}>
      <Documents
        vehicle={vehicle}
        vehicleId={vehicleId}
        title="Import New Data Tape"
        documentIdentifier={DOCUMENT_IDENTIFIER.reports}
        config={config[DOCUMENT_IDENTIFIER.reports]}
        triggerCollapse={triggerCollapse('left')}
      />
    </Card>
  );

  const otherReports = authorization.canPerformAction(actionKey.getOtherReports) && (
    <Card size='small' title="Other Reports" bordered={false}>
      <Documents
        vehicle={vehicle}
        vehicleId={vehicleId}
        title="Import Other Report"
        documentIdentifier={DOCUMENT_IDENTIFIER.otherReports}
        config={config[DOCUMENT_IDENTIFIER.otherReports]}
        triggerCollapse={triggerCollapse('right')}
      />
    </Card>
  );

  return (
    <HorizontalCollapse
      activePanel="both"
      ref={collapseReference}
      nodes={[
        {
          text: "CardoAI Reports",
          content: databaseReports
        },
        {
          text: "Other Reports",
          content: otherReports
        }
      ]}/>
  );
};
export default Reports;
