import React from 'react';
//Other Libs
import cx from "classnames";
//Styles
import { StyledDiv, Table } from "../../../../../styles";
//Icons
import { MinusOutlined } from "@ant-design/icons";
//Helpers
import { formatters, isEmpty, validateValue } from "../../../../../../../../../../../helpers";
//Components
import { SimpleEmpty } from "@cardoai/components";
import { EmojiBreach } from "../../../../../../../../../../../components";
//Helpers
import withLimitDownload from "../../../../../components/hoc/with_limit_download";

const getColor = (value: any) => {
  return !value ? "redText" : "greenText"
}


const NewLoans = (props: any) => {
  const {data, currency} = props;
  const {records, isEligible} = data;
  const {newLoans, newLoansPrincipal} = records;

  const formatter_list: any = {
    "Years": formatters.years,
    "Percentage": formatters.percent,
    "Amount": (value: any) => formatters.currency({value, currency})
  }

  if (isEmpty(records.newLoans) && isEmpty(records.newLoansPrincipal))
    return <SimpleEmpty/>

  return (
    <StyledDiv>
      <Table className="fullWidth">
        <thead>
        <tr>
          <th/>
          <th className="textCenter primary">Amount</th>
          <th className="textCenter primary">Percentage</th>
          <th className="textCenter primary">Cap/Floor</th>
          <th className="textCenter primary">Check</th>
        </tr>
        <tr>
          <th className="primary">New Loans Principal</th>
          <th className="textCenter">{formatters.currency({value: newLoansPrincipal.fulfillment, currency})}</th>
          <th/>
        </tr>
        <tr>
          <th>Of which:</th>
          <th/>
          <th/>
        </tr>
        </thead>
        <tbody>
        {newLoans.map((record: any, index: number) => {
          return (
            <tr key={index}>
              <td className="pl24">{record.name}</td>
              <td className="pl24">{validateValue((record.fulfillment ||
                <MinusOutlined/>), formatter_list[record.fulfillment_formatter])}</td>
              <td className="pl24">{validateValue(record.fulfillment_percent ||
                <MinusOutlined/>, formatters.percent)}</td>
              <td className="pl24">
                {validateValue((record.threshold || record.threshold_percent), formatter_list[record.threshold_formatter])}
                <span className="bolder">
                    {record.threshold_type ? ` (${record.threshold_type})` : ""}
                  </span>
              </td>
              <td style={{textAlign: 'center'}}>
                <EmojiBreach record={record}/>
              </td>
            </tr>)
        })}
        <tr>
          <td className="bold">Eligibility Criteria Match?</td>
          <td/>
          <td/>
          <td/>
          <td className={cx("pl24", getColor(isEligible))}>{isEligible.toString().toUpperCase()}</td>
        </tr>
        </tbody>
      </Table>
    </StyledDiv>
  );
};

export default withLimitDownload(NewLoans)({
  title: 'New Loan Limits',
  prepareData: function (data: any) {
    const records = data.records.newLoans;
    const isEligible = data.isEligible;
    const newLoansPrincipal = data.records.newLoansPrincipal;

    const downloadableStructure = records.map((record: any) => {
      const {name, fulfillment, fulfillment_percent, threshold_percent, threshold_type, broken} = record;
      return {
        "Name": name,
        "Amount": fulfillment,
        "Percentage": fulfillment_percent,
        "Cap/Floor": `${threshold_percent} ${threshold_type}`,
        "Check": broken,
      }
    });

    downloadableStructure.push({
      "Name": newLoansPrincipal.name,
      "Amount": newLoansPrincipal.fulfillment
    })

    downloadableStructure.push({
      "Name": "Eligibility Criteria Match?",
      "Check": isEligible
    })

    return downloadableStructure;
  }
});