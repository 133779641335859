import React from 'react';
//Components
import { Button } from "antd";
//Icons
import { DeleteOutlined } from "@ant-design/icons";
//Helpers
//Constants
import { STATUS_TYPE } from "../status/config";
//Helpers
import { callApi } from "../../../../../../helpers";

interface Props {
  id?: any,
  fileType?: any,
  vehicleId?: any,
  status?: any
  requestReports?: any
}

const Delete = (props: Props) => {
  const {status, requestReports, id, vehicleId} = props;

  const [loading, setLoading] = React.useState(false);

  const allowDelete = [
    STATUS_TYPE.ready,
    STATUS_TYPE.health_check_failed,
  ].includes(status);

  const onDelete = () => {
    if (!allowDelete)
      return;

    callApi({
      url: `/main/sync_request/${id}/?vehicle_id=${vehicleId}`,
      method: "delete",
      setLoading,
      onSuccess: () => {
        requestReports();
      },
      onFinish: () => setLoading(false)
    })
  };

  return (
    <Button danger size="small" loading={loading} onClick={onDelete} disabled={!allowDelete} icon={<DeleteOutlined/>}>
      Delete
    </Button>
  );
};

export default Delete;