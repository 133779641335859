import React from 'react';
//Components
import { SimpleEmpty } from "@cardoai/components";
import LimitsDefaultTable from "./components/limits_default_table";
//Utils
import config from '../../../../../../constants/config'
import { withData, withDownload } from "../../../../../../utils";
//Helpers
import { prepareVehicleContentUrl } from "../../helpers";
import { isEmpty } from "../../../../../../helpers";
import { useMediator } from "@cardoai/utils";

interface Props {
  height?: any,
  data?: any,
  vehicle?: any,
  vehicleId?: any
  currency?: any,
  requestData: () => void,
}

const Limits = (props: Props) => {
  const {height, data, requestData, vehicle, vehicleId, currency} = props;
  const vehicleIdentifier: any = vehicle.vehicle_identifier;

  useMediator('Limits', { refreshLimits: () => requestData() });

  if (isEmpty(data))
    return <SimpleEmpty description="No Data Available Yet"/>

  /*Check if the vehicle has a custom limit view and return that custom view instead of the general limits table*/

  const hasCustomLimitsView = config.hasOwnProperty(vehicleIdentifier);

  if (hasCustomLimitsView) {
    const {component: Component, ...rest} = config[vehicleIdentifier].default;
    return <Component data={data} type="default" currency={currency}
                      vehicleIdentifier={vehicleIdentifier} {...props} {...rest} />
  }

  return <LimitsDefaultTable currency={currency} height={height} data={data} vehicle={vehicle} vehicleId={vehicleId}/>
}

export const formatLimitResponse = (result: any) => {
  /**
   * This function takes BE data and formats the limit to group them by the "limit_group__name".The new structure is an
   * array of objects, which includes the category (limit group), records (all the limits under a specific grouping) and
   * also a boolean key group (if the limit is part of a group or not)
   *
   * @params: result -> BE data
   */

  if (!result)
    return null;

  const limits: any = {};
  const structure = [];

  result.limits.forEach((limit: any) => {
    const {limit_group__name, ...config} = limit;

    if (!limits.hasOwnProperty(limit_group__name))
      limits[limit_group__name] = [];

    limits[limit_group__name].push(config);
  });

  for (let category in limits) {
    structure.push({
      category: category,
      records: limits[category],
      group: category !== "null" && category !== "undefined"
    })
  }

  /*Finally, the function returns an object with all the limits grouped in an array of object and the reference date
  * of the limits. */

  return {
    allLimits: structure,
    referenceDate: result.reference_date
  };
}


export default withData({
  formatResponse: formatLimitResponse,
  urlFormatter: prepareVehicleContentUrl("/main/limit/limits")
})(withDownload(Limits)({}));