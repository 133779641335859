import React from 'react'
//Components
import { CustomTable } from "@cardoai/components";
//Helpers
import { formatLimits } from "../../../../../helpers";

interface Props {
  data?: any,
  subGroupName?: any,
  currency?: any
}

const TableLimits = (props: Props) => {

  const {data, subGroupName, currency} = props

  const limitsIndented = ["Un - capped Senior Target Amount", "o.w. Unused Fee"];
  const limitsInBold = ["Total", "New Borrowing base"];
  const limitsInItalic = ["Un - capped Senior Target Amount", "o.w. Unused Fee", "New Borrowing base"];

  return (
    <CustomTable hover={false} height={280}>
      <thead>
      <th className="textCenter" colSpan={2}>{subGroupName}</th>
      </thead>
      <tbody>
      {data.map(({limit_name, fulfillment, formatter}: any, index:number) => {
        const classNameBold: any = limitsInBold.includes(limit_name) ? "bold " : '';
        const classNameItalic: any = limitsInItalic.includes(limit_name) ? "italic " : '';
        const classNameIndent: any = limitsIndented.includes(limit_name) ? "textCenter" : 'textLeft';
        return (
          <tr key={index}>
            <td className={classNameBold + classNameItalic + classNameIndent}>{limit_name}</td>
            <td className={classNameBold} width={100}>{formatLimits(fulfillment, formatter, currency)}</td>
          </tr>
        )
      })}
      </tbody>
    </CustomTable>
  )
}
export default TableLimits;