import React from "react";
//Helpers
import { formatters, Text } from "../../../../helpers";
//Icons
import { MinusOutlined } from "@ant-design/icons";

export const columns = [
  //General Group
  {
    key: "platform_loan_id",
    label: Text("Loan\nID"),
    group: "General",
    sortable: true,
    align: "center",
    sticky: true,
    disableSelection: true,
    noWrap: true,
  },
  {
    key: "spv_name",
    label: Text("SPV\nName"),
    width: 200,
    group: "General",
    noWrap: true,
    sticky: true,
    disableSelection: true,
  },
  {
    key: "originator",
    label: Text("Originator"),
    sortable: true,
    width: 200,
    group: "General",
    noWrap: true,
  },
  {
    key: "company_name",
    label: Text("Debtor\nName"),
    sortable: true,
    width: 200,
    group: "General",
    noWrap: true,
  },
  {
    key: "company_vat",
    label: Text("Company\nVAT"),
    sortable: true,
    width: 100,
    group: "General",
  },
  {
    key: "loan_status",
    label: Text("Loan\nStatus"),
    group: "General",
    sortable: true,
    noWrap: true,
  },
  {
    key: "loan_type",
    label: Text("Loan\nType"),
    sortable: true,
    group: "General",
    noWrap: true,
  },
  {
    key: "sector",
    label: "Sector",
    sortable: false,
    width: 150,
    group: "General",
  },
  {
    key: "sector_code",
    label: Text("ATECO\nCode"),
    sortable: false,
    width: 150,
    group: "General",
  },
  {
    key: "company_country",
    label: Text("Country"),
    sortable: true,
    width: 100,
    group: "General",
  },
  {
    key: "company_region_name",
    label: Text("Region"),
    sortable: true,
    width: 100,
    group: "General",
  },
  {
    key: "company_city",
    label: Text("City"),
    sortable: true,
    group: "General",
  },
  {
    key: "issue_date",
    label: Text("Issue\nDate"),
    sortable: false,
    width: 100,
    format: formatters.date,
    group: "General",
  },
  {
    key: "loan_age",
    label: Text("Loan Age\n(yrs)"),
    sortable: true,
    width: 120,
    format: formatters.years,
    group: "General",
  },
  {
    key: "currency",
    download_key: "currency",
    label: Text("Currency"),
    format: (value: any) => value.abbreviation,
    sortable: true,
    group: "General",
  },
  {
    key: "loan_amount",
    label: Text("Original\nBalance"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    sortable: true,
    group: "General",
  },
  {
    key: "pool_addition_date",
    label: Text("Pool\nAddition Date"),
    sortable: true,
    width: 150,
    format: formatters.date,
    group: "General",
  },
  {
    key: "investment_date",
    label: Text("Investment\nDate"),
    sortable: true,
    width: 150,
    format: formatters.date,
    group: "General",
  },
  {
    key: "investment_amount",
    label: Text("Investment\nAmount"),
    width: 150,
    sortable: true,
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    group: "General",
  },
  {
    key: "loan_price",
    label: Text("Price"),
    sortable: true,
    format: formatters.percent,
    group: "General",
  },
  {
    key: "maturity_date",
    label: Text("Maturity\nDate"),
    sortable: true,
    format: formatters.date,
    group: "General",
  },
  {
    key: "performance_status",
    label: Text("Performance\nstatus"),
    sortable: true,
    group: "General",
  },
  {
    key: "remaining_term",
    label: Text("Residual Maturity\n(yrs)"),
    sortable: true,
    width: 140,
    format: formatters.thousands,
    group: "General",
  },
  {
    key: "average_residual_life",
    label: Text("Avg. Residual\n Maturity(yrs)"),
    sortable: true,
    width: 140,
    format: formatters.thousands,
    group: "General",
  },
  {
    key: "days_delinquent",
    label: Text("DPD"),
    sortable: true,
    group: "General",
    format: formatters.thousandsShorted,
  },
  {
    key: "current_balance",
    label: Text("Current\nBalance"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    sortable: true,
    group: "General",
    noWrap: true,
  },
  {
    key: "accrued_interest",
    label: Text("Accrued\nInterest"),
    format: formatters.thousands,
    sortable: false,
    group: "General",
  },
  {
    key: "loan_gross_rate",
    label: Text("Loan\nGross Return"),
    sortable: true,
    width: 120,
    format: formatters.percent,
    group: "General",
  },
  {
    key: "coupon",
    label: Text("Coupon"),
    sortable: true,
    sortingName: "loan_interest_rate_exp",
    format: formatters.percent,
    group: "General",
  },
  {
    key: "loan_rating",
    label: Text("Rating"),
    sortable: true,
    group: "General",
    noWrap: true,
  },
  {
    key: "rating_at_inception",
    label: Text("Rating at inception"),
    sortable: true,
    group: "General",
    noWrap: true,
  },
  {
    key: "pd",
    label: Text("PD"),
    sortable: false,
    group: "General",
    format: formatters.percent,
    noWrap: true,
  },
  {
    key: "lgd",
    label: Text("LGD"),
    format: formatters.percent,
    sortable: false,
    group: "General",
  },
  {
    key: "sae",
    label: Text("SAE"),
    format: formatters.default,
    sortable: false,
    group: "General",
  },
  {
    key: "date",
    label: Text("Reference\nDate"),
    format: formatters.date,
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "loan_interest_rate_act",
    label: Text("Actual Loan\nInterest Rate"),
    format: formatters.percent,
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "spread",
    label: Text("Spread"),
    format: formatters.addPercent,
    sortable: false,
    group: "General",
  },
  {
    key: "original_maturity",
    label: Text("Original\nMaturity"),
    format: formatters.date,
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "loan_purpose",
    label: Text("Loan\nPurpose"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "interest_rate_type",
    label: Text("Interest\n Rate Type"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "original_term",
    label: Text("Original\n Term"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "loan_initial_weighted_average_maturity",
    label: Text("Initial Weighted\nAverage Maturity"),
    sortable: false,
    format: formatters.thousands,
    width: 130,
    group: "General",
  },
  {
    key: "initial_loan_price",
    label: Text("Initial Loan\nPrice"),
    sortable: false,
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    group: "General",
  },
  {
    key: "residual_term",
    label: Text("Residual \nTerm"),
    sortable: false,
    group: "General",
  },
  {
    key: "invoice_number",
    label: Text("Invoice \nNumber"),
    sortable: false,
    group: "General",
  },
  {
    key: "accrued_interests_at_pool_addition_date",
    label: Text("Accrued Interests At Pool Addition Date"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "current_interest_rate_index",
    label: Text("Current Interest Rate Index"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "current_interest_rate_index_tenor",
    label: Text("Current Interest Rate Index Tenor"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "index_valuation_date",
    label: Text("Index Valuation Date"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "current_index",
    label: Text("Current Index"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "current_interest_rate_margin",
    label: Text("Current Interest Rate Margin"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "interest_rate_floor",
    label: Text("Interest Rate Floor"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "description",
    label: Text("Description"),
    sortable: false,
    width: 130,
    group: "General",
  },
  {
    key: "realized_return",
    label: Text("Realized Return"),
    sortable: false,
    format: formatters.percent,
    width: 130,
    group: "General",
  },
  {
    key: "expected_return",
    label: Text("Expected Return"),
    sortable: false,
    format: formatters.percent,
    width: 130,
    group: "General",
  },
  {
    key: "accrued_interest_default_date",
    label: Text("Accrued Interest Default Date"),
    sortable: false,
    width: 130,
    group: "General",
  },

  //Collateral Group
  {
    key: "primary_collateral_type",
    label: Text("Primary\nCollateral"),
    sortable: false,
    width: 100,
    group: "Collateral",
  },
  {
    key: "primary_collateral_coverage",
    label: Text("Primary Collateral\nCoverage (%)"),
    sortable: true,
    format: formatters.percent,
    width: 150,
    group: "Collateral",
  },
  //Modification Group Columns
  {
    key: "last_modified_date",
    label: Text("Modified\n Date"),
    sortable: true,
    format: formatters.date,
    width: 150,
    group: "Modification",
  },
  {
    key: "last_modified_type",
    label: Text("Modified\n Type"),
    sortable: true,
    format: formatters.default,
    width: 150,
    group: "Modification",
  },
  {
    key: "last_modification_reason",
    label: Text("Modification\n Reason"),
    sortable: false,
    format: formatters.default,
    width: 150,
    group: "Modification",
  },
  //New Low Priority Columns
  {
    key: "loan_status_prior_last_modification",
    label: Text("Pre-Modification \n Performance status"),
    sortable: true,
    format: formatters.default,
    width: 150,
    group: "Modification",
  },
  {
    key: "last_modification_end_date",
    label: Text("Modification\n End Date"),
    sortable: true,
    format: formatters.date,
    width: 150,
    group: "Modification",
  },
  {
    key: "number_of_times_modified",
    label: Text("Number of \n Times Modified"),
    sortable: false,
    format: formatters.default,
    width: 150,
    group: "Modification",
  },
  //Seller Group
  {
    key: "seller_name",
    label: Text("Seller\nName"),
    sortable: true,
    width: 100,
    group: "Seller",
  },
  {
    key: "seller_vat",
    label: Text("Seller\nVAT"),
    sortable: true,
    width: 100,
    group: "Seller",
  },
  //Realized Group
  {
    key: "closing_date",
    label: Text("Closing\nDate"),
    sortable: false,
    format: formatters.date,
    width: 150,
    group: "Realized",
  },
  {
    key: "realized_term",
    label: Text("Realized \nMaturity/DSO (yrs)"),
    format: formatters.thousands,
    sortable: false,
    width: 200,
    group: "Realized",
  },
  {
    key: "payment_delays",
    label: Text("Payment\nDelays (days)"),
    format: (value: any) => (value === 0 ? <MinusOutlined /> : value),
    sortable: true,
    width: 200,
    group: "Realized",
  },
  {
    key: "installment_amount_realized",
    label: Text("Cumulated Installment \n Principal"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    width: 180,
    sortable: false,
    group: "Realized",
  },
  {
    key: "cumulated_interest_amount",
    label: Text("Cumulated Installment\n Interest"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    sortable: false,
    width: 180,
    group: "Realized",
  },
  {
    key: "write_off_amount",
    label: Text("Write Off\nAmount"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    sortable: false,
    group: "Realized",
  },
  {
    key: "write_off_date",
    label: Text("Write Off\n Date"),
    format: formatters.date,
    sortable: false,
    width: 130,
    group: "Realized",
  },
  {
    key: "prepaid_principal",
    label: Text("Prepaid\nPrincipal"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    sortable: false,
    width: 130,
    group: "Realized",
  },
  {
    key: "default_date",
    label: Text("Default\nDate"),
    format: formatters.date,
    sortable: false,
    width: 100,
    group: "Realized",
  },
  {
    key: "amount_default_date",
    label: Text("Amount at Default\n Date"),
    format: formatters.thousands,
    sortable: false,
    width: 130,
    group: "Realized",
  },
  {
    key: "redemption_date",
    label: Text("Redemption\n Date"),
    format: formatters.date,
    sortable: false,
    group: "Realized",
  },
  //Delays Group
  {
    key: "first_arrears",
    label: Text("First Arrears\n Date"),
    width: 150,
    format: formatters.date,
    sortable: false,
    group: "Delays",
  },
  {
    key: "last_arrears",
    label: Text("Last Arrears\n Date"),
    width: 150,
    format: formatters.date,
    sortable: false,
    group: "Delays",
  },
  {
    key: "arrears_balance",
    label: Text("Arrears\nBalance"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    sortable: false,
    group: "Delays",
  },
  {
    key: "arrears_capital",
    label: Text("Capital\nArrears"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    sortable: false,
    group: "Delays",
  },
  {
    key: "arrears_interest",
    label: Text("Interest\nArrears"),
    format: (value: any, rest: any) =>
      formatters.currency({ value, currency: rest?.currency }),
    sortable: false,
    group: "Delays",
  },
];

export const cacheEntity = "loans_tabular_format";

export const initialSort = ["-current_balance"];
