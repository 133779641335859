import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const CashFlow = () => {
  return (
    <>
      <div>
        <p>
          The left side of this section provides details on the cash flows of the Collateral pool, confronting the
          expected
          flow with the realized ones. Using the commands highlighted in the figure below, the User can interact with
          the
          chart selecting the time span to be displayed, changing the type of graph or viewing this information in a
          tabular format.
        </p>
      </div>
      <CustomImage
        alt="SPV Management Collateral Cash Flow"
        src={assets.images.spvModule.collateralCashFlow}
      />
      <div>
        <p>
          The right side shows the list of the cash flows of the loans in the Collateral pool. The User can choose to
          view cash
          flows at Portfolio (aggregated) or Loan (i.e. organized by Loan ID) level. Also in this case, the User can
          browse data
          by selecting time range, searching by the type of cashflow or looking up a specific Loan ID. The User can also
          click on
          any listed cash flow within the table to open the Loan Details view and get additional insights on the
          amortization plan of the loan.
        </p>
      </div>
    </>
  );
};

export default CashFlow;
