//Custom Components
import { Box, Circle } from "@cardoai/components";
//Constants
import { theme } from "../../../../../assets/theme/colors";

const shapes: any = {
  box: Box,
  circle: Circle
};

//Principal_and_interest should remain first dataset (first priority) because if there is a value,
//it should discard principal only or interest only and display 'principal_and_interest' dataset

const config: any = {
  spacing: 4,
  entities: [
    {
      key: "principal_and_interest",
      label: "Principal and Interest",
      color: theme.colors.blueChill
    },
    {
      key: "interest",
      label: "Interest",
      color: theme.colors.darkorange
    },
    {
      label: "Principal Only",
      key: "principal",
      color: theme.colors.darkblue
    },
    {
      label: "No Payment",
      key: "no_payment",
      color: theme.colors.lightpurple
    }
  ]
};


//Helper functions

const prepareData = (data: any) => {
  if (!data)
    return [];
  /**
   * This helper function modifies the BE data structure to one that is adaptable for being used in the notes_section table
   * It finds the record inside "summary" that has the same tranche name as the record inside "details"
   *
   * it returns an array of objects that has all the information of "details", adding also the repayment graph from
   * the "summary" field
   *
   * @params: {
   *   details: [],
   *   loan_structure: {},
   *   summary: []
   * }
   */

  return data.details.map((record: { name: any; }) => {
    const summaryRecord = data.summary.find((item: { tranche: any; }) => item.tranche === record.name);
    return {
      ...record,
      repayment: summaryRecord.repayment_graph
    }
  })
}

const setSticky = (index: number): any => {
  const firstCol = index === 0;
  return firstCol ? 'sticky' : ''
}

const setStripes = (index: number) => {
  return index % 2 === 0 ? theme.colors.white : theme.colors.darkwhite
}

export {
  shapes,
  config,
  setSticky,
  setStripes,
  prepareData,
}