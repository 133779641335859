//Custom Components

import { NumberInput } from "@cardoai/components";

export const userInput: any = [
  {
    label: "Euribor % (t-1)",
    key: "euribor_t_min_1",
    Component: NumberInput,
    props: {
      type: "percent",
      normalizeValue: true,
    }
  },
  {
    label: "Euribor % (t-2)",
    key: "euribor_t_min_2",
    Component: NumberInput,
    props: {
      type: "percent",
      normalizeValue: true,
    }
  },
  {
    label: "Euribor % (t-3)",
    key: "euribor_t_min_3",
    Component: NumberInput,
    props: {
      type: "percent",
      normalizeValue: true,
    }
  },
  {
    label: "General Accounts Balance",
    key: "general_accounts_balance",
    Component: NumberInput,
  },
  {
    label: "Excess Cash Account Balance",
    key: "excess_cash_account_balance",
    Component: NumberInput,
  },
  {
    label: "Disposals Account Balance",
    key: "disposal_account_balance",
    Component: NumberInput,
  },
  {
    label: "Registration Tax Reserve Account Balance",
    key: "registration_tax_reserve_account_balance",
    Component: NumberInput,
  },
  {
    label: "Senior Drawn Margin %",
    key: "senior_drawn_margin",
    Component: NumberInput,
    props: {
      type: "percent",
      normalizeValue: true,
    }
  },
  {
    label: "New Discretional Buffer",
    key: "new_discretional_buffer",
    Component: NumberInput,
  },
  {
    label: "Subordinated Debt Repayment (t-1)",
    key: "subordinated_debt_repayment",
    Component: NumberInput,
  },
  {
    label: "Excess Cash Account Replenishment",
    key: "excess_cash_account_replenishment",
    Component: NumberInput,
  },
  {
    label: "Subordinated Debt Additional Drawn (t-1)",
    key: "subordinated_debt_additional_drawn",
    Component: NumberInput,
  },
  {
    label: "Dividend Payment (t-1)",
    key: "dividend_payment",
    Component: NumberInput,
  },
];
