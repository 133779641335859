import React from 'react';
//Other Libs
import { Link } from 'react-router-dom';

interface ILinkView {
  href: any,
  children: any
}

const LinkView = ({href, children}: ILinkView) => {
  return (
    <Link to={href}>
      {children}
    </Link>
  );
};

export default LinkView;