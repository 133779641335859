import React, { useState } from 'react';
//Components
import { Alert, Button } from "antd";

const containerStyle = {
  paddingTop: "16px",
  paddingBottom: "16px"
};

const Error = (props: any) => {
  const {status, description} = props;
  const [visible, setVisible] = useState(false);

  const onClick = () => {
    setVisible(previous => !previous);
  };

  return (
    <div style={containerStyle}>
      <Alert
        showIcon
        type="error"
        message={status}
        description={visible ? description : "An error has occurred! Click to open technical details!"}
        action={
          <Button size="small" danger onClick={onClick}>
            {visible ? "Hide details" : "View details"}
          </Button>
        }
      />
    </div>
  );
};

export default Error;