import React from 'react';
//Components
import { SimpleEmpty, Tabs } from "@cardoai/components";
import LoansSimulation from "./loans";
import NotesSimulation from "./notes";
//Helpers
import { isEmpty } from "../../../../../helpers";
//Constants
const tabsConfig = [
  {
    key: 'loans',
    label: 'Collateral',
    content: LoansSimulation
  },
  {
    key: 'notes',
    label: 'Notes',
    content: NotesSimulation
  }
]

const SimulationResults = (props: { data?: any }) => {
  const {data} = props;

  if (!data)
    return null;

  if (isEmpty(data))
    return <SimpleEmpty/>

  return (
    <Tabs
      data={data}
      config={tabsConfig}
      defaultActive="loans"/>
  )
}

export default SimulationResults;
