import React from 'react';
//Styles
import {StyledChild, StyledDiv} from "./styles";
//Helpers
import {formatThousands} from "../../../../../helpers";
//Static Data
import stats from "../../stats.json";

const LuzzattiHDRMainStats = () => {
  const mainStats = stats.main_stats;

  return (
    <StyledDiv>
      {Object.entries(mainStats).map(([label, value]) => {
        // Format only float numbers
        if (typeof value === "number" && !Number.isInteger(value))
          value = formatThousands(value, 0);

        return (
          <StyledChild key={label}>
            <p className="value">{value}</p>
            <p className="label">{label}</p>
          </StyledChild>
        )
      })}
    </StyledDiv>
  );
};

export default LuzzattiHDRMainStats;