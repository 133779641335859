import React from 'react';
//Custom Components
import { MessageBox, Section } from "../../../../components";

const DisabledViews = () => {
  return (
    <div>
      <Section
        header="SPV Addition Status"
        body="Details of the loan at the date it was added to the pool (purchase price, residual balance, etc.)"
      />
      <MessageBox type='warning' description="This feature is currently under development."/><br/>
      <Section
        header="Status & Notes"
        body="Here the user can receive specific updates on the status of the loans and notes/comments provided by the Servicer."
      />
      <MessageBox type='warning' description="This feature is currently under development."/><br/>
      <Section
        header="Files"
        body="In this section the user can access files (e.g. covenant certificates, loan agreement, financials, etc.) available that are provided by the Servicer."
      />
      <MessageBox type='warning' description="This feature is currently under development."/>
    </div>
  );
};

export default DisabledViews;
