//Other Libs
import cx from "classnames";
//Helpers
import { formatters, isToday } from "../../../../../helpers";

/** Amortization table Configurations*/

export const AMORTIZATION_PLAN_DATASETS: any = {
  'FUNDING': 'funding',
  'FUNDING ADJUSTMENT': 'funding_adjustment',
  'INTEREST': 'interest',
  'REPAYMENT CAPITAL': 'capital',
  'REPURCHASE': 'capital',
  'LATE FEES': 'late_fees',
  'OTHER': 'other',
  'REPAYMENT ADJUSTMENT': 'repayment_adjustment'
}


export const amortizationPlan: any = (currency: any) => {
  return [
    {key: "date", label: "Date", align: 'center'},
    {key: "capital", label: "Principal", format: (value: any) => formatters.currency({value, currency})},
    {key: "interest", label: "Interest", format: (value: any) => formatters.currency({value, currency})},
    {key: "late_fees", label: "Late Fees", format: (value: any) => formatters.currency({value, currency})},
  ]
}


export const amortizationPlanRealized: any = (currency: any) => {
  return [
    {key: "date", label: "Date", align: 'center'},
    {key: "funding", label: "Funding", format: (value: any) => formatters.currency({value, currency})},
    {
      key: "funding_adjustment",
      label: "Funding Adjustment",
      format: (value: any) => formatters.currency({value, currency})
    },
    {
      key: "repayment_adjustment",
      label: "Repayment Adjustment",
      format: (value: any) => formatters.currency({value, currency})
    },
    {key: "capital", label: "Principal", format: (value: any) => formatters.currency({value, currency})},
    {key: "interest", label: "Interest", format: (value: any) => formatters.currency({value, currency})},
    {key: "late_fees", label: "Late Fees", format: (value: any) => formatters.currency({value, currency})},
    {key: "other", label: "Other", format: (value: any) => formatters.currency({value, currency})},
  ]
}


/** Changes table Configurations*/

export const changesTable: any = [
  {
    width: 120,
    key: "date",
    label: "Date",
    format: formatters.date,
    group: "Expected"
  },
  {
    key: "current_balance",
    label: "Current Balance",
    format: formatters.thousands,
    group: "Expected"
  },
  {
    key: "installment_amount",
    label: "Installment Amount",
    format: formatters.thousands,
    group: "Expected"
  },
  {
    key: "installment_interest_amount",
    label: "Installment Interest Amount",
    format: formatters.thousands,
    group: "Expected"
  },
  {
    key: "installment_capital_amount",
    label: "Installment Capital Amount",
    format: formatters.thousands,
    group: "Expected"
  },
  {
    key: "installment_amount_realized",
    label: "Installment Amount Realized",
    format: formatters.thousands,
    group: "Realized"
  },
  {
    key: "installment_interest_amount_realized",
    label: "Installment Interest Amount Realized",
    format: formatters.thousands,
    group: "Realized"
  },
  {
    key: "installment_capital_amount_realized",
    label: "Installment Capital Amount Realized",
    format: formatters.thousands,
    group: "Realized"
  }
];


/** Events table Configurations and Helpers */

export const amountClass = (amount: any) => cx({greenText: amount > 0, redText: amount < 0})
export const dateClass = (date: any) => cx({greyBackground: isToday(date)})

export const radioButton: any = [
  {
    key: "realized",
    label: "Realized"
  },
  {
    key: "expected",
    label: "Expected"
  }
]


