//Helpers
import { downloadClientFile } from "../../helpers"
import { client as Fetch } from "../../helpers";

const baseURL = "https://excel-generator-staging.k8s.cardoai.com"

export const client = (params: any) => {
  return Fetch({
    ...params,
    baseURL: baseURL
  })
}

export const CLIENT_OPTIONS = [
  {
    key: 'postgres',
    label: 'Postgresql'
  }
]


export const downloadTemplate = (id: any) => {
  client({
    url: `/api/template/download/${id}`,
    method: 'get',
    responseType: 'json',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then((response: any) =>
    downloadClientFile({
      url: response.data.url,
      filename: "download.xlsx"
    }))
}