import React, { useContext } from 'react';

const DebtorContext = React.createContext({})

const DebtorWaiverProvider = ({children}: any) => {

  const [valueChecked, setValueChecked] = React.useState<number>();
  const [selectTypeOfCalculationWaiver, setSelectTypeOfCalculationWaiver] = React.useState<string>('');

  const updateValueChecked = (company_id: number) => {
    setSelectTypeOfCalculationWaiver('');
    if (company_id === valueChecked) setValueChecked(undefined);
    else setValueChecked(company_id);
  }

  const updateTypeOfCalculationWaiver = (value: string) => {
    setSelectTypeOfCalculationWaiver(value);
  }

  return (
    <DebtorContext.Provider value={{
      valueChecked,
      updateValueChecked,
      selectTypeOfCalculationWaiver,
      updateTypeOfCalculationWaiver
    }}>
      {children}
    </DebtorContext.Provider>
  );
};

export default DebtorWaiverProvider;

export const useDebtorContext = () => {
  return useContext(DebtorContext);
}
