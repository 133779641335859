import React from "react";
//Layout
import Layout from "../layout";
//Other Libs
import { Route, Routes } from "react-router-dom";

const Content = (props: any) => {
  const {style, routeConfig} = props;

  return (
    <Routes>
      {routeConfig.map((route: any) => {
        return (
          <Route key={route.key} path={route.path} element={<Layout config={route} style={style}>{route.element}</Layout>}/>
        )
      })}
    </Routes>
  )
}
export default Content;
