import React from 'react';
//Components
import { Button, Popconfirm } from "antd";

const Remove = (props: any) => {
  const {withPopUp, fields, onClick, ...rest} = props;

  function confirm(e: any) {
    onClick();
  }

  if (!withPopUp)
    return (
      <Button onClick={onClick} type="danger" {...rest}>
        Delete
      </Button>
    );

  return (
    <Popconfirm
      okText="Yes"
      cancelText="No"
      onConfirm={confirm}
      title="Are you sure to delete this scenario?">
      <Button type="danger" {...rest}>
        Delete
      </Button>
    </Popconfirm>
  );
};

Remove.defaultProps = {
  withPopUp: false
}

export default Remove;