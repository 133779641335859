import React from 'react';
//Other Libs
import { useNavigate } from "react-router-dom";
//Components
import { Button, Modal } from "antd";
//Icons
import { CloudUploadOutlined } from "@ant-design/icons";
//Custom Constants
const modalStyles = {
  container: {
    margin: 0,
    padding: 0
  },
  content: {
    paddingTop: 0,
    paddingBottom: 8,
    paddingRight: 16,
    paddingLeft: 16
  }
};

interface Config {
  backTo?: any,
  defaultWidth?: any,
  modalTitle?: string,
  useButton?: boolean,
  defaultVisible?: boolean,
}

interface Props {
  title?: string,
  onClose?: () => void,
  documentId?: any,
  vehicleId?: any,
  makeRequest?: any
  vehicle?: any,
}

const withModal = (config: Config = {}) => (Component: any) => (props: Props) => {
  const {title, onClose, ...rest} = props;

  const navigate = useNavigate();
  const [key, setKey] = React.useState(1);
  const [modalTitle, setModalTitle] = React.useState(config.modalTitle)
  const [visible, setVisible] = React.useState(config.defaultVisible);
  const [modalWidth, setModalWidth] = React.useState(config.defaultWidth);
  const [allowCloseModal, setAllowCloseModal] = React.useState(true);

  const onClick = () => {
    setVisible(true);
  };

  const onCancel = () => {
    if (config.backTo) {
      navigate(config.backTo);
    } else {
      setVisible(false);
      if (onClose)
        onClose();
    }
  };

  const onUnmount = () => {
    setKey(previousKey => previousKey + 1);
  };

  const changeModalWidth = (value: any) => {
    setModalWidth(value)
  }

  const setWidthAsContent = () => {
    setModalWidth(undefined);
  }

  const params: any = {};

  if (allowCloseModal)
    params.onCancel = onCancel;

  return (
    <>
      <Modal centered closable footer={<div/>} destroyOnClose visible={visible} width={modalWidth}
             title={modalTitle} style={modalStyles.container} bodyStyle={modalStyles.content}{...params}>
        <div className="mt16"/>
        <Component
          {...rest}
          key={String(key)}
          handleUnmount={onUnmount}
          handleCloseModal={onCancel}
          changeModalTitle={setModalTitle}
          allowModalClose={setAllowCloseModal}
          changeModalWidth={changeModalWidth}
          setWidthAsContent={setWidthAsContent}
        />
      </Modal>
      {config.useButton && (
        <Button
          type='primary'
          onClick={onClick}
          className="floatR mt16"
          icon={<CloudUploadOutlined/>}>
          {title}
        </Button>
      )}
    </>
  )
}

export default withModal;