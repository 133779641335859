import { formatters } from '../../../../../../../../helpers';

export const groupedColumnsInventory: any = {
  'property_information': [
    {
      label: 'Property ID',
      key: 'property_id',
      align: 'center',
    },
    {
      label: 'Property ID (M)',
      key: 'property_id_m',
      align: 'center',
    },
    {
      label: 'Reference',
      key: 'reference',
      align: 'center',
    },
    {
      label: 'Municipality',
      key: 'municipality',
      align: 'center',
    },
    {
      label: 'Metropolitan Area',
      key: 'metropolitan_area',
      align: 'center',
    },
    {
      label: 'Country',
      key: 'country',
      align: 'center',
    },
    {
      label: 'Expected Sale Price',
      key: 'expected_sale_price',
      align: 'center',
    },
    {
      label: 'Total Expected Sale Price',
      key: 'total_expected_sale_price',
      align: 'center',
    },
    {
      label: 'SQM',
      key: 'sqm',
      align: 'center',
    },
    {
      label: 'SQM (M)',
      key: 'sqm_m',
      align: 'center',
    },
    {
      label: 'Status',
      key: 'status',
      align: 'center',
    },
    {
      label: 'Deal Type',
      key: 'deal_type',
      align: 'center',
    },
    {
      label: 'Deed Type',
      key: 'deed_type',
      align: 'center',
    },
  ],
  'pp': [
    {
      label: 'Purchase Price',
      key: 'purchase_price',
      align: 'center',
    },
  ],
  'financed_costs': [
    {
      label: 'Down-Payment',
      key: 'down_payment',
      align: 'center',
    },
    {
      label: 'Outstanding Purchase Price',
      key: 'outstanding_purchase_price',
      align: 'center',
    },
    {
      label: 'Purchase Price-Surplus',
      key: 'purchase_price_surplus',
      align: 'center',
    },
    {
      label: 'Renovation Company',
      key: 'renovation_company',
      align: 'center',
    },
    {
      label: 'Renovation Partner',
      key: 'renovation_partner',
      align: 'center',
    },
    {
      label: 'Extra Renovation Buyer',
      key: 'extra_renovation_buyer',
      align: 'center',
    },
    {
      label: 'Extra Renovation Structural',
      key: 'extra_renovation_structural',
      align: 'center',
    },
    {
      label: 'Notary at Acquisition - CR',
      key: 'notary_at_acquisition_cr',
      align: 'center',
    },
    {
      label: 'Notary at Acquisition - RE',
      key: 'notary_at_acquisition_re',
      align: 'center',
    },
    {
      label: 'Notary at Acquisition - Gamma',
      key: 'notary_at_acquisition_gamma',
      align: 'center',
    },
    {
      label: 'Transfer Tax',
      key: 'transfer_tax',
      align: 'center',
    },
    {
      label: 'Insurance',
      key: 'insurance',
      align: 'center',
    },
  ],
  'other_costs': [
    {
      label: 'Property Management',
      key: 'property_management',
      align: 'center',
    },
    {
      label: 'Due Diligence',
      key: 'due_diligence',
      align: 'center',
    },
    {
      label: 'Marketing',
      key: 'marketing',
      align: 'center',
    },
    {
      label: 'Notary at Sale - CR',
      key: 'notary_at_sale_cr',
      align: 'center',
    },
    {
      label: 'Notary at Sale - RE',
      key: 'notary_at_sale_re',
      align: 'center',
    },
    {
      label: 'Notary at Sale - Gamma',
      key: 'notary_at_sale_gamma',
      align: 'center',
    },
    {
      label: 'Agency fee at Acquisition',
      key: 'agency_fee_at_acquisition',
      align: 'center',
    },
    {
      label: 'Agency fee at Sale',
      key: 'agency_fee_at_sale',
      align: 'center',
    },
    {
      label: 'Other Costs',
      key: 'other_costs',
      align: 'center',
    },
  ],
  'renovation_flag': [
    {
      label: 'Actual Renovation Costs',
      key: 'actual_renovation_costs',
      align: 'center',
    },
    {
      label: 'CAPEX Requested',
      key: 'capex_requested',
      align: 'center',
    },
    {
      label: 'CAPEX Actual + Requested',
      key: 'capex_actual_requested',
      align: 'center',
    },
    {
      label: 'Initial Renovation Budget (Net)',
      key: 'initial_renovation_budget_net',
      align: 'center',
    },
    {
      label: 'DD Value',
      key: 'dd_value',
      align: 'center',
    },
    {
      label: 'Initial CAPEX / SQM',
      key: 'initial_capex_sqm',
      align: 'center',
    },

    {
      label: 'Buyer Requested Costs Amount',
      key: 'buyer_requested_costs_amount',
      align: 'center',
    },
    {
      label: 'Total Renovation Budget',
      key: 'total_renovation_budget',
      align: 'center',
    },
    {
      label: 'Renovation Limit',
      key: 'renovation_limit',
      align: 'center',
    },
    {
      label: 'Extra Financed Costs',
      key: 'extra_financed_costs',
      align: 'center',
    },
    {
      label: 'Final Renovation Budget / DD',
      key: 'final_renovation_budget_dd',
      align: 'center',
    },
    {
      label: 'Renovation Progress',
      key: 'renovation_progress',
      align: 'center',
    },
  ],
  'relevant_dates': [
    {
      label: 'Outstanding Purchase Price Payment Date',
      key: 'outstanding_purchase_price_payment_date',
      align: 'center',
    },
    {
      label: 'Renovation Start Date',
      key: 'renovation_start_date',
      align: 'center',
    },
    {
      label: 'Renovation End Date',
      key: 'renovation_end_date',
      align: 'center',
    },
    {
      label: 'Commercialization Start Date',
      key: 'commercialization_start_date',
      align: 'center',
    },
  ],

  'total': [
    {
      label: 'Current LTC Senior',
      key: 'current_ltc_senior',
      align: 'center',
      render: (value: any) => formatters.percent(value),
    },

    {
      label: 'Current LTC Mezzanine',
      key: 'current_ltc_mezzanine',
      align: 'center',
      render: (value: any) => formatters.percent(value),
    },
    {
      label: 'New Purchase Price',
      key: 'theta_new_purchase_price',
      align: 'center',
    },
    {
      label: 'New Financed Costs',
      key: 'theta_new_other_costs',
      align: 'center',
    },
    {
      label: 'New Financed Amount',
      key: 'theta_new_financed_amount',
      align: 'center',
    },
    {
      label: 'New Other Costs',
      key: 'theta_new_other_costs',
      align: 'center',
    },
    {
      label: 'LTC Senior',
      key: 'current_ltc_senior',
      align: 'center',
      render: (value: any) => formatters.percent(value),
    },
    {
      label: 'LTC Mezzanine',
      key: 'current_ltc_mezzanine',
      align: 'center',
      render: (value: any) => formatters.percent(value),
    },
    {
      label: 'New Senior Borrowing Base',
      key: 'new_senior_borrowing_base',
      align: 'center',
    },
    {
      label: 'New Mezzanine Borrowing Base',
      key: 'new_mezzanine_borrowing_base',
      align: 'center',
    },
    {
      label: 'New Junior Borrowing Base',
      key: 'new_junior_borrowing_base',
      align: 'center',
    },
    {
      label: 'Agency Property Allocated Ledger Amount',
      key: 'total_new_agency_property_allocated_ledger_amount',
      align: 'center',
    },
  ],
};