import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../assets";


const HDRSyncs = () => {

  const description =
    <div style={{textAlign: 'justify'}}>
      The HDR Servicer can import new data type documents (HDR request) into the platform by also choosing
      from the option to run the Data Health Check or not and the respective reference date.
    </div>

  return (
    <Row>
      <Col sm={24}>
        <CustomImage
          description={description}
          alt="Syncs Module"
          src={assets.images.syncsModule.fullView}
        />
      </Col>
    </Row>
  );
};

export default HDRSyncs;
