import React, { useEffect, useState } from 'react';
//Other Libs
import axios from "axios";
//Settings
import { idpBaseUrl } from "../../../settings";
//Helpers
import { assignErrorInterceptor } from "@cardoai/utils"
// Helpers
import { authStorage, client } from "../../../helpers";
import notifications from "../../../components/notifications";
import { clearViewsCache } from "../../hocs/with_data/cache";

const AuthenticationContext = React.createContext({});

const AuthenticationProvider = (props: any) => {
  const [initialized, setInitialized] = useState<any>(null);
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [authenticatedToken, setAuthenticatedToken] = useState<any>(authStorage.getAccessToken());

  useEffect(() => {
    setInitialized(
      assignErrorInterceptor({
        client,
        onSessionEnd: onLogout,
        refreshSession: refreshToken,
        onSessionUpdate: authStorage.setAccessToken,
        notification: notifications,
      })
    )
  }, []);

  const refreshToken = async (): Promise<void> => {
    return await axios.post(idpBaseUrl + "refresh_token/", {
      refresh: authStorage.getRefreshToken()
    })
  }

  const onLogout = (): void => {
    authStorage.clearAll();
    clearViewsCache();
    setAuthenticatedToken(false);
  };

  const onLogin = async (credentials: any): Promise<void> => {
    setAuthenticating(true);

    try {
      const response = await axios.post(idpBaseUrl + 'login/', {
        username: credentials.username,
        password: credentials.password
      })

      const tokens = response.data;
      authStorage.setAccessToken(tokens.access);
      authStorage.setRefreshToken(tokens.refresh);

      setAuthenticatedToken(true);
    } catch (error) {
      notifications.error("Invalid Credentials")
    } finally {
      setAuthenticating(false);
    }
  };

  const contextValue = React.useMemo(
    () => ({
      onLogin,
      onLogout,
      authenticating,
      authenticated: !!authenticatedToken
    }),
    [authenticatedToken, authenticating]
  );

  if (!initialized) return null

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export {
  AuthenticationContext,
  AuthenticationProvider
}

