import React, { useState } from 'react';
//Components
import { Skeleton } from "antd";
//Components
import { SimpleEmpty } from "@cardoai/components";
//Custom Components
import Header from "../header";
import Management from "../management";
//Custom helpers
import { getNested } from "../../../../helpers";
//Constants
const properties = {
  height: 700
};

interface Props {
  data: any,
  redirectionRule?: any,
  redirectionSource?: any,
  extraSpace?: any
}

const Vehicles = (props: Props) => {
  const {data, redirectionRule, redirectionSource, ...rest} = props;

  const [selectedVehicle, setSelectedVehicle] = useState(() => {
    return redirectionRule ? redirectionRule.vehicleId : null
  });

  if (!data)
    return <Skeleton/>;

  if (!data.length)
    return <SimpleEmpty description="No Data"/>;

  const getRules = (vehicleId: any) => {
    return getNested(redirectionRule, "vehicleId") === vehicleId ? redirectionRule : null;
  };

  const createList = () => {
    if (!selectedVehicle)
      return data;

    return [
      data.find((vehicle: any) => vehicle.vehicle_id === selectedVehicle),
      ...data.filter((vehicle: any) => vehicle.vehicle_id !== selectedVehicle)
    ]
  }

  const list = createList();

  if (!list.length)
    return <SimpleEmpty/>

  const singleVehicleView = data.length === 1;

  return (
    <>
      <Header vehicles={data} singleVehicleView={singleVehicleView}/>
      {list.map((record: any) => {
        return (
          <Management
            {...rest}
            key={record.vehicle_id}
            data={record}
            vehicles={data}
            currency={record.currency}
            height={properties.height}
            onVehicleSelect={setSelectedVehicle}
            redirectionSource={redirectionSource}
            singleVehicleView={singleVehicleView}
            redirectionRule={getRules(record.vehicle_id)}
            isLocked={selectedVehicle === record.vehicle_id}
          />
        )
      })}
    </>
  )

};

export default Vehicles;
