//Helpers
import { formatters, Text } from "../../helpers";
import { theme } from "../../assets/theme/colors";

export const chartDefaultPeriodOptions: any = [
  {"label": "1-6"},
  {"label": "7-12"},
  {"label": "13-18"},
  {"label": "19-24"},
  {"label": "25-30"},
  {"label": "31-36"},
  {"label": "37-42"},
  {"label": "43-48"},
  {"label": "49-54"},
  {"label": "55-60"},
  {"label": "61-66"},
  {"label": "67-72"},
  {"label": "73-78"},
  {"label": "79-84"},
  {"label": "85-90"}
]

export const FIELD: any = {
  name: 'name',
  group: 'group',
  prepaymentFee: 'prepayment_fee',
  applyCapital: 'delinquencies_on_capital',
  applyInterest: 'delinquencies_on_interest',
  servicingFee: 'servicing_fee',
  daysDelinquent: 'days_delinquent',
  interestRateCurve: "interest_rate_curve"
};

export const FORM_FIELD: any = {
  "cdr": "cdr",
  "cpr": "cpr",
  "delinquency": "delinquency",
  "recovery_lag": "recovery_lag",
  "recovery_rate": "recovery_rate",
  "interest_rate_curve": "interest_rate_curve"
}

export const statisticsFields: any = [
  {
    key: "reference_date",
    format: formatters.date,
    label: Text("Last \n Reporting Date"),
  },
  {
    key: "current_loan_balance",
    label: Text("Current\nBalance"),
    format: formatters.thousands
  },
  {
    key: "loans_outstanding",
    label: Text("Loans\nOutstanding #"),
    format: formatters.thousandsShorted
  },
  {
    key: "avg_ticket_size",
    label: Text("Average \nLoan Size"),
    format: formatters.thousands
  },
  {
    key: "gwar",
    label: Text("WAR\n(%)"),
    format: formatters.percent
  },
  {
    key: "wam",
    label: Text("WAM\n(years)"),
    format: formatters.years
  },
  {
    key: "average_delay",
    label: Text("Average\n Delay"),
    format: formatters.days
  },
  {
    key: "remaining_term",
    format: formatters.thousands,
    label: Text("Remaining \n Terms"),
  },
  {
    key: "average_delay_vs_original_maturity",
    label: Text("Avg Delay vs\nOriginal Maturity"),
    format: formatters.days
  },
  {
    key: "delinquency_ratio",
    label: Text("Delinquency\nRatio"),
    format: formatters.percent
  },
  {
    key: "gross_default_ratio",
    label: Text("Gross\nDefault Ratio"),
    format: formatters.percent
  },
  {
    key: "conditional_default_ratio",
    label: Text("Conditional\nDefault Ratio"),
    format: formatters.percent
  },
  {
    key: "prepayment_ratio",
    label: Text("3m \nPrepayments Ratio"),
    format: formatters.percent
  },
  {
    key: "conditional_prepayment_ratio",
    format: formatters.percent,
    label: Text("Conditional \n Prepayment ratio"),
  },
  {
    key: "ltv",
    label: Text("LTV"),
    format: formatters.percent
  },
];

const GROUP: any = {
  "General": " ",
  "CapitalInstalment": "Capital Instalment",
  "Losses": "Losses",
  "OtherFlows": "Other Flows",
  "Custom": "  "
}

const styles: any = {
  background: theme.palette.grayscale[3],
  borderColor: theme.colors.white,
  color: 'rgba(0, 0, 0, 0.65)'
}

export const tableFieldsGroups: any = [
  {
    key: GROUP.General,
    styles: styles
  },
  {
    key: GROUP.CapitalInstalment,
    styles: styles
  },
  {
    key: GROUP.Losses,
    styles: styles
  },
  {
    key: GROUP.OtherFlows,
    styles: styles
  },
  {
    key: GROUP.Custom,
    styles: styles
  }
]

const defaultTableFields = [
  {
    key: "name",
    label: "",
    group: GROUP.General
  },
  {
    group: GROUP.General,
    key: "initial_balance",
    label: "Initial Balance",
    format: formatters.thousands
  },
  {
    group: GROUP.General,
    key: "total_terms",
    label: "Total Terms",
    format: formatters.thousands
  },
  {
    key: "wal",
    label: "WAL",
    group: GROUP.General,
    format: formatters.thousands
  },
  {
    key: "scheduled",
    group: GROUP.CapitalInstalment,
    label: "Scheduled",
    format: formatters.thousands
  },
  {
    key: "prepaid",
    group: GROUP.CapitalInstalment,
    label: "Prepaid",
    format: formatters.thousands
  },
  {
    group: GROUP.CapitalInstalment,
    key: "capital_instalment_total",
    label: "Total",
    format: formatters.thousands
  },
  {
    key: "gross_loss",
    group: GROUP.Losses,
    label: "Gross",
    format: formatters.thousands
  },
  {
    key: "gross_loss_avg",
    group: GROUP.Losses,
    label: "Gross (%)",
    format: formatters.percent
  },
  {
    key: "recoveries",
    label: "Recoveries",
    group: GROUP.Losses,
    format: formatters.thousands
  },
  {
    key: "net_losses",
    label: "Net Losses",
    group: GROUP.Losses,
    format: formatters.percent
  },
  {
    key: "total_interest",
    group: GROUP.OtherFlows,
    label: "Interests",
    format: formatters.thousands
  },
  {
    key: "prepayment_fees",
    group: GROUP.OtherFlows,
    label: "Prepayment fees",
    format: formatters.thousands
  },
  {
    key: "servicing_fees",
    group: GROUP.OtherFlows,
    label: "Servicing fees",
    format: formatters.thousands
  },
  {
    group: GROUP.Custom,
    key: "total_collections",
    label: "Total Collections",
    format: formatters.thousands
  },
  {
    group: GROUP.Custom,
    key: "contractual_rate",
    label: "% Contractual",
    format: formatters.percent
  },
]

const labels: any = {
  "scheduled": {
    demo: "Business Plan",
    default: "Scheduled"
  },
  "contractual_rate": {
    demo: "% Business Plan",
    default: "% Contractual"
  }
}

export const tableFields = (isDemoUser: boolean) => {

  const hiddenKeys = ["wal", "prepaid", "recoveries", "net_losses", "prepayment_fees"]
  const renameLabels = ["scheduled", "contractual_rate"]

  return defaultTableFields.filter(field => (!hiddenKeys.includes(field.key))).map(column => {
    if (renameLabels.includes(column.key))
      return {...column, label: isDemoUser ? labels[column.key].demo : labels[column.key].default}
    else
      return column;
  })

}

export const detailedTable: any = [
  {key: "date", label: "Date", format: formatters.shortedDate},
  {key: "period", label: "Period", format: formatters.default},
  {key: "performing_balance", label: "Performing Balance", format: formatters.thousands},
  {key: "collections_principal", label: "Collection Principal", format: formatters.thousands},
  {key: "interest", label: "Interest", format: formatters.thousands},
  {key: "recoveries", label: "Recoveries", format: formatters.thousands},
  {key: "total_cash_flows", label: "Total Cash Flow", format: formatters.thousands},
  {key: "scheduled_principal", label: "Scheduled Principal", format: formatters.thousands},
  {key: "prepayment", label: "Prepayment", format: formatters.thousands},
  {key: "default", label: "Default", format: formatters.thousands},
  {key: "loss", label: "Loss", format: formatters.thousands},
  {key: "gross_return", label: "Gross Return", format: formatters.percent},
]

export function emptyFieldList() {
  const keys: any = {};

  for (let key in FORM_FIELD)
    if (FORM_FIELD.hasOwnProperty(key))
      keys[key] = null;

  return keys;
}

export function emptyGroupRecord(label: string) {
  return {
    label: label,
    field_list: emptyFieldList()
  }
}

export default {
  statisticsFields,
  tableFields,
  detailedTable
}