import React from 'react';
//Other Libs
import clone from 'clone';
import moment from "moment";
//Components
import { Col, Row } from "antd";
import { SimpleEmpty } from "@cardoai/components";
import { SmartTable } from "../../../../../components";
//Configurations
import { AMORTIZATION_PLAN_DATASETS as DATASETS, amortizationPlan, amortizationPlanRealized } from "./config";
//Helpers
import { formatDate } from "../../../../../helpers";

interface IAmortizationPlanTable {
  title: any,
  records: any,
  columns: any,
  poolAdditionDate: any
}

interface IAmortizationPlan {
  data: any,
  poolAdditionDate: any,
  visible: any,
  currency?: any
}

//Helper functions
function shouldColorCell(date: any, poolAdditionDate: any, key: any) {
  /**
   * tThis function decided if the type of payment registered for a loan has happened before the loan was added to the
   * pool of investment, and if so, it will color the cell of the table so it will be distinguished
   *
   * @params: date -> date of the payment coming in for the loan
   * @params: poolAdditionDate -> date loan was added in the investment pool
   * @params: key -> the type of payment
   */

  let invalidCashFlow = false;
  const clonedDate = clone(date)
  const formattedPoolAdditionDate = moment(poolAdditionDate);

  if (moment(clonedDate).isBefore(formattedPoolAdditionDate) && key !== "funding")
    invalidCashFlow = true;

  return invalidCashFlow;
}

const AmortizationPlanTable = (props: IAmortizationPlanTable) => {
  const {title, records, columns, poolAdditionDate} = props;

  const data: any = [];

  Object.keys(records).forEach(date => {
    const dataset = records[date];
    dataset.forEach((cashFlow: any) => {
      const cashFlowType = cashFlow.cash_flow_type;
      const key = DATASETS[cashFlowType]
      data.push({
        date: formatDate(date),
        [key]: cashFlow.amount,
        cellBackground: shouldColorCell(date, poolAdditionDate, key) ? "#fffb8f" : null
      })
    })
  })

  return (
    <>
      <h3>{title}</h3>
      <SmartTable
        height={350}
        columns={columns}
        externalData={data}
      />
    </>
  )
}

const AmortizationPlan = (props: IAmortizationPlan) => {
  const {data, poolAdditionDate, visible, currency} = props;

  if (!visible)
    return <SimpleEmpty description="No Amortization Plan Available Yet"/>


  return (
    <Row gutter={8}>
      <Col xs={24} lg={12}>
        <AmortizationPlanTable
          records={data.expected}
          columns={amortizationPlan(currency)}
          title="Expected Installments"
          poolAdditionDate={poolAdditionDate}/>
      </Col>
      <Col xs={24} lg={12}>
        <AmortizationPlanTable
          records={data.realized}
          title="Realized Installments"
          columns={amortizationPlanRealized(currency)}
          poolAdditionDate={poolAdditionDate}/>
      </Col>
    </Row>
  );
};

export default AmortizationPlan;
