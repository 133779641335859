//charts
import { Bar, Bubble, Doughnut, Line, Pie, PolarArea, Radar, Scatter } from "react-chartjs-2";
//CardoAI Constants
import { theme } from "../../../../../../assets/theme/colors";

export const defaultProperties: any = {
  displayLegend: true,
  legendPosition: 'bottom',
  fixedHeight: false,
  format: 'default',
  stacked: false,
  beginAtZero: false,
  displayTooltips: true,
  displayXTicks: true,
  displayYTicks: true,
  displayXLines: true,
  displayYLines: true,
  xLabelFontSize: 11,
  xAxisLabel: null,
  xAxisPosition: 'bottom',
  yAxisPosition: 'left',
  yAxisLabel: null,
  displayXBorder: true,
  displayYBorder: true,
  displayLabels: false,
  tooltipMultiple: false,
  dataSetStack: false,
  labelConf: {},
  labelCallback: null,
  extra: null,
  /*This will change the default extra content on top right of chart.*/
  extraStyle: {},
  /*This will change the layout style for element on top right of chart.*/
  lineTension: 0.1,
  limits: {
    x: {},
    y: {}
  },
  formatXAxis: true,
  /*This will disable formatting in case of X axis.*/
  showAxisLabels: true,
  /*This will make axis  labels visible.*/
  tipFormatXAxis: null,
  /*This will format the x axis on hover.*/
  customTip: null,
  disableLegend: false,
  tooltipIndexMode: false,
  allowSnapshot: false,
  allowDistribution: false,
  allowExcelDownload: false,
  allowFullscreen: false,
  draggable: false,
  hideDots: false,
  initialDistribution: 'percentage',
  showEmptyPlaceholder: false
}


export const charts: any = {
  'bar': Bar,
  // 'horizontalBar': HorizontalBar,
  'doughnut': Doughnut,
  'line': Line,
  'pie': Pie,
  'polar': PolarArea,
  'radar': Radar,
  'scatter': Scatter,
  'bubble': Bubble,
};
export const defaultMultiSetData: any = {
  fill: false,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBackgroundColor: theme.colors.white,
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};
export const multipleDataSets: any = ['bar', 'horizontalBar', 'line', 'radar'];
export const singleDataSets: any = ['doughnut', 'pie', 'scatter', 'polar', 'bubble'];
