import React from 'react';
//Components
import { SimpleEmpty } from "@cardoai/components";
//Custom Components
import UploadFilesFields from "./upload_files_fields";
import OtherFields from "./other_fields";
import UploadFooter from "./upload_footer";
import UploadLoader from "../../../../../components/upload_files/upload_loader";
//Styles
import Styles from "./styles";
//Helpers
import { getFormDatesFormatters, prepareForm } from "./helpers";
import { DEFAULT_MODAL_WIDTH, SYNC_TYPES } from "../../configurations";
import { callApi, formatServerDate, generateUrl, getUrl, MathOp } from "../../../../../helpers";
import { notifications } from "../../../../../components";


interface IUserInputFields {
  settings?: any,
  type?: string,
  vehicle?: any,
  vehicleId?: any,
  syncTypeParam?: string,
  urlIdentifier?: string,
  makeRequest?: any,

  handleUnmount?: any,
  allowModalClose?: any,
  defaultDatePicker?: any,
  changeModalTitle?: any,
  handleCloseModal?: any,
  setWidthAsContent?: any,
  changeModalWidth?: any,
}

const UserInputFields = (props: IUserInputFields) => {

  const {
    settings, type, vehicle, vehicleId, urlIdentifier, makeRequest,
    allowModalClose, defaultDatePicker, changeModalTitle, handleCloseModal, setWidthAsContent,
    changeModalWidth
  } = props;

  const fields = settings.options;
  const initialForm = prepareForm(fields, settings);
  const [form, setForm] = React.useState<any>(initialForm);
  const [percent, setPercent] = React.useState(0)
  const [modified, setModified] = React.useState(true);
  const [loading, setLoading] = React.useState(false);


  const isCasavoVehicle = vehicle.identifier.includes("casavo");
  const loanProposalCase = type === SYNC_TYPES.new_loan_proposal;
  const paymentReportCase = type === SYNC_TYPES.payment_report;
  const {referenceDatePicker, reconciliationDatePicker} = getFormDatesFormatters(settings, defaultDatePicker);

  const onChange = (updates: any) => {
    setModified(true);
    setForm((previousForm: any) => ({
      ...previousForm,
      ...updates
    }))
  };

  const onCheckboxChange = (attr: any) => (event: any) => {
    onChange({[attr]: event.target.checked})
  };

  const handleFileChange = (field: any) => (event: any) => {
    onChange({[field]: event})
  };

  const onStartUploading = () => {
    allowModalClose(false);
    setWidthAsContent()
    setPercent(0);
    setLoading(true);
  };

  function prepareReferenceDate() {
    if (!form.reference_date)
      return null;

    let finalDate = form.reference_date
    if (referenceDatePicker === "month")
      finalDate = finalDate.clone().endOf("month"); //for reference date always send the end of the month

    return formatServerDate(finalDate);
  }

  const handleUpload = () => {
    onStartUploading();

    const body = new FormData();

    const params = {
      vehicle_id: vehicleId
    }

    fields.forEach((field: any) => body.append(field.key, form[field.key]));
    body.append('vehicle', vehicleId);
    body.append('perform_dhc', form.perform_dhc);
    body.append('reference_date', prepareReferenceDate());
    body.append('originator', settings.originator_identifier);

    if (loanProposalCase && !isCasavoVehicle) {
      body.append('currency', form.currency);
      body.append('is_interest_payment', form.is_interest_payment);
      body.append("current_recon_period_end", formatServerDate(form.current_recon_period_end));
    }

    if (loanProposalCase && isCasavoVehicle) {
      body.append("facility_report_date", formatServerDate(form.facility_report_date))
    }

    const url = generateUrl(`/main/sync_request/${urlIdentifier}`, params);
    callApi({
      url: getUrl(url),
      method: 'POST',
      body: body,
      onError: (error: any) => {
        notifications.error(error?.data?.detail);
        allowModalClose(true);
      },
      onSuccess: () => {
        makeRequest();
        handleCloseModal();
      },
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.total;
        if (totalLength !== null) {
          const percentageUploaded: any = MathOp.divide(progressEvent.loaded, progressEvent.total) * 100;
          setPercent(Math.round(percentageUploaded));
        }
      },
      onFinish: () => {
        setLoading(false);
        changeModalTitle("Import Files");
        changeModalWidth(DEFAULT_MODAL_WIDTH)
      }
    })
  };

  const onUpload = () => {
    if (modified) handleUpload();
  };

  if (loading)
    return <UploadLoader percent={percent}/>

  if (!fields || !fields[0])
    return <SimpleEmpty description="No Upload Available"/>


  return (
    <Styles enctype="multipart/form-data" loading={loading}>
      <UploadFilesFields form={form} fields={fields} onFileChange={handleFileChange}/>
      <OtherFields form={form} settings={settings} isCasavoVehicle={isCasavoVehicle}
                   loanProposalCase={loanProposalCase} syncType={type}
                   onChange={onChange} datePickers={{
        referenceDatePicker,
        reconciliationDatePicker
      }}/>
      <UploadFooter form={form} loading={loading} percent={percent} loanProposalCase={loanProposalCase}
                    onCheckboxChange={onCheckboxChange} isCasavoVehicle={isCasavoVehicle}
                    paymentReportCase={paymentReportCase} onUpload={onUpload}/>
    </Styles>
  );
}

export default UserInputFields;
