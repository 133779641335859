import React, { useState } from 'react';
//Other Libs
import moment from "moment";
import produce from "immer";
//Components
import { Button, Col, DatePicker, Input, Modal, Popconfirm, Popover, Row, Skeleton, Spin, Switch, Tag } from "antd";
import { CustomTable, FieldSet, FlatList, MultiColorProgressBar, SimpleEmpty } from "@cardoai/components";
//Constants
import config from "./config";
import { theme } from "../../../../../assets/theme/colors";
//Helpers
import { callApi, useQuery } from "../../api";
import { authStorage, formatServerDate, MathOp } from "../../../../../helpers";
//Icons
import { CheckOutlined, DeleteOutlined, FileSearchOutlined, PlusOutlined } from "@ant-design/icons";


interface DayCircleProps {
  size?: any,
  children?: any,
  color?: string
}

interface CreateDocumentProps {
  data: any,
  getData: () => any,
  closeCreate: () => any
}

interface DocumentProps {
  data: any,
  loading: any,
  getData: () => any
}

interface DetailsContentProps {
  data: any,
  onUpdate: () => any
}

interface DetailsProps {
  record: any,
  onUpdate: () => any
}

const editableFields = [
  {
    key: 'wip_date',
    date: true
  },
  {
    key: 'pending_date',
    date: true
  },
  {
    key: 'completed_date',
    date: true
  },
  {
    key: 'document_type',
    date: false
  },
  {
    key: 'required',
    date: false
  }
]

const createMomentDate = (date: any) => {
  if (!date)
    return null;

  if (!(date instanceof moment))
    return moment(date)

  return date;
}

const getDifferenceInDays = (a: any, b: any) => {
  if (!a || !b)
    return null;
  return Math.abs(a.diff(b, 'days'))
}

const createState = (data: any) => {
  return produce(data, (draft: any) => {
    for (let record of draft) {
      for (let field of editableFields) {
        const key = field.key;
        const value = record[key];
        record[key] = field.date ? createMomentDate(value) : value
      }
    }
  })
}

const DayCircle = ({size, children, color}: DayCircleProps) => {
  return (
    <div style={{
      width: size,
      height: size,
      color: color,
      fontSize: 12,
      display: 'flex',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: `0px 0px 0px 4px ${color}`
    }}>
      {children || 0}
    </div>
  )
}

DayCircle.defaultProps = {
  size: 20,
  color: theme.colors.primary
}

const CreateDocument = ({data, getData, closeCreate}: CreateDocumentProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [required, setRequired] = React.useState<boolean>(false);
  const [document, setDocument] = React.useState<any>(null);

  const onChange = (e: any) => {
    setDocument(e.target.value);
  }

  const onToggle = () => {
    setRequired((r: any) => !r);
  }

  const onSave = () => {
    setLoading(true);
    callApi({
      method: 'POST',
      url: `/entity/${data.entity}/document_collection/?role=${authStorage.getRole()}`,
      body: {
        loan: data.id,
        required: required,
        document_type: document
      },
      onSuccess: () => {
        getData();
        closeCreate();
      },
      onFinish: () => {
        setLoading(false);
      }
    })
  }

  return (
    <Row>
      <Col xs={24}>
        <FieldSet value={document} onChange={onChange} size='small' label="Document Type" component={Input}/>
      </Col>
      <Col xs={24} className="mt8">
        <Row align="middle" justify="space-between">
          <Col>
            <Switch
              defaultChecked
              checked={required}
              onChange={onToggle}
              checkedChildren="Required"
              unCheckedChildren="Required"/>
          </Col>
          <Col>
            <Button loading={loading} onClick={onSave} size='small' type="primary">
              {loading ? "Saving document" : "Save document"}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};


const Documents = ({data, loading, getData}: DocumentProps) => {

  const editable = data.status !== "Completed";
  const [canCreate, setCanCreate] = React.useState<any>(false);
  const [state, setState] = React.useState<any>(() => createState(data.documents_collections));

  React.useEffect(() => {
    const initialState = createState(data.documents_collections);
    setState(initialState);
  }, [data])

  const onCreate = () => {
    setCanCreate((c: any) => !c);
  };

  const onChange = (index: any, attr: string) => (event: any) => {
    setState((previousState: any) => {
      return produce(previousState, (draft: any) => {
        if (attr === "document_type")
          event = event.target.value
        draft[index][attr] = event
      })
    })
  };

  const onRemove = (id: any) => () => {
    callApi({
      method: 'DELETE',
      url: `/entity/${data.entity}/document_collection/${id}/?role=${authStorage.getRole()}`,
      onSuccess: () => {
        getData();
      }
    })
  }

  const onOpenCollectionProcess = () => {
    callApi({
      method: "POST",
      url: `/entity/${data.entity}/loans/${data.id}/re_open_collection_process/?role=${authStorage.getRole()}`,
      onSuccess: () => {
        getData();
      }
    })
  }

  const onComplete = async () => {
    callApi({
      method: "POST",
      url: `/entity/${data.entity}/loans/${data.id}/complete_collection/?role=${authStorage.getRole()}`,
      onSuccess: () => {
        getData();
      }
    })
  }

  const onSave = async () => {

    const edited: any = {};
    const previousDocuments = data.documents_collections;

    for (let document of previousDocuments) {
      const editableDocument = state.find((d: any) => d.id === document.id);
      let changed = false;
      for (let field of editableFields) {
        const key = field.key;

        const current = field.date ? createMomentDate(editableDocument[key]) : editableDocument[key];
        const previous = field.date ? createMomentDate(previousDocuments[key]) : previousDocuments[key];

        if (field.date) {
          if (!current)
            continue;

          if (!current.isSame(previous)) {
            changed = true;
            break;
          }
        } else {
          if (current !== previous) {
            changed = true;
            break;
          }
        }


      }

      if (changed) {
        const values: any = {
          document_type: editableDocument.document_type
        };

        editableFields.forEach(field => {
          const value = editableDocument[field.key];
          if (field.date) {
            values[field.key] = formatServerDate(value)
          } else {
            values[field.key] = value
          }
        })

        edited[document.id] = values;
      }
    }


    const records = Object.keys(edited).map(id => {
      return {
        url: `/entity/${data.entity}/document_collection/${id}/?role=${authStorage.getRole()}`,
        payload: edited[id]
      }
    })

    if (!records.length)
      return;

    const success = await Promise.all(records.map(record => callApi({
      url: record.url,
      method: 'PUT',
      body: record.payload
    })))

    if (success)
      getData();
  }

  const disableFutureDates = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  const computeProgress = () => {
    const documents: any = data.documents_collections;

    const completed: any = documents.filter((doc: any) => doc.status === "Completed").length;

    // @ts-ignore
    const acquired: any = MathOp.divide(completed, data.documents_collections.length) * 100;

    const toBeAcquired = 100 - acquired;

    return [
      {
        color: "#87d068",
        name: "Collected",
        value: acquired,
        textValue: acquired ? acquired.toFixed(2) : null,
      },
      {
        color: "#108ee9",
        value: toBeAcquired,
        name: "To Be Collected",
        textValue: toBeAcquired ? toBeAcquired.toFixed(2) : null,
      }
    ]
  }

  const contentStyle: any = {};

  if (!editable)
    contentStyle['pointer-events'] = 'none';

  return (
    <section style={contentStyle}>
      <Spin spinning={loading}>
        <Row justify="space-between" align="bottom">
          {data.documents_collections.length ? (
            <Col xs={8}>
              <MultiColorProgressBar
                config={computeProgress()}/>
            </Col>
          ) : null}
          {editable && (
            <Col>
              <Popover
                trigger="click"
                visible={canCreate}
                title="Insert Document"
                onVisibleChange={onCreate}
                content={
                  <CreateDocument
                    data={data}
                    getData={getData}
                    closeCreate={onCreate}/>
                }
              >
                <Button
                  size='small'
                  type="primary"
                  icon={<PlusOutlined/>}>
                  Insert Document
                </Button>
              </Popover>
            </Col>
          )}
        </Row>
        {state.length ? (
          <CustomTable centerData={true} className="mt16 mb8">
            <thead>
            <tr>
              <th>ID</th>
              <th>Document Type</th>
              <th>Pending Date</th>
              <th>Days</th>
              <th>Wip Date</th>
              <th>Days</th>
              <th>Completed Date</th>
              <th>Status</th>
              <th>Required</th>
              {editable && <th/>}
            </tr>
            </thead>
            <tbody>
            {state.map((record: any, index: any) => {
              return (
                <tr>
                  <td>
                    {record.id}
                  </td>
                  <td>
                    <Input
                      value={record.document_type}
                      onChange={onChange(index, 'document_type')}/>
                  </td>
                  <td>
                    <DatePicker
                      value={record.pending_date}
                      disabledDate={disableFutureDates}
                      onChange={onChange(index, 'pending_date')}/>
                  </td>
                  <td>
                    <DayCircle>
                      {getDifferenceInDays(record.wip_date, record.pending_date)}
                    </DayCircle>
                  </td>
                  <td>
                    <DatePicker
                      value={record.wip_date}
                      disabledDate={disableFutureDates}
                      onChange={onChange(index, 'wip_date')}/>
                  </td>
                  <td>
                    <DayCircle>
                      {getDifferenceInDays(record.completed_date, record.wip_date)}
                    </DayCircle>
                  </td>
                  <td>
                    <DatePicker
                      value={record.completed_date}
                      disabledDate={disableFutureDates}
                      onChange={onChange(index, 'completed_date')}/>
                  </td>
                  <td>
                    <Tag>
                      {record.status.toUpperCase()}
                    </Tag>
                  </td>
                  <td>
                    <Switch
                      checked={record.required}
                      onChange={onChange(index, 'required')}
                    />
                  </td>
                  {editable && (
                    <td>
                      <Popconfirm
                        okText="Yes"
                        cancelText="No"
                        onConfirm={onRemove(record.id)}
                        title="Are you sure to delete this document?">
                        <Button danger icon={<DeleteOutlined/>}/>
                      </Popconfirm>
                    </td>
                  )}
                </tr>
              )
            })}
            </tbody>
          </CustomTable>
        ) : <SimpleEmpty/>}
        <Row gutter={8} justify="end" className="mt8">
          {editable && (
            <>
              <Col>
                <Button
                  shape="round"
                  type="primary"
                  onClick={onSave}>
                  Save Changes
                </Button>
              </Col>
              {data.status === "Ready To Be Completed" && (
                <Col>
                  <Button
                    shape="round"
                    type="primary"
                    onClick={onComplete}
                    icon={<CheckOutlined/>}>
                    Complete
                  </Button>
                </Col>
              )}
            </>
          )}
          {data.status === "Completed" && (
            <Col style={{pointerEvents: 'auto'}}>
              <Button
                shape="round"
                type="primary"
                onClick={onOpenCollectionProcess}>
                Reopen Collection Process
              </Button>
            </Col>
          )}
        </Row>
      </Spin>
    </section>
  )
}

const DetailsContent = ({data, onUpdate}: DetailsContentProps) => {

  const loan = useQuery({
    url: `/entity/${data.entity}/loans/${data.id}/?role=${authStorage.getRole()}`
  })

  // @ts-ignore
  React.useEffect(async () => {
    await loan.fetch();
  }, [data])

  return (
    <div>
      <FlatList
        data={data}
        config={config}
        elementWidth={150}
      />
      <br/>
      {!loan.data ?
        <Skeleton active/> :
        <Documents
          data={loan.data}
          getData={onUpdate}
          loading={loan.loading}
        />}
    </div>
  )
}


const Details = ({record, onUpdate}: DetailsProps) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Row>
      <Col>
        <Button type="default" onClick={showModal} icon={<FileSearchOutlined/>}>
          Details
        </Button>
          <Modal
            centered
            width={1200}
            footer={null}
            closable={false}
            visible={visible}
            onCancel={handleCancel}>
            <DetailsContent
              data={record}
              onUpdate={onUpdate}/>
          </Modal>
      </Col>
    </Row>
  );
};

export default Details;

