import React from "react";
//Components
import { Col, Row } from "antd";
//Constants
import { theme } from "../../../../../assets/theme/colors";

const textStyle = {
  fontSize: 14,
  fontWeight: 500,
  color: theme.colors.darkgrey,
};

interface Props {
  value?: any,
  formatter?: any,
  label?: any,
  Component?: any,
  componentConfig?: any
}

const RowInformation = (props: Props) => {
  const {value, formatter, label, Component, componentConfig} = props;

  if (Component)
    return <Component value={value} label={label} {...componentConfig}/>

  return (
    <Row gutter={8} style={textStyle} className="mb8">
      <Col>
        {label}:
      </Col>
      <Col>
        {formatter ? formatter(value) : value}
      </Col>
    </Row>
  )
};


export default RowInformation;