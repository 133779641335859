//Components
import Title from "./components/title";
import { useToggle } from "@cardoai/utils";
import StatusSign from "../../../../../design/status_sign";
import { formatLimits } from "../../../../../../spv/components/management/notes_section/limits/helpers";


const Subgroup = ({record}: any) => {
  const [visible, toggle] = useToggle(false);

  if (record.showSubgroup)
    return record.subgroup
  return (
    <div
      onMouseEnter={toggle}
      onMouseLeave={toggle}
      style={{
        height: 24,
        minWidth: '100%'
      }}
      children={visible ? record.subgroup : null}/>
  )
}

const columns = [
  {
    width: 250,
    key: 'subgroup',
    label: 'Subgroups',
    render: (_: any, record: any) => <Subgroup record={record}/>
  },
  {
    width: 520,
    key: 'name',
    label: 'Limit',
    // render: (value: any) => <Title value={value}/>
  },
  {
    width: 50,
    key: 'type',
    label: 'Type'
  },
  {
    width: 100,
    key: 'threshold',
    label: 'Threshold',
    render: (value: any, record: any) => formatLimits(value, record.formatter)
  },
  {
    width: 100,
    key: 'calculated',
    label: 'Calculated',
    render: (value: any, record: any) => formatLimits(value, record.formatter)
  },
  {
    width: 50,
    key: 'broken',
    label: 'Status',
    render: (value: any) => <StatusSign action={value ? "danger" : "success"}/>
  },
  {
    width: 100,
    key: 'consequence',
    label: 'Consequence'
  },
];


export {
  columns
}