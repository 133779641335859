import React from "react";
//Custom Components
import Filter from "./filter";

interface Props {
  filterEntries?: any,
  toggleFilter?: any,
  filtersState?: any,
  onMultipleSelectChange: (param?: any) => any,
  onSingleSelectChange: (param?: any) => any,
  onOperatorValuesChange: (param?: any) => any,
  onValueChange?: any,
  hiddenFields?: any,
  ratings?: any,
  searchOptions?: any,
  onSearchOptionsChange: (param?: any) => any,
  options?: any
  filterOptions?: any
}

const FiltersList = (props: Props | any) => {
  const {
    filterEntries, toggleFilter, filtersState, onMultipleSelectChange, onSingleSelectChange, onOperatorValuesChange,
    onValueChange, hiddenFields, ratings, searchOptions, onSearchOptionsChange, oneVehicleSelected, filterOptions
  } = props;

  return filterEntries.map((filter: any) => {
    const {label, attr, ...config} = filter;

    /*Hide filters that are not allowed dynamically*/

    let visible = true;

    if (attr === "region")
      visible = filtersState.country.value.length;
    else if (attr === "city")
      visible = filtersState.country.value.length && filtersState.region.value.length;

    if (!visible)
      return null;

    /*Hide list of predefined hidden fields*/

    if (hiddenFields.includes(attr))
      return null;

    if (config.hasOwnProperty("options")) {
      if (filterOptions)
        config.options = filterOptions[config.options];
      if (typeof config.options === "string")
        config.options = props[config.options];
    }

    let onUpdate;
    let onSearch

    if (config.type === "checkbox") {
      if (config.mode === "single")
        onUpdate = onSingleSelectChange(attr);
      else
        onUpdate = onMultipleSelectChange(attr);
    } else if (config.type === "operator") {
      onUpdate = onOperatorValuesChange(attr);
    } else {
      onUpdate = onValueChange(attr)
    }

    if (config.type === "selectWithSearch")
      onSearch = onSearchOptionsChange(attr);

    return (
      <Filter
        key={attr}
        attr={attr}
        label={label}
        config={config}
        ratings={ratings}
        onUpdate={onUpdate}
        handleToggle={toggleFilter}
        onSearch={onSearch}
        searchOptions={searchOptions}
        valueConfig={filtersState[attr]}
        oneVehicleSelected={oneVehicleSelected}/>
    )
  });
};

export default FiltersList;
