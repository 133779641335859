import React from 'react';
//Components
import { Alert } from "antd";

interface IMessageBox {
  type?: any,
  title?: any,
  description?: any,
  showIcon?: any,
  icon?: any
}

const MessageBox = (props: IMessageBox) => {
  const {type, title, description, showIcon, icon} = props;
  return (
    <Alert
      type={type}
      message={title}
      icon={icon}
      showIcon={showIcon}
      description={description}
    />
  );
};
MessageBox.defaultProps = {
  type: "info",
  title: "",
  description: "",
  showIcon: true,
};

export default MessageBox;
