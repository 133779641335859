import moment from "moment";
import settings from "../settings";
import { endOfMonth, startOfMonth } from "@cardoai/helpers";

export const getUrl = (url: string) => {
  return settings.apiUrl + url;
};

const now = moment();

export const getRangePeriod = (interval?: any, unit?: any, fullRange: any = true, referenceDate?: any) => {
  const startDate = referenceDate ? moment(referenceDate) : moment();
  const difference = startDate.subtract(interval, unit);
  if (fullRange) {
    const firstSelected = startOfMonth(difference, false)
    const secondSelected = endOfMonth(now, false)
    return [firstSelected, secondSelected]
  } else
    return [difference, now];
};

export const getVehicleCurrencyById = (vehicles: any, vehicleId: any, idAttr = "vehicle_id") => {
  const findVehicleById = vehicles.find((vehicle: any) => vehicle[idAttr] == vehicleId)
  if (findVehicleById)
    return findVehicleById.base_currency || findVehicleById.currency;
}