import React from 'react';
//Components
import { Card, Skeleton, Tabs } from "antd";
import Documents from "../components/documents";
//Configurations
import { config, DOCUMENT_IDENTIFIER } from "../config";
//Utils
import { useGlobalApi } from "../../../utils/hooks";

//Custom Components
const TabPane = Tabs.TabPane;

const HDRDocuments = () => {
  const {vehicles} = useGlobalApi();

  if (!vehicles)
    return <Skeleton/>

  return (
    <Tabs>
      {vehicles.map((vehicle: any) => {
        const vehicleName = vehicle.name;
        const vehicleId = vehicle.id;
        return (
          <TabPane
            key={vehicleId}
            tab={vehicleName}>
            <Card size='small' title="Other Documents" bordered={false}>
              <Documents
                vehicle={vehicle}
                vehicleId={vehicleId}
                title="Import Other Document"
                config={config[DOCUMENT_IDENTIFIER.otherDocuments]}
                documentIdentifier={DOCUMENT_IDENTIFIER.otherDocuments}
              />
            </Card>
          </TabPane>
        )
      })}
    </Tabs>
  );
};

export default HDRDocuments;