//Styled Components
import styled from "styled-components";
//Components
import { Card } from "antd";
//Constants
import { theme } from "../../../../../assets/theme/colors";

export const GroupCard = styled(Card)`
  .ant-card-head {
    min-height: 36px;
    min-width: 100%;
    font-size: 14px;
    padding: 0px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: ${theme.colors.darkwhite};
  }

  .ant-card-head-wrapper {
    min-width: 100%;
    min-height: 100%;
  }

  .ant-card-head-title {
    min-width: 100%;
    min-height: 100%;

  }

`
