import React from "react";

export default [
  {
    date: 'March 23rd, 2021',
    list: [
      <div>
        <span className="bold">Smart Tables (Loans Management): </span>
        <span>Allows the user to create a tailor-made view in Loans Management.</span>
      </div>,
      <div>
        <span className="bold">Enriched Loans Management View: </span>
        <span>New data including SAE code, ATECO Category, information
                    on Arrears and Realized amounts, modification, etc.</span>
      </div>,
      <div>
        <span className="bold">Upgraded Document Management Module: </span>
        <span>Allows the user to upload multiple files at the same time also through drag & drop.</span>
      </div>,
      <div>
        <span className="bold">Help Module: </span>
        <span>Including the explanation of the main functions available and a glossary of terminology.</span>
      </div>,
      <div>
        <span className="bold">Data Health Check: </span>
        <span>Supporting the synchronization of new data tapes granting a smoother synchronization (available for servicer profiles only).</span>
      </div>,
      <div>
        <span className="bold">New Download Feature: </span>
        <span>Provides faster and seamless files generation</span>
      </div>,
      <div>
        <span className="bold">Migrated into Kubernetes: </span>
        <span>A container-orchestration system for automating computer application deployment, scaling and management.</span>
      </div>
    ]
  },
  {
    date: 'May 21st, 2021',
    list: [
      <div>
        <span className="bold">Sync Module Improvement: </span>
        <span>Now covering additional use cases (also dedicated to specific reporting) with dedicated Data Health Checks.</span>
      </div>,
      <div>
        <span className="bold">Improved Limits View: </span>
        <span>Added graphic visualization (for specific use case).</span>
      </div>,
      <div>
        <span className="bold">Enriched Loans Management: </span>
        <span>Expanded data availability in the loans management view and improved download functionality.</span>
      </div>,
      <div>
        <span className="bold">Dynamic Data Load (Loans Management): </span>
        <span>The loans endpoint can now be queried to retrieve just a subset of fields (i.e. just loan id and debtor name), or even a single loan record, despite the default pagination being 50 records per page.</span>
      </div>,
      <div>
        <span className="bold">CardoAI Repository (Components Library): </span>
        <span>We have developed and published our own FrontEnd React Component Library. This will result in re-usability and time efficiency when coding.</span>
      </div>,
      <div>
        <span className="bold">Frontend Authentication Refactor: </span>
        <span>Enhanced application security (minimized security vulnerabilities).</span>
      </div>,
    ]
  },
  {
    date: 'November 5th, 2021',
    list: [
      <div>
        <span className="bold">Users Module Upgrade</span>
        <span>
          <li>• Users can have different roles and manage multiple vehicles - no more log-out/log-in to sync and update different SPVs.</li>
          <li>• Enabled the creation of temporary access links.</li>
        </span>
      </div>,
      <div>
        <span className="bold">VDR Feature</span>
        <span>
          <li>• Originators and Arrangers can now offer a fully digital Due Diligence.</li>
          <li>• Investors can enjoy total transparency on portfolios, historical data and performance.</li>
          <li>• Quality, accessibility and reliability of data are assured on all the stages of the transaction.</li>
          <li>• Through VDR, potential investors can access a full set of statistics at originator and portfolio level.</li>
          <li>• Summary statistics stratified by investment date; charts with distribution by geography, sector and debtors.</li>
          <li>• Expected and Actual Losses, Default and Prepayment evolution on a monthly and cumulative basis.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Document Collection Management</span>
        <span>
          <li>• Loan Servicers can track the process of retrieving documents needed in order to trigger the collection process.</li>
          <li>• Users can monitor the performance of the process through statistics on progress status and timing, which directly affect the return of each single exposure.</li>
        </span>
      </div>,
      <div>
        <span className="bold">SPV Management View Improvement</span>
        <span>
          <li>• Updated the layout of the module to allow the user a smoother transition between collateral data and notes data.</li>
          <li>• Added the option to pin a vehicle to compare it with others.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Customizable Dashboard</span>
        <span>
          <li>• Users can now select which charts are displayed on the dashboard.</li>
        </span>
      </div>,
    ]
  },
  {
    date: 'April 26th, 2022',
    list: [
      <div>
        <span className="bold">Architectural Upgrade</span>
        <span>
          <li>• Updated the authorization and authentication process for users by making it more secure and stable.</li>
          <li>• Upgraded libraries to the latest version to enhance the performance of the platform.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Synchronization Module</span>
        <span>
          <li>• Improved the synchronization process by adding notification to users.</li>
          <li>• Strengthened DHC with additional checks and better interaction in case of errors.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Expanded Notes Manager</span>
        <span>
          <li>• Functionalities with notifications and new limits checks.</li>
        </span>
      </div>,
      <div>
        <span className="bold">General Improvements</span>
        <span>
          <li>• Users have the option to rearrange the main statistics at Loans Management by drag and drop.</li>
          <li>• Enabled redirection from Days Past Due chart to Loans Management.</li>
          <li>• Enriched HDR module with new data and chart visualizations.</li>
        </span>
      </div>
    ]
  },
  {
    date: 'June 24th, 2022',
    list: [
      <div>
        <span className="bold">Credentials Management</span>
        <span>
          <li>• Improved management of users’ credentials, including “Change Password” functionality to reset password directly on the platform.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Synchronization Module</span>
        <span>
          <li>• Allow users to synchronize incremental installments in order to keep the amount of the notes always up to date.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Notes Manager</span>
        <span>
          <li>• New notifications through pop-up messages for an improved User Experience.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Q&A Module</span>
        <span>
          <li>• New functionalities allowing interaction among investors, originators and servicers of an SPV.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Enriched Loans Management View</span>
        <span>
          <li>• New data including Invoice Number.</li>
        </span>
      </div>,
      <div>
        <span className="bold">Improvement on CSV download</span>
        <span>
          <li>• Allow the user to select the format of the csv download (i.e. decimal separator “.” or “,”) before the download of data.</li>
        </span>
      </div>,
      <div>
        <span className="bold">HDR Module</span>
        <span>
          <li>• Added a new “Cashflow” section that provides details on expected and realized cash flows at portfolio and single loan level.</li>
        </span>
      </div>,
      <div>
        <span className="bold">General Improvements</span>
        <span>
          <li>• Improved the views in the Limits sections for a better User Experience.</li>
          <li>• Enriched Limits section with additional limits’ description.</li>
        </span>
      </div>,
    ]
  },

]