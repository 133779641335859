export default {
  "notes": "notes",
  "collateral": "collateral",
  "structure": "structure",
  "cashFlowNotes": "cashFlow-notes",
  "limits": "limits",
  "accounts": "accounts",
  "cashFlowCollateral": "cashFlow-collateral",
  "performance": "performance",
  "diversification": "diversification",
  "concentration": "concentration",
  "loans": "loans",
  "geography": "geography",
  "breakdown": "breakdown",
  "sector": "sector",
  "debtor": "debtor",
  "notes_manager": "notes_manager"
};
