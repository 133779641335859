import React from 'react';
//Components
import { Col, Input, Row, Select } from "antd";
//Custom Components
import Filter from "./filter";
//Icons
import { FileSearchOutlined } from "@ant-design/icons";

interface Props {
  extra?: any,
  children?: any,
  columns?: any,
  searchValue?: any,
  groupByValue?: any,
  onSearchChange?: any,
  onGroupChange?: any,
  filteredGroups?: any,
  updateFiltering?: any,
  headerGroupingConfig?: any,
  headerTransformTableConfig?: any,
  visible?: boolean
  groupingPosition?: any
}

const Header = (props: Props) => {
  const {
    extra,
    children,
    columns,
    searchValue,
    groupByValue,
    onSearchChange,
    onGroupChange,
    filteredGroups,
    updateFiltering,
    headerGroupingConfig,
    headerTransformTableConfig,
  } = props;

  const groupColumns = columns.filter((col: any) => col.grouping);

  const inputStyle = {minWidth: 160};

  if (searchValue.length > 20) inputStyle.minWidth += 160;

  const style: any = {}

  if (props.groupingPosition === "end")
    style.flex = 1


  return (
    <Row align="bottom" className="mb8">
      <Col style={style}>
        {children}
      </Col>
      <Col>
        <Row align="middle" justify="end" gutter={8}>
          {headerGroupingConfig && (
            <>
              <Col>
                <Input
                  allowClear
                  size='small'
                  style={inputStyle}
                  value={searchValue}
                  onChange={onSearchChange}
                  suffix={<FileSearchOutlined/>}
                  placeholder={groupByValue ? "Search" : "Search All"}/>
              </Col>
              <Col>
                <Select
                  size='small'
                  value={groupByValue}
                  style={{minWidth: 160}}
                  onChange={onGroupChange}
                  placeholder="Select Group">
                  {groupColumns.map((col: any) => (
                    <Select.Option key={col.key} value={col.key}>
                      {col.label}
                    </Select.Option>
                  ))}
                  <Select.Option key="none" value="none">
                    None
                  </Select.Option>
                </Select>
              </Col>
            </>
          )}
          {headerTransformTableConfig && <Col>
            <Filter
              config={filteredGroups}
              updateConfig={updateFiltering}
              useModal={headerTransformTableConfig.modalView}
              showGroupHeader={headerTransformTableConfig.showGroupHeader}/>
          </Col>}
          <Col style={style}>
            {extra}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
