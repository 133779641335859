import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../components";
//Components
import { Col, Row } from "antd";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../assets";

const HDRMainStatistics = (props: any) => {

  const description =
    <div style={{textAlign: 'justify'}}>
      The main dashboard of the HDR, the Hyper Data Room, consists of several compartments as explained below.
    </div>

  return (
    <>
      <Row>
        <Col sm={24}>
          <CustomImage
            alt="Main Statistics"
            src={assets.images.HDRModule.mainStatistics}
            description={description}
          />
        </Col>
      </Row>
      <div>
        <DefinitionList
          header="Main Statistics shown in the information bar on top shows information as of last reported date:"
          data={[
            {
              term: 'Current balance',
              explanation: 'shows the Current Balance of all the vehicles in this environment.'
            },
            {
              term: 'Loans Outstanding #',
              explanation: 'The total count of the open loans.'
            },
            {
              term: 'Average Loan Size',
              explanation: 'is the total amount invested in loans divided by the total number of loans.'
            },
            {
              term: 'WAR(%)',
              explanation: 'Average coupon weighted by the current outstanding principal balance of the loan.'
            },
            {
              term: 'WAM(%)',
              explanation: 'Average residual term of the loans weighted by the current outstanding principal balance of the loan.'
            },
            {
              term: 'Average Delay',
              explanation: 'Average payment delays in days from original maturity,calculated only for closed loans.'
            },
          ]}
        />
      </div>
    </>
  );
};
export default HDRMainStatistics;