import React from 'react';
//Other Components
import {Col,Select} from "antd";
//Helpers
import {STATUSES, STATUSES_ITEMS} from "./helpers";
import {callApi} from "../../../../../../../../helpers";
//Utils
import { mediatorSend, queryServices } from "@cardoai/utils";
//Hooks
import {useAuthorization} from "../../../../../../../../utils/hooks";

const {Option} = Select;

const HistoryStatus = (props: any) => {
  const {record, vehicleId, limitId} = props;
  const auth = useAuthorization();

  const onEditHistoryStatus = (value: any) => {
    if (record.status !== STATUSES.REQUESTED || value === STATUSES.REQUESTED) return;
    const form = new FormData();
    form.append("status", value);
    callApi({
      url: `main/limit_waiver/${record.id}/change_status_of_waiver_request/?vehicle_id=${vehicleId}`,
      method: 'PATCH',
      body: form,
      successMessage: 'The status has been updated',
      onSuccess: () => {
        mediatorSend('Limits', 'refreshLimits', undefined);
        // queryServices.invalidateQuery(`main/limit_waiver/?vehicle_id=${vehicleId}&limit_id=${limitId}`);
        mediatorSend(
          "WaiverRequestHistory",
          "refreshDataWaiverHistory",
          undefined
        );
      }
    })
  }

  return (
    <Col>
      <Select
        value={record.status}
        onChange={onEditHistoryStatus}
        size="small"
        style={{fontSize: 12, width: 95}}
        disabled={record.status !== STATUSES.REQUESTED || auth.role !== "EqualizerInvestor"}
      >
        {Object.keys(STATUSES_ITEMS).map((key: string) => {
          const item = STATUSES_ITEMS[key];
          return (
            <Option
              style={{fontSize: 12}}
              key={key}
              value={key}
            >
              <div style={{color: item.backgroundColor}}>
                {item.label}
              </div>
            </Option>
          )
        })}
      </Select>
    </Col>
  );
};

export default HistoryStatus;