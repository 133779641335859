import React, {useImperativeHandle} from 'react';
import {Row, Col, Select, Input, Button} from "antd";
import {useToggle} from "@cardoai/utils";
import SearchIcon from "../../icons/search";

const CustomSelect = ({options, ...rest}: any) => {
    return (
        <Select
            {...rest}
            style={{...rest.style, width: '100%'}}>
            {options.map((option: any) => (
                <Select.Option key={option.key}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    )
}

const components: any = {
    input: Input,
    select: CustomSelect,
}

const CloseSearch = (props: any) => {
    return (
        <div {...props}
             style={{
                 display: 'flex',
                 cursor: 'pointer',
                 alignItems: 'center',
                 background: '#EAF1FD',
                 justifyContent: 'center'
             }}>
            <SearchIcon active={true}/>
        </div>
    )
}

const useDebounce = () => {
    const [intervalId, setIntervalId] = React.useState(null);
    return (func: any, wait: number) => {
        if (intervalId) clearTimeout(intervalId);
        // @ts-ignore
        setIntervalId(setTimeout(func, wait));
    };
};


const SmartSearch = React.forwardRef(({width = 400, config = [], ...rest}: any, ref) => {
    const debounce = useDebounce();
    const [open, toggleOpen] = useToggle(false);

    const [selected, setSelected] = React.useState(() => {
        return config.find((record: any) => record.defaultOpen)?.key
    });

    const getInitialState = () => {
        const initialState: any = {};
        config.forEach((record: any) => initialState[record.key] = null)
        return initialState
    }

    const [state, setState] = React.useState(() => getInitialState())

    useImperativeHandle(ref, () => ({
        reset: () => {
            setState(getInitialState())
        }
    }));

    const updateValue = (key: any, value: any) => {
        setState((previous: any) => {
            debounce(() => {
                if (rest.onChange)
                    rest.onChange({...previous, [key]: value})
            }, 250)
            return {
                ...previous,
                [key]: value
            }
        })
    }

    const multiple = config.length > 1;

    return (
        <div>
            {!open && (
                <Button
                    ghost
                    type="link"
                    onClick={() => toggleOpen()}
                    icon={<SearchIcon/>}/>
            )}
            {open && (
                <Row align="middle" style={{minWidth: width, maxWidth: width}}>
                    <Col xs={8}>
                        <Select
                            value={selected}
                            showArrow={multiple}
                            style={{
                                width: '100%',
                                pointerEvents: multiple ? 'auto' : 'none'
                            }}
                            onChange={multiple ? setSelected : undefined}>
                            {config.map((record: any) => {
                                return (
                                    <Select.Option key={record.key}>
                                        {record.label}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Col>
                    {config.map(({type, key, ...rest}: any) => {
                        const Component = components[type];
                        return (
                            <Col xs={16}
                                 style={{display: key === selected ? 'block' : 'none'}}>
                                <Row gutter={0} align="middle" style={{flexWrap: 'nowrap'}}>
                                    <Col flex="auto">
                                        <Component
                                            {...rest}
                                            allowClear
                                            value={state[key]}
                                            onChange={(e: any) => {
                                                updateValue(key, typeof e === "object" ? e.target.value : e)
                                            }}
                                        />
                                    </Col>
                                    <Col style={{flexBasis: 32}}>
                                        <CloseSearch
                                            active={true}
                                            onClick={() => toggleOpen()}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
            )}
        </div>
    );
});

export default SmartSearch;