import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const Performance = () => {
  return (
    <>
      <div>
        <DefinitionList
          header="This section shows the current performance of each of the tranches of the SPVs in terms of:"
          data={[
            {
              term: 'Notes Current Balance',
              explanation: 'current balance of the specific Note'
            },
            {
              term: 'Factor',
              explanation: 'repayment factor of the tranche (ratio between Initial Balance and Current Balance of the notes_section)'
            },
            {
              term: 'OC',
              explanation: 'overcollateralization'
            },
            {
              term: 'Coupon',
              explanation: 'coupon paid during the last period'
            },
            {
              term: 'Rating',
              explanation: 'public rating assigned to the tranche (where available). Hovering on the field, the User can get additional details'
            },
            {
              term: 'Return',
              explanation: 'return recorded by the note in the last reporting period'
            },
            {
              term: 'Tranche',
              explanation: 'seniority of the tranche'
            },
            {
              term: 'Payments’ history',
              explanation: 'shows the type of payment (if any) made by the tranche at each reporting date'
            }
          ]}
        />
      </div>
      <div>
        <p><b>The chart on the right compares the structure of the Notes (in terms of seniority) and that of the
          portfolio
          (in terms of performance / delinquency distribution of the loans)</b></p>
      </div>
      <div>
        <CustomImage
          alt="Dashboard Performance"
          src={assets.images.dashboardModule.performance}
        />
      </div>
    </>
  );
};

export default Performance;
