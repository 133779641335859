import React from 'react';
import { Col, Row } from "antd";
import cx from "classnames";
import {formatVehicleLimits} from "../../helpers";

const InformationWaiver = ({active,fulfillmentClassLimit,fulfillment_percent,formatter,currencyLimits}: any) => {
  return (
    <Row>
      <Col span={12}>
        <h4 className="bolder textCenter">Waiver Id: {active.id}</h4>
        <h4 className="bolder textCenter">Reviewed By: {active.decided_by}</h4>
      </Col>
      <Col span={12}>
        <h4 className="bolder textCenter">Waiver Type: {active.waiver_type}</h4>
        <h4 className="bolder textCenter">Pre-waiver Limit: <span className={cx(fulfillmentClassLimit)}>{formatVehicleLimits(active.original_value,
          fulfillment_percent,
          formatter,
          currencyLimits)}</span></h4>
      </Col>
    </Row>
);
};
export default InformationWaiver;