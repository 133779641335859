import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../assets";

const HyperDataRoom = () => {

  const description =
    <div style={{textAlign: 'justify'}}>
      The main dashboard of the HDR, the Hyper Data Room, consist of the several compartments as explained below.
    </div>

  return (
    <Row>
      <Col sm={24}>
        <CustomImage
          description={description}
          alt="Hyper Data Room"
          src={assets.images.HDRModule.fullView}
        />
      </Col>
    </Row>
  );
};

export default HyperDataRoom;