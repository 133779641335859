import React, { useState } from "react";
import queryString from "query-string";
//Components
import { Col, Input, Row, Select } from "antd";
//Custom Components
import config from "../configurations";
import CashFlowGraph from "./components/cashflow_graph";
import CashFlowTable from "./components/cashflow_table";
//Icons
//Components
import { HorizontalCollapse } from "@cardoai/components";
//Custom Components
import DateSelector from "../../../../components/form_elements/date_selector";
//Helpers
import { createFileName, downloadClientFile, formatServerDate, getRangePeriod } from "../../../../helpers";
import { DownloadAsCSV } from "../../../../components";

interface Props {
  vehicleId?: any,
  filterOptions?: any,
}

const styles = {
  search: {
    minWidth: 200
  }
}

const CashFlowTab = (props: Props) => {
  const {vehicleId, filterOptions} = props
  const initialRange = getRangePeriod(3, "months")

  const [dateRange, setDateRange] = useState<any>(initialRange)
  const [cashFlowSelected, setCashFlowSelected] = useState<any>([])
  const [searchInput, setSearchInput] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const casavoIotaVehicle = vehicleId === 39;

  const onSearch = (attr: string) => (event: any) => {
    setSearchInput((prevState: any) => ({
      ...prevState,
      [attr]: event.target.value
    }))
  }

  const getFilters = () => {

    const [fromDate, toDate] = dateRange;

    const filters: any = {
      vehicle_id: vehicleId,
      from_date: formatServerDate(fromDate),
      to_date: formatServerDate(toDate),
      cash_flow_type: cashFlowSelected
    }

    for (let key in searchInput)
      if (searchInput[key] !== '')
        filters[key] = searchInput[key]

    return `${queryString.stringify(filters)}`
  }

  const onDownload = (decimalSeparatorQuery?: string) => {
    const finalUrl = `${config.cashflows.endpoints.downloadUrl}${getFilters()}${decimalSeparatorQuery}`;
    downloadClientFile({
      url: finalUrl,
      filename: createFileName(config.cashflows.entity, 'csv'),
      setLoading
    })
  }

  return (
    <>
      <Row align="middle" justify={casavoIotaVehicle ? "center" : "end"} gutter={8}>
        {casavoIotaVehicle &&
          <Col>
            <DateSelector
              range
              value={dateRange}
              initialInterval={3}
              endOfMonthFormat={false}
              onChange={setDateRange}/>
          </Col>
        }
        <Col>
          <Select placeholder="Filter by Cash Flow Type"
                  style={{width: 250}}
                  allowClear
                  mode="multiple"
                  value={cashFlowSelected}
                  onChange={setCashFlowSelected}>
            {filterOptions?.cash_flow_type?.map((cashFlow: string) => {
              return (
                <Select.Option key={cashFlow} value={cashFlow}>
                  {cashFlow}
                </Select.Option>
              )
            })
            }
          </Select>
        </Col>
        <Col>
          <Input
            allowClear
            style={styles.search}
            onChange={onSearch("real_estate_id")}
            placeholder="Search Real Estate ID"
          />
        </Col>
        <Col>
          <DownloadAsCSV loading={loading} onDownload={onDownload}/>
        </Col>
      </Row>
      <HorizontalCollapse
        activePanel="both"
        height={750}
        nodes={[
          {
            text: "Cashflow Graph",
            content: <CashFlowGraph filters={getFilters()}/>
          },
          {
            text: "Cashflow Table",
            content: <CashFlowTable filters={getFilters()}/>
          }
        ]}/>
    </>
  )
}
export default CashFlowTab;