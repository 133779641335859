import React from "react";
//Icons
import { MinusOutlined } from "@ant-design/icons";
//Helpers
import { addMonths, addYears, formatPercent, formatters, formatThousands } from "../../../../../../helpers";

const FORMATTER: any = {
  "Amount": "Amount",
  "Years": "Years",
  "Months": "Months",
  "String": "String",
  "Boolean": "Boolean",
  "Default": "Default",
  "Percentage": "Percentage",
  "Number": "Number"
}

export const getPreWaiverLimitColor = (colorPreWaiverLimit: boolean) => {
  if (colorPreWaiverLimit) return "redText"
  return "greenText"
}

const getLimitColor = (brokenLimit: boolean, timeExtensionWaiver?: boolean) => {
  if (brokenLimit === null) return "";
  else if (timeExtensionWaiver) return "greyText";
  else if (brokenLimit) return "redText";
  return "greenText";
}

const formatLimits = (value: any, formatter: any, currency?: any) => {
  if (!value)
    return " - ";

  switch (formatter) {
    case FORMATTER.Amount:
      return formatters.currency({value, currency});
    case FORMATTER.Number:
      return formatThousands(value);
    case FORMATTER.Percentage:
      return formatPercent(value);
    default:
      return value;
  }
}


export const formatVehicleLimits = (value: any, percentage: any, formatter: any, currency?: any) => {
  function formatLimit() {
    let limit;
    switch (formatter) {
      case FORMATTER.Amount: {
        limit = formatters.currency({value, currency})
        break;
      }
      case FORMATTER.Years: {
        limit = addYears(value)
        break;
      }
      case FORMATTER.Months: {
        limit = addMonths(value)
        break;
      }
      case FORMATTER.Number: {
        limit = formatThousands(value);
        break;
      }
      case FORMATTER.Percentage: {
        limit = !value ? formatPercent(percentage) : formatPercent(value);
        break;
      }
      case FORMATTER.Boolean:
      case FORMATTER.String:
      case FORMATTER.Default: {
        limit = value
        break;
      }
    }
    return limit;
  }

  if (!value && !percentage)
    return " - ";

  if (!value && percentage)
    return formatPercent(percentage);

  if (value && percentage)
    return formatPercent(percentage) + ' (' + formatLimit() + ')';

  return formatLimit();
}

const showThresholdType = (condition: any) => {
  if (condition === null)
    return <MinusOutlined/>

  switch (condition) {
    case "eq":
      return "Equal";
    case "ne":
      return "Not Equal";
    case "gt":
      return "Min";
    case "ge":
      return "Min";
    default:
      return "Max"
  }
}

export {
  formatLimits,
  getLimitColor,
  showThresholdType,
}