import AspireAccounts from "./aspire/aspire_accounts";
import IncomLendAccounts from "./incomlend/incom_lend_accounts";
import AccountsCasavo from "./casavo";

export const ACCOUNTS: any = {
  "compartment_c": {
    component: AspireAccounts,
    urlParam: "notes_manager",
    hasDefaultDate: false,
    showDateSelection: true,
  },
  "w_compartment": {
    component: IncomLendAccounts,
    urlParam: "notes",
    hasDefaultDate: true,
    showDateSelection: true,
  },
  "casavo_iota": {
    component: AccountsCasavo,
    urlParam: "casavo_iota",
    showDateSelection: false
  }
}