import React from 'react';
//Other Libraries
import Particles from "react-tsparticles";
//Constants
import { theme } from "../../../../assets/theme/colors";

const ParticlesContainer = () => {
  return (
    <Particles
      id="tsparticles"
      options={{
        background: {
          color: {
            value: theme.palette.grayscale[4],
          },
        },
        particles: {
          color: {
            value: theme.colors.primary,
          },
          links: {
            color: theme.colors.primary,
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            value: 60,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}/>
  );
};

export default ParticlesContainer;
