//charts
import { Bar, Bubble, Doughnut, Line, Pie, PolarArea, Radar, Scatter } from "react-chartjs-2";
//Helpers
import { humanString } from "../../../../../helpers";
//Constants
import { theme } from "../../../../../assets/theme/colors";

export const charts: any = {
  'bar': Bar,
  'doughnut': Doughnut,
  'line': Line,
  'pie': Pie,
  'polar': PolarArea,
  'radar': Radar,
  'scatter': Scatter,
  'bubble': Bubble,
};

/*Default chart Options*/
export const defaultOptions: any = {
  maintainAspectRatio: false,
  responsive: true,
  bezierCurve: true,
  layout: {
    padding: {
      'top': 20,
      'right': 20,
      'bottom': 20,
      'left': 20
    }
  }
}

/*Custom Helpers*/
export const createGradient = () => {
  const canvas = document.createElement('canvas');
  const ctx: any = canvas.getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 300, 0);
  gradient.addColorStop(0, theme.palette.us[0]);
  gradient.addColorStop(0.5, theme.palette.us[1]);
  gradient.addColorStop(1, theme.palette.us[2]);
  return gradient;
}

export const sortItems = (data: any, getValue: any, valueEntity: any, entities: any) => {
  const sortedData = [...data];
  sortedData.sort((a, b) => {
    const aValue = getValue ? getValue(a, entities) : a[valueEntity];
    const bValue = getValue ? getValue(b, entities) : b[valueEntity];
    return bValue - aValue;
  });
  return sortedData;
};

export const prepareSheet = (records: any) => {
  return records.map((record: any) => {
    const newRecord: any = {};
    for (let key in record) {
      if (record.hasOwnProperty(key)) {
        const newKey = humanString(key);
        newRecord[newKey] = record[key]
      }
    }
    return newRecord;
  })
};