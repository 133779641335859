import React from "react";
//Components
import { CustomTable, SimpleEmpty } from "@cardoai/components";
//Helpers
import { formatters, validateValue } from "../../../../../../../../../helpers";

const calculateSum = (data: any) => {
  const sum: any = {
    "retained_costs": 0,
    "net_accrued_interest": 0,
  }

  for (let record of data) {
    for (let key in sum) {
      sum[key] += record[key];
    }
  }
  return sum;
}

interface Props {
  data: any[],
  columns: any,
  currencyConfig: any
}

const AssetSaleDetails = (props: Props) => {
  const {data, columns, currencyConfig} = props;

  if (!data || !data.length)
    return <SimpleEmpty/>

  const sum = calculateSum(data);

  const renderTableHeader = () => {
    return (
      <thead>
      {columns.map((column: any) => <th style={{minWidth: column.width}}>{column.label}</th>)}
      </thead>
    )
  };

  const renderTableBody = (columns: any) => {
    return (
      <tbody>
      {data.map(record => {
        return (
          <tr>
            {columns.map((column: any,index:number) => {
              const format = column.format ? column.format : formatters.thousands
              return (
                <td key={index}>
                  {validateValue(record[column.key], format)}
                </td>
              )
            })}
          </tr>
        )
      })}
      {renderLastRow(data)}
      </tbody>
    )
  };

  const renderLastRow = (data?: any) => {
    return (
      <tr>
        <td><b>TOTAL</b></td>
        <td>
          <b>{formatters.currency({value: sum.retained_costs, currency: currencyConfig})}</b>
        </td>
        <td><b>{validateValue(sum.net_accrued_interest, formatters.thousands)}</b></td>
      </tr>
    )
  }

  return (
    <CustomTable hover={false} size='small'>
      {renderTableHeader()}
      {renderTableBody(columns)}
    </CustomTable>
  )
}

export default AssetSaleDetails;
