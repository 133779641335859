import styled from 'styled-components';
//Constants
import { theme } from "../../../../assets/theme/colors";

//Transition
export const transition = (timing = 0.3) => {
  return `
      -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;
}

// Border Radius
export const borderRadius = (radius = 0) => {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -ms-transition: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `;
}

const SidebarWrapper = styled.div`
  .isomorphicSidebar {
    z-index: 1000;
    background: #1e1f21fa;
    width: 280px;
    flex: 0 0 280px;
    height: ${props => {
      return `${props.theme.height}px`;
    }};

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 70px;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0px;

      .siteIcon {
        height: 35px;
      }

      .siteLogo {
        height: 55px;
      }
    }

    &.ant-layout-sider-collapsed {
      /* TEMPORARY FIX FOR ICON CENTERING */

      .ant-menu-item {
        padding: 0 30px !important;
      }

      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      padding-top: 35px;
      padding-bottom: 35px;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 0;
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;

        i {
          font-size: 19px;
          color: inherit;
          margin: ${(props: any) => props['data-rtl'] === 'rtl' ? '0 0 0 30px' : '0 30px 0 0'};
          width: 18px;
          ${transition()};
        }
      }

      .subMenu {
        background-color: rgba(0, 0, 0, 0.1);
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 14px;
        color: inherit;
        font-weight: 400;
        padding-left: -10px;
        ${transition()};
      }

      .ant-menu-item-selected {
        background-color: rgba(0, 0, 0, 0.4) !important;

        .anticon {
          color: ${theme.colors.white};
        }

        i {
          color: ${theme.colors.white};
        }

        .nav-text {
          color: ${theme.colors.white};
        }
      }

      > li {
        &:hover {
          i,
          .nav-text {
            color: ${theme.colors.white};
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: #9e9e9e;
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 24px;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${(props: any) => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
          right: ${(props: any) => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: ${theme.colors.white};
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${(props: any) => props['data-rtl'] === 'rtl' ? '0px !important' : '74px !important'};
          padding-right: ${(props: any) => props['data-rtl'] === 'rtl' ? '74px !important' : '0px !important'};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            a {
              color: ${theme.colors.white} !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }

          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      /*TEMPORARY FIX FOR MOBILE*/
      &.ant-layout-sider-collapsed {
        display: none;
      }
    }

    .sideBarActions {
      height: 41px;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3) !important;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      //user-select: none;

      :hover {
        font-weight: 500;
        color: ${theme.colors.white};
        cursor: pointer;
        transition: all;
        transition-delay: 0.2s;
      }

      span {
        font-size: 14px;
      }

      .icon {
        font-size: 18px;
        width: 25px;
        margin-right: 2px;
      }
    }
  }
`;

export default SidebarWrapper
