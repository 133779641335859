import React from "react";
//Components
import { Col, Row } from "antd";
import { Box } from "@cardoai/components";
//Helpers
import { stopPropagation } from "../../../helpers";

interface StaticLegendProps {
  customClick?: boolean,
  className?: string | undefined,
  selected?: any,
  config?: any,
  onSelect?: (param: any) => any,
  staticDisplay?: boolean,
  disableSelection?: boolean
}

const Legend = (props: StaticLegendProps) => {
  const {selected, onSelect, config, customClick, staticDisplay, className, disableSelection} = props;

  const handleClick = (key: any) => {
    return customClick ? () => onSelect?.(key) : onSelect?.(key)
  }
  return (
    <Row justify="center" align="middle" gutter={8} className={className}>
      {config.map((item: any, index: any) => {
        let active: any = item.key;

        if (!staticDisplay) {
          if (Array.isArray(selected)) {
            active = selected.includes(item.key);
          } else if (typeof selected === "object") {
            active = selected[item.key];
          }
        }

        const labelStyle: any = {};
        if (!active)
          labelStyle.textDecoration = "line-through";

        return (
          <Col onClick={!disableSelection ? handleClick(item.key) : stopPropagation} key={index}>
            <Row gutter={8} align="middle">
              <Col>
                <Box
                  blank={!active}
                  color={item.color}
                  disabled={disableSelection}
                />
              </Col>
              <Col className={!disableSelection ? "pointerCursor" : ""} style={labelStyle}>
                {item.label}
              </Col>
            </Row>
          </Col>
        )
      })}
    </Row>
  )
}

Legend.defaultProps = {
  customClick: false,
  disableSelection: false,
  staticDisplay: false
}
export default Legend;