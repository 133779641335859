import React from 'react';
import {Button, Tabs} from "antd";
import {CasavoTable} from "../../../../components";
import config from "../configurations";
import CashFlowTab from "../cashflow_tab";
import {FilterOutlined} from "@ant-design/icons";
import {useGlobalApi} from "../../../../utils/hooks";
import {useGetProposalDateLoan} from "../../apis";
const TabPane = Tabs.TabPane;

const CasavoIotaTab = ({filterOptions,cashflowTypes,toggleColFilterModal}:any) => {
  const {vehicles} = useGlobalApi();
  let vehicleId:any;
  const selectedVehicle = vehicles.find((vehicle: any) => {
    return vehicle.identifier === "casavo_iota" || vehicle.identifier === "casavo_iota_demo";
  });

  if (selectedVehicle) {
    vehicleId = selectedVehicle.id;
  }
  const loanByLoanFiltering = useGetProposalDateLoan(vehicleId);
  const dateOptions = loanByLoanFiltering.data;

  const tableConfig = config.loanByLoanSection;

  return (
      <Tabs>
        <TabPane
          key="real_estate_asset"
          tab="Real Estate Asset">
          <CasavoTable
            height={700}
            vehicleId={vehicleId}
            filterOptions={filterOptions}
            tableConfig={config.asset}
            fileExtension='csv'/>
        </TabPane>
        <TabPane
          key="cash_flow"
          tab="Cash Flow">
          <CashFlowTab vehicleId={vehicleId} filterOptions={{...filterOptions, ...cashflowTypes}}/>
        </TabPane>
        <TabPane key="loan_by_loan" tab="Loan-By-Loan">
          <CasavoTable
            height={680}
            tableConfig={tableConfig}
            vehicleId={vehicleId}
            usesRealEstateSearch
            usesTransactionSearch
            usesFilterByAcquisition
            usesDownload={false}
            excelDownload
            usesFilterByStatus={true}
            usesFilters
            filterOptions={filterOptions}
            dateFilter={dateOptions}
            extra={
              <Button onClick={toggleColFilterModal} icon={<FilterOutlined />}>
                Columns
              </Button>
            }
          />
        </TabPane>
      </Tabs>

  );
};

export default CasavoIotaTab;