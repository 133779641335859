import React, { useState } from 'react';
//Custom CSS
import Styles from './styles';
//Components
import { HorizontalCollapse, Panel } from "@cardoai/components";
//Custom Components
import { Debtor, DelaysChart, Diversification, LossChart } from "../common";
import MainStatistics from "../common/main_statistics";
import VehiclesSelection from "./components/vehicles_selection";
import ContributorsChart from "./components/contributors";
import DODTable from "./components/dod_table";
//Custom Helpers
import {getInitialQuery, getVehicleId} from "./helpers";
//Helpers
import { Analytics } from "../../helpers";
import { actionKey } from "../../constants";
import { systemCurrency } from "../../settings";
//Hooks
import { useAuthorization, useGlobalApi } from "../../utils/hooks";

const AnalyticsModule = () => {
  const {vehicles} = useGlobalApi();
  const authorization: any = useAuthorization();
  const singleVehicleView = vehicles.length === 1;

  let initialQueryStr = '', vehicleId;

  if (singleVehicleView) {
    vehicleId = vehicles[0].id;
    initialQueryStr = getInitialQuery(vehicleId);
  }

  const [queryStr, setQueryStr] = useState<any>(initialQueryStr);
  const [oneVehicleSelected, setOneVehicleSelected] = useState<any>(null);

  const currency = systemCurrency;

  const handleVehicleChange = (selections: any) => {
    const allSelections = Object.values(selections);
    const checked = allSelections.filter(item => !!item).length === 1;
    checked ? setOneVehicleSelected(true) : setOneVehicleSelected(false);
  }

  const handleQueryChange = (str: React.SetStateAction<string>) => {
    setQueryStr(str);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: 'Selected multiple vehicles',
      label: str
    });
  }

  /*Decide whether to show DOD panel based on the selected vehicles*/

  let enableDOD = false;

  if (oneVehicleSelected)
    enableDOD = authorization.canPerformAction(actionKey.getDODList, Number(getVehicleId(queryStr)))
  else {
    const allVehiclesIds = getVehicleId(queryStr).map(item => Number(item)); /*Convert the ids to Number*/
    if (allVehiclesIds.length)
      enableDOD = allVehiclesIds.every(id => authorization.canPerformAction(actionKey.getDODList, id)); /*Enable only if all of them have permission for this action*/
    if (singleVehicleView)
      enableDOD = authorization.canPerformAction(actionKey.getDODList, Number(vehicleId))
  }

  return (
    <Styles>
      <VehiclesSelection
        data={vehicles}
        onChange={handleVehicleChange}
        setQueryStr={handleQueryChange}
        disableSelection={singleVehicleView}/>
      <Panel extraSpace defaultOpen className="panel-design" header="Main Statistics">
        <MainStatistics currency={currency} queryStr={queryStr} exclude={['reference_date']}/>
      </Panel>
      <Panel extraSpace className="panel-design" header="Delinquencies / Loss Curve">
        <HorizontalCollapse
          activePanel="both"
          height={500}
          nodes={[
            {
              text: "Delays",
              content: <DelaysChart currency={currency} queryStr={queryStr}/>
            },
            {
              text: "Loss Curve",
              content: <LossChart showLine={false} queryStr={queryStr}/>
            }
          ]}/>
      </Panel>
      <Panel extraSpace className="panel-design" header="Concentration Statistics">
        <HorizontalCollapse
          height={900}
          activePanel="both"
          nodes={[
            {
              text: "Concentration Tables",
              content: <Debtor currency={currency} displayRating={oneVehicleSelected} queryStr={queryStr}/>
            },
            {
              text: "Diversification Graphs",
              content:
                <Diversification
                  chartHeight={170}
                  currency={currency}
                  queryStr={queryStr}
                  minSelectionSize={2}
                  maxSelectionSize={6}
                  displayRating={oneVehicleSelected}
                  cacheSelectionEntity="analytics_general_pie_charts"/>
            }
          ]}/>
      </Panel>
      <Panel extraSpace defaultOpen={false} className="panel-design" header="Contribution to Returns">
        <ContributorsChart queryStr={queryStr} displayRating={oneVehicleSelected}/>
      </Panel>
      {enableDOD &&
        <Panel extraSpace defaultOpen={false} className="panel-design" header="Default Classification (DoD)">
          <DODTable currency={currency} queryStr={queryStr}/>
        </Panel>}
    </Styles>
  );
}

export default AnalyticsModule;