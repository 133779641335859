import React, { useEffect, useState } from 'react';
//Other Libraries
import { produce } from "immer";
//Components
import { Button, Card, Checkbox, Col, Divider, Row } from "antd";
import { Break, NumberInput, TransparentInput } from "@cardoai/components";
import Load from "./load";
import Forms from "./forms";
import Remove from "./remove";
import InputPicker from "./forms/form/input_picker";
//Configuration
import { chartDefaultPeriodOptions, emptyGroupRecord, FIELD, FORM_FIELD } from "../../../config"
import { theme } from "../../../../../assets/theme/colors";
//Helpers
import { callApi, generateUrl } from "../../../../../helpers";
import notifications from "../../../../../components/notifications";
import { useAuthorization } from "../../../../../utils/hooks";
//Styles
const Styles = {
  mediumText: {
    color: theme.palette.border[9],
    marginBottom: 12
  },
  button: {
    minWidth: 120
  },
  input: {
    width: 160
  }
};
//Constants
const defaultGroup = [
  {
    selection: "All",
    records: [emptyGroupRecord("All")]
  }
]

interface Props {
  scenarioType?: any,
  scenarioKey?: any,
  vehicleId?: any,
  reference?: any,
  groupOptions?: any,
  loadingOptions?: any,
  initialConfig?: any,
  onClear?: any
}

const Scenario = (props: Props) => {
  const {
    scenarioType,
    scenarioKey,
    vehicleId,
    reference,
    groupOptions,
    loadingOptions,
    initialConfig,
    onClear
  } = props;

  const [fields, setFields] = useState<any>(null);
  const [selected, setSelected] = useState(['All']);
  const [serverFields, setServerFields] = useState<any>(null);
  const {isDemoUser} = useAuthorization();

  const fixedScenario = scenarioType === "fixed";

  const initializeFields: any = (scenario: any) => {

    function updateField(attr: string, defaultValue: any) {
      return scenario ? scenario[attr] : defaultValue;
    }

    if (scenario) {
      const initialGroup = scenario[FIELD.group][0];
      setSelected(initialGroup.records.map((rec: any) => rec.label))
    }

    setFields({
      ...scenario,
      [FIELD.group]: updateField(FIELD.group, defaultGroup),
      [FIELD.name]: updateField(FIELD.name, ""),
      [FIELD.servicingFee]: updateField(FIELD.servicingFee, 0),
      [FIELD.prepaymentFee]: updateField(FIELD.prepaymentFee, 0),
      [FIELD.daysDelinquent]: updateField(FIELD.daysDelinquent, 0),
      [FIELD.applyCapital]: updateField(FIELD.applyCapital, false),
      [FIELD.applyInterest]: updateField(FIELD.applyInterest, false),
      [FIELD.interestRateCurve]: updateField(FIELD.interestRateCurve, null),
    });
  };

  useEffect(() => {
    initializeFields(initialConfig);
  }, []);

  const handleLoad = (scenario: any) => {
    setServerFields(scenario);
    initializeFields(scenario);
  };

  const handleClear = () => {
    initializeFields();
  };

  const clearServerFields = () => {
    setServerFields(null);
  }

  const handleDelete = () => {
    if (serverFields) {
      const name = serverFields.name || '';
      callApi({
        url: `/lab/scenarios/delete_scenario/?scenario_id=${serverFields.id}`,
        method: "delete",
        onSuccess: () => {
          if (!fixedScenario)
            onClear(scenarioKey);

          handleClear();
          clearServerFields();
          notifications.success(`Scenario ${name} has been removed`);
        },
      })
    } else {
      onClear(scenarioKey);
    }
  };

  const applyRules = () => {
    return produce(fields, (draftState: any) => {
      const initialGroup = draftState[FIELD.group][0];

      /*Rule => Validate Days Delinquent Field*/
      if (!draftState[FIELD.daysDelinquent] || draftState[FIELD.daysDelinquent] < 30)
        draftState[FIELD.daysDelinquent] = 0;

      /*Rule => filter out non-selected records*/
      initialGroup.records = initialGroup.records.filter((record: any) => selected.includes(record.label))


      /*Rule => remove all record in case if the reset is selected*/
      if (selected.includes("All") && selected.length > 1) {

        const selectedGroup = groupOptions.find((record: any) => record.label === initialGroup.selection);

        const totalRecords = selectedGroup.records.length;

        if (totalRecords === selected.length)
          initialGroup.records = initialGroup.records.filter((record: any) => record.label !== "All");
      }
    })
  }

  const handleSave = () => {

    if (!groupOptions)
      return null;

    const finalScenario: any = applyRules();

    if (!serverFields) {
      /*Handle Create*/
      callApi({
        url: '/lab/scenarios/create_scenario/',
        body: {vehicle_id: vehicleId, scenario: finalScenario},
        method: "post",
        onSuccess: (result: any) => {
          let name = finalScenario.name || '';
          handleLoad(result);
          notifications.success(`Scenario ${name} has been created`);
        },
      })
    } else {
      /*Handle Update*/
      const updateUrl = generateUrl('/lab/scenarios/update_scenario', {vehicle_id: vehicleId})

      callApi({
        url: updateUrl,
        body: finalScenario,
        method: "put",
        onSuccess: (result: any) => {
          let name = result.name || '';
          handleLoad(result);
          notifications.success(`Scenario ${name} has been updated`);
        },
      })
    }
  }

  const handleChange = (attr: any) => (value: any) => {
    setFields(produce((draft: any) => {
      draft[attr] = value;
    }))
  };

  const handleCheckboxChange = (attr: any) => (value: any) => {
    setFields(produce((draft: any) => {
      draft[attr] = value.target.checked;
    }))
  }

  const getValue = (attr: string, ...rest: any[]) => {
    let value = fields[attr];

    if (rest.length === 0)
      return value

    rest.forEach(key => value = value && value[key])

    return value
  };

  const handleGroupChange = (index: any) => (func: any) => {
    setFields(produce((draftFields: any) => {
      func(draftFields[FIELD.group][index]);
    }))
  };

  const handleSelected = (label: any, type = "toggle") => (e: any) => {
    if (type === "toggle") {
      const initialGroup = fields[FIELD.group][0];
      const recordExist = initialGroup.records.find((record: any) => record.label === label)

      if (!recordExist) {
        const record = emptyGroupRecord(label);

        setFields(produce((draftFields: any) => {
          draftFields[FIELD.group][0].records.push(record)
        }))
      }

      setSelected(previousSelected => {
        let cloneState = [...previousSelected];
        const exist = cloneState.includes(label);
        if (!exist)
          cloneState.push(label);
        else
          cloneState = cloneState.filter(rec => rec !== label);
        return cloneState;
      });
    } else {
      if (selected.includes(label))
        return;
      setSelected(previousSelected => [
        ...previousSelected,
        label
      ]);
    }


  };

  const sameValues = (values: any) => {
    return values.every((value: any) => value === values[0]);
  }

  const getInterestRateValue = (attr: any) => {
    if (fields.hasOwnProperty(attr)) {
      const periods = fields[attr];
      if (!periods)
        return null;
      const values = Object.values(periods);
      if (!values.length)
        return null;
      return sameValues(values) ? values[0] : null;
    }
  };

  const getInterestRatePlaceholder = (attr: any, defaultPlaceholder: any) => {
    if (fields.hasOwnProperty(attr)) {
      const periods = fields[attr];
      if (!periods)
        return defaultPlaceholder;
      const values = Object.values(periods);
      if (!values.length)
        return defaultPlaceholder;
      return sameValues(values) ? defaultPlaceholder : "Custom input";
    }
    return defaultPlaceholder;
  };

  if (!fields)
    return null

  const groups = getValue(FIELD.group);
  const multipleGroups = groups && groups.length > 1;

  reference[scenarioKey] = {
    fields: fields,
    selected: selected,
    serverFields: serverFields
  }

  const disableRemoval = fixedScenario ? !serverFields : false;

  return (
    <Card size="small">
      <Row gutter={8}>
        <Col xs={6} style={{marginRight: 8}}>
          <TransparentInput
            autoFocus={true}
            autoCapitalize="true"
            placeholder="Scenario Name"
            value={getValue(FIELD.name)}
            onChange={handleChange(FIELD.name)}/>
        </Col>
        <Col>
          <Button style={Styles.button} onClick={handleSave} type="primary">
            Save
          </Button>
        </Col>
        <Col>
          <Load
            onLoad={handleLoad}
            vehicleId={vehicleId}
            initialSelected={serverFields}/>
        </Col>
        <Col>
          <Button onClick={handleClear} style={Styles.button} type="primary">
            Clear Settings
          </Button>
        </Col>
        <Col>
          <Remove
            type="danger"
            style={Styles.button}
            onClick={handleDelete}
            fields={serverFields}
            disabled={disableRemoval}
            withPopUp={!!(serverFields && serverFields.id)}>
            Delete
          </Remove>
        </Col>
      </Row>
      <Divider/>
      {!isDemoUser() && <Row>
        <Col xs={12}>
          <div style={Styles.mediumText}>Fees Structure</div>
          <Break/>
          <Row align="middle" gutter={16}>
            <Col style={{minWidth: 45}}/>
            <Col>
              <Row align="middle" gutter={8}>
                <Col>
                  Prepayment Fee
                </Col>
                <Col>
                  <NumberInput
                    min={0}
                    max={100}
                    width={160}
                    normalizeValue={true}
                    type='percent'
                    value={getValue(FIELD.prepaymentFee)}
                    onChange={handleChange(FIELD.prepaymentFee)}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row align="middle" gutter={8}>
                <Col>
                  Servicing Fee
                </Col>
                <Col>
                  <NumberInput
                    min={0}
                    max={100}
                    width={160}
                    normalizeValue={true}
                    type='percent'
                    value={getValue(FIELD.servicingFee)}
                    onChange={handleChange(FIELD.servicingFee)}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <div style={Styles.mediumText}>Delinquency Inputs</div>
          <Break/>
          <Row gutter={16} align="middle">
            <Col style={{minWidth: 45}}/>
            <Col>
              <Row align="middle" gutter={32}>
                <Col>
                  Delinquency Days
                </Col>
                <Col>
                  <NumberInput
                    min={0}
                    width={160}
                    normalizeValue={true}
                    value={getValue(FIELD.daysDelinquent)}
                    onChange={handleChange(FIELD.daysDelinquent)}/>
                </Col>
              </Row>
            </Col>
            <Col>
              Apply Delinquency to:
            </Col>
            <Col>
              <Checkbox
                checked={getValue(FIELD.applyCapital)}
                onChange={handleCheckboxChange(FIELD.applyCapital)}>
                Capital
              </Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={getValue(FIELD.applyInterest)}
                onChange={handleCheckboxChange(FIELD.applyInterest)}>
                Interest
              </Checkbox>
            </Col>
          </Row>
        </Col>
      </Row>}
      <Row className="mt16">
        <Col xs={12}>
          <div style={Styles.mediumText}>Interest Rate</div>
          <Break/>
          <Row gutter={16} align="middle">
            <Col style={{minWidth: 45}}/>
            <Col>
              <Row align="middle" gutter={32}>
                <Col>
                  Interest Rate Curve
                </Col>
                <Col>
                  <InputPicker
                    propagate
                    min={-100}
                    max={100}
                    normalizeValue
                    showTitle={false}
                    title="Interest Rate Curve"
                    type='percent'
                    options={chartDefaultPeriodOptions}
                    fields={getValue(FIELD.interestRateCurve)}
                    value={getInterestRateValue(FIELD.interestRateCurve)}
                    updateFields={handleChange(FORM_FIELD.interest_rate_curve)}
                    placeholder={getInterestRatePlaceholder(FIELD.interestRateCurve, "Interest Rate Curve %")}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider/>
      <div style={Styles.mediumText}>Scenario Settings</div>
      <Forms
        groups={groups}
        selected={selected}
        options={groupOptions}
        loading={loadingOptions}
        multiple={multipleGroups}
        onChange={handleGroupChange}
        handleSelected={handleSelected}
        handleMultipleSelect={setSelected}/>
    </Card>
  );
};

export default Scenario;