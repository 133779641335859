import React from 'react';
import { useNavigate } from "react-router-dom";
import { useAuthorization } from "../../hooks";
import { routeConfiguration } from "../../../constants";
import { notifications } from "../../../components";


export const RedirectionContext = React.createContext({});

export const RedirectionProvider = (props: any) => {
  const navigate = useNavigate();
  const authorization = useAuthorization();
  const [rules, setRules] = React.useState(null);
  const [source, setSource] = React.useState(null);
  const reference = React.useRef({isRedirecting: false});

  const clearRules = () => {
    setRules(null);
  }

  const clearSource = () => {
    setSource(null)
  }

  const clearAll = () => {
    setSource(null);
    setRules(null);
  }

  const setRedirecting = (value: boolean) => {
    reference.current.isRedirecting = value;
  }

  const setRedirection = (params: any) => {
    const {path, config} = params;

    const routes = routeConfiguration;
    const corePath = path.split('/')[1];

    const keys = routeConfiguration.map((route: any) => route.key);

    const index = routes.findIndex((route: any) => route.path === corePath);

    const key = keys[index];

    if (!authorization.canRenderRoute(key)) {
      notifications.warning("Redirection is not possible", "No permissions granted.")
      return;
    }

    reference.current.isRedirecting = true;

    const newRules = config?.rules;

    const newSource = config?.source;

    if (newSource)
      setSource(newSource)

    if (newRules)
      setRules(newRules)

    navigate(path)
  }

  return (
    <RedirectionContext.Provider value={{
      rules,
      source,
      clearAll,
      clearRules,
      clearSource,
      setRedirecting,
      setRedirection,
      reference: reference
    }}>
      {props.children}
    </RedirectionContext.Provider>
  );
}

export default RedirectionProvider;
