import { useQuery } from "../../../helpers";

const useFilterOptions = () => {
  return useQuery({
    url: `/main/real_estate/real_estate_filter_options/`
  })
}


const useCashFlowTypesFiltering = () => {
  return useQuery({
    url: `/main/real_estate_cash_flows/get_filter_options/`
  })
}

const useCashFlowGraphAPI = (filters: any) => {
  return useQuery({
    url: `/main/real_estate_cash_flows/get_cash_flow_timeline/?${filters}`,
    deps: [filters]
  })
}

const useGetProposalDateLoan = (vehicleId: any) => {
  return useQuery({
    url: `/casavo/notes_manager/get_all_proposal_dates/?&vehicle_id=${vehicleId}`,
  })
}

export {
  useCashFlowTypesFiltering,
  useFilterOptions,
  useCashFlowGraphAPI,
  useGetProposalDateLoan
}