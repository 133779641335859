import { useQuery } from "../../../helpers";

export const useSectors = () => {
  return useQuery({
    url: '/common/sector/'
  })
}

export const useCountries = () => {
  return useQuery({
    url: '/common/country/grouped_regions/'
  })
}

export const useCurrencies = () => {
  return useQuery({
    url: '/common/currency/'
  })
}

export const useVehicles = () => {
  return useQuery({
    url: '/main/originator_instance/',
    onPrepareResponse(response) {
      return response.filter((vehicle: any) => vehicle.status !== "onboarding")
    }
  })
}

