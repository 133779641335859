//Helpers
import { formatters } from "../../../../../../helpers";
/*tables Columns*/

export const CONFIG: any = {
  collateral: {
    initialSort: [
      "-reference_date",
    ],
    url: {
      daily: '/main/cash_flows/grouped_cash_flows/daily/',
      monthly: '/main/cash_flows/grouped_cash_flows/monthly/'
    },
    downloadUrl: {
      daily: '/main/cash_flows/grouped_cash_flows/download_excel_daily',
      monthly: '/main/cash_flows/grouped_cash_flows/download_excel_monthly'
    },
    cashFlowFormat: [
      {key: "monthly", label: "Portfolio"},
      {key: "daily", label: "Loans"},
    ]
  },
  notes: {
    url: '/main/notescashflows/',
    downloadUrl: '/main/notescashflows/download_cashflows_excel',
    initialSort: [
      "-reference_date",
      "-amount",
    ]
  }
}


export const collateralCashFlowColumns: any = {
  daily: [
    {
      key: "reference_date",
      label: "Reference Date",
      format: formatters.date,
      align: 'center',
      width: 100,
      sortable: true
    },
    {
      key: "platform_loan_id",
      label: "Loan ID",
      sortable: true
    },
    {
      key: "cash_flow_type",
      label: "Cash Flow Type",
      sortable: true
    },
    {
      key: "expected_amount",
      label: "Expected",
      format: (value: any, rest: any) => formatters.currency({value, symbol: rest.currency_symbol}),
      sortable: true
    },
    {
      key: "realized_amount",
      label: "Realized",
      format: (value: any, rest: any) => formatters.currency({value, symbol: rest.currency_symbol}),
      sortable: true
    },
  ],
  monthly: [
    {
      key: "reference_date",
      label: "Reference Date",
      format: formatters.shortedDate,
      align: 'center',
      width: 100,
      sortable: true
    },
    {
      key: "cash_flow_type",
      label: "Cash Flow Type",
      sortable: true
    },
    {
      key: "expected_amount",
      label: "Expected",
      format: (value: any, rest: any) => formatters.currency({value, symbol: rest.currency_symbol}),
      sortable: true
    },
    {
      key: "realized_amount",
      label: "Realized",
      format: (value: any, rest: any) => formatters.currency({value, symbol: rest.currency_symbol}),
      sortable: true
    },
  ]
}

export const defaultNotesPaymentsColumns = [
  {
    key: "reference_date",
    label: "Reference Date",
    format: formatters.date,
    group: true,
    align: 'center',
    sortable: true
  },
  {
    key: "name",
    label: "Note Name",
    group: true,
    sortable: true
  },
  {
    key: "amount",
    label: "Amount",
    format: (value: any, rest: any) => formatters.currency({value, currency: rest.currency}),
    group: true,
    sortable: true
  },
  {
    key: "cash_flow_type",
    label: "Payment Type",
    group: true,
    sortable: true
  },
]

export const extraNotesPayementsColumns  = [
    {
      key: "transaction_id",
      label: "Transaction ID",
      align: 'center',
      sortable: true
    },
    {
      key: "note_holder",
      label: "Investor",
      align: 'center',
    },
    ...defaultNotesPaymentsColumns,
    {
      key: "coupon_rate",
      label: "Coupon Rate",
      format: formatters.percent,
      align: 'center',
      sortable: true
    }
  ]


export const getEntity = (entity: any, type: any) => {

  if (type && CONFIG.hasOwnProperty(type)) {
    const details = CONFIG[type];
    if (entity in details)
      return details[entity];
  }
  return null;
}