import React from 'react';
//Custom Components
import { CustomTable } from "@cardoai/components";
//Helpers
import { formatLimits } from "../../../../../helpers";
import { formatters } from "../../../../../../../../../../../helpers";
//HOC
import withLimitDownload from "../../../../../components/hoc/with_limit_download";

const TableContent = ({data, formatter, currency}: any) => {
  return (
    <CustomTable height={300}>
      <thead>
      <th>Reference Date</th>
      <th>Realized</th>
      </thead>
      <tbody>
      {data.map((record: any) => {
        return (
          <tr>
            <td>{formatters.date(record.reference_date)}</td>
            <td>{formatLimits(record.realized, formatter, currency)}</td>
          </tr>
        )
      })}
      </tbody>
    </CustomTable>
  );
};

export default withLimitDownload(TableContent)({
  title: "Historical Data",
  prepareData: (data: any[]) => {
    return data.map((record: any) => {
      return {
        "Reference Date": record.reference_date,
        "Realized": record.realized,
      }
    })
  }
});