import React from 'react';
//Custom Components
import { DefinitionList, LinkView } from "../../../components";

const Home = () => {
  return (
    <>
      <div>
        <p className="bold">The application is developed in modules that are reflecting the main activities to manage
          and monitor investments in securitizations and SPVs.</p>
      </div>
      <br/>
      <div>
        <DefinitionList
          header="The menu is always active on the left side of the application and has the following sections:"
          data={[
            {
              term: <LinkView href='/documentation/dashboard'>Dashboard</LinkView>,
              explanation: 'is the summary overview of the SPVs managed in CARDO AI'
            },
            {
              term: <LinkView href='/documentation/spv-management'>SPV Management</LinkView>,
              explanation: 'is the module developed to show in a single view the Asset (collateral) and Liabilities (notes) of an SPV'
            },
            {
              term: <LinkView href='/documentation/notes-manager'>Notes Manager</LinkView>,
              explanation: 'is a tool that allows users to manage the notes on the vehicle as well as provide loan proposals through a certain procedure within the platform.'
            },
            {
              term: <LinkView href='/documentation/loans-management'>Loans Management</LinkView>,
              explanation: 'is the module that stores all the loans in the SPVs (outstanding and closed)'
            },
            {
              term: <LinkView href='/documentation/analytics'>Analytics</LinkView>,
              explanation: 'is the module that provides advanced statistics'
            },
            {
              term: 'Stratification',
              explanation: 'is the module created to allow the user to set custom views of the portfolio'
            },
            {
              term: <LinkView href='/documentation/documents-management'>Documents Management</LinkView>,
              explanation: 'is the module to access transactions documents'
            },
            {
              term: 'Cash Flow Simulation',
              explanation: 'is the module to run simulation of loans’ and notes’ cashflows based on customized scenarios '
            },
            {
              term: 'Syncs',
              explanation: 'is the module that allows users (servicer profiles only) to run data health checks on files and import new data tape in the platform.'
            },
          ]}
        />
      </div>
    </>
  );
};

export default Home;