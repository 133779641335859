import React, { useEffect, useImperativeHandle } from "react";
//Other Libraries
import produce from "immer";
//Components
import { Col, Row } from "antd";
import { Draggable } from "@cardoai/components";
import Group from "./group";


const Groups = (props: any, ref: any) => {
  const {showGroupHeader, directModification, forceUpdate} = props;

  const [groups, setGroups] = React.useState(props.config);

  useImperativeHandle(ref, () => ({
    getGroups: () => groups
  }));

  useEffect(() => {
    if (directModification) {
      forceUpdate(groups);
    }
  }, [directModification, groups])

  const handleGroupsChange = (start: any, end: any) => {
    setGroups((previous: any) => {
      return produce(previous, (draft: any) => {
        const findIndex = (key: any) => draft.findIndex((group: any) => group.key === key);
        const startIndex = findIndex(start);
        const endIndex = findIndex(end);
        let temp = draft[startIndex];
        draft[startIndex] = draft[endIndex];
        draft[endIndex] = temp;
      })
    })
  }

  return (
    <Row justify="space-between" gutter={8}>
      <Draggable onChange={handleGroupsChange}>
        {groups.map((group: any) => (
          <Col key={group.key} xs={group.key === "General" ? 24 : undefined} className="mt16">
            <Group
              group={group}
              update={setGroups}
              showHeader={!showGroupHeader}/>
          </Col>
        ))}
      </Draggable>
    </Row>
  )
};

Groups.defaultProps = {
  unSelectableColumns: []
}

// @ts-ignore
export default React.forwardRef(Groups);