import React from 'react';
//Components
import { Button, Row } from "antd";
//Json
import staticData from "./loans.json";
//Configuration
import { columnGroups, columns } from "./config";
//Icons
import { DownloadOutlined } from "@ant-design/icons";
//Custom Components
import { DownloadAsCSV, SmartTable } from "../../../../components";

const NPLLoans = ({onDownload}: any) => {
  return (
    <>
      <Row className="mr8" align="middle" justify="end">
        <DownloadAsCSV onDownload={onDownload(staticData, "Loans")}>
          <Button icon={<DownloadOutlined/>} size="small">Download</Button>
        </DownloadAsCSV>
      </Row>
      <SmartTable columns={columns} height={700}
                  externalData={staticData}
                  allowGrouping
                  groupingPosition="start"
                  columnGroups={columnGroups}/>
    </>
  );
};

export default NPLLoans;