import React from 'react';
import {Button, Col, Row} from "antd";
import DownloadIcon from "../../icons/download";
import CloseIcon from "../../icons/close";
import XlsxIcon from "../../icons/xlsx";
import {useInterval} from "@cardoai/utils";

export const FileAvatar = (props: any) => {
    return (
        <span style={{
            width: 32,
            height: 32,
            display: 'flex',
            borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EAF1FD'
        }}>
            <XlsxIcon />
          </span>
    )
}

const File =
    ({
         onRemove,
         onDownload,
         canAnimate = false,
         removable = false,
         fileSize = "69 MB",
         title = "Tech design requirements.xls"
     }: any) => {
        const reference = React.useRef<any>();
        const [width, setWidth] = React.useState<number>(0);

        useInterval(() => {
            if (canAnimate && width !== 100)
                setWidth((previous: any) => previous + 5);
        }, 20)

        return (
            <Row
                align="middle"
                justify="space-between"
                style={{
                    padding: 16,
                    borderRadius: 8,
                    overflow: 'hidden',
                    position: 'relative',
                    border: '1px solid #F2F2F2'
                }}>
                {canAnimate && (
                    <div
                        ref={reference}
                        style={{
                            top: 0,
                            left: 0,
                            height: '100%',
                            borderRadius: 8,
                            width: width + '%',
                            position: 'absolute',
                            background: '#C0D5F9'
                        }}>
                    </div>
                )}
                <Col>
                    <Row align="middle" gutter={16}>
                        <Col>
                            <FileAvatar/>
                        </Col>
                        <Col>
                            <Row gutter={[8, 2]}>
                                <Col xs={24} style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#151219',
                                }}>
                                    {title}
                                </Col>
                                {fileSize && (
                                    <Col xs={24} style={{
                                        fontWeight: 400,
                                        color: '#8F8796',
                                        fontSize: 12
                                    }}>
                                        {fileSize}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row gutter={16}>
                        {onDownload && (
                            <Col>
                                <Button type="link" ghost icon={<DownloadIcon/>} onClick={onDownload}/>
                            </Col>
                        )}
                        {removable && (
                            <Col>
                                <Button type="link" ghost icon={<CloseIcon/>} onClick={onRemove}/>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        )
    };


export default File;