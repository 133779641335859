import React, { useEffect, useState } from 'react';
//Custom Components
import Notes from "./notes_section";
import Header from "./common/header";
import Collateral from "./collateral_section";
//Custom helpers
import KEYS from "./keys";
import { OPTIONS, TABS } from "./config";
import { capitalize } from "../../../../helpers";
import { statisticsFields } from "./common/header/statistics/config";
import { NAVIGATOR_HELPER, sectionType } from "./helpers";
//Custom Hooks
import { useTabsNavigation } from "../../../../utils/hooks";

//Constants
const headerWidth = {
  notes: 450,
  collateral: 450
};

interface Props {
  data?: any,
  currency?: any
  vehicles?: any,
  height?: number,
  onVehicleSelect: any,
  redirectionSource?: any,
  redirectionRule?: any,
  isLocked?: any,
  singleVehicleView?: any,
  extraSpace?: boolean,
}

const Management = (props: Props) => {

  const {
    data,
    currency,
    vehicles,
    redirectionRule,
    redirectionSource,
    singleVehicleView,
    extraSpace,
    isLocked,
    height,
    onVehicleSelect
  } = props;

  const navigator: any = useTabsNavigation();

  let initialSection: any;

  if (redirectionRule && redirectionRule.section)
    initialSection = redirectionRule.section;

  if (!redirectionRule && singleVehicleView)
    initialSection = sectionType.notes;

  const reference: any = React.useRef();

  const [section, setSection] = useState(initialSection);
  const [visible, setVisible] = useState(() => !!initialSection);

  const vehicleId = data.vehicle_id;
  const vehicleName = data.vehicle_name;

  React.useEffect(() => {
    if (section)
      setVisible(true);
  }, [section]);

  useEffect(() => {
    if (singleVehicleView)
      onSectionChange(KEYS.notes)
  }, []);

  useEffect(() => {
    const name = NAVIGATOR_HELPER.extractVehicle(navigator);
    if (name.label === vehicleName) {
      const currentSection = NAVIGATOR_HELPER.extractSection(navigator);
      setSection((previousSection: any) => {
        if (previousSection !== currentSection.key)
          return currentSection.key;
        return previousSection;
      })
    } else {
      setSection(null)
    }

  }, [navigator]);


  useEffect(() => {
    const reStructuredBreadCrumb = [];

    if (redirectionRule) {
      const {vehicleId, section, tabKey, nestedTabKey} = redirectionRule;

      const vehicleRecord = vehicles.find((vehicle: any) => vehicle.vehicle_id === vehicleId);

      if (!vehicleRecord)
        return;

      const vehicleName = vehicleRecord.vehicle_name;

      reStructuredBreadCrumb.push({
        clear: [],
        key: vehicleName,
        label: vehicleName,
        options: OPTIONS.extractVehicleOptions(vehicles)
      });

      //Adding section and SubSection to Breadcrumb
      reStructuredBreadCrumb.push({
          clear: [],
          key: section,
          label: capitalize(section),
          options: OPTIONS.extractNestedTypes()
        },
        OPTIONS.extractSectionOptions(section, tabKey)
      );

      //Adding NestedSubSection to Breadcrumb
      reStructuredBreadCrumb.push({
        clear: [],
        key: nestedTabKey,
        label: capitalize(nestedTabKey),
        options: {
          initial: TABS.extractConcentration[0],
          list: TABS.extractConcentration
        }
      });

      navigator.set(reStructuredBreadCrumb)
    }

  }, [redirectionRule]);

  const onSectionChange = (event: any) => {
    if (!event) {
      navigator.clear();
    } else {
      const vehicleOptions = OPTIONS.extractVehicleOptions(vehicles)
      const types = OPTIONS.extractNestedTypes();

      navigator.set([
        {
          clear: [],
          key: vehicleName,
          label: vehicleName,
          options: vehicleOptions
        },
        {
          clear: [],
          key: event,
          label: capitalize(event),
          options: types
        },
        OPTIONS.extractSectionOptions(event)
      ])
    }

    setSection(event);
  };

  const commonProps = {
    redirectionRule,
    section,
    currency,
    isLocked,
    vehicleId,
    vehicleName,
    vehicle: data,
    singleVehicleView,
    onVehicleSelect,
    redirectionSource
  };

  return (
    <div style={isLocked ? {position: "sticky", top: "115px", zIndex: 10} : {}}>
      {!singleVehicleView && <div style={{height: 12}} ref={reference}/>}
      <Header
        {...commonProps}
        extraSpace={extraSpace}
        headerWidth={headerWidth}
        setSection={onSectionChange}>
        {visible && (
          <>
            <Notes
              {...commonProps}
              headerWidth={headerWidth.notes}
              fields={statisticsFields(currency).sections.notes(data.vehicle_identifier === "casavo_iota")}/>
            <Collateral
              {...commonProps}
              headerWidth={headerWidth.collateral}
              fields={statisticsFields(currency).sections.collateral(data.vehicle_identifier === "casavo_iota")}/>
          </>
        )}
      </Header>
    </div>
  );
};

Management.defaultProps = {
  height: 700
};

export default Management;
