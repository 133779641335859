import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const ServicerView = () => {
  return (
    <>
      <CustomImage
        description="Servicer profile allows the User to manage documents: uploading new documents, deciding which
      one to make available/unavailable to Investors, delete, or modify documents."
        src={assets.images.documentsModule.importDocument}
        alt="Servicer View"
      />
      <div>
        <DefinitionList
          header="Document Management Module is organised in 4 main areas:"
          data={[
            {
              term: 'Deal Documents',
              explanation: 'securitisation prospectus, indenture, and other legal documentation'
            },
            {
              term: 'Other Documents',
              explanation: 'documents not already included in other sections'
            },
            {
              term: 'Input Reports',
              explanation: 'database inputs are used to feed CARDO SP and generate reports (STS, Servicer Report, etc.)'
            },
            {
              term: 'Other Reports',
              explanation: 'transaction reports not generated by CARDO AI Securitisation Platform'
            },
          ]}
        />
      </div>
    </>
  );
};

export default ServicerView;
