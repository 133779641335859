import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import FilterByYears from "../filter_by_years";
import { SmartChart } from "../../../../components";
import { RadioGroup, SimpleEmpty } from "@cardoai/components";
//Helpers
import { theme } from "../../../../assets/theme/colors";
import { Analytics, formatters, humanString, isEmpty } from "../../../../helpers";
//Constants
import { datasetKeys, datasetRadioConfig } from "./config";
import { withData } from "../../../../utils";
import { prepareVehicleContentUrl } from "../../../spv/components/management/helpers";

interface IExpectedLossChart {
  data: any
}

const ExpectedLossChart = ({data}: IExpectedLossChart) => {

  const years = Object.keys(data);
  const defaultSelectedYear = years[years.length - 1];

  const [selectedYear, setSelectedYear] = React.useState<any>(defaultSelectedYear);
  const [selectedDataset, setSelectedDataset] = React.useState('all');


  if (!data || isEmpty(data))
    return <SimpleEmpty/>

  const handleYearSelect = (event: any) => setSelectedYear(event);

  let yearLabels = [];
  const labels = [];
  const datasets: any = [];
  let colorIndex = 0;

  /*Determine if the dataset should be crossed out depending on the filters*/
  const isItHidden = (year: any, dataset: any) => {
    if (selectedDataset === 'all' && selectedYear.includes(year))
      return false;
    else
      return !(selectedDataset === dataset && selectedYear.includes(year));
  }

  /*Create Dataset for each Year*/
  years.forEach((currentYear) => {
    datasetKeys.forEach((dataset) => {
      const key = `${currentYear}_${dataset}`
      datasets.push({
        pointRadius: 1,
        identifier: key,
        hidden: isItHidden(currentYear, dataset),
        label: `${currentYear} - ${humanString(key)}`,
        backgroundColor: theme.palette.us[colorIndex++],
        data: []
      })
    })
  })

  /*Populate datasets matching the year with the dataset identifier*/
  for (let mainYear in data) {
    if (data.hasOwnProperty(mainYear)) {
      for (let year in data[mainYear]) {
        yearLabels.push(year);
        const dataSource = data[mainYear][year];
        for (let key in dataSource) {
          if (dataSource.hasOwnProperty(key)) {
            const matchingKey = `${mainYear}_${key}`
            const dataset = datasets.find((item: any) => item.identifier === matchingKey)
            if (dataset) {
              dataset.data.push(dataSource[key])
            }
          }
        }
      }
    }
  }

  /*filter out duplicate Years for labels*/
  for (let year of yearLabels) {
    if (labels.indexOf(year) === -1)
      labels.push(year);
  }

  const filterLegendItems = (legendItem: any) => {
    /*Do not show hidden datasets in the legend*/
    if (legendItem.hidden)
      return null;
    else
      return legendItem;
  }

  const onChangeDataset = (key: any) => {
    setSelectedDataset(key);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: '(HDR user): Changed dataset for losses chart',
      label: key
    });
  }

  return (
    <div>
      <Row justify='start' gutter={8}>
        <Col>
          <RadioGroup
            size='small'
            value={selectedDataset}
            onChange={onChangeDataset}
            entries={datasetRadioConfig}/>
        </Col>
        <FilterByYears optionsConfig={years} selectedYear={selectedYear} handleYearSelect={handleYearSelect}/>
      </Row>
      <SmartChart
        height={400}
        disableLegend
        labels={labels}
        dataSets={datasets}
        innerDatasets={false}
        displayYLines={false}
        displayXLines={false}
        filterLegend={filterLegendItems}
        format={formatters.percentOnePrecision}
        title="Expected vs Actual Loss"
        types={['line']}/>
    </div>
  );
}

export default withData({
  urlFormatter: prepareVehicleContentUrl('/main/vdr/get_loss_time_series'),
  usesDateFilters: false,
  showIntervals: false,
})(ExpectedLossChart);