import React, { useRef, useState } from 'react';
//Other Libs
import { useLocation, useNavigate } from "react-router-dom";
//Components
import { Card, Tree } from "antd";
//Custom Components
import { CustomTitle } from "../index";
//Helpers
import { getKey } from "../../helpers";
import { useAuthorization } from "../../../../utils/hooks";

function initialParameters(location: any, invalidKeys: any) {
  const pathName = location.pathname;
  const expandKeys = [], selectedKeys = [];
  try {
    let paths = pathName.split('/');
    paths = paths.filter((path: any) => !invalidKeys.includes(path));

    if (paths.length === 1) {
      expandKeys.push(paths[0])
      selectedKeys.push(paths[0])
    } else {
      const length = paths.length;
      paths.forEach((path: any, index: any) => {
        if (index === length - 1) {
          selectedKeys.push(path);
          expandKeys.push(path);
        } else
          expandKeys.push(path);
      })
    }
  } catch (e) {
    throw new Error("No path found");
  }

  return {
    expandKeys: expandKeys,
    selectedKeys: selectedKeys
  };
}

interface Props {
  toc: any,
  invalidKeys: any,
  paths: any,
  style: any
}

const TreeView = (props: Props) => {
  const {toc, invalidKeys, paths, style} = props;
  const {defaultPath, homePath} = paths;

  const location = useLocation();
  const navigate = useNavigate();
  const authorization: any = useAuthorization();

  const initialState = initialParameters(location, invalidKeys);
  const searchableList = useRef<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [expandKeys, setExpandedKeys] = useState<any>(initialState.expandKeys);
  const [selectedKeys, setSelectedKeys] = useState<any>(initialState.selectedKeys);
  const [autoExpandParent, setAutoExpandParent] = useState<any>(true);


  React.useEffect(() => {
    navigate(homePath);
  }, [authorization.role])

  React.useEffect(() => {
    if (location.pathname === defaultPath)
      navigate(homePath);

    setExpandedKeys(initialParameters(location, invalidKeys).expandKeys);
    setSelectedKeys(initialParameters(location, invalidKeys).selectedKeys);
  }, [location]);

  const generateList = (data: any) => {
    data.forEach((node: any) => {
      const {key, title, displayTitle, ...rest}: any = node;
      searchableList.current.push({key, title: title, displayTitle, ...rest});
      if (node.children) {
        generateList(node.children);
      }
    })
  };

  React.useEffect(() => {
    generateList(toc);
  }, [toc]);


  const onExpand = (keys: any) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  }

  const onSelect = (selectedKeys: any, info: any) => {
    const url = info.node.url;
    navigate(`${defaultPath}/${url}`);
  }

  const onSearch = (event: any) => {
    const searchValue = event.target.value;
    const getKeys = getKey(searchableList.current, searchValue, toc);
    const expandedKeys = getKeys.filter((item: any, i: any, allKeys: any) => {
      return item && allKeys.indexOf(item) === i
    });
    setSearchTerm(searchValue);
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(true);
  }


  const search = (treeData: any) => {
    return treeData.map((node: any) => {
      const str = node.title;
      const index = str.toLowerCase().indexOf(searchTerm);
      let title = str;
      if (index >= 0) {
        const beforeStr = str.substr(0, index);
        const highlightedPart = str.substr(index, searchTerm.length)
        const afterStr = str.substr(index + searchTerm.length);
        title = <span>{beforeStr}<span className="highlight_text">{highlightedPart}</span>{afterStr}</span>
      }

      if (node.children) {
        return {
          ...node,
          title,
          key: node.key,
          displayTitle: node.title,
          children: search(node.children)
        };
      }
      return {
        ...node,
        title,
        key: node.key,
        displayTitle: node.title,
      };
    });
  }

  return (
    <Card
      style={style}
      size='small'
      title={<CustomTitle value={searchTerm} onChange={onSearch}/>}>
      <Tree
        onSelect={onSelect}
        onExpand={onExpand}
        treeData={search(toc)}
        expandedKeys={expandKeys}
        selectedKeys={selectedKeys}
        autoExpandParent={autoExpandParent}/>
    </Card>
  );
};

export default TreeView;