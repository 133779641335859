import React from 'react';
import { Col, Row } from 'antd';
import Actions from './actions';
import queryString from 'query-string';
import { groupedColumnsInventory } from './config';
import { useProposal } from '../../../../../../context';
import SmartTable from '../../../../../../design/table';
import { useTableFilters } from '../../../../../../design/table/helpers';
import { createFileName, downloadClientFile, useQuery } from '../../../../../../../../helpers';

const Inventory = (props: any) => {
  const { vehicleId, proposalId, fetchProposal } = useProposal();
  const { filters, updateFilters, updateFilter, setPage } = useTableFilters();
  const [groups, setGroups] = React.useState<string[]>(['property_information', 'total']);

  const inventory = useQuery({
    url: () => {
      const query = queryString.stringify({
        ...filters,
        page_size: 16,
        vehicle_id: vehicleId,
      });
      return `/casavo/collateral/${proposalId}/inventory/?${query}&order=renovation_limit`;
    },
    deps: [filters],
  });

  const handleDownload = () => {
    downloadClientFile({
      filename: createFileName('ltc_and_bb', 'csv'),
      url: `/casavo/collateral/inventory/?${queryString.stringify({
        ...filters,
        vehicle_id: vehicleId,
        proposal_id: proposalId,
      })}`,
    });
  };

  const memoizedColumns = React.useMemo(() => {
    const columns: any = [];
    for (let group of groups)
      groupedColumnsInventory[group].forEach((col: any) => columns.push(col));

    return [
      ...columns,
    ];
  }, [groups]);


  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Row justify='end'>
          <Col>
            <Actions
              groups={groups}
              filters={filters}
              setGroups={setGroups}
              updateFilter={updateFilter}
              updateFilters={updateFilters}
              handleDownload={handleDownload} />
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <SmartTable
              pagination={{
                pageSize: 16,
                current: filters.page,
                showSizeChanger: false,
                total: inventory?.data?.count,
                onChange: (event: any) => setPage(event),
              }}
              columns={memoizedColumns}
              loading={inventory?.loading}
              dataSource={inventory?.data?.results}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Inventory;