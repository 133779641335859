import React from 'react';
//Configuration
import { columns } from "../config";
//Icons
import { SearchOutlined } from "@ant-design/icons";
//Components
import SelectWaiverType from "../select_waiver_type";
//Utils
import { mediatorSend, useDebounce } from "@cardoai/utils";
//Other Components
import { Button, Col, Input, Row, Skeleton, Spin } from "antd";
//Context
import { useWaiverContext } from "../calculation_waiver_provider";
//Custom Components
import { SmartTable } from "../../../../../../../../../components";
//Helpers
import { callApi, useQuery } from "../../../../../../../../../helpers";
import { WAIVER_TYPE } from "../../create_waiver_request/create_waver_request";

interface BodyData {
  limit?: number | string,
  waiver_type: string,
  loans: any,
  reason?: any,
  start_date?: any,
  end_date?: any,
}

interface Props {
  limitId: number | string,
  vehicleId: number | string,
  reason: string,
  startDate: any,
  endDate: any,
}

const LoanWaiver = ({
                      limitId,
                      vehicleId,
                      reason,
                      startDate,
                      endDate,
                    }: Props) => {

  const [displayModal, setDisplayModal] = React.useState(false);
  const [searchTradeId, setSearchTradeId] = React.useState<string>('');
  const [searchCompany, setSearchCompany] = React.useState('');
  const searchTrade = useDebounce(searchTradeId, 1000);
  const searchCompanyName = useDebounce(searchCompany, 1000);

  const waiverContext: any = useWaiverContext();

  const dataCalculation = useQuery({
    deps: [searchTrade, searchCompanyName, vehicleId],
    url: () => `main/limit_waiver/get_list_of_loans_to_waiver/?page=1&page_size=100&vehicle_id=${vehicleId}&loan_identifier=${searchTrade}&company=${searchCompanyName}`,
  });

  if (!dataCalculation.data)
    return <Skeleton/>

  const finalData = dataCalculation.data.results;

  const handleSearchTradeID = (event: any) => {
    setSearchTradeId(event.target.value);
  }

  const handleSearchCompany = (event: any) => {
    setSearchCompany(event.target.value)
  }

  const onConfirm = () => {
    const body: BodyData = {
      limit: limitId,
      waiver_type: WAIVER_TYPE.CALCULATION_EXCLUSION,
      loans: waiverContext.getLoans(),
    };

    if (reason !== '') body['reason'] = reason;
    if (startDate !== '') body['start_date'] = startDate.format("YYYY-MM-DD");
    if (endDate !== '') body['end_date'] = endDate.format("YYYY-MM-DD");

    return callApi({
      url: `main/limit_waiver/?vehicle_id=${vehicleId}`,
      method: 'POST',
      body: body,
      onSuccess: () => {
        // todo replace with invalidate query
        mediatorSend('Limits', 'refreshLimits', undefined);
      },
      successMessage: "Waiver submitted for approval!"
    });
  }

  const openModal = () => {
    setDisplayModal(true);
  }

  const onCancel = (value: any) => {
    waiverContext.updateCalculationWaiver(value);
    setDisplayModal(false);
  }

  return (
    <div>
      <Row justify="end">
        <Col span={6}>
          <Input
            size="middle"
            autoFocus
            allowClear
            style={{width: 200}}
            value={searchTradeId}
            onChange={handleSearchTradeID}
            prefix={<SearchOutlined/>}
            placeholder="Search for Asset ID"
          />
        </Col>
         <Col span={5}>
          <Input
            size="middle"
            autoFocus
            allowClear
            style={{width: 193.5}}
            value={searchCompany}
            onChange={handleSearchCompany}
            prefix={<SearchOutlined/>}
            placeholder="Search by Debtor/VAT"
          />
        </Col>
      </Row>
      <Spin spinning={dataCalculation.loading}>
        <SmartTable
          height={500}
          columns={columns}
          externalData={finalData}
        />
      </Spin>
      <Row justify="space-between" className="mt16">
        <Button
          onClick={openModal}
          type="primary"
          disabled={waiverContext.loans.length === 0}
        >
          Confirm
        </Button>
        <SelectWaiverType
          showModal={displayModal}
          onCancel={onCancel}
        />
        <Button
          onClick={onConfirm}
          type="primary"
        >
          Submit
        </Button>
      </Row>
    </div>
  );
};

export default LoanWaiver;
