import React, {useState} from 'react';
import {Button, Col, Modal, Row} from "antd";
import {DownloadAsCSV, SmartTable} from "../../../../../../../../../../components";
import { fourDigitLetterATECOInfoColumns } from "./config";
import {downloadClientFile,createFileName} from "../../../../../../../../../../helpers";

const Ateco = ({ record, currency,vehicleId,proposalId,urlParam}: Props) => {
  const [loading, setLoading] = useState(false);
  const data = record.four_digit_letter_ateco_information;

  const atecoLimitsUrl = urlParam !== "limits" ? "proposal_limits" : "limits";

  const downloadUrl =  urlParam !== "limits"
    ? `mcs/${atecoLimitsUrl}/download_four_digit_ateco_limits/?vehicle_id=${vehicleId}&proposal_id=${proposalId}&ateco_letter=${record.name}`
    : `/mcs/${atecoLimitsUrl}/download_four_digit_ateco_limits/?vehicle_id=${vehicleId}&ateco_letter=${record.name}`

  const onDownload = () => {
    downloadClientFile({
      url: downloadUrl,
      filename: createFileName("Ateco Limits", "csv"),
      setLoading
    })
  }

  return <>
    <Row justify="end">
      <Col >
        <DownloadAsCSV loading={loading} onDownload={onDownload} />
      </Col>
    </Row>
    <SmartTable
      externalData={data ?? []}
      columns={fourDigitLetterATECOInfoColumns(currency)}
      height={window.innerHeight - 250}
    />
  </>;
}

interface Props {
  record: any,
  currency: any,
  vehicleId:any,
  proposalId:any,
  urlParam:any
}

const FourDigitLetterInfo = ({ record, currency,vehicleId,proposalId,urlParam }: Props) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const showModal = () => {
    setVisible(true);
  }

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} disabled={record.four_digit_letter_ateco_information == null} >
        Subcategories
      </Button>
      <Modal
        visible={visible}
        title="Four Digit Letter Ateco Information"
        onCancel={onCancel}
        footer={null}
        width={800}
      >
        <Ateco
          record={record}
          currency={currency}
          proposalId={proposalId}
          vehicleId={vehicleId}
          urlParam={urlParam}
        />
      </Modal>
    </>
  );
};

export default FourDigitLetterInfo;
