import React, { ReactNode } from 'react';
//Components
import { Card } from "antd";
import { FeedBack } from "../index";
//Styles
import styles from "./styles";

interface ILayout {
  config: any,
  children: ReactNode,
  style: any
}

const Layout: React.FC<ILayout> = (props: ILayout) => {
  const {config, children, style} = props;

  return (
    <Card
      size="small"
      style={style}
      title={<h2>Securitization Platform Documentation - {config?.title}</h2>}>
      <section style={styles.content}>
        {children}
      </section>
      <section>
        <FeedBack/>
      </section>
    </Card>

  );
};

export default Layout;