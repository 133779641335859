import React from 'react';
//Components
import { Col, Row } from "antd";
import { Panel, SimpleEmpty } from "@cardoai/components";
import { LimitsPieChart, LTVCheck, PortfolioCheck } from "./components";

const LimitsCategories: any = {
  "Portfolio check": PortfolioCheck,
  "Pre-draw LTV check": LTVCheck,
}

const emptyView = <SimpleEmpty description="No Data Available Yet"/>;

const AspireLimits = (props: any) => {
  const {data, collapsible, type, ...rest} = props;


  const limits = data.allLimits;
  const defaultView = type === "default";

  /*Return empty view for aspire if there is no data in limits; check if the limits' structure is both an array and object*/

  if (Array.isArray(data)) {
    if (!data || !data.length)
      return emptyView;
  } else if (typeof data === "object") {
    if (!data || !data.allLimits || !data.allLimits.length)
      return emptyView;
  }

  const records = limits.map((limit: any, index: number) => {
    const header = limit.category;
    const Component = LimitsCategories[header]; /*Match the limit group/category with the specific component to return*/
    return (
      <Panel
        padding={0}
        header={header}
        extraSpace={false}
        defaultOpen={index === 0}
        collapsible={collapsible}>
        <Component data={limit.records} {...rest}/>
      </Panel>
    )
  });

  const category = limits.find((limit: any) => limit.category === "Pre-draw LTV check");

  function extractParam(param: string) {
    return Number(category.records.find((r: any) => r.name === param).fulfillment);
  }

  return (
    <Row align="middle">
      <Col xs={defaultView ? 16 : 24}>
        {records}
      </Col>
      {defaultView && <Col xs={8}>
        <Row justify="center">
          <Col>
            <LimitsPieChart data={data.allLimits} params={{
              ltv: extractParam("LTV"),
              targetLtv: extractParam("Target LTV")
            }}/>
          </Col>
        </Row>
      </Col>}
    </Row>
  )
}


AspireLimits.defaultProps = {
  collapsible: true,
  defaultView: true
};

export default AspireLimits;
