import React from 'react';
//Styled Components
import styled from "styled-components";

interface ChartContentProps {
  height?: any,
  fixedDimensions?: any,
  children?: any
}

const Styles = styled.div`
  width: 100%;

  .chart-container {
    position: relative;
  }
`;
/*
Wrapper can be used only if  these graph options are set as :
 maintainAspectRatio: false,
 responsive: true
*/
const ChartContent = (props: ChartContentProps) => {
  const {height, fixedDimensions, children} = props;
  const style: any = {};

  if (height)
    style.height = height;

  if (fixedDimensions)
    style.width = height;

  return (
    <Styles className="chart-container" style={style}>
      {children}
    </Styles>
  );
};

export default ChartContent;
