import React from "react";
//Custom Components
import { Graph } from "../../../../../components";
import DelaysSingleDataset from "./single_dataset";
//Custom Helpers
import { getChartFormatter, prepareConfig } from "../helpers";
import { redirectionMapOfDatasets } from "../config";
//Helpers
import { formatters, isVDRUser, MathOp } from "../../../../../helpers";
//Hooks
import { useRedirection } from "../../../../../utils/hooks";

interface IDelaysChart {
  data: any,
  currency?: any
  queryStr?: string | undefined, //from analytics
  vdrUser?: boolean,
  height?: number,

  vehicle?: any,
  externalData?: any,
  redirectSourceDelays?: any,
  vehicleId?: any,
}

const DelaysChartContent = (props: IDelaysChart) => {

  const {data, currency, height, vehicle, externalData, redirectSourceDelays, vehicleId, vdrUser, ...rest} = props;

  const {setRedirection}: any = useRedirection();

  const [distributionType, setDistributionType] = React.useState<any>('amount');

  const onDistributionChange = (current: any) => {
    setDistributionType(current)
  }

  const dataSets = prepareConfig(vdrUser);
  const finalData = externalData ? externalData : data;
  const chartFormat = getChartFormatter(distributionType, currency);

  const configurations: any = {
    labels: [],
    dataSets: [],
  }

  for (let date in finalData) {
    if (finalData.hasOwnProperty(date)) {
      let label: any = formatters.shortedDate(date);
      configurations.labels.push(label);
      const record = finalData[date];
      dataSets.forEach(dataSet => {
        const balance = record.late_balance || 0;
        let value: any = record[dataSet.identifier];
        if (distributionType === 'percentage' && balance !== 0)
          value = MathOp.divide(value, balance);
        dataSet.data.push(value)
      })
    }
  }
  configurations.dataSets = dataSets;


  /*Redirection from the graph to loans_management spv_management*/
  const onDatasetClick = (record: any) => {
    const {dataSetIndex} = record;
    const label = dataSets[dataSetIndex].label;
    const datasetLabel = redirectionMapOfDatasets[label];

    if (redirectSourceDelays) {
      setRedirection({
        path: '/loans',
        config: {
          rules: {
            performanceStatus: {
              selected: true,
              value: [datasetLabel]
            },
            selectedVehicles: {
              selected: true,
              value: [vehicleId]
            }
          },
          source: redirectSourceDelays
        }
      })
    }
  };

  const singleDataset = data && Object.keys(data).length === 1;

  return (
    <>
      {(singleDataset && isVDRUser()) ?
        <DelaysSingleDataset onClick={onDatasetClick} dataSets={dataSets} format={chartFormat} currency={currency}/> :
        <Graph
          usesMenu
          stacked
          beginAtZero
          displayLegend
          displayYLines
          allowSnapshot
          height={height}
          tooltipIndexMode
          allowDistribution
          format={chartFormat}
          displayXLines={false}
          extraStyle={{top: -32}}
          initialDistribution="amount"
          title="Days Past Due Distribution"
          types={['bar', 'line']}
          onDistributionChange={onDistributionChange}
          onPointClick={onDatasetClick}
          {...rest}
          {...configurations}
        />}

    </>
  )
}

DelaysChartContent.defaultProps = {
  height: 400,
  vdrUser: false
}

export default DelaysChartContent;
