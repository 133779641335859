import React from "react";
//Components
import { SmartTable } from "../../../../../../../../../../../components";
import { Button, Col, Popover, Row, Skeleton } from "antd";
//Icons
import { InfoCircleOutlined, SnippetsOutlined } from "@ant-design/icons";
//Helpers
import { Analytics, generateUrl, isEmpty, useQuery } from "../../../../../../../../../../../helpers";
import { sellerContent, sellerTable } from "../../config";

const SellerLimits = ({urlParam, vehicleId, proposalId, activeSection, onSectionChange, currency}: any) => {

  const {data} = useQuery({
    url: generateUrl(`/mcs/${urlParam}/seller_limits`, {
      page: 1,
      page_size: 20,
      vehicle_id: vehicleId,
      proposal_id: proposalId
    })
  })

  if (!data)
    return <Skeleton/>


  const finalData = data.results;


  const handleSectionChange = () => {
    if (activeSection === 'seller')
      return onSectionChange(null)

    Analytics.dispatchEvent({
      category: 'User',
      action: 'Clicked `Show More` button at seller table for proposal limits',
      label: 'seller'
    })

    return onSectionChange('seller');
  }

  const buttonType = activeSection === 'seller' ? "primary" : "default";

  return (
    <>
      <Row justify="space-between">
        <Col className="flexDisplay">
          <h3>Seller</h3>
          <Popover content={sellerContent} title="Seller Limit" trigger="click">
            <InfoCircleOutlined className="ml8" style={{color: '#1890FF'}}/>
          </Popover>
        </Col>
        <Col>
          {!isEmpty(data) &&
            <Button icon={<SnippetsOutlined/>} onClick={handleSectionChange} size="small" type={buttonType}>
              Show more
            </Button>}
        </Col>
      </Row>
      <SmartTable
        bordered
        height={635}
        externalData={finalData}
        columns={sellerTable(currency)}
      />
    </>
  )
}

export default SellerLimits;