import React from "react";
//Constants
import { theme } from "../../../../../../assets/theme/colors";
//Configurations
import { setSticky } from "../configurations";

interface IBodyCell {
  index?: any,
  position?: any,
  children?: any,
}

const BodyCell = (props: IBodyCell) => {
  const {index} = props;
  return (
    <td key={index}
        className="borderBottom"
        style={{
          left: index * 100,
          position: setSticky(index),
          backgroundColor: setSticky(index) ? theme.palette.grayscale[10] : '',
          zIndex: 5,
        }}>
      {props.children}
    </td>
  )
}

export default BodyCell;