import React, {createContext} from "react";
//Other Libs
import queryString from "query-string";
import {Navigate} from "react-router-dom";
//System Routes
import routes from "../../../routes/config";
import {routeKey, specialRoutesKeys} from "../../../constants";
//Hooks
import {useAuthentication} from "../../hooks";
//Services
import {authStorage, callApi, callIdpApi, client} from "../../../helpers";
import {CURRENT_NAMESPACE} from "../../../settings";
import {notifications} from "../../../components";

const defaultAllowedMenus = [
    routeKey.documentation,
    routeKey.dashboard,
    routeKey.hdrDocumentation,
    routeKey.collectionManagement,
    routeKey.questionsAndAnswers,
    routeKey.syncReports,
];

const demoMenus = [routeKey.collateral];

//Constants
const AuthorizationContext = createContext({});

const extractGroups = (features: any) => {
    const finalGroups: any = {};
    for (let key in features) {
        if (features.hasOwnProperty(key)) {
            /* Make use of default since no other tenant is present */

            const groups = features[key].default;

            for (let group in groups) {
                if (groups.hasOwnProperty(group)) {
                    const permissions = groups[group];

                    if (!finalGroups.hasOwnProperty(group)) {
                        finalGroups[group] = {
                            actions: {},
                            menus: [],
                        };
                    }

                    const groupPermissions = finalGroups[group];

                    permissions.menus.forEach((menu: any) => {
                        if (!groupPermissions.menus.includes(menu))
                            groupPermissions.menus.push(menu);
                    });

                    Object.keys(permissions.actions).forEach((action) => {
                        if (!groupPermissions.actions.hasOwnProperty(action))
                            groupPermissions.actions[action] = permissions.actions[action];
                    });
                }
            }
        }
    }
    return finalGroups;
};

const getInitialRole = (permissions: any, groups: any, cacheRole: any) => {
    const namespaceGroups = Object.keys(permissions[CURRENT_NAMESPACE].default);
    return groups.includes(cacheRole) ? cacheRole : namespaceGroups[0];
};

const AuthorizationProvider = ({children, ...rest}: any) => {
    const interceptor: any = React.useRef(null);
    const authentication = useAuthentication();
    const [key, setKey] = React.useState(1);
    const [role, setRole] = React.useState<any>(null);
    const [idpUser, setIdpUser] = React.useState<any>(null);
    const [originatorInstances, setOriginatorInstances] =
        React.useState<any>(null);

    const changeKey = () => {
        setKey((key) => key + 1);
    };

    React.useEffect(() => {
        return () => {
            if (interceptor.current)
                client.interceptors.request.eject(interceptor.current);
        };
    }, []);

    React.useEffect(() => {
        callIdpApi({
            url: "users/me/",
            onSuccess: (record: any) => {
                const permissions = record.permissions;

                if (permissions) {
                    const availableNameSpaces = Object.keys(permissions);

                    if (!availableNameSpaces.includes(CURRENT_NAMESPACE)) {
                        authentication.onLogout();
                        notifications.warning("You do not have access to this namespace!");
                    }

                    const groups = extractGroups(permissions);

                    const groupNames = Object.keys(groups);

                    const cacheRole: any = authStorage.getRole();

                    const initialRole = getInitialRole(
                        permissions,
                        groupNames,
                        cacheRole
                    );

                    setIdpUser(record);
                    setRole(initialRole);
                }
            },
        });
    }, []);

    React.useEffect(() => {
        if (role) {
            authStorage.setRole(role);

            if (interceptor.current)
                client.interceptors.request.eject(interceptor.current);

            interceptor.current = client.interceptors.request.use(onRoleChange);

            callApi({
                url: `/main/originator_instance/`,
                onSuccess: (response: any) => {
                    changeKey();
                    setOriginatorInstances(response);
                },
            });
        }
    }, [role]);

    const onRoleChange = (request: any) => {
        let currentUrl = request.url;

        currentUrl = queryString.exclude(currentUrl, ["role"]);

        if (!currentUrl.includes("?")) currentUrl += "?";

        request.url = currentUrl + `&role=${role}`;

        return request;
    };

    if (!idpUser || !role || !interceptor.current || !originatorInstances)
        return null;

    const groups: any = extractGroups(idpUser.permissions);

    const permissions = groups[role];

    if (!permissions) authentication.onLogout();

    const isDemoUser = () => idpUser?.username === "demo";

    const canOriginatorAccessRoute = (key: any) => {
        if (!originatorInstances) return false;

        for (let instance of originatorInstances) {
            const functionalities = instance.functionalities_config;
            const supportingMenu = `supports_${key}`;
            if (
                functionalities.hasOwnProperty(supportingMenu) &&
                functionalities[supportingMenu] === true
            )
                return true;
        }
        return false;
    };

    const canRenderRoute = (key: any) => {
        const isRouteSpecial = specialRoutesKeys.includes(key);

        const isAllowedByUser = permissions.menus.includes(key);
        const isAllowedByDefault = defaultAllowedMenus.includes(key);

        if (demoMenus.includes(key) && isDemoUser()) return true;

        if (isRouteSpecial || isAllowedByUser) return true;

        if (!isAllowedByUser) return false;

        return isAllowedByDefault || canOriginatorAccessRoute(key);
    };

    const extractRoutes = () => {
        return routes.filter((route: any) => canRenderRoute(route.key));
    };

    const canPerformAction = (key: any, id: any) => {
        const validator = permissions.actions[key];

        if (typeof validator === "object") return validator.vehicle.includes(id);

        if (Array.isArray(validator)) return validator.includes(id);

        return validator;
    };

    if (idpUser && idpUser.has_consented_cookies === null)
        return <Navigate replace to="/cookie_consent" state={{user: idpUser}}/>;

    return (
        <AuthorizationContext.Provider
            value={{
                role,
                setRole,
                isDemoUser,
                user: idpUser,
                groups: groups,
                originatorInstances,
                permissions: permissions,
                extractRoutes: extractRoutes,
                canRenderRoute: canRenderRoute,
                canPerformAction: canPerformAction,
                ...rest,
            }}
        >
            <section key={key}>{children}</section>
        </AuthorizationContext.Provider>
    );
};

export {AuthorizationContext, AuthorizationProvider};
