import React from 'react';
//Components
import { HorizontalCollapse } from "@cardoai/components";
import AccountsTables from "./components/accounts_tables";

const AspireAccounts = (props: any) => {
  const {currencies, vehicleId, date} = props;
  return (
    <section>
      <HorizontalCollapse
        height={850}
        activePanel="both"
        nodes={[
          {
            text: "Data in SGD",
            content: <AccountsTables currencies={currencies} currency="SGD" vehicleId={vehicleId} date={date}/>
          },
          {
            text: "Data in USD",
            content: <AccountsTables currencies={currencies} currency="USD" vehicleId={vehicleId} date={date}/>
          }
        ]}/>
    </section>

  );
};

export default AspireAccounts;