import React from "react";
//Icons
import {
  AuditOutlined,
  BarChartOutlined,
  BoxPlotOutlined,
  CodeSandboxOutlined,
  ContainerOutlined,
  DashboardOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  FileExclamationOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SnippetsOutlined,
  SyncOutlined,
  WalletOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
//Containers
import SyncsContainer from "../containers/syncs";
import FundingManager from "../containers/funding_manager"
import DashboardContainer from "../containers/dashboard";
import AnalyticsContainer from "../containers/analytics";
import DocumentsContainer from "../containers/documents";
import LoansManagementContainer from "../containers/loans";
import SimulationContainer from "../containers/simulation";
import CollectionContainer from "../containers/collection";
import SPVManagementContainer from "../containers/spv";
import ExcelZillaContainer from "../containers/excel_zilla";
import HdrHelpContainer from "../containers/help/hdr_help";
import HelpContainer from "../containers/help/general_help";
import NotesManagerContainer from "../containers/notes_manager";
import HyperDataRoomContainer from "../containers/hyper_data_room";
import HdrDocumentsContainer from "../containers/documents/hdr_documents";
import RealEstateManagement from "../containers/real_estate";
import QuestionsAndAnswers from "../containers/questions_and_answers";
//NPL Modules
import NPLHDR from "../containers/npl_feature/npl_hdr";
import NPLModule from "../containers/npl_feature/npl_module";
import { routeConfiguration, routeKey } from "../constants";
import CollateralManagement from "../containers/collateral";
import SyncReports from "../containers/sync_reports";

const routes: any = [
  {
    key: routeKey.dashboard,
    icon: <DashboardOutlined />,
    component: DashboardContainer,
  },
  {
    key: routeKey.spvManagement,
    icon: <ContainerOutlined />,
    component: SPVManagementContainer,
  },
  {
    icon: <WalletOutlined />,
    key: routeKey.notesManager,
    component: NotesManagerContainer,
  },
  {
    icon: <HomeOutlined />,
    key: routeKey.realEstateManagement,
    component: RealEstateManagement,
  },
  {
    icon: <AuditOutlined />,
    key: routeKey.collateral,
    component: CollateralManagement,
  },
  {
    icon: <BoxPlotOutlined />,
    key: routeKey.hyperDataRoom,
    component: HyperDataRoomContainer,
  },
  {
    key: routeKey.loans,
    icon: <SnippetsOutlined />,
    component: LoansManagementContainer,
  },
  {
    key: routeKey.analytics,
    icon: <BarChartOutlined />,
    component: AnalyticsContainer,
  },
  {
    key: routeKey.simulation,
    icon: <FundProjectionScreenOutlined />,
    component: SimulationContainer,
  },
  {
    key: routeKey.collectionManagement,
    icon: <CodeSandboxOutlined />,
    component: CollectionContainer,
  },
  {
    key: routeKey.excelGenerator,
    icon: <FileSearchOutlined />,
    component: ExcelZillaContainer,
  },
  {
    key: routeKey.documents,
    component: DocumentsContainer,
    icon: <FolderOpenOutlined />,
  },
  {
    icon: <BoxPlotOutlined />,
    key: routeKey.nplHDR,
    component: NPLHDR,
  },
  {
    key: routeKey.nplModule,
    icon: <FileExclamationOutlined />,
    component: NPLModule,
  },
  {
    key: routeKey.hdrDocuments,
    component: HdrDocumentsContainer,
    icon: <FolderOpenOutlined />,
  },
  {
    key: routeKey.synchronization,
    icon: <SyncOutlined />,
    component: SyncsContainer,
  },
  {
    key: routeKey.syncReports,
    component: SyncReports,
    icon: <FileSyncOutlined />,
  },
  {
    component: HelpContainer,
    key: routeKey.documentation,
    icon: <ReadOutlined />,
  },
  {
    key: routeKey.hdrDocumentation,
    component: HdrHelpContainer,
    icon: <ReadOutlined />,
  },
  {
    key: routeKey.questionsAndAnswers,
    component: QuestionsAndAnswers,
    icon: <QuestionCircleOutlined />,
  },
  {
    key: routeKey.casavo_theta_notes_manager,
    component: FundingManager,
    icon: <WalletOutlined />,
  },
];

export default routes.map((route: any) => {
  const foundRoute = routeConfiguration.find(
    (config: any) => config.key === route.key
  );
  return {
    ...route,
    ...foundRoute,
  };
});
