import React from "react";
//Custom Components
import { SmartTable } from "../../../../../../components";
//Configurations
import config from "../../../configurations";
import {tableCashFlowTheta} from "../../../configurations/theta_table_columns";

const CashFlowTable = ({filters}: any) => {
  return (
    <SmartTable
      height={500}
      columns={tableCashFlowTheta}
      filters={filters}
      url={config.cashflowsTheta.endpoints.tableUrl}
    />
  )
}
export default CashFlowTable;