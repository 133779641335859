import React from 'react';
//Components
import { Col, Row } from "antd";
import NewLoans from "./new_loans";
import ExistingLoans from "./existing_loans";
//Helpers
import { filterRecords, getNotionalSum, isEligibleCriteria } from "./helpers";

const NewLoanLimits = (props: any) => {
  const {existingLoansData, newLoansData, currency} = props;

  return (
    <Row>
      <Col xs={10}>
        <ExistingLoans currency={currency} data={{
          records: existingLoansData,
          notionalSum: getNotionalSum(existingLoansData)
        }}/>
      </Col>
      <Col xs={14}>
        <NewLoans
          currency={currency}
          data={{
            records: filterRecords(newLoansData),
            isEligible: isEligibleCriteria(filterRecords(newLoansData).newLoans)
          }}
        />
      </Col>
    </Row>
  );
};

export default NewLoanLimits;