import React, { useEffect, useLayoutEffect } from 'react';
//Other Libraries
import queryString from "query-string";
//Icons
import { SearchOutlined } from '@ant-design/icons';
//Components
import { Col, Input, Row, Select } from 'antd';
import { HorizontalCollapse, RadioGroup } from "@cardoai/components";
import LoanDetails from "../../../../../loans/components/loan_details/loan_details";
import { DateSelector, DownloadAsCSV, SmartTable } from "../../../../../../components";
import CashFlowTimeLine from "./cashflow_timeline";
//Constants
import { collateralCashFlowColumns, CONFIG, getEntity } from "./config";
//Context
import { Consumer } from "../../../../context";
//Helpers
import {
  createFileName,
  debounce,
  downloadClientFile,
  formatServerDate,
  formatters,
  getRangePeriod,
  isVDRUser
} from "../../../../../../helpers";
import {
  trackCashflowFormat,
  trackCashflowSelection,
  trackDateFilters,
  trackDownloadExcel,
  trackSearchOfCashflows
} from "./analytics_helpers";
import { useAuthorization } from "../../../../../../utils/hooks";

interface Props {
  vehicleId: any,
  type: any,
  cashflowList: any,
  currency?: any,
}

const CashFlowCollateral = (props: Props) => {
  const {role}: any = useAuthorization();

  const {vehicleId, type, cashflowList, currency} = props;

  const reference = React.useRef(null);

  const initialRange = getRangePeriod(3, 'months');

  const [filters, setFilters] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [dateRange, setDateRange] = React.useState(initialRange);
  const [selectedLoan, setSelectedLoan] = React.useState(null);
  const [cashFlowTypes, setCashFlowTypes] = React.useState([]);
  const [cashFlowFormat, setCashFlowFormat] = React.useState("daily");

 // useLayoutEffect(() => {
 //   console.log('rendering...')
 //   if (role === "ServicerVDR" || role === "InvestorVDR") {
 //     const otherColumns ={
 //       key: "expected_loss",
 //       label: "Expected Loss",
 //       format: (value: any, rest: any) => formatters.currency({value, symbol: rest.currency_symbol}),
 //       sortable: true
 //     }
 //     // collateralCashFlowColumns.daily[5] = otherColumns;
 //     // collateralCashFlowColumns.monthly[4] = otherColumns;
 //     collateralCashFlowColumns.daily= otherColumns ;
 //     collateralCashFlowColumns.monthly =otherColumns ;
 //   }
 //   // else {
 //   //   if(!!collateralCashFlowColumns.daily[5]) {
 //   //     collateralCashFlowColumns.daily.pop();
 //   //   }
 //   //   if(!!collateralCashFlowColumns.monthly[4]) {
 //   //     collateralCashFlowColumns.monthly.pop();
 //   //   }
 //   // }
 //   console.log(collateralCashFlowColumns);
 // },[role])


  const handleDateChange = (key: any) => {
    const period = key;
    setDateRange(period);
    const dateParams: any = {};
    if (period && period.length) {
      const [fromDate, toDate] = key;
      dateParams.from_date = formatServerDate(fromDate);
      dateParams.to_date = formatServerDate(toDate);
    }
    const queryStr = queryString.stringify(dateParams);
    trackDateFilters(queryStr)
    return setFilters(queryStr)
  }


  const handleCashFlowType = (key: any) => {
    const cashFlowTypes = key;
    setCashFlowTypes(cashFlowTypes);

    if (cashFlowTypes && cashFlowTypes.length) {
      trackCashflowSelection(cashFlowTypes);
      return setFilters(queryString.stringify({cash_flow_type: cashFlowTypes}))
    } else
      return setFilters(queryString.stringify({cash_flow_type: []}))
  }

  const handleCloseModal = () => {
    setSelectedLoan(null);
  }

  const downloadCSV = (query?: any) => {
    const url = getEntity("downloadUrl", type)[cashFlowFormat];
    downloadClientFile({
      setLoading,
      url: `${url}/?&${filtersQuery()}${query}`,
      filename: createFileName(`cashflows-collateral-${cashFlowFormat}`, 'csv')
    })
    trackDownloadExcel(cashFlowFormat);
  };

  const onClick = (loanId: any) => () => {
    if (loanId)
      setSelectedLoan(loanId);
  }

  const handleSearch = debounce(200, (event: any) => {
    const value = event.target.value;
    trackSearchOfCashflows(value)
    return setFilters(queryString.stringify({search: value}))
  })

  const filtersQuery = () => {
    let finalFilters = filters;
    const str = `vehicle_id=${vehicleId}`;
    if (finalFilters)
      finalFilters += `&${str}`;
    else
      finalFilters = str;
    return finalFilters;
  }


  const url = getEntity("url", type)[cashFlowFormat];
  const columns = collateralCashFlowColumns[cashFlowFormat];
  const downloadUrl = getEntity("downloadUrl", type);
  const initialSort = getEntity("initialSort", type);

  const disableDownload = !downloadUrl;

  const onCashFlowFormatChange = (value: any) => {
    trackCashflowFormat(value);
    setCashFlowFormat(value);
  }

  if (role === "ServicerVDR" || role === "InvestorVDR") {
    const otherColumns ={
      key: "expected_loss",
      label: "Expected Loss",
      format: (value: any, rest: any) => formatters.currency({value, symbol: rest.currency_symbol}),
      sortable: true
    }
    collateralCashFlowColumns.daily[5] = otherColumns
    collateralCashFlowColumns.monthly[4] = otherColumns
  } else
  {
    if(!!collateralCashFlowColumns.daily[5]) collateralCashFlowColumns.daily.pop();
    if (!!collateralCashFlowColumns.monthly[4]) collateralCashFlowColumns.monthly.pop();
  }

  const table = (
    <>
      <Row justify="end" style={{marginBottom: 10}}>
        <Col>
          <RadioGroup
            size='small'
            value={cashFlowFormat}
            onChange={onCashFlowFormatChange}
            entries={CONFIG.collateral.cashFlowFormat}/>
        </Col>
      </Row>
      <Row align="middle" justify="end" gutter={8}>
        <Col>
          <Input
            allowClear
            size='small'
            onChange={handleSearch}
            suffix={<SearchOutlined/>}
            placeholder="Search Loan ID"
            disabled={cashFlowFormat === "monthly"}/>
        </Col>
        <Col>
          <Select
            mode="multiple"
            size='small'
            value={cashFlowTypes}
            placeholder="Filter by Cash Flow Type"
            onChange={handleCashFlowType}
            style={{minWidth: 220}}>
            {cashflowList?.map((cashFlow: any) => (
              <Select.Option key={cashFlow.name} value={cashFlow.name}>
                {cashFlow.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <DownloadAsCSV disabled={disableDownload} loading={loading} onDownload={downloadCSV}/>
        </Col>
      </Row>
      <SmartTable
        url={url}
        height={350}
        columns={columns}
        loading={loading}
        dataId="cardo_loan_id"
        onClick={onClick}
        ref={reference}
        initialSort={initialSort}
        filters={filtersQuery()}
        cancelPreviousCalls={true}
      />
      <LoanDetails
        visible={!!selectedLoan}
        loanId={selectedLoan}
        onClose={handleCloseModal}/>
    </>
  );

  const chart = (
    <div style={{marginTop: 74}}>
      <CashFlowTimeLine
        download={true}
        currency={currency}
        dateRange={dateRange}
        vehicleId={vehicleId}/>
    </div>
  )

  return (
    <>
      <DateSelector
        range
        value={dateRange}
        initialInterval={3}
        endOfMonthFormat={false}
        onChange={handleDateChange}/>
      <HorizontalCollapse
        nodes={[
          {
            text: "Cashflow Timeline",
            content: chart
          },
          {
            text: "Cashflow Table",
            content: table
          }
        ]}/>
    </>
  )

}

export default Consumer(CashFlowCollateral)
