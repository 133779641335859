import React from 'react';
//Components
import {Row, Col, Button, Tag} from "antd";
import HandShake from "../../../../icons/hand_shake";
import {callApi} from "../../../../../../helpers";
import {useProposal} from "../../../../context";
import { useAuthorization } from '../../../../../../utils/hooks';
import { actionKey } from '../../../../../../constants';

const Header = () => {
    const authorization: any = useAuthorization();
    const {proposalId, vehicleId, toggleProgress, onClose} = useProposal();

    const execute = (status: string) => {
        callApi({
            body: {},
            method: "put",
            setLoading: toggleProgress,
            onSuccess: () => onClose(),
            url: `/main/notes_manager/${proposalId}/${status}/?vehicle_id=${vehicleId}`
        })
    }

    const handleReject = () => execute("reject");

    const handleConfirm = () => execute("approve")

    const canApproveProposal = authorization.canPerformAction(actionKey.approveProposal, vehicleId)
    const canRejectProposal = authorization.canPerformAction(actionKey.rejectProposal, vehicleId)
    const canApproveOrReject = canApproveProposal || canRejectProposal

    return (
        <Row
            align="middle"
            justify="space-between"
            style={{padding: 16, borderRadius: '12px', border: '1px solid #E3DDE6'}}>
            <Col>
                <Row gutter={8}>
                    <Col>
                        <HandShake/>
                    </Col>
                    <Col>
                        <Row gutter={[8, 8]}>
                            <Col xs={24}>
                                <Row gutter={8}>
                                    <Col style={{fontWeight: 500}}>
                                        Funding Proposal
                                    </Col>
                                    <Col>
                                        <Tag style={{color: '#FFB701'}} color="#FFF5DD">In progress</Tag>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} style={{
                                color: '#8F8796',
                                fontWeight: 400,
                                fontSize: 12
                            }}>
                                Submit founding proposal to the investor
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            {canApproveOrReject && <Col>
                <Row gutter={16}>
                    <Col>
                        <Button
                          onClick={handleReject}
                          type='primary'
                          style={{
                              background: '#FF4C55',
                              borderColor: '#FF4C55',
                          }}>
                            Reject Proposal
                        </Button>
                    </Col>
                    <Col>
                        <Button
                          type='primary'
                          onClick={handleConfirm}
                        >
                            Confirm Proposal
                        </Button>
                    </Col>
                </Row>
            </Col>}
        </Row>
    );
};

export default Header;