import React from "react";

const useTableFilters = () => {

    const [filters, setFilters] = React.useState({
        page: 1
    });

    const updateFilters = (changes: any) => {
        setFilters((previous: any) => ({
            ...previous,
            ...changes,
            page: 1
        }))
    }

    const updateFilter = (key: any, value: any) => {
        updateFilters({
            [key]: value
        })
    };

    const setPage = (event: any) => {
        setFilters((previous: any) => ({
            ...previous,
            page: event
        }))
    }

    return {
        filters: filters,
        setPage: setPage,
        setFilters: setFilters,
        updateFilter: updateFilter,
        updateFilters: updateFilters
    }
}

export {
    useTableFilters
}