import React from 'react';
//Other Libraries
import styled from "styled-components";
//Components
import { Select } from "antd";
//Config
import { worldRegions } from "../../config";
import { theme } from "../../../../../assets/theme/colors";

interface Props {
  value: string,
  view: string,
  onChange: any
}

const Styles = styled.div`
  margin-top: 4px;

  .ant-select-single .ant-select-selector {
    position: relative;
    border: 1px solid ${theme.colors.white};
    border-radius: 16px !important;
    background: ${theme.colors.dogerblue} !important;
    min-width: 124px;
    color: ${theme.colors.white};
    font-size: 12px;
  }

  .ant-select-arrow {
    color: ${theme.colors.white}
  }
`
const optionStyle = {
  fontSize: 12,
  paddingTop: 4,
  paddingBottom: 4,
}

const RegionSelect: React.FC<Props> = (props: Props) => {
  const {value, view, onChange} = props;
  const options = worldRegions[view].map((region: any) => {
     return (
      <Select.Option style={optionStyle} value={region.key} key={region.key}>
        {region.label}
      </Select.Option>
    )
  });
  return (
    <Styles>
      <Select
        size='small'
        onChange={onChange}
        placeholder="Select Region"
        value={value || undefined}
        style={{width: 150}}>
        {options}
      </Select>
    </Styles>
  )
};

export default RegionSelect;

