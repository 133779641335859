import React from "react";
//Custom Components
import { Space } from "antd";
import { SimpleEmpty } from "@cardoai/components";
//Custom Components
import DetailsRecord from "./record";
//Helpers
import { humanString, isEmpty } from "../../../../../../helpers";

const Details = (props: any) => {
  const {reports} = props;

  let results = reports?.dhc_result;
  // Keeping both versions to support change of structure of results
  if (!results)
    results = reports?.detailed_result?.dhc_result

  if (isEmpty(results))
    return <SimpleEmpty/>

  return (
    <Space direction='vertical' className="fullWidth" size='large'>
      {results.map((report: any, index: number) => (
          <DetailsRecord
            data={report.results}
            defaultOpen={index === 0}
            title={humanString(report.entity)}/>
        )
      )}
    </Space>
  )
};
export default Details;