//Helpers
import { formatters, Text } from "../../../../helpers";

export const loansConfig = (currency: any) => {
  return [
    {
      key: "current_balance",
      label: Text("Current\nBalance"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})
    },
    {
      key: "loan_number",
      label: Text("Total \nLoans #"),
      format: formatters.thousandsShorted
    },
    {
      key: "open_loan_number",
      label: Text("Loans \n Outstanding #"),
      format: formatters.thousandsShorted
    },
    {
      key: "wam",
      label: Text("WAM\n(yrs)"),
      format: formatters.years
    },
    {
      key: "wala",
      label: Text("WALA\n(yrs)"),
      format: formatters.years
    },
    {
      key: "gwar",
      label: Text("WAR\n(%)"),
      format: formatters.percent
    },
    {
      key: "avg_loan_price",
      label: Text("Avg. Price\n(%)"),
      format: formatters.percent
    },
    {
      key: "total_investment_amount",
      label: Text("Investment\n Amount"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})
    },
    {
      key: "total_collected_amount",
      label: Text("Total\n Collections"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})
    },
    {
      key: "late_loan_number",
      label: Text("Late\nLoans #"),
      format: formatters.thousandsShorted
    },
    {
      key: "late_loan_balance",
      label: Text("Late\n Amount"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})
    },
    {
      key: "late_balance_1_29",
      label: Text("DPD \n(1-29)"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})

    },
    {
      key: "late_balance_30_59",
      label: Text("DPD\n (30-59)"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})

    },
    {
      key: "late_balance_60_89",
      label: Text("DPD\n (60-89)"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})
    },
    {
      key: "late_balance_90_119",
      label: Text("DPD \n(90-119)"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})
    },
    {
      key: "late_balance_120",
      label: Text("DPD \n(120+)"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})
    },
    {
      key: "avg_realized_maturity",
      label: Text("Avg. Realized Maturity \n DSO (yrs)"),
      format: formatters.years
    }
  ]
}
