import React from 'react';
//Components
import { Tabs } from "antd";
//Custom Components
import { FlatList } from "@cardoai/components";
//Config
import { collateralInfo } from "../../../../config";
//Images
import overview from "../../../../assets/overview.png";
import strategy from "../../../../assets/strategy.png";
import timeline from "../../../../assets/timeline.png";
import financials from "../../../../assets/financials.jpg";
import status from "../../../../assets/status.png";

const {TabPane} = Tabs;

const CollateralContent = () => {

  const projectStatus = <img src={status} alt="status"/>

  return (
    <div>
      <FlatList elementWidth={200} data={collateralInfo.data} config={collateralInfo.config}/>
      <div>
        <Tabs defaultActiveKey="1" tabBarExtraContent={projectStatus}>
          <TabPane tab="Overview" key="1">
            <img src={overview} alt="overview"/>
          </TabPane>
          <TabPane tab="Strategy" key="2">
            <img src={strategy} alt="strategy"/>
          </TabPane>
          <TabPane tab="Timeline" key="3">
            <img src={timeline} alt="timeline"/>
          </TabPane>
          <TabPane tab="Financials" key="4">
            <img src={financials} alt="financials"/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default CollateralContent;