import React from 'react';
//Components
import { Col, Row, Select } from "antd";
//Helpers
import { callApi } from "../../../../../../helpers";
import { ACCOUNTS } from "./accounts_config";

const {Option} = Select;

interface Props {
  vehicle: any,
  vehicleId: any,
  vehicleIdentifier?: any,
}

const MainView = (props: Props) => {

  const {vehicle, vehicleId, vehicleIdentifier} = props;

  let currencies = [];
  const {hasDefaultDate, component: Component, urlParam, showDateSelection} = ACCOUNTS[vehicleIdentifier];

  if (vehicle.hasOwnProperty("note_currencies"))
    currencies = vehicle.note_currencies;

  const [date, setDate] = React.useState(null);
  const [reconciliationDates, setReconciliationDates] = React.useState<any>([]);

  const getReconciliationDates = () => {
    callApi({
      url: `/main/${urlParam}/accounts/reconciliation_periods/?vehicle_id=${vehicleId}`,
      onSuccess: ((response: any) => {
        if (Array.isArray(response)) {
          setReconciliationDates(response)
          if (hasDefaultDate && response.length) {
            setDate(response[response.length - 1])
          }
        }
      })
    })
  }

  const onDateChange = (value: any) => {
    setDate(value);
  };

  React.useEffect(() => {
    if (showDateSelection)
      getReconciliationDates()
  }, [vehicleId])


  return (
    <>
      {showDateSelection && <Row gutter={16}>
        <Col>
          <Select
            value={date}
            style={{width: 150}}
            placeholder="Select Date"
            onChange={onDateChange}>
            {!hasDefaultDate && <Option value="all" key="all">All</Option>}
            {reconciliationDates?.map((option: any) => {
              return (
                <Option value={option} key={option}>
                  {option}
                </Option>
              )
            })}
          </Select>
        </Col>
      </Row>}
      <Component currencies={currencies} vehicleId={vehicleId} date={date}/>
    </>
  );
};

export default MainView;