import React from "react";
//Components
import { Popover } from "antd";
import { SimpleEmpty } from "@cardoai/components";
import SmartTable from "../../../../../../../../../../components/tables/smart_table";
//Helpers
import { ATECOContent, ATECOLimitsColumns } from "./config";
import { withData } from "../../../../../../../../../../utils";
import withLimitDownload from "../../../../components/hoc/with_limit_download";
import {formatters, generateUrl, isEmpty} from "../../../../../../../../../../helpers";
//Icons
import { InfoCircleOutlined } from "@ant-design/icons";

const AtecoSection = ({data, currency, vehicleId, proposalId, urlParam}: any) => {

  return (
    <>
      <Popover content={ATECOContent} title="ATECO Limits" trigger="click">
        ATECO Limits <InfoCircleOutlined className="ml8" style={{color: '#1890FF'}}/>
      </Popover>
      {isEmpty(data) ? <SimpleEmpty description={'Not applicable the aggregate portfolio is lower then 20m!'}/> :
        <span className="bolder"> Aggregate Portfolio: {formatters.currency({
          value: data[0].aggregate_portfolio,
          precision: 0,
          currency
        })}</span>
      }
      <SmartTable
        externalData={data}
        columns={ATECOLimitsColumns("ATECO Code", currency,vehicleId,proposalId,urlParam)}
      />
    </>
  )
};


export default withData({
  urlFormatter: (config: any) => {
    const {props: {proposalId, vehicleId, urlParam}} = config
    return generateUrl(`/mcs/${urlParam}/ateco_limits`, {
        vehicle_id: vehicleId,
        proposal_id: proposalId
      }
    );
  }
})
(withLimitDownload(AtecoSection)({
  title: 'ATECO Limits',
  prepareData: (data: any) => {
    return data.map((atecoLimit: any) => {
      return {
        Name: atecoLimit.name,
        Threshold: atecoLimit.value,
        Realized: Number(atecoLimit.fulfillment),
        Check: atecoLimit.broken.toString().toUpperCase(),
        Available: atecoLimit.available_amount,
      }
    })
  }
}));