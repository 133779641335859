import React from 'react';
//Components
import NotesManagerCharts from "./components/charts"
import NotesTable from "./components/table";
import { Card, Col, Row, Skeleton, Tabs } from "antd"
import HeaderStatistics from "./components/header_statistics";
//Helpers
import { actionKey } from "../../constants";
//REDUX
import { useQuery, } from "../../helpers";
import { useAuthorization } from "../../utils/hooks"

const TabPane = Tabs.TabPane;

const isChartVisible = (canPerformRollback: boolean, identifier: string) => {
  return !canPerformRollback && identifier !== "casavo_iota" && identifier !== "casavo_iota_demo";
}

const NotesManager = () => {
  const authorization: any = useAuthorization();
  const maxChartHeight = window.innerHeight - 300;

  const { data } = useQuery({ url: '/main/notes_manager/vehicles/' });

  if (!data || !data.length)
    return <Skeleton active/>;

  const dataNotesManager = data.filter((item:any)=>item.identifier !== "casavo_theta")

  return (
    <Tabs defaultActiveKey="1">
      {dataNotesManager.map((vehicle: any) => {
        const {id, name, identifier, base_currency: baseCurrency} = vehicle;
        const canProposeLoan = authorization.canPerformAction(actionKey.synchronizeCollateralSimulation, id);
        const canPerformRollback = authorization.canPerformAction(actionKey.rollbackProposals, id);

        return (
          <TabPane key={id} tab={name}>
            <Card size='small' bordered={false} title="New Portfolio Purchases">
              {identifier === "mcs" &&
                <HeaderStatistics baseCurrency={baseCurrency} vehicleId={id}/>}
              <Row gutter={16}>
                {isChartVisible(canPerformRollback, identifier) &&
                  <Col xs={6}>
                    <NotesManagerCharts vehicleId={id} vehicle={vehicle} maxHeight={maxChartHeight}/>
                  </Col>}
                <Col xs={isChartVisible(canPerformRollback, identifier) ? 18 : 24}>
                 <NotesTable
                    vehicleId={id}
                    vehicle={vehicle}
                    height={maxChartHeight}
                    canProposeLoan={canProposeLoan}
                  />
                </Col>
              </Row>
            </Card>
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default NotesManager;
