import React from 'react';
import { CheckOutlined, MinusOutlined } from "@ant-design/icons";

const customStyles: any = {
  success: {
    customStyle: {},
    background: '#2D72EA',
    Icon: CheckOutlined
  },
  disabled: {
    background: '#D4D3D4',
    Icon: MinusOutlined,
    customStyle: {
      transform: 'rotate(45deg)'
    }
  },
  danger: {
    customStyle: {},
    background: '#F15928',
    Icon: MinusOutlined
  },
  warning: {
    customStyle: {},
    background: '#d4b106',
    Icon: CheckOutlined
  }
}


const StatusSign = ({action, style = {}}: any) => {
  const {Icon, customStyle, background} = customStyles[action];
  return (
    <div
      style={{
        ...style,
        width: '16.25px',
        height: '16.25px',
        display: 'flex',
        borderRadius: '50%',
        transition: 'all .5s',
        alignItems: 'center',
        background: background,
        justifyContent: 'center',
      }}>
      <Icon style={{...customStyle, color: '#fff', fontSize: 11}}/>
    </div>
  );
};

export default StatusSign;
