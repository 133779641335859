import React from "react";
//Icons
import {
  BarChartOutlined,
  LineChartOutlined,
  Loading3QuartersOutlined,
  PieChartOutlined,
  TableOutlined
} from "@ant-design/icons";

const charts = [
  {
    icon: <BarChartOutlined/>,
    tip: "Bar",
    key: 'bar'
  },
  {
    icon: <LineChartOutlined/>,
    tip: "Line",
    key: 'line'
  },
  {
    icon: <PieChartOutlined/>,
    tip: "Pie",
    key: 'pie'
  },
  {
    icon: <Loading3QuartersOutlined/>,
    tip: "Doughnut",
    key: 'doughnut'
  }
];
const table = {
  icon: <TableOutlined/>,
  tip: "Table",
  key: 'table'
}


export {
  charts,
  table
}