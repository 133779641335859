import React from 'react';
//Custom Components
import Content from "./content";
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { isEmpty, useQuery } from "../../../../../../../../../../../helpers";
import { Spin } from "antd";

interface IHistoricalData {
  limitName?: string,
  currency?: any,
  vehicleId?: any,
}

const HistoricalData = (props: IHistoricalData) => {
  const {limitName, currency, vehicleId} = props;

  const evolutionOfLimits = useQuery({
    url: `/casavo/notes_manager/concentration_limits_evolution/?vehicle_id=${vehicleId}&name=${limitName}`,
    deps: [limitName]
  })

  return (
    <>
      {isEmpty(evolutionOfLimits.data) ? <SimpleEmpty/> :
        <Spin spinning={evolutionOfLimits.loading}>
          <Content currency={currency} data={evolutionOfLimits.data}/>
        </Spin>}
    </>
  );
};

export default HistoricalData;