import React from 'react';
//Custom Components
import TooltipItem from "./tooltip_item";
//Helpers
import { validateValue } from "../../../../../../helpers";
import { tableConfig } from "../config";
//Styles
import { ToolTipStyles } from './tooltip_styles';

interface Props {
  feature: any
}

const CountryToolTip = (props: Props) => {
  const {feature} = props;
  let record: any;
  let currency: any;

  if (feature.data && feature.data.hasOwnProperty("record")) {
    record = feature.data.record;
    currency = feature.data.record.currency;
  }

  if (!record)
    return null;

  return (
    <ToolTipStyles size='small'>
      <TooltipItem title="Country" value={record.name}/>
      {tableConfig(currency).map((item: any) => {

        return <TooltipItem key={item.key} title={item.label} value={validateValue(record[item.key], item.format)}/>
      })}
    </ToolTipStyles>
  )
};

export default CountryToolTip;

