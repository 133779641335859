import React, { useState } from 'react';
//Other Libraries
import moment from "moment";
//Components
import { Button, Checkbox, Col, DatePicker, Divider, Row } from "antd";
//CardoUI Components
import { FieldSet, FileUpload } from "@cardoai/components";
//Custom Components
import Files from "../upload_document/file_upload/files";
import DocumentType from "../upload_document/document_type";
import ConfirmButton from "../upload_document/confirm_button";
import UploadLoader from "../../../../components/upload_files/upload_loader";
//Icons
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
//Helpers
import { callApi, formatServerDate, generateUrl, MathOp } from "../../../../helpers";
import notifications from "../../../../components/notifications";
//Styles
import { containerStyles, UploadLayout } from "../upload_document/styles";

interface IUploadTape {
  config: any,
  vehicle: any,
  vehicleId: any,
  allowUpload: boolean | undefined,
  handleConfirm: any,
  onCancel: any,
  documentIdentifier: any
  isGrouped?: boolean
}

const UploadTape = (props: IUploadTape) => {
  const {config, vehicleId, handleConfirm, onCancel, documentIdentifier, isGrouped} = props;

  const [files, setFiles] = useState<any>([]);
  const [percent, setPercent] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [publish, setPublished] = useState<any>(true);
  const [documentType, setDocumentType] = useState<any>("");
  const [groupName, setGroupName] = useState<any>("");
  const [referenceDate, setReferenceDate] = useState<any>("");

  const disabledDate = (current: any) => {
    return current && current >= moment().endOf('day');
  }

  const onPublish = () => {
    setPublished((prevState: any) => !prevState);
  }

  const getToolTipTitle = () => {
    if (!publish)
      return "By selecting, the document will be published to investor"
    return "The document will be available to investor";
  }

  const handleUpload = () => {
    setLoading(true);

    function formatDate() {
      const serverData = config.picker === 'month' ? referenceDate.clone().endOf("month") : referenceDate;
      return formatServerDate(serverData);
    }

    const form = new FormData();

    for (let file of files) {
      const name = file.name;
      const extension = file.extension;
      const fileName = name.replace(`.${extension}`, " ");
      form.append(fileName, file);
    }

    const params = {
      doc_type: documentType.trim(),
      group: groupName.trim(),
      confirmed: publish ? 1 : 0,
      reference_date: formatDate(),
      vehicle_id: vehicleId
    };

    const url = generateUrl(`/main/documents/upload_${documentIdentifier}`, params)

    callApi({
      url: url,
      method: 'post',
      dataType: "form",
      body: form,
      onSuccess: () => {
        handleConfirm();
        setLoading(false);
      },
      onUploadProgress: (progressEvent: any) => {
        const totalLength = progressEvent.total;
        if (totalLength !== null) {
          const percentageUploaded: any = MathOp.divide(progressEvent.loaded, progressEvent.total) * 100;
          setPercent(Math.round(percentageUploaded));
        }
      },
      onFinish: () => {
        setLoading(false);
      }
    })
  }

  const handleFilesChange = (newFiles: any) => {
    setFiles(newFiles)
  }

  const handleFileRemove = (index: any) => (e: any) => {
    setFiles((previousFiles: any) => {
      return previousFiles.filter((_: any, fileIndex: any): any => fileIndex !== index)
    })
  }

  const uploadConfig = {
    files: files,
    required: true,
    multiple: true,
    onError: () => {
      notifications.warning("You cannot upload this type of file")
    },
    accepts: config.allowedFiles,
    onChange: handleFilesChange
  }


  if (loading)
    return <UploadLoader percent={percent}/>

  const enableUpload = !!files.length && documentType && referenceDate;

  return (
    <>
      <div style={containerStyles.layout}>
        <Row gutter={32} justify="space-between" style={containerStyles.form}>
          <Col xs={isGrouped ? 8 : 12}>
            <FieldSet
              autoFocus
              label={isGrouped ? "Originator" : "Type"}
              placeholder={isGrouped ? 'Originator Name' : 'Document Type'}
              component={DocumentType}
              type={config.documentType}
              onChange={setDocumentType}/>
          </Col>
          {isGrouped && <Col xs={8}>
            <FieldSet
              autoFocus
              label="Borrower"
              placeholder="Borrower Name"
              component={DocumentType}
              type={config.groupName}
              onChange={setGroupName}/>
          </Col>}
          <Col xs={isGrouped ? 8 : 12}>
            <FieldSet
              defaultValue={null}
              label="Reference Date"
              picker={config.picker}
              className="table-input"
              component={DatePicker}
              disabledDate={disabledDate}
              onChange={setReferenceDate}/>
          </Col>
        </Row>
        <Divider style={{fontWeight: 400}}>
          Files
        </Divider>
        <div title="Click or drag files to this area for upload" className="pointerCursor">
          <FileUpload {...uploadConfig} style={containerStyles.upload}>
            {!files.length ? (
              <UploadLayout>
                <Row className="columnDirection">
                  <Col>
                    <UploadOutlined style={containerStyles.icon}/>
                  </Col>
                  <Col>
                    <p>Click or drag files to this area for upload</p>
                  </Col>
                </Row>
              </UploadLayout>
            ) : (
              <Files files={files} handleFileRemove={handleFileRemove}/>
            )}
          </FileUpload>
        </div>
      </div>
      <Divider style={{marginBottom: 8}}/>
      <Row gutter={8} align='middle' justify="space-between" style={{padding: "0 12px"}}>
        <Col title={getToolTipTitle()} style={{fontWeight: 500, fontSize: 16}}>
          <Checkbox
            checked={publish}
            onChange={onPublish}>
            Publish to Investor
          </Checkbox>
        </Col>
        <Col>
          <Row gutter={8} justify="end">
            <Col>
              <Button onClick={onCancel}>
                Cancel
              </Button>
            </Col>
            <Col>
              <ConfirmButton
                showConfirm
                okText="Yes"
                cancelText="No"
                onConfirm={handleUpload}
                disabled={!enableUpload}
                placement='topRight'
                title="Are you sure to upload these documents?">
                <Button
                  disabled={!enableUpload}
                  icon={<CloudUploadOutlined/>}
                  type='primary'>
                  Upload
                </Button>
              </ConfirmButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UploadTape;