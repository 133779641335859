import React from 'react';
//Custom Components
import { BoldText, CustomTable } from "@cardoai/components";
import { formatPercent, formatThousands } from "../../../../../../helpers";

const headerNames: any = {
  "Origination Year": "Year",
  "Loan Size Distribution": "Size range"
}

const LoanStatsTable = (props: any) => {
  const {categoryName, data} = props;

  return (
    <div>
      <BoldText className="textCenter">{categoryName}</BoldText>
      <CustomTable height={400}>
        <thead>
        <th>{headerNames[categoryName]}</th>
        <th>GBV</th>
        <th>No. of loans</th>
        <th>% Balance</th>
        </thead>
        <tbody>
        {data.map((record: any) => {
          return (
            <tr>
              <td>{record.name}</td>
              <td>{formatThousands(record.gbv,0)}</td>
              <td>{record.nr_loans}</td>
              <td>{formatPercent(record.balance_percent,0)}</td>
            </tr>
          )
        })}
        </tbody>
      </CustomTable>
    </div>
  );
};

export default LoanStatsTable;