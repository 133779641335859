import React from 'react';
//Custom Components
import { DefinitionList } from "../../../../components";
import CustomImage from "../../../../components/custom_image";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Collateral = () => {
  return (
    <>
      <CustomImage
        alt="SPV Management Collateral Full View"
        src={assets.images.spvModule.collateralFullView}
      />
      <div>
        <DefinitionList
          header="The section show details of the Collateral, starting from some summary statistics:"
          data={[
            {
              term: 'Reporting Date',
              explanation: 'cut-off date of the reported data'
            },
            {
              term: 'Initial Balance',
              explanation: 'initial balance of the collateral_section pool (including initial and further portfolios, if any)'
            },
            {
              term: 'Current Balance',
              explanation: 'current outstanding amount of the loans'
            },
            {
              term: 'Delinquent (%)',
              explanation: 'delinquent loans as % on the total current balance'
            },
            {
              term: 'Loans Repaid #',
              explanation: 'number of loans repaid as at Reporting Date'
            },
            {
              term: 'Loans Outstanding #',
              explanation: 'number of loans outstanding as at Reporting Date'
            },
            {
              term: 'Average Loan Size',
              explanation: 'average amount of the loans in the collateral_section pool'
            },
            {
              term: 'WALA (years)',
              explanation: 'Weighted Average Loan Age (calculated with reference to the issue date of the loan) weighted by current balance'
            },
            {
              term: 'WAM (years)',
              explanation: 'Weighted Average Residual Maturity calculated (with reference of the maturity date, as eventually revised / changed), weighted by current balance'
            },
            {
              term: 'WAR (%)',
              explanation: 'Weighted Average Return (including contractual coupon, fees and purchase price), weighted by current balance'
            },
            {
              term: 'Avg. Realized Maturity / DSO (years)',
              explanation: 'average realized maturity of the loans, weighted by original amount'
            },

          ]}
        />
      </div>
    </>
  );
};

export default Collateral;
