import Accounts from "./components/accounts";
import Waterfall from "./components/waterfall";
import LtcAndOutstanding from "./components/ltc_and_outstanding";

const config = [
    {
        key: 'waterfall',
        label: 'Waterfalls',
        component: Waterfall
    },
    {
        key: 'ltc_bb',
        label: 'LTC and Outst. BB',
        component: LtcAndOutstanding
    },
    {
        key: 'accounts',
        label: 'Accounts',
        component: Accounts
    },
]

export {
    config
}