import React from 'react'
//Other Libraries
import { Link, useLocation } from 'react-router-dom';
//Components
import { Layout, Menu } from 'antd';
import ThemeSwitch from "./components/theme_switch";
//Icons
//Styles
import SidebarWrapper from './style';
//Images
import Logo from './components/logo';
//Custom Helpers
import { useAuthorization } from "../../../../utils/hooks";
import { routeConfiguration } from "../../../../constants";

const {Sider} = Layout;


const SideBar = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);

  const location = useLocation();
  const authorization = useAuthorization();

  const toggleSidebar = () => {
    setOpenSidebar(prevState => !prevState)
  }

  const onMouseEnter = () => {
    if (!openSidebar && collapsed)
      toggleSidebar();
  };

  const onMouseLeave = () => {
    if (openSidebar && collapsed)
      toggleSidebar();
  };

  const onCollapse = () => {
    setCollapsed(prevState => !prevState)
  }

  const shouldCollapse = collapsed && !openSidebar;

  let selectedKeys: string[] = [];

  for (let route of routeConfiguration) {
    const mainPath = location.pathname.split('/')[1];
    if (mainPath === route.path) {
      selectedKeys.push(route.path);
      break;
    }
  }

  return (
    <SidebarWrapper>
      <Sider
        collapsible
        width={250}
        trigger={null}
        collapsed={shouldCollapse}
        className="isomorphicSidebar">
        <Logo onClick={onCollapse} collapsed={shouldCollapse}/>
        <Menu
          theme="dark"
          selectedKeys={selectedKeys}
          className="isoDashboardMenu"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}>
          {authorization.extractRoutes().map((route: any) => {
            const {title, path, icon, disabled} = route;
            return (
              <Menu.Item disabled={disabled} key={route.path}>
                <Link to={path}>
                                  <span className="isoMenuHolder">
                                          {icon}
                                    <span className="nav-text">
                                          {title}
                                        </span>
                                  </span>
                </Link>
              </Menu.Item>
            );
          })}
          <ThemeSwitch collapsed={shouldCollapse}/>
        </Menu>
      </Sider>
    </SidebarWrapper>
  );
};

export default SideBar
