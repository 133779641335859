import React from 'react';
//Custom Components
import GroupedDocuments from "./grouped_documents";
import DefaultDocuments from "./default_documents";
//Custom Hooks
import { useAuthorization } from "../../../../utils/hooks";
//Helpers
import { callApi, downloadClientFile, generateUrl } from "../../../../helpers";
import { actionKey } from "../../../../constants";

interface IDocuments {
  vehicle: any,
  vehicleId: any,
  title: any,
  documentIdentifier: any,
  config: any,
  triggerCollapse?: any,
}

const isGroupedTable = (vehicle: any) => {
  return vehicle.functionalities_config.hasOwnProperty("supports_table_groupings") && vehicle.functionalities_config['supports_table_groupings'] === true;
}

const Documents = (props: IDocuments) => {
  const {vehicle, vehicleId, title, documentIdentifier, config, triggerCollapse} = props;

  const authorization: any = useAuthorization();

  const isGrouped = isGroupedTable(vehicle);

  const onDelete = (record: any, updateTable: any) => {
    const url = generateUrl(
      `/main/documents/delete_uploaded_document`,
      {id: record.id, vehicle_id: vehicleId}
    );

    callApi({
      url: url,
      method: "delete",
      successMessage: "Document Deleted",
      onSuccess: () => {
        updateTable();
      },
    })
  }

  const onPublish = (record: any, updateTable: any) => {
    const url = generateUrl(
      `/main/documents/confirm_upload`,
      {
        id: record.id,
        vehicle_id: vehicleId,
        confirm: !record.confirmed ? "True" : "False",
      }
    );

    callApi({
      url: url,
      method: "put",
      successMessage: `Document ${record.confirmed ? "Unpublished" : "Published"} to Investor`,
      onSuccess: () => {
        updateTable();
      },
    });
  };

  const onDownload = (records: any[]) => {
    const url = generateUrl(
      `/main/documents/download_documents`,
      {
        id: records,
        vehicle_id: vehicleId
      }
    );

    callApi({
      url: url,
      onSuccess: (result: any) => {
        downloadClientFile({
          url: result.url,
          filename: 'documents',
          link: true,
          target: '_blank'
        });
      },
    })
  }

  const onSingleDownload = (record: any) => {
    onDownload([record.id]);
  }

  const onDownloadMultiple = (selected: any[]) => {
    onDownload(selected);
  }

  const uploadDocumentKey = `upload_${documentIdentifier}`
  const allowUpload = authorization.canPerformAction(actionKey[uploadDocumentKey], vehicleId);

  return (
    <>
      {isGrouped ?
        <GroupedDocuments vehicleId={vehicleId} onDelete={onDelete} documentIdentifier={documentIdentifier}
                          onPublish={onPublish} onDownload={onSingleDownload} title={title}
                          config={config} vehicle={vehicle} isGrouped={isGrouped} allowUpload={allowUpload}
                          onDownloadMultiple={onDownloadMultiple}/>
      :
        <DefaultDocuments vehicleId={vehicleId} documentIdentifier={documentIdentifier}
                          triggerCollapse={triggerCollapse} onDelete={onDelete}
                          onPublish={onPublish} onDownload={onSingleDownload} title={title}
                          config={config} vehicle={vehicle} isGrouped={isGrouped} allowUpload={allowUpload}/>}
    </>
  );
};


Documents.defaultProps = {
  height: 300
}

export default Documents;
