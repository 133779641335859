import React from 'react';
//Components
import { Skeleton } from "antd";
import { FlatList } from "@cardoai/components";
//Helpers
import { useQuery } from "../../../../helpers";
import { mainStatistics } from "./config";

const HeaderStatistics = ({vehicleId, baseCurrency}: any) => {

  const headerStatistics = useQuery({
    url: `/mcs/notes_manager/overview/?&vehicle_id=${vehicleId}`
  })

  if (!headerStatistics)
    return <Skeleton/>

  return (
    <>
      <FlatList
        size="large"
        elementWidth={300}
        data={headerStatistics.data}
        config={mainStatistics(baseCurrency)}
      />
    </>
  );
};

export default HeaderStatistics;