import {
  tableCashFlow, tableLoanByLoanSection,
  tableRealEstate,
} from "./table_columns";
import {tableCashFlowTheta, tableLoanByLoanSectionTheta, tableRealEstateTheta} from "./theta_table_columns";

const config: any = {
  asset: {
    entity: "realEstate",
    columns: tableRealEstate,
    endpoints: {
      tableUrl: '/main/real_estate/',
      downloadUrl: '/main/real_estate/download_real_estate_csv/?'
    }
  },
  assetTheta: {
    entity: "realEstate",
    columns: tableRealEstateTheta,
    endpoints: {
      tableUrl: '/main/real_estate/',
      downloadUrl: '/main/real_estate/download_real_estate_csv/?'
    }
  },
  cashflows: {
    entity: "realEstateCashflows",
    columns: tableCashFlow,
    endpoints: {
      tableUrl: '/main/real_estate_cash_flows/get_grouped_cash_flows/',
      downloadUrl: '/main/real_estate_cash_flows/download_cash_flows/?'
    }
  },
  cashflowsTheta: {
    entity: "realEstateCashflows",
    columns: tableCashFlowTheta,
    endpoints: {
      tableUrl: '/main/real_estate_cash_flows/get_grouped_cash_flows/',
      downloadUrl: '/main/real_estate_cash_flows/download_cash_flows/?'
    }
  },
  loanByLoanSection: {
    entity: "realEstateLoanByLoanSection",
    columns: tableLoanByLoanSection,
    endpoints: {
      tableUrl: '/casavo/collateral/loan_by_loan/?',
      downloadUrl: ' '
    }
  },
  loanByLoanSectionTheta: {
    entity: "realEstateLoanByLoanSection",
    columns: tableLoanByLoanSectionTheta,
    endpoints: {
      tableUrl: '/casavo/collateral/loan_by_loan/?',
      downloadUrl: ' '
    }
  },
}
export default config;