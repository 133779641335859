import React from 'react';
//Components
import { Col, Row } from "antd";
import VehicleStatistics from "../statistics/vehicle_statistics";
import { Diversification, GeographyContent } from "../../../../../common";
import withVehicleSelection from "../statistics/with_vehicle_selection";
//Utils
import { vehicleFieldConfig } from "../../config";
import { getVehicleCurrencyById } from "../../../../../../helpers";

interface IOverview {
  countries?: any,
  statistics?: any,
  vehicleId?: any,
  setRedirection?: any,
  setSelectedVehicle?: any,
  redirectSourceGeography?: any,
}

const contentStyle: any = {
  flex: '1 0 0',
  overflowX: 'auto',
  paddingLeft: 12,
  width: 1400
};

const Statistics = withVehicleSelection(VehicleStatistics);

const OverviewContent = (props: IOverview) => {
  const {
    vehicleId,
    redirectSourceGeography,
    setRedirection,
    setSelectedVehicle,
    countries,
    statistics
  } = props;

  const isVehicleActive = (key: any) => {
    return key == vehicleId;
  }

  return (

    <>
      <Row gutter={8} justify="space-between" className="noWrap">
        <Col lg={8} md={24} sm={24} style={{marginTop: 16}}>
          <Statistics
            width={400}
            height={450}
            keyAttr="vehicle_id"
            vehicleId={vehicleId}
            isActive={isVehicleActive}
            data={statistics.vehicles}
            config={vehicleFieldConfig}
            onChange={setSelectedVehicle}
          />
        </Col>
        <Col lg={16} md={24} style={contentStyle}>
          <Row gutter={8} align='middle'>
            <Col xs={8}>
              <GeographyContent
                height={350}
                showTable={false}
                vehicleId={vehicleId}
                showCountryCards={false}
                singleVehicleView={false}
                redirectSourceGeography={redirectSourceGeography}
                currency={getVehicleCurrencyById(statistics.vehicles, vehicleId)}
              />
            </Col>
            <Col xs={16}>
              <Diversification
                chartSize
                usesMenu={false}
                chartHeight={170}
                showLegend={false}
                vehicleId={vehicleId}
                minSelectionSize={3}
                maxSelectionSize={6}
                cacheSelectionEntity="dashboard_general_pie_charts"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OverviewContent;
