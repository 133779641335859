import React from 'react';
//Components
import { Button } from "antd";
//Icons
import { DownloadOutlined } from "@ant-design/icons";
//Helpers
import { downloadClientFile } from "../../../helpers";

interface Props {
  file?: any,
  filename?: any
}

const DownloadButton = (props: Props) => {
  const {file, filename} = props

  const download = (url: string, name: string) => () => {
    downloadClientFile({
      url: url,
      filename: name,
      link: true,
      target: '_blank'
    });
  }

  return (
    <Button
      className='mt8'
      icon={<DownloadOutlined/>}
      onClick={download(file, filename)}
    >
      {filename}
    </Button>
  );
};

export default DownloadButton;