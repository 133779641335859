import React, { useState } from "react";
//Components
import {Tabs} from "antd";
//Configurations
//Custom Components
import { ModalGroupCheckBox } from "../../components";
import { useCashFlowTypesFiltering, useFilterOptions } from "./apis";
import { tableLoanByLoanSection, otherColumnsLoanByLoanSection } from "./components/configurations/table_columns";
import CasavoIotaTab from "./components/casavo_iota";
import CasavoThetaTab from "./components/casavo_theta";

const TabPane = Tabs.TabPane;

const ALL_COLUMNS: any[] = [...tableLoanByLoanSection, ... otherColumnsLoanByLoanSection];
const INITIAL_SELECTED_COLUMNS: string[] = tableLoanByLoanSection.map((c: any) =>  c.key);
const COLUMN_OPTIONS: any[] = ALL_COLUMNS.map((c: any) => ({ label: c.label, value: c.key }));

const RealEstateManagement = () => {
  const [selectedColumns, setSelectedColumns] = useState<any[]>(INITIAL_SELECTED_COLUMNS);
  const [columnFilterVisible, setColumnFilterVisible] = useState(false);

  const filtering = useFilterOptions();
  const cashflowTypesFiltering = useCashFlowTypesFiltering();

  const filterOptions = filtering.data;
  const cashflowTypes = cashflowTypesFiltering.data;


  const toggleColFilterModal = () => {
    setColumnFilterVisible((prev: boolean) => !prev);
  }

  return (
    <>
      <Tabs>
        <TabPane key="casavo_iota" tab="Casavo Iota">
          <CasavoIotaTab
            filterOptions={filterOptions}
            cashflowTypes={cashflowTypes}
            toggleColFilterModal={toggleColFilterModal}/>
        </TabPane>
        <TabPane key="casavo_theta" tab="Casavo Theta">
          <CasavoThetaTab
            filterOptions={filterOptions}
            cashflowTypes={cashflowTypes}
            toggleColFilterModal={toggleColFilterModal}
          />
        </TabPane>
      </Tabs>
      <ModalGroupCheckBox
        options={COLUMN_OPTIONS}
        visible={columnFilterVisible}
        initialColumns={selectedColumns}
        onConfirm={setSelectedColumns}
        close={toggleColFilterModal}
      />
    </>
  );
}

export default RealEstateManagement;
