import React from 'react';
//Components
import { Panel, SimpleEmpty } from "@cardoai/components";
import CostsTable from "./costs_table";
import AssetSaleDetails from "./asset_sale_details";
import ReconciliationTable from "./reconciliation_table";
//Helpers
import { config } from "../../config";
import { withData } from "../../../../../../../../../utils";
import { generateUrl } from "../../../../../../../../../helpers";

interface Props {
  data: any,
  height: any,
  currencyConfig: any
}

const OtherTables = (props: Props) => {
  const {data, height, currencyConfig} = props;

  const styles: any = {
    overflowY: "auto",
    maxHeight: height,
  }

  let finalData = [];
  const vehicleCurrencies = Object.keys(data);
  const currencyName = (typeof currencyConfig === "object") ? currencyConfig.abbreviation : currencyConfig;

  if (vehicleCurrencies.includes(currencyName))
    finalData = data[currencyName]


  if (!data)
    return <SimpleEmpty description="No Data Available Yet"/>

  return (
    <div style={styles}>
      <Panel header="Reconciliation Table">
        <ReconciliationTable currencyName={currencyName} data={finalData.reconciliation_table_data}/>
      </Panel>
      <Panel header="Asset Sale" className="mt24">
        <AssetSaleDetails currencyConfig={currencyConfig} data={finalData.asset_sale_details}
                          columns={config.asset_sale_columns}/>
      </Panel>
      <Panel header="Costs" className="mt24">
        <CostsTable data={finalData}/>
      </Panel>
    </div>
  )
};

export default withData({
  urlFormatter: (config: any) => {
    const {vehicleId, date} = config.props;
    let url = generateUrl("/main/notes_manager/accounts/reconciliation", {
      vehicle_id: vehicleId,
    });
    if (date && date !== "all")
      url = `${url}&reference_date=${date}`
    return url;
  },
  extraParams: ["vehicleId", "date"],
})(OtherTables);
