import styled from "styled-components";
//styles
export const ManagementStyles = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 25%;

      > div {
        width: 100% !important;
      }
    }
  }
`
