//Assets
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img8 from './assets/img8.png';

export const COLLATERAL: any = [
  {
    id: 1000072,
    title: "Via Orobia",
    type: "Real Estate",
    amount: "1,238,000",
    returns: "IRR 12%",
    location: "Milan, IT",
    image: img1
  },
  {
    id: 1000083,
    title: "Via Tiozzo",
    type: "Office & Retail",
    amount: "5,208,000",
    returns: "IRR 13,5%",
    location: "Verona, IT",
    image: img2
  },
  {
    id: 1000020,
    title: "Via Masotto",
    type: "Hotel",
    amount: "5,208,000",
    returns: "IRR 13,5%",
    location: "Verona, IT",
    image: img3
  },
  {
    id: 1000079,
    title: "Via Cavour",
    type: "Hotel",
    amount: "5,208,000",
    returns: "IRR 13,5%",
    location: "Verona, IT",
    image: img4
  },
  {
    id: 1000044,
    title: "Via Margutta",
    type: "Hotel",
    amount: "5,208,000",
    returns: "IRR 13,5%",
    location: "Verona, IT",
    image: img5
  },
  {
    id: 1000090,
    title: "Via Giulia",
    type: "Hotel",
    amount: "5,208,000",
    returns: "IRR 13,5%",
    location: "Verona, IT",
    image: img6
  },
  {
    id: 1000061,
    title: "Via Nazionale",
    type: "Hotel",
    amount: "5,208,000",
    returns: "IRR 13,5%",
    location: "Verona, IT",
    image: img7
  },
  {
    id: 1000100,
    title: "Via Sacra",
    type: "Hotel",
    amount: "5,208,000",
    returns: "IRR 13,5%",
    location: "Verona, IT",
    image: img8
  },
]


export const fundSelection = [
  {id: 1, label: "Fund 1"},
  {id: 2, label: "Fund 2"},
  {id: 3, label: "Fund 3"},
  {id: 4, label: "Fund 4"},
  {id: 5, label: "Fund 5"},
  {id: 6, label: "Fund 6"},
  {id: 7, label: "Fund 7"},
  {id: 8, label: "Fund 8"},
  {id: 9, label: "Fund 9"},
]


export const headerStatistics = {
  config: [
    {
      key: "market_valuation",
      label: 'Market Valuation'
    },
    {
      key: "purchase_price",
      label: 'Purchase Price'
    },
    // {
    //   key: "ltv",
    //   label: 'LTV'
    // },
    {
      key: "nr_of_re_assets",
      label: '# of RE assets'
    },
    {
      key: "nr_of_cre_assets",
      label: '# of CRE assets'
    },
    {
      key: "nr_of_rre_assets",
      label: '# of RRE assets'
    },
    {
      key: "nr_of_office",
      label: '# Office'
    },
    {
      key: "nr_of_hotel",
      label: '# Hotel'
    },
    {
      key: "expected_duration",
      label: 'Exp. Duration'
    },
    {
      key: "duration_to_date",
      label: 'Duration to Date'
    },
    {
      key: "expected_irr",
      label: 'Exp. IRR'
    },
  ],
  data: {
    "market_valuation": '€100,001,234.00',
    "purchase_price": '€ 98,001,209.32',
    // "ltv": '43%',
    "nr_of_re_assets": "15",
    "nr_of_cre_assets": "10",
    "nr_of_office": "8",
    "nr_of_hotel": "2",
    "nr_of_rre_assets": "5",
    "expected_duration": "3.2 yrs",
    "duration_to_date": "1.2 yrs",
    "expected_irr": "12%"
  }

}


export const collateralInfo = {
  config: [
    {
      key: "project_name",
      label: "Project Name"
    },
    // {
    //   key: "investment_type",
    //   label: "Investment Type"
    // },
    {
      key: "project_type",
      label: "Project Type"
    },
    // {
    //   key: "gbv",
    //   label: "GBV"
    // },
    {
      key: "expected_irr",
      label: "Expected IRR"
    },
    // {
    //   key: "expected_duration",
    //   label: "Expected Duration"
    // },
    {
      key: "project_age",
      label: "Project Age"
    }


  ],
  data: {
    project_name: "Via Orobia",
    // investment_type: "Super Senior",
    project_type: "Office to Residential Conversion",
    // gbv: "€40,000,000",
    expected_irr: "12.43%",
    // expected_duration: "24 months",
    project_age: "12 months"
  }
}