import React, { useState } from 'react';
//Components
import { Col, Row, Select, Spin } from "antd";
import { CustomTable, SimpleEmpty } from "@cardoai/components";
//Configuration
import { groupType, tableConfig } from "../accounts/casavo/config";
//Icons
import { DownOutlined, RightOutlined } from "@ant-design/icons";
//Helpers
import { formatters } from "../../../../../../helpers";
import { formatLimits } from "../limits/helpers";
import { DateSelector } from "../../../../../../components";
import moment from "moment";

const Option = Select.Option;

const groups: any = [
  groupType.generalAccount,
  groupType.excessCashAccount,
  groupType.disposalAccount,
  groupType.registrationTaxReserveAccount
];

const groupNames: any = {
  [groupType.generalAccount]: "General Account",
  [groupType.excessCashAccount]: "Excess Cash Account",
  [groupType.disposalAccount]: "Disposal Account",
  [groupType.registrationTaxReserveAccount]: "Registration Tax Reserve Account"
};

const tableStyle: any = {
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  width: '100%',
};

const stickyColumn: any = {
  position: 'sticky',
  backgroundColor: 'white',
  left: 0,
  top: 0,
  zIndex: 10,
};

const tableHeaderColor = '#F5F5F5';

const getPeriodString = (periodNr: string) => {
  return `Period ${periodNr}`;
}

const getCellValue = (value: any) => {
  const valueString = formatLimits(value, 'Amount');
  const currency = valueString === ' - ' ? '' : ' €';
  return `${valueString}${currency}`;
}

interface Props {
  dataSource: any[],
  loading?: boolean,
  hideFilter?: boolean
}

const AccountsTable = ({dataSource, loading, hideFilter = false}: Props) => {
  const [date, setDate] = React.useState<any>(null);
  const [expandedPanels, setExpandedPanels] = useState<any[]>([]);
  const [periods, setPeriods] = useState<any[]>([]);

  React.useEffect(() => {
    if (!dataSource) return;
    const periods = dataSource.map((record: any) => record.period_nr);
    const lastPeriods = periods.slice(Math.max(periods.length - 5, 0))
    setPeriods(lastPeriods);
  }, [dataSource]);

  const onPanelClick = (key: string) => () => {
    if (expandedPanels.includes(key)) setExpandedPanels((prev: any[]) => prev.filter((p: any) => p !== key));
    else setExpandedPanels((prev: any[]) => [...prev, key]);
  };

  const filteredData = dataSource.filter((record: any) => {
    if (date && date.length) {
      const [startDate, endDate] = date;
      if (!moment(record.collection_period_end).isBetween(startDate, endDate))
        return false;
    }
    return periods.includes(record.period_nr);
  });

  return (
    <>
      {!hideFilter &&
      <>
        <Row justify='end' align='middle' gutter={16} className="mb16">
          <Col className="bolder">
            Filter by collection period end date:
          </Col>
          <Col>
            <DateSelector
              picker="date"
              size='small'
              range={true}
              value={date}
              onChange={setDate}
              showIntervals={false}
            />
          </Col>
          <Col className="bolder">
            Filter by period/s:
          </Col>
          <Col>
            <Select
              size='small'
              placeholder='Filter By Period'
              value={periods}
              onChange={setPeriods}
              showArrow
              autoFocus
              allowClear
              style={{minWidth: 200, maxWidth: 880}}
              mode='multiple'
            >
              {dataSource.map((record: any) => (
                <Option key={record.period_nr} value={record.period_nr}>
                  {getPeriodString(record.period_nr)}
                </Option>
              ))}
            </Select>
          </Col>
        </Row></>}
      <Spin spinning={loading}>
        {filteredData.length ? (
          <CustomTable style={tableStyle}>
            <thead>
            <tr>
              <th style={{...stickyColumn, width: 240, backgroundColor: tableHeaderColor}}/>
              {filteredData.map((record: any) => (
                <th key={record.period_nr} className="textCenter" style={{width: 320}}>
                  {getPeriodString(record.period_nr)}
                </th>
              ))}
            </tr>
            <tr>
              <th style={{...stickyColumn, backgroundColor: tableHeaderColor}} className="textLeft pl24">
                Collection Period End Date
              </th>
              {filteredData.map((record: any) => (
                <th key={record.period_nr} className="textCenter">
                  {formatters.date(record.collection_period_end)}
                </th>
              ))}
            </tr>
            </thead>
            <tbody>
            {groups.map((group: string, index: number) => {
              const groupFields = tableConfig.filter((field: any) => field.groups.includes(group));
              const isExpanded = expandedPanels.includes(group);
              return (
                <React.Fragment key={String(index)}>
                  <tr className="pointerCursor" onClick={onPanelClick(group)}>
                    <td className="textLeft bold" style={stickyColumn}>
                      {isExpanded ? <DownOutlined className="p8"/> : <RightOutlined className="p8"/>}
                      {groupNames[group]}
                    </td>
                    {filteredData.map((record: any) => <td key={record.period_nr} />)}
                  </tr>
                  {isExpanded && groupFields.map((field: any) => (
                    <tr key={field.key}>
                      <td className="textLeft pl24" style={stickyColumn}>
                        {field.label}
                      </td>
                      {filteredData.map((record: any) => {
                        const periodDetails = dataSource?.find((p: any) => p.period_nr === record.period_nr);
                        const periodDetailsGroup = periodDetails !== undefined ? periodDetails[group] : undefined;
                        const value = periodDetailsGroup !== undefined ? periodDetailsGroup[field.key] : '';
                        return (
                          <td key={record.period_nr} className="textCenter" style={{maxWidth: 200}}>
                            {getCellValue(value)}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              );
            })}
            </tbody>
          </CustomTable>
        ) : (
          <SimpleEmpty/>
        )}
      </Spin>
    </>
  )
};

export default AccountsTable;
