import React from 'react'
//Components
import { Popover } from 'antd';
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { isEmpty } from "../../../../../../helpers";

//Styles
const styles: any = {
  container: {
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  child: {
    color: '#fff',
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
  }
}

interface IStacked {
  title?: string
  data?: any,
  getColor?: any,
  customLabel?: any,
  fullHeight?: boolean,
}

const StackedChart = (props: IStacked) => {
  const {data, getColor, customLabel, fullHeight, title} = props;

  const {formatter, dataset} = customLabel;

  if (isEmpty(data))
    return <SimpleEmpty centered description={`No Data for ${title}`}/>

  return (
    <div style={styles.container}>
      {data.map((record: any, index: number) => {

          const {percentage, label} = record;

          let visible = true;

          const childStyle = {
            ...styles.child,
            backgroundColor: getColor(index),
            width: '100%',
          }

          /*Decide if the slice of chart (dataset) will be full height on its div or if the height will be calculated
          * as a percentage of it actual value*/
          const calcHeight = fullHeight ? 100 : (percentage * 100);

          childStyle.height = `${calcHeight}%`;

          const PopoverContent = `${label}: ${formatter(record[dataset], 3)}`

          if (calcHeight >= 10)
            visible = false;

          const hiddenLabels = visible ? 'none' : ''

          return (
            <Popover content={PopoverContent} title={null} trigger="hover" placement='right' key={index}>
              <div style={childStyle} >
                <div className="textCenter" style={{display: hiddenLabels, fontSize: 10}}>
                  {label}
                </div>
                <div style={{display: hiddenLabels, fontSize: 10}}>
                  {formatter(record[dataset], 2)}
                </div>
              </div>
            </Popover>
          )
        }
      )}
    </div>
  );
};

StackedChart.defaultProps = {
  fullHeight: false
};

export default StackedChart;