import React, { useState } from 'react';
//Other Libraries
import clone from "clone";
//Components
import { Col, Row } from "antd";
import { Break, NumberInput } from "@cardoai/components";
import ChartUpdate from "./chart_update";
//Styles
import { Text, View } from "./styles";
//Icons
import { LineChartOutlined } from "@ant-design/icons";

interface Props {
  propagate?: boolean,
  defaultValue?: any,
  title?: any,
  showTitle?: any,
  clickable?: boolean,
  options?: any,
  fields?: any,
  updateFields?: any,
  min?: any,
  max?: any,
  normalizeValue?: any,
  type?: any,
  value?: any,
  placeholder?: any
}

const InputPicker = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const {propagate, defaultValue, title, showTitle, clickable, options, fields, updateFields, min, ...rest} = props;


  const toggleVisible = () => {
    setVisible(v => !v);
  };

  const handleInputChange = (value: any) => {
    if (!propagate)
      return updateFields(value)

    const newState = fields ? clone(fields) : {};

    if (!fields) {
      options.forEach((option: any) => newState[option.label] = value);
    } else {
      for (let key in fields) {
        if (fields.hasOwnProperty(key))
          newState[key] = value;
      }
    }
    return updateFields(newState)
  }

  const visibleText = title && showTitle;


  const input = (
    <NumberInput
      {...rest}
      fullWidth
      min={min}
      onChange={handleInputChange}/>
  )

  return (
    <>
      {visibleText && (
        <>
          <Text className="title">{title}</Text>
          <Break/>
        </>
      )}
      <View>
        {!clickable && input}
        {clickable && <Row align="middle" justify="space-between" gutter={8}>
          <Col xs={20}>
            {input}
          </Col>
          <Col xs={4} onClick={toggleVisible} className="pointerCursor">
            <LineChartOutlined className="svgIcon"/>
          </Col>
        </Row>}
      </View>
      {clickable && visible && (
        <ChartUpdate
          min={min}
          title={title}
          fields={fields}
          visible={visible}
          options={options}
          onOk={toggleVisible}
          onCancel={toggleVisible}
          updateFields={updateFields}
        />
      )}
    </>
  );
};

export default InputPicker;

InputPicker.defaultProps = {
  clickable: true,
  showTitle: true,
  propagate: false
};
