import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { DefinitionList } from "../../../components";

const HDRInformationBar = () => {

  return (
    <Row>
      <Col sm={18}>
        <div style={{textAlign: 'justify'}}>
          <DefinitionList
            header='The main information bar shows the following metrics:'
            data={[
              {
                term: 'Current Balance',
                explanation: 'Total balance of all the outstanding loans.'
              },
              {
                term: 'Total Loans #',
                explanation: 'Total number of loans issued.'
              },
              {
                term: 'Loans Outstanding',
                explanation: 'Total number of outstanding loans.'
              },
              {
                term: 'WAM (yrs)',
                explanation: 'Average residual term of the loans weighted by the current outstanding principal balance of the loans.'
              },
              {
                term: 'WALA (yrs)',
                explanation: 'Average Loan age (from inception) weighted by the current outstanding principal balance of the loans.'
              },
              {
                term: 'WAR',
                explanation: 'Average expected return weighted by the current outstanding principal balance of the loans.'
              },
              {
                term: 'Investment Amount',
                explanation: 'Total Invested amount.'
              },
              {
                term: 'Total Collections',
                explanation: 'Total amount realized or total cash flows realized during the collection period.'
              },
              {
                term: 'Late Loans',
                explanation: 'Number of loans that did not pay within the specified time constraints.'
              }, {
                term: 'Late Amount',
                explanation: ' Amount owed that should have been paid within the specified time but did not.'
              }, {
                term: 'DPD (1-29)',
                explanation: 'the number of days by which the borrowers have missed their expected payment date, grouped from 1-29 days.'
              }, {
                term: 'DPD (30-59)',
                explanation: 'the number of days by which the borrowers have missed their expected payment date, grouped from 30-59 days.'
              }, {
                term: 'DPD (60-89)',
                explanation: 'the number of days by which the borrowers have missed their expected payment date, grouped from 60-89 days.'
              }, {
                term: 'DPD (90-119)',
                explanation: 'the number of days by which the borrowers have missed their expected payment date, grouped from 90-119 days.'
              }, {
                term: 'DPD (120+)',
                explanation: 'the number of days by which the borrowers have missed their expected payment date, grouped from more than 120 days.'
              }, {
                term: 'Avg. Realized Maturity DSO (yrs)',
                explanation: 'average maturity from issue date to closing date shown in years.'
              },
            ]}
          />
        </div>
      </Col>
    </Row>
  );
};

export default HDRInformationBar;