import React from 'react';
//Other Components
import { Checkbox } from "antd";
//Context
import { useDebtorContext } from "./debtor_waiver_provider";

const CheckboxDebtor = ({record}:any) => {
  const debtorContext: any = useDebtorContext();

  const onChange = () => {
    debtorContext.updateValueChecked(record.company_id);
  };

  return <Checkbox checked={record.company_id === debtorContext.valueChecked} onChange={onChange}/>
};

export default CheckboxDebtor;
