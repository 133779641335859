import React from 'react';
//Styles
import { StyledCenter } from "./graph_style";
//Components
import { SimpleEmpty } from "@cardoai/components";

const GraphPlaceholder = (props: any) => {
  const {headers, height, component: Component, showEmptyPlaceholder, options} = props;
  return (
    <>
      <div style={{textAlign: 'center'}}>{headers}</div>
      {height > 130 ? <StyledCenter height={height}>
        {showEmptyPlaceholder ? <SimpleEmpty description="No Data"/> :
          <Component data={{datasets: [], labels: []}} options={options}/>}
      </StyledCenter> : "No Data"}
    </>
  );
};

export default GraphPlaceholder;