import React from "react";
//Custom Components
import { Button, Tooltip } from "antd";
//Icons
import { FileExcelOutlined } from "@ant-design/icons";
//Constants
import settings from "../../../settings";

interface Props {
  config?: any,
  getUrl?: any
}

const Download = (props: Props) => {
  const {config, getUrl} = props;

  const handleClick = () => {
    const url = getUrl(config);
    window.location.assign(`${settings.apiUrl}${url}`);
  };

  return (
    <Tooltip title="Download as Excel">
      <Button
        icon={<FileExcelOutlined/>}
        onClick={handleClick}
        shape="circle"/>
    </Tooltip>
  )
};

export default Download;