import React from 'react';
//Custom Components
import { MessageList } from "./message_list";
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { isEmpty } from "../../../../helpers";

export const MessagesContent = (props: any) => {
  const {fetchMessages, messages, vehicleId, topic, currentUserId} = props;

  if (isEmpty(messages.data) || isEmpty(messages.data.results))
    return <SimpleEmpty description="There are no messages on this topic!"/>

  return (
    <>
      {messages.data.results.length > 0 &&
        <MessageList
          vehicleId={vehicleId}
          topicId={topic.id}
          fetchMessages={fetchMessages}
          currentUserId={currentUserId}
          messages={messages.data.results}
        />}
    </>
  );
};
