export const selectionConfig = {
  selection: "gwar",
  radioGroup: [
    {
      label: "WAR",
      key: "gwar",
    },
    {
      label: "DQ",
      key: "delinquencies"
    }
  ]
};

export const chartConfig = {
  getLabel: (entry: any) => entry.entity,
  getValue: (entry: any) => {
    return {
      y: entry.gwar,
      label: entry.entity,
      x: entry.delinquence,
      r: entry.exposure * 70
    }
  }
};

function getInitialChartConfig(title: any): any {
  return {
    labels: [],
    dataSets: [
      {
        data: [],
        label: title
      }]
  }
}

export function prepareChartConfig(data: any[], config: any, toggle: any) {

  if (!data)
    data = [];

  const chartConfig = getInitialChartConfig(config.title);

  data.forEach(entry => {
    const label = config.getLabel ? config.getLabel(entry) : entry[config.label];
    const value = config.getValue ? config.getValue(entry, toggle) : entry[config.value];
    chartConfig.labels.push(label);
    chartConfig.dataSets[0].data.push(value);
  });

  return chartConfig;
}