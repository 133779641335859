import styled from "styled-components";
import { theme } from "../../assets/theme/colors";

export default styled.label<any>`
  ${props => props.completed && `
      svg {
        fill: ${theme.colors.primary} !important;
        height: 21px;
    
        g {
          fill: ${theme.colors.primary} !important;
        }
      }
  `}
`
