import React from 'react';
//Custom Components
import { DefinitionList, LinkView } from "../../../components";
//Helpers
import { isVDRInvestor } from "../../../../../helpers";

const HDRHome = () => {
  const list = [
    {
      term: <LinkView href="/hdr-documentation/hdr">Hyper Data Room</LinkView>,
      explanation: 'is the module that allows users to manage documents.'
    },
    {
      term: <LinkView href='/hdr-documentation/hdr-documents'>Documents</LinkView>,
      explanation: 'is the module that allows users to manage documents.'
    },
    {
      term: <LinkView href='/hdr-documentation/hdr-loans-management'>Loans Management</LinkView>,
      explanation: 'is the module that module allows the user to browse through the portfolio, ' +
        'and access different statistics and detailed information down to loan level information'
    },
  ];

  if (!isVDRInvestor()) {
    list.push({
      term: <LinkView href='/hdr-documentation/hdr-syncs'>Syncs</LinkView>,
      explanation: 'is the module that allows users (HDR servicer profiles only) to run data health ' +
        'checks on files and import new data tape in the platform. '
    })
  }

  return (
    <>
      <div>
        <p className="bold">The Hyper Data Room, or HDR, is an extremely secure cloud-based environment
          that enables users to conveniently share, store, and collaborate on sensitive data and files. </p>
      </div>
      <br/>
      <div>
        <DefinitionList
          data={list}
          header="The menu is always active on the left side of the application and has the following sections:"
        />
      </div>
    </>
  );
};

export default HDRHome;
