import { Access, Home, UserProfiles } from "../general_help/modules/home";
import {
  Dashboard,
  Geography,
  Performance,
} from "../general_help/modules/dashboard";
import SPVMngmt from "../general_help/modules/spv_management";
import {
  Accounts,
  CashflowNotesTab,
  Limits,
  NotesSection,
  Structure,
} from "../general_help/modules/spv_management/notes";
import {
  CashflowTab,
  CollateralSection,
  DiversificationTab,
  LoansTab,
  PerformanceTab,
} from "../general_help/modules/spv_management/collateral";
import {
  Breakdown,
  DebtorConcentration,
  GeographyConcentration,
  SectorConcentration,
} from "../general_help/modules/spv_management/concentration";
import {
  LoanPurchaseDetails,
  NotesMngmt,
} from "../general_help/modules/notes_manager";
import {
  AdvancedFiltering,
  AggregatedStatistics,
  LoansMngmt,
  OtherOptions,
} from "../general_help/modules/loans";
import {
  Collateral,
  DebtorDetails,
  DisabledViews,
  LoanDetails,
  LoanTimeline,
} from "../general_help/modules/loans/loan_details";
import {
  Analytics,
  Concentration,
  ContributorsToReturn,
  DelinquenciesAndLoss,
  DOD,
  MainStatistics,
} from "../general_help/modules/analytics";
import {
  DocumentsManagement,
  DownloadDocuments,
  ManageDocuments,
  OptionsDocument,
  ServicerView,
  UploadDocuments,
} from "../general_help/modules/documents_management";
import Glossary from "../general_help/modules/glossary";
import SyncReports from "../../sync_reports";

export const GeneralHelpTreeStructure = [
  { key: "home", children: [{ key: "access" }, { key: "user-profiles" }] },
  {
    key: "dashboard",
    children: [
      { key: "performance-dashboard" },
      { key: "geography-dashboard" },
    ],
  },
  {
    key: "spv-management",
    children: [
      {
        key: "notes-section",
        children: [
          { key: "structure-notes" },
          { key: "payments-notes" },
          { key: "limits-notes" },
          { key: "accounts-notes" },
        ],
      },
      {
        key: "collateral-section",
        children: [
          { key: "cashflow-collateral" },
          { key: "performance-collateral" },
          {
            key: "diversification-collateral",
            children: [
              { key: "breakdown-concentration" },
              { key: "geography-concentration" },
              { key: "sector-concentration" },
              { key: "debtor-concentration" },
            ],
          },
          { key: "loans-collateral" },
        ],
      },
    ],
  },
  { key: "notes-manager", children: [{ key: "loan-purchase-details" }] },
  {
    key: "loans-management",
    children: [
      { key: "aggregated-statistics-loans" },
      { key: "advanced-filtering-options-loans" },
      { key: "other-options-loans" },
      {
        key: "loan-details",
        children: [
          { key: "loan-timeline" },
          { key: "debtor-details" },
          { key: "collateral-details" },
          { key: "disabled-features-loans" },
        ],
      },
    ],
  },
  {
    key: "analytics",
    children: [
      { key: "main-statistics-analytics" },
      { key: "delinquencies-loss-analytics" },
      { key: "concentration-analytics" },
      { key: "contributors-to-return-analytics" },
      { key: "definition-of-default" },
    ],
  },
  {
    key: "documents-management",
    children: [
      { key: "other-options-documents" },
      { key: "download-documents" },
      {
        key: "documents-management-servicer",
        children: [{ key: "upload-documents" }, { key: "manage-documents" }],
      },
    ],
  },
  { key: "glossary" },
];

export const GeneralHelpRoutes = [
  {
    path: "home",
    key: "home",
    title: "Home",
    element: <Home />,
  },
  {
    path: "home/access",
    key: "access",
    title: "Access",
    element: <Access />,
  },
  {
    path: "home/user-profiles",
    key: "user-profiles",
    title: "User Profiles",
    element: <UserProfiles />,
  },
  {
    path: "dashboard",
    key: "dashboard",
    title: "Dashboard Module",
    element: <Dashboard />,
  },
  {
    path: "dashboard/performance-dashboard",
    key: "performance-dashboard",
    title: "Performance / Structure",
    element: <Performance />,
  },
  {
    path: "dashboard/geography-dashboard",
    key: "geography-dashboard",
    title: "Geography / Distribution",
    element: <Geography />,
  },
  {
    path: "spv-management",
    key: "spv-management",
    title: "SPV Management",
    element: <SPVMngmt />,
  },
  {
    path: "spv-management/notes-section",
    key: "notes-section",
    title: "Notes",
    element: <NotesSection />,
  },
  {
    path: "spv-management/notes-section/structure-notes",
    key: "structure-notes",
    title: "Structure",
    element: <Structure />,
  },
  {
    path: "spv-management/notes-section/payments-notes",
    key: "payments-notes",
    title: "Payments",
    element: <CashflowNotesTab />,
  },
  {
    path: "spv-management/notes-section/limits-notes",
    key: "limits-notes",
    title: "Limits",
    element: <Limits />,
  },
  {
    path: "spv-management/notes-section/accounts-notes",
    key: "accounts-notes",
    title: "Accounts",
    element: <Accounts />,
  },
  {
    path: "spv-management/collateral-section",
    key: "collateral-section",
    title: "Collateral",
    element: <CollateralSection />,
  },
  {
    path: "spv-management/collateral-section/cashflow-collateral",
    key: "cashflow-collateral",
    title: "Cash Flow",
    element: <CashflowTab />,
  },
  {
    path: "spv-management/collateral-section/performance-collateral",
    key: "performance-collateral",
    title: "Performance",
    element: <PerformanceTab />,
  },
  {
    path: "spv-management/collateral-section/diversification-collateral",
    key: "diversification-collateral",
    title: "Diversification",
    element: <DiversificationTab />,
  },
  {
    path: "spv-management/collateral-section/diversification-collateral/breakdown-concentration",
    element: <Breakdown />,
    key: "breakdown-concentration",
    title: "Breakdown",
  },
  {
    path: "spv-management/collateral-section/diversification-collateral/geography-concentration",
    title: "Geography",
    element: <GeographyConcentration />,
    key: "geography-concentration",
  },
  {
    path: "spv-management/collateral-section/diversification-collateral/sector-concentration",
    title: "Sector",
    element: <SectorConcentration />,
    key: "sector-concentration",
  },
  {
    path: "spv-management/collateral-section/diversification-collateral/debtor-concentration",
    title: "Debtor",
    element: <DebtorConcentration />,
    key: "debtor-concentration",
  },
  {
    path: "spv-management/collateral-section/loans-collateral",
    key: "loans-collateral",
    title: "Loans",
    element: <LoansTab />,
  },
  {
    path: "notes-manager",
    key: "notes-manager",
    title: "Notes Manager",
    element: <NotesMngmt />,
  },
  {
    path: "notes-manager/loan-purchase-details",
    key: "loan-purchase-details",
    title: "Loan Purchase Details",
    element: <LoanPurchaseDetails />,
  },
  {
    path: "loans-management",
    key: "loans-management",
    title: "Loans Management",
    element: <LoansMngmt />,
  },
  {
    path: "loans-management/aggregated-statistics-loans",
    key: "aggregated-statistics-loans",
    title: "Aggregated Statistics",
    element: <AggregatedStatistics />,
  },
  {
    path: "loans-management/advanced-filtering-options-loans",
    key: "advanced-filtering-options-loans",
    title: "Advanced Filtering Options",
    element: <AdvancedFiltering />,
  },
  {
    path: "loans-management/other-options-loans",
    key: "other-options-loans",
    title: "Other Options",
    element: <OtherOptions />,
  },
  {
    path: "loans-management/loan-details",
    key: "loan-details",
    title: "Loan Details",
    element: <LoanDetails />,
  },
  {
    path: "loans-management/loan-details/loan-timeline",
    key: "loan-timeline",
    title: "Loan Timeline",
    element: <LoanTimeline />,
  },
  {
    path: "loans-management/loan-details/debtor-details",
    key: "debtor-details",
    title: "Debtor Details",
    element: <DebtorDetails />,
  },
  {
    path: "loans-management/loan-details/collateral-details",
    key: "collateral-details",
    title: "Collateral",
    element: <Collateral />,
  },
  {
    path: "loans-management/loan-details/disabled-features-loans",
    key: "disabled-features-loans",
    title: "Disabled Features",
    element: <DisabledViews />,
  },
  {
    path: "analytics",
    key: "analytics",
    title: "Analytics",
    element: <Analytics />,
  },
  {
    path: "analytics/main-statistics-analytics",
    key: "main-statistics-analytics",
    title: "Main Statistics",
    element: <MainStatistics />,
  },
  {
    path: "analytics/delinquencies-loss-analytics",
    key: "delinquencies-loss-analytics",
    title: "Delinquencies / Loss Curve",
    element: <DelinquenciesAndLoss />,
  },
  {
    path: "analytics/concentration-analytics",
    key: "concentration-analytics",
    title: "Concentration Statistics",
    element: <Concentration />,
  },
  {
    path: "analytics/contributors-to-return-analytics",
    key: "contributors-to-return-analytics",
    title: "Contributors to Return",
    element: <ContributorsToReturn />,
  },
  {
    path: "analytics/definition-of-default",
    key: "definition-of-default",
    title: "Default Classification (DoD)",
    element: <DOD />,
  },
  {
    path: "documents-management",
    key: "documents-management",
    title: "Documents Management",
    element: <DocumentsManagement />,
  },
  {
    path: "documents-management/other-options-documents",
    key: "other-options-documents",
    title: "Other Options",
    element: <OptionsDocument />,
  },
  {
    path: "documents-management/download-documents",
    key: "download-documents",
    title: "Download Documents",
    element: <DownloadDocuments />,
  },
  {
    path: "documents-management/documents-management-servicer",
    key: "documents-management-servicer",
    title: "Documents Management (Servicer View)",
    element: <ServicerView />,
  },
  {
    path: "documents-management/documents-management-servicer/upload-documents",
    key: "upload-documents",
    title: "Upload Documents",
    element: <UploadDocuments />,
  },
  {
    path: "documents-management/documents-management-servicer/manage-documents",
    key: "manage-documents",
    title: "Manage Documents",
    element: <ManageDocuments />,
  },
  {
    path: "glossary",
    key: "glossary",
    title: "Glossary",
    element: <Glossary />,
  },
  {
    path: "sync-reports",
    key: "sync_dashboard",
    title: "Sync Reports",
    element: <SyncReports />,
  },
];
