import React, { useEffect, useState } from "react";
//Components
import { Spin } from "antd";
//Custom Components
import { FlatList } from "@cardoai/components";
import notifications from "../../../components/notifications";
//Helpers
import { callApi } from "../../../helpers";
import config from "./config"

interface IMainStatistics {
  exclude?: any,
  currency?: any,
  queryStr?: string,
  elementWidth?: number
}

const MainStatistics = (props: IMainStatistics) => {

  const {queryStr, exclude, currency, elementWidth} = props;

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean | any>(true);

  const fetchData = () => {
    let baseUrl = `/main/vehicle_stats/investment_management_statistics/?`;
    if (queryStr)
      baseUrl += queryStr;

    callApi({
      url: baseUrl,
      setLoading: setLoading,
      onSuccess: (data: any) => setData(data),
      onError: () => notifications.error(),
    })
  }

  useEffect(() => {
    fetchData();
  }, [queryStr]);

  const statsConfiguration = config(currency).filter((entity: any) => !exclude.includes(entity.key));

  return (
    <Spin spinning={loading} size='large'>
      <FlatList
        data={data}
        size='medium'
        scrollable={false}
        background="#d6e4ff"
        config={statsConfiguration}
        elementWidth={elementWidth}
        formatters={FlatList.getFormatters(statsConfiguration)}
      />
    </Spin>
  );
};

MainStatistics.defaultProps = {
  exclude: [],
  elementWidth: 150
};

export default MainStatistics;