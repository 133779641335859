import React from "react";
import { Checkbox } from "antd";
import { useWaiverContext } from "../../calculation_waiver/calculation_waiver_provider";

const UpdateCheckboxItem = ({ record }: any) => {
  const waiverContext: any = useWaiverContext();

  const onChange = (e: any) => {
    waiverContext.updateEditCheckedItems(record.loan, e.target.checked);
  };

  const selectedLoans = waiverContext.initialLoan;

  const loan = selectedLoans.find((l: any) => l.loan === record.loan);

  return <Checkbox checked={loan} onChange={onChange} />;
};

export default UpdateCheckboxItem;
