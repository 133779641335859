import React from "react";
//Components
import { Select } from "antd";
//Config
import { datasetSelection } from "../config";

interface ISelectTitle {
  selection?: any,
  setSelection?: any,
}

const SelectTitle = (props: ISelectTitle) => {
  const {selection, setSelection} = props;

  return (
    <Select
      bordered={false}
      size='small'
      value={selection}
      placeholder={selection}
      onChange={setSelection}
      style={{minWidth: 220}}>
      {datasetSelection.map(dataset => (
        <Select.Option key={dataset.identifier} value={dataset.identifier}>
          {dataset.label}
        </Select.Option>
      ))}
    </Select>

  )
};

export default SelectTitle;