import React from 'react';
//Custom Components
import { LegendTable } from "../../tables";
import { SmartChart } from '../../../../components';
//Helpers
import { formatters } from "../../../../helpers";
import { useRedirection } from "../../../../utils/hooks";
//Constants
import { theme } from "../../../../assets/theme/colors";
//Colors
const colors = theme.palette.SectorBlueColors;

interface ISectorChart {
  data?: any,
  vehicleId?: any,
  sectors?: any,
  redirectSourceSector?: any,
  singleVehicleView?: any,
  currency?: any,
}

const SectorContent = (props: ISectorChart) => {
  const {setRedirection}: any = useRedirection();
  const {data, vehicleId, sectors, redirectSourceSector, singleVehicleView, currency} = props;

  const onClick = (record: any) => {
    let sectorName: any;

    if (typeof record === "string")
      sectorName = record;
    else
      sectorName = record.sector;

    const selectedSector = sectors.find((sector: any) => sector.name === sectorName);

    redirect(selectedSector)
  };

  const redirect = (selectedSector: any) => {
    if (selectedSector) {
      const config: any = {
        rules: {
          sector: {
            selected: true,
            value: [selectedSector.id]
          },
        },
        source: redirectSourceSector
      };

      if (!singleVehicleView) {
        config.rules.selectedVehicles = {
          selected: true,
          value: [vehicleId]
        }
      }

      setRedirection({
        path: '/loans',
        config: config
      })
    }
  }

  return (
    <LegendTable
      data={data}
      height={350}
      title="Sectors"
      colors={colors}
      currency={currency}
      onClick={onClick}>
      <SmartChart
        data={data}
        height={250}
        colors={colors}
        onClick={onClick}
        showLegend={false}
        valueEntity="ratio"
        labelEntity="sector"
        types={['pie']}
        format={formatters.percentNoPrecision}/>
    </LegendTable>
  )
}

export default SectorContent;

