import React from 'react';
//Components
import { Input, Modal } from "antd";
//Custom Components
import { FieldSet } from "@cardoai/components";

interface Props {
  visible: boolean,
  onChange: (param: any) => void
  onToggle: () => void,
  editMode?: boolean
  currentTopicName?: string
}

export const AddTopicModal = (props: Props) => {
  const {visible, onChange, onToggle, editMode = false, currentTopicName} = props;

  const [topicName, setTopicName] = React.useState("");

  const onTopicNameChange = (event: any) => {
    setTopicName(event.target.value);
  }

  const onOk = () => {
    onChange(topicName);
  }

  return (
    <Modal destroyOnClose onOk={onOk} okText={editMode ? "Edit" : "Create"} centered
           closable={false} visible={visible} onCancel={onToggle}>
      <FieldSet
        autoFocus
        value={topicName}
        component={Input}
        label="Topic Name"
        onChange={onTopicNameChange}
        placeholder={currentTopicName ? currentTopicName : ""}/>
    </Modal>
  );
};

