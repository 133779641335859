import React from 'react';
//Icons
import { CheckCircleOutlined, QuestionOutlined, StopOutlined, WarningOutlined } from "@ant-design/icons";
//Constants
import { theme } from "../../../../../assets/theme/colors";

export const ERROR_TYPE = {
  'blocking': 'blocking',
  'warning': 'warning',
  'healthy': 'healthy',
  'missing': 'missing',
};

export const ERROR_CONFIG = {
  [ERROR_TYPE.blocking]: {
    icon: StopOutlined,
    color: theme.colors.error,
    lightColor: theme.colors.lightred,
    strongColor: theme.colors.error,
  },
  [ERROR_TYPE.warning]: {
    icon: WarningOutlined,
    color: theme.colors.warning,
    lightColor: theme.colors.lightyellow,
    strongColor: theme.colors.orange

  },
  [ERROR_TYPE.missing]: {
    icon: QuestionOutlined,
    color: theme.colors.blue,
    lightColor: theme.colors.lightblue,
    strongColor: theme.colors.geekblue
  },
  [ERROR_TYPE.healthy]: {
    icon: CheckCircleOutlined,
    color: theme.colors.success,
    lightColor: theme.colors.lightgreen,
    strongColor: theme.colors.stronggreen
  }
};

export const DocumentIcon = (props: any) => {
  const {type} = props;

  if (!type)
    return null;

  const details = ERROR_CONFIG[type];

  const Icon = details.icon;

  return (
    <Icon style={{color: details.color}}/>
  )
};




