import React from 'react';
//Components
import Form from "./form";
//Helpers
import { stringify } from "../../../../../../helpers";

const memoization = (prevProps: any, nextProps: any) => {
  const compare = (entity: any) => {
    const previous = prevProps[entity];
    const next = nextProps[entity];
    return JSON.stringify(previous) === JSON.stringify(next);
  }
  return compare("options") && compare("groups") && compare("loading") && compare("selected");
}

const Forms = React.memo((props: any) => {
  const {
    groups, options, selected, handleSelected, handleMultipleSelect, selectRecord,
    onChange, loading
  } = props;

  if (loading)
    return null;

  if (!options)
    return null;

  const getValue = (attr: any, index: number = 0, defaultValue: any = null) => {

    if (!groups || !groups.length)
      return defaultValue;

    const selectedGroup = groups[index];

    return selectedGroup[attr] || defaultValue;
  };

  const resetRestSelected = () => {
    handleMultipleSelect(["All"])
  }

  return groups.map((group: any, index: number) => (
    <Form
      loading={loading}
      options={options}
      selected={selected}
      key={stringify(index)}
      onChange={onChange(index)}
      selectRecord={selectRecord}
      handleSelected={handleSelected}
      resetRestSelected={resetRestSelected}
      group={getValue("selection", index)}
      records={getValue("records", index, [])}/>
  ))
}, memoization);

export default Forms;