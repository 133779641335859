import React from "react";
//Other Libs
import cx from "classnames";
//Components
import { CustomTable, Panel, SimpleEmpty } from "@cardoai/components";
//Helpers
import { addPercent, formatters, isEmpty } from "../../../../../../../../../../helpers";

const formatFulfillment = (value: any, format: any, currency?: any) => {
  if (format === "Amount")
    return formatters.currency({value, currency})
  if (format === "Percentage")
    return addPercent(parseFloat(value))
  return value;
}

const NewLoanSection = ({limits, currency}: any, index:number) => {
  return (
    <Panel
      key={index}
      defaultOpen
      collapsible={false}>
      <CustomTable>
        <thead>
        <tr>
          <th colSpan={2}>{limits.category}</th>
        </tr>
        </thead>
        <tbody>
        {!isEmpty(limits) ? limits?.records?.map((limit: any, index: number) => {

          const {name, fulfillment, formatter} = limit;

          const formattedValue = formatFulfillment(fulfillment, formatter, currency);

          return <tr key={index}>
            <td className={cx("alignLeft", {bold: index === 1 || index === limits.records.length - 1})}>{name}</td>
            <td
              className={cx("alignRight", {bold: index === 1 || index === limits.records.length - 1})}>{formattedValue}</td>
          </tr>
        }) : <SimpleEmpty/>}
        </tbody>
      </CustomTable>
    </Panel>
  );
}

export default NewLoanSection;