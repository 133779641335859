import React from 'react';
//Components
import { Button, Col, Row, Select, Tooltip } from "antd";
import { DateSelector, SmartTable } from "../../../../../../components";
//Styles
import { Styles } from "./styles";
//Other Libs
import queryString from "query-string";
//Icons
import { FileExcelOutlined } from '@ant-design/icons';
//Helpers
import { defaultNotesPaymentsColumns, extraNotesPayementsColumns, getEntity } from "./config";
import { Consumer } from "../../../../context";
import { Analytics, createFileName, downloadClientFile, formatServerDate } from "../../../../../../helpers";

interface Props {
  vehicleId?: any,
  type?: any,
  cashflowList?: any,
  currency?: any
  vehicle?: any
}

const supportsExtraColumns = (vehicle: any) => {
  const {functionalities_config} = vehicle;
  const key = "supports_dynamic_coupon_tranches";
  return functionalities_config.hasOwnProperty(key) && functionalities_config[key] === true;
}

const getTableColumns = (hasExtraColumns: boolean) => {
  return hasExtraColumns ? extraNotesPayementsColumns : defaultNotesPaymentsColumns;
}

const CashFlowNotes = (props: Props) => {
  const {vehicleId, type, cashflowList, vehicle} = props;

  const [dateRange, setDateRange] = React.useState([]);
  const [cashFlowTypes, setCashFlowTypes] = React.useState([]);
  const [filters, setFilters] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const reference: any = React.useRef(null);

  React.useEffect(() => {
    handleFilter();
  }, [dateRange, cashFlowTypes])

  const handleDateChange = (key: any) => {
    setDateRange(key);
  }

  const handleCashFlowType = (key: any) => {
    setCashFlowTypes(key);
  }

  const handleFilter = () => {
    const params: any = {};
    if (dateRange && dateRange.length) {
      const [fromDate, toDate] = dateRange;
      params.from_date = formatServerDate(fromDate);
      params.to_date = formatServerDate(toDate);
    }
    if (cashFlowTypes.length)
      params.cash_flow_type = cashFlowTypes;

    return setFilters(queryString.stringify(params))
  }
  const filtersQuery = () => {
    let finalFilters = filters;
    const str = `vehicle_id=${vehicleId}`;
    if (finalFilters)
      finalFilters += `&${str}`;
    else
      finalFilters = str;

    Analytics.dispatchEvent({
      category: 'Filters',
      action: 'Applied Filters at Payments',
      label: finalFilters
    });

    return finalFilters;
  }

  const downloadExcel = () => {
    const url = getEntity("downloadUrl", type);
    const count = reference.current && reference.current.getCount();

    const finalUrl = `${url}/?&count=${count}&${filtersQuery()}`;

    downloadClientFile({
      url: finalUrl,
      setLoading: setLoading,
      filename: createFileName("cashflows"),
    })

    Analytics.dispatchEvent({
      category: 'Download',
      action: 'Downloaded cashflow file at payments',
    });
  };

  const url = getEntity("url", type);
  const downloadUrl = getEntity("downloadUrl", type);
  const initialSort = getEntity("initialSort", type);
  const disableDownload = !downloadUrl;

  /*Extra condition for Fasanara vehicles and their extra info on notes cash flows*/
  const canViewExtraFasanaraColumns = supportsExtraColumns(vehicle);
  const tableColumns = getTableColumns(canViewExtraFasanaraColumns);

  return (
    <Styles>
      <Row justify="start" gutter={8} className="mb12">
        <Col>
          <DateSelector
            range
            allowClear
            fullDateRange
            value={dateRange}
            initialInterval="max"
            endOfMonthFormat={false}
            onChange={handleDateChange}/>
        </Col>
        <Col>
          <Select
            mode="multiple"
            size='small'
            value={cashFlowTypes}
            placeholder="Filter by Payment Type"
            onChange={handleCashFlowType}
            style={{minWidth: 220}}>
            {cashflowList.map((cashFlow: any) => (
              <Select.Option key={cashFlow.name} value={cashFlow.name}>
                {cashFlow.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Tooltip title="Download as Excel" placement='right'>
            <Button
              size='small'
              loading={loading}
              disabled={disableDownload}
              icon={<FileExcelOutlined/>}
              onClick={downloadExcel}/>
          </Tooltip>
        </Col>
      </Row>
      <SmartTable
        simpleFetch
        url={url}
        height={400}
        ref={reference}
        initialSort={initialSort}
        filters={filtersQuery()}
        columns={tableColumns}/>
    </Styles>
  );
};

export default Consumer(CashFlowNotes);