import React from 'react';
//Context
import { useDebtorContext } from "./debtor_waiver_provider";

const CalculationWaiver = ({ record }: any) => {
  const debtorContext: any = useDebtorContext();

  const value = record.company_id === debtorContext.valueChecked
    ? debtorContext.selectTypeOfCalculationWaiver
    : '-';

  return<>{value || '-'}</>;
};

export default CalculationWaiver;
