import React, { useRef, useState } from 'react';
import moment from "moment";
//Components
import { Col, DatePicker, Row } from 'antd';
//Custom Components
import { Visible } from '@cardoai/components';
import Intervals from "./intervals";
//Styles
import Styles from "./Styles";
//Helpers
import { endOfMonth, generateId, startOfMonth } from "../../../helpers";

interface Props {
  range?: any,
  unit?: any,
  value?: any,
  format?: any,
  allowClear?: any,
  onChange?: any,
  showPicker?: any,
  showMax?: any,
  initialInterval?: any,
  showIntervals?: any,
  size?: any,
  endOfMonthFormat?: any,
  picker?: any,
  fullDateRange?: any,
}

const {RangePicker} = DatePicker;
const now = moment();
const timeIntervals = {
  day: [
    {key: 7, label: "7 days"},
    {key: 3, label: "3 days"},
    {key: 1, label: "24 h"}
  ],
  months: [
    {key: 3, label: "3m"},
    {key: 6, label: "6m"},
    {key: 12, label: "1Yr"},
    {key: 36, label: "3Yr"}
  ]
};
const dateFormat = {
  'month': "MMM, YY",
  'daily': "MMM D, YY",
}
//Custom helpers
const getPeriod = (interval: any, unit: any, range = false, fullDateRange: any) => {
  const startDate = moment();
  const difference = startDate.subtract(interval, unit);

  if (range && fullDateRange && unit === "months") {
    const firstSelected = startOfMonth(difference, false)
    const secondSelected = endOfMonth(now, false)
    return [firstSelected, secondSelected]
  }

  if (range)
    return [difference, now];

  return difference;
};

const DateSelector = (props: Props) => {
  const {
    range, unit, value, format, allowClear, onChange, showPicker, showMax,
    initialInterval, showIntervals, size, endOfMonthFormat, picker, fullDateRange, ...rest
  } = props;


  const [activeInterval, setInterval] = useState<any>(initialInterval || 'max');

  let finalFormat = format;

  if (!finalFormat) { // @ts-ignore
    finalFormat = dateFormat[picker]
  }

  let DateSelect: any = range ? RangePicker : DatePicker;

  /*References*/
  const domReference = React.useRef(generateId());
  // @ts-ignore
  const intervals = useRef(timeIntervals[unit]);

  /*Methods*/
  const getDomReference = () => {
    return document.getElementById(domReference.current);
  };
  const onIntervalChange = (event: any, direct = false) => {
    const newInterval = direct ? event : event.target.value;
    let period = null;

    if (newInterval === "max") {
      onChange(null, 'max'); /*Set the state date range as null*/
      onSelect(null, 'max'); /*Set the visual date range selector to null*/
    } else {
      period = getPeriod(newInterval, unit, range, fullDateRange);
      onSelect(period, newInterval)
    }
    setInterval(newInterval);
  };

  const onSelect = (event: any, interval: any) => {

    /*Handles the case if allowClear is clicked to reset the state of the date ranges*/
    if (!event && onChange) {
      onChange([], 'max')
      setInterval('max')
    }

    if (event) {

      setInterval(null) /*Reset Interval on Date Change*/

      /*Modify the dates to consider start of month to the end of the month for monthly range */
      if (range && fullDateRange && picker === 'month') {
        const [firstDate, lastDate] = event;
        event[0] = startOfMonth(firstDate, false)
        event[1] = endOfMonth(lastDate, false)
      }

      /*Modify the dates to take only the end of the month*/
      if (endOfMonthFormat && picker === 'month')
        event = event.map((event: any) => endOfMonth(event, false));

      if (onChange)
        onChange(event, interval);

    }
  }

  return (
    <Styles {...rest} id={domReference.current}>
      <Row gutter={8} align="middle">
        <Col>
          <Visible when={showPicker}>
            <DateSelect
              size={size}
              value={value}
              picker={picker}
              onChange={onSelect}
              format={finalFormat}
              allowClear={allowClear}
              className="performancePeriod"
              getCalendarContainer={getDomReference}
            />
          </Visible>
        </Col>
        <Col>
          <Visible when={showIntervals}>
            <Intervals
              unit={unit}
              showMax={showMax}
              value={activeInterval}
              intervals={intervals}
              onChange={onIntervalChange}/>
          </Visible>
        </Col>
      </Row>
    </Styles>
  )
};

DateSelector.defaultProps = {
  unit: 'months',
  size: 'small',
  range: false,
  picker: 'month',
  showMax: true,
  allowClear: true,
  showPicker: true,
  showIntervals: true,
  fullDateRange: true,
  endOfMonthFormat: true,
};


export default DateSelector;