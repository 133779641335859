import React from "react";
//Components
import { CustomTable, SimpleEmpty } from "@cardoai/components";
//Helpers
import { formatThousands, hasValue, isEmpty } from "../../../../../../../../../helpers";

const renderTableHeaders = () => {
  return (
    <thead>
    <th/>
    <th>Recon Period Start</th>
    <th>Recon Period End</th>
    <th>Yr Base</th>
    <th>Amount</th>
    </thead>
  )
}


interface Props {
  data?: any,
  currencyName?: any
}

const ReconciliationTable = (props: Props) => {
  const {data, currencyName} = props;

  if (isEmpty(data))
    return <SimpleEmpty/>

  const formatValue = (record: any) => {
    if (!hasValue(record))
      return " - "
    return formatThousands(record);
  }


  return (
    <CustomTable hover={false} size='small'>
      {renderTableHeaders()}
      <tbody>
      <tr>
        <td>Assumption: General SPV costs</td>
        <td>{data.recon_period_start}</td>
        <td>{data.recon_period_end}</td>
        <td>{data.year_base}</td>
        <td>{`${formatValue(data.general_spv_costs)} ${currencyName}/year`}</td>
      </tr>
      <tr>
        <td>Provision for costs (SGD)</td>
        <td/>
        <td/>
        <td/>
        <td>{`${formatValue(data.provision_for_costs)} ${currencyName}/year`}</td>
      </tr>
      </tbody>
    </CustomTable>
  )
}

export default ReconciliationTable;
