import React from 'react';
//Google Analytics
import { Analytics } from "../../../../helpers";
//Components
import { Col, Select } from "antd";
import { DateOperator } from "@cardoai/components";

const SPVFilters = (props: any) => {
  const {
    filterAttr,
    getVehiclesValue,
    handleVehiclesChange,
    createVehicleOptions,
    getPoolAdditionDate,
    handlePoolAdditionDateChange
  } = props;

  const onVehicleChange = (event: any) => {
    handleVehiclesChange(event);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: 'Filtered Vehicles using selection above loans table',
      label: event
    });
  }

  const onPoolAdditionDateChange = (value: any) => {
    handlePoolAdditionDateChange(value);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: 'Filtered by Pool Addition Date using selection above loans table',
      label: value
    });
  }

  return (
    <>
      <Col>
        <div className="bolder">Filter by SPV</div>
        <Select
          allowClear
          mode="multiple"
          style={{width: 240}}
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          optionFilterProp="children"
          placeholder="Select Vehicles"
          value={getVehiclesValue()}
          onChange={onVehicleChange}>
          {createVehicleOptions()}
        </Select>
      </Col>
      <Col>
        <div className="bolder">Filter by Pool Addition Date</div>
        <DateOperator
          config={getPoolAdditionDate()}
          onChange={onPoolAdditionDateChange}/>
      </Col>
    </>
  );
};

export default SPVFilters;