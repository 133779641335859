import React from 'react';
//Components
import MainView from "./main_view";
import { SimpleEmpty } from "@cardoai/components";
//Utils
import { ACCOUNTS } from "./accounts_config";

interface Props {
  vehicle?: any,
  vehicleId?: any
}

const supportsAccountsFunctionality = (vehicle: any) => {
  const {functionalities_config: functionalities} = vehicle;
  const supportsAccountsKey = "supports_accounts";
  return functionalities.hasOwnProperty(supportsAccountsKey) && functionalities[supportsAccountsKey] === true;
}

const Accounts = (props: Props) => {
  const {vehicle, vehicleId} = props;
  const {vehicle_identifier} = vehicle;

  const canViewAccountsTab = supportsAccountsFunctionality(vehicle) && ACCOUNTS[vehicle_identifier];

  let content;

  if (canViewAccountsTab)
    content = <MainView vehicle={vehicle} vehicleIdentifier={vehicle_identifier} vehicleId={vehicleId}/>
  else
    content = <SimpleEmpty height={500} centered description="No Data Available Yet"/>;

  return content;
};


export default Accounts;