//Other Libraries
import styled from "styled-components";
//Constants
import { theme } from "../../../assets/theme/colors";

export default styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  text-align: center;
  padding: 4px 11px 4px;
  border: 1px dashed ${theme.palette.grayscale[2]};
  transition: border 0.3s, box-shadow 0.3s;
`