import React from 'react';
//Other Libs
import Loading from './loading';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../../../../package.json';

const withCacheService = (Component: any) => (props: any) => {
  return (
    <CacheBuster
      isVerboseMode={false}
      currentVersion={packageInfo.version}
      loadingComponent={<Loading/>}
      isEnabled={process.env.NODE_ENV === 'production'}>
      <Component {...props}/>
    </CacheBuster>
  );
};

export default withCacheService;