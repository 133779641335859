import { HDRAccess, HDRHome, HDROverview } from "../hdr_help/modules/hdr_home_content";
import HyperDataRoom from "../hdr_help/modules/hyper_data_room_content";
import HDRMainStatistics from "../hdr_help/modules/hyper_data_room_content/hdr_main_statistics";
import HDREnvironment from "../hdr_help/modules/hyper_data_room_content/hdr_environment";
import HDRSummaryStatistics
  from "../hdr_help/modules/hyper_data_room_content/hdr_environment/hdr_summary_statistics";
import HDRStratification
  from "../hdr_help/modules/hyper_data_room_content/hdr_environment/hdr_stratification";
import HDRDelinquenciesDistribution
  from "../hdr_help/modules/hyper_data_room_content/hdr_environment/hdr_delinquencies_distribution";
import HDRDefaultDistribution
  from "../hdr_help/modules/hyper_data_room_content/hdr_environment/hdr_default_distribution";
import HDRLosses from "../hdr_help/modules/hyper_data_room_content/hdr_environment/hdr_losses";
import HDRIRRGraph from "../hdr_help/modules/hyper_data_room_content/hdr_environment/hdr_irr_graph";
import HDRDocuments from "../hdr_help/modules/hdr_documents_content";
import HDRLoansManagement from "../hdr_help/modules/hdr_loans_management_content";
import HDRInformationBar from "../hdr_help/modules/hdr_loans_management_content/hdr_information_bar";
import HDRSyncs from "../hdr_help/modules/hdr_syncs_content";

export const HDRHelpTreeStructure = [
  {key: "hdr-home", children: [{key: "hdr-access"}, {key: "hdr-overview"}]},
  {
    key: "hdr", children: [
      {key: "hdr-main-statistics"},
      {
        key: "hdr-environment", children: [
          {key: "hdr-summary-statistics"},
          {key: "hdr-stratification"},
          {key: "hdr-delinquencies-distribution"},
          {key: "hdr-default-distribution"},
          {key: "hdr-losses"},
          {key: "hdr-irr-graph"},
        ]
      }
    ]
  },
  {key: "hdr-documents"},
  {key: "hdr-loans-management", children: [{key: "hdr-information-bar"}]},
  {key: "hdr-syncs"}
]

export const HDRHelpRoutes = [
  {
    path: 'hdr-home', key: "hdr-home",
    title: "HDR Home", element: <HDRHome/>
  },
  {
    path: 'hdr-home/hdr-access', key: "hdr-access",
    title: "Access", element: <HDRAccess/>
  },
  {
    path: 'hdr-home/hdr-overview', key: "hdr-overview",
    title: 'What is a Hyper Data Room?', element: <HDROverview/>
  },
  {
    path: 'hdr', key: 'hdr',
    title: 'Hyper Data Room', element: <HyperDataRoom/>
  },
  {
    path: 'hdr/hdr-main-statistics', key: 'hdr-main-statistics',
    title: 'Main Statistics', element: <HDRMainStatistics/>
  },
  {
    path: 'hdr/hdr-environment', key: 'hdr-environment',
    title: 'Hyper Data Room Environment', element: <HDREnvironment/>
  },
  {
    path: 'hdr/hdr-environment/hdr-summary-statistics', key: 'hdr-summary-statistics',
    title: 'Summary Statistics', element: <HDRSummaryStatistics/>
  },
  {
    path: 'hdr/hdr-environment/hdr-stratification', key: 'hdr-stratification',
    title: 'Stratification', element: <HDRStratification/>
  },
  {
    path: 'hdr/hdr-environment/hdr-delinquencies-distribution', key: 'hdr-delinquencies-distribution',
    title: 'Delinquencies Distribution', element: <HDRDelinquenciesDistribution/>
  },
  {
    path: 'hdr/hdr-environment/hdr-default-distribution', key: 'hdr-default-distribution',
    title: 'Default Distribution', element: <HDRDefaultDistribution/>
  },
  {
    path: 'hdr/hdr-environment/hdr-losses', key: 'hdr-losses',
    title: 'Losses', element: <HDRLosses/>
  },
  {
    path: 'hdr/hdr-environment/hdr-irr-graph', key: 'hdr-irr-graph',
    title: 'IRR graph', element: <HDRIRRGraph/>
  },
  {
    path: 'hdr-documents', key: 'hdr-documents',
    title: "Documents", element: <HDRDocuments/>
  },
  {
    path: 'hdr-loans-management', key: 'hdr-loans-management',
    title: 'Loans Management', element: <HDRLoansManagement/>
  },
  {
    path: 'hdr-loans-management/hdr-information-bar', key: 'hdr-information-bar',
    title: 'Main Information Bar', element: <HDRInformationBar/>
  },
  {
    path: 'hdr-syncs', key: 'hdr-syncs',
    title: 'Syncs', element: <HDRSyncs/>
  }
];