import React from 'react';
//Utils
import {useToggle} from "@cardoai/utils";
//Components
import {Button, Col, Row, Popover} from "antd";
//Icons
import MoreIcon from "../../../../../../../icons/more";
import DownloadIcon from "../../../../../../../icons/download";
//Custom Components
import MoreActions from "./more";
import ChooseStatus from "./choose_status";
import ChooseColumns from "../../../../../../../design/choose_columns";
import ColumnChooserIcon from "../../../../../../../icons/column_chooser";
import SmartSearch from '../../../../../../../design/smart_search';
import {useProposal} from "../../../../../../../context";


const Actions = ({
                     state,
                     selected,
                     groups,
                     setGroups,
                     status,
                     filters,
                     setStatus,
                     handleDownload,
                     updateFilters,
                     isFar
                 }: any) => {
    const [moreVisible, toggleMore]: any = useToggle(false);
    const [columnsChooser, toggleColumnsChooser]: any = useToggle(false);
    const {proposal} = useProposal();
    const showStatusMode = proposal.status === "Awaiting For Submission" ||  proposal.status === "Awaiting For Computation" ||  proposal.status === "Awaiting User Input"

    return (
        <Row align="middle" justify="space-around" gutter={4}>
            <Col>
              <SmartSearch
                width={450}
                onChange={(event: any) => {
                  updateFilters(event);
                }}
                config={[
                  {
                    type: 'input',
                    defaultOpen: true,
                    key: "transaction_id",
                    label: "Asset ID",
                    defaultValue: filters.transaction_id
                  },
                ]}/>
            </Col>
            <Col>
                <Popover
                    trigger="click"
                    open={columnsChooser}
                    placement="bottomRight"
                    onOpenChange={toggleColumnsChooser}
                    content={(
                        <ChooseColumns
                            value={groups}
                            config={[
                                {key: "Eligibility Criteria", label: "Eligibility Criteria"},
                                {key: "Additional Eligibility Criteria", label: "Additional Eligibility Criteria"},
                                {key: "Alternative Eligibility Criteria", label: "Alternative Eligibility Criteria"},
                                {key: "Parameters", label: "Parameters"}
                            ]}
                            onChange={setGroups}
                            onClose={toggleColumnsChooser}/>
                    )}>
                    <Button type="link" ghost icon={<ColumnChooserIcon active={!!columnsChooser}/>}/>
                </Popover>
            </Col>

           {
             showStatusMode &&
               <Col>
               <ChooseStatus
               setValue={setStatus}
               state={state}
               selected={selected}
               value={status}
               onChange={(e: any) => {
                 setStatus(e);
               }} />
             </Col>
           }
            <Col>
                <Button onClick={handleDownload} type="link" ghost icon={<DownloadIcon/>}/>
            </Col>
            <Col>
                <Popover
                    trigger="click"
                    showArrow={false}
                    open={moreVisible}
                    placement="bottomRight"
                    onOpenChange={toggleMore}
                    content={(
                        <MoreActions
                            selected={selected}
                            groups={groups}
                            filters={filters}
                            onClose={toggleMore}
                            setGroups={setGroups}
                            updateFilters={updateFilters}/>
                    )}
                >
                    <Button type="link" ghost icon={<MoreIcon/>}/>
                </Popover>
            </Col>
        </Row>
    );
};

export default Actions;