import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CollateralCard } from "./components";
import { SimpleEmpty } from "@cardoai/components";


const AssetDetails = ({visibleAssets}: any) => {
  return (
    <>
      <Row gutter={[8, 8]} justify="space-around" align="middle">
        {visibleAssets.length ? visibleAssets.map((collateralAsset: any) => {
          return (
            <Col span={6}>
              <CollateralCard collateral={collateralAsset}/>
            </Col>
          )
        }) : <SimpleEmpty description="No matches found!"/>}
      </Row>
    </>
  );
};

export default AssetDetails;