import React from "react";
//Other Libraries
import { Navigate, useLocation } from "react-router-dom";
//Providers
import { useAuthentication } from "../utils/hooks";
import { RedirectionProvider } from "../utils/context/redirection_provider";
import { AuthorizationProvider } from "../utils/context/authorization_provider";


const PrivateRoute = ({children, redirectTo}: any) => {
  const authentication = useAuthentication();
  const location = useLocation();

  return authentication.authenticated ? (
    <AuthorizationProvider>
      <RedirectionProvider>
        {children}
      </RedirectionProvider>
    </AuthorizationProvider>
  ) : (
    <Navigate to={redirectTo} state={{from: location}}/>
  )
}

export default PrivateRoute;