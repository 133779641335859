import React from 'react';
//Components
import { Tabs } from "antd";
//Custom Components
import { SimpleEmpty } from "@cardoai/components"
import { ChatContainer } from "./components/chat";
//Helpers
import { useVehicles } from "../../utils/context/api_global_provider/api";


const {TabPane} = Tabs;

const QuestionsAndAnswers = () => {

  const vehicles = useVehicles();
  const allVehicles = vehicles.data;

  if (!allVehicles)
    return <SimpleEmpty/>

  return (
    <Tabs>
      {allVehicles.map((vehicle: any) => {
        return (
          <TabPane key={vehicle.id} tab={vehicle.name}>
            <ChatContainer vehicle={vehicle}/>
          </TabPane>
        )
      })}
    </Tabs>
  );
};

export default QuestionsAndAnswers;