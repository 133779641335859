const datasetKeys = ['expected_loss_percentage', 'actual_loss_percentage'];

const datasetRadioConfig: any = [
  {key: 'all', label: 'All'},
  {key: 'expected_loss_percentage', label: 'Expected loss %'},
  {key: 'actual_loss_percentage', label: 'Actual loss %'},
]

export {
  datasetRadioConfig,
  datasetKeys
}