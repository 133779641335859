import React from 'react';
//Components
import { Button, Card, Col, Row } from "antd";
import ListTemplates from "./components/templates";
import EditTemplate from "./components/templates/edit_template";
import CreateTemplate from "./components/templates/create_template";
//Icons
import { DatabaseOutlined } from "@ant-design/icons";


const ExcelZilla = () => {
  const [view, setView] = React.useState<any>("load");
  const [template, setTemplate] = React.useState<any>(null);

  const clearView = () => {
    setView(null)
    clearTemplate()
  }

  const clearTemplate = () => {
    setTemplate(null)
  };

  const onCreate = (record: any) => {
    setView("create");
    clearTemplate()
  }

  const onEdit = (template: any) => {
    setView("edit");
    setTemplate(template);
  }

  const onLoad = () => {
    setView("load");
    clearTemplate();
  }

  if (view === "load") {
    return (
      <ListTemplates
        onEdit={onEdit}
        onCreate={onCreate}
        onCancel={clearView}/>
    )
  }

  return (
    <Card size="small" style={{paddingBottom: 64}}>
      {view && view !== "load" && (
        <Row gutter={8} justify="end" className="mb16">
          <Col>
            <Button icon={<DatabaseOutlined/>} onClick={onLoad}>
              Open Templates
            </Button>
          </Col>
        </Row>
      )}
      {view === "create" && <CreateTemplate onCancel={clearView}/>}
      {template && view === "edit" && <EditTemplate templateId={template.id} onCancel={onLoad}/>}
    </Card>
  );
};

export default ExcelZilla;