import React from 'react';
//Components
import Layout from "./layout";
//Other Libraries
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
//External Services
import {useAuthorization} from "../../utils/hooks";


const AppRouter = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const authorization = useAuthorization();
    const routes = authorization.extractRoutes();

    React.useEffect(() => {
        if (!routes.length)
            return;

        const currentPath = location.pathname.replaceAll("/", "");

        if (routes.find((route: any) => currentPath.includes(route.path)))
            return;

        navigate(routes[0].path)
    }, [authorization.role])

    return (
        <Routes>
            {routes.map((route: any) => {
                const Component = route.component;
                const path = `${route.path}/*`;
                return (
                    <Route
                        key={route.key}
                        path={path}
                        element={
                            <Layout route={route}>
                                <Component/>
                            </Layout>
                        }
                    />
                );
            })}
        </Routes>
    )
};

export default AppRouter;
