import React from 'react';
//Components
import { Button, Col, Row, Tooltip } from "antd";
import { Break, Panel, SimpleEmpty } from "@cardoai/components";
import { SmartTable } from "../../../../../../components";
import Charts from "./chart";
//Helpers
import { createFileName, downloadClientFile, isEmpty } from "../../../../../../helpers";
import { detailedTable, tableFields, tableFieldsGroups } from "../../../../config";
//Icons
import { FileExcelOutlined } from "@ant-design/icons";
import { useAuthorization } from "../../../../../../utils/hooks";

function unifyData(data = []) {
  const records: any = {};

  function createRecord(name: string | number, record: any) {
    const date = record.date;

    if (!records.hasOwnProperty(date))
      records[date] = {};

    records[date][name] = record;
  }

  data.forEach((item: any) => {
    item.projection.forEach((projection: any) => {
      createRecord(item.name, projection)
    })
  });

  let totalRecords = [];

  for (let date in records) {
    if (records.hasOwnProperty(date)) {
      totalRecords.push({
        date: date,
        records: records[date]
      })
    }
  }

  // @ts-ignore
  totalRecords.sort((a, b) => new Date(a.date) - new Date(b.date));

  return totalRecords;
}

interface Props {
  data?: any
}

const SimulationResults = (props: Props) => {
  const {data} = props;

  const {isDemoUser} = useAuthorization();

  if (!data)
    return null;

  if (isEmpty(data))
    return <SimpleEmpty/>

  const downloadExcel = () => {
    downloadClientFile({
      filename: createFileName("cash_flow_simulation"),
      url: '/'
    })
  }

  const aggregatedRecords = data.map((record: any) => ({name: record.name, ...record.summary}));

  return (
    <>
      <Row justify='end' align="middle" gutter={8} className="mb8">
        <Col>
          <Tooltip title="Download as Excel">
            <Button
              disabled
              onClick={downloadExcel}
              icon={<FileExcelOutlined/>}>
              Download Excel
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <SmartTable
        columns={tableFields(isDemoUser())}
        columnGroups={tableFieldsGroups}
        externalData={aggregatedRecords}/>
      <Break size='large'/>
      <Charts scenarios={data} data={unifyData(data)}/>
      {data.map((record: any) => (
        <Panel header={record.name}>
          <SmartTable
            centerData
            centerHeaders
            height={400}
            columns={detailedTable}
            externalData={record.projection}/>
        </Panel>
      ))}
    </>
  )
}

export default SimulationResults;
