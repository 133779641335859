import React from 'react';
//Components
import { Col, Row } from 'antd';
import { config } from './config';
import { useToggle } from '@cardoai/utils';
import { useProposal } from '../../../../context';
import Segment from '../../../../design/segment';
import { callApi } from '../../../../../../helpers';
import Container from '../../create/components/container';
import WarningModal from '../../../../design/warning_modal';
import { configFarIntegration } from './config_far_integration';

const Submit = ({ footer }: any) => {
  const { proposal } = useProposal();
  const [visibleWarning, toggleWarning] = useToggle(false);
  const [category, setCategory] = React.useState('waterfall');
  const [categoryIntegration, setCategoryIntegration] = React.useState('inventory');

  const { onClose, vehicleId, proposalId, inProgress, toggleProgress, isFar } = useProposal();

  const execute = () => {
    callApi({
      body: {},
      method: 'put',
      setLoading: toggleProgress,
      onSuccess: () => onClose(),
      url: `/main/notes_manager/${proposalId}/submit/?vehicle_id=${vehicleId}`,
    });
  };
  return (
    <Container
      {...footer}
      loading={inProgress}
      confirmLabel={!proposal?.warnings_on_submission && 'Are you sure to confirm this operation ?'}
      onNext={() => {
        if (!proposal?.warnings_on_submission)
          execute();
        else
          toggleWarning();
      }}
    >
      <Row gutter={[16, 32]}>
        <Col xs={12}>
          <Segment
            isFar={isFar}
            value={isFar ? category : categoryIntegration}
            config={isFar ? config : configFarIntegration}
            onChange={isFar ? setCategory : setCategoryIntegration} />
        </Col>
        {
          !isFar ? configFarIntegration.map(({ key, component: Component }: any) => (
              <Col key={key} xs={24} style={key === categoryIntegration ? { display: 'block' } : { display: 'none' }}>
                <Component category={categoryIntegration} />
              </Col>
            )) :
            config.map(({ key, component: Component }: any) => {
                return (
                  <Col key={key} xs={24} style={key === category ? { display: 'block' } : { display: 'none' }}>
                    <Component category={category} />
                  </Col>
                );
              },
            )}
      </Row>
      <WarningModal
        onOk={execute}
        open={visibleWarning}
        onCancel={toggleWarning}
        title={proposal?.warnings_on_submission} />
    </Container>
  );
};

export default Submit;