import React, { useEffect, useState } from 'react';
//Components
import { Col, Row } from "antd";
//custom Components
import DateSelector from "../../../components/form_elements/date_selector";
//Custom  Components
import Download from "./download";
import Content from "./content";
//Helpers
import { callApi, formatServerDate } from '../../../helpers';
//Utils
import Cache, { initializeCache, verifyCache } from "./cache";
//Constants
const positions: any = {
  left: "start",
  middle: "center",
  right: "end",
  between: 'space-between'
};

const willRender = (props: any, params: any) => {
  return params.map((param: any) => props[param]).toString()
};


const withData = (configurations: any) => (WrappedComponent: any) => (props: any) => {
  let {
    url, urlFormatter, initialState, formatResponse, usesDateFilters, showIntervals, headerPosition,
    spacing, downloadUrlFormatter, Extra, initialLoading = true, clearDataOnError = true,
    endOfMonthFormat = true, usesCache = true, fullDateRange = true, allowClear = true, initialInterval
  } = configurations;

  if (!headerPosition)
    headerPosition = "left";

  if (!spacing)
    spacing = 8;

  const showExtra = !!Extra;
  const showDownload = !!downloadUrlFormatter;

  let {extraParams} = configurations;

  if (!extraParams)
    extraParams = [];

  const [mainUrl, setMainUrl] = useState<any>(null);
  const [data, setData] = useState(getInitialState('data', null));
  const [loading, setLoading] = useState(getInitialState('loading', false));
  const [period, setPeriod] = useState(getInitialState('period', []));

  const shouldReRender = willRender(props, extraParams);

  const prepareParams = () => {
    const params: any = {};
    if (usesDateFilters && period && period.length) {
      const [fromDate, toDate] = period;
      params.from_date = formatServerDate(fromDate);
      params.to_date = formatServerDate(toDate);
    }
    return params;
  };

  const handleFiltersChange = () => {
    const preparedUrl = url || urlFormatter({props: props, params: prepareParams()});
    setMainUrl((previousUrl: any) => {
      return previousUrl !== preparedUrl ? preparedUrl : previousUrl
    })
  };

  useEffect(() => {
    handleFiltersChange();
  }, [period, shouldReRender]);


  useEffect(() => {
    if (mainUrl !== null) {
      requestData();
    }
  }, [mainUrl]);


  const onSuccess = (response: any) => {
    if (formatResponse)
      response = formatResponse(response, props);
    initializeCache(mainUrl, response);
    setData(response);
  };

  const onError = (error: any) => {
    if (clearDataOnError)
      setData(null);
  }

  const onFinish = () => {
    setLoading(false)
  }


  const requestData = () => {
    if (usesCache) {
      if (!verifyCache(mainUrl))
        setLoading(true);
    } else {
      setLoading(true);
    }

    callApi({
      url: mainUrl,
      onError: onError,
      onFinish: onFinish,
      onSuccess: onSuccess,
    })
  };

  function getInitialState(entity: any, defaultValue: any) {
    if (initialState && (entity in initialState))
      return initialState[entity];
    return defaultValue;
  }


  let finalData;

  if (usesCache) {
    if (verifyCache(mainUrl)) {
      finalData = Cache.api[mainUrl].records;
    }
  } else {
    finalData = data;
  }

  const showRowInformation = usesDateFilters || showDownload || showExtra;

  const viewConfig = {props, params: prepareParams()};

  return (
    <section>
      {showRowInformation && <Row
        gutter={8}
        align="middle"
        style={{padding: spacing, paddingTop: 0}}
        justify={positions[headerPosition]}>
        {usesDateFilters && <Col>
          <DateSelector
            range
            value={period}
            onChange={setPeriod}
            allowClear={allowClear}
            fullDateRange={fullDateRange}
            showIntervals={showIntervals}
            initialInterval={initialInterval}
            endOfMonthFormat={endOfMonthFormat}/>
        </Col>}
        {showDownload && <Col>
          <Download config={viewConfig} getUrl={downloadUrlFormatter}/>
        </Col>}
        {showExtra && <Col>
          <Extra config={viewConfig}/>
        </Col>}
      </Row>}
      <Content
        {...props}
        data={finalData}
        loading={loading}
        Child={WrappedComponent}
        requestData={requestData}
        queryParams={prepareParams()}
        initialLoading={initialLoading}/>
    </section>
  )
};

export default withData;