import React from 'react';
//Constants
import { theme } from "../../../../../assets/theme/colors";

const withDataLabel = (type: string, color: any) => ({record}: any) => {
  const styles = {
    color: color,
    width: '100%',
    fontSize: 12
  };
  let value;

  if (!record.warning_errors && !record.blocking_errors)
    value = 0;
  else
    value = record[type];

  if (value === 0)
    styles.color = theme.colors.success;

  return (
    <div style={styles}>
      {value}
    </div>
  );
};

const Warning = withDataLabel("warning_errors", theme.colors.warning);

const Blocking = withDataLabel("blocking_errors", theme.colors.error);

export default {
  Warning,
  Blocking
}
