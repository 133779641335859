import React from 'react';
//Custom Components
import config from "../../../../../../../../../../constants/config";

interface IFundingFields {
  data?: any,
  height?: any,
  onFieldsRemove?: any,
  vehicleId?: any,
  onFieldsChange?: any,
  vehicleIdentifier?: any
}

const FundingFields = (props: IFundingFields) => {

  const {data, height, onFieldsChange, onFieldsRemove, vehicleId, vehicleIdentifier} = props;

  const hideDeleteButton = data.length === 1;

  const {fundingView: Component} = config[vehicleIdentifier].notesManager;


  const fundingContent = data.map((funding: any, index: any) => {
    return (
      <Component vehicleIdentifier={vehicleIdentifier} key={index} funding={funding} hideDeleteButton={hideDeleteButton}
                 index={index} onFieldsChange={onFieldsChange} vehicleId={vehicleId} onFieldsRemove={onFieldsRemove}/>
    )
  })
  return (
    <div style={{height: height, overflow: "auto", paddingRight: 10}}>
      {fundingContent}
    </div>
  )
};


export default FundingFields;
