import React from 'react';
//Configuration
import { columns } from "./config";
//Icons
import { SearchOutlined } from "@ant-design/icons";
//Components
import { Button, Input, Popconfirm, Row, Skeleton } from "antd";
//Utils
import { mediatorSend, useDebounce } from "@cardoai/utils";
//Context
import { useDebtorContext } from "./debtor_waiver_provider";
//Custom Components
import { SmartTable } from "../../../../../../../../../components";
//Helpers
import { callApi, useQuery } from "../../../../../../../../../helpers";
//Components
import SelectWaiverType from "../select_waiver_type";
import { WAIVER_TYPE } from "../../create_waiver_request/create_waver_request";

interface Props {
  limitId?: any,
  vehicleId?: any,
  reason: string,
  startDate: any,
  endDate: any,
}

const DebtorWaiver = ({ vehicleId, limitId, reason, startDate, endDate }: Props) => {
  const [displayModal, setDisplayModal] = React.useState(false);
  const [searchDebtor, setSearchDebtor] = React.useState('');
  const searchDebtorVat = useDebounce(searchDebtor, 1000);
  const debtorContext: any = useDebtorContext();

  const dataDebtorWaiver = useQuery({
    deps: [vehicleId, searchDebtorVat],
    url: () => `main/limit_waiver/get_list_of_debtors_to_waiver/?vehicle_id=${vehicleId}&company=${searchDebtorVat}`
  });

  if (!dataDebtorWaiver.data)
    return <Skeleton/>

  const finalData = dataDebtorWaiver?.data.results
  const company = finalData.find((item: any) => item.company_name);

  const handleSearchDebtor = (event: any) => {
    setSearchDebtor(event.target.value)
  }

  const onConfirm = () => {
    const body: any = {
      company:  debtorContext.valueChecked,
      limit: limitId,
      waiver_type: WAIVER_TYPE.CALCULATION_EXCLUSION,
      exclusion_type: debtorContext.selectTypeOfCalculationWaiver,
    };

    if (reason !== '') body['reason'] = reason;
    if (startDate !== '') body['start_date'] = startDate.format("YYYY-MM-DD");
    if (endDate !== '') body['end_date'] = endDate.format("YYYY-MM-DD");

    callApi({
      url: `main/limit_waiver/?vehicle_id=${vehicleId}`,
      method: "POST",
      body: body,
      onSuccess: () => {
        mediatorSend('Limits', 'refreshLimits', undefined);
      },
      successMessage: "Waiver submitted for approval!"
    });
  }

  const openModal = () => {
    setDisplayModal(true);
  }

  const onCancel = (value: any) => {
    debtorContext.updateTypeOfCalculationWaiver(value)
    setDisplayModal(false);
  }

  return (
    <>
      <Row justify="end">
        <Input
          allowClear
          size="middle"
          style={{width: 200}}
          value={searchDebtor}
          onChange={handleSearchDebtor}
          prefix={<SearchOutlined/>}
          placeholder="Search by Debtor/VAT"/>
      </Row>
      <SmartTable
        columns={columns}
        externalData={finalData}
        height={500}
      />
      <Row justify="space-between" className="mt16">
        <Button
          onClick={openModal}
          type="primary"
        >
          Confirm
        </Button>
        <SelectWaiverType
          showModal={displayModal}
          onCancel={onCancel}
        />
        <Popconfirm
          title={`You are waiving all loans belonging to the debtor ${company?.company_name} with an exclusion type of ${debtorContext?.selectTypeOfCalculationWaiver}.`}
          onConfirm={onConfirm}
        >
          <Button type="primary">
            Submit
          </Button>
        </Popconfirm>
      </Row>
    </>
  );
};

export default DebtorWaiver;
