import React from 'react';
//Components
import { Panel } from "@cardoai/components";
import { MainTable, OtherTables } from "../index";
//Configurations
import { config } from "../../config";


interface Props {
  vehicleId: any,
  date: any,
  currency: any,
  currencies: any
}

const AccountsTables = (props: Props) => {
  const {vehicleId, date, currency, currencies} = props;

  let currencyConfig = currency;

  if (currencies && currencies.length)
    currencyConfig = currencies.find((item: any) => item.abbreviation === currency);

  return (
    <div>
      <p className="textCenter bolder">Information on Accounts in {currency}</p>
      <Panel header="Detailed Assets Sale" defaultOpen>
        <MainTable currencyConfig={currencyConfig} key={vehicleId} vehicleId={vehicleId} columns={config.mainTable} date={date}/>
      </Panel>
      <OtherTables currencyConfig={currencyConfig}  key={vehicleId} vehicleId={vehicleId} height={450} date={date}/>
    </div>
  );
};

export default AccountsTables;