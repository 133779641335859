//External Libraries
import Big from 'big-js';
//Other Formatters
import { isNumber } from "@cardoai/helpers";

function areValid(num1?: any, num2?: any) {
  return !(num1 === null || num1 === undefined || num1 === '' || num2 === null || num2 === undefined || num2 === '');
}

function add(num1?: any, num2?: any) {
  if (!areValid(num1, num2))
    return null;
  const result = new Big(num1).plus(num2).toString();
  return parseFloat(result);
}

function subtract(num1?: any, num2?: any) {
  if (!areValid(num1, num2))
    return null;

  const result = new Big(num1).minus(num2).toString();

  return parseFloat(result);
}

function multiply(num1?: any, num2?: any, toFixed?: any) {
  if (!areValid(num1, num2))
    return null;

  const result = new Big(num1).times(num2).toFixed(toFixed);

  return parseFloat(result);
}

function divide(num1?: any, num2?: any, toFixed?: any) {
  if (!areValid(num1, num2))
    return null;

  const result = new Big(num1).div(num2).toFixed(toFixed);

  return parseFloat(result);
}

//Returns a number rounded to the nearest integer value
export function round(value?: any, precision = 4) {
  if (!isNumber(value))
    return null;

  const factor = Math.pow(10, precision);
  return Math.floor(value * factor) / factor;
}

export const MathOp: any = {
  add,
  round,
  subtract,
  multiply,
  divide
};