import React from "react";
//Components
import { Card, Row } from "antd";
import { FlatList } from "@cardoai/components";
//Configurations
import { loanHeader } from "../config";

const LoanHeader = (props: any) => {
  const {data, currency} = props;
  return (
    <Card size='small' style={{padding: "4px 0px"}}>
      <p className="bold">Loan Information</p>
      <Row gutter={8} justify="space-between" align='bottom' className="mt8">
        <FlatList
          data={data}
          size='medium'
          space={false}
          elementWidth={150}
          config={loanHeader(currency)}
        />
      </Row>
    </Card>
  )
};

export default LoanHeader;