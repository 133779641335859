import React from 'react';
//Components
import { Button, Tooltip } from "antd";
//Icons
import { ArrowRightOutlined } from "@ant-design/icons";

interface Props {
  record: any,
  title: string,
  onClick: any
}

const CardTitle = (props: Props) => {
  const {record, title, onClick} = props;

  const handleClick = () => {
    onClick(record);
  }

  return (
    <Tooltip title="Go to Loans Management">
      <Button
        block
        onClick={handleClick}>
        <span className="bold">{title}</span> <ArrowRightOutlined style={{fontSize: 14}} className="pointerCursor"/>
      </Button>
    </Tooltip>
  );
};

export default CardTitle;



