import React from "react";

const ChartContainer = (props: any) => {
  return React.createElement("div", {
    style: {
      position: 'relative',
      width: props.dimension,
      height: props.dimension,
    },
    children: props.children
  })
};


const ChartElement = (props: any) => {
  return React.createElement("div", {
    style: {
      top: '50%',
      left: '50%',
      zIndex: props.zIndex,
      position: "absolute",
      transform: 'translate(-50%,-50%)'
    },
    children: props.children
  })
}

export default {
  Container: ChartContainer,
  Element: ChartElement
}