//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "./colors";

export default styled.div`
  .content {
    padding: 0;
    background: ${theme.colors.darkwhite};
    position: relative;
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1976D2;
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${theme.palette.secondary[1]};

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .layout {
    flex-direction: row;
    overflow-x: hidden;
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.palette.border[0]};
    height: 100%;
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    }
  }

  .footer {
    height: 41px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #ededed;
    background: ${theme.colors.darkwhite};
  }

  .ant-layout-footer {
    font-size: 13px;
    padding: 10px 10px;
  }
`;

