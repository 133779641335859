import React from 'react';
//Components
import { Button, Col, Modal, Row, Select, Skeleton, Tooltip } from 'antd';
import { SmartChart } from "../../../../components";
import { CustomTable, SimpleEmpty } from "@cardoai/components";
//Icons
import { BarChartOutlined } from '@ant-design/icons';
//Helpers
import { Analytics, formatters, isEmpty } from "../../../../helpers";
//Configurations
import { getYear, sortedHeaders } from "./config";
import { prepareVehicleContentUrl } from "../../../spv/components/management/helpers";
import { withData } from "../../../../utils";

const Option = Select.Option;

const SummaryStats = ({data}: any) => {

  const [chart, setChart] = React.useState<any>(null);
  const [selectedYears, setSelectedYears] = React.useState<any>([]);
  const years = data.map((d: any) => d.year);


  React.useEffect(() => {
    if (data && data.length)
      setSelectedYears([...years])
  }, [])

  if (!data)
    return <Skeleton/>

  if (isEmpty(data))
    return <SimpleEmpty/>


  const handleYearSelect = (event: any) => {
    setSelectedYears(event);
    Analytics.dispatchEvent({
      category: 'User',
      action: '(HDR user): Selected years at Summary Statistics',
      label: event
    });
  }
  let headers: any = [];
  const initialObject = data[0];

  for (let key in initialObject) {
    if (initialObject.hasOwnProperty(key) && key !== 'year') {
      const currentHeader = sortedHeaders.find((r: any) => (!r.hidden && r.key === key));
      if (currentHeader)
        headers.push({
          key: key,
          format: currentHeader.format,
          order: currentHeader ? currentHeader.order : 0,
          label: currentHeader.label
        })
    }
  }

  headers.sort((a: any, b: any) => a.order - b.order)

  const handleCancel = () => {
    setChart(null)
  };

  let allValues: any = [];

  headers.map(({key}: any) => {
    let valuesArray: any = []
    selectedYears.map((y: any) => {
      const records: any = data.find((d: any) => d.year === y);
      let values = records && records[key];
      valuesArray.push(values);
    })
    allValues.push(valuesArray);
  })


  const openModal = (key: any, index: any) => {
    const field: any = sortedHeaders.find(h => h.key === key);
    setChart({
      title: field.label,
      formatter: field.format,
      dataset: selectedYears.map((y: any, yearIndex: any) => ({
        label: getYear(y),
        value: allValues[index][yearIndex],
      }))
    })
  }

  return (
    <>
      <Row justify='end' align='middle' gutter={16} className="mb16">
        <Col className="bolder">
          Filter by Year/s:
        </Col>
        <Col>
          <Select size='small' placeholder='Filter By Year' value={selectedYears} onChange={handleYearSelect}
                  showArrow autoFocus allowClear style={{minWidth: 120}} mode='multiple'>
            {years.map((year: any) => {
              return (
                <Option
                  value={year}
                  selected={year}>
                  {getYear(year)}
                </Option>
              )
            })}
          </Select>
        </Col>
      </Row>
      {isEmpty(selectedYears) ? <SimpleEmpty description="Please select a year to view data"/> : <CustomTable>
        <thead>
        <tr>
          <th/>
          <th>
          </th>
          {selectedYears.map((y: any) => <th>{getYear(y)}</th>)}
        </tr>
        </thead>
        <tbody>
        {headers.map(({key, format, label}: any, index: number) => {
          return (
            <tr>
              <td>
                {label}
              </td>
              <td className="pointerCursor" onClick={() => openModal(key, index)}>
                <Tooltip title="Display Chart">
                  <Button type="link" icon={<BarChartOutlined/>} size='small'/>
                </Tooltip>
              </td>
              {selectedYears.map((y: any) => {
                let finalValue;
                const record: any = data.find((d: any) => d.year === y);
                finalValue = record && record[key];
                if (format) {
                  finalValue = format(finalValue);
                } else
                  finalValue = formatters.default(finalValue);
                return (
                  <td>
                    {finalValue === null ? format(0) : finalValue}
                  </td>
                )
              })}
            </tr>
          )
        })}
        </tbody>
        {chart && <Modal
          footer={null}
          closable={false}
          visible={!!chart}
          bodyStyle={{padding: 8}}
          onCancel={handleCancel}>
          <SmartChart
            usesMenu
            height={200}
            types={['bar']}
            sortAsc={false}
            showLegend={false}
            labelEntity="label"
            valueEntity="value"
            title={chart.title}
            data={chart.dataset}
            format={chart.formatter}/>
        </Modal>}
      </CustomTable>}
    </>
  );
};

export default withData({
  urlFormatter: prepareVehicleContentUrl('/main/vdr/vdr')
})(SummaryStats);
