import React from 'react';
//Components
import { BoldText } from "@cardoai/components";
import { SmartChart } from "../../../../../../components";
//Colors
import { theme } from "../../../../../../assets/theme/colors";

const CollateralBarChart = ({data, categoryName}: any) => {
  return (
    <>
      <BoldText className="textCenter">{categoryName}</BoldText>
      <SmartChart
        height={450}
        labels={data.filter((i: any) => i.name !== "Total").map((record: any) => record.name)}
        dataSets={[{
          label: "Balance",
          backgroundColor: theme.colors.malibu,
          data: data.map((record: any) => record.balance)
        }]}
        innerDatasets={false}
        types={['bar']}
      />
    </>
  );
};

export default CollateralBarChart;