import React, { useEffect, useState } from "react";
//External Libs
import styled from "styled-components";
//Components
import LoansTable from "../../loans_table";
import { RadioGroup } from "@cardoai/components";
import { Col, Pagination, Row, Spin } from "antd";
import Filters from "../../../../../components/filters";
import PerformanceStatusChart from "./performance_status_chart";
//Helpers
import { callApi, } from "../../../../../helpers";
import { useAuthorization } from "../../../../../utils/hooks";

//Configurations
interface IFilteredTrades {
  loading?: any,
  data?: any,
  externalData?: any,
  pagination?: any,
  onFiltersChange: any
}

interface ICompanyLoans {
  companyId: any,
  role?: any,
  extraFilters?: any
}

const tableConfig = {
  defaultPage: 1,
  defaultPageSize: 50,
  pageSizeOptions: ['50', '100', '1000'],
  report: function (total: any, range: any[]) {
    return `${range[0]}-${range[1]} of ${total} loans`;
  }
};

const Styles = styled.section`
  .fullHeight {
    height: 100%;
  }

  .title {
    font-weight: bolder;
  }

  .subTitle {
    margin-left: 24px;
  }

  .info {
    margin-top: 24px;
  }
`;

const FilteredTrades = (props: IFilteredTrades) => {
  const {loading, data, externalData, pagination, onFiltersChange} = props;
  const {canPerformAction}: any = useAuthorization();
  return (
    <section>
      <Row justify="end" className="mt8 mb8">
        <Col>
          <Filters
            useSearch
            entity="loans"
            canPerformAction={canPerformAction}
            onChange={onFiltersChange}
          />
        </Col>
      </Row>
      <Spin spinning={loading} size='large'>
        <LoansTable
          loans={data}
          height={400}
          externalData={externalData}/>
      </Spin>
      <Row justify="end" className="mt8">
        {pagination}
      </Row>
    </section>
  )
};

const CompanyLoans = (props: ICompanyLoans) => {
  const {companyId, role, extraFilters} = props;
  const [status, setStatus] = useState<any>('all');
  const [loading, setLoading] = useState<any | boolean>(false);
  const [loans, setLoans] = useState<any>({count: 0, data: []});
  const [page, setPage] = useState<any>(tableConfig.defaultPage);
  const [pageSize, setPageSize] = useState<any>(tableConfig.defaultPageSize);
  const [filtersQueryString, setFiltersQueryString] = useState<any>("");

  const getInvestments = () => {
    let query = `page=${page}&page_size=${pageSize}`;
    let mainUrl = `/main/sme_loan/?company_id=${companyId}&`;
    if (filtersQueryString)
      query += "&" + filtersQueryString;
    if (extraFilters)
      query += "&" + extraFilters;
    if (role)
      query += "&" + `${role}=${companyId}`;
    if (status !== "all")
      query += "&" + `${status}=true`;

    callApi({
      url: `${mainUrl}?${query}`,
      onSuccess: function (response: { results: any; count: any; }) {
        setLoans({
          data: response.results,
          count: response.count,
        });
      },
      setLoading: setLoading
    })
  };

  useEffect(() => {
    getInvestments();
  }, [page, pageSize, status, role, companyId, extraFilters, filtersQueryString]);


  const onFiltersChange = (newFiltersQueryString: any) => {
    setFiltersQueryString(newFiltersQueryString);
  };

  const onPageSizeChange = (currentPageSize: any, newPageSize: number) => {
    setPageSize(newPageSize);
    if (page * newPageSize > loans.count)
      setPage(1);
  };

  return (
    <Styles>
      <RadioGroup
        value={status}
        onChange={setStatus}
        position='right'
        size='small'
        entries={[
          {
            key: 'all',
            label: 'All'
          },
          {
            key: 'open',
            label: 'Open'
          },
          {
            key: 'closed',
            label: 'Closed'
          }
        ]}
      />
      <PerformanceStatusChart
        status={status}
        companyId={companyId}/>
      <FilteredTrades
        loading={loading}
        data={loans.data}
        externalData={loans.data}
        onFiltersChange={onFiltersChange}
        pagination={
          <Pagination
            current={page}
            showSizeChanger
            onChange={setPage}
            pageSize={pageSize}
            total={loans.count}
            showTotal={tableConfig.report}
            onShowSizeChange={onPageSizeChange}
            pageSizeOptions={tableConfig.pageSizeOptions}/>
        }
      />
    </Styles>
  )
};

export default CompanyLoans;
