import React from 'react';
//Components
import Slideshow from "./slideshow";

//Other Libs
interface ICustomImage {
  description?: any,
  alt?: any,
  src?: any,
  centered?: any,
  fullWidth?: any,
  style?: any,
  isStyled?: any,
  useSlideshow?: any
}

const CustomImage = (props: ICustomImage) => {
  const {description, alt, src, centered, fullWidth, style, isStyled, useSlideshow} = props;

  let finalDescription: any = description;

  if (isStyled)
    finalDescription = <span className="bold">{description}</span>

  return (
    <>
      <div>
        <p>
          {finalDescription}
        </p>
      </div>
      <br/>
      <div style={centered ? {textAlign: 'center'} : {}}>
        <Slideshow
          useSlideshow={useSlideshow}
          className={fullWidth ? "fullWidth" : ""}
          alt={alt} src={src} style={style}/>
      </div>
      <br/>
    </>
  );
};

CustomImage.defaultProps = {
  alt: "Sample Image",
  src: "",
  description: '',
  fullWidth: true,
  isStyled: true,
  centered: true,
  useSlideshow: true
}
export default CustomImage;
