import React from 'react';
//Custom Components
import { CustomImage, DefinitionList, MessageBox } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const UploadDocuments = () => {
  return (
    <>
      <CustomImage
        fullWidth={false}
        alt="Upload Document"
        src={assets.images.documentsModule.upload}/>
      <div>
        <DefinitionList
          header="Selecting Import New Deal Document or Import Other Document, import interface becomes available:"
          data={[
            {
              term: 'Click or drag files to this area for upload',
              explanation: 'allows the user to select or drag the relevant file or multiple files at once to be uploaded. ' +
                'Formats accepted are .pdf, .docx, .doc, pptx, .ppt, txt, .xls and .xlsx'
            },
            {
              term: 'Type*',
              explanation: 'file type showed in the documents lists'
            },
            {
              term: 'Reference Date*',
              explanation: 'reference date of the document showed in the document lists'
            }
          ]}
        />
        <span>* Mandatory Fields</span>
      </div>
      <br/>
      <MessageBox
        type='warning'
        description="Additional fields in the document list (Upload Date and Uploaded By) are automatically
        filled by the system (based on username and upload date)."/><br/>
      <div>
        <CustomImage
          description="By selecting “Upload” (and confirming the action with “Yes”) the file is uploaded in
        CARDO AI Securitisation Platform. A Preview is available for PDF documents."
          src={assets.images.documentsModule.uploadControls}
          alt="Upload Controls"
          fullWidth={false}
        />
      </div>
    </>
  );
};

export default UploadDocuments;
