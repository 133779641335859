import React from "react";
//Components
import { Popover } from "antd";
import { SimpleEmpty } from "@cardoai/components";
import SmartTable from "../../../../../../../../../../components/tables/smart_table";
//Helpers
import { withData } from "../../../../../../../../../../utils";
import withLimitDownload from "../../../../components/hoc/with_limit_download";
import { formatters, generateUrl, isEmpty } from "../../../../../../../../../../helpers";
import { RatingContent, ratingLimitsColumns } from "./config";
//Icons
import { InfoCircleOutlined } from "@ant-design/icons";

const RatingLimitSection = ({data, currency}: any) => {

  const aggregatePortfolio = data.length && data[0].aggregate_portfolio;

  return (
    <>
      <Popover content={RatingContent} title="Rating Limits" trigger="click">
        Rating Limits <InfoCircleOutlined className="ml8" style={{color: '#1890FF'}}/>
      </Popover>
      {isEmpty(data) || (aggregatePortfolio < 20000000) ?
        <SimpleEmpty description='Not applicable the aggregate portfolio is lower then 20m!'/> :
        <span className="bolder"> Aggregate Portfolio: {formatters.currency({
          value: aggregatePortfolio,
          currency
        })}</span>}
      <SmartTable
        externalData={data}
        columns={ratingLimitsColumns("Rating Limit", currency)}
      />
    </>
  )
};


export default withData({
  urlFormatter: (config: any) => {
    const {props: {proposalId, vehicleId, urlParam}} = config;
    return generateUrl(`/mcs/${urlParam}/rating_limits`, {
      vehicle_id: vehicleId,
      proposal_id: proposalId
    });
  }
})(withLimitDownload(RatingLimitSection)({
  title: 'Rating Limits', prepareData: (data: any) => {
    return data.map((ratingLimit: any) => {
      return {
        Name: ratingLimit.name,
        Threshold: ratingLimit.value,
        Realized: Number(ratingLimit.fulfillment),
        Check: ratingLimit.broken.toString().toUpperCase(),
        Available: ratingLimit.available_amount,
      }
    })
  }
}));