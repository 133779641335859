//Helpers
import { formatters, Text } from "../../../../helpers";

export const fields = (currency: any) => {
  return [
    {
      key: "spv",
      label: "SPV #",
      format: formatters.thousandsShorted
    },
    {
      key: "total_current_balance",
      format: (value: any) => formatters.currency({value, currency, shortFormat: true}),
      label: Text("Notes Total \n Current Balance")
    },
    {
      key: "wac",
      format: formatters.percent,
      label: Text("WAR \n (Notes)")
    },
  ]
}

export const vehicleFieldConfig = (currency: any) => {
  return [
    {
      key: "asset_class",
      label: Text("Asset\n Class"),
    },
    {
      key: "notes_current_balance",
      label: Text("Notes \n Current Balance"),
      format: (value: any) => formatters.currency({value, currency, shortFormat: true})
    },
    {
      key: "last_reported_on",
      label: Text("Last \n Sync Date"),
      format: formatters.date
    },
  ]
}



