import React from 'react';
//Other Libraries
import clone from "clone";
//Styled Components
import { useTheme } from "styled-components";
//Constants
import { theme } from "../../../../../../assets/theme/colors";
import { Chart as ChartJS } from 'chart.js';
//ChartJS libraries
import annotation from "chartjs-plugin-annotation";
import dataLabels from "chartjs-plugin-datalabels";
import dragPoints from "chartjs-plugin-dragdata";
ChartJS.register(annotation);

const ChartLayout = (props: any) => {
  // @ts-ignore
  const {darkMode} = useTheme();
  const {onClick, options, reference, type, data, component: Component} = props;
  const properties = clone(options);


  function fillGridLineColor(color: any) {
    ['x', 'y'].forEach(type => {
      const scales = properties.scales;
      if (scales.hasOwnProperty(type))
        scales[type].grid.color = color;
    })
  }

  if (darkMode)
    fillGridLineColor(theme.colors.darkgrey);
  else
    fillGridLineColor(theme.colors.greyshade1);

  return <Component onClick={onClick} data={data} plugins={[dataLabels, dragPoints]} type={type} ref={reference}
                options={properties}/>
};

export default ChartLayout;