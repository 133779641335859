import React, { createContext, useContext } from 'react';

export default (config: any) => {
  const Context = createContext(config);

  return {
    OriginalContext: Context,
    Provider: function (props: any) {
      const {children, ...rest} = props;
      return (
        <Context.Provider value={rest}>
          {props.children}
        </Context.Provider>
      )
    },
    Consumer: function (Component: any) {
      return (props: any) => {
        const ContextProps = () => {
          return useContext(Context);
        }
        return (
          <Component
            {...props}
            {...ContextProps()}/>
        );
      }
    }
  }
}

