import { Text } from "../../../../../../../helpers";

export const groupedColumns: any = {
  "Eligibility Criteria": [
    {
      "key": "concentration_limits_breach",
      "label": Text("Concentration Limits"),
      "group": "Eligibility Criteria",
      "width":150,
    },
    {
      "key": "cadastral_category_breach",
      "label": Text("Cadastral Category"),
      "group": "Eligibility Criteria",
      "width":150,
    },
    {
      "key": "due_diligence_breach",
      "label": "Due Diligence",
      "group": "Eligibility Criteria",
      "width":150
    },
    {
      "key": "purchase_price_dd_breach",
      "label": "Purchase Price/DD",
      "group": "Eligibility Criteria",
      "width":150,
    },
    {
      "key": "initial_renovation_budget_dd_breach",
      "label": "Initial Renovation Budget/DD",
      "group": "Eligibility Criteria",
       "width": 200
    },
    {
      "key": "metropolitan_area_breach",
      "label": "Metropolitan Area",
      "group": "Eligibility Criteria",
      "width":150
    },
    {
      "key": "maximum_purchase_price_breach",
      "label": "Maximum Purchase Price",
      "group": "Eligibility Criteria",
      "width":180
    },
    {
      "key": "sellers_repre_warranties_breach",
      "label": Text("Sellers Representations and Warranties"),
      "group": "Eligibility Criteria",
      "width": 270,
    },
    {
      "key": "vacant_property_breach",
      "label": "Vacant Property",
      "group": "Eligibility Criteria",
      "width": 200,
    }
  ],
  "Additional Eligibility Criteria": [
    {
      "key": "documents_delivery_breach",
      "label": "Documents Delivery",
      "group": "Additional Eligibility Criteria",
      "width": 180
    },
    {
      "key": "sellers_additional_repre_and_warranties_breach",
      "label": Text("Sellers Additional Representations  and Warranties"),
      "group": "Additional Eligibility Criteria",
      "width": 340,
    },
    {
      "key": "minimum_purchase_price_breach",
      "label": "Minimum Purchase Price",
      "group": "Additional Eligibility Criteria",
      "width":180
    },
    {
      "key": "protests_certificate_visura_protesti_breach",
      "label": Text("Protests Certificate Visura Protesti"),
      "group": "Additional Eligibility Criteria",
      "width":250
    },
    {
      "width": 340,
      "key": "liabilities_compliance_certificate_breach",
      "label": Text("Liabilities Compliance Certificate(e.g. CRIF,Cerved)"),
      "group": "Additional Eligibility Criteria"
    }
  ],
  "Alternative Eligibility Criteria": [
    {
      "key": "documents_delivery_breach",
      "label": "Documents Delivery",
      "group": "Alternative Eligibility Criteria",
      "width":150
    },
    {
      "width": 220,
      "key": "sellers_additional_repre_and_warranties_breach",
      "label": Text("Sellers Additional Repr and War"),
      "group": "Alternative Eligibility Criteria"
    },
    {
      "key": "minimum_purchase_price_breach",
      "label": "Minimum Purchase Price",
      "group": "Alternative Eligibility Criteria",
      "width":200
    },
    {
      "key": "protests_certificate_visura_protesti_breach",
      "label": "Protests Certificate Visura Protesti",
      "group": "Alternative Eligibility Criteria",
      "width":250
    },
    {
      "width": 240,
      "key": "liabilities_compliance_certificate_breach",
      "label": Text("Liabilities Compliance Certificate"),
      "group": "Alternative Eligibility Criteria"
    },
    {
      "key": "cadastral_category_italy_breach",
      "label": "Cadastral Category",
      "group": "Alternative Eligibility Criteria",
      "width":180
    },
    {
      "key": "due_diligence_italy_breach",
      "label": "Due Diligence",
      "group": "Alternative Eligibility Criteria",
      "width":180
    },
    {
      "key": "sellers_repre_warranties_italy_breach",
      "label": "Sellers Repr and War",
      "group": "Alternative Eligibility Criteria",
      "width":180
    }
  ],
  "Parameters": [
    {
      "key": "_deed_type",
      "label": "Deed Type",
      "group": "Parameters",
      "width":150
    },
    {
      "key": "purchase_price",
      "label": "Purchase Price",
      "group": "Parameters",
      "width":150
    },
    {
      "key": "_cadastral_category",
      "label": "Cadastral Category",
      "group": "Parameters",
      "width":220
    },
    {
      "key": "_municipality",
      "label": "Municipality",
      "group": "Parameters",
      "width":150
    },
    {
      "key": "due_diligence_price",
      "label": "Due Diligence Price",
      "group": "Parameters",
      "width":200
    },
    {
      "key": "tab",
      "label": "Tab",
      "group": "Parameters",
      "width":150
    },
    {
      "key": "down_payment_date",
      "label": "Down-payment Date",
      "group": "Parameters",
      "width":150
    },
    {
      "key": "outstanding_purchase_price_payment_date",
      "label": Text("Outstanding Purchase Price Payment Date"),
      "group": "Parameters",
      "width":290
    },
    {
      "key": "expected_acquisition_date",
      "label": "(Expected) Acquisition Date",
      "group": "Parameters",
      "width":200
    },
    {
      "key": "status_active_or_cash_in",
      "label": "Status(ACTIVE or CASH IN)",
      "group": "Parameters",
      "width":190
    },
    {
      "key": "_seller_is_natural_person",
      "label": "Seller Is Natural Person",
      "group": "Parameters",
      "width":170
    },
    {
      "key": "is_recalled",
      "label": "Is Re-called",
      "group": "Parameters",
      "width":180
    },
    {
      "key": "primary_municipality",
      "label": "Primary Municipality",
      "group": "Parameters",
      "width":180
    },
    {
      "key": "to_be_screened",
      "label": "To be screened",
      "group": "Parameters",
      "width":180
    },
    {
      "key": "mandatory",
      "label": "Mandatory",
      "group": "Parameters",
      "width":180
    },
    {
      "key": "cr_cash_out",
      "label": "CR Cash-out?",
      "group": "Parameters",
      "width":180
    }
  ]
}
