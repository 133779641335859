import React, { useState } from 'react';
import Input from "../../input";
import { Divider, Modal, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoginContent = ({onLogin, loading}: any) => {

  const [visible, setVisible] = useState(false);

  const toggleTerms = () => {
    setVisible(prevState => !prevState)
  }

  return (
    <div className="inner-container main-content">
      <form onSubmit={onLogin}>
        <p className="title">
          Welcome to Securitization Investment Management Suite
        </p>
        <span className="label">Username</span>
        <Input id="user_name" required placeholder=""/>
        <span className="label">Password</span>
        <Input id="password" type="password" required placeholder=""/>
        <div className="button-container">
          <button className="button button--active">
            <Space>
              {loading && <LoadingOutlined spin={true}/>}
              <span> Login</span>
            </Space>
          </button>
          <a className="button" id="secondary-button">
            Contact us
          </a>
        </div>
        <div>
          <Divider/>
          <span>
              By using this website, I agree that I have read and understood <a onClick={toggleTerms}>
              the terms and conditions </a> and accepted CardoAI's
             <a href="https://www.iubenda.com/privacy-policy/87365683/legal" target="_blank"> privacy policy.</a>
            </span>
        </div>
      </form>
      <Modal footer={null} visible={visible} onOk={toggleTerms} onCancel={toggleTerms} width={600} closable={false}>
        <embed
          src="https://d1e7g9vo5zfob6.cloudfront.net/public/TermsAndConditions.pdf"
          width="550" height="700"
          type="application/pdf"/>
      </Modal>
    </div>
  );
};

export default LoginContent;