import React from 'react';
//Other Libs
import Lottie from "react-lottie";
//JSON file
import UploadSimulation from "./json/30723-data-animation.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Loader = (props: any) => {

  const {animation, size} = props;

  const options = {...defaultOptions, animationData: animation};
  return (
    <Lottie
      width={size}
      height={size}
      options={options}
    />
  );
};

Loader.defaultProps = {
  size: 500,
  animation: UploadSimulation
}

export default Loader;