//Utils
import { withData } from "../../../../utils";
//Helpers
import { getRangePeriod } from "../../../../helpers";
import { prepareVehicleContentUrl } from "../../../spv/components/management/helpers";
//Components
import ChartContent from "./components/chart_content";

export default withData({
  urlFormatter: prepareVehicleContentUrl("/main/vehicle_timeline/loss_curve"),
  usesDateFilters: true,
  showIntervals: true,
  endOfMonthFormat: false,
  fullDateRange: true,
  extraParams: ["queryStr"],
  initialInterval: 3,
  initialState: {
    period: getRangePeriod(3, 'months')
  },
})(ChartContent);

