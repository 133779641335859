import React from 'react';

const ColumnChooserIcon = ({active}: any) => {
  const styles = active ? {
    borderRadius: 8,
    backgroundColor: '#EAF1FD',
  } : {
    transition: 'all .5s'
  }

  const stroke = active ? "#2D72EA" : "#8F8796"
  return (
    <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 19.5L10.5 4.5C10.5 4.08579 10.1642 3.75 9.75 3.75L6 3.75C5.58579 3.75 5.25 4.08579 5.25 4.5L5.25 19.5C5.25 19.9142 5.58579 20.25 6 20.25H9.75C10.1642 20.25 10.5 19.9142 10.5 19.5Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.75 19.5V4.5C18.75 4.08579 18.4142 3.75 18 3.75L14.25 3.75C13.8358 3.75 13.5 4.08579 13.5 4.5V19.5C13.5 19.9142 13.8358 20.25 14.25 20.25H18C18.4142 20.25 18.75 19.9142 18.75 19.5Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </>

  );
};

export default ColumnChooserIcon;
