import React from 'react';
//Other Libs
import moment from "moment";
//Components
import { DatePicker, Select } from "antd";
//Custom Components
import Form from "../form";
//Config

const {Option} = Select;

enum DateFormats {
  "daily" = "DD-MMM-YYYY",
  "monthly" = "MMM-YYYY"
}

const getDatePickerFormat = (isDailyDate: any) => {
  return isDailyDate ? DateFormats.daily : DateFormats.monthly
}

const OtherFields = (props: any) => {

  const {form, settings, datePickers, isCasavoVehicle, loanProposalCase, onChange} = props;
  const {referenceDatePicker, reconciliationDatePicker} = datePickers;

  const isReferenceDateDaily = referenceDatePicker === "date";
  const isReconciliationDaily = reconciliationDatePicker === "date";

  const disabledDate = (current: any) => {
    return current && current >= moment().endOf('day');
  };

  const disabledMonth = (current: any) => {
    return current && current >= moment().endOf('month');
  };

  const onCurrencyChange = (attr: any) => (event: any) => {
    onChange({[attr]: event})
  }

  const onDateChange = (attr: any) => (event: any) => {
    onChange({[attr]: event})
  };

  return (
    <>
      <Form label="Reference Date" required={true}>
        <DatePicker
          className="table-input"
          picker={referenceDatePicker}
          value={form.reference_date}
          onChange={onDateChange("reference_date")}
          format={getDatePickerFormat(isReferenceDateDaily)}
          disabledDate={isReferenceDateDaily ? disabledDate : disabledMonth}/>
      </Form>
      {(loanProposalCase && isCasavoVehicle) && <Form label="Facility Report Date" required={true}>
        <DatePicker
          picker="date"
          className="table-input"
          format={DateFormats.daily}
          disabledDate={disabledDate}
          value={form.facility_report_date}
          onChange={onDateChange("facility_report_date")}/>
      </Form>}
      {(loanProposalCase && !isCasavoVehicle) &&
        <Form label="Current Recon Period End" required={true}>
          <DatePicker
            className="table-input"
            disabledDate={disabledDate}
            picker={reconciliationDatePicker}
            value={form.current_recon_period_end}
            format={getDatePickerFormat(isReconciliationDaily)}
            onChange={onDateChange("current_recon_period_end")}/>
        </Form>}
      {(loanProposalCase && !isCasavoVehicle) && <Form label="Currency" required={true}>
        <Select className="fullWidth" onChange={onCurrencyChange("currency")} placeholder="Select currency">
          {settings.currencies.map((currency: any) => {
              return <Option key={currency.abbreviation}>{currency.name}</Option>
            }
          )}
        </Select>
      </Form>}
    </>
  );
};

export default OtherFields;