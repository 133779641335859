/**
 * Returns the parent key (string) of the param key in the tree data
 * Makes recursive calls to find the key in children of the node
 * @param key
 * @param tree
 * @returns parentKey
 */

export const getParentKey: any = (key: any, tree: any) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: { key: any; }) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export const getKey = (dataList: any, value: any, tree: any) => {
  return dataList.map((item: any) => {
    if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1)
      return getParentKey(item.key, tree);
    return null;
  })
}

export function prepareRoute(config: any, route?: any, path?: any) {

  const url = path ? path + "/" + route.key : route.key;
  const matchingRoute = config.find((el: any) => el.key === route.key);

  const params = {
    ...route,
    ...matchingRoute,
    key: route.key,
    url: url
  };

  if (route.children)
    params.children = params.children.map(((section?: any) => prepareRoute(config, section, url)))
  else
    params.children = []

  return params;
}

export function prepareRoutes(config?: any) {
  const routes: any = [];

  function appendRoute(route?: any) {
    routes.push(route);
    if (route.children)
      route.children.forEach(appendRoute)
  }

  config.forEach(appendRoute);

  return routes;
}