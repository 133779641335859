import React from "react";
//Components
import { Col, Row } from "antd";

interface Props {
  title: any,
  value: any
}

const TooltipItem = (props: Props) => {
  const {title, value} = props;

  return (
    <Row justify="space-between" gutter={16}>
      <Col>
        {title} :
      </Col>
      <Col>
        {value}
      </Col>
    </Row>
  )
}

export default TooltipItem;
