import React from 'react';
//Components
import { Skeleton, Tabs } from "antd";
import Management from "./components/management";
//Utils
import { useGlobalApi } from "../../utils/hooks";
//Custom Components
const TabPane = Tabs.TabPane;

const DocumentManagement = () => {
  const {vehicles} = useGlobalApi();

  if (!vehicles)
    return <Skeleton/>

  return (
    <Tabs>
      {vehicles.map((vehicle: { name: any; id: any }) => {
        const vehicleName = vehicle.name;
        const vehicleId = vehicle.id;
        return (
          <TabPane
            key={vehicleId}
            tab={vehicleName}>
            <Management vehicleId={vehicleId} vehicle={vehicle}/>
          </TabPane>
        )
      })}
    </Tabs>
  );
};

export default DocumentManagement