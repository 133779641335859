import React from 'react';
//Custom Components
import { CreateTopicButton } from "./create_topic_button";
import { SimpleEmpty } from "@cardoai/components";

export const EmptyTopics = ({onClick}: any) => {
  return (
    <SimpleEmpty
      description={<div>
        <p>There are no topics created for this vehicle!</p>
        <p className="mt8"><CreateTopicButton onClick={onClick}/></p>
      </div>}
    />
  )
}