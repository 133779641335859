import React, { useEffect } from "react";
//Utils
import { storage } from "../../helpers";
//Components
import { notification } from 'antd';
//Custom Components
import { ChangeLogContent, ChangeLogMessage } from "@cardoai/components"
//Constants
import Deployments from "../../components/deployments";

function generateUniqueIdentifier(date:any) {
  return "deployment:" + date
}

const CHANGE_LOG_CACHE_ENTITY = "last_deployment_date"

export default () => {
  const total = Deployments.length;
  const deploy = Deployments[total - 1];

  const [visible, setVisible] = React.useState(false);

  const lastDeployment = {
    changes: deploy,
    id: generateUniqueIdentifier(deploy.date)
  };

  const notifyChanges = () => {
    if (visible)
      return;
    setVisible(true)
    const changes = lastDeployment.changes;

    notification.open({
      duration: 90,
      message: <ChangeLogMessage date={changes.date}/>,
      description: <ChangeLogContent allDeployments={Deployments} currentDeploy={changes}/>,
      className: 'change_log_notification-class',
      onClose: () => {
        setVisible(false)
      },
      style: {
        width: 400,
        maxHeight: "80vh",
        overflow: 'auto'
      },
    });
  }

  const closeChanges = () => {
    // @ts-ignore
    notification.close();
  }

  useEffect(() => {
    try {
      const deploymentStorage = storage.get(CHANGE_LOG_CACHE_ENTITY);

      if (!deploymentStorage || (deploymentStorage && deploymentStorage !== lastDeployment.id)) {
        storage.set(CHANGE_LOG_CACHE_ENTITY, lastDeployment.id);
        notifyChanges();
      }
    } catch (err) {
      console.log("Unable to retrieve information")
    }
  }, [])


  return {
    notifyChanges: notifyChanges,
    closeChanges: closeChanges
  }
}