import React from 'react';
//Custom Components
import Synchronization from "../../../synchronization";
//Configurations
import { DHC_VIEWS } from "../../../configurations";


interface ISyncView {
  vehicle?: any,
  vehicleId?: any,
  makeRequest?: any,
  title?: string,
  type?: any,
  key?: string,
  handleUnmount?: any,
  handleCloseModal?: any,
  changeModalTitle?: any,
  allowModalClose?: any,
  changeModalWidth?: any,
  setWidthAsContent?: any
}

const SyncView = (props: ISyncView) => {
  const otherProps = DHC_VIEWS[props.type].otherProps;
  return <Synchronization {...props} {...otherProps}/>
};

export default SyncView;