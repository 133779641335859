//Components
import { Card } from "antd";
//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../../../../assets/theme/colors";

export const ToolTipStyles = styled(Card)`
  .ant-card {
    border-radius: 8px;
  }

  .ant-card-body {
    font-size: 12px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.65);
    color: ${theme.colors.white};
    padding: 8px !important;
  }
`

