//Other Libs
import styled from "styled-components";

export default styled.form<any>`
  ${props => props.loading && "pointer-events:none;"};

  .ant-picker {
    width: 100% !important;
  }
`;
