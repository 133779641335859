//Helpers
const nonRequiredFields = [
  "send_email",
  "perform_dhc",
  "is_interest_payment",
  "guarantees",
  "currency"
];

const pickerMap: any = {
  "monthly": "month",
  "daily": "date"
}

const prepareForm = (fields: any, settings: any) => {

  const state: any = {
    send_email: false,
    perform_dhc: true,
    reference_date: null,
    is_interest_payment: false,
  };

  fields?.forEach((record: any) => {
    if (!record.required)
      nonRequiredFields.push(record.key);
    state[record.key] = null
  });

  if (settings.currencies) {
    state.currency = null;
  }
  return state;
};

const getFormDatesFormatters: any = (settings: any, defaultDatePicker: any) => {

  let referenceDatePicker = defaultDatePicker;
  let reconciliationDatePicker = defaultDatePicker;

  if (settings.hasOwnProperty("reference_date_format")) {
    referenceDatePicker = pickerMap[settings.reference_date_format]
  }

  if (settings.hasOwnProperty("reconciliation_period_end_date_format")) {
    reconciliationDatePicker = pickerMap[settings.reconciliation_period_end_date_format]
  }

  return {
    referenceDatePicker,
    reconciliationDatePicker
  }
};

export {
  prepareForm,
  nonRequiredFields,
  getFormDatesFormatters
}