import React from 'react';
//Components
import { Col, Pagination, Row } from "antd";
//Helpers
import { showPaginationTotal } from "../helpers";

interface Props {
  visible: any,
  data: any,
  page: any,
  pageSize: any,
  onPageChange: any,
  onPageSizeChange: any
}

const Footer = (props: Props) => {
  const {visible, data, page, pageSize, onPageChange, onPageSizeChange} = props;

  if (!visible)
    return null;

  return (
    <Row justify="end" className="mt16">
      <Col>
        <Pagination
          current={page}
          showSizeChanger
          total={data.count}
          pageSize={pageSize}
          onChange={onPageChange}
          showTotal={showPaginationTotal}
          onShowSizeChange={onPageSizeChange}
          pageSizeOptions={['20', '50', '100', '200']}
        />
      </Col>
    </Row>
  );
};

export default Footer;
