import React, { useState } from 'react';
//Components
import { Button, Modal } from "antd";
//Custom Components
import UploadTape from "../upload_tape";
//Icons
import { CloudUploadOutlined } from "@ant-design/icons";

interface IUploadDocument {
  title?: any,
  config?: any,
  vehicle?: any,
  vehicleId?: any,
  updateTable?: any,
  allowUpload?: boolean,
  documentIdentifier?: any
  isGrouped?: boolean
}

const UploadDocument = (props: IUploadDocument) => {
  const {title, config, vehicle, vehicleId, updateTable, allowUpload, documentIdentifier, isGrouped} = props;
  const [openModal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(prevState => !prevState);
  };

  const onConfirm = () => {
    toggleModal();
    updateTable();
  }

  return (
      <>
        <Modal
          centered
          width={700}
          footer={null}
          destroyOnClose
          closable={false}
          visible={openModal}
          style={{padding: 0, margin: 0}}
          title={title + " Process"}
          bodyStyle={{padding: 8, paddingTop: 0, paddingLeft: 0}}>
          <UploadTape
            config={config}
            vehicle={vehicle}
            isGrouped={isGrouped}
            vehicleId={vehicleId}
            onCancel={toggleModal}
            allowUpload={allowUpload}
            handleConfirm={onConfirm}
            documentIdentifier={documentIdentifier}
          />
        </Modal>
        {(allowUpload) &&
          <Button
            type='primary'
            onClick={toggleModal}
            disabled={!allowUpload}
            icon={<CloudUploadOutlined/>}>
            {title}
          </Button>
        }
      </>
  );
};

export default UploadDocument;
