import React from "react";
//Components
import { Graph } from "../../../../../components";
//Colors
import { blue, cyan, geekblue } from "@ant-design/colors";
//Helpers
import { formatters } from "../../../../../helpers";
import { withData } from "../../../../../utils";

interface IPerformanceChart {
  data: any,
  status?: any,
  companyId?: any,
}

const PerformanceChart = (props: IPerformanceChart) => {
  const {data, status} = props;

  const labels = [];
  const dataSets = [
    {
      stack: 1,
      data: [],
      label: "Performing",
      backgroundColor: cyan[1],
      identifier: "debtor_performing_amount"
    },
    {
      stack: 1,
      data: [],
      label: "Late 1-29 days",
      backgroundColor: cyan[3],
      identifier: "late_1_29_balance"
    },
    {
      stack: 1,
      data: [],
      label: "Late 30-59 days",
      backgroundColor: cyan[5],
      identifier: "late_30_59_balance"
    },
    {
      stack: 1,
      data: [],
      label: "Late 60-89 days",
      backgroundColor: blue[3],
      identifier: "late_60_89_balance"
    },
    {
      stack: 1,
      data: [],
      label: "Late 90-119 days",
      backgroundColor: blue[5],
      identifier: "late_90_119_balance"
    },
    {
      stack: 1,
      data: [],
      label: "Late 120+ days",
      backgroundColor: geekblue[4],
      identifier: "late_120_balance"
    }
  ];

  const records: any = {};

  const initializeSumRecord = React.useCallback(() => {
    const record: any = {};
    dataSets.forEach(dataset =>
      record[dataset.identifier] = 0);
    return record
  }, []);

  if (status === "all") {
    data.forEach((record: any) => {
      const {date, ...rest} = record;
      records[date] = initializeSumRecord();

      for (let key in rest) {
        if (rest.hasOwnProperty(key)) {
          const dataset = rest[key];

          for (let datasetKey in dataset) {
            if (dataset.hasOwnProperty(datasetKey))
              records[date][datasetKey] += dataset[datasetKey];
          }

        }
      }
    })
  } else {
    data.forEach((record: any) => records[record.date] = record[status])
  }

  for (let date in records) {
    const values: any = records[date];
    labels.push(formatters.date(date));
    dataSets.forEach((dataset: any) => {
      const identifier: any = dataset.identifier;
      dataset.data.push(values[identifier]);
    })
  }

  return (
    <Graph
      height={300}
      dataSetStack
      types={['bar']}
      displayLegend
      labels={labels}
      showEmptyPlaceholder
      dataSets={dataSets}
      format={formatters.thousands}
    />
  )
};

export default withData({
  urlFormatter: function (config: any) {
    const {props} = config;
    return `/main/company/${props.companyId}/delay_timeline/`
  }
})(PerformanceChart);

