import React from 'react';
//Components
import { Col, Row } from "antd";

const NotesDashedBorder = () => {
  return (
    <Row>
      <Col style={{
        left: 8,
        zIndex: 10,
        position: "absolute",
        width: `calc(100% - 16px)`,
        borderBottom: '1px dashed blue'
      }}/>
    </Row>
  )
}

export default NotesDashedBorder;