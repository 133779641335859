import React from 'react';
//Components
import { LegendTable } from "../../../tables";
import RegionsMap from "../../../../../components/maps/regions";
//Helpers
import { isVDRUser } from "../../../../../helpers";

interface Props {
  data?: any,
  countryMode?: any,
  scale?: any,
  onClick: (param: any) => any,
  showTable?: any,
  height?: any,
  map?: any,
  currency?: any
}

const RegionsComponent = (props: Props) => {
  const {data, countryMode, scale, onClick, showTable, height, map, currency} = props;
  const ratingLabel = isVDRUser() ? "Average Pricing Cluster" : "Average Rating";

  const mapContent = <RegionsMap map={map} height={height} scale={scale} selected={data} onClick={onClick}
                                 countryMode={countryMode} ratingLabel={ratingLabel} currency={currency}
                                 colors={data.map((item: any) => item.color)}/>

  if (showTable)
    return <LegendTable data={data} height={350} title="Regions" centered={false} onClick={onClick} currency={currency}
                        ratingLabel={ratingLabel} colors={data.map((item: any) => item.color)}>
      {mapContent}
    </LegendTable>

  return mapContent;
};
RegionsComponent.defaultProps = {
  showTable: true,
  height: 380
}
export default RegionsComponent;
