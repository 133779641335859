import React from "react";
//Other Libs
import cx from "classnames";
//Components
import { Button, Modal } from "antd";
//Icons
import { BarChartOutlined } from "@ant-design/icons";
//Styles
import { Table } from "../../../../styles";
//Custom Components
import HistoricalData from "./historical_data";
import { EmojiBreach, LimitsTable } from "../../../../../../../../../../components";
//Helpers
import { formatLimits, getLimitColor, showThresholdType } from "../../../../helpers";

interface TableView {
  data?: any
  currency?: any
  vehicleId?: any
  showHistoricalLimits?: boolean
}

const TableView = (props: TableView) => {

  const {data, currency, vehicleId, showHistoricalLimits} = props;
  const [visibleHistoricalData, setVisibleHistoricalData] = React.useState(false);
  const [selectedLimit, setSelectedLimit] = React.useState<any>(null);

  const toggleModalVisibility = () => {
    setVisibleHistoricalData(prevState => !prevState);
  }

  const openHistoricalData = (limitName: string) => {
    setSelectedLimit(limitName);
    toggleModalVisibility();
  }

  return (
    <>
      <LimitsTable>
        <Table className="fullWidth">
          <thead>
          <th/>
          <th className="textCenter" style={{width: 200}}>Threshold</th>
          <th className="textCenter" style={{width: 200}}>Realized</th>
          <th className="textCenter" style={{width: 200}}>Threshold Type</th>
          <th className="textCenter" style={{width: 200}}>Broken?</th>
          {showHistoricalLimits && <th/>}
          </thead>
          <tbody>
          {data.map(({name, threshold, formatter, realized, broken, condition}: any,index:number ) => {

            return (
              <tr key={index}>
                <td style={{width: 700}}>{name}</td>
                <td className="textCenter">{formatLimits(threshold, formatter, currency)}</td>
                <td
                  className={cx("textCenter", getLimitColor(broken))}>{formatLimits(realized, formatter, currency)}</td>
                <td className="textCenter primary">{showThresholdType(condition)}</td>
                <td className="textCenter"><EmojiBreach record={{broken: broken}}/></td>
                {showHistoricalLimits && <td className="textCenter">
                  <Button onClick={() => openHistoricalData(name)} type="link" size="small" icon={<BarChartOutlined/>}>Historical
                    Data</Button></td>}
              </tr>
            )
          })}
          </tbody>
        </Table>
      </LimitsTable>
      <Modal width={900} title={selectedLimit} footer={null} centered closable
             onCancel={toggleModalVisibility} visible={visibleHistoricalData}>
        <HistoricalData vehicleId={vehicleId} limitName={selectedLimit} currency={currency}/>
      </Modal>
    </>
  )
}
TableView.defaultProps = {
  showHistoricalLimits: false
}

export default TableView;