import React, { useState } from "react";
//Components
import DetailsWaiver from "./details_waiver";
//Other Components
import { Button, Col, Modal, Popconfirm, Row } from "antd";
//Icons
import {
  DeleteOutlined,
  EditOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
//Utils
import { mediatorSend } from "@cardoai/utils";
import { useAuthorization } from "../../../../../../../../utils/hooks";
//Constants
import { actionKey } from "../../../../../../../../constants";
//Helpers
import { callApi} from "../../../../../../../../helpers";
import WaiverProvider from "../calculation_waiver/calculation_waiver_provider";

interface Props {
  record?: any;
  vehicleId?: any;
  limitId?: any;
  formatter?: any;
  fulfillment_percent?:any,
  currencyLimits?:any
}

const DeleteAndDetails = (props: Props) => {
  const { record, vehicleId, limitId, formatter, currencyLimits, fulfillment_percent} = props;
  const { canPerformAction } = useAuthorization();

  const [visibleDetailsModal, setVisibleDetailsModal] =
    useState<boolean>(false);
  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const deleteWaiver = canPerformAction(actionKey.deleteLimitWaiver, vehicleId);

  const onDelete = () => {
    callApi({
      url: `main/limit_waiver/${record.id}/?vehicle_id=${vehicleId}`,
      method: "delete",
      successMessage: "Waiver Deleted",
      setLoading: setDeleteLoading,
      onSuccess: () => {
        // todo replace with invalidate query and update useQuery id
        mediatorSend("Limits", "refreshLimits", undefined);
        mediatorSend(
          "WaiverRequestHistory",
          "refreshDataWaiverHistory",
          undefined
        );
        // queryServices.invalidateQuery(`main/limit_waiver/?vehicle_id=${vehicleId}&limit_id=${limitId}`);
      },
    });
  };

  const toggleDisplayCalculationVisibility = () => {
    if (visibleDetailsModal) {
      setVisibleDetailsModal((prevState) => !prevState);
    } else {
      setVisibleUpdateModal((prevState) => !prevState);
    }
  };

  const onDisplayDetails = () => {
    setVisibleDetailsModal(true);
  };

  const onUpdateDetails = () => {
    setVisibleUpdateModal(true);
  };

  return (
    <WaiverProvider>
      <Row justify="center" className="ant-row-no-wrap" gutter={8}>
        <Col>
          <Button
            size="small"
            onClick={onDisplayDetails}
            icon={<FileSearchOutlined/>}
          >
            Details
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            type="primary"
            ghost
            onClick={onUpdateDetails}
            icon={<EditOutlined/>}
          >
            Modify
          </Button>
        </Col>
        <Modal
          width={1000}
          title={visibleUpdateModal ? "Modify Waiver" : "Details"}
          visible={visibleDetailsModal || visibleUpdateModal}
          centered={true}
          closable={false}
          footer={null}
          onCancel={toggleDisplayCalculationVisibility}
          destroyOnClose={true}
        >
          <DetailsWaiver
            vehicleId={vehicleId}
            limitId={limitId}
            id={record.id}
            formatter={formatter}
            editable={visibleUpdateModal}
            setVisibleUpdateModal={setVisibleUpdateModal}
            fulfillment_percent={fulfillment_percent}
            currencyLimits={currencyLimits}
          />
        </Modal>
        {deleteWaiver && (
          <Col span={8}>
            <Popconfirm
              okText="Yes"
              cancelText="No"
              onConfirm={onDelete}
              title="Are you sure you want to delete this waiver?"
            >
              <Button
                loading={deleteLoading}
                disabled={deleteLoading}
                danger
                size="small"
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Popconfirm>
          </Col>
        )}
      </Row>
    </WaiverProvider>
  );
};

export default DeleteAndDetails;
