//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../../assets/theme/colors";

export default styled.div`
  width: 100%;

  input {
    background-color: ${theme.palette.grayscale[4]};
    border: none;
    padding: 0.7em 1em;
    margin: 0.4em 0;
    width: 100%;
  }
`;