import React from 'react';
//Components
import {Row, Col, Popover, Skeleton, Divider} from "antd";
//Icons
import {CheckOutlined, MinusOutlined} from "@ant-design/icons";
import {formatters, useQuery} from "../../../../../../../../../helpers";

const iconStyles: any = {
    normal: {
        style: {},
        background: '#2D72EA',
        Icon: CheckOutlined
    },
    notApplicable: {
        background: '#D4D3D4',
        Icon: MinusOutlined,
        style: {
            transform: 'rotate(45deg)'
        }
    },
    breached: {
        style: {},
        background: '#F15928',
        Icon: MinusOutlined
    },
    waivered: {
        style: {},
        background: '#d4b106',
        Icon: CheckOutlined
    }
}


const WaiveredInformation = ({vehicleId, collateralLogId, criteriaType}: any) => {

    const info = useQuery({
        query: {
            vehicle_id: vehicleId,
            criteria_type: criteriaType,
            collateral_log_id: collateralLogId
        },
        url: `/casavo/collateral/waiver-criteria/retrieve_waiver/?`
    })


    if (!info.data)
        return <Skeleton active/>

    return (
        <Row gutter={[8, 8]} style={{maxWidth: 240}}>
            <Col xs={24}>
                <Row gutter={16}>
                    <Col>
                        Date First Waivered :
                    </Col>
                    <Col>
                        {formatters.date(info.data?.date_first_waivered)}
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Divider style={{margin: 0}}/>
            </Col>
            <Col xs={24}>
                <Row gutter={16}>
                    <Col>
                        Reason:
                    </Col>
                    <Col>
                        {info.data?.reason}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}


const Checker = ({
                     value,
                     status,
                     state = [],
                     onWaiverClick,
                     index,
                     column = {},
                     collateralLogId,
                     vehicleId,
                     ...rest
                 }: any) => {

    let action;

    switch (value) {
        case "no":
            action = "normal"
            break;
        case "yes":
            action = "breached"
            break;
        case "na":
            action = "notApplicable"
            break;
        case "waivered":
            action = "waivered";
            break;
        default:
            action = null;
    }

    if (!action)
        return value;

    const {style, Icon, background} = iconStyles[action];

    const visibleWaiver = status === "waive";

    const toggleWaiver = () => onWaiverClick(`${collateralLogId}-${column.key}`);

    const isWaved = state.includes(`${collateralLogId}-${column.key}`)

    let iconContent = (
        <div
            style={{
                width: '16.25px',
                height: '16.25px',
                display: 'flex',
                borderRadius: '50%',
                transition: 'all .5s',
                alignItems: 'center',
                background: background,
                justifyContent: 'center',
                cursor: action === "waivered" ? "pointer" : "default",
                opacity: visibleWaiver ? value !== "yes" ? 0.5 : 1 : 1
            }}>
            <Icon style={{...style, color: '#fff', fontSize: 11}}/>
        </div>
    )

    if (action === "waivered")
        iconContent = <Popover showArrow={false} content={(
            <WaiveredInformation
                vehicleId={vehicleId}
                criteriaType={column.key}
                collateralLogId={collateralLogId}/>
        )}>{iconContent}</Popover>

    if (!status || status === "cure" || action !== "breached") {
        return iconContent;
    } else {
        return (
            <div onClick={toggleWaiver}
                 style={{background: 'transparent', width: '100%', cursor: 'pointer'}}>
                {isWaved ? (
                    <Row align="middle" gutter={8}
                         style={{
                             maxWidth: 90,
                             borderRadius: 16,
                             flexWrap: 'nowrap',
                             backgroundColor: '#FCDED4'
                         }}>
                        <Col>
                            {iconContent}
                        </Col>
                        <Col style={{color: '#0C1E30', fontWeight: 400}}>
                            Waive
                        </Col>
                    </Row>
                ) : iconContent}
            </div>
        );
    }


};

export default Checker;