import React, { useState } from 'react';
//Utils
import { useDebounce } from '@cardoai/utils';
//Custom Components
import { InfoTag } from "@cardoai/components";
//Icons
import {SearchOutlined } from '@ant-design/icons';
//Other Components
import {Col, Input, Row, Select } from 'antd';
//Colors
import GetColorDelayed from './get_color_days_delayed';
//Custom Components
import { DownloadAsCSV, SmartTable } from '../../components';
//Helpers
import { createFileName, downloadClientFile, Text } from "../../helpers";
import WindowResizeListener, { WindowSize } from 'react-window-size-listener';

const columns = [
  {
    key: 'vehicle_name',
    label: 'SPV',
    align: 'center',
    width: 150,
  },
  {
    key: 'data',
    label: 'Data Sync Type',
    align: 'center',
    width: 150,
  },
  {
    key: 'expected_synchronization_date',
    label: "Expected Reporting Date",
    align: 'center',
    width: 150,
    sortable:true
  },
  {
    key: 'last_synchronization_date',
    label: 'Last Synchronization Date',
    align: 'center',
    width: 150,
    sortable:true
  },
  {
    key: 'frequency',
    label: 'Expected Update Frequency',
    align: 'center',
    width: 150,
  },
  {
    key: 'status',
    label: 'Status',
    width: 150,
    align: 'center',
  },
  {
    key: 'days_delayed',
    label: 'Days on Delay',
    width: 150,
    align: 'center',
    Component: GetColorDelayed,
    sortable:true
  },
  {
    key: 'delay_count',
    label: <InfoTag showInfo toolTipPlacement="bottom"
                    title="This field counts, for each SPV, the number of times the Originator was in delay.">{Text("Historical Delay Count")}</InfoTag>,
    width: 100,
    align: 'center',
    sortable:true
  },
];

const { Option } = Select;

enum Status {
  DELAYED = 'Delayed',
  ONTIME = 'On Time',
}

const SyncReports = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [status, setStatus] = useState<Status>(Status.DELAYED);
  const search = useDebounce(searchInput, 1000);
  const [height, setHeight] = useState(window.innerHeight);
  const [loading, setLoading] = useState(false);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleChange = (value: Status) => {
    setStatus(value);
  };

  const useFilter = () => {
    return `vehicle_name=${search}&status=${status}`;
  };

  const onResize = (event: WindowSize) => {
    const headerAndFooterHeight = 220;
    setHeight(event.windowHeight - headerAndFooterHeight);
  };

  const downloadExcel = () => {
    const baseUrl = `/main/originator_instance/download_statistics_dashboard/`;
    downloadClientFile({
      url: baseUrl,
      filename: createFileName("Sync Reports", "csv"),
      setLoading
    })
  }

  return (
    <div style={{ margin: '2px' }}>
      <WindowResizeListener onResize={onResize} />
      <Row justify='end' align='middle' gutter={8} className='mb16'>
        <Col className='bolder'>Filter By Status:</Col>
        <Col>
          <Select
            style={{ width: 150}}
            onChange={handleChange}
            className='mr8'
            placeholder="Filter By Status"
          >
            <Option value={Status.DELAYED}>{Status.DELAYED}</Option>
            <Option value={Status.ONTIME}>{Status.ONTIME}</Option>
          </Select>
        </Col>
        <Col>
          <Input
            allowClear
            value={searchInput}
            onChange={onSearch}
            style={{ width: 170 }}
            placeholder='Search for SPV'
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Col style={{flexBasis: 50}}>
            <DownloadAsCSV loading={loading} onDownload={downloadExcel}/>
          </Col>
        </Col>
      </Row>
      <SmartTable
        sortType='single'
        simpleFetch
        useFilters
        filters={useFilter()}
        height={height}
        columns={columns}
        url='/main/originator_instance/statistics_dashboard/'
      />
    </div>
  );
};

export default SyncReports;
