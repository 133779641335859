//Other Libs
import styled from "styled-components";

interface Props {
  sizeSmall?: any,
  height?: any
}

export default styled.div<Props>`
  table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    margin-bottom: 25px;
    padding: 4px;
    font-size: 13px;

    thead {
      tr {
        th {
          position: relative;
          color: #000000d9;
          font-weight: 500;
          text-align: left;
          background: #fafafa;
          border-bottom: 2px solid #f0f0f0;
          transition: background .3s ease;
          padding: ${props => props.sizeSmall ? "8px" : "16px"};
          overflow-wrap: break-word;
        }

        th:before {
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 1.6em;
          background-color: #0000000f;
          transform: translateY(-50%);
          transition: background-color .3s;
          content: "";
        }
      }
    }

    td {
      position: relative;
      padding: ${props => props.sizeSmall ? "8px" : "16px"};
      overflow-wrap: break-word;
      border-bottom: 1px solid #f0f0f0;
      transition: background .3s;
    }

    tr {
      border-bottom: 1px solid #d9d9d9;
    }
  }
`;