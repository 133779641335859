import React from 'react';
//Components
import { CustomTable } from "@cardoai/components";
//Helpers
import { formatters, validateValue } from "../../../../../../../../../helpers";

interface Props {
  data: any
}

const CostsTable = ({data}: Props) => {

  return (
    <CustomTable size='small'>
      <thead>
      <th>Costs</th>
      <th>Amount</th>
      </thead>
      <tbody>
      <tr>
        <td>Net Accrued Costs:</td>
        <td>{validateValue(data.net_accrued_costs, formatters.thousands)}</td>
      </tr>
      <tr>
        <td>Provisions for Costs:</td>
        <td>{validateValue(data.provision_for_costs, formatters.thousands)}</td>
      </tr>
      <tr>
        <td>Catch Up Costs:</td>
        <td>{validateValue(data.catch_up_costs, formatters.thousands)}</td>
      </tr>
      <tr>
        <td>Costs Due:</td>
        <td>{validateValue(data.costs_due, formatters.thousands)}</td>
      </tr>
      </tbody>
    </CustomTable>
  );
};

export default CostsTable;