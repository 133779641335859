import React from 'react';
//Components
import { NestedPie } from "../../../../../../../../../../components";
//Helpers
import { formatters, MathOp } from "../../../../../../../../../../helpers";
//Constants
import { theme } from "../../../../../../../../../../assets/theme/colors";
//Colors
const pieChartColors = ["#4472c4", "#92d050", ...theme.palette.structureChartColors]


//Custom Helpers
const getDatasetValue = (record: any) => {
  const notionalCapital = Number(record.fulfillment);
  const valuation = Number(record.value_percent);

  if (notionalCapital !== null && valuation !== null)
    return MathOp.multiply(notionalCapital, valuation);
  else
    return null;
};

const preparePieData = (data: any[]) => {
  const limits = data.find(limit => limit.category === "Portfolio check");

  return limits.records.map((record: any, index: number) => {
    const label = record.name;
    const value = getDatasetValue(record);
    return {
      label: label,
      value: value,
      color: pieChartColors[index]
    }
  })
};

interface Props {
  data: any[],
  params: any
}

const LimitsPieChart = (props: Props) => {
  const {data, params} = props;

  const {ltv, targetLtv} = params;

  const doughnutFirst = [
    {
      value: ltv,
      label: "Ltv",
      color: "#6fd2f7",
    },
    {
      value: 1 - ltv,
      label: "Remaining",
      color: "#fff",
    }
  ];
  let doughnutSecond;

  const difference = targetLtv - ltv;
  const remainingLtv = 1 - ltv;

  if (difference > 0) {
    doughnutSecond = [
      {
        value: ltv,
        label: "Remaining",
        color: theme.colors.white
      },
      {
        value: difference,
        label: "Difference",
        color: theme.colors.green
      },
      {
        value: 1 - (difference + ltv),
        label: "Remaining",
        color: theme.colors.white
      },
    ]
  } else {
    const absDifference = Math.abs(difference);
    doughnutSecond = [
      {
        value: ltv - absDifference,
        label: "Remaining",
        color: theme.colors.white
      },
      {
        value: absDifference,
        label: "Difference",
        color: theme.colors.red
      },
      {
        value: remainingLtv,
        label: "Remaining",
        color: theme.colors.white
      }
    ]
  }


  return (
    <NestedPie
      pie={{
        dimension: 200,
        config: preparePieData(data)
      }}
      doughnuts={{
        dimension: 250,
        spacing: 75,
        config: [
          doughnutFirst,
          doughnutSecond
        ]
      }}
      displayPieLabels
      innerRadiusWidth={0.2}
      formatter={formatters.thousandsShorted}/>
  )
}

export default LimitsPieChart;
