import React from 'react';
//Components
import { Divider } from "antd";
//Custom Components
import TableContent from "./table_content";
import ChartContent from "./chart_content";

interface IContent {
  data?: any,
  currency?: any
}


const Content = ({data, currency}: IContent) => {

  const {threshold, formatter, historical_fulfillment: historicalData} = data;

  return (
    <>
      <ChartContent threshold={threshold} formatter={formatter} currency={currency} data={historicalData}/>
      <Divider/>
      <TableContent data={historicalData} formatter={formatter} currency={currency}/>
    </>
  );
};

export default Content;