import React from "react";
//Components
import { Popconfirm } from "antd";

interface IConfirmButton {
  showConfirm?: any,
  children?: any,
  onText?: any,
  okText?: any,
  title: any,
  cancelText?: any,
  onConfirm?: any,
  disabled?: any,
  placement?: any
}

const ConfirmButton = (props: IConfirmButton) => {
  const {showConfirm, children, ...rest} = props;
  if (showConfirm) {
    return (
      <Popconfirm {...rest}>
        {children}
      </Popconfirm>
    )
  }
  return children;
}


export default ConfirmButton;