import React from "react";
//Components
import { Tooltip } from "antd";
//Helpers
import { formatters } from "../../../../../../../helpers";

interface Props {
  height?: any,
  name?: any,
  value?: any
  display?: boolean
}

const CellLink = (props: Props) => {
  const {height, name, value, display} = props;
  const styles: any = {width: 60, height: '100%'};

  const content = <Tooltip title={`${name} Tranche Attachment Point`}>
    <div>
      <span>
        {formatters.percent(value)}
      </span>
    </div>
  </Tooltip>

  return (
    <td style={styles}>
      <div style={{
        height: height,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}>
        {display && content}
      </div>
    </td>
  )
};


export default CellLink;