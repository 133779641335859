import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Collateral = () => {
  return (
    <CustomImage
      description="Details of the collateral associated with the loan."
      src={assets.images.loansModule.collateral}
    />
  );
};

export default Collateral;
