//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../assets/theme/colors";

export default styled.div<any>`
  input[type="radio"] {
    display: none;
  }

  label {
    color: white;
    z-index: 10;
    cursor: pointer;

    svg {
      fill: white;
      height: 21px;
      font-weight: bolder;

      g {
        fill: white;
      }
    }
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }

  .main-container {
    vertical-align: middle;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 210px;
    height: 24px;
    border-radius: 100px;
    position: relative;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    z-index: 1;
    ${props => {
      if (props.active)
        return `background-color:${theme.colors.primary}`;
      return `background-color:#bfbfbf`;
    }};
  }

  .switch {
    height: 18px;
    width: 70px;
    background-color: white;
    border-radius: 100px;
    position: absolute;
    left: 3px;
    top: 2px;
    transition: ease-in;
  }

  label h4 {
    color: white !important;
  }

  /*---------------- LEFT ------------------- */

  .switch.left-to-center {
    animation-name: leftToCenter;
    animation-duration: 0.5s;
  }

  .switch.left-to-right {
    animation-name: leftToRight;
    animation-duration: 0.5s;
  }

  .switch.left-position {
    left: 3px;
  }

  .left-label {
    position: absolute;
  }

  .left-selector + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    width: 75px;
    border-radius: 100px;
  }

  @keyframes leftToCenter {
    from {
      left: 3px;
    }
    to {
      left: 65px;
    }
  }

  @keyframes leftToRight {
    from {
      left: 3px;
    }
    to {
      left: 135px;
    }
  }

  /* -------------------- center ------------------ */

  .switch.center-to-left {
    animation-name: centerToLeft;
    animation-duration: 0.5s;
  }

  .switch.center-to-right {
    animation-name: centerToRight;
    animation-duration: 0.5s;
  }

  .switch.center-position {
    left: 67px;
  }

  .center-label {
    position: absolute;
    left: 67px;
  }

  .center-selector + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    width: 80px;
    border-radius: 100px;
  }


  @keyframes centerToLeft {
    from {
      left: 67px;
    }
    to {
      left: 3px;
    }
  }

  @keyframes centerToRight {
    from {
      left: 85px;
    }
    to {
      right: 3px;
    }
  }

  /* ------------------- RIGHT ------------------- */

  .switch.right-to-left {
    animation-name: rightToLeft;
    animation-duration: 0.5s;
  }

  .switch.right-to-center {
    animation-name: rightToCenter;
    animation-duration: 0.5s;
  }

  .switch.right-position {
    left: 135px;
  }

  .right-label {
    position: absolute;
    right: 2px;
  }

  .right-selector + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 21px;
    width: 75px;
    border-radius: 100px;
  }

  @keyframes rightToLeft {
    from {
      left: 135px;
    }
    to {
      left: 3px;
    }
  }

  @keyframes rightToCenter {
    from {
      left: 135px;
    }
    to {
      right: 85px;
    }
  }
`;