import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage, DefinitionList } from "../../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../../assets";

const HDREnvironment = () => {

  const description =
    <div style={{textAlign: 'justify'}}>
      The Loans Management module allows the user to browse through the portfolio, and access different statistics
      and detailed information down to loan level information. The user can filter by Lender, Investment Date,
      loan ID or company name, as well as apply filters by multiple columns if needed, and download that information
      in .csv format.
    </div>

  return (
    <Row>
      <Col sm={24}>
        <CustomImage
          description={description}
          alt="Access Page"
          src={assets.images.HDRModule.hdrEnvironment}
        />
        <div style={{textAlign: 'justify'}}>
          <DefinitionList
            header="There are different modules in the Virtual Data Room environment:"
            data={[
              {
                term: 'Summary Statistics',
                explanation: "Loan's statistics are aggregated based on investment date or year on a cumulative basis.Columns can be filtered as the user" +
                  "needs to, in order to have a better understanding and view of the statistics changes across the years.Each column can be also displayed as a pie, chart, bar, line etc.",
              },

              {
                term: 'Stratification',
                explanation: 'Stratification is divided into General Strats, Geography, Sector and Debtor, each displaying a different set of information. In this section,' +
                  'the user has the option to filter by Lender as well.'
              },
              {
                term: 'Delinquencies Distribution',
                explanation: 'Shows the distribution of delinquent loans via a pie chart grouped by the number of days in delay. The user can choose to change the type of chart.'
              },
              {
                term: 'Default/ Prepayment Distribution',
                explanation: 'Shows separately the graphs of Default Rate and Prepayment Rate, by month and cumulative.' +
                  ' The user has the option of filtering the data visualized in the graph by year in order ' +
                  'to view closely specific information '
              },
              {
                term: 'Losses',
                explanation: "In this section,a chart representation of actual and expected losses is displayed." +
                  "The user, as in Summary Statistics, can filter by year of investment and actual/expected/both types of losses."
              },
              {
                term: 'IRR graph',
                explanation: 'In this graph there are three types of IRRs displayed for each year: Upside Case, Downside Case and Base Case.'
              },
            ]}
          />
        </div>
      </Col>
    </Row>
  );
};

export default HDREnvironment;