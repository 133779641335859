import React, { useRef, useState } from 'react';
//Other Libs
import queryString from "query-string";
//Components
import { Button, Col, Input, Row, Select, Tooltip } from "antd";
import { DownloadAsCSV, Filters, SmartTable } from "../../index";
//Icons
import { FileExcelOutlined, InfoCircleOutlined } from "@ant-design/icons";
//Helpers
import { callApi, createFileName, downloadClientFile } from "../../../helpers";

//Styles
const styles = {
  search: {
    minWidth: 200
  }
}

interface Props {
  height: number,
  vehicleId: any,

  usesFilterByAcquisition?: boolean,
  usesTransactionSearch?: boolean,
  usesRealEstateSearch?: boolean,
  usesFilters?: boolean,
  usesFilterByStatus?: boolean,
  usesFilterByCashFlow?: boolean,

  excelDownload?: boolean,
  usesDownload?: boolean,
  proposalId?: any,

  filterOptions?: any
  tableConfig: any,
  externalData?: any,
  onQueryChange?: any,
  fileExtension?: string,
  usesFilterByIneligibleProperties?: boolean,
  defaultValueForAcquisitionStatus?: any,
  dateFilter?: any,
  defaultDate?: any,

  extra?: any,
}


const CasavoTable = (props: Props) => {
  const {
    height,
    vehicleId,
    usesFilterByAcquisition,
    usesTransactionSearch,
    usesRealEstateSearch,
    usesFilters,
    usesFilterByStatus,
    usesDownload,
    excelDownload,
    proposalId,
    filterOptions,
    tableConfig,
    externalData,
    onQueryChange,
    usesFilterByCashFlow,
    fileExtension,
    usesFilterByIneligibleProperties,
    defaultValueForAcquisitionStatus,
    dateFilter,
    defaultDate,
    extra: Extra,
  } = props;

  const {endpoints, columns, entity} = tableConfig;
  const tableRef: any = useRef();
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [searchInput, setSearchInput] = useState<any>({});
  const [selection, setSelection] = useState(defaultValueForAcquisitionStatus);
  const [status, setStatus] = useState<any>([]);
  const [cashFlowSelected, setCashFlowSelection] = React.useState([]);
  const [filtersQuery, setFiltersQuery] = useState<any>(null);
  const [isEligibleProperties, setIsEligibleProperties] = useState(false)
  const [dateSelect, setDateSelect] = useState<any>(dateFilter ? dateFilter[0] : " ")

  const getFilters = (): string => {

    const filters: any = {
      vehicle_id: vehicleId,
      proposal_id: proposalId
    }

    if (usesFilterByStatus)
      filters.status = status;

    if (usesFilterByAcquisition)
      filters.acquisition_status = selection;

    if (usesFilterByCashFlow)
      filters.cash_flow_type = cashFlowSelected;

    if (usesFilterByIneligibleProperties)
      filters.eligibility_breach = isEligibleProperties;

    if (dateFilter)
      filters.date = dateSelect;


    let finalQuery = '';

    for (let key in searchInput)
      if (searchInput[key] !== '')
        filters[key] = searchInput[key]

    if (filtersQuery)
      finalQuery += filtersQuery;

    const allFilters = `${finalQuery}${queryString.stringify(filters)}`

    if (onQueryChange) {
      onQueryChange(allFilters)
    }

    return allFilters;
  }

  const onDownload = (decimalSeparatorQuery?: string) => {
    const baseUrl = `${endpoints.downloadUrl}${getFilters()}`;

    const finalUrl = fileExtension === "csv" ? `${baseUrl}${decimalSeparatorQuery}` : baseUrl;

    downloadClientFile({
      url: finalUrl,
      filename: createFileName(entity, fileExtension),
      setLoading
    })
  }

  const onSearch = (attr: string) => (event: any) => {
    setSearchInput((prevState: any) => ({
      ...prevState,
      [attr]: event.target.value
    }))
  }


  const onFilterChange = (query: any) => {
    setFiltersQuery(query);
  }

  const toggleEligibleProperties = () => {
    setIsEligibleProperties(prevState => !prevState)
  }

  const downloadExcel = () => {
    downloadClientFile({
      url: `/casavo/collateral/download_loan_by_loan/?date=${dateSelect}&vehicle_id=${vehicleId}`,
      filename: createFileName('Loan By Loan', 'xlsx'),
      setExcelLoading,
    });
  }

  const finalColumns = typeof columns === 'function' ? columns(tableRef.current?.getData) : columns;

  return (
    <>
      <Row justify={usesFilterByAcquisition ? "space-between" : "end"} gutter={[8 ,8]}>
        {usesFilterByCashFlow && <Col>
          <Select placeholder="Filter by Cash Flow Type" style={{width: 250}} allowClear mode="multiple"
                  value={cashFlowSelected} onChange={setCashFlowSelection}>
            {filterOptions?.cash_flow_type?.map((cashFlow: string) => {
              return (
                <Select.Option key={cashFlow} value={cashFlow}>
                  {cashFlow}
                </Select.Option>
              )
            })
            }
          </Select>
        </Col>}
        {usesFilterByAcquisition && <Col>
          <span className="bolder">Select Acquisition Status </span>
          <Select
            allowClear
            mode="multiple"
            value={selection}
            style={{width: 320}}
            onChange={setSelection}
            defaultValue={defaultValueForAcquisitionStatus}
          >
            {filterOptions?.acquisition_status?.map((value: any) => (
              <Select.Option key={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Col>}
        {usesFilterByStatus && <Col>
          <span className="bolder">Select Status (Casavo) </span>
          <Select
            allowClear
            mode="multiple"
            value={status}
            style={{width: 240}}
            onChange={setStatus}
            placeholder="Select a status"
          >
            {filterOptions.asset_status.map((value: any) => (
              <Select.Option key={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Col>}
        <Col>
          <Row justify="end" align="middle" gutter={8}>
            {usesFilters && <Col>
              <Filters showFilterTags filterOptions={filterOptions} entity={entity} onChange={onFilterChange}/>
            </Col>}
            {usesFilterByIneligibleProperties && <Col>
              <Button type={isEligibleProperties ? "primary" : "default"} loading={loading}
                      onClick={toggleEligibleProperties} icon={<InfoCircleOutlined/>}>
                Ineligible Properties
              </Button>
            </Col>
            }
            {usesRealEstateSearch && <Col>
              <Input
                allowClear
                style={styles.search}
                onChange={onSearch("real_estate_id")}
                placeholder='Search Real Estate ID'
              />
            </Col>}
            {usesTransactionSearch && <Col>
              <Input
                allowClear
                style={styles.search}
                onChange={onSearch("transaction_id")}
                placeholder='Search Transaction ID'
              />
            </Col>}
            {dateFilter && <Col>
              <Select
                allowClear
                value={dateSelect}
                style={{width: 150}}
                onChange={setDateSelect}
                defaultValue={dateSelect}
              >
                {dateFilter?.map((value: any) => (
                  <Select.Option key={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            }
            {usesDownload && <Col>
              {fileExtension === "csv" ? <DownloadAsCSV onDownload={onDownload} loading={loading}/> :
                <Tooltip title={`Download ${fileExtension} file`} placement="left">
                  <Button loading={loading} onClick={() => onDownload()} icon={<FileExcelOutlined/>} shape="circle"/>
                </Tooltip>}
            </Col>}
            {excelDownload &&
              <Col>
                <Button
                  shape="circle"
                  icon={<FileExcelOutlined />}
                  onClick={downloadExcel}
                  loading={excelLoading}
                />
              </Col>
            }
          </Row>
        </Col>
        {!!Extra && <Col>{Extra}</Col>}
      </Row>
      <SmartTable
        ref={tableRef}
        height={height}
        columns={finalColumns}
        filters={getFilters()}
        vehicleId={vehicleId}
        externalData={externalData}
        url={endpoints.tableUrl}/>
    </>
  )
}

CasavoTable.defaultProps = {
  height: 600,
  usesFilterByAcquisition: true,
  usesRealEstateSearch: true,
  usesTransactionSearch: true,
  usesFilters: true,
  usesFilterByStatus: false,
  usesDownload: true,
  usesFilterByCashFlow: false,
  fileExtension: 'xlsx',
  usesFilterByIneligibleProperties: false,
  defaultValueForAcquisitionStatus: 'Actual Acquisition',
  dateFilter: null,
}

export default CasavoTable;
