import React from 'react';
//Google Analytics
import {Analytics, useQuery} from "../../../../../../../../helpers";
//Components
import { Tabs } from "antd";
import OverviewSection from "./components/new_loan_and_drawdown/overview_section";
import NewLoanAndDrawdown from "./components/new_loan_and_drawdown";
import SellerAndDebtorPane from "./components/seller_and_debtor";
import ATECOAndRating from "./components/ateco_and_rating";
import HelveticaLimits from "./components/helvetia_limits";
import {useAuthorization} from "../../../../../../../../utils/hooks";

const TabPane = Tabs.TabPane;

const getLimitGroup = (allGroups: any[], groupName: string) => {
  const foundCategory = allGroups.filter(group => group.category === groupName);

  if (foundCategory.length)
    return foundCategory[0];

  return [];
}

interface Props {
  data?: any,
  id?: any,
  vehicleId?: any,
  urlParam?: any,
  proposalId?: any,
  type?: any,
  vehicle?: any,
  currency?: any,
  record?:any
}

const MCS = (props: Props) => {
  const {user}: any = useAuthorization();
  const {data, vehicleId, urlParam, proposalId, type, vehicle,record} = props;
  const {allLimits} = data;
  const isGeneralLimitsView = type === "default";
  const currency = props.currency || vehicle.base_currency;

  const helveticaData = useQuery({
    deps: [vehicleId, proposalId],
    url: () => `/mcs/proposal_limits/get_helvetia_limits/?vehicle_id=${vehicleId}&proposal_id=${proposalId}`
  })

  const formattedData = {
    newAsset: getLimitGroup(allLimits, 'New Asset Purchase'),
    financing: getLimitGroup(allLimits, 'Financing')
  }

  const onTabChange = (key: any) => {
    Analytics.dispatchEvent({
      category: 'User',
      action: `Changed tabs at ${type} limits`,
      label: key
    });
  }
  const userName = user.username;
 const helveticaTabDemoLabel = userName === "demo"
  return (
    <>
      {isGeneralLimitsView && <OverviewSection urlParam={urlParam} vehicleId={vehicleId} proposalId={proposalId}/>}
      <Tabs onChange={onTabChange}>
        {!isGeneralLimitsView && <TabPane
          key="new_loan_and_drawdown"
          tab="Waterfall">
          <OverviewSection urlParam={urlParam} vehicleId={vehicleId} proposalId={proposalId}/>
          <NewLoanAndDrawdown currency={currency} newLoanData={formattedData} proposalId={proposalId} vehicleId={vehicleId} record={record}/>
        </TabPane>}
        <TabPane
          key="ateco_and_rating"
          tab="ATECO and Rating">
          <ATECOAndRating
            vehicleId={vehicleId}
            urlParam={urlParam}
            currency={currency}
            proposalId={proposalId}/>
        </TabPane>
        <TabPane
          key="seller_and_debtor"
          tab="Seller and Debtor">
          <SellerAndDebtorPane
            currency={currency}
            vehicleId={vehicleId}
            proposalId={proposalId}
            urlParam={urlParam}/>
        </TabPane>
        {helveticaData?.data?.helvetia_outstanding_amount.length > 0 ||  helveticaData?.data?.helvetia_limits.length > 0 &&
            <TabPane
            key="helvetia_limits"
            tab={helveticaTabDemoLabel ? "Insurance Limits" : "Helvetia Limits"}>
            <HelveticaLimits
                helveticaData={helveticaData}
                helveticaTabDemoLabel={helveticaTabDemoLabel}
                vehicleId={vehicleId}
                proposalId={proposalId}
                currency={currency}
                record={record}
            />
        </TabPane>
        }

      </Tabs>
    </>

  )
};

export default MCS;