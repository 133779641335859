import React, { useEffect } from 'react';
//Custom Css
import 'highlight.js/styles/github.css';
//Other Libs
import clone from "clone";
import produce from "immer";
import Nprogress from "nprogress";
//Components
import { Button, Col, Divider, Input, Pagination, Popover, Row, Skeleton } from "antd";
import Datasource from "../data_source";
import { FieldSet } from "@cardoai/components";
import GlobalConfigurations from "../database/global_configurations";
//Helpers
import { downloadClientFile, splitRecords } from "../../../../helpers";
import notifications from "../../../../components/notifications"
//Icons
import {
  AppstoreOutlined,
  CheckOutlined,
  CloudUploadOutlined,
  DownloadOutlined,
  FileSyncOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
//Constants
import { client, downloadTemplate } from "../../config";
import { theme } from "../../../../assets/theme/colors";

interface ILayout {
  current?: any,
  onChange?: any
}

interface IEditTemplate {
  templateId?: any,
  type?: any,
  onCancel?: any
}

const layouts = [
  {
    id: 'grid',
    icon: AppstoreOutlined,
    label: 'Grid'
  },
  {
    id: 'list',
    label: 'List',
    icon: UnorderedListOutlined
  }
];

const removeMultipleNewLines = (value: any) => {
  if (!value)
    return value;
  return value.replace(/\\n/g, ' ');
};

const removeMultipleSpaces = (value: any) => {
  if (!value)
    return value;
  return value.replace(/\s\s+/g, ' ');

}

const parseQuery = (value: any) => {
  // value = removeSingleNewLine(value);
  // value = removeMultipleNewLines(value);
  // value = removeMultipleSpaces(value)
  return value
}

const LayoutRecord = (props: any) => {
  const {active, icon: Icon, id, label, onChange} = props;
  const containerStyle: any = {};

  if (active)
    containerStyle.color = theme.colors.primary;

  return (
    <Row justify="space-between" onClick={() => onChange(id)} style={{cursor: 'pointer'}}>
      <Col xs={18}>
        <Row style={containerStyle}>
          <Col xs={6}>
            {active && <CheckOutlined/>}
          </Col>
          <Col xs={18}>
            {label}
          </Col>
        </Row>
      </Col>
      <Col xs={6}>
        <Icon/>
      </Col>
    </Row>
  )
}

const Layout = ({current, onChange}: ILayout) => {
  // @ts-ignore
  const {icon: Icon} = layouts.find(l => l.id === current);

  return (
    <Popover
      style={{
        margin: 0,
        padding: 0
      }}
      title={null}
      trigger="click"
      placement="bottom"
      content={(
        <Row style={{flexDirection: "column", minWidth: 120}}>
          {layouts.map((layout, index) => (
            <Col xs={24} className="mt4">
              <LayoutRecord
                {...layout}
                onChange={onChange}
                active={current === layout.id}
              />
              {index === 0 && <Divider style={{marginTop: 4, marginBottom: 4}}/>}
            </Col>
          ))}
        </Row>
      )}>
      <Button icon={<Icon/>}>
        View as
      </Button>
    </Popover>
  )
}

const EditTemplate = ({templateId}: IEditTemplate) => {

  const [page, setPage] = React.useState<any>(1);
  const [data, setData] = React.useState<any>(null);
  const [state, setState] = React.useState<any>(null);
  const [layout, setLayout] = React.useState<any>('grid');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<any>(10);
  const [searchQuery, setSearchQuery] = React.useState<any>('');
  const [selected, setSelected] = React.useState<any>([]);
  const [loadingValidation, setLoadingValidation] = React.useState<any>({});
  const [editableDatasource, setEditableDatasource] = React.useState<any>(null);
  const [createGlobalConfiguration, setCreateGlobalConfiguration] = React.useState<boolean>(false);

  useEffect(() => {
    getDetails();
  }, [templateId]);

  useEffect(() => {
    if (data)
      prepareState(data)
  }, [data]);

  const prepareState = (data: any) => {
    setState({
      template: data.template,
      datasource: constructDatasourceState(data.datasource)
    });
  };

  const constructDatasourceState = (sources: any) => {
    const initialForm = {};

    sources.forEach((record: any) => {

      const id = record.id;

      const getNested = (attr: any) => {
        if (!record.configs)
          return null;
        return record.configs[attr]
      }

      // @ts-ignore
      initialForm[id] = {
        validate: false,
        db_choice: record.db_choice,
        expression: record.expression,
        description: record.description,
        host: getNested("host"),
        port: getNested("port"),
        password: getNested("password"),
        username: getNested("username"),
        database: getNested("database"),
      }
    });

    return initialForm;
  };

  const onApply = (config: any) => {
    setState((prevState: any) => {
      return produce(prevState, (draft: any) => {
        for (let attr in config) {
          if (config.hasOwnProperty(attr)) {
            const value = config[attr];
            for (let id in draft.datasource) {
              if (!selected.includes(Number(id)))
                continue;
              draft.datasource[id][attr] = value;
              draft.datasource[id].validate = false;
            }
          }
        }
      })
    })
  };

  const getDetails = () => {
    setLoading(true);
    client({
      url: `/api/template/${templateId}`,
      method: 'get',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      setData(response)
    }).finally(() => {
      setLoading(false);

    })
  };

  const onGenerate = () => {
    Nprogress.start();

    const params: any = {
      method: "string",
      template_id: templateId,
      datasources: data.datasource.map((source: any) => {
        const current = state.datasource[source.id];
        return {
          template_id: templateId,
          sheet_name: source.sheet_name,
          db_choice: current.db_choice,
          description: current.description,
          expression: parseQuery(current.expression),
          configs: {
            host: current.host,
            database: current.database,
            username: current.username,
            password: current.password,
            port: current.port ? Number(current.port) : null,
          }
        }
      })
    };

    client({
      url: `/api/report/`,
      data: params,
      method: 'post',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      downloadClientFile({
        link: true,
        url: response,
        filename: "files.xlsx",
      })
    }).catch((error: any) => {
      notifications.error("An error happened during generation. Please Try Again Later!");
    }).finally(() => {
      Nprogress.done();
    })
  };

  const onReportsUpdate = async () => {

    const params: any = {
      template: {
        template_name: state.template.template_name,
        description: state.template.description,
      },
      datasources: data.datasource.map((source: any) => {
        const current = state.datasource[source.id];
        return {
          id: source.id,
          sheet_name: source.sheet_name,
          db_choice: current.db_choice,
          description: current.description,
          expression: parseQuery(current.expression),
          configs: {
            host: current.host,
            database: current.database,
            username: current.username,
            password: current.password,
            port: current.port ? Number(current.port) : null,
          }
        }
      })
    };

    client({
      url: `/api/template/${templateId}/`,
      data: params,
      method: 'put',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      getDetails();
    }).finally(() => {

    })
  };

  const onReportLoadingValidate = async (id: any, value: any) => {
    setLoadingValidation((prevState: any) => {
      return {
        ...prevState,
        [id]: value
      }
    })
  };

  const onReportsValidate = async () => {

    for (let id in state.datasource) {
      if (state.datasource.hasOwnProperty(id)) {
        const current = state.datasource[id];
        if (!current.validate) {
          await onReportLoadingValidate(id, true);

          const params = {
            expression: current.expression,
            db_choice: current.db_choice,
            configs: {
              host: current.host,
              port: current.port ? Number(current.port) : null,
              database: current.database,
              username: current.username,
              password: current.password
            }
          };

          client({
            url: "/api/report/validate/",
            data: params,
            method: 'post',
            responseType: 'json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          }).then((response: any) => {
            setTimeout(() => {
              onValidate(id, true);
            }, 2000)
          }).catch((response: any) => {
            onValidate(id, false);
            notifications.error(response.data.message);
          }).finally(() => {
            setTimeout(() => {
              onReportLoadingValidate(id, false);
            }, 2000)
          })
        }
      }
    }
  };

  const onReportsDownload = async () => {
    downloadTemplate(templateId);
  };

  const onValidate = (id: any, value: any) => {
    setState((prevState: any) => {
      return produce(prevState, (draft: any) => {
        draft.datasource[id].validate = value;
      })
    })
  };

  const onChange = (attr: any) => (event: any) => {
    setState((prevState: any) => ({
      ...prevState,
      template: {
        ...prevState.template,
        [attr]: event.target.value
      }
    }))
  };

  const onSearch = (e: any) => {
    setSearchQuery(e);
    resetPagination();
  };

  const onDelete = () => {
    getDetails();
  };

  const onShowPageSize = (_: any, v: any) => {
    setPageSize(v);
    setPage(1);
  }

  const resetPagination = () => {
    if (page !== 1)
      setPage(1);
    if (pageSize !== 10)
      setPageSize(10);
  };

  const updateDatasource = (id: any) => (attr: any) => (e: any) => {
    if (typeof e === "object")
      e = e.target.value;
    setState((prevState: any) => {
      return produce(prevState, (draft: any) => {
        if (attr !== "description")
          draft.datasource[id].validate = false;

        draft.datasource[id][attr] = e;
      })
    })
  };

  if (loading || !state)
    return <Skeleton active={true}/>

  let dataSources = data.datasource;

  if (searchQuery !== "" && searchQuery !== null) {
    dataSources = dataSources.filter((source: any) => {
      return source.sheet_name.toLowerCase().includes(searchQuery.toLowerCase());
    })
  }

  const totalDataSources = clone(dataSources);

  const results = splitRecords(dataSources, pageSize);

  dataSources = results[page - 1] || [];

  // @ts-ignore
  const reportsValidated = Object.values(state.datasource).every(d => d.validate);

  return (
    <>
      <Row gutter={32} justify="space-between">
        <Col xs={12}>
          <FieldSet
            autoFocus
            label="Name"
            component={Input}
            placeholder="Enter Name"
            value={state.template.template_name}
            onChange={onChange("template_name")}/>
        </Col>
        <Col xs={12}>
          <FieldSet
            component={Input}
            label="Description"
            placeholder="Enter Description"
            value={state.template.description}
            onChange={onChange("description")}/>
        </Col>
      </Row>
      {!createGlobalConfiguration && <Row justify="end" className="mt16">
        <Col>
          <Button onClick={() => setCreateGlobalConfiguration(true)}>
            Create Global Configuration
          </Button>
        </Col>
      </Row>}
      {createGlobalConfiguration && (
        <GlobalConfigurations
          onApply={onApply}
          selected={selected}
          setSelected={setSelected}
          dataSources={data.datasource}
        />
      )}
      <Divider style={{fontWeight: 400}}>
        Data sources
      </Divider>
      <Row justify="space-between">
        <Col xs={8} style={{paddingRight: 8}}>
          <Input.Search
            onChange={(e) => {
              const value = e.target.value;
              if (!value)
                setSearchQuery(null);
            }}
            onSearch={onSearch}
            placeholder="Search Data sources"/>
        </Col>
        <Col>
          <Row gutter={16}>
            <Col>
              <Row gutter={8} justify="end">
                <Col>
                  <Button disabled={reportsValidated} icon={<FileSyncOutlined/>} onClick={onReportsValidate}>
                    Validate
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={onGenerate}
                    disabled={!reportsValidated}
                    icon={<CloudUploadOutlined/>}>
                    Generate
                  </Button>
                </Col>
                <Col>
                  <Button icon={<DownloadOutlined/>} onClick={onReportsDownload}>
                    Download
                  </Button>
                </Col>
                <Col>
                  <Button icon={<SaveOutlined/>} onClick={onReportsUpdate}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Layout
                current={layout}
                onChange={setLayout}/>
            </Col>
            <Col>
              <Pagination
                showSizeChanger
                current={page}
                pageSize={pageSize}
                onChange={setPage}
                total={totalDataSources.length}
                onShowSizeChange={onShowPageSize}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16} className="mt16" id={"scroll-content"}>
        {dataSources.map((record: any) => {
          const id = record.id;

          const dimensions = {
            xs: 24, md: 12, lg: 8
          };

          if (layout === "list") {
            dimensions.md = 24;
            dimensions.lg = 24;
          }

          const selected = editableDatasource && id === editableDatasource.id;

          if (selected) {
            dimensions.md = 24;
            dimensions.lg = 24;
          }

          return (
            <Col {...dimensions} className="mt16">
              <Datasource
                record={record}
                onSave={getDetails}
                onDelete={onDelete}
                selected={selected}
                templateId={templateId}
                onValidate={onValidate}
                state={state.datasource[id]}
                onChange={updateDatasource(id)}
                setEditable={setEditableDatasource}
                parentValidation={loadingValidation[id]}
                activeAction={selected && editableDatasource.type}/>
            </Col>
          )
        })}
      </Row>
    </>
  )
};

export default EditTemplate;