const HDR_ASSETS_CONFIG = {
  images: {
    homeModule: {
      access: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/access.png',
    },
    HDRModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/vdr_fullView.png',
      mainStatistics: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/mainStatistics.png',
      hdrEnvironment: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/vdrEnvironment.png',
      summaryStatistics: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/summaryStatistics.png',
      defaultDistribution: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/defaultDistribution.png',
      delinquenciesDistribution: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/deliquenciesDistribution.png',
      stratification: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/stratifications.png',
      losses: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/losses.png',
      irrGraph: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/irrGraph.png',
    },
    loansManagementModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/loansManagement.png',
    },
    documentsModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/documments.png',
    },
    syncsModule: {
      fullView: 'https://d1e7g9vo5zfob6.cloudfront.net/help_module/images/syncs.png',
    }
  }
};

export default HDR_ASSETS_CONFIG;