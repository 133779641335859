import { useContext } from "react";

import { AuthorizationContext } from "../context/authorization_provider";
import { AuthenticationContext } from "../context/authentication_provider";

export const useAuthentication = (): any => {
  return useContext(AuthenticationContext);
}

export const useAuthorization = (): any => {
  return useContext(AuthorizationContext);
}