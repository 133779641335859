import React from 'react';
//Components
import { Col, Row } from "antd";
import { SmartChart } from "../../../../../../../components";
//Helpers
import { formatters } from "../../../../../../../helpers";
import { theme } from "../../../../../../../assets/theme/colors";

const prepareData = (data: any) => {
  const labels: any[] = [];
  const charts = [
    [
      {
        data: [],
        id: 'senior_capital',
        label: 'Senior Note',
        backgroundColor: theme.colors.orangepeel
      },
      {
        data: [],
        id: 'junior_capital',
        label: 'Junior Note',
        backgroundColor: theme.colors.blueshade
      }
    ],
    [
      {
        data: [],
        id: 'senior_interest',
        label: 'Senior Note',
        backgroundColor: theme.colors.orangepeel
      },
      {
        data: [],
        id: 'junior_interest',
        label: 'Junior Note',
        backgroundColor: theme.colors.blueshade
      }
    ]
  ]

  data.forEach((record: any) => {
    labels.push(record.payment_date);
    charts.forEach(chart => chart.forEach((dataset: any) => dataset.data.push(record[dataset.id])))
  });

  return {
    labels,
    charts
  }
}


const Charts = (props: { data?: any }) => {
  const {data} = props;
  const {labels, charts} = prepareData(data);

  return (
    <Row>
      <Col xs={24} lg={12} className="mt16">
        <SmartChart
          hideDots
          height={500}
          displayLegend
          labels={labels}
          types={['line']}
          dataSets={charts[0]}
          innerDatasets={false}
          title="Principal Evolution"
          format={formatters.thousands}/>
      </Col>
      <Col xs={24} lg={12} className="mt16">
        <SmartChart
          hideDots
          height={500}
          displayLegend
          labels={labels}
          types={['line']}
          dataSets={charts[1]}
          innerDatasets={false}
          format={formatters.thousands}
          title="Interest and Additional Return per month"/>
      </Col>
    </Row>
  );
};

export default Charts;