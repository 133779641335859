import React from "react";
//Components
import { Button, Modal, Popover, Tooltip } from "antd";
import Groups from "./groups";
//Icons
import { ProjectOutlined } from "@ant-design/icons";

interface Props {
  useModal: any,
  updateConfig: any,
  config: any,
  showGroupHeader: any
}

const FilterGroupsView = (props: Props) => {
  const {useModal, updateConfig, config, showGroupHeader} = props;

  const directModification = !useModal;

  const reference = React.useRef<any>(null);
  const [visible, setVisible] = React.useState<boolean>(false);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const performUpdate = () => {
    const config = reference.current.getGroups();
    updateConfig(config);
  }

  const onOk = () => {
    performUpdate();
    closeModal();
  }

  const content = (
    <Groups
      config={config}
      ref={reference}
      forceUpdate={updateConfig}
      showGroupHeader={showGroupHeader}
      directModification={directModification}/>
  )

  if (useModal) {
    return (
      <>
        <Tooltip title="Filter Columns" placement='top'>
          <Button shape='circle' icon={<ProjectOutlined/>} onClick={openModal}/>
        </Tooltip>
        <Modal
          onOk={onOk}
          width={1580}
          okText="Apply"
          centered={true}
          closable={false}
          visible={visible}
          onCancel={closeModal}
          title="Filter Columns"
        >
          {content}
        </Modal>
      </>
    )
  }

  return (
    <Popover
      title={null}
      trigger="click"
      placement="left"
      content={content}>
      <Button
        shape="circle"
        icon={<ProjectOutlined/>}/>
    </Popover>
  )
};

export default FilterGroupsView;
