import React from 'react';
//Components
import { Skeleton } from "antd";
import { SmartChart } from "../../../../../components";
import { RadioGroup, SimpleEmpty } from "@cardoai/components";
//Configurations
import { prepareChartConfig, selectionConfig } from "./config";
import { withChartScale } from "../../../../../utils";

interface IChartContent {
  usesToggle?: boolean,
  radioGroup?: any,
  displayLabels?: boolean,
  defaultSelection?: string,
  data?: any,
  config?: any,
  height?: string | number,
  title?: string,
  loading?: boolean,
  onClick: () => any,
  selection?: string,
  yAxisLabel?: string | ((param: string | undefined) => string),
}

const ChartContent = (props: IChartContent) => {
  let {
    data, config, height, displayLabels, usesToggle, title,
    loading, onClick, radioGroup, selection, yAxisLabel, ...others
  } = props;

  const [toggle, setToggle] = React.useState(selection);

  if (typeof yAxisLabel === "function")
    yAxisLabel = yAxisLabel(toggle)

  const chartConfig = prepareChartConfig(data, config, toggle);

  if (!data)
    return <Skeleton/>;

  if (!data.length)
    return <SimpleEmpty height={265} centered description={title ? `No Data for ${title}.` : 'No Data'}/>

  let extra = null;

  if (usesToggle)
    extra = (
      <RadioGroup
        size='small'
        value={toggle}
        fieldLabel="label"
        onChange={setToggle}
        entries={radioGroup}/>
    );

  return (
    <SmartChart
      beginAtZero
      title={title}
      extra={extra}
      displayLegend={false}
      innerDatasets={false}
      onPointClick={onClick}
      height={height || 240}
      yAxisLabel={yAxisLabel}
      displayLabels={displayLabels}
      {...others}
      {...chartConfig}/>
  );
};
ChartContent.defaultProps = {
  usesToggle: false,
  displayLabels: true,
  selection: selectionConfig.selection,
  radioGroup: selectionConfig.radioGroup
}

export default withChartScale([
  {
    axis: "x",
    format: 'days',
    beginZero: false,
    entity: "delinquence",
    axisLabel: "Delinquence"
  },
  {
    axis: "y",
    entity: "gwar",
    beginZero: true,
    format: 'percent',
    axisLabel: "WAR"
  }
])(ChartContent)

