//Utils
import KEYS from "../../../spv/components/management/keys";
import withDownload from "../../../../utils/hocs/with_download";
//Custom Components
import { Debtor, Diversification, SectorChart, GeographyContent } from "../../../common";

export default [
  {
    key: KEYS.breakdown,
    label: "General Strats",
    content: Diversification,
  },
  {
    key: KEYS.geography,
    label: "Geography",
    content: withDownload(GeographyContent)({
      disableSnapshotOnDarkMode: true
    }),
  },
  {
    key: KEYS.sector,
    label: "Sector",
    content: withDownload(SectorChart)({disableSnapshotOnDarkMode: false})
  },
  {
    key: KEYS.debtor,
    label: "Debtor",
    content: Debtor
  }
];
