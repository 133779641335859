import React from 'react';

interface IDefinition {
  term: any,
  explanation: any,
}

interface IDefinitionList {
  data: any,
  header?: string,
  ordered?: boolean,
  children?: any
}

const Definition = (props: IDefinition) => {
  const {term, explanation} = props;
  return <li >
    <span className="bold">{term}: </span>
    <span>{explanation}</span>
  </li>
}


const DefinitionList = (props: IDefinitionList) => {
  const {data, header, ordered} = props;

  const innerList = data.map(({term, explanation}: any, index: any) => {
    return <Definition key={String(index)} term={term} explanation={explanation}/>
  })
  return (
    <>
      <div><p>{header}</p></div>
      <br/>
      <div>
        {ordered ? <ol>{innerList}</ol> : <ul>{innerList}</ul>}
      </div>
    </>
  );
};

DefinitionList.defaultProps = {
  data: [],
  header: "",
  ordered: false
}

export default DefinitionList;
