//Other Libs
import styled from "styled-components";

interface Props {
  width?: any
}

export default styled.div<Props>`
  ${props => {
    const width = props.width;
    if (typeof width === "string")
      return `minWidth: ${width};`
    return `minWidth: ${width}px;`
  }};
  padding: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  left: -9999px;
`;
