import React from 'react';
//Helpers
import { amountClass, dateClass, radioButton as config } from "./config";
import { Analytics, formatters, isEmptyObject } from "../../../../../helpers";
//Components
import { CustomTable, RadioGroup, SimpleEmpty } from "@cardoai/components";

const createHeader = (keys: any) => {
  return (
    <thead>
    <tr>
      <th/>
      {keys.map((date: any) => (
        <th key={date} className={dateClass(date)}>
          {formatters.date(date)}
        </th>
      ))}
    </tr>
    </thead>
  )
}
const createBody = (data: any, keys: any, currency: any) => {
  return (
    <tbody>
    <tr>
      <td className="italic bolder">
        Cash Flows
      </td>
      {keys.map((key: any, i: any) => {
        const records = data[key];

        return (
          <td key={String(i)}>
            {records.map(({amount, cash_flow_type}: any, index: any) => {
              const showDivider = index !== 0;
              return (
                <p key={String(index)} className={showDivider ? "borderTop" : ""}>
                      <span className={amountClass(amount)}>
                        {formatters.currency({value: amount, currency})}
                      </span>
                  {" "}
                  <span>
                     ({cash_flow_type})
                      </span>
                </p>
              )
            })}
          </td>
        )
      })
      }
    </tr>
    </tbody>
  )
}


const EventsTable = ({data, currency}: any) => {
  const [type, setType] = React.useState("realized");
  const finalData = data[type];
  const keys = Object.keys(finalData);


  let content;

  if (isEmptyObject(keys)) {
    content = <SimpleEmpty description="No cash flow data available yet"/>;
  } else {
    content = (
      <CustomTable hover={false}>
        {createHeader(keys)}
        {createBody(finalData, keys, currency)}
      </CustomTable>
    )
  }

  const onTypeChange = (key: any) => {
    setType(key);
    Analytics.dispatchEvent({
      category: 'User',
      action: "Changed cashflow types at Events table inside loan details",
      label: key
    });
  }

  return (
    <>
      <RadioGroup
        value={type}
        position='right'
        entries={config}
        onChange={onTypeChange}/>
      <div className="mt8">
        {content}
      </div>
    </>
  );
};

export default EventsTable;