//Analytics
import ReactGA from "react-ga";

/**
 * Analytics is an object that contains multiple methods to initiate Google Analytics tracking.
 * It allows for easier usage of dispatching events and its categories
 *
 * Example:
 * @usage: Analytics.dispatchEvent({
 *   category: "",
 *   action: "",
 *   label: ""
 * })
 *
 */

export const Analytics: any = {

  createDimension: function (dimensionIndex: string, value: string) {
    ReactGA.set({dimensionIndex: value});
  },

  initialize: function (userInfo: any) {
    ReactGA.initialize('UA-191031236-1', {
      gaOptions: {
        userId: userInfo.id,
        clientId: userInfo.id
      }
    });
    this.createDimension("dimension1", userInfo.role)
    this.createDimension("dimension2", userInfo.id)
    this.createDimension("dimension3", userInfo.username)
    ReactGA.pageview(window.location.pathname);
  },

  dispatchEvent: function (eventConfiguration: any) {
    return ReactGA.event(eventConfiguration);
  }
}