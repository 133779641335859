import React from 'react';
//Components
import { Card } from "antd";
//Custom Components
import { CustomTable } from "@cardoai/components";
import CardTitle from "./card_title";
//Helpers
import { validateValue } from "../../../../../../helpers";
//Constants
import { tableConfig } from "../config";


interface Props {
  country: any,
  onClick: any
  currency?: any
}

const CountryCard = (props: Props) => {
  const {country, onClick, currency} = props;

  return (
    <Card
      bordered={false}
      size='small'
      style={{width: 250}}
      className="textCenter"
      title={<CardTitle
        record={country}
        onClick={onClick}
        title={country.name}/>}>
      <CustomTable hover={false} bordered={false}>
        <tbody>
        {tableConfig(currency).map((heading: any) => {
          return <tr>
            <td className="bolder">{heading.label}</td>
            <td>{validateValue(country[heading.key], heading.format)}</td>
          </tr>
        })}
        </tbody>
      </CustomTable>
    </Card>
  );
};

export default CountryCard;


