//Helpers
import { formatters } from "../../../../helpers";
//Colors
import { theme } from "../../../../assets/theme/colors";

export const columns: any = [
  {
    key: "originator",
    label: "Originator",
    group: "General",
    grouping: true,
    width: 100
  },
  {
    key: "cutoff_date",
    label: "Cutoff Date",
    group: "General",
    width: 100,
    format: formatters.safeDate,
    grouping: true,
  },
  {
    key: "loan_id",
    label: "Loan ID",
    group: "Identifier",
    grouping: true,
    width: 100,
  },
  {
    key: "id_borrower",
    label: "Borrower ID",
    group: "Identifier",
    grouping: true,
    width: 100
  },
  {
    key: "borrower_name",
    label: "Borrower Name",
    group: "Identifier",
    width: 350,
    grouping:true,
  },
  {
    key: "loan_type",
    label: "Loan Type",
    group: "Loan Details"
  },
  {
    key: "line_description",
    label: "Line Description",
    width: 150,
    group: "Loan Details"
  },
  {
    key: "origination_channel",
    label: "Origination Channel",
    group: "Loan Details"
  },
  {
    key: "loan_purpose",
    label: "Loan Purpose",
    group: "Loan Details",
    width: 200
  },
  {
    key: "performance_status",
    label: "Performance Status",
    group: "Loan Details"
  },
  {
    key: "syndicated_loans",
    label: "Syndicated Loans",
    group: "Loan Details",
    grouping: true,
  },
  {
    key: "syndicated_portion",
    label: "Syndicated Portion",
    group: "Loan Details"
  },
  {
    key: "securitised",
    label: "Securitised",
    group: "Loan Details"
  },
  {
    key: "issue_date",
    label: "Issue Date",
    group: "Loan Life",
    grouping: true,
    format: formatters.safeDate,
    width: 150
  },
  {
    key: "loan_age",
    label: "Loan Age (yrs)",
    group: "Loan Life",
    grouping: true,
    width: 150,
    format: (value: any) => value ? formatters.thousands(value) : "-",
  },
  {
    key: "original_maturity_date",
    label: "Original Maturity Date",
    group: "Loan Life",
    width: 150,
    format: formatters.safeDate,
  },
  {
    key: "current_maturity_date",
    label: "Current Maturity Date",
    group: "Loan Life",
    width: 150,
    format: formatters.safeDate,
  },
  {
    key: "internal_credit_rating_at_origination",
    label: "Internal Credit Rating at Origination",
    group: "Rating",
    width: 150,
  },
  {
    key: "external_credit_rating_at_origination",
    label: "External Credit Rating at Origination",
    group: "Rating",
    width: 150,
  },
  {
    key: "source_of_external_credit_rating",
    label: "Source of External Credit Rating",
    group: "Rating",
    width: 150,
  },
  {
    key: "original_balance",
    label: "Original Balance",
    group: "Balance",
    format: formatters.thousands,
  },
  {
    key: "current_balance",
    label: "Current Balance",
    group: "Balance",
    format: formatters.thousands,
  },
  {
    key: "current_principal_date",
    label: "Date of Current Principal",
    group: "Balance",
    format: formatters.safeDate,
    width: 150
  },
  {
    key: "current_principal",
    label: "Current Principal",
    group: "Balance",
    format: formatters.thousands,
  },
  {
    key: "accrued_interest_balance",
    label: "Accrued Interest Balance",
    group: "Balance",
    width: 150,
    format: formatters.thousands,
  },
  {
    key: "other_balances",
    label: "Other Balances",
    group: "Balance",
    format: formatters.thousands,
  },
  {
    key: "original_interest_rate",
    label: "Original Interest Rate",
    group: "Balance",
    width: 150,
  },
  {
    key: "original_interest_rate_type",
    label: "Original Interest Rate Type",
    group: "Balance",
    width:150
  },
  {
    key: "interim_cashflows",
    label: "Interim Cashflows",
    group: "Balance"
  },
  {
    key: "last_payment_date",
    label: "Last Payment Date",
    group: "Default Details",
    width: 150,
    format: formatters.safeDate,
  },
  {
    key: "last_payment_amount",
    label: "Last Payment Amount",
    group: "Default Details",
    width: 150,
    format: formatters.thousands,
  },
  {
    key: "default_date",
    label: "Default Date",
    group: "Default Details",
    grouping: true,
    format: formatters.safeDate,
    width: 100,
  },
  {
    key: "amount_at_default_date",
    label: "Amount at Default Date",
    format: formatters.thousands,
    group: "Default Details",
    width: 150,
  },
  {
    key: "personal_guarantees",
    label: "Personal Guarantees (Y/N)",
    group: "Personal Guarantees Info",
    width: 150,
  },
  {
    key: "personal_guaranteed_amount",
    label: "Personal Guaranteed Amount",
    group: "Personal Guarantees Info",
    width: 150,
    format: formatters.thousands,
  },
  // {
  //   key: "corporate_guarantees",
  //   label: "# of Personal/Corporate Guarantees",
  //   group: "Collateral Info",
  //   width: 150,
  // },
  {
    key: "re_guarantees",
    label: "# of RE Guarantees",
    group: "Collateral Info"
  },
  {
    key: "mortgage",
    label: "Mortgage",
    group: "Collateral Info",
  },
  {
    key: "mortgage_amount",
    label: "Mortgage Amount",
    group: "Collateral Info",
    format: formatters.thousands,
    width: 150
  },
  {
    key: "lien",
    label: "Lien",
    group: "Collateral Info",
    width: 150
  },
  {key: "id_mortgage", label: "ID Mortgage", group: "Collateral Info", grouping: true,width: 100},
  {
    key: "higher_ranking_loan",
    label: "Higher Ranking Loan",
    group: "Collateral Info",
    format: formatters.thousands,
    width: 150,
  },
  {
    key: "collateral_value",
    label: "Collateral Value",
    group: "Collateral Info",
    format: formatters.thousands,
  },
  {
    key: "ltv",
    label: "LTV",
    group: "Collateral Info",
    format:formatters.percent
  },
  {
    key: "legal_procedure_type",
    label: "Legal Procedure Type",
    group: "Legal Status",
    width: 150

  },
  {
    key: "rge_procedure",
    label: "RGE Procedure",
    group: "Legal Status",
    width: 150,
  },
  {
    key: "court",
    label: "Court",
    group: "Legal Status",
    width: 150
  },
  {
    key: "legal_seniority",
    label: "Seniority on legal procedure or SoW",
    group: "Legal Status"
  },
  {
    key: "stage_procedure",
    label: "Stage Procedure",
    group: "Legal Status",
    width: 150
  },
  {
    key: 'notes',
    label: 'Notes',
    group: "Additional Information",
    width: 150,
  },
  // {
  //   key: "hdr",
  //   label: "HDR (x)",
  //   group: "Legal Status",
  //   width: 150
  // },
]

export const columnGroups: any = [
  {
    key: "General",
    styles: {
      background: theme.palette.border[2],
      borderColor: theme.colors.white,
      color: 'rgba(0, 0, 0, 0.65)'
    },
  },
  {
    key: "Identifier",
    styles: {
      background: theme.palette.border[3],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Loan Details",
    styles: {
      background: theme.palette.border[2],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Loan Life",
    styles: {
      background: theme.palette.border[5],
      borderColor: theme.colors.white,
      color: 'rgba(0, 0, 0, 0.65)'
    },
  },
  {
    key: "Rating",
    styles: {
      background: theme.palette.border[6],
      borderColor: theme.colors.white,
      color: 'rgba(0, 0, 0, 0.65)'
    },
  },
  {
    key: "Balance",
    styles: {
      background: theme.palette.border[7],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Default Details",
    styles: {
      background: theme.palette.border[8],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Personal Guarantees Info",
    styles: {
      background: theme.palette.border[4],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Collateral Info",
    styles: {
      background: theme.palette.border[9],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Legal Status",
    styles: {
      background: theme.palette.border[3],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Additional Information",
    styles: {
      background: theme.palette.border[5],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
];
