import React from 'react';
//Components
import { Collapse } from "antd";
//Custom Components
import OverviewSection from "../../components/overview_section";
import ConcentrationTable from "./components/concentration_table";
import CriteriaTable from "./components/criteria_table";

const Panel = Collapse.Panel;

interface Props {
  type: string,
  vehicleIdentifier: any,
  vehicleId?: any,
  proposalId?: any,
  currency?: any,
  filterOptions?:any
  status?:any
}

const CasavoLimits = (props: Props) => {
  const {type, vehicleId, proposalId, currency, vehicleIdentifier, status} = props;
  const urlParam = "notes_manager"

  return (
    <>
      <Collapse defaultActiveKey={['1']} ghost>
        <Panel header={<b>Concentration Limits</b>} key="1">
          <ConcentrationTable showHistoricalLimits={type === "default"} urlParam={urlParam}
                              vehicleIdentifier={vehicleIdentifier} vehicleId={vehicleId}
                              proposalId={proposalId} currency={currency}/>
        </Panel>
        <Panel header={<b>Eligibility Criteria</b>} key="2">
          <CriteriaTable status={status} urlParam={urlParam} vehicleId={vehicleId} proposalId={proposalId}/>
        </Panel>
      </Collapse>
    </>
  );
};

export default CasavoLimits;