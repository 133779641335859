import React, { useEffect, useState } from 'react';
//Context
import { Consumer } from "../../../../context";
//Components
import { Tabs } from "@cardoai/components";
//Helpers
import KEYS from "../../keys";
import config, { TABS } from "../../config";
import { copyArray } from "../../../../../../helpers";
import { NAVIGATOR_HELPER, sectionType } from "../../helpers";
//Hooks
import { useTabsNavigation } from "../../../../../../utils/hooks";

interface Props {
  vehicleName?: string,
  redirectionRule?: any,
  sectors?: any,
  countries?: any,
  vehicle: any,
  vehicleId: any,
  singleVehicleView: any
}

const DiversificationContent = (props: Props) => {
  const navigator: any = useTabsNavigation();
  const [tabKey, setTabKey] = useState(KEYS.breakdown);
  const {vehicleName, redirectionRule, sectors, countries, vehicle, vehicleId, singleVehicleView} = props;

  const currency = vehicle.currency;

  useEffect(() => {
    if (redirectionRule && redirectionRule.nestedTabKey)
      setTabKey(redirectionRule.nestedTabKey)
  }, [redirectionRule])

  useEffect(() => {
    const name = NAVIGATOR_HELPER.extractVehicle(navigator);
    const sectionTab = NAVIGATOR_HELPER.extractSectionTab(navigator);

    if (name.label === vehicleName && sectionTab.key === KEYS.diversification) {
      const sectionNestedTab = NAVIGATOR_HELPER.extractSectionNestedTab(navigator);
      setTabKey(previousTabKey => {
        if (previousTabKey !== sectionNestedTab.key)
          return sectionNestedTab.key;
        return previousTabKey;
      })
    }
  }, [navigator]);


  const onTabChange = (event: any) => {
    const tab = TABS.extractConcentration.find((rec: { key: any; }) => rec.key === event);

    const lastIndex = navigator.state.length - 1;
    const copyNavigatorState = copyArray(navigator.state);
    const record = copyNavigatorState[lastIndex];

    record.key = tab.key;
    record.label = tab.label;

    if (record.hasOwnProperty("clear")) {
      const history = [];

      for (let index = 0; index < lastIndex; index++) {
        const item = copyNavigatorState[index];
        history.push(item);
      }

      const list = TABS.extractConcentration;
      const initial = list[0];

      record.clear = [
        ...history,
        {
          ...initial,
          options: {
            initial: initial,
            list: list
          },
        }
      ]
    }

    navigator.set(copyNavigatorState)
    setTabKey(event)
  }

  const otherProps = {
    redirectSourceSector: {
      path: '/spv-management',
      config: {
        rules: {
          vehicleId: vehicleId,
          nestedTabKey: "sector",
          tabKey: "diversification",
          section: sectionType.collateral
        }
      }
    },
    redirectSourceGeography: {
      path: '/spv-management',
      config: {
        rules: {
          vehicleId: vehicleId,
          tabKey: "diversification",
          nestedTabKey: "geography",
          section: sectionType.collateral
        }
      }
    },
    redirectSourceBreakdown: {
      path: '/spv-management',
      config: {
        rules: {
          vehicleId: vehicleId,
          tabKey: "diversification",
          nestedTabKey: "breakdown",
          section: sectionType.collateral
        }
      }
    }
  }

  return (
    <Tabs
      value={tabKey}
      view="management"
      vehicle={vehicle}
      sectors={sectors}
      onChange={onTabChange}
      countries={countries}
      vehicleId={vehicleId}
      currency={currency}
      config={config.diversification}
      singleVehicleView={singleVehicleView}
      {...otherProps}
    />
  );
};

export default Consumer(DiversificationContent);