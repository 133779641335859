import React from "react";
//Custom Components
import Label from "./label"
import Styles from "./styles";

interface TripleSwitchProps {
  value?: any,
  config?: any,
  onChange: (param: any) => any,
  onReset: () => any
}

interface TripleSwitchState {
  animation?: any,
  direction?: any
}

class TripleSwitch extends React.Component<TripleSwitchProps, TripleSwitchState> {

  constructor(props: TripleSwitchProps) {
    super(props);
    this.state = {
      animation: null,
      direction: this.getDirection(props.value),
    };
  }

  getDirection = (currentKey: any) => {

    const {config} = this.props;

    if (!currentKey)
      return "center";

    const directions = Object.keys(config);

    for (let direction of directions) {
      const record = config[direction];

      if (record.key === currentKey)
        return direction;
    }

  };

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (prevState.direction !== this.state.direction) {
      const config = this.props.config;
      const record = config[this.state.direction];
      this.props.onChange(record.key)
    }

    if (prevProps.value !== this.props.value) {
      const current = this.getDirection(this.props.value);
      if (current !== this.state.direction)
        this.props.onReset();
    }
  }

  getSwitchAnimation = (currentDirection: string, buttonDisabled: boolean) => () => {
    if (buttonDisabled) return;

    this.setState(previousState => {
      const previousDirection = previousState.direction;

      let animation = null;

      if (currentDirection === "center" && previousDirection === "left")
        animation = "left-to-center";
      else if (currentDirection === "right" && previousDirection === "center")
        animation = "center-to-right";
      else if (currentDirection === "center" && previousDirection === "right")
        animation = "right-to-center";
      else if (currentDirection === "left" && previousDirection === "center")
        animation = "center-to-left";
      else if (currentDirection === "right" && previousDirection === "left")
        animation = "left-to-right";
      else if (currentDirection === "left" && previousDirection === "right")
        animation = "right-to-left";

      return {
        animation: animation,
        direction: currentDirection
      }
    })
  };

  render() {
    const {config} = this.props;
    const {direction, animation} = this.state;

    return (
      <Styles active={['left', 'right'].includes(direction)}>
        <div className="main-container">
          <div className={`switch ${animation} ${direction}-position`}/>
          {['left', 'center', 'right'].map((dir: any, index: number) => {
            const title = config[dir].title;
            const disabled = config[dir].disabled;
            return (
             <React.Fragment key={index}>
                <input type="radio" className={`${dir}-selector`}/>
                <Label
                  className={`${dir}-label`}
                  completed={direction === dir}
                  onClick={this.getSwitchAnimation(dir, disabled)}
                  style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                >
                  {title}
                </Label>
             </React.Fragment>
            )
          })}
        </div>
      </Styles>
    );
  }
}


const generateRandomKey = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

const withReset = (Component: any) => (props: any) => {
  const [key, setKey] = React.useState(() => generateRandomKey());

  const onReset = () => {
    setKey(generateRandomKey());
  }

  return (
    <div key={key}>
      <Component onReset={onReset} {...props}/>
    </div>
  )
}

export default withReset(TripleSwitch);
