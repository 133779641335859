import React from 'react';
//Other Libs
import { Link } from 'react-router-dom';
//Components
import { Col, Row } from 'antd';
import ToggleButton from "../toggle_button";
//Config
import { siteConfig } from '../../../../../../settings';

export default ({collapsed, onClick}: any) => {
  return (
    <Row className="isoLogoWrapper">
      <ToggleButton onClick={onClick} collapsed={collapsed}/>
      <Link to='/dashboard'>
        {collapsed ?
          <Col xs={0} sm={24}><img src={siteConfig.siteIcon} alt="Securitization" className="siteIcon"/> </Col> :
          <Col xs={24}><img src={siteConfig.siteLogo} alt="Securitization" className="siteLogo"/></Col>}
      </Link>
    </Row>
  );
};
