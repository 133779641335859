import React from "react";
//Components
import { Button } from "antd";
//Styles
import Styles from "./styles";
//Icons
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

interface IToggleButton {
  collapsed?: boolean,
  onClick?: any,
}

const ToggleButton = ({collapsed, onClick}: IToggleButton) => {

  const icon = collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>;

  return (
    <Styles>
      <Button onClick={onClick} shape='circle' icon={icon}/>
    </Styles>
  );
};

export default ToggleButton
