import React from 'react';
//Google Analytics
import { Analytics } from "../../../../helpers";
//Components
import { Col, Select } from "antd";
import { DateOperator } from "@cardoai/components";

const {Option} = Select;

const HDRFilters = (props: any) => {

  const {
    filterAttr,
    lenders,
    getLendersValue,
    handleLenderChange,
    getInvestmentDate,
    handleInvestmentDateChange
  } = props;

  const onInvestmentDateChange = (value: any) => {
    handleInvestmentDateChange(value);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: '(HDR user): Filtered by Investment Date using selection above loans table',
      label: value
    })
  }

  const onLenderChange = (event: any) => {
    handleLenderChange(event);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: '(HDR user): Filtered by Lenders using selection above loans table',
      label: event
    })
  }


  return (
    <>
      {/*{lenders && <Col>*/}
      {/*  <div className="bolder">Filter by Lenders</div>*/}
      {/*  <Select*/}
      {/*    allowClear*/}
      {/*    mode="multiple"*/}
      {/*    style={{width: 240}}*/}
      {/*    filterOption={(input, option: any) =>*/}
      {/*      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
      {/*    }*/}
      {/*    optionFilterProp="children"*/}
      {/*    placeholder="Select Lender/s"*/}
      {/*    value={getLendersValue()}*/}
      {/*    onChange={onLenderChange}>*/}
      {/*    {lenders.map((lender: string) => {*/}
      {/*      return <Option key={lender}>{lender}</Option>*/}
      {/*    })}*/}
      {/*  </Select>*/}
      {/*</Col>}*/}
      <Col>
        <div className="bolder">Filter by Investment Date</div>
        <DateOperator
          config={getInvestmentDate()}
          onChange={onInvestmentDateChange}
        />
      </Col>
    </>
  );
};

export default HDRFilters;