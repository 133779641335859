import React from 'react';
//Components
import { Button, Col, Row } from 'antd';
//Other Libs
import cx from "classnames";
//Custom CSS
import Styles from './checkbox_button_style';

const CheckBoxButton = (props: any) => {

  const {color, size, selected, count, onChange, children, ...others}: any = props;
  return (
    <Styles>
      <Button
        type={selected ? 'primary' : 'default'}
        block
        onClick={onChange}
        className={
          cx({
            [color]: selected,
          })
        }
        size={size}
        title={children}
        {...others}
      >
        <Row justify="center">
          <Col className="labelText">
            {children}
          </Col>
        </Row>
      </Button>
    </Styles>
  );
}

CheckBoxButton.defaultProps = {
  size: 'medium',
}

export default CheckBoxButton;

