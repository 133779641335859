import React from 'react';
import styled from "styled-components";
//Components
import {Row, Col, Tooltip} from "antd";
import {useToggle} from "@cardoai/utils";
import WarningModal from "../../../../../../../../design/warning_modal";
import EligibilityAction from "../../../../../../../../design/eligibility_action";
import {useProposal} from "../../../../../../../../context";
//Styles
const Column = styled(Col)`
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  border-radius: 8px;
  padding: 4px 4px 4px 8px;
  background-color: #fff;

  :hover {
    background-color: #F5F8FE;
  }

  transition: background-color .25s;
`
//Constants
const config = [
    {
        key: 'cure',
        label: 'Curable Mode'
    },
    {
        key: 'waive',
        label: 'Waiver Mode'
    }
]

const ChooseStatus = ({onChange, onClose, value, state, selected, setValue}: any) => {
  const {proposal} = useProposal();
  const [tempValue, setTempValue] = React.useState(null)
  const [visibleWarning, toggleWarning] = useToggle(false);

  const breachedWarning = proposal.warnings_on_submission !==null;
    return (
      <><Row gutter={16}>
        {config.map((record: any) => (
          <Tooltip placement='topLeft' title={record.label}>
            <Col>
              <EligibilityAction
                type={record.key}
                breached={breachedWarning}
                onClick={() => {
                  if (state.length || selected.length) {
                    setTempValue(record.key);
                    toggleWarning();
                  } else {
                    setValue(record.key)
                  }
                  setTimeout(onClose, 250);
                }}
                onChange={onChange}
              />
            </Col>
          </Tooltip>
        ))}
      </Row>
        <WarningModal
        open={visibleWarning}
        onCancel={toggleWarning}
        onOk={() => setValue(tempValue)}
        title='Changes you have not saved will be discarded' />
      </>
    )
};

export default ChooseStatus;