import React from 'react';
//Google Analytics
import { Analytics } from "../../helpers";
//Components
import { Layout } from 'antd';
import ApplicationPrivateRoutes from './routes';
import Header from "./layout/header";
import Footer from "./layout/footer";
import Sidebar from './layout/sidebar';
//Custom Hooks
import { useAuthorization, useChangeLog } from "../../utils/hooks";
//Contexts
import { GlobalApiProvider } from "../../utils/context/api_global_provider";

const App = () => {
  const changeLogAPI = useChangeLog();
  const {role, user, groups, setRole}: any = useAuthorization();

  const userId = user.id;
  const userName = user.username;
  const firstName = user.first_name || "User";

  const onLogOpen = () => {
    changeLogAPI.notifyChanges()
  }

  React.useEffect(() => {

    if (user.cookie_consent === true)
      Analytics.initialize({
        id: userId,
        role: role,
        username: userName
      })
  }, []);


  return (
    <Layout className="layout">
      <Sidebar/>
      <Layout className="isoContentMainLayout">
        <Layout.Content className="content">
          <Header role={role} changeRole={setRole} groups={groups} firstName={firstName}/>
          <GlobalApiProvider>
            <ApplicationPrivateRoutes/>
          </GlobalApiProvider>
        </Layout.Content>
        <Footer onClick={onLogOpen}/>
      </Layout>
    </Layout>
  )
}

export default App;
