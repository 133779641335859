import React from 'react';
//Other libs
import produce from "immer";
//Components
import { Button, Col, Divider, Radio, Row } from "antd";
import DatabaseConfiguration from "./database_configuration";

interface IGlobalConfigurations {
  dataSources: any,
  selected: any,
  setSelected: any,
  onApply: any
}

const GlobalConfigurations = (props: IGlobalConfigurations) => {
  const {dataSources, selected, setSelected} = props;

  const [state, setState] = React.useState<any>({});

  const [modified, setModified] = React.useState<boolean>(false);

  const noneSelected = selected.length === 0;

  const triggerChange = () => {
    if (!modified)
      setModified(true);
  }

  const addSelected = (key: any) => {
    setSelected((prevState: any) => {
      return [
        ...prevState,
        key
      ]
    })
  };

  const removeSelected = (key: any) => {
    setSelected((prevState: any) => {
      return prevState.filter((v: any) => v !== key);
    })
  };

  const onToggle = (key: any) => () => {
    triggerChange();
    return selected.includes(key) ? removeSelected(key) : addSelected(key)
  };

  const onChange = (attr: any) => (value: any) => {
    triggerChange();
    setState((prevState: any) => {
      return produce((prevState, draft): any => {
        draft[attr] = value;
      })
    })
  }

  const toggleAll = () => {
    triggerChange();

    if (noneSelected)
      setSelected(dataSources.map((d: any) => d.id));
    else
      setSelected([])
  };

  const handleApply = () => {
    setModified(false);
    return props.onApply(state);
  };

  return (
    <>
      <Divider style={{fontWeight: 400}}>Global Configurations</Divider>
      <Row gutter={16}>
        <Col xs={12}>
          <DatabaseConfiguration
            state={state}
            onChange={onChange}
          />
        </Col>
        <Col xs={12}>
          <Row gutter={8}>
            {dataSources.map((datasource: any) => {
              const id = datasource.id;
              const sheet_name = datasource.sheet_name;
              return (
                <Col xs={8}>
                  <Radio
                    onClick={onToggle(id)}
                    checked={selected.includes(id)}>
                    {sheet_name}
                  </Radio>
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
      <Row align="middle" gutter={8} justify="end">
        <Col>
          <Button onClick={toggleAll}>
            {noneSelected ? "Select all" : "Deselect all"}
          </Button>
        </Col>
        <Col>
          <Button type={modified ? "primary" : "default"} onClick={handleApply}>
            Apply Configuration
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default GlobalConfigurations;