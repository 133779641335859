import React from 'react';
//Components
import {Row, Col, Select} from "antd";
import Menu from "../../../../../../../design/menu";

const Sider = ({category, onCategoryChange, categories, section, onSectionChange, sections}: any) => {
    return (
        <Row
            gutter={[16, 16]}
            style={{
                paddingRight: 8,
                minHeight: '500px',
                alignContent: 'start',
                boxShadow: 'inset -1px 0px 0px rgba(227, 221, 230, 0.5)'
            }}>
            <Col xs={24} style={{height: '100%'}}>
                <Select
                    value={category}
                    className="fullWidth"
                    onChange={onCategoryChange}>
                    {categories.map((c: any) => (
                        <Select.Option key={c.key}>
                            {c.label}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} style={{height: '100%'}}>
                <Menu
                    value={section}
                    config={sections}
                    onChange={onSectionChange}/>
            </Col>
        </Row>
    );
};

export default Sider;