import {formatters} from "../../../../../../../../helpers";

export const groupedColumns: any = {
    'standard': [
        {
            label: 'LTC Senior',
            key: 'ltc_senior',
            align: "center",
            render: (value: any) => formatters.percent(value),
        },
        {
            label: 'Total Borrowing Base',
            key: 'total_borrowing_base',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
        {
            label: 'Outstanding BB (Reconciled)',
            key: 'outstanding_borrowing_base_reconcile',
            align: "center",
            render: (value: any) => formatters.thousands(value),
            info: 'Computed based on actual cash flows.',
        },
        {
            label: 'Outstanding BB (Overall)',
            key: 'outstanding_borrowing_base_overall',
            align: "center",
            render: (value: any) => formatters.thousands(value),
            info: 'Computed based on both actual and expected cash flows',
        },
        {
            label: 'Delta Outstanding BB',
            key: 'delta_outstanding_borrowing_base',
            align: "center",
            render: (value: any) => formatters.thousands(value),
            info: 'Shows the expected increase of the actual borrowing base',
        },
        {
            label: 'Borrowing Base Released',
            key: 'borrowing_base_released',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
    ],
    'junior': [
        {
            label: 'Total Junior Borrowing Base',
            key: 'total_junior_borrowing_base',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
        {
            label: 'Outstanding Junior BB (Reconciled)',
            key: 'outstanding_junior_borrowing_base_reconciled',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
        {
            label: 'Outstanding Junior BB (Overall)',
            key: 'outstanding_junior_borrowing_base_overall',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
        {
            label: 'Delta Outstanding Junior BB',
            key: 'delta_outstanding_junior_borrowing_base',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
    ],
    'senior': [
        {
            label: 'Total Senior Borrowing Base',
            key: 'total_senior_borrowing_base',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
        {
            label: 'Outstanding Senior BB (Reconciled)',
            key: 'outstanding_senior_borrowing_base_reconciled',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
        {
            label: 'Outstanding Senior BB (Overall)',
            key: 'outstanding_senior_borrowing_base_overall',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        },
        {
            label: 'Delta Outstanding Senior BB',
            key: 'delta_outstanding_senior_borrowing_base',
            align: "center",
            render: (value: any) => formatters.thousands(value),
        }
    ]
}