import React from 'react';
//Component
import { Popover } from "antd";
//Helpers
import { validateValue } from "../../../helpers";
//Icons
import { MinusOutlined } from "@ant-design/icons";

//Constants
const agenciesMap: any = {
  's&p': "S&P",
  'moodys': "Moody's",
  'fitch': "Fitch",
  'dbrs': "DBRS"
}

//Custom Helpers
function prepareData(ratings: any) {
  const ratingConfig = [];

  function getAgency(key: string) {
    const keyEntry = key.split('_');
    const length = keyEntry.length;
    const agencyKey = keyEntry[length - 1];
    if (agencyKey)
      return agenciesMap[agencyKey];
    return null
  }

  for (let key in ratings) {
    if (ratings.hasOwnProperty(key)) {
      ratingConfig.push({
        rating: ratings[key],
        agency: getAgency(key)
      })
    }
  }
  return ratingConfig;
}

interface IRatings {
  height?: any,
  data?: any,
  detailed?: any
  hideRatings?: any
}

export const Ratings = (props: IRatings) => {
  const {height, data, hideRatings} = props;

  const formattedData = prepareData(data);

  const popOverContent = formattedData.map((field, index) => {
    return <div key={String(index)}>
      <p key={String(index)} className="bolder">{field.agency}: {validateValue(field.rating, null, "Not Rated")}</p>
    </div>
  })

  const renderContent = () => {
    let content;
    const validRatings = [];

    //check if all rating values are null
    function emptyRatings() {
      for (let key in data) {
        if (data.hasOwnProperty(key) && data[key] !== null)
          return false;
      }
      return true;
    }

    if (emptyRatings()) {
      content = "Not Rated";
    } else {
      for (let key in data) {
        if (data.hasOwnProperty(key) && data[key] !== null)
          validRatings.push(data[key])
      }
      content = validRatings.join(', ');
    }

    const containerStyle: any = {
      height,
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      justifyContent: 'center'
    };

    if (hideRatings)
      return <MinusOutlined/>

    return (
      <div className="pointerCursor" style={containerStyle}>
        <Popover content={popOverContent}>
          {content}
        </Popover>
      </div>
    )
  };

  return renderContent();
};

