import React from "react";
//Icons
import {
  DeliveredProcedureOutlined,
  FileAddOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  SolutionOutlined,
  WalletOutlined
} from "@ant-design/icons";

export const SYNC_TYPES = {
  "dod": "dod",
  'vdr_request': 'vdr_request',
  "synchronization": "synchronization",
  "new_loan_proposal": "new_loan_proposal",
  "payment_report": "payment_report",
  "incremental_instalment": "incremental_instalment"
}

export const SYNC_SELECTION = [
  {
    key: SYNC_TYPES.synchronization,
    label: "Synchronization",
    icon: <FileSyncOutlined/>,
    description: "Import files to start synchronization",
    permissionKey: "collateral_sync_request"
  },
  {
    key: SYNC_TYPES.new_loan_proposal,
    label: "Loan Proposal",
    icon: <FileTextOutlined/>,
    description: "Import the loan file for a proposal",
    permissionKey: "collateral_simulation_sync_request"
  },
  {
    key: SYNC_TYPES.dod,
    label: "DoD",
    icon: <DeliveredProcedureOutlined/>,
    description: "Import files for DoD",
    permissionKey: "dod_sync_request"
  },
  {
    key: SYNC_TYPES.payment_report,
    label: "Payment Report",
    icon: <WalletOutlined/>,
    description: "Import the payment report file",
    permissionKey: "payment_report_sync_request"
  },
  {
    key: SYNC_TYPES.vdr_request,
    label: "HDR Request",
    icon: <SolutionOutlined/>,
    description: "Import a HDR request",
    permissionKey: "vdr_sync_request"
  },
  {
    key: SYNC_TYPES.incremental_instalment,
    label: "Incremental Instalment",
    icon: <FileAddOutlined/>,
    description: "Import the incremental instalment file",
    permissionKey: "incremental_instalment_sync_request"
  },
]

export const DHC_VIEWS = {
  [SYNC_TYPES.synchronization]: {
    otherProps: {
      defaultDatePicker: "month",
      syncTypeParam: "sync_collateral",
      urlIdentifier: "create_collateral_sync_request",
    }
  },
  [SYNC_TYPES.dod]: {
    otherProps: {
      defaultDatePicker: "date",
      syncTypeParam: "sync_dod",
      urlIdentifier: "create_dod_sync_request"
    }
  },
  [SYNC_TYPES.new_loan_proposal]: {
    otherProps: {
      defaultDatePicker: "date",
      syncTypeParam: "sync_collateral_simulation",
      urlIdentifier: "create_collateral_simulation_sync_request"
    }
  },
  [SYNC_TYPES.payment_report]: {
    otherProps: {
      defaultDatePicker: "date",
      syncTypeParam: "sync_payment_report",
      urlIdentifier: "create_payment_report_sync_request"
    }
  },
  [SYNC_TYPES.vdr_request]: {
    otherProps: {
      defaultDatePicker: "date",
      syncTypeParam: "sync_vdr",
      urlIdentifier: "create_vdr_sync_request"
    }
  },
  [SYNC_TYPES.incremental_instalment]: {
    otherProps: {
      defaultDatePicker: "date",
      syncTypeParam: "sync_incremental_instalment",
      urlIdentifier: "create_incremental_instalment_sync_request"
    }
  }
};


//Modal

export const DEFAULT_MODAL_WIDTH = 700;

export const MODAL_TITLE = {
  selected: 'Import Files',
  unselected: 'Select Originator'
};
