import React from 'react';
//Other libs
import styled from "styled-components";
//Constants
import { Button, Col, Row } from "antd";
//Hooks
import { useRedirection } from "../../../utils/hooks";
import { ArrowLeftOutlined } from '@ant-design/icons';

const Styles = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-flow: row wrap;
  overflow-y: auto;
  max-height: ${props => `${props.theme.height - 41}px`};
  @media (max-width: 580px) {
    padding: 15px;
  }
  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
  }

  .fullContent {
    width: 100%;
    height: 100%;
  }

  .sticky-header {
    top: 0;
    z-index: 999;
    position: sticky;
    background: #fafafa;
    padding: 20px 20px 20px 0;
  }
`;

//Helpers
export default (props: any) => {
  const {setRedirection, reference, setRedirecting, source, clearRules, clearSource} = useRedirection();

  React.useEffect(() => {
    document.title = props.route.title ? `${props.route.title} - Securitization` : "Securitization";
    return () => {
      if (source) clearSource();
      if (!reference.current.isRedirecting) clearRules()
      setRedirecting(false);
    }
  }, [props.route.title]);

  const onBack = () => {
    setRedirecting(true);
    clearSource();
    setRedirection(source)
  }

  return (
    <Styles>
      <div className="fullContent">
        <Row align="middle" gutter={8} className="sticky-header">
          {source && (
            <Col>
              <Button icon={<ArrowLeftOutlined/>} type="primary" shape="round" onClick={onBack}>
                Go Back
              </Button>
            </Col>
          )}
          <Col id="header-layout-title">{props.route?.displayTitle && props.route?.title}</Col>
          <Col id="header-layout"/>
        </Row>
        <div className="fullHeight">{props.children}</div>
      </div>
    </Styles>
  );
};
