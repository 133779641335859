import React, { useEffect, useState } from 'react';
//Components
import { Button, Col, DatePicker, Row, Spin } from "antd";
import GroupedTable from "./grouped_table";
import { Visible } from "@cardoai/components";
import UploadDocument from "../../upload_document";
//Icons
import { FileExcelOutlined } from "@ant-design/icons";
//ka-table
import { ITableProps } from "ka-table";
import { DataType, EditingMode } from "ka-table/enums";
//Custom Components
import { SmartTable } from "../../../../../components";
//helpers
import { useQuery } from "../../../../../helpers";
//Configuration
import { columns } from "../../../hdr_documents/config";

const getQueryParams = (vehicleId: string | number, selectedDate: string) => {
  let query = `?vehicle_id=${vehicleId}&page=${1}&page_size=${1000}`;
  if (selectedDate !== '') query += `&uploaded_at=${selectedDate}`;
  return query;
}

const GroupedDocuments = (props: any) => {
  const {
    vehicleId,
    onDelete,
    onPublish,
    onDownload,
    documentIdentifier,
    allowUpload,
    title,
    config,
    vehicle,
    isGrouped,
    onDownloadMultiple,
  } = props;

  const [tableDataDate, setTableDataDate] = React.useState<string>('');
  const [uploadDataOrder, setUploadDataOrder] = useState<string>('');
  const [uploadDataDate, setUploadDataDate] = useState<string>('');

  const tableData = useQuery({
    deps: [documentIdentifier, vehicleId, tableDataDate],
    url: () => `/main/documents/get_list_of_${documentIdentifier}/${getQueryParams(vehicleId, tableDataDate)}`,
  });

  const uploadData = useQuery({
    deps: [vehicleId, uploadDataOrder, uploadDataDate],
    url: () => {
      let finalUrl = `main/documents/get_list_of_message_attachment_documents/?vehicle_id=${vehicleId}&order=${uploadDataOrder}`;
      if (uploadDataDate !== '') finalUrl = finalUrl + `&created_at=${uploadDataDate}`;
      return finalUrl;
    }
  });

  const onUploadDataSort = (sortingFields: string[]) => {
    let result = '';
    sortingFields.forEach((field: string, index: number) => {
      result += `${index !== 0 ? ',' : ''}${field}`;
    });
    setUploadDataOrder(result);
  }

  const dataSource = tableData.data?.results;

  const updateTable = () => {
    /*After upload success rerender table*/
    tableData.fetch();
  }

  const tablePropsInit: ITableProps = {
    columns: [
      {key: 'selection-cell', style: {width: 50}},
      {key: 'type', title: "Originator", dataType: DataType.String, style: {width: 200}},
      {key: 'group', title: 'Borrower', dataType: DataType.String, style: {width: 200}},
      {key: 'name', title: 'Name', dataType: DataType.String, style: {textAlign: "center", width: 400}},
      {key: 'reference_date', title: 'Reference Date', dataType: DataType.Date, style: {width: 200}},
      {key: 'uploaded_at', title: 'Uploaded At', dataType: DataType.Date, style: {width: 200, textAlign: "center"}},
      {key: 'uploaded_by', title: 'Uploaded By', dataType: DataType.String, style: {width: 300, textAlign: "center"}},
      {key: 'actions', title: 'Actions', style: {width: 300, justifyContent: "center", textAlign: "center"}},
    ],
    data: dataSource,
    search: ({searchText, rowData, column}) => {
      return (column.key === selectedSearchGroup)
        && (rowData[selectedSearchGroup].toString().toLowerCase().includes(searchText.toLowerCase()));
    },
    editingMode: EditingMode.None,
    groups: [
      {columnKey: 'type'},
      {columnKey: 'group'}
    ],
    rowKeyField: 'id',
    height: 480,
  };

  const [tableProps, setTableProps] = React.useState<ITableProps>(tablePropsInit);
  const [selectedSearchGroup, setSelectedSearchGroup] = React.useState<string>('type');

  useEffect(() => {
    setTableProps(tablePropsInit);
  }, [tableData.data, selectedSearchGroup]);

  const downloadMultiple = () => {
    onDownloadMultiple(tableProps.selectedRows);
  }

  const onDateChange = (date: any) => {
    const newDate = date == null ? '' : date.format('YYYY-MM-DD');
    setTableDataDate(newDate);
  }

  const onUploadDateChange = (date: any) => {
    const newDate = date == null ? '' : date.format('YYYY-MM-DD');
    setUploadDataDate(newDate);
  }

  return (
    <>
      {!!tableData.data &&
      <GroupedTable
        tableProps={tableProps}
        setTableProps={setTableProps}
        vehicleId={vehicleId}
        selectedSearchGroup={selectedSearchGroup}
        setSelectedSearchGroup={setSelectedSearchGroup}
        onDateChange={onDateChange}
        loading={tableData.loading}
        callbacks={{
          onDeleteRecord: onDelete,
          onPublishRecord: onPublish,
          onDownloadRecord: onDownload,
          updateTable: updateTable
        }}
      />}

      <Row justify="end" className="mt16" gutter={[16, 0]}>
        <Col>
          <Visible when={allowUpload}>
            <UploadDocument
              title={title}
              config={config}
              vehicle={vehicle}
              isGrouped={isGrouped}
              vehicleId={vehicleId}
              updateTable={updateTable}
              allowUpload={allowUpload}
              documentIdentifier={documentIdentifier}/>
          </Visible>
        </Col>
        <Col>
          <Visible when={tableProps.selectedRows != undefined && tableProps.selectedRows?.length > 0}>
            <Button
              type='primary'
              icon={<FileExcelOutlined/>}
              onClick={downloadMultiple}
            >
              Document Download
            </Button>
          </Visible>
        </Col>
      </Row>

      {!!uploadData &&
        <div className="mt32">
          <Spin spinning={uploadData.loading}>
            <SmartTable
              columns={columns}
              vehicleId={vehicleId}
              externalData={uploadData.data}
              maxHeight={600}
              allowGrouping
              groupBy={"topic_name"}
              onClientSort={onUploadDataSort}
              extra={
                <DatePicker
                  size="small"
                  placeholder="Upload Date"
                  onChange={onUploadDateChange}
                />
              }
            />
          </Spin>
        </div>
      }
    </>
  );
};

export default GroupedDocuments;
