import React from 'react';
//Components
import CollateralStatsTable from "./components/collateral_stats_table";
import CollateralBarChart from "./components/collateral_bar_chart";
import CollateralPieChart from "./components/collateral_pie_chart";

interface ITableAndBarAndPieChartCollateral {
  selectedView?: any,
  categoryName?: any,
  data?: any
}

const NPLCollateralViews = (props: ITableAndBarAndPieChartCollateral) => {
  const {selectedView, categoryName, data} = props

  return (
    <div>
      {selectedView === "Table" && <CollateralStatsTable categoryName={categoryName} data={data}/>}
      {selectedView === "Bar" && <CollateralBarChart categoryName={categoryName} data={data}/>}
      {selectedView === "Pie" && <CollateralPieChart categoryName={categoryName} data={data}/>}
    </div>
  );
};

export default NPLCollateralViews;