import React from "react";
//Components
import { Button, Col, Popconfirm, Row, Tooltip } from "antd";
//Icons
import { CheckOutlined, DeleteOutlined, DownloadOutlined, SendOutlined } from "@ant-design/icons";
//Custom Hooks
import { useAuthorization } from "../../../../utils/hooks";
//Constants
import { theme } from "../../../../assets/theme/colors";
import { actionKey } from "../../../../constants";

//Styles
interface IActionCell {
  record: any,
  vehicleId: any,
  callbacks: any,
}

const primaryStyle = {
  color: theme.colors.primary,
  borderColor: theme.colors.primary
}

const successStyle = {
  color: theme.colors.white,
  borderColor: theme.colors.green,
  backgroundColor: theme.colors.green
}

const ActionCell = (props: IActionCell) => {
  const {record, vehicleId, callbacks} = props;
  const {onDeleteRecord, onPublishRecord, onDownloadRecord, updateTable}: any = callbacks;

  const isConfirmed: any = record && record.confirmed

  const authorization: any = useAuthorization();

  const disabledDelete = !authorization.canPerformAction(actionKey.deleteDocuments, vehicleId)
  const disabledPublish = !authorization.canPerformAction(actionKey.publishDocuments, vehicleId);
  const disabledDownload = !authorization.canPerformAction(actionKey.downloadDocuments, vehicleId);

  return (
    <Row gutter={8} justify="center" style={{minWidth: 100}}>
      {/*Download file*/}
      <Col>
        <Tooltip title="Download" placement='bottom'>
          <Button
            size='small'
            icon={<DownloadOutlined/>}
            disabled={disabledDownload}
            style={disabledDownload ? {} : primaryStyle}
            onClick={onDownloadRecord ? () => onDownloadRecord(record) : undefined}
          />
        </Tooltip>
      </Col>
      {/*Delete file*/}
      <Col>
        <Popconfirm
          okText="Yes"
          cancelText="No"
          disabled={disabledDelete}
          placement='left'
          title="Are you sure to delete this document?"
          onConfirm={() => onDeleteRecord(record, updateTable)}>
          <Tooltip title="Delete" placement='bottom'>
            <Button
              danger
              size='small'
              disabled={disabledDelete}
              icon={<DeleteOutlined/>}/>
          </Tooltip>
        </Popconfirm>
      </Col>
      {/*Publish file*/}
      <Col>
        <Popconfirm
          okText="Yes"
          cancelText="No"
          placement='left'
          disabled={disabledPublish}
          title={`Are you sure to ${isConfirmed ? "unpublish" : "publish"} this document?`}
          onConfirm={() => onPublishRecord(record, updateTable)}>
          <Tooltip title={isConfirmed ? "Unpublish" : "Publish"}
                   placement='bottom'>
            <Button
              size='small'
              disabled={disabledPublish}
              icon={isConfirmed ? <CheckOutlined/> : <SendOutlined/>}
              style={disabledPublish ? {} : !isConfirmed ? primaryStyle : successStyle}/>
          </Tooltip>
        </Popconfirm>
      </Col>
    </Row>
  )
};

export default ActionCell;