import React from "react";
//Icons
import { CommentOutlined } from "@ant-design/icons";

export const ViewReplies = ({item, onClick}: any) => {

  const childMessagesCount = item.child_messages_count;
  const replyLabel = childMessagesCount === 1 ? "reply" : "replies";


  if (!childMessagesCount)
    return null;

  return (
    <div>
    <span className="pointerCursor" onClick={onClick}>
      <CommentOutlined/> View {childMessagesCount} {replyLabel}
    </span>
    </div>
  )
}
