//Components
import DeleteAndDetails from "./delete_and_details";
import HistoryStatus from "./history_status";

export const columns = (vehicleId: number, limitId: number, formatter: any,fulfillment_percent:any,currencyLimits?:any) => [
  {
    key: "id",
    label: "Waiver ID",
    align: "center",
    sortable: true,
  },
  {
    key: "waiver_type",
    label: "Waiver Type",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    key: "date_requested",
    label: "Requested Date",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    key: "requested_by",
    label: "Requested by",
    align: "center",
  },
  {
    key: "status",
    label: "Status",
    align: "center",
    vehicleId: vehicleId,
    limitId: limitId,
    Component: HistoryStatus,
    sortable: true,
  },
  {
    key: "date_decided",
    label: "Reviewed Date",
    align: "center",
    width: 100,
  },

  {
    key: "decided_by",
    label: "Reviewed by",
    align: "center",
  },
  {
    key: "start_date",
    label: "Start Date",
    align: "center",
    width: 100,
  },
  {
    key: "end_date",
    label: "End Date",
    align: "center",
    width: 100,
  },
  {
    key: "action",
    label: "Action",
    align: "center",
    vehicleId: vehicleId,
    limitId: limitId,
    Component: DeleteAndDetails,
    formatter: formatter,
    fulfillment_percent: fulfillment_percent,
    currencyLimits :currencyLimits,
    width: 280,
  },
];
