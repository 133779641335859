export const demoVehicleNameDictionary: any = {
  "Italian SMEs": "Italian NPL"
}


export const demoRatingDictionary: any = {
  "B": "Secured Residential Mortgage",
  "C": "Secured Commercial Mortgage",
  "F": "Leasing",
  "D": "Unsecured",
  "E": "Trade Receivables",
  "A": "Auto-Loans"
};
