import React, { useState } from 'react';
//Other Libraries
import queryString from "query-string";
//Components
import { Card, Col, Row } from 'antd';
//Icons
//Custom Components
import Statistics from "./components/statistics";
import WrapContent from "./components/wrap_content";
//Custom Hooks
import { useAuthorization, useGlobalApi, useRedirection } from "../../utils/hooks";
//Custom Helpers
import { Analytics, callApi, createFileName, downloadClientFile } from "../../helpers";
//Configurations
import { systemCurrency } from "../../settings";
import { actionKey } from "../../constants";
import { DownloadAsCSV, Filters } from "../../components";
import LoansTable from "./components/loans_table";
import { prepareFilters } from "../../components/filters/config";

//Constants
const defaultHiddenFields = {
  table: {
    multipleVehicles: [],
    singleVehicle: [
      "spv_name",
    ],
    companyData: [
      "company_name",
      "company_vat",
      "seller_name",
      "seller_vat"
    ],
    hideForVDR: [
      "pool_addition_date",
      "pd",
      "loan_rating",
      "lgd",
      "rating_at_inception"
    ],
    hideForNotVDR: [
      "investment_date"
    ],
  },
  filters: {
    multipleVehicles: [],
    singleVehicle: [
      "selectedVehicles"
    ]
  },
};

interface ILoansContent {
  wrapContent?: any,
  displayStatistics?: any,
  height?: any,
  vehicleId?: any,
  hiddenFields?: any,
  filterFields?: any,
}

const LoansContent = (props: ILoansContent) => {
  const {
    wrapContent,
    displayStatistics,
    height,
    vehicleId,
    hiddenFields,
    filterFields,
  } = props;

  const {sectors, vehicles} = useGlobalApi();

  const [query, setQuery] = useState<any>(null);
  const [allLenders, setAllLenders] = useState<any>([]);
  const [loading, setLoading] = React.useState<any>(false);

  const {rules}: any = useRedirection();
  const tableReference: any = React.useRef();

  const singleVehicleView = vehicles?.length === 1;
  const userView = singleVehicleView ? "singleVehicle" : "multipleVehicles";
  const currency = singleVehicleView ? vehicles[0].currency : systemCurrency;
  const {canPerformAction}: any = useAuthorization();

  React.useEffect(() => {
    if (!rules) return;
    const initialQuery: any = prepareFilters(rules, 'loans');
    setQuery(initialQuery);
  }, [rules])

  React.useEffect(() => {
    if (canPerformAction(actionKey.showVDRFilters)) {
      callApi({
        url: `/main/vdr/lenders/`,
        onSuccess: (res) => setAllLenders(res),
      })
    }
  }, [])

  const downloadExcel = (decimalSeparatorQuery?: string) => {

    let selectedColumnsKeys: any

    if (tableReference && tableReference.current)
      selectedColumnsKeys = tableReference.current.getSelectedColumnKeys();

    let query = getFinalQuery();

    if (selectedColumnsKeys)
      query = `${query}&columns=${selectedColumnsKeys}`

    if (decimalSeparatorQuery)
      query += decimalSeparatorQuery

    downloadClientFile({
      setLoading,
      filename: createFileName("loans", "csv"),
      url: `/main/sme_loan/download_excel/?${query}`,
    })

    Analytics.dispatchEvent({
      category: 'Download',
      action: 'Downloaded file for all loans at Loans Management',
    });
  };
  const getFinalQuery = () => {
    let str = query ? `${query}&` : "";
    const params: any = {};
    params.vehicle_id = vehicleId;
    return str + queryString.stringify(params)
  };

  const getFilterHiddenFields = () => {
    const hidden: any = [];

    function addField(field: any) {
      if (hidden.includes(field))
        return;
      hidden.push(field);
    }

    function populateFields(fields: any) {
      fields.forEach(addField);
    }

    populateFields(hiddenFields);

    populateFields(defaultHiddenFields.filters[userView]);
    return hidden;
  };

  const getTableHiddenColumns = () => {
    const hiddenColumns: any = defaultHiddenFields.table[userView];
    if (!canPerformAction(actionKey.showCompanyColumns))
      hiddenColumns.push(...defaultHiddenFields.table.companyData);
    if (canPerformAction(actionKey.showVDRFilters))
      hiddenColumns.push(...defaultHiddenFields.table.hideForVDR);
    else
      hiddenColumns.push(...defaultHiddenFields.table.hideForNotVDR);
    return hiddenColumns;
  };

  const header = (
    <Row justify="space-between" align='bottom' gutter={8}>
      <Col style={{flex: 1}}>
        <Filters
          useSearch
          entity="loans"
          showFilterTags
          onChange={setQuery}
          lenders={allLenders}
          defaultFilters={rules}
          canPerformAction={canPerformAction}
          hiddenFields={getFilterHiddenFields()}
          visibleVehicleField={!singleVehicleView}
          {...filterFields}
        />
      </Col>
      <Col style={{flexBasis: 50}}>
        <DownloadAsCSV loading={loading} onDownload={downloadExcel}/>
      </Col>
    </Row>
  );

  return (
    <WrapContent view={Card} wrap={wrapContent}>
      {displayStatistics && <Statistics currency={currency} filters={getFinalQuery()}/>}
      <LoansTable
        header={header}
        height={height}
        sectors={sectors}
        extraHeight={390}
        filters={getFinalQuery()}
        reference={tableReference}
        hiddenColumns={getTableHiddenColumns()}/>
    </WrapContent>
  );
};

LoansContent.defaultProps = {
  height: 580,
  hiddenFields: [],
  filterFields: {
    displayVehicleFilter: true,
    displayPoolDateFilter: true
  },
  wrapContent: true,
  displayStatistics: true,
};

export default LoansContent;