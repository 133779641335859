//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../../../assets/theme/colors";

export default styled.div`
  overflow-x: auto;
  max-height: 280px;

  table {
    width: 99%;

    thead tr th {
      padding: 8px;
      width: 100px;
      text-align: center;
      vertical-align: middle;
      background: ${theme.palette.grayscale[10]} !important;
      line-height: normal;
      font-size: 12px;
      z-index: 5;

      .fixed-col {
        background: ${theme.palette.grayscale[10]};
      }
    }

    tbody tr td {
      width: 100px;
      padding: 8px;
      text-align: center;
    }
  }
;

  .fixed-col {
    position: sticky;
    width: 100px;
    z-index: 100;
  }
`;
