import React from "react";
//Components
import { Col, Row } from "antd";
//Custom Components
import Legend from "../chart/legend";
import { ChartContent } from "../../../../index";

interface Props {
  data?: any,
  height?: any,
  options?: any,
  plugins?: any,
  reference?: any,
  identifier?: any,
  chartSize?: any,
  fullscreen?: any,
  chartConfig?: any,
  onChartClick?: any,
  Visualisation?: any,
  onLegendClick?: any,
  fixedDimensions?: any,
  legendConfigurations?: any,
  fullscreenHeightAdjusted?: any,
}

const CircularChart = (props: Props) => {

  const {
    Visualisation, onChartClick, options, chartConfig, data, height, fixedDimensions, legendConfigurations,
    onLegendClick, fullscreen, fullscreenHeightAdjusted, reference, plugins, identifier
  } = props;

  const {datasets, labels} = chartConfig;

  const {showLegend, hoverOnLegend, computeOthers, maxLegendItems, formatter} = legendConfigurations;

  const prepareLegendRecords = () => {
    let othersSum = 0;
    const lastIndex = data.length - 1;
    const legendRecords: any = {top: [], others: []}

    data.forEach((record: any, index: any) => {
      const datasetValue = datasets[0].data[index];
      const datasetLabel = labels[index];
      const datasetColor = datasets[0].backgroundColor[index];

      function populateLegendRecords(type: any, config: any = {}) {
        const records = legendRecords[type];

        let legendValue = datasetValue, legendLabel = datasetLabel;

        if (config.hasOwnProperty("label"))
          legendLabel = config.label;

        if (config.hasOwnProperty("value"))
          legendValue = config.value;

        records.push({
          value: legendValue,
          label: legendLabel,
          color: datasetColor,
        })
      }

      if (computeOthers) {
        /*Make computations including others*/
        if (index < maxLegendItems) {
          populateLegendRecords(['top']);
        } else {
          othersSum += datasetValue;
          if (index === lastIndex) {
            populateLegendRecords(['top'], {value: othersSum, label: "Others"});
            populateLegendRecords("others"); //add the last element to the popover
          } else {
            populateLegendRecords("others");
          }
        }
      } else {
        /*Make computations including all records*/
        populateLegendRecords(['top']);
      }
    })

    return legendRecords;
  }

  return (
    <Row gutter={8} align="middle" id={identifier}>
      <Col lg={(!fullscreen && showLegend) ? 12 : 24}>
        <ChartContent fixedDimensions={fixedDimensions} height={fullscreen ? fullscreenHeightAdjusted : height}>
          <Visualisation
            options={options}
            data={chartConfig}
            ref={reference}
            plugins={plugins}
            onClick={onChartClick}/>
        </ChartContent>
      </Col>
      {(!fullscreen && showLegend) && <Col lg={12}>
        <Legend
          height={height}
          formatter={formatter}
          onClick={onLegendClick}
          showHover={hoverOnLegend}
          records={prepareLegendRecords()}/>
      </Col>}
    </Row>
  )
};

export default CircularChart;