const COOKIE_TYPES = {
  "statistics": "statistics",
  "necessary": "necessary",
};

const COOKIE_INFORMATION = {
  [COOKIE_TYPES.necessary]: {
    disabled: true,
    description: "Necessary cookies help make a website usable by enabling basic functions like page with_navigation and access to secure areas of the website. The website cannot function properly without these cookies.",
    table: [
      {
        name: "access",
        provider: "securitization.cardoai.com",
        purpose: "Stores a unique user access token that authenticates the user to the platform.",
        expiry: "30 minutes or as long as the session is active",
        type: "HTTP"
      },
      {
        name: "refresh",
        provider: "securitization.cardoai.com",
        purpose: "Stores a unique user refresh token that keeps the user authenticated to the platform without signing out.",
        expiry: "30 minutes or as long as the session is active",
        type: "HTTP"
      },
      {
        name: "role",
        provider: "securitization.cardoai.com",
        purpose: "Stores the user profile role.",
        expiry: "As long as the session is active",
        type: "user"
      },
      {
        name: "last_deployment_date",
        provider: "securitization.cardoai.com",
        purpose: "Stores the latest version of the platform.",
        expiry: "As long as the session is active",
        type: "user"
      }
    ]
  },
  [COOKIE_TYPES.statistics]: {
    disabled: false,
    description: "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.",
    table: [
      {
        name: "_ga",
        provider: "securitization.cardoai.com",
        purpose: "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
        expiry: "2 yrs",
        type: "HTTP"
      },
      {
        name: "_gat",
        provider: "securitization.cardoai.com",
        purpose: "Used by Google Analytics to throttle request rate.",
        expiry: "1 day",
        type: "HTTP"
      },
      {
        name: "_gid",
        provider: "securitization.cardoai.com",
        purpose: "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
        expiry: "1 day",
        type: "HTTP"
      }
    ]
  }
}

export {
  COOKIE_TYPES,
  COOKIE_INFORMATION,
}