import React from 'react';
//Components
import { SmartChart } from "../../../../index";

interface PieChartProps {
  height: any,
  width: any,
  config: any,
  formatter: any
}

const Pie = (props: PieChartProps) => {
  const {height, width, config, formatter} = props;
  return (
    <div style={{width: width, height: height}}>
      <SmartChart
        data={config}
        height={height}
        fixedDimensions
        showLegend={false}
        format={formatter}
        valueEntity="value"
        labelEntity="label"
        displayLabels={false}
        types={['pie']}
        colors={config.map((record: any) => record.color)}
      />
    </div>
  );
};

export default Pie;
