import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const OptionsDocument = () => {
  return (
    <>
      <div>
        <p className="bold">By clicking on the Sorting Button, the user can sort documents ascending or descending
          order.</p>
      </div>
      <br/>
      <CustomImage
        alt="Document Management Options"
        src={assets.images.documentsModule.actions}
        fullWidth={false}
        isStyled={false}
        description="Sorting order can be based on each column and also combination of columns (e.g. sorting by descending Reference Date
            and by ascending Type). The User can also search within the table for the records as well as use the grouping feature
            that allows him to group the records by the Document Type, Name, Reference Date, Uploaded Date or Uploaded By. By default,
            the tables in Documents Management are grouped by the Document Type."
      />
    </>
  );
};

export default OptionsDocument;
