import React from 'react';
import {Button, Tabs} from "antd";
import RealEstateCasavoTable from "../../../../components/tables/real_estate_casavo_table";
import config from "../configurations";
import CashFlowTab from "../cashflow_tab";
import {useGlobalApi} from "../../../../utils/hooks";
import {useGetProposalDateLoan} from "../../apis";
import {FilterOutlined} from "@ant-design/icons";
const TabPane = Tabs.TabPane;

const CasavoThetaTab = ({filterOptions,cashflowTypes,toggleColFilterModal}:any) => {
  const {vehicles} = useGlobalApi();

  let vehicleIdTheta;
  const selectedVehicleTheta = vehicles.find((vehicle: any) => {
    return vehicle.identifier === "casavo_theta" ;
  });
  if (selectedVehicleTheta) {
    vehicleIdTheta = selectedVehicleTheta.id;
  }
  const tableConfigTheta= config.loanByLoanSectionTheta;
  const loanByLoanFiltering = useGetProposalDateLoan(vehicleIdTheta);
  const dateOptions = loanByLoanFiltering.data;
  return (

      <Tabs>
        <TabPane
          key="real_estate_asset"
          tab="Real Estate Asset">
          <RealEstateCasavoTable
            height={700}
            vehicleId={vehicleIdTheta}
            filterOptions={filterOptions}
            tableConfig={config.assetTheta}
            fileExtension='csv'
            usesPropertyIdSearch
            usesRealEstateSearch={false}
            usesFilterByAcquisition
          />
        </TabPane>
        <TabPane
          key="cash_flow"
          tab="Cash Flow">
          <CashFlowTab
            vehicleId={vehicleIdTheta}
            filterOptions={{...filterOptions, ...cashflowTypes}}
          />
        </TabPane>
        <TabPane key="loan_by_loan" tab="Loan-By-Loan">
          <RealEstateCasavoTable
            height={680}
            tableConfig={tableConfigTheta}
            vehicleId={vehicleIdTheta}
            usesTransactionSearch
            usesDownload={false}
            excelDownload
            usesFilters
            filterOptions={filterOptions}
            dateFilter={dateOptions}
            usesFilterByStatus={true}
            usesPropertyIdSearch
            usesRealEstateSearch={false}
            usesFilterByAcquisition
            extra={
              <Button onClick={toggleColFilterModal} icon={<FilterOutlined />}>
                Columns
              </Button>
            }
          />
        </TabPane>
      </Tabs>

  );
};

export default CasavoThetaTab;