import React, { useState } from 'react';
//Components
import { Button, Checkbox, Col, List, Modal, Row, Skeleton } from "antd";
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { theme } from "../../../../../../assets/theme/colors";
import { formatDatetime, generateUrl, stringify, useQuery } from "../../../../../../helpers";
//Styles
const Styles = {
  button: {
    minWidth: 120
  },
  mediumText: {
    color: theme.palette.border[9],
  },
  bodyStyle: {
    paddingLeft: 8,
    paddingBottom: 8,
    paddingRight: 8
  }
};

interface Props {
  url: string,
  onLoad: (param?: any) => any,
  toggleModal?: any,
  initialSelected?: any
}

const LoadContent = (props: Props) => {
  const {url, onLoad, toggleModal, initialSelected} = props;

  const [selected, setSelected] = useState<any>(initialSelected);

  const list: any = useQuery({url: url});

  if (list.loading)
    return <Skeleton active/>;

  if (!list.data || !list.data.length)
    return <SimpleEmpty/>

  const onSelect = (record: any) => () => {
    setSelected(record);
  }

  const handleConfirm = () => {
    onLoad(selected);
    toggleModal();
  };

  const handleCancel = () => {
    toggleModal();
  };

  return (
    <>
      <List
        size="small"
        bordered={false}
        dataSource={list.data}
        style={{minHeight: 150}}
        renderItem={(item: any) => {
          const itemId = item.id;
          const checked = selected && selected.id === itemId;
          const style: any = {marginLeft: 8};

          if (checked)
            style.color = theme.colors.blue;

          return (
            <List.Item key={stringify(itemId)} onClick={onSelect(item)}>
              <Row className="fullWidth pointerCursor" justify="space-between">
                <Col>
                  <Checkbox checked={checked}>
                    <span style={style}> {item.name} </span>
                  </Checkbox>
                </Col>
                <Col style={Styles.mediumText}>
                  {formatDatetime(item.last_updated)}
                </Col>
              </Row>
            </List.Item>
          )
        }}
      />
      <Row gutter={8} justify="end" className="mt8">
        <Col>
          <Button onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={handleConfirm}>
            Ok
          </Button>
        </Col>
      </Row>
    </>
  )
}

const Load = (props: any) => {
  const {vehicleId, initialSelected, onLoad} = props;

  const [visible, setVisible] = useState(false);

  const url = generateUrl('/lab/scenarios', {vehicle_id: vehicleId})

  const toggleModal = () => {
    setVisible(v => !v);
  };

  return (
    <>
      <Button style={Styles.button} type="primary" onClick={toggleModal}>
        Load
      </Button>
      {visible &&
        <Modal
          centered
          footer={null}
          closable={false}
          visible={visible}
          onCancel={toggleModal}
          bodyStyle={Styles.bodyStyle}>
          <LoadContent
            url={url}
            onLoad={onLoad}
            toggleModal={toggleModal}
            initialSelected={initialSelected}/>
        </Modal>}
    </>
  );
}

export default Load;