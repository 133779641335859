import React, { Component } from 'react';
//External Libs
import clone from "clone";
import produce from "immer";
//Custom  Components
import { Button, Col, Input, Modal, Row, Select, Skeleton, Tooltip } from 'antd';
//Filter Components
import { FiltersList, FilterTags } from './components';
import { SPVFilters, VDRFilters } from "../../containers/loans/components/header_filters"
//Icons
import { FilterOutlined } from '@ant-design/icons';
//Custom Helpers
import { callApi, debounce, generateUrl, isEmpty, isVDRInvestor } from "../../helpers";
import {
  filterInvalidFields,
  isFilterActive,
  prepareFilters,
  prepareSearchOptions,
  produceFiltersState,
  removeHiddenFields
} from './config';
import { actionKey } from "../../constants";
import { useGlobalApi } from "../../utils/hooks";
import ExtraFilters from "./components/extra_filters";

//Styles
const styles = {
  search: {
    minWidth: 350
  },
  modalBody: {
    paddingBottom: 16
  }
}

interface State {
  filters?: any,
  geographies?: any,
  showFilters: boolean,
  loanType?: any[],
  loadingGeographies?: boolean,
  searchOptions?: any,
  ratings?: any,
}

type FiltersProps = typeof Filters.defaultProps & {
  displayVehicleFilter?: boolean,
  displayPoolDateFilter?: boolean,
  useSearch?: boolean,
  fetchOnMount?: boolean,
  showFilters?: boolean,
  hiddenFields?: [],
  defaultFilters?: any,
  entity?: any,
  fetchData?: any,
  vehicleId?: any,
  vehicle_id?: any,
  onChange?: any,
  onSingleSelectChangeCallback?: any,
  onFilterToggleCallback?: any,
  vehicles?: any,
  className?: string,
  sectors?: any,
  loanRating?: any,
  showFilterTags?: any,
  filterEntries?: any,
  searchConfig?: any,
  canPerformAction?: any,
  lenders?: any,
  filterOptions?: any
};

class Filters extends Component<FiltersProps, State> {
  modified: any;
  searchConfig: any;
  filterEntries: any;

  static defaultProps = {
    displayVehicleFilter: true,
    displayPoolDateFilter: true,
    useSearch: true,
    fetchOnMount: false,
    showFilters: true,
    hiddenFields: []
  }

  constructor(props: FiltersProps) {
    super(props);
    this.state = {
      loanType: [],
      loadingGeographies: false,
      geographies: {
        all: [],
        cities: [],
        regions: [],
        countries: []
      },
      ...this.constructState(props.defaultFilters)
    };
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (prevProps.defaultFilters !== this.props.defaultFilters)
      this.setState({...this.constructState(this.props.defaultFilters)})

    if (prevState.filters.selectedVehicles !== this.state.filters.selectedVehicles)
      this.getLoanRatings();
  }

  constructState = (initialFilters: any) => {
    const {entity} = this.props;

    const {filtersInitialState, filterEntries, searchConfig} = produceFiltersState(entity);

    const totalFilters = {
      ...filtersInitialState,
      ...initialFilters
    };


    this.modified = false;
    this.filterEntries = filterEntries;
    this.searchConfig = searchConfig;

    return {
      showFilters: false,
      filters: totalFilters,
      initialFilters: clone(filtersInitialState),
      searchOptions: prepareSearchOptions(entity, totalFilters),
    }
  }

  componentDidMount() {
    const {fetchOnMount} = this.props;
    /*Required Actions */
    this.requestGeographies();
    this.getLoanRatings();

    if (fetchOnMount)
      this.processChange();
  }

  requestGeographies = () => {
    const {vehicleId} = this.props;
    const params: any = {};

    if (vehicleId)
      params.vehicle_id = vehicleId;

    this.setState({loadingGeographies: true});

    const url = generateUrl(`/main/sme_loan/loan_regions`, params);

    callApi({
      url: url,
      onSuccess: (countries: any) => {
        if (!countries)
          return null;

        const geographies: any = {
          all: countries, countries: [],
          regions: [], cities: []
        };

        for (let country of countries) {
          geographies.countries.push({
            id: country.id,
            name: country.name
          });
          for (let region of country.regions) {
            geographies.regions.push(region.name);
            for (let city of region.cities) {
              geographies.cities.push(city.name);
            }
          }
        }
        this.setState({geographies});
      },
      onFinish: () => {
        this.setState({loadingGeographies: false});
      },
    })
  };

  getFilters = () => {
    return this.state.filters;
  };

  getFiltersQueryString = (externalFilters: any) => {
    const {filters} = this.state;
    const {hiddenFields, entity} = this.props;
    const formattedFilters = removeHiddenFields(filters, hiddenFields);


    return prepareFilters(externalFilters || formattedFilters, entity);

  };

  resetFields = () => {
    this.setState(produce(draft => {
      draft.filters = draft.initialFilters;
    }), () => {
      this.setModified(false)
    })
  };

  getFieldValue = (attr: any) => {
    return this.state.filters[attr];
  };

  processChange = (externalFilters?: any) => {
    const {fetchData, onChange} = this.props;
    const filters = externalFilters || this.state.filters;
    const queryString = this.getFiltersQueryString(filters);

    if (fetchData)
      fetchData(queryString);
     if (onChange)
      onChange(queryString);
  };

  triggerSearchChange = debounce(200,
    () => {
      this.processChange();
    });

  setModified = (attr = true) => {
    this.modified = attr;
  };

  setFilters = (newFilters: any) => {
    this.setState({
      filters: newFilters
    })
  };

  onCountryChange = (value: any) => {
    const geographies = this.state.geographies;

    this.setState(produce((draft: any) => {
      let countries = draft.filters.country.value;

      if (countries.includes(value))
        countries.splice(countries.indexOf(value), 1);
      else
        countries.push(value);

      if (isEmpty(countries)) {
        draft.filters.region.value = [];
        draft.filters.city.value = [];
      } else {

        // @ts-ignore
        function checkCity(customCity: any) {
          for (let countryId of countries) {
            let countryRegions = [];
            const countryRecord = geographies.all.find((country: any) => country.id === countryId);

            if (countryRecord)
              countryRegions = countryRecord.regions;

            for (let region of countryRegions) {
              const cities = region.cities;
              const exist = cities.find((city: any) => city.name === customCity);
              if (exist)
                return true;
            }
          }
          return false;
        }

        // @ts-ignore
        function checkRegion(customRegion) {
          for (let countryId of countries) {
            let countryRegions = [];

            const countryRecord = geographies.all.find((country: any) => country.id === countryId);

            if (countryRecord)
              countryRegions = countryRecord.regions;

            const exist = countryRegions.find((region: any) => region.name === customRegion);

            if (exist)
              return true;
          }
          return false;
        }

        /*Clear Cities that does not belong to given country/region*/
        draft.filters.city.value = draft.filters.city.value.filter(checkCity);
        /*Clear Regions that does not belong to given countries*/
        draft.filters.region.value = draft.filters.region.value.filter(checkRegion);
      }
    }));
    this.setModified(true)
  };

  onRegionChange = (value: any) => {
    const geographies = this.state.geographies;

    this.setState(produce(draft => {
      let countries = draft.filters.country.value;
      let regions = draft.filters.region.value;

      if (regions.includes(value))
        regions.splice(regions.indexOf(value), 1);
      else
        regions.push(value);

      function checkCity(customCity: any) {
        if (!isEmpty(countries)) {
          for (let countryId of countries) {
            let countryRegions = [];
            const countryRecord = geographies.all.find((country: any) => country.id === countryId);
            if (countryRecord)
              countryRegions = countryRecord.regions;
            for (let region of countryRegions) {
              if (regions.includes(region.name)) {
                const cities = region.cities;
                const exist = cities.find((city: any) => city.name === customCity);
                if (exist) return true;
              }
            }
          }
          return false;
        } else if (!isEmpty(regions)) {
          for (let region of regions) {
            for (let country of geographies.all) {
              const customRegions = country.regions;
              const regionDetails = customRegions.find((record: any) => record.name === region);
              if (regionDetails) {
                const cities = regionDetails.cities;
                const exist = cities.find((record: any) => record.name === customCity);
                if (exist) return true;
              }
            }
          }
          return false;
        }
      }

      /*Clear Cities that does not belong to given country/region*/
      draft.filters.city.value = draft.filters.city.value.filter(checkCity);
    }));
    this.setModified(true)
  };

  onMultipleSelectChange = (attr: any) => (entityId: any) => () => {
    switch (attr) {
      case "country":
        return this.onCountryChange(entityId);
      case "region":
        return this.onRegionChange(entityId);
      default:
        return this.setState(produce(draft => {
          const existingFilter = draft.filters[attr];
          let selectedEntities = existingFilter.value;
          if (selectedEntities.includes(entityId))
            selectedEntities.splice(selectedEntities.indexOf(entityId), 1);
          else
            selectedEntities.push(entityId);
        }), this.setModified)
    }
  };

  onSingleSelectChange = (attr: any, checkCallback = false) => (entityId: any) => {
    const {onSingleSelectChangeCallback} = this.props;
    this.setState(produce(draft => {
      const existingFilter = draft.filters[attr];
      existingFilter.value = entityId;
      if (checkCallback && onSingleSelectChangeCallback)
        onSingleSelectChangeCallback({
          attr,
          value: entityId,
          values: draft.filters
        });
    }), this.setModified)
  };

  onOperatorValuesChange = (entity: any) => (data: any) => {
    this.setState(produce(draft => {
      const filters = draft.filters;
      filters[entity] = data;
    }), this.setModified);
  };

  onValueChange = (entity: any) => (value: any) => {
    this.setState(produce(draft => {
      draft.filters[entity].value = value;
    }), this.setModified)
  };

  onSearchOptionsChange = (entity: any) => (value: any) => {
    this.setState(produce(draft => {
      draft.searchOptions[entity].push(value);
    }))
  };

  handleFilterToggle = (event: any, processFetching = false, checkCallback = false) => {
    const {onFilterToggleCallback} = this.props;

    let entity: any;

    if (typeof event === "object")
      entity = event.target.value;
    else
      entity = event;


    this.setState(produce((draft: any) => {
      const filterConfig = draft.filters[entity];
      if (filterConfig.selected)
        draft.filters[entity] = draft.initialFilters[entity];
      else
        filterConfig.selected = !filterConfig.selected;

      if (checkCallback && onFilterToggleCallback) {
        onFilterToggleCallback({
          attr: entity,
          value: filterConfig.selected,
          values: draft.filters
        })
      }
    }), () => {
      if (processFetching)
        this.processChange();
      else
        this.setModified(true);
    });
  };

  resetAllFilters = () => {
    this.setState(produce(draft => {
      draft.filters = draft.initialFilters;
    }), this.processChange);
  };

  toggleProperty = (attr?: any, callback?: any) => () => {
    // @ts-ignore
    this.setState((prevState: any) => ({
      [attr]: !prevState[attr]
    }), () => {
      if (callback)
        callback();
    });
  };

  handleSearchChange = (event: any) => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft.filters.search = value;
      }), this.triggerSearchChange)

  };

  handleModalClose = () => {
    this.toggleProperty("showFilters")();
  };

  handleAfterClose = () => {
    const {entity} = this.props;
    /*Make State Computation*/
    const finalState = filterInvalidFields(this.state.filters, entity);
    /*Process with side effects */
    if (this.modified) {
      this.setModified(false);
      this.setFilters(finalState);
      this.processChange(finalState);
    }
  }

  getFormattedRegions = () => {
    let regions = [];
    const geographies = this.state.geographies;
    const countries = this.state.filters.country.value;

    if (!countries.length) {
      regions = geographies.regions;
    } else {
      countries.forEach((countryId: any) => {
        const countryDetails = geographies.all.find((country: any) => country.id === countryId);
        if (countryDetails)
          regions.push(...countryDetails.regions.map((region: any) => region.name));
      });
    }
    return regions;
  };

  getFormattedCities = () => {
    let cities = [];
    const geographies = this.state.geographies;
    const countries = this.state.filters.country.value;
    const regions = this.state.filters.region.value;

    if (!regions.length) {
      cities = geographies.cities;
    } else {
      countries.forEach((countryId: any) => {
        const countryDetails = geographies.all.find((country: any) => country.id === countryId);
        if (countryDetails) {
          countryDetails.regions.forEach((region: any) => {
            if (regions.includes(region.name))
              cities.push(...region.cities.map((city: any) => city.name));
          })
        }
      })
    }
    return cities
  };

  createVehicleOptions = () => {
    return this.props.vehicles.map((vehicle: any) => {

      return (
        <Select.Option title="" key={vehicle.id} value={vehicle.spv_name}>
          {vehicle.name}
        </Select.Option>
      )
    })
  };

  //Refactor these lines to reuse a single function for the same types of filters
  getVehiclesValue = () => {
    const {filters} = this.state;
    const stateVehicles = filters.selectedVehicles;

    if (!stateVehicles.selected)
      return undefined;

    return stateVehicles.value.map((s: any) => String(s));
  };

  getLendersValue = () => {
    const {filters} = this.state;
    const stateLenders = filters.selectedLenders;

    if (!stateLenders.selected)
      return undefined;

    return stateLenders.value.map((s: any) => String(s));
  };

  handleVehiclesChange = (event: any) => {
    const disableSelected = !event || !event.length;
    this.setState(produce(draft => {
      draft.filters.selectedVehicles.selected = !disableSelected;
      draft.filters.selectedVehicles.value = event.map((rec: any) => Number(rec));
      this.setModified(true)
      this.processChange(draft.filters);
    }));
  };

  handleLendersChange = (event: any) => {
    const {vehicles} = this.props;
    const disableSelected = !event || !event.length;
    this.setState(produce(draft => {
      draft.filters.selectedLenders.selected = !disableSelected;
      draft.filters.selectedLenders.value = event.map((rec: any) => rec);
      draft.filters.selectedVehicles.selected = true;
      draft.filters.selectedVehicles.value = (vehicles && vehicles.length) ? vehicles[0].id : [];
      this.setModified(true)
      this.processChange(draft.filters);
    }));
  };

  getPoolAdditionDate = () => {
    return this.state.filters.poolAdditionDate
  };

  getInvestmentDate = () => {
    return this.state.filters.investmentDate
  }

  handlePoolAdditionDateChange = (value: any) => {
    this.setState(produce(draft => {
      draft.filters.poolAdditionDate = value;
      this.setModified(true)
      this.processChange(draft.filters);
    }));
  }

  handleInvestmentDateChange = (value: any) => {
    this.setState(produce(draft => {
      draft.filters.investmentDate = value;
      this.setModified(true)
      this.processChange(draft.filters);
    }));
  }

  generateVehicleIdQuery = () => {
    const {filters} = this.state;
    const selectedVehicles = filters.hasOwnProperty("selectedVehicles") ? filters.selectedVehicles.value : []
    let queryParams = '';
    if (selectedVehicles.length)
      selectedVehicles.forEach((id: any) => queryParams += `&vehicle_id=${id}`);
    return queryParams;
  }

  getLoanRatings = () => {
    const queryParams = this.generateVehicleIdQuery();
    const ratingUrl = queryParams ? `/common/rating/?${queryParams}` : `common/rating/?`;
    callApi({
      url: ratingUrl,
      onSuccess: ((response: any) => {
        this.setState({
          ratings: response
        });
      })
    })
  }

  isOneVehicleSelected = () => {
    const {filters} = this.state;
    if (filters.hasOwnProperty("selectedVehicles"))
      return filters.selectedVehicles.value.length === 1;
    return false;
  }

  render() {
    const {showFilters, filters, geographies, loadingGeographies, searchOptions, ratings} = this.state;

    const {
      entity,
      className,
      sectors,
      vehicles,
      useSearch,
      showFilterTags,
      hiddenFields,
      lenders,
      filterOptions,
    } = this.props;
    const {countries, regions, cities} = geographies;

    const activeFiltering = isFilterActive(filters);
    const isLoansContent = entity === "loans";

    let filterContent = <Skeleton active/>;

    const searchText = isVDRInvestor() ? "Search Loan ID" : "Search Loan ID / Company Name"

    if (!loadingGeographies)
      filterContent = (
        <FiltersList
          sectors={sectors}
          vehicles={vehicles}
          countries={countries}
          lenders={lenders}
          ratings={ratings}
          filtersState={filters}
          hiddenFields={hiddenFields}
          filterOptions={filterOptions}
          searchOptions={searchOptions}
          onValueChange={this.onValueChange}
          filterEntries={this.filterEntries}
          cities={this.getFormattedCities()}
          regions={this.getFormattedRegions()}
          toggleFilter={this.handleFilterToggle}
          oneVehicleSelected={this.isOneVehicleSelected()}
          onSingleSelectChange={this.onSingleSelectChange}
          onSearchOptionsChange={this.onSearchOptionsChange}
          onMultipleSelectChange={this.onMultipleSelectChange}
          onOperatorValuesChange={this.onOperatorValuesChange}/>
      );

    return (
      <Row justify="space-between" align="bottom" gutter={8}>
        {isLoansContent && <ExtraFilters
          lenders={lenders}
          getLendersValue={this.getLendersValue}
          getVehiclesValue={this.getVehiclesValue}
          getInvestmentDate={this.getInvestmentDate}
          handleLendersChange={this.handleLendersChange}
          getPoolAdditionDate={this.getPoolAdditionDate}
          handleVehiclesChange={this.handleVehiclesChange}
          createVehicleOptions={this.createVehicleOptions}
          handlePoolAdditionDateChange={this.handlePoolAdditionDateChange}
          handleInvestmentDateChange={this.handleInvestmentDateChange}
        />}
        <Col>
          <Row align="middle" gutter={8} className={className}>
            {showFilterTags && <Col>
              <FilterTags
                entity={entity}
                filters={filters}
                hiddenFields={hiddenFields}
                filterEntries={this.filterEntries}
                handleRemove={this.handleFilterToggle}
                handleRemoveAll={this.resetAllFilters}
                options={{
                  countries,
                  regions,
                  cities,
                  sectors,
                  vehicles,
                  ratings
                }}
              />
            </Col>}
            {(useSearch && isLoansContent) && <Col>
              <Input
                autoFocus
                allowClear
                style={styles.search}
                value={filters.search}
                placeholder={searchText}
                onChange={this.handleSearchChange}/>
            </Col>}
            <Col>
              <Tooltip title="Open Filtering" placement="top">
                <Button shape='circle' icon={<FilterOutlined/>}
                        type={activeFiltering ? 'primary' : 'default'}
                        onClick={this.toggleProperty("showFilters")}/>
              </Tooltip>
              <Modal
                width={680}
                footer={null}
                title="Filter by"
                className="smallModal"
                visible={showFilters}
                onOk={this.processChange}
                bodyStyle={styles.modalBody}
                onCancel={this.handleModalClose}
                afterClose={this.handleAfterClose}>
                {filterContent}
                <Row justify="end" className="mt16">
                  <Col>
                    <Button
                      onClick={this.handleModalClose}
                      type={this.modified ? 'primary' : 'default'}>
                      OK
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
};

const FiltersFunc = (props: any) => {
  const {sectors, vehicles, loanRatings} = useGlobalApi();
  return (
    <Filters sectors={sectors} vehicles={vehicles} loanRating={loanRatings} {...props}/>
  )
}

export default FiltersFunc;