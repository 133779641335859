//Constants
import { theme } from "../../../../../assets/theme/colors";

export default {
  padAngle: 0.7,
  borderWidth: 1,
  cornerRadius: 0,
  radialLabelsSkipAngle: 10,
  sliceLabelsSkipAngle: 10,
  radialLabelsLinkColor: {from: 'color'},
  sliceLabelsTextColor: theme.colors.white,
}
