import React from "react";
//Configuration
import { columns } from "./config";
//Components
import { Skeleton, Spin } from "antd";
//Utils
import { useMediator } from "@cardoai/utils";
//Helpers
import { generateUrl, useQuery } from "../../../../../../../../helpers";
//Custom Components
import { SmartTable } from "../../../../../../../../components";
import { withData } from "../../../../../../../../utils";
import withLimitDownload from "../../components/hoc/with_limit_download";
import moment from "moment";

interface Props {
  vehicleId?: any;
  id?: any;
  formatter?: any;
  fulfillment_percent?:any,
  currencyLimits?:any
}

const WaiverRequestHistory = (props: Props) => {
  const { vehicleId,id,formatter,currencyLimits,fulfillment_percent } = props;
  const [sortingFields, setSortingField] = React.useState<string>("");

  const onSort = (fields: string[]) => {
    setSortingField(fields.join(","));
  };

  const dataWaiverRequestHistory = useQuery({
    deps: [vehicleId, id, sortingFields],
    url: () =>
      `main/limit_waiver/?vehicle_id=${vehicleId}&limit_id=${id}&order=${sortingFields}`,
  });

  useMediator("WaiverRequestHistory", {
    refreshDataWaiverHistory: dataWaiverRequestHistory.fetch,
  });

  if (!dataWaiverRequestHistory.data) return <Skeleton />;

  const finalData = dataWaiverRequestHistory.data.results;

  return (
    <Spin spinning={dataWaiverRequestHistory.loading}>
      <SmartTable
        height={500}
        columns={columns(vehicleId, id, formatter,fulfillment_percent,currencyLimits)}
        externalData={finalData}
        onClientSort={onSort}
      />
    </Spin>
  );
};

export default withData({
  urlFormatter: (config: any) => {
    const {
      props: { vehicleId, id },
    } = config;
    return generateUrl(`/main/limit_waiver`, {
      vehicle_id: vehicleId,
      limit_id: id,
    });
  },
})(
  withLimitDownload(WaiverRequestHistory)({
    title:`waiver_history_${moment().format("MMM DD, YYYY")}`,
    prepareData: (data: any) => {
      return data.results.map((waiverHistory: any) => {
        return {
          WaiverType: waiverHistory.waiver_type,
          RequestedDate: waiverHistory.date_requested,
          RequestedBy: waiverHistory.requested_by,
          Status: waiverHistory.status,
          ReviewedDate: waiverHistory.date_decided,
          ReviewedBy: waiverHistory.decided_by,
          StartDate: waiverHistory.start_date,
          EndDate: waiverHistory.end_date,
        };
      });
    },
  })
);
