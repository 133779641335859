import React from 'react';
//Components
import { Button, Col, Popconfirm, Row, Tag, Tooltip } from "antd";
//Custom Components
import { AddTopicModal } from "./add_topic_modal";
//Icons
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
//Helpers
import { callApi } from "../../../../helpers";
import { useAuthorization } from "../../../../utils/hooks";
import { actionKey } from "../../../../constants";

export const TopicHeader = (props: any) => {
  const {topic, currentUserId, vehicleId, fetchTopics} = props;

  const {canPerformAction}: any = useAuthorization();
  const canDeleteTopic = canPerformAction(actionKey.deleteTopic, vehicleId);
  const canToggleTopicPublished = canPerformAction(actionKey.toggleTopicPublished, vehicleId);

  const {user, id, name, has_children_messages: hasMessages, published} = topic;

  const [visibleEdit, setVisibleEdit] = React.useState<boolean>(false);

  const displayEditTopic = currentUserId == user
  const displayDeleteTopic = currentUserId == user && canDeleteTopic

  const toggleVisibility = () => {
    setVisibleEdit(prevState => !prevState)
  }

  const onEdit = (newTopicName: string) => {
    callApi({
      url: `common/topic/${id}/?pk=${vehicleId}&model=vehicle`,
      method: "put",
      body: {
        name: newTopicName
      },
      onSuccess: () => {
        toggleVisibility();
        fetchTopics();
      }
    })
  }

  const onDelete = () => {
    callApi({
      url: `common/topic/${id}/?pk=${vehicleId}&model=vehicle`,
      method: "delete",
      onSuccess: () => {
        toggleVisibility();
        fetchTopics();
      }
    })
  }

  const onTogglePublished = () => {
    callApi({
      url: `common/topic/${id}/toggle_published/?pk=${vehicleId}&model=vehicle`,
      method: "patch",
      onSuccess: () => {
        fetchTopics();
      }
    })
  }

  return (
    <>
      <Row justify="start" align="middle" gutter={8}>
        <Col>
          <h4>You are viewing content for <Tag>{name}</Tag></h4>
        </Col>
        {canToggleTopicPublished && <Col>
          <Popconfirm
            title={`Are you sure to ${published ? "unpublish": "publish"} this topic?`}
            onConfirm={onTogglePublished}
            okText="Yes"
            cancelText="No">
            <Button size="small" icon={published ? <CloseOutlined/> : <CheckOutlined/>}>
              {published ? "Unpublish" : "Publish"}
            </Button>
          </Popconfirm>
        </Col>}
        {displayEditTopic && <Col>
          <Tooltip title="Edit Topic Name">
            <Button onClick={toggleVisibility} size="small" icon={<EditOutlined/>}/>
          </Tooltip>
        </Col>}
        {displayDeleteTopic && <Col>
          <Popconfirm
            title="Are you sure to delete this topic?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No">
            <Button size="small" icon={<DeleteOutlined/>} danger/>
          </Popconfirm>
        </Col>}
      </Row>
      <AddTopicModal currentTopicName={name} editMode onChange={onEdit} visible={visibleEdit}
                     onToggle={toggleVisibility}/>
    </>
  );
};

