import React from "react";
import { Col, Row } from "antd";
import HelpIcon from "../../../../../../../icons/help";

const Description = (props: any) => {
  return (
    <Row gutter={8}
         style={{
           padding: 8,
           borderRadius: 12,
           backgroundColor: "#F2F2F2"
         }}>
      <Col>
        <HelpIcon/>
      </Col>
      <Col style={{color: "#736974"}}>
        You are now available to waiver the limits by clicking on each
        icon. {`${props.count ? ("( " + props.count + " selected" + " )") : ""}`}
      </Col>
    </Row>
  )
};

export default Description;