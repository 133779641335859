import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const LoanPurchaseDetails = () => {
  return (
    <CustomImage alt="Default Classification (DoD)" src={assets.images.analyticsModule.dod} description=""/>
  );
};
export default LoanPurchaseDetails;
