import React, { useState } from 'react';
//Components
import Legend from "../../../../../../../components/charts/static_legend";
import { Graph } from "../../../../../../../components";
//Other Libs
import { Button, Divider, Modal, Skeleton } from "antd";
//Icons
import { DotChartOutlined } from "@ant-design/icons";
//Helpers
import { formatters, getNested } from "../../../../../../../helpers";
import datasetConfig from "./constants";
import { theme } from "../../../../../../../assets/theme/colors";

const invalidKeys = ['name', 'type', 'date', 'factor', 'principal_outstanding'];

interface Props {
  height: number,
  data?: [],
  vehicle?: any,
  currency?: any
}

function validRecord(record: any) {
  /**
   * This function is used to iterate through the keys in the record and to determine if the record has a valid
   * dataset to be shown as a type of payment. If it returns false, the record has no payment, and it will
   * show "No Payment" in the chart
   * @params: record
   */

  const keys = Object.keys(record);

  for (let key of keys) {
    if (!invalidKeys.includes(key)) {
      const value = record[key];
      if (value !== null) {
        return true;
      }
    }
  }
  return false;
}


const ChartContent = (props: Props) => {
  const {height, currency, data} = props;
  const [visible, setVisible] = useState(false);

  const chartConfig = datasetConfig;

  if (!data)
    return <Skeleton/>;

  const configurations: any = {};
  const records: any = [];

  function getEntity(key: any, type: any) {
    const configuration = chartConfig.find((dataset: any) => dataset.key === key);
    return getNested(configuration, type);
  }

  function getLabel(record: any) {
    let tip = [];
    tip.push(`Balance: ${formatters.currency({value: record.principal_outstanding, currency})} `);
    return tip;
  }

  function createRecord(key: any, record: any) {

    /**
     * This function creates the datasets by assigning the color, date, and the value of the dataset.
     * In case of "expect_interest" type of payment, the dataset will have distinct properties.
     * @params: key -> type of payment extracted from BE data
     * @params: record -> each dataset with its values
     */

    const color = getEntity(key, "color");

    const recordsDetails: any = {
      key: key,
      date: record.date,
      borderColor: color,
      backgroundColor: color,
      value: record.principal_outstanding
    };

    if (key === "expected_interest") {
      recordsDetails.pointRadius = 8;
      recordsDetails.backgroundColor = theme.palette.grayscale[10];
    }
    records.push(recordsDetails);
  }

  const updatedData = data.map((record: any) => {
    let showNoPayment = !validRecord(record);
    const newRecord = {...record}
    if (showNoPayment)
      newRecord.no_payment = 0;
    return newRecord;
  })


  for (let record of updatedData) {
    const valid = validRecord(record);

    if (valid) {

      /*If there is a dataset available in the record, check first for "principal_and_interest"*/
      if (record.principal_and_interest)
        createRecord("principal_and_interest", record)
      else if (record.interest) {
        createRecord("interest", record)
      } else {
        for (let key in record) {
          if (record.hasOwnProperty(key)) {
            if (!invalidKeys.includes(key)) {
              const value = record[key];
              if (value !== null)
                createRecord(key, record);
            }
          }
        }
      }
    }
  }


  configurations.labels = records.map((record: any) => formatters.shortedDate(record.date));

  configurations.dataSets = [{
    label: "Balance",
    showLine: false,
    pointRadius: 6,
    data: records.map((record: any) => record.value),
    pointBorderColor: records.map((record: any) => record.borderColor),
    backgroundColor: records.map((record: any) => record.backgroundColor),
  }];


  const formatTooltip = (config: any) => {
    const recordIndex = config.tooltipItem.dataIndex;
    const record: any = data[recordIndex];

    const tip = getLabel(record);

    /*Similarly to the creation of datasets, the tooltips are created based on the dataset*/
    if (record.principal_and_interest)
      tip.push(`${getEntity("principal_and_interest", 'label')}: ${formatters.currency({
        value: record.principal_and_interest,
        currency
      })}`);
    else if (record.interest) {
      tip.push(`${getEntity("interest", 'label')}: ${formatters.currency({
        value: record.interest,
        currency
      })}`);
    } else {
      for (let key in record) {
        if (record.hasOwnProperty(key)) {
          if (!invalidKeys.includes(key)) {
            const value = record[key];
            if (value) {
              const label = getEntity(key, "label");
              tip.push(`${label}: ${formatters.currency({value, currency})}`);
            }
          }
        }
      }
    }
    return tip;
  };

  const onToggle = () => {
    setVisible(previous => !previous);
  };


  /*Display a modal if the height in which the chart will show is less than 150*/
  const modalView = height <= 150;

  return (
    <>
      <Modal
        centered
        width={850}
        onOk={onToggle}
        closable={true}
        visible={visible}
        footer={null}
        onCancel={onToggle}
        className="smallModal">
        <Divider>
          <span className="fw400">Payment Waterfall</span>
        </Divider>
        <br/>
        <Graph
          beginAtZero
          types={['line']}
          displayLegend={false}
          formatAxisLabels={false}
          customTip={formatTooltip}
          yAxisLabel="% on Original Balance"
          format={formatters.currencyNoPrecision}
          {...configurations}/>
        <Legend
          staticDisplay
          disableSelection
          config={datasetConfig}/>
      </Modal>
      {modalView && <Button type="link" onClick={onToggle} icon={<DotChartOutlined/>}>
        Click here to view chart
      </Button>}
      {!modalView && <Graph
        beginAtZero
        height={height}
        types={['line']}
        displayXLines={false}
        displayLegend={false}
        formatAxisLabels={false}
        customTip={formatTooltip}
        format={formatters.currencyShortedNoPrecision}
        {...configurations}
        layoutPadding={{
          top: 35,
          bottom: 0,
          left: 0,
          right: 0
        }}
      />}
    </>
  )
};


ChartContent.defaultProps = {
  modalChartHeight: 240
};

export default ChartContent;