//other libs
import styled from "styled-components";

export default styled.div`
  text-overflow: ellipsis !important;

  .deepColor {
    background-color: #00000040;
    border-radius: 5px;
    width: 28px;
  }

  .transparent {
    &, &:focus, &:hover {
      color: inherit;
      background: transparent;
      box-shadow: none;
    }
  }

  .round {
    border-radius: 30px;
  }

  .labelText {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .alignedCenter {
    font-size: 10.5px;
    white-space: pre;
    text-align: center;
    font-weight: 600;
  }

  .disabled {
    opacity: 0.65;
    pointer-events: none;
  }
`;



