//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../../../../assets/theme/colors";

export default styled.table`
  width: 100%;
  font-size: 12px;

  thead tr th {
    text-align: center;
    font-weight: 500;
    border-bottom: 1px solid ${theme.palette.grayscale[2]};
    padding: 0 !important;
  }

  tbody tr td {
    text-align: center;
    font-weight: 400;
    border-bottom: 1px solid ${theme.palette.grayscale[2]};
    padding: 0 !important;
  }
`;
