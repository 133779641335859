import React from 'react';
//Components
import { Collapse, Skeleton, Space } from "antd";
//Custom Components
import DelaysChart from "../common/charts/delays";
import MainStatistics from "../common/main_statistics";
import {
  DefaultPrepaymentDistribution,
  Diversification,
  ExpectedLossChart,
  IrrGraph,
  SelectLender,
  SummaryStats
} from "./components";
//Helpers
import { useGlobalApi, useRedirection } from "../../utils/hooks";
import { generateUrl, useQuery } from "../../helpers";
import { CashFlowCollateral } from "../spv/components/management/common/cashflows";
import { useCashFlows } from "../spv";
import { collateralCashFlowColumns } from "../spv/components/management/common/cashflows/config";

const {Panel} = Collapse;

const headerStatsToExclude = [
  'average_delay_vs_original_maturity',
  'delinquency_ratio',
  'gross_default_ratio',
  'conditional_default_ratio',
  'prepayment_ratio',
  'ltv',
]

const VirtualDataRoom = () => {
  const {vehicles, sectors, countries} = useGlobalApi();
  const {rules}: any = useRedirection();
  const cashFlows: any = useCashFlows();

  const [selectedLender, setSelectedLender] = React.useState('');
  const [activeKey, setActiveKey] = React.useState<any>('summary-statistics');


  React.useEffect(() => {
    if (rules && rules.panel === "Stratification")
      setActiveKey("stratification")
  }, [rules])

  let vehicleId: any, currency: any;


  if (vehicles && vehicles.length) {
    vehicleId = vehicles[0].id;
    currency = vehicles[0].base_currency;
  }


  const lenders = useQuery({
    url: generateUrl('/main/vdr/lenders', {vehicle_id: vehicleId})
  })

  const redirectionSources = {
    geography: {
      path: '/virtual-data-room',
      rules: {
        tabKey: "geography",
        panel: "Stratification"
      }
    },
    sector: {
      path: '/virtual-data-room',
      rules: {
        tabKey: "sector",
        panel: "Stratification"
      }
    }
  }

  const onLenderSelect = (value: any) => {
    setSelectedLender(value)
  }

  if (!vehicles || !sectors || !countries)
    return <Skeleton/>

  return (
    <Space direction="vertical" className="fullWidth">
      <MainStatistics queryStr={`vehicle_id=${vehicleId}`} exclude={headerStatsToExclude} elementWidth={230}/>
      <Collapse
        accordion={false}
        activeKey={activeKey}
        onChange={setActiveKey}>
        <Panel
          key="summary-statistics"
          header="Summary Statistics">
          <SummaryStats vehicleId={vehicleId}/>
        </Panel>
        <Panel
          key="stratification"
          header="Stratification"
          // extra={<SelectLender lenders={lenders.data} onLenderSelect={onLenderSelect}/>}
        >
          <Diversification
            sectors={sectors}
            countries={countries}
            vehicleId={vehicleId}
            currency={currency}
            redirectionRule={rules}
            redirectionSources={redirectionSources}
            selectedLender={selectedLender !== "none" ? selectedLender : null}
          />
        </Panel>
        {/*<Panel*/}
        {/*  key="delinquencies-distribution"*/}
        {/*  header="Delinquencies Distribution">*/}
        {/*  <DelaysChart currency={currency} vdrUser vehicleId={vehicleId}/>*/}
        {/*</Panel>*/}
        <Panel key="default_prepayment" className="panel-design" header="Default / Prepayment Distribution">
          <DefaultPrepaymentDistribution vehicleId={vehicleId}/>
        </Panel>
        <Panel
          key="losses"
          header="Losses">
          <ExpectedLossChart vehicleId={vehicleId}/>
        </Panel>
        <Panel
          key="irr-graph"
          header="IRR Graph">
          <IrrGraph vehicleId={vehicleId}/>
        </Panel>
        <Panel
          key="cash-flow"
          header="Cash Flow">
          <CashFlowCollateral
            type="collateral"
            vehicleId={vehicleId}
            cashflowList={cashFlows.data}
            currency={currency}
            sectors={sectors}
            vehicles={vehicles}
            countries={countries}
          />
        </Panel>
      </Collapse>
    </Space>
  );
};

export default VirtualDataRoom;
