import React from 'react';
// Components
import { Col, Row } from 'antd';
//Custom Components
import CheckBoxButton from './checkbox_button';

interface Props {
  mode: any,
  attr: any,
  labelAttr: any,
  items: any,
  selectedItems: any,
  selectedItem: any,
  customRenderer: any,
  updateSelected: any,
  groupName: any,
  color: any,
  colors: string,
  sm: any,
  lg: any,
}

const CheckBoxButtonsGroup = (props: Props) => {
  const {
    mode, attr, labelAttr, items, selectedItems, selectedItem, customRenderer,
    updateSelected, groupName, color, colors, sm, lg
  } = props;

  const changeSelected = (newValue: any) => () => {
    if (mode === "multiple") {
      updateSelected(newValue)();
    } else if (mode === "single") {
      if (selectedItem !== newValue)
        updateSelected(newValue)
    }
  };

  const validItems = () => {
    return !items ? [] : items.filter((item: any) => item !== null)
  }

  return (
    <div className="fade-in">
      {groupName && <p>{groupName}</p>}
      <Row gutter={[8, 8]}>
        {validItems().map((entry: any, index: any) => {
          let value, label, selected;
          if (typeof entry === 'object') {
            value = entry[attr];
            label = entry[labelAttr];
          } else {
            value = entry;
            label = entry;
          }

          if (mode === "multiple")
            selected = selectedItems.includes(value);
          else if (mode === "single")
            selected = value === selectedItem;

          const content = customRenderer ? customRenderer(entry) : label;
          return (
            <Col key={value} xs={24} sm={sm} lg={lg}>
              <CheckBoxButton
                color={colors ? colors[index] : color}
                selected={selected}
                onChange={updateSelected ? changeSelected(value) : null}
                size='large'>
                {content}
              </CheckBoxButton>
            </Col>
          )
        })}
      </Row>
    </div>
  );
}

CheckBoxButtonsGroup.defaultProps = {
  sm: 12,
  attr: 'id',
  mode: "multiple",
  labelAttr: "name"
};

export default CheckBoxButtonsGroup;