import React from "react";
//Components
import { Button, Row } from "antd";
//Icons
import { ReloadOutlined } from "@ant-design/icons";

export const MessageListHeader = ({messages, fetchMessages}: any) => {
  return (
    <Row justify="end" align="middle" className="p8">
      <span className="mr8">{messages.length} {messages.length > 1 ? 'replies' : 'reply'}</span>
      <Button size="small" onClick={() => fetchMessages()} icon={<ReloadOutlined/>}>Refresh Chat</Button>
    </Row>
  )
}