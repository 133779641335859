//Colors
import { blue, geekblue } from "@ant-design/colors";
//Helpers
import { formatters } from "../../../../helpers";

export const datasetSelection = [
  {
    label: "Monthly Default Ratio",
    backgroundColor: geekblue[4],
    identifier: "gross_default_rate",
    format: formatters.percent,
    annotations: [
      {
        label: "Contractual Limit",
        color: "#f5222d",
        key: "default_limit",
      }
    ],
  },
  {
    backgroundColor: blue[4],
    identifier: "delinquency_ratio",
    format: formatters.percentOnePrecision,
    label: "Delinquency Ratio (>30 DPD)",
  },
  {
    backgroundColor: geekblue[6],
    label: "Cumulative Default Ratio",
    format: formatters.percent,
    identifier: "cumulative_default_ratio",
    annotations: [
      {
        label: "First performance Event",
        color: "#f5222d",
        key: "first_performance_limit",
      },
      {
        label: "Second performance Event",
        color: "#424242",
        key: "second_performance_limit",
      }
    ],
  },
  {
    backgroundColor: blue[6],
    format: formatters.percent,
    label: "Cumulative losses",
    identifier: "cumulative_losses",
  }
]