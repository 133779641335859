import React from 'react';
//Custom Components
import { CustomImage, MessageBox } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const Analytics = () => {
  return (
    <>
      <CustomImage
        alt="Analytics Full View"
        src={assets.images.analyticsModule.fullView}
        description="Analytics allow the User to get statistics and views on his overall portfolio, single SPV level or combination of different SPVs."
      />
      <MessageBox
        title="Note"
        description="By clicking on the list at the top, the User can select the relevant parameters on which the
        statistics are calculated. The first click on the list (All, SPV1, SPV2, SPV3) imply the inclusion of the SPV
        in the statistics calculated while a second click excludes it."/>
    </>
  );
};

export default Analytics;
