import React from 'react';
//Config
import { prepareCriteriaTableConfig } from "./config";
//Helpers
import { useQuery } from "../../../../../../../../../../helpers";
//Components
import { CasavoTable } from "../../../../../../../../../../components";

interface CriteriaTableProps {
  urlParam?: any
  vehicleId?: any,
  proposalId?: any,
  status?: any,
}

const CriteriaTable = (props: CriteriaTableProps) => {
  const {vehicleId, urlParam, proposalId, status} = props;

  const filterOptions = useQuery({
    url: `/main/real_estate/real_estate_filter_options/`
  })

  return (
    <CasavoTable
      height={350}
      usesFilters={false}
      vehicleId={vehicleId}
      usesFilterByAcquisition
      proposalId={proposalId}
      usesFilterByIneligibleProperties
      usesRealEstateSearch={false}
      filterOptions={filterOptions.data}
      tableConfig={prepareCriteriaTableConfig(urlParam, vehicleId, proposalId, status)}
      defaultValueForAcquisitionStatus={['Junior Bridge', 'Expected Acquisition']}
    />
  );
};

export default CriteriaTable;