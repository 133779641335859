import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import RowInformation from "../views/row_information";

const styles = {
  flex: 1,
  minWidth: "100%",
};

const Header = ({config}: any) => {
  return (
    <Row justify="space-between" gutter={16} style={styles}>
      {config.map((params: any) => (
        <Col>
          <RowInformation {...params}/>
        </Col>
      ))}
    </Row>
  )
};

export default Header;
