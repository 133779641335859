import React from 'react';
//Helpers
import { formatters, validateValue } from "../../../../../../../../../helpers";

//Helper function to calculate the sum of specific entities
const calculateSum = (data: any) => {
  const sum: any = {
    "sgd_subscribed": 0,
    "gross_accrued_interest": 0,
    "retained_init": 0,
    "net_account_init": 0
  }

  for (let record of data) {
    for (let key in sum) {
      sum[key] += record[key];
    }
  }
  return sum;
};

const renderLastRow = (data: any) => {
  const sum = calculateSum(data);
  return (
    <tr>
      <td><b>TOTAL</b></td>
      <td/>
      <td><b>{validateValue(sum.sgd_subscribed, formatters.thousands)}</b></td>
      <td/>
      <td/>
      <td/>
      <td/>
      <td/>
      <td><b>{validateValue(sum.gross_accrued_interest, formatters.thousands)}</b></td>
      <td><b>{validateValue(sum.retained_init, formatters.thousands)}</b></td>
      <td><b>{validateValue(sum.net_account_init, formatters.thousands)}</b></td>
    </tr>
  )
};

interface Props {
  data: any,
  preparedData: any,
  columns: any
}

const TableBody = ({data, preparedData, columns}: Props) => {
  return (
    <tbody>
    {Object.keys(preparedData).map(key => {
      const rows = preparedData[key];
      const rowSpan = rows.length;
      return rows.map((row: any, index: number) => {
        const visible = index === 0;
        return (
          <tr>
            {visible && (<td rowSpan={rowSpan}>{key}</td>)}
            {columns.map((col: any ,index:number) => {
              const value = row[col.key];
              const format = col.format;
              return (<td key={index}> {validateValue(value, format)}</td>)
            })}
          </tr>
        )
      })
    })}
    {renderLastRow(data)}
    </tbody>
  )
};

export default TableBody;



