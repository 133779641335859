const selections: any = {
  modified: [
    "N",
    "Y"
  ],
  loanStatus: [
    "Open",
    "Closed"
  ],
  loanPerformanceStatus: [
    "Performing",
    "1 - 29 Days Delinquent",
    "30 - 59 Days Delinquent",
    "60 - 89 Days Delinquent",
    "90 - 119 Days Delinquent",
    ">= 120 Days Delinquent",
    "Prepaid",
    "Full recovery",
    "Write off - Partial recovery",
    "Write off - No recovery",
    "Default",
    "Dissesto",
    "Repurchased",
    "Rejected",
    "Other",
  ],
  loanType: [
    "Secured Loan",
    "Unsecured Loan",
    "Invoice",
    "Credit Note"
  ],
  assetClass: [
    "SMEs loan",
    "Trade Receivables"
  ],
};

export {
  selections
}