//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../assets/theme/colors";

export default styled.div`
  .panel-design {
    margin-bottom: 10px;

    .ant-collapse-header:hover {
      background-color: #f2f2f2 !important;
    }
  }
}

.scrollable {
  overflow-x: auto !important;
}

table.concentrationTable {
  border-collapse: collapse;
  width: 100%;

  thead > tr > th {
    background-color: ${theme.colors.geekblue2};

    :first-child {
      border-left: 1px solid ${theme.palette.grayscale[2]};
      text-align: left;
    }

    text-align: center;
    padding: 4px;
    vertical-align: top;
    border-top: 1px solid ${theme.palette.grayscale[2]};
    border-right: 1px solid ${theme.palette.grayscale[2]};
    border-bottom: 1px solid ${theme.palette.grayscale[2]};
  }

  tbody > tr {
    :hover {
      background-color: ${theme.palette.grayscale[10]};
      cursor: pointer;
    }

    td {
      padding: 4px;
      border-right: 1px solid ${theme.palette.grayscale[2]};
      border-bottom: 1px solid ${theme.palette.grayscale[2]};
      text-align: center;

      :first-child {
        border-left: 1px solid ${theme.palette.grayscale[2]};
        font-weight: bold;
        text-align: left;
      }
    }
  }

  .statsContainer {
    background-color: ${theme.colors.geekblue2};
    border-radius: 5px;

    .statItem {
      text-align: center;

      h1 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
      }

      h3 {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
`;

