//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../../assets/theme/colors";

export default styled.div`
  position: relative;

  .clickableIcon {
    color: grey;
    transform: scale(0.6);

    :hover {
      transform: scale(0.75);
    }
  }

  .customTable-wrap {
    overflow: auto;
    max-height: 600px;
  }

  .information-row:hover {
    background-color: ${theme.colors.darkwhite};
    transition: background-color .5s;
  }

  .information-row:active {
    background-color: ${theme.palette.grayscale[10]};
  }

  .customTable {
    thead > tr > th {
      color: grey;
      text-align: center;
      padding-bottom: 8px;
      z-index: 99;
      background-color: ${theme.colors.white};
    }
  }
`;