//Other Libs
import styled from "styled-components";

export default styled.div<any>`
  overflow: hidden;
  width: 100%;
  height: 100%;

  .header {
    position: relative;
    margin-bottom: 16px;
  }

  .header .title {
    text-align: center;
    font-weight: 400;
  }

  .header .menu-actions {
    top: 0;
    position: absolute;
    right: 16px;
  }
`;