import React from 'react';

const CloseIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5001 3.5L3.50006 12.5" stroke="#736974" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M12.5001 12.5L3.50006 3.5" stroke="#736974" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    );
};

export default CloseIcon;