const filterKeys = (keys: any) => {
  return keys.filter((key: any) => !!key);
}

export const prepareKeys = (selected: any) => {
  const keys = [];

  for (let key in selected) {
    if (selected.hasOwnProperty(key)) {
      const value = selected[key];
      if (key === "grouping" && value) {
        keys.push(key);
      } else {
        keys.push(value);
      }
    }
  }
  return filterKeys(keys);
}
