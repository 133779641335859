import React from "react";
//Other Libs
import styled from "styled-components";
import moment from "moment";
//Components
import { Button, Card, Col, Row } from "antd";
import { InformationRow } from "@cardoai/components";
import FinancialStatement from "./financial_statement";
//Helpers
import { dateDifference, formatters, hasValue, makeUrl } from "../../../../../../helpers";
//Icons
import { LinkOutlined } from "@ant-design/icons";

//Custom Styles
const Styles = styled.section`
  .fullHeight {
    height: 100%;
  }

  .title {
    font-weight: bolder;
  }
`;

interface ICompanyInformation {
  data?: any,
  companyId?: any,
  fetchCompanyData?: any,
  sectors?: any
}

interface IDataRow {
  value?: any,
  label?: any,
  secondaryValue?: any,
  fetchCompanyData?: any,
  companyId?: any,
  attr?: string
}

function DataRow(props: IDataRow) {
  const {value, label, secondaryValue} = props;

  let finalValue = value;
  if (value && secondaryValue)
    finalValue += ", " + secondaryValue;

  return <InformationRow label={label} value={finalValue}/>
}

const CompanyInformation = (props: ICompanyInformation) => {
  const {data, companyId, fetchCompanyData, sectors} = props;

  const findCode = (sectors: any[], sectorCode: string) => {
    if (sectors && data.sector)
      return sectors.find(sector => sector.name === data.sector)[sectorCode]
    return null;
  }
  return (
    <Styles>
      <Row gutter={8}>
        <Col xs={24} lg={6} className="fullHeight">
          <Card size='small'>
            <p className="title">Company Information:</p>
            <section>
              <DataRow label="Company ID" value={data.id}/>
              <DataRow fetchCompanyData={fetchCompanyData} companyId={companyId} attr="name" label="Name"
                       value={data.name}/>
              <DataRow label="VAT Number" value={data.vat}/>
              <DataRow fetchCompanyData={fetchCompanyData} companyId={companyId} attr="company_type"
                       label="Company Type" value={data.company_type}/>
              <DataRow fetchCompanyData={fetchCompanyData} companyId={companyId} attr="legal_form"
                       label="Legal Form" value={data.legal_form}/>
              <DataRow fetchCompanyData={fetchCompanyData} companyId={companyId} attr="business_description"
                       label="Business Description"
                       value={data.business_description}/>
              <DataRow fetchCompanyData={fetchCompanyData} companyId={companyId} attr="company_size"
                       label="Company Size" value={data.company_size}/>
              <DataRow fetchCompanyData={fetchCompanyData} companyId={companyId} attr="rating"
                       label="Company Rating" value={data.rating}/>
              <DataRow fetchCompanyData={fetchCompanyData} companyId={companyId} attr="sector"
                       label="Sector" value={data.sector}/>
              <DataRow label="Sector NACE code"
                       value={findCode(sectors, "nace_code")}/>
              <DataRow label="Sector ATECO code"
                       value={findCode(sectors, "ateco_code")}/>
              <DataRow
                fetchCompanyData={fetchCompanyData}
                companyId={companyId}
                attr="number_of_employees"
                label="Number of Employees"
                value={data.number_of_employees}/>
              <DataRow
                fetchCompanyData={fetchCompanyData}
                companyId={companyId}
                attr="founded_date"
                label="Founding Date"
                value={data.founded_date &&
                  <span>
                  {formatters.date(data.founded_date)} ({dateDifference(data.founded_date, moment(), 'years') + " year/s"})
                </span>}
              />
            </section>

            <section className="mt16">
              <DataRow fetchCompanyData={fetchCompanyData} companyId={companyId} attr="country" label="Country"
                       value={data.country}/>
              <DataRow fetchCompanyData={fetchCompanyData} attr="city" companyId={companyId}
                       label="City" value={data.city}/>
              <DataRow fetchCompanyData={fetchCompanyData} attr="address" companyId={companyId}
                       label="Address" value={data.address}/>
              <DataRow fetchCompanyData={fetchCompanyData} attr="postal_code" companyId={companyId}
                       label="Postal Code" value={data.postal_code}/>
              <DataRow fetchCompanyData={fetchCompanyData} attr="province_code" companyId={companyId}
                       label="Province Code" value={data.province_code}/>
            </section>

            <section className="mt16">
              <Row align="middle" gutter={8}>
                <Col>
                  <DataRow fetchCompanyData={fetchCompanyData} attr="website" companyId={companyId}
                           label="Website" value={data.website}/>
                </Col>
                <Col>
                  {hasValue(data.website) &&
                    <a href={makeUrl(data.website)} rel="noopener noreferrer" target="_blank">
                      <Button size="small" shape="circle" icon={<LinkOutlined/>}/>
                    </a>}
                </Col>
              </Row>
              <DataRow fetchCompanyData={fetchCompanyData} attr="email" companyId={companyId}
                       label="E-mail" value={data.email}/>
              <DataRow fetchCompanyData={fetchCompanyData} attr="phone" companyId={companyId}
                       label="Phone" value={data.phone}/>
            </section>
          </Card>
        </Col>
        <Col xs={24} lg={18}>
          <FinancialStatement data={data.financials}/>
        </Col>
      </Row>
    </Styles>
  )
};

export default CompanyInformation;
