import React from 'react';
//Components
import Loader from "../../lottie_loader";
//Styles
import styles from "./styles"
//Custom Helpers
import { capitalize } from "../../../helpers";

interface Props {
  percent?: any,
  fileName?: any
}

const UploadLoader = (props: Props) => {
  const {percent, fileName} = props;

  const childStyle: any = Object.assign({}, styles.percentageItem);

  childStyle.width = `${percent}%`;
  const barLabel = `Uploading ${capitalize(fileName)} (${percent}%)`;

  return (
    <>
      <Loader/>
      <div style={styles.container}>
        <div style={styles.labelItem}>
          {barLabel}
        </div>
        <div style={childStyle}/>
      </div>
    </>
  )
};

export default UploadLoader;

UploadLoader.defaultProps = {
  fileName: 'files'
}
