//Components
import { notification } from "antd";

export default {
  error(description = "An error happened. Please Try Again!", message = "Error") {
    notification.error({message, description, placement: 'bottomRight'})
  },
  warning(description: any, message = "Warning") {
    notification.warning({message, description, placement: 'bottomRight'})
  },
  success(description: any, message = "Success") {
    notification.success({message, description, placement: 'bottomRight'})
  }
}
