import React from 'react';

interface Props {
  columns: any,
  group: any
}

const FamilyCell = (props: Props) => {
  const {columns, group} = props
  const styles = group.styles || {};
  const groupElements = columns.filter((column: any) => column.group === group.key);

  return (
    <th
      style={{
        ...styles,
        zIndex: 14
      }}
      colSpan={groupElements.length}>
      {group.key}
    </th>
  )
};

export default FamilyCell;
