import React from 'react';
//Other Libs
import queryString from "query-string";
//Custom Components
import { SmartTable } from "../../../../../components";
//Config
import { columns } from "../../../config";
import { Visible } from "@cardoai/components";
import UploadDocument from "../../upload_document";

const INITIAL_TABLE_SORT: any = [
  "-reference_date",
];

const DefaultDocuments = (props: any) => {

  const {
    vehicleId,
    documentIdentifier,
    triggerCollapse,
    onDelete,
    onDownload,
    onPublish,
    allowUpload,
    vehicle,
    title,
    config,
    isGrouped
  } = props;


  const tableReference: any = React.useRef();
  const documentsAPI = `/main/documents/get_list_of_${documentIdentifier}/`;

  const getTableAPI = () => {
    if (tableReference && tableReference.current)
      return tableReference.current;
    return {};
  }

  const updateTable = () => {
    const tableAPI: any = getTableAPI();
    /*After upload success rerender table*/
    tableAPI.getData();
  }

  const getFilters = () => {
    const params: any = {}
    if (vehicleId)
      params.vehicle_id = vehicleId;
    return queryString.stringify(params);
  };

  return (
    <>
      <SmartTable
        simpleFetch
        pageSize={50}
        allowGrouping
        groupBy="type"
        columns={columns}
        ref={tableReference}
        url={documentsAPI}
        vehicleId={vehicleId}
        filters={getFilters()}
        onExpand={triggerCollapse}
        renderCallbacks={{
          onDeleteRecord: onDelete,
          onDownloadRecord: onDownload,
          onPublishRecord: onPublish,
          updateTable: updateTable
        }}
        initialSort={INITIAL_TABLE_SORT}/>
      <Visible when={allowUpload}>
        <UploadDocument
          title={title}
          config={config}
          vehicle={vehicle}
          isGrouped={isGrouped}
          allowUpload={allowUpload}
          vehicleId={vehicleId}
          updateTable={updateTable}
          documentIdentifier={documentIdentifier}/>
      </Visible>
    </>
  );
};

export default DefaultDocuments;
