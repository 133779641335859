import React from "react";
//Components
import SellerDetails from "./seller_details";
import DebtorDetails from "./debtor_details";

interface Props {
  vehicleId?: number,
  proposalId?: any,
  section?: any,
  debtorQueryParams?: any,
  updateQueryParams?: any
  urlParam?: any
  currency?: any
}

const DetailSection = ({
                         section,
                         proposalId,
                         vehicleId,
                         debtorQueryParams,
                         updateQueryParams,
                         urlParam,
                         currency
                       }: Props) => {

  return (
    <div style={{marginTop: 20}}>
      {section === 'seller' &&
        <SellerDetails
          currency={currency}
          urlParam={urlParam}
          vehicleId={vehicleId}
          proposalId={proposalId}/>
      }
      {section === 'debtor' &&
        <DebtorDetails
          urlParam={urlParam}
          currency={currency}
          vehicleId={vehicleId}
          proposalId={proposalId}
          debtorQueryParams={debtorQueryParams}
          updateQueryParams={updateQueryParams}
        />
      }
    </div>
  )
}

export default DetailSection;