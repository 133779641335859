import React from 'react';
//Styled Components
import styled from "styled-components";

interface ChartTitleProps {
  children?: any,
  showPlaceholder?: boolean,
  centered?: boolean,
}

const Styles: any = styled.p<any>`
  ${props => props.centered && `text-align: center;`};
  font-weight: 400;
  margin-bottom: 8px !important;
`

const ChartTitle = ({children, showPlaceholder, ...rest}: ChartTitleProps) => {
  if (!children && showPlaceholder)
    return React.createElement('div', {style: {minHeight: 14}});

  return React.createElement(Styles, {...rest, children})
};

export default ChartTitle;

ChartTitle.defaultProps = {
  showPlaceholder: true,
  centered: true
}

