export const routeKey: any = {
    dashboard: "dashboard",
    spvManagement: "spv_management",
    loans: "loans_management",
    analytics: "analytics",
    hyperDataRoom: "vdr",
    hdrDocuments: "vdr-documents",
    hdrDocumentation: "vdr-help",
    documents: "documents",
    documentation: "documentation",
    simulation: "cash_flow_simulation",
    synchronization: "synchronization",
    notesManager: "notes_manager",
    collateral: "collateral",
    excelGenerator: "excel_generator",
    collectionManagement: "document_collection_management",
    realEstateManagement: "spv_management_real_estate",
    questionsAndAnswers: "q_&_a",
    fundingManager: "fundingManager",
    casavo_theta_notes_manager: "casavo_theta_notes_manager",
    notesSpvManagement: "notes_spv_management",
    collateralSpvManagement: "collateral_spv_management",
    //Luzzatti
    nplModule: "npe_module",
    nplHDR: "npe_hdr",
    syncReports: "sync_dashboard",
};

// todo remove once permission handling is updated
export const specialRoutesKeys = [
    "notes_spv_management",
    "collateral_spv_management",
];

export const actionKey: any = {
    /*Notes Manager actions*/
    confirmProposal: "confirm_notes_manager_proposal",

    getProposal: "get_notes_manager_proposal",
    deleteProposal: "delete_notes_manager_proposal",
    fundProposal: "fund_notes_manager_proposal",
    submitProposal: "submit_notes_manager_proposal",
    approveProposal: "approve_notes_manager_proposal",
    rejectProposal: "reject_notes_manager_proposal",
    rollbackProposals: "notes_manager_rollback_to_date",

    //Notes Manager functionalities config
    showInputTab: "supports_input_tab_notes_manager",

    //SPV functionality config
    showDetailsWaiver: "supports_waive_limit",
    //SPV actions
    createLimitWaiver: "create_limit_waiver",
    deleteLimitWaiver: "delete_limit_waiver",

    /*Sync actions*/
    executeSync: "execute_sync_request",
    synchronizeDOD: "create_dod_sync_request",
    synchronizePaymentReport: "create_payment_report_sync_request",
    synchronizeVDRRequest: "create_vdr_sync_request",
    synchronizeCollateral: "create_collateral_sync_request",
    synchronizeCollateralSimulation: "create_collateral_simulation_sync_request",
    removeCollateralSyncs: "remove_collateral_syncs",

    /*Documents actions*/
    upload_deal_documents: "upload_deal_documents",
    upload_other_documents: "upload_other_documents",
    upload_cardo_reports: "upload_cardo_reports",
    upload_other_reports: "upload_other_reports",

    //Loans actions
    showCompanyColumns: "show_companies_data_in_loans_management",
    showSPVFilters: "show_spv_filters_in_loans_management",
    showVDRFilters: "show_vdr_filters_in_loans_management",

    getDealDocuments: "get_list_of_deal_documents",
    getOtherDocuments: "get_list_of_other_documents",
    getOtherReports: "get_list_of_other_reports",
    getCardoAIReports: "get_list_of_cardo_reports",

    deleteDocuments: "delete_uploaded_document",
    publishDocuments: "confirm_documents_upload",
    downloadDocuments: "download_specific_document",

    getDODList: "get_dod_list",

    viewAccountsSection: "",
    accountsAssetSales: "get_notes_manager_accounts_asset_sales",
    accountsReconciliation: "get_notes_manager_accounts_reconciliation",
    accountsReconciliationPeriods:
        "get_notes_manager_accounts_reconciliation_periods",
    hideDPDDataset: "actions.hideDelaysDataset",

    //  Q&A Module actions
    updateMessage: "update_message",
    deleteTopic: "delete_topic",
    toggleTopicPublished: "toggle_topic_published",
};

export const authorizationKey: any = {
    route: routeKey,
    action: actionKey,
};
