import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const SPVManagement = () => {
  return (
    <>
      <CustomImage
        alt="SPV Management Full View"
        src={assets.images.spvModule.fullView}
        description="SPV Management shows in a single view the Assets (Collateral) and Liabilities (Notes) of an SPV."
      />
      <CustomImage
        description="By clicking on the toggle switch, the User can access details on Notes or Collateral."
        alt="Toggle Switch"
        fullWidth={false}
        centered={false}
        src={assets.images.others.switch}
      />
      <div>
        <DefinitionList
          header="The first screen offers a summary of the SPV within the Users’ domain with general information:"
          data={[
            {
              term: 'Asset Class',
              explanation: 'type of exposure included in the collateral_section pool'
            },
            {
              term: 'Issue date',
              explanation: 'first date of issuance of the notes'
            },
            {
              term: 'Notes Balance',
              explanation: 'total current balance of the notes_section'
            },
            {
              term: 'Collateral Balance',
              explanation: 'total current balance of loans_management in the collateral_section pool'
            },
            {
              term: 'Current OC (%)',
              explanation: 'current overcollateralization as % of the outstanding notes'
            },
            {
              term: 'Current OC (€)',
              explanation: 'current overcollateralization of the transaction in € amount'
            },
            {
              term: 'XS (%)',
              explanation: 'excess spread of the transaction as per last reporting period'
            },
            {
              term: 'Loans Outstanding #',
              explanation: 'number of loans (outstanding) included in the collateral_section pool'
            },
            {
              term: 'WAM (years)',
              explanation: 'Weighted Average Residual Maturity of the collateral_section pool calculated with reference ' +
                'to the maturity date (as eventually revised / changed), weighted by current loan balance'
            },
            {
              term: 'Final Maturity',
              explanation: 'date of final maturity of the notes_section'
            },

          ]}
        />
      </div>
    </>
  );
};

export default SPVManagement;
