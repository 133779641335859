import styled from "styled-components";

export const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  box-sizing: border-box;
  background-color: #d6e4ff;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
`

export const StyledChild = styled.div`
  flex: 1;
  display: flex;
  padding: 8px;
  margin: 0 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > .value {
    font-size: 16px;
    font-weight: 500;
    min-height: 25px;
  }

  & > .label {
    font-size: 11px;
    margin-top: 4px;
    font-weight: 400;

  }`