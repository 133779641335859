import React from 'react';
//Custom Components
import { Section } from "../../../components";

const UserProfiles = () => {
  return (
    <>
      <Section
        header="Investor Profile"
        body="Investor profile allows access to all the SPVs in the Investors’ portfolios and to all the modules
          activated on the basis of the specific subscription. In addition to browsing data of different SPVs,
          drill down at single loan or company levels and get advanced statistics, investors can view and
          download documents and reports in the Documents Management Module."
      />
      <br/>
      <Section
        header="Servicer Profile"
        body="Users with a Servicer profile can access one SPV at the time and with no possibility to aggregate data
          among different SPVs. In addition to browsing data of the SPVs to which he is associated, drill down
          at single loan or company levels and get advanced statistics, servicers can view, upload, download and
          publish documents and reports to the Investor in the Documents Management Module."
      />
    </>
  );
};

export default UserProfiles;
