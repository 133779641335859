import React from 'react';
//Components
import { Skeleton } from "antd";
//Hooks
import { useCountries, useCurrencies, useSectors, useVehicles } from "./api";

const GlobalApiContext = React.createContext({});

const canRender = (...args: any) => args.every((query: any) => query.data);

const Layout = (props: any) => {
  return (
    <div style={{paddingLeft: 16, paddingTop: 64, paddingRight: 16}}>
      {props.children}
    </div>
  )
}

const GlobalApiProvider = (props: any) => {
  const sectors = useSectors();
  const vehicles = useVehicles();
  const countries = useCountries();
  const currencies = useCurrencies();

  if (!canRender(sectors, countries, vehicles, currencies))
    return (
      <Layout>
        <Skeleton active/>
      </Layout>
    )

  return (
    <GlobalApiContext.Provider
      value={{
        sectors: sectors.data,
        vehicles: vehicles.data,
        vehicle: vehicles.data[0],
        countries: countries.data,
        currencies: currencies.data,
      }}>
      {props.children}
    </GlobalApiContext.Provider>
  );
};

export {
  GlobalApiContext,
  GlobalApiProvider
}


