import React, { useEffect, useState } from 'react';
//Other Libraries
import queryString from "query-string";
//Components
import { Button } from "antd";
//Constants
import { styles } from "./styles";
//Custom Helpers
import { cloneState, getInitialSelection } from "./helpers";

interface IVehicles {
  data?: any,
  setQueryStr: (param?: any) => void,
  onChange: (param: any) => void,
  disableSelection?: boolean
}

const Vehicles = (props: IVehicles) => {

  const {data, setQueryStr, onChange, disableSelection} = props;
  const [selected, setSelected] = useState<any>(getInitialSelection(data));


  useEffect(() => {
    let finalSelected = [];
    for (let key in selected) {
      if (selected.hasOwnProperty(key) && selected[key])
        finalSelected.push(key);
    }

    if (finalSelected.length === data.length)
      finalSelected = [];

    let query = queryString.stringify({vehicle_id: finalSelected});

    if (query)
      query = "&" + query;

    setQueryStr(query)

  }, [selected]);

  if (disableSelection)
    return null;

  const onClick = (id: any) => () => {
    setSelected((previousSelection: any) => {
      const newSelection = cloneState(previousSelection);

      function setAllEntities() {
        for (let key in newSelection) {
          if (newSelection.hasOwnProperty(key))
            newSelection[key] = false;
        }
      }

      function setCustomEntity() {
        newSelection[id] = !newSelection[id];
      }

      function checkSelection() {
        let total = 0;
        for (let key in newSelection) {
          if (newSelection.hasOwnProperty(key) && newSelection[key])
            total++;
        }

        if (total === 0 || total === data.length) {
          setAllEntities();
        }
      }

      if (id === "all") {
        setAllEntities();
      } else {
        setCustomEntity();
        checkSelection();
      }
      return newSelection;
    })
  }

  if (onChange) {
    onChange(selected);
  }


  const getButtonType = (value: any) => {
    let isSelected = true;

    if (value === "all") {
      for (let record of data) {
        const vehicleId = record.id;
        if (selected[vehicleId])
          isSelected = false;
      }
    } else
      isSelected = selected[value];

    return isSelected ? 'primary' : 'default';
  }


  return (
    <section style={styles.container}>
      <Button
        style={styles.button}
        onClick={onClick("all")}
        type={getButtonType("all")}>
        All
      </Button>
      {data.map((record: any, index: any) => {
        const vehicleId = record.id;
        const vehicleName = record.name;
        return (
          <Button
            key={String(index)}
            style={styles.button}
            onClick={onClick(vehicleId)}
            type={getButtonType(vehicleId)}>
            {vehicleName}
          </Button>
        )
      })}
    </section>
  );
};

export default Vehicles;

