import React from "react";
//Components
import { Button, Col, DatePicker, Row, Typography } from "antd";
//Components
import TextArea from "antd/es/input/TextArea";
import { disableEndDate, disableStartDate } from "./date_validation";

interface Props {
  input: any;
  onChangeValue: any;
  startDate: any;
  onChangeStartDate: any;
  endDate: any;
  onChangeEndDate: any;
  onConfirm?: any;
  useSubmitButton?: boolean;
  requiredDataValidation?: boolean;
}

const CreateWaiverContent = (props: Props) => {
  const {
    input,
    onChangeValue,
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    onConfirm,
    useSubmitButton,
    requiredDataValidation,
  } = props;

  const { Text } = Typography;

  const reqiuredAsterix = requiredDataValidation && (
    <Text type="danger">*</Text>
  );

  return (
    <div>
      <Row justify="space-between" align="middle" className="mb8">
        <Col span={4} className="bolder">
          Start date: {reqiuredAsterix}
        </Col>
        <Col span={8}>
          <DatePicker
            allowClear={false}
            value={startDate}
            onChange={onChangeStartDate}
            disabledDate={disableStartDate}
            placeholder="Select Start Date"
          />
        </Col>
        <Col span={4} className="bolder">
          End date: {reqiuredAsterix}
        </Col>
        <Col span={8}>
          <DatePicker
            allowClear={false}
            value={endDate}
            onChange={onChangeEndDate}
            disabledDate={disableEndDate}
            placeholder="Select End Date"
          />
        </Col>
      </Row>
      <Row align="middle" justify="start" className="mb8">
        <Col span={4} className="bolder">
          Description: <Text type="danger">*</Text>
        </Col>
        <Col span={20}>
          <TextArea
            rows={2}
            allowClear
            value={input}
            onChange={onChangeValue}
          />
        </Col>
      </Row>
      {useSubmitButton && (
        <Row justify="end" className="mt16">
          <Button onClick={onConfirm} type="primary">
            Submit
          </Button>
        </Row>
      )}
    </div>
  );
};

CreateWaiverContent.defaultProps = {
  useSubmitButton: true,
};

export default CreateWaiverContent;
