import React, { useState } from 'react';
//Components
import { Modal } from "antd";
//Constants
const modalStyle = {
  padding: 0,
  maxWidth: "75vw",
  minWidth: "75vw",
  borderRadius: 32,
}

const Slideshow = (props: any) => {
  const {useSlideshow, style, ...rest} = props;

  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => setVisible(previous => !previous);

  const handleCancel = () => {
    toggleVisibility()
  }

  const handleOk = () => {
    toggleVisibility()
  }

  const params: any = {
    style: {
      ...style,
      borderRadius: 4
    }
  };

  if (useSlideshow) {
    params.onClick = handleOk;

    if (!visible)
      params.className = rest.className + " pointerZoomIn";
    else
      params.className = rest.className + " pointerZoomOut";
  }

  const content = (
    <img alt="Slideshow" {...rest} {...params} />
  )

  if (!useSlideshow)
    return content;

  return (
    <>
      {content}
      <Modal
        centered
        footer={null}
        closable={false}
        visible={visible}
        style={modalStyle}
        bodyStyle={modalStyle}
        onCancel={handleCancel}>
        <img alt="Slideshow"
             src={rest.src}
             {...params}
             style={{width: "100%"}}
        />
      </Modal>
    </>
  )

};

export default Slideshow;
