import React from 'react';
//Components
import { Skeleton, Tabs } from "antd";
//Components
import SyncTable from "./components/sync_table";
//Configuration
import { isEmpty, useQuery } from "../../helpers";
import config from "./components/sync_table/config";

const {TabPane} = Tabs;


const Synchronization = () => {

  const syncVehicles = useQuery({
    url: '/main/sync_request/vehicles/'
  })

  if (!syncVehicles || isEmpty(syncVehicles.data))
    return <Skeleton/>


  return (
    <Tabs>
      {syncVehicles.data.map((vehicle: any) => {
        const vehicleId = vehicle.id;
        const vehicleName = vehicle.name;
        const vehicleIdentifier = vehicle.identifier
        return (
          <TabPane key={vehicleId} tab={vehicleName}>
            <SyncTable
              vehicle={vehicle}
              vehicleId={vehicleId}
              vehicleName={vehicleName}
              vehicleIdentifier={vehicleIdentifier}
              title={config.syncReports.title}
              columns={config.syncReports.columns}
            />
          </TabPane>
        )
      })}
    </Tabs>)
};

export default Synchronization;