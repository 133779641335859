import React from 'react';
//Components
import { Col, Row } from "antd";
import NewLoanSection from "./new_loan_section";
import DrawdownSection from "./drawdown_section";

interface Props {
  newLoanData?: any,
  currency?: any,
  proposalId?:any,
  vehicleId?:any,
  record?:any
}

const NewLoanAndDrawdown = ({newLoanData, currency,proposalId,vehicleId, record}: Props) => {
  return (
    <Row>
      <Col xs={12}>
        <NewLoanSection currency={currency} limits={newLoanData.newAsset}/>
      </Col>
      <Col xs={12}>
        <DrawdownSection currency={currency} limits={newLoanData.financing} proposalId={proposalId} vehicleId={vehicleId} record={record}/>
      </Col>
    </Row>
  );
};

export default NewLoanAndDrawdown;