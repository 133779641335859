import React, { useState } from 'react';
//Other libraries
import styled from "styled-components";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
//Custom Styles
import StyledDiv from '../styles';
//Custom Components
import Input from "../input";
import Layout from "../layout"
import { Button, Col, Divider, Row } from "antd";
//Icons
import { DownOutlined, UpOutlined } from "@ant-design/icons";
//Helpers
import CookieSettings from "./components/cookie_settings";
import { callIdpApi, formatDate } from "../../../../helpers";
//Styles
const ReadOnly = styled(Input)`
  pointer-events: none;
`;

const CookieConsent = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [consentType, setConsentType] = useState(true);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [cookieSettingsVisibility, setCookieSettingsVisibility] = useState(false);


  if (!location || !location.state)
    return <Navigate replace to='/signin'/>

  const userInfo = location.state.user;

  const toggleCookieSettings = () => {
    setCookieSettingsVisibility(prevState => !prevState)
  }

  const onConsentChange = (consentGiven: any) => {
    setConsentType(consentGiven);
  }

  const updateConsentType = () => {
    setLoadingConfirmation(true);
    callIdpApi({
      method: 'put',
      url: 'users/consent_cookies/',
      body: {
        consent_cookies: consentType
      },
      onSuccess: () => {
        navigate('/');
      },
      onFinish: () => {
        setLoadingConfirmation(false);
      }
    })
  }

  return (
    <StyledDiv>
      <Layout>
        <h2 className="textCenter p8">Confirm Your Data</h2>
        <Divider plain className="mt8"/>
        <div className="p16">
          <Row gutter={16}>
            <Col span={12}>
              <span className="bolder">FIRST NAME</span><ReadOnly value={userInfo.first_name} required placeholder=""/>
              <span className="bolder">EMAIL</span><ReadOnly value={userInfo.email} required placeholder=""/>
            </Col>
            <Col span={12}>
              <span className="bolder">LAST NAME</span><ReadOnly value={userInfo.last_name} required placeholder=""/>
              <span className="bolder">JOINED DATE</span><ReadOnly value={formatDate(userInfo.date_joined)}
                                                                   required placeholder=""/>
            </Col>
            <Col span={24} className="mt16">
              <Button type="primary" className="size12" loading={loadingConfirmation} onClick={updateConsentType}
                      style={{width: 150}}>CONFIRM</Button>
            </Col>
            <Col span={24} className="size14 mt24">
              By clicking the "Confirm" button, I acknowledge that I have read and accepted CardoAI's <a
              className="pointerCursor" target={'_blank'} href='https://www.iubenda.com/privacy-policy/87365683'>Privacy
              Policy</a>.
            </Col>
          </Row>
          <Row align="middle" justify="start">
            <Col onClick={toggleCookieSettings} className="size12 bold pointerCursor mt8">
              Cookie Settings {cookieSettingsVisibility ? <UpOutlined className="size10"/> :
              <DownOutlined className="size10"/>}
            </Col>
          </Row>
          {cookieSettingsVisibility && <CookieSettings onConsentChange={onConsentChange}/>}
        </div>
      </Layout>
    </StyledDiv>
  );
};

export default CookieConsent;