import React from "react";
//Custom Components
import { SimpleEmpty } from "@cardoai/components";
import { SmartChart } from "../../../../../components";
//Helpers
import { formatters, MathOp } from "../../../../../helpers";

interface IDelaysSingleDataset {
  format?: any,
  currency?: any,
  dataSets?: any,
  onClick?: any
}

//Get chart value in single dataset
const getChartValue = (record: any, entities: any) => {
  return (entities.distribution === 'percentage') ? record.ratio : record.value
}

const DelaysSingleDataset = (props: IDelaysSingleDataset) => {

  const {format, currency, dataSets, onClick} = props;

  //Sum of all amounts in the dataset
  let sum = 0;
  dataSets.forEach((dataset: any) => sum += dataset.data[0]);

  if (sum === 0)
    return <SimpleEmpty/>

  //Shows a SmartChart doughnut in case of a single dataset
  const prepareSingleDatasetData = () => {
    return dataSets.map((dataset: any) => {
      const value = dataset.data[0];
      const label = dataset.label;
      const ratio = sum !== 0 ? MathOp.divide(value, sum) : 0;
      return ({
        label: label,
        value: value,
        ratio: ratio
      })
    });
  }

  //Formatter for single dataset
  const getCustomFormatter = (entities: any) => {
    if (entities.distribution === 'count')
      return formatters.thousandsShorted;
    if (entities.distribution === 'percentage') {
      return (value: any) => {
        return formatters.percentOnePrecision(value);
      }
    }
    return (value: any) => formatters.currency({value, currency})
  };

  return (
    <SmartChart
      usesMenu
      format={format}
      allowDistribution
      onClick={onClick}
      labelEntity="label"
      types={['doughnut']}
      getValue={getChartValue}
      data={prepareSingleDatasetData()}
      customFormatter={getCustomFormatter}
      initialEntities={{distribution: "amount"}}/>
  )
}

export default DelaysSingleDataset;