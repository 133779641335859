import React from 'react';
//Components
import { Col, Input, Row, Select } from "antd";
import { FieldSet } from "@cardoai/components";
//Constants
import { CLIENT_OPTIONS } from "../../config";

interface IDatabaseConfiguration {
  state?: any,
  onChange?: any,
  selected?: any
}

const styles: any = {};

const DatabaseConfiguration = (props: IDatabaseConfiguration) => {
  const {state} = props;

  const onChange: any = (attr: any) => (e: any) => {
    if (typeof e === "object")
      e = e.target.value;
    return props.onChange(attr)(e);
  };

  return (
    <Row
      gutter={16}
      align="middle"
      className="mt16"
      justify="space-between"
      style={styles.content}>
      <Col xs={8}>
        <Row className="columnDirection">
          <Col>
            <FieldSet
              label="Client"
              component={Select}
              placeholder="Enter"
              value={state.db_choice}
              children={CLIENT_OPTIONS.map(option => {
                return (
                  <Select.Option value={option.key}>
                    {option.label}
                  </Select.Option>
                )
              })}
              onChange={onChange("db_choice")}/>
          </Col>
          <Col className="mt16">
            <FieldSet
              label="Database"
              component={Input}
              placeholder="Enter"
              value={state.database}
              onChange={onChange("database")}/>
          </Col>
        </Row>
      </Col>
      <Col xs={8}>
        <Row className="columnDirection">
          <Col>
            <FieldSet
              label="Host"
              component={Input}
              placeholder="Enter"
              value={state.host}
              onChange={onChange("host")}/>
          </Col>
          <Col className="mt16">
            <FieldSet
              label="Port"
              component={Input}
              placeholder="Enter"
              value={state.port}
              onChange={onChange("port")}/>
          </Col>
        </Row>
      </Col>
      <Col xs={8}>
        <Row className="columnDirection">
          <Col>
            <FieldSet
              name="usr"
              label="Username"
              component={Input}
              placeholder="Enter"
              autocomplete="new-usr"
              value={state.username}
              onChange={onChange("username")}/>
          </Col>
          <Col className="mt16">
            <FieldSet
              name="pwd"
              label="Password"
              component={Input}
              type="password"
              placeholder="Enter"
              autocomplete="new-password"
              value={state.password}
              onChange={onChange("password")}/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DatabaseConfiguration;