import React from "react";
//Other Libs
import { Col, Row } from "antd";
//Constants
import { theme } from "../../../../../../assets/theme/colors";

const Form = (props: any) => {
  let labelContent: any = props.label + " :";
  if (props.required) {
    labelContent = (
      <>
        <span style={{marginRight: 6, color: theme.colors.lightred, lineHeight: 1}}>
          *
        </span>
        {props.label}:
      </>
    )
  }
  return (
    <Row gutter={8} align='middle' className={props.className}>
      <Col xs={6}>
        {labelContent}
      </Col>
      <Col xs={18}>
        {props.children}
      </Col>
    </Row>
  )
};

Form.defaultProps = {
  className: 'mt8'
};
export default Form;