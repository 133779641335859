import React from 'react';
//Other Libraries
import cx from "classnames";
//Custom helpers
import { formatValue, getWidthStyle } from "../../helpers";
import { validateValue } from "../../../../../helpers";
//Components
import { Tooltip } from "antd";
//Constants
import { theme } from "../../../../../assets/theme/colors";

interface Props {
  id: any,
  column: any,
  record: any,
  recordIndex: number,
  columnIndex: number,
  callbacks: any,
  extraSpacing: boolean,
  emptyCell: boolean,
  getData: any,
  onCellClick: any,
  showTooltipOnCell: any,
}

const BodyCell = (props: Props) => {
  const {
    id, column, record, recordIndex, columnIndex, callbacks, extraSpacing, emptyCell, getData, onCellClick,
    showTooltipOnCell, ...other
  } = props;

  if (emptyCell)
    return <td style={{backgroundColor: theme.colors.darkwhite}}/>;

  const {key, format, width, align, noWrap, render, cellStyle, Component, ...restColumnParams} = column;


  let value = record[key];

  const params = {
    ...other,
    getData: getData,
    record: record,
    index: recordIndex,
    callbacks: callbacks,
  };

  if (render)
    value = render(value, params);

  if (format)
    value = formatValue(value, format, record)

  let style = {...cellStyle}, title;


  if (width) {
    title = value;
    style = getWidthStyle(width);
  }

  if (extraSpacing)
    style.paddingLeft = 28;

  const classes = {
    noWrap,
    [`cell-${id}`]: true,
    textLeft: columnIndex === 0,
    textCenter: align === 'center',
    textRight: align === 'right',
    pointerCursor: !!onCellClick
    // yellowText: record.colored === true
    // borderRight: columnGroups && groupsLastColumn[group] === key
  };

  if (Component) {
    return (
      <td>
        <Component
          {...params}
          {...restColumnParams}
          value={value}
          column={column}
          {...value}/>
      </td>
    )
  }

  if (record.cellBackground) {
    style.backgroundColor = record.cellBackground
  }

  let content;

  if (Component) {
    content = <Component {...value}/>
  } else {
    content = (
      <Tooltip title={showTooltipOnCell ? title : null}>
        {validateValue(value)}
      </Tooltip>
    )
  }

  let cellClick;

  if (onCellClick)
    cellClick = () => onCellClick(record, column)

  return (
    <td style={style} className={cx(classes)} onClick={cellClick}>
      {content}
    </td>
  )
};

export default BodyCell;
