import React from 'react';
//Layout Components
import Layout from "./layout"
//Components
import Pie from "./chart/pie";
import Doughnut from "./chart/doughnut";
//Helpers
import { formatters } from "../../../helpers";

interface NestedPieProps {
  pie: any,
  doughnuts: any,
  displayPieLabels?: boolean,
  displayDoughnutLabels?: boolean,
  innerRadiusWidth?: number,
  parentWidth?: number,
  formatter?: any,
}

function prepareDoughnuts(doughnuts: any, scale: any) {

  if (!doughnuts)
    return [];

  const radius_list: any = {};

  return doughnuts.config.map((doughnut: any, index: any) => {

    const spacing = doughnuts.spacing || 40;

    const dimension = doughnuts.dimension + index * spacing + spacing;

    radius_list[index] = dimension / 2;

    if (index > 0)
      scale = scale * radius_list[index - 1] / radius_list[index];

    return {
      config: doughnut,
      width: dimension,
      height: dimension,
      innerRadius: 1 - scale,
    }
  })
}

const NestedPie = (props: NestedPieProps) => {
  const {pie, doughnuts, displayPieLabels, displayDoughnutLabels, innerRadiusWidth, parentWidth, formatter} = props;

  let dimension = 0;

  if (pie)
    dimension += pie && pie.dimension;

  if (doughnuts) {
    const total = doughnuts.config.length;
    const marginOfError = doughnuts.spacing;
    dimension = doughnuts.dimension + (total - 1) * doughnuts.spacing + marginOfError;
  }

  return (
    <Layout.Container dimension={dimension}>
      {pie && (
        <Layout.Element zIndex={displayPieLabels ? 10 : 1}>
          <Pie
            config={pie.config}
            width={pie.dimension}
            height={pie.dimension}
            formatter={formatter}
          />
        </Layout.Element>
      )}
      {prepareDoughnuts(doughnuts, innerRadiusWidth).map((properties: any, index: any) => {
        return (
          <Layout.Element zIndex={index + 1}  key={index}>
            <Doughnut {...properties} parentWidth={parentWidth} displayLabels={displayDoughnutLabels}/>
          </Layout.Element>
        )
      })}
    </Layout.Container>
  );
};

export default NestedPie;

NestedPie.defaultProps = {
  innerRadiusWidth: 0.2,
  displayDoughnutLabels: false,
  displayPieLabels: false,
  formatter: formatters.percentOnePrecision
}