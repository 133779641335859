//Styled Components
import styled from "styled-components";
//Constants
import { theme } from "../../../../../../assets/theme/colors";

export default styled.div<any>`
  ul {
    list-style-type: disc !important;
    max-height: ${props => props.height}px;
    overflow: auto;
    padding-right: 2px;

    li {
      font-size: 18px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: normal !important;
      ${props => props.showHover && `
      :hover{
       background-color: ${theme.palette.grayscale[10]};
       transition: background-color .5s;
       };
       :active{
         background-color: ${theme.palette.grayscale[10]};
       }
   `};
    }
  }
`;

