//Components
import { Row } from "antd";
//Other Libs
import styled from "styled-components";

export default styled(Row)`
  display: flex;
  align-items: center;
  padding: 0px 12px;
  position: sticky;
  top: 71px;
  z-index: 11;
  background-color: #fafafa;
  flex-wrap: nowrap;

  p:nth-child(1) {
    font-size: 14px !important;
    font-weight: 500 !important;
    min-height: 25px;
  }
`
