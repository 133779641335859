import React from 'react';
//Components
import { Button } from "antd";
//Icons
import { SyncOutlined } from "@ant-design/icons";
//Constants
import { STATUS_TYPE } from "../status/config";
//Helpers
import { callApi } from "../../../../../../helpers";

const SYNC_TYPES: any = {
  "Sync DoD": {
    icon: <SyncOutlined/>,
    label: "Sync",
  },
  "Sync Collateral Simulation": {
    icon: <SyncOutlined/>,
    label: "Sync",
  },
  "Sync Collateral": {
    icon: <SyncOutlined/>,
    label: "Sync",
  },
  "Sync VDR": {
    icon: <SyncOutlined/>,
    label: "Sync",
  },
  "Sync Payment Report": {
    icon: <SyncOutlined/>,
    label: "Sync",
  },
  "Sync Incremental Instalment": {
    icon: <SyncOutlined/>,
    label: "Sync",
  }
}

interface ISyncButton {
  size?: any,
  status?: any,
  requestReports?: any,
  id?: any,
  fileType?: any,
  vehicleId?: any
}

const SyncButton = (props: ISyncButton) => {
  const {size, status, requestReports, id, fileType, vehicleId} = props;

  const [loading, setLoading] = React.useState(false);

  const allowSync = [
    STATUS_TYPE.ready,
  ].includes(status);

  const {label, icon} = SYNC_TYPES[fileType];

  const onSync = () => {
    if (!allowSync)
      return;

    callApi({
      url: `/main/sync_request/${id}/execute/?vehicle_id=${vehicleId}`,
      method: "post",
      body: {},
      setLoading,
      onSuccess: (response: any) => {
        requestReports();
      },
      onFinish: () => setLoading(false)
    })
  };

  return (
    <Button
      icon={icon}
      size={size}
      onClick={onSync}
      loading={loading}
      disabled={!allowSync}>
      {label}
    </Button>
  );
};

export default SyncButton;

SyncButton.defaultProps = {
  size: 'small'
};
