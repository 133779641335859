import React from 'react';
//Other Libs
import styled from "styled-components";
//Configurations
import { COOKIE_INFORMATION } from "../config";

const CookieParent = styled.div`
  max-width: 700px;
  max-height: 150px;
  overflow: auto;
`

const CookieTable = styled.table`
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 10px;
`

const TABLE_HEADERS = [
  {key: "name", label: "Name"},
  {key: "provider", label: "Provider"},
  {key: "Purpose", label: "Purpose"},
  {key: "expiry", label: "Expiry"},
  {key: "type", label: "Type"},
]

const CookieDeclaration = ({cookieType}: any) => {
  return (
    <CookieParent>
      <p className="size12">{COOKIE_INFORMATION[cookieType].description}</p>
      <CookieTable>
        <thead>
        {TABLE_HEADERS.map(({label}) => <th>{label}</th>)}
        </thead>
        <tbody>
        {COOKIE_INFORMATION[cookieType].table.map(element => (
          <tr>
            <td>{element.name}</td>
            <td>{element.provider}</td>
            <td>{element.purpose}</td>
            <td>{element.expiry}</td>
            <td>{element.type}</td>
          </tr>
        ))}
        </tbody>
      </CookieTable>
    </CookieParent>
  );
};

export default CookieDeclaration;
