import React from 'react';
//Components
import { Col, Row } from 'antd';
//Custom Components
import { ContentView, TreeView } from "../components";
import { GeneralHelpRoutes, GeneralHelpTOC } from "../configurations";

const contentHeight = {
  height: window.innerHeight - 140,
  overflow: 'auto'
};

const GeneralHelp = () => {

  return (
    <Row gutter={0}>
      <Col span={6}>
        <TreeView
          toc={GeneralHelpTOC}
          paths={{
            defaultPath: '/documentation',
            homePath: '/documentation/home'
          }}
          style={contentHeight}
          invalidKeys={["", "documentation"]}
        />
      </Col>
      <Col span={18}>
        <ContentView routeConfig={GeneralHelpRoutes} style={contentHeight}/>
      </Col>
    </Row>
  );
};

export default GeneralHelp;