import React from 'react';
import { Col, Divider, Row } from "antd";
import { formatLimits } from "../../../spv/components/management/notes_section/limits/helpers";

const Card = ({title, config}: any) => {
  return (
    <div style={{
      display: 'flex',
      borderRadius: '8px',
      flexDirection: 'column',
      overflow: 'hidden',
      border: '1px solid #E3DDE6'
    }}>
      <div style={{
        height: 40,
        padding: 8,
        color: '#fff',
        fontWeight: 400,
        display: 'flex',
        paddingLeft: 16,
        alignItems: 'center',
        backgroundColor: '#2D72EA'
      }}>
        {title}
      </div>
      <div style={{height: '100%', padding: 16, minHeight: 200, maxHeight: 400, overflowY: "auto"}}>
        <Row gutter={[8, 8]}>
          {config.map((record: any, index: number) => {
            const labelStyle: any = {}
            const valueStyle: any = {};
            const isLatest = index === config.length - 1;

            if (["Total", "Sum"].includes(record.name)) {
              valueStyle.backgroundColor = '#F5F8FE'
              valueStyle.borderRadius = 8;
              valueStyle.padding = 8;
            }

            if (record.bolder) {
              labelStyle.fontWeight = 500;
            }
            if(record.level === 1){
              labelStyle.marginLeft = 20
            }
            if(record.level === 2){
              labelStyle.marginLeft = 35
            }
            return (
              <>
                <Col xs={24}>
                  <Row justify="space-between" gutter={[16, 16]}>
                    <Col style={labelStyle}>
                      {record.name}
                    </Col>
                    <Col style={valueStyle}>
                      {formatLimits(record.value, record.formatter)}
                    </Col>
                  </Row>
                </Col>
                {!isLatest && (
                  <Col xs={24}>
                    <Divider style={{margin: 4}}/>
                  </Col>
                )}
              </>
            )
          })}
        </Row>
      </div>
    </div>
  )
}

export default Card;