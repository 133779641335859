import React from 'react';
//Other Libs
import cx from "classnames";
//Helpers
import { formatVehicleLimits, getLimitColor } from "../../../../helpers";
//Styles
import { StyledDiv, Table } from "../../../../styles";
//HOC
import withLimitDownload from "../../../../components/hoc/with_limit_download";

interface Props {
  data: any[],
  height: any,
  currency?: any,
}

const LTVCheck = (props: Props) => {
  const {data, height, currency} = props;

  return (
    <StyledDiv height={height}>
      <Table className="fullWidth">
        <thead>
        <tr>
          <th/>
          <th className="textCenter primary">Realized</th>
          <th className="textCenter primary">Notes</th>
        </tr>
        </thead>
        <tbody>
        {data.map((entry: any, index: number) => {
          const {broken, name, fulfillment, fulfillment_percent, value, value_percent, formatter}: any = entry;

          let colorType = "";
          if (name.includes("Junior"))
            colorType += getLimitColor(broken);

          return (
            <tr key={index}>
              <td className="bold pl24">{name}</td>
              <td
                className={cx("textCenter bold", colorType)}>{formatVehicleLimits(fulfillment, fulfillment_percent, formatter, currency)}</td>
              <td
                className={cx("textCenter bold", colorType)}>{formatVehicleLimits(value, value_percent, formatter, currency)}</td>
            </tr>
          )
        })}
        </tbody>
      </Table>
    </StyledDiv>
  );
};

export default withLimitDownload(LTVCheck)({
  title: "Pre-draw LTV Check",
  prepareData: (data: any[]) => {
    return data.map(record => {
      return {
        "Name": record.name,
        "Realized": Number(record.fulfillment),
        "Notes": record.value
      }
    })
  }
});
