import {Skeleton, Spin} from "antd";
import {useQuery} from "../../../helpers";
import {useToggle} from "@cardoai/utils";
import {createContext, useContext} from "react";

const ProposalContext = createContext({});

const ProposalProvider = ({proposalId, vehicleId, children,isFar, ...rest}: any) => {
    const [inProgress, toggleProgress] = useToggle(false);
    const proposal = useQuery({url: `/main/notes_manager/${proposalId}/?vehicle_id=${vehicleId}`});

    if (!proposal.data)
        return <Skeleton/>

    return (
        <ProposalContext.Provider value={{
            ...rest,
            vehicleId,
            inProgress: inProgress,
            proposal: proposal.data,
            toggleProgress: toggleProgress,
            fetchProposal: proposal.fetch,
            isFar: proposal?.data?.is_far,
            status: proposal?.data?.status,
            proposalId: proposal?.data?.id,
            file: proposal?.data?.user_input_file
        }}>
            <Spin size="small" spinning={inProgress}>
                {children}
            </Spin>
        </ProposalContext.Provider>
    );
}

const useProposal = (): any => useContext(ProposalContext);

export {
    useProposal,
    ProposalContext,
    ProposalProvider
}