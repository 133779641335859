import React, { useEffect, useImperativeHandle } from 'react';
//Components
import Container from "./styles";
import { Skeleton, Spin } from "antd";
//Helpers
import { callApi } from "../../api";
//Helpers
import { MathOp } from "../../../../../helpers";

interface ProgressBarProps {
  a: any,
  b: any
}

const ProgressBar = ({a, b}: ProgressBarProps) => {
  if (!b)
    return null;

  // @ts-ignore
  const percentage: any = MathOp.divide(a, b) * 100;

  return (
    <>
      <section className="night-progress">
        <div style={{
          width: percentage + "%",
          backgroundColor: "transparent"
        }}/>
        <div style={{
          width: (100 - percentage) + 0.1 + "%",
          backgroundColor: "#fff",
          zIndex: 8
        }}/>
      </section>
      <section className="night-progress-percentage">
        {percentage.toFixed(2) + "%"}
      </section>
    </>
  )
}

const Header = (props: { entity: any; filters: any; }, ref: any) => {
  const {entity, filters} = props;
  const [loading, setLoading] = React.useState<boolean>(false)
  const [statistics, setStatistics] = React.useState<any>(null);

  useEffect(() => {
    getData();
  }, [filters])

  const getData = () => {
    setLoading(true)
    callApi({
      url: `/entity/${entity}/loans/statistics/?${filters}`,
      onSuccess: ((response: boolean | ((prevState: boolean) => boolean)) => {
        setStatistics(response);
      }),
      onFinish: () => {
        setLoading(false);
      }
    })
  }

  useImperativeHandle(ref, () => ({
    getData: getData
  }));

  if (!statistics)
    return <Skeleton active/>

  return (
    <Spin spinning={loading}>
      <Container>
        <div className="clx-box">
          <h2>{statistics.total_loans}</h2>
          <h3>Open loans to be managed</h3>
        </div>
        <div className="clx-box">
          <ProgressBar
            a={statistics.wip_loans}
            b={statistics.total_loans}
          />
          <h2>{statistics.wip_loans}</h2>
          <h3>Work in Progress</h3>
        </div>
        <div className="clx-box">
          <ProgressBar
            a={statistics.completed_loans}
            b={statistics.total_loans}/>
          <h2>{statistics.completed_loans}</h2>
          <h3>Completed</h3>
        </div>
        <div className="clx-box">
          <h2>{statistics.avg_delay}</h2>
          <h3>Avg completion days</h3>
        </div>
      </Container>
    </Spin>
  );
};

export default React.forwardRef(Header);
