import React from "react";
//Components
import { SimpleEmpty } from "@cardoai/components";
//Styles
import { StyledDiv, Table } from "../../../../../styles";
//Helpers
import withLimitDownload from "../../../../../components/hoc/with_limit_download";
import { formatters, isEmpty, validateValue } from "../../../../../../../../../../../helpers";


const ExistingLoans = (props: any) => {
  const {data, currency} = props;
  const {records, notionalSum} = data;


  if (isEmpty(records))
    return <SimpleEmpty/>

  return (
    <StyledDiv>
      <Table className="fullWidth">
        <thead>
        <tr>
          <th/>
          <th className="textCenter primary">Amount</th>
          <th className="textCenter primary">Percentage</th>
        </tr>
        <tr>
          <th className="primary">Notional Outstanding Capital</th>
          <th className="textCenter">{formatters.currency({value: notionalSum, currency})}</th>
          <th/>
        </tr>
        <tr>
          <th>Of which:</th>
          <th/>
          <th/>
        </tr>
        </thead>
        <tbody>
        {records.map((record: any, index:number) => (
          <tr key={index}>
            <td className="pl24">{record.name}</td>
            <td className="pl24">{formatters.currency({value: record.fulfillment, currency})}</td>
            <td className="pl24">{validateValue(record.fulfillment_percent, formatters.percent)}</td>
          </tr>)
        )}
        </tbody>
      </Table>
    </StyledDiv>
  )
}


export default withLimitDownload(ExistingLoans)({
  title: "Realized Loans Limits",
  prepareData: function (data: any) {
    const records = data.records;
    const sum = data.notionalSum;

    const downloadableStructure = records.map((record: any) => {
      const {name, fulfillment, fulfillment_percent} = record;
      return {
        "Name": name,
        "Amount": fulfillment,
        "Percentage": fulfillment_percent
      }
    });

    downloadableStructure.push({
      "Name": "Notional Outstanding Capital",
      "Amount": sum
    })

    return downloadableStructure;
  }
});