import React, { useEffect } from 'react';
//Other Libraries
import { useTheme } from "styled-components";
import { Navigate } from "react-router-dom";
//Custom Styles
import StyledLogin from './components/styles';
//Custom Components
import Layout from "./components/layout"
import LoginForm from "./components/login_form";
//Constants
import { useAuthentication } from "../../utils/hooks";

const Login = () => {
  const theme: any = useTheme();
  const authentication = useAuthentication();

  useEffect(() => {
    document.title = "Sign In - Securitization"
  }, [])

  useEffect(() => {
    if (theme.darkMode)
      theme.switchThemeMode();
  }, []);

  const onSubmit = async (credentials: any) => {
    authentication.onLogin(credentials);
  }

  if (authentication.authenticated)
    return <Navigate to='/' replace/>

  return (
    <StyledLogin>
      <div className="disable_color_invert">
        <Layout>
          <LoginForm
            onLogin={onSubmit}
            loading={authentication.authenticating}
          />
        </Layout>
      </div>
    </StyledLogin>
  )
};

export default Login;