import React from 'react';
//Components
import { Col, Input, Row } from "antd";

const CustomTitle = (props: any) => {
  const {value, onChange} = props;
  return (
    <>
      <Row justify="center">
        <Col>
          <h3>Table of Contents</h3>
        </Col>
      </Row>
      <Row>
        <Input.Search
          value={value}
          onChange={onChange}
          placeholder="Search..."/>
      </Row>
    </>
  )
};

export default CustomTitle;