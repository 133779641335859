import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../assets";
import { isVDRInvestor } from "../../../../../helpers";

const HDRDocuments = () => {

  const hdrInvestor = isVDRInvestor();

  const description =
    <div style={{textAlign: 'justify'}}>
      {hdrInvestor ? 'The documents_management module allows the user to download and view the documents disclosed by the admin.' :
        'The documents module allows users to manage documents: uploading new documents, deciding which type of document ' +
        'and the reference date, has the option to delete, unpublish or download documents, and also can choose which ' +
        'documents to show to investors.'}
    </div>

  return (
    <Row>
      <Col sm={24}>
        <CustomImage
          description={description}
          alt="Documents Module"
          src={assets.images.documentsModule.fullView}
        />
      </Col>
    </Row>
  );
};

export default HDRDocuments;
