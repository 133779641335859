import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const Concentration = () => {
  return (
    <>
      <div>
        <p>
          The section displays the concentration statistics aggregated at the level of the selected parameters along
          the following main dimensions: Largest Debtor (by outstanding balance), Debtor Region, Loan Size, Debtor
          Industry,
          Loan Type, Residual Maturity and Loan Rating (for single SPV selection or if a standard rating scale has been
          defined
          by the User for multiple selections).
        </p>
      </div>
      <CustomImage
        alt="Analytics Concentration Statistics View"
        src={assets.images.analyticsModule.concentration}
      />
      <br/>
      <div>
        <p>
          The user can expand the left and right side of the screen by clicking on “Expand Concentration Table” or
          “Expand Diversification Graphs” at the top. Through the menu button on the top right of each graph, the User
          can access to a list of options including switching the labels from percentage (%) to amounts (€) or to count
          (#), expand the “Other” category (clicking on “Ungroup Items”) and changing the style of the graph, as well as
          downloading each graph in a CSV or PNG format and even view it at full screen.
        </p>
      </div>
    </>
  );
};

export default Concentration;
