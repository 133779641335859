import React from 'react';
//Components
import { Col, Row } from "antd";
import DrawDownTable from "./draw_down_table";

const DrawdownLimits = (props: any) => {
  const {seniorData, juniorData, currency} = props
  return (
    <Row gutter={8}>
      <Col lg={12}>
        <DrawDownTable currency={currency} data={seniorData} title="Senior Notes Draw Down"/>
      </Col>
      <Col lg={12}>
        <DrawDownTable currency={currency} data={juniorData} title="Junior Notes Draw Down"/>
      </Col>
    </Row>
  );
};

export default DrawdownLimits;