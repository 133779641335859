import React, { useState } from 'react';
//Components
import { Col, Pagination, Row, Select } from "antd";
import { MessagesContent, WriteMessage } from "../message";
//Helpers
import { isEmpty } from "../../../../helpers";
import { useGetMessages } from "../../apis";
import { STATUSES } from "../helpers";

const {Option} = Select;

export const ChatContent = (props: any) => {
  const {vehicleId, fetchTopics, topic, currentUserId} = props;

  const [selectStatus, setSelectStatus] = React.useState(undefined);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const messages = useGetMessages(page, pageSize, vehicleId, topic.id, selectStatus);

  const onChangeSelectStatus = (value: any) => {
    setSelectStatus(value);
  }

  const onPageSizeChange = (current: any, size: any) => {
    setPage(current)
    setPageSize(size)
  }

  const onChangePage = (page: any) => {
    setPage(page)
  }

  const statusSelect = () => (
    <div className="mt24">
      <Row className="mb8" align="middle" gutter={8}>
        <Col><span className="bolder">Filter by Status:</span></Col>
        <Col>
          <Select
            value={selectStatus}
            onChange={onChangeSelectStatus}
            placeholder="Select status"
            allowClear
            size="middle"
            style={{width: 200}}
          >
            {[STATUSES.INITIAL, STATUSES.ONGOING, STATUSES.RESOLVED].map((status: any) => (
              <Option style={{fontSize: 12}} key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <div className="bolder mb8"></div>
    </div>
  );

  if (isEmpty(messages.data))
    return (
      <>
        <div>No Messages</div>
        {statusSelect()}
      </>
    );

  return (
    <div>
      {statusSelect()}
      <MessagesContent
        currentUserId={currentUserId}
        vehicleId={vehicleId}
        topic={topic}
        messages={messages}
        fetchMessages={messages.fetch}
      />
      <Row align="middle" justify="end">
        <Pagination
          current={page}
          showSizeChanger
          defaultCurrent={1}
          pageSize={pageSize}
          onChange={onChangePage}
          total={messages?.data.count}
          onShowSizeChange={onPageSizeChange}
        />
      </Row>
      <WriteMessage
        fetchTopics={fetchTopics}
        fetchMessages={messages.fetch}
        vehicleId={vehicleId}
        topicId={topic.id}
      />
    </div>
  );
};
