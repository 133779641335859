import React from "react";
//Styled Components
import styled from "styled-components";
//Components
import { Tag } from "antd";
//Helpers
import { getActiveFilters, removeHiddenFields } from "../config";
//Custom Styles
const Styles = styled.div`
  max-width: 900px;
  overflow-x: auto !important;

  .ant-tag {
    padding: 4px;
    margin-bottom: 1px;
    margin-left: 2px;
  }
`;

interface Props {
  handleRemove: any,
  handleRemoveAll: any,
  hiddenFields: any,
  filterEntries: any,
  filters: any,
  options: any,
  entity?: any
}

const FilterTags = (props: Props) => {
  const {handleRemove, handleRemoveAll, hiddenFields, filterEntries, filters, options} = props;
  const formattedFilters = removeHiddenFields(filters, hiddenFields);
  const activeFilters = getActiveFilters(filterEntries, formattedFilters, options);
  const multipleFilters = activeFilters.length > 1;

  return (
    <Styles>
      {activeFilters.map((filter) => {
        const onClose = () => handleRemove(filter.identifier, true);
        return <Tag key={filter.label} closable color="blue" onClose={onClose}>{filter.label}</Tag>
      })}
      {multipleFilters && <Tag closable color="red" onClose={handleRemoveAll}>Remove All</Tag>}
    </Styles>
  )
};
export default FilterTags;

