import React from 'react';
//Other Libs
//Styles
import Styles from './styles';
//Components
import { Button, Col, Row } from "antd";
import { CustomTable, SimpleEmpty } from "@cardoai/components";
//Icons
import { FileTextOutlined } from "@ant-design/icons";
//Custom Helpers
import { downloadArrayOfObjectsAsCSV, formatters, isEmpty, Text, validateValue } from "../../../../helpers";
import { DownloadAsCSV } from "../../../../components";
import { getDelimiter } from "../../../npl_feature/helpers";

interface IDebtorTable {
  data: any,
  height: any,
  currency?: any
  displayRating?: any,
  queryStr?: string | undefined,
}

const Table = (props: IDebtorTable) => {
  const {data, displayRating, height, currency} = props;

  if (isEmpty(data))
    return <SimpleEmpty/>

  const handleDownload = (_: any, {decimalSeparator}: any) => {
    try {
      const columnDelimiter = getDelimiter(decimalSeparator)
      downloadArrayOfObjectsAsCSV(data, "Debtors", true, {
        columnDelimiter,
        decimalSeparator,
        canConvertDecimalSeparator: true
      });
    } catch (e) {
      console.error('export error', e);
    }
  }

  return (
    <Styles>

      <Row justify="space-between" align='middle'>
        <Col>
          <p className="bold mb16">Top 20 Debtors</p>
        </Col>
        <Col>
          <DownloadAsCSV disabled={!data.length} onDownload={handleDownload}>
            <Button
              size='small'
              icon={<FileTextOutlined/>}>Download as CSV</Button>
          </DownloadAsCSV>
        </Col>
      </Row>
      <CustomTable height={height} centerHeaders centerData>
        <thead>
        <tr>
          <th className="textLeft textWrap">Name / ID</th>
          <th>Region</th>
          <th>{Text("Current \n Balance")}</th>
          <th>Weight</th>
          <th>{Text("Weighted Average \n Loan Life (WALA)")}</th>
          <th>{Text("Weighted Average \n Maturity (WAM)")}</th>
          {displayRating && <th>{Text("Rating \n (Loan Rating)")}</th>}
          <th>WAR</th>
        </tr>
        </thead>
        <tbody>
        {data.map((entry: any, index: any) => (
            <tr key={index}>
              <td className="textLeft textWrap" title={entry.name}>{validateValue(entry.name)}</td>
              <td>{validateValue(entry.region)}</td>
              <td>{formatters.currency({value: entry.current_balance, currency, precision: 0})}</td>
              <td>{validateValue(entry.concentration, formatters.percent)}</td>
              <td>{validateValue(entry.wala, formatters.years)}</td>
              <td>{validateValue(entry.wam, formatters.years)}</td>
              {displayRating && <td>{validateValue(entry.rating)}</td>}
              <td>{validateValue(entry.wac, formatters.percent)}</td>
            </tr>
          )
        )}
        </tbody>
      </CustomTable>
    </Styles>
  )
};

Table.defaultProps = {
  displayRating: true,
  height: '100%'
};

export default Table;
