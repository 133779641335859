import React from 'react';
//Components
import { Button, Dropdown, Menu } from "antd";
//Custom Components
import ChooseRole from "./choose_role";
import PasswordChange from "./password_change";
//Icons
import { PoweroffOutlined, SettingOutlined, UserOutlined, UserSwitchOutlined } from "@ant-design/icons";
//Styles
import { userManagementStyle } from "./choose_role/styles";
//Config
import config from "./choose_role/config";
import { useAuthentication } from "../../../../utils/hooks";

const Header = ({groups, changeRole, firstName, role}: any) => {

  const [visibleRoleChange, setVisibleRoleChange] = React.useState(false);
  const [visiblePasswordChange, setVisiblePasswordChange] = React.useState(false);
  const authentication = useAuthentication();

  const shortenedRole: any = config.find(g => g.name === role);
  const availableRoles = config.filter(record => groups.hasOwnProperty(record.name));
  const hasOtherRoles = availableRoles && availableRoles.length > 1;

  //TODO: Luzzatti -> Remove later
  const isLuzzatti = ["Luzzati", "Luzzatti"].includes(firstName)

  const togglePasswordChangeVisibility = () => {
    setVisiblePasswordChange(prevState => !prevState)
  }

  const toggleRoleChangeVisibility = () => {
    setVisibleRoleChange(prevState => !prevState)
  }

  const userActionsMenu = (
    <Menu>
      {hasOtherRoles && <Menu.Item onClick={toggleRoleChangeVisibility} icon={<UserSwitchOutlined/>}>
        Change Role
      </Menu.Item>}
      <Menu.Item onClick={togglePasswordChangeVisibility} icon={<SettingOutlined/>}>
        Change Password
      </Menu.Item>
      <Menu.Item onClick={authentication.onLogout} icon={<PoweroffOutlined/>}>
        Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <div style={userManagementStyle}>
        <Dropdown placement="bottomLeft" overlay={userActionsMenu}>
          <Button shape="round"
                  icon={<UserOutlined/>}>Welcome, {firstName} {!isLuzzatti && `(${shortenedRole.title})`}</Button>
        </Dropdown>
      </div>
      <ChooseRole userRoles={availableRoles} role={role} changeRole={changeRole} visible={visibleRoleChange}
                  toggleVisibility={toggleRoleChangeVisibility}/>
      <PasswordChange onLogout={authentication.onLogout} visible={visiblePasswordChange}
                      toggleVisibility={togglePasswordChangeVisibility}/>
    </>
  );
};

export default Header;