import { theme } from "../../../../assets/theme/colors";

export const dataSetsConfig: any = [
  {
    label: "IRR - Upside Case",
    key: "upside",
    backgroundColor: theme.colors.blue,
  },
  {
    label: "IRR - Base Case",
    key: "base",
    backgroundColor: theme.colors.gold,
  },
  {
    label: "IRR - Downside Case",
    key: "downside",
    backgroundColor: theme.colors.grey,
  }
]
