//Other Libs
import numbro from "numbro";
import FormatText from "react-nl2br";
//Formatters
import { addDays, addPercent, formatDate, isNumber } from "@cardoai/helpers";
import { MathOp } from "./mathop";

export const Text = (str: any) => {
  return FormatText(str);
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/*Date Constants*/
export const dateFormat = "MMM D, YYYY";


function getFormatterType(value: any) {
  if (Math.abs(Number(value)) >= 1000000000)
    return "billion";

  if (Math.abs(Number(value)) >= 1000000)
    return "million";

  return "thousand";
}

/*Format Currencies*/
export function formatCurrency({
                                 value,
                                 precision = 2,
                                 symbol = '',
                                 currency = null,
                                 shortFormat = false
                               }: any) {
  if (!isNumber(value))
    return value;

  let format: any = {mantissa: precision};
  const formatterType = shortFormat ? getFormatterType(value) : 'separate';

  switch (formatterType) {
    case 'separate': {
      format.thousandSeparated = true;
      break;
    }
    case 'thousand':
    case 'million':
    case 'billion': {
      format.forceAverage = formatterType;
      break;
    }
  }

  let finalSymbol = symbol;

  if (!symbol) {
    if (currency && currency.hasOwnProperty("symbol"))
      finalSymbol = currency.symbol;
  }

  return `${finalSymbol}${numbro(value).format(format)}`;
}

export function formatThousands(value: any, precision = 2) {
  return isNumber(value) ? numbro(value).format({thousandSeparated: true, mantissa: precision}) : value
}

/*Format Percentage Values*/
export function formatPercent(value: any, precision = 2) {
  return isNumber(value) ? numbro(value).format({output: "percent", mantissa: precision}) : value
}

export function addYears(value: any, precision = 1) {
  return formatThousands(value, precision) + ' yrs';
}

export function addMonths(value: any, precision = 0) {
  return formatThousands(value, precision) + ' months';
}


export function formatMillions(value: any, precision?: number) {
  return `${MathOp.divide(value, 1000000, precision)}m`;
}

export function safeFormatDate(value: any) {
  if (typeof value !== 'string')
    return value;

  return formatDate(value);
}


export function formatStaticCurrency(value: any, precision = 0) {
  return isNumber(value) ? numbro(value).formatCurrency({thousandSeparated: true, mantissa: precision, currencySymbol:"€"}) : value
}

export function capitalize(value:any){
  return value.toUpperCase()
}
export const formatters: any = {
  capitalize:(value:any)=>capitalize(value),
  default: (value: any) => value,
  /*All Date Formatters*/
  date: (value: any) => formatDate(value),
  safeDate: safeFormatDate,
  shortedDate: (value: any) => formatDate(value, "MMM-YY"),
  days: (value: any) => addDays(value),
  months: (value: any) => addMonths(value),
  years: (value: any) => addYears(value),

  /*All Currency Formatters*/
  currency: (args: any) => formatCurrency(args),
  currencyNoPrecision: (value: any) => formatCurrency({value, precision: 0}),
  currencyShortedNoPrecision: (value: any) => formatCurrency({value, precision: 0, shortFormat: true}),

  /*All Value Formatters*/
  thousands: (value: any) => formatThousands(value),
  thousandsShorted: (value: any) => formatThousands(value, 0),
  staticThousandsCurrency: (value:any)=> formatStaticCurrency(value),

  /*All Percent Formatters*/
  percent: (value: any) => formatPercent(value),
  percentNoPrecision: (value: any) => formatPercent(value, 0),
  percentOnePrecision: (value: any) => formatPercent(value, 1),
  percentTwoPrecision: (value: any) => formatPercent(value, 2),
  addPercent: (value: any) => addPercent(value)
}
