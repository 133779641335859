import React from 'react';
//Constants
import { theme } from "../../../../../../assets/theme/colors";

const primaryColor = theme.colors.primary;

const withVehicleSelection = (Component: any) => (props: any) => {
  const {keyAttr, height, data, onChange, isActive, hideOverflow, ...rest}: any = props;

  const content = data.map((record: any) => {
    const currentKey = record[keyAttr];

    const active = isActive(currentKey);

    const handleClick = () => {
      onChange(currentKey)
    };

    const style = {
      borderLeftColor: 'transparent',
      transition: 'borderColor 1.5s ease',
      cursor: 'pointer',
      borderLeft: active ? `2px solid ${primaryColor}` : ""
    };

    const contentStyle: any = {
      marginTop: 20,
      paddingLeft: 12,
      fontWeight: active ? 500 : 400,
      color: active ? primaryColor : "",
    };

    return (
      <div style={style} key={currentKey} onClick={handleClick}>
        <div style={contentStyle}>
          <Component data={record}{...rest}/>
        </div>
      </div>
    )
  });

  const containerStyle: any = {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: height || 450,
    borderLeft: '1px solid #eee'
  };

  return (
    <div style={containerStyle}>
      {content}
    </div>
  )
}

export default withVehicleSelection;
