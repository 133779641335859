import React from "react";
//Other Libs
import cx from "classnames";
//Components
import LimitsWaiver from "../../limits_waiver";
//Components
import { Button, Col, notification, Popover, Row } from "antd";
import { LimitsTable } from "../../../../../../../../components";
import { InfoTag, Panel, SimpleEmpty } from "@cardoai/components";
//Custom Style
import { Table } from "../../styles";
import { theme } from "../../../../../../../../assets/theme/colors";
//Icons
import { FileExcelOutlined, InfoCircleFilled } from "@ant-design/icons";
//Constants
import { actionKey } from "../../../../../../../../constants";
//Helpers
import InformationWaiver from "./information_waiver";
import { formatVehicleLimits, getLimitColor, getPreWaiverLimitColor, showThresholdType, } from "../../helpers";
import { downloadClientFile, formatters, isEmpty, validateValue, } from "../../../../../../../../helpers";

interface Props {
  vehicle: any;
  vehicleId: any;
  data: any;
  height?: any;
  currency?: any;
}

const LimitsDefaultTable = (props: Props) => {
  const {vehicle, vehicleId, height, data, currency} = props;

  const [loading, setLoading] = React.useState<boolean>(false);

  const vehicleName: any = vehicle.vehicle_name;

  const {functionalities_config} = vehicle;
  const waiverDetail =
    functionalities_config.hasOwnProperty(actionKey.showDetailsWaiver) &&
    functionalities_config[actionKey.showDetailsWaiver] === true;

  const {allLimits, referenceDate} = data;

  const handleModal = (name: any, description: any) => () => {
    notification["info"]({
      message: name,
      description: description,
      duration: 30,
      placement: "bottomLeft",
      style: {
        width: 600,
      },
    });
  };

  const downloadExcel = () => {
    const url = `/main/limit/download_limits/?vehicle_id=${vehicleId}`;
    downloadClientFile({
      url: url,
      filename: `${vehicleName}-Limits.xls`,
      setLoading,
    });
  };

  if (isEmpty(allLimits)) return <SimpleEmpty/>;

  let limitsView = allLimits.map((limits: any, index: number) => {
    const group = limits.group;
    const records = limits.records;
    const category = limits.category;

    const content = (
      <LimitsTable height={height} sizeSmall>
        <Table className="fullWidth">
          <thead>
          <tr>
            <th/>
            <th className="textCenter" style={{width: 200}}>
              Threshold Type
            </th>
            <th className="textCenter" style={{width: 200}}>
              Threshold
            </th>
            {waiverDetail && (
              <th className="textCenter" style={{width: 90}}>
                Waiver Info
              </th>
            )}
            {waiverDetail && (
              <th className="textCenter" style={{width: 200}}>
                Details
              </th>
            )}
            <th className="textCenter" style={{width: 200}}>
              Calculated
            </th>
            <th className="textCenter" style={{width: 200}}>
              Notes
            </th>
          </tr>
          </thead>
          <tbody>
          {records.map((entry: any, index: number) => {
            const {
              broken,
              name,
              fulfillment,
              fulfillment_percent,
              value,
              value_percent,
              condition,
              formatter,
              notes,
              description,
              id,
              active_waiver,
            } = entry;
            const currencyLimits = entry.currency === null ? currency : entry.currency
            const {
              id: Id,
            } = active_waiver;

            const timeExtensionWaiver = active_waiver.waiver_type === "Suspension"

            let limitName;

            if (!description) limitName = name;
            else
              limitName = (
                <InfoTag showInfo onClick={handleModal(name, description)}>
                  {name}:
                </InfoTag>
              );

            const colorPreWaiverLimit:boolean = active_waiver.original_broken_flag

           const fulfillmentClassLimit = getPreWaiverLimitColor(
             colorPreWaiverLimit
           )

            const fulfillmentClass = getLimitColor(
              broken,
              timeExtensionWaiver
            );

            const isActiveWaiverEmpty = Object.keys(active_waiver).length === 0;

            return (
              <tr key={index}>
                <td>{limitName}</td>
                <td className="textCenter">{showThresholdType(condition)}</td>
                <td className="primary textCenter">
                  {formatVehicleLimits(
                    value,
                    value_percent,
                    formatter,
                    currencyLimits
                  )}
                </td>
                {waiverDetail &&
                (isActiveWaiverEmpty ? (
                  <td className="textCenter">
                    <Button disabled icon={<InfoCircleFilled/>}/>
                  </td>
                ) : (
                  <td className="textCenter">
                    <Popover  overlayStyle={{
                      width: 450
                    }}
                      content={<InformationWaiver active={active_waiver} fulfillment={fulfillment} fulfillmentClassLimit={fulfillmentClassLimit} fulfillment_percent={fulfillment_percent} formatter={formatter} currencyLimits={currencyLimits}/>}
                    >
                      <Button
                        icon={
                          <InfoCircleFilled
                            style={{color: theme.colors.dogerblue}}
                          />
                        }
                      />
                    </Popover>
                  </td>
                ))}
                {waiverDetail && (
                  <td className="textCenter">
                    <LimitsWaiver
                      vehicleId={vehicleId}
                      id={id}
                      formatter={formatter}
                      timeExtensionWaiver={timeExtensionWaiver}
                      fulfillment_percent={fulfillment_percent}
                      currencyLimits={currencyLimits}
                    />
                  </td>
                )}
                <td className={cx("textCenter", fulfillmentClass)}>
                  {formatVehicleLimits(
                    fulfillment,
                    fulfillment_percent,
                    formatter,
                    currencyLimits
                  )}
                </td>
                <td className="textCenter" style={{width: 120}}>
                  {validateValue(notes, null)}
                </td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </LimitsTable>
    );

    if (!group) return <Col>{content}</Col>;

    return (
      <Col key={String(index)}>
        <Panel extraSpace defaultOpen header={category}>
          {content}
        </Panel>
      </Col>
    );
  });

  return (
    <>
      <Row gutter={8} justify="space-between">
        <Col>
          <span className="bold">
            Reference date: {validateValue(referenceDate, formatters.date)}
          </span>
        </Col>
        <Col>
          <Button
            size="small"
            loading={loading}
            icon={<FileExcelOutlined/>}
            onClick={downloadExcel}
          >
            Download as Excel
          </Button>
        </Col>
      </Row>
      <Row style={{flexDirection: "column"}}>{limitsView}</Row>
    </>
  );
};

export default LimitsDefaultTable;
