import React from 'react';
//Components
import { Col, Row } from "antd"
import { SPVFilters, VDRFilters } from "../../../containers/loans/components/header_filters";
//Helpers
import { actionKey } from "../../../constants";
import { useAuthorization } from "../../../utils/hooks";

const ExtraFilters = (props: any) => {
  const {
    getVehiclesValue,
    handleVehiclesChange,
    createVehicleOptions,
    getPoolAdditionDate,
    handlePoolAdditionDateChange,
    lenders,
    handleInvestmentDateChange,
    getInvestmentDate,
    handleLendersChange,
    getLendersValue
  } = props;

  const {canPerformAction}: any = useAuthorization();

  return (
    <>
      <Col>
        <Row gutter={8}>
          {canPerformAction(actionKey.showSPVFilters) &&
            <SPVFilters
              filterAttr='selectedVehicles'
              getVehiclesValue={getVehiclesValue}
              handleVehiclesChange={handleVehiclesChange}
              createVehicleOptions={createVehicleOptions}
              getPoolAdditionDate={getPoolAdditionDate}
              handlePoolAdditionDateChange={handlePoolAdditionDateChange}
            />}
          {canPerformAction(actionKey.showVDRFilters) && lenders &&
            <VDRFilters
              lenders={lenders}
              filterAttr='selectedLenders'
              handleInvestmentDateChange={handleInvestmentDateChange}
              getInvestmentDate={getInvestmentDate}
              handleLenderChange={handleLendersChange}
              getLendersValue={getLendersValue}
            />}
        </Row>
      </Col>
    </>
  );
};

export default ExtraFilters;