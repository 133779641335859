import React from 'react';
//Components
import {Row, Col, Input, Button} from "antd";

const Apply = ({onSubmit, onCancel,loading}: any) => {
    const [value, setValue] = React.useState<any>(null);
    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Input.TextArea value={value} onChange={(e: any) => setValue(e.target.value)}/>
            </Col>
            <Col xs={24}>
                <Row justify="end" gutter={8}>
                    <Col>
                        <Button onClick={onCancel}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            disabled={!value || value === ''}
                            onClick={() => {
                                onSubmit({
                                    reason: value
                                })
                            }}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Apply;
