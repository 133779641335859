import { formatters, Text } from "../../../../helpers";

export const tableRealEstateTheta: any = [
  {
    label: Text("Property ID"),
    key: "real_estate_id",
  },
  {
    label: Text("Property ID (M)"),
    key: "transaction_id",
    width:150
  },
  {
    label: Text("Sqm"),
    key: "net_square_metres",
    width:100
  },
  {
    label: Text("Sqm (M)"),
    key: "sqm_mother",
    width:100
  },
  {
    label: Text("City"),
    key: "city",
    width:100
  },
  {
    label: Text("Country"),
    key: "country",
    width:100
  },
  {
    label: Text("Deed Type"),
    key: "deed_type",
    width: 150,
  },
  {
    label: Text("Status"),
    key: "asset_status",
    width:100
  },
  {
    label: Text("# Units"),
    key: "number_of_units",
    width:100
  },
  {
    label: Text("Total Purchase Price"),
    key: "purchase_price",
    format: formatters.thousands,
    width:200
  },
  {
    label: Text("Acquisition Date"),
    key: "acquisition_date",
    format: formatters.date,
    width: 150,
  },
  {
    label: Text("Expected Sale Price"),
    key: "expected_sale_price",
    width: 150,
    format: formatters.thousands,
  },
  {
    label: Text("Total Expected Sale Price"),
    key: "total_expected_sale_price",
    width: 200,
    format: formatters.thousands
  },
  {
    label: Text("Due Diligence Price"),
    key: "original_valuation_amount",
    format: formatters.thousands,
    width: 150,
  },
  {
    label: Text("Total Renovation Costs"),
    key: "total_renovation_costs",
    format: formatters.thousands,
    width: 180,
  },
  {
    label: Text("Initial Renovation Budget (Net)"),
    key: "renovation_budget_net",
    format: formatters.thousands,
    width: 220,
  },
  {
    label: Text("Deal Type"),
    key: "deal_type",
    width: 100,
  },
  {
    label: Text("Cadastral Category"),
    key: "cadastral_category",
    width:150
  },
  {
    label: Text("Municipality"),
    key: "municipality",
    width:150
  },
  {
    label: Text("Metropolitan Area"),
    key: "metropolitan_area",
    width:150
  },
  {
    label: Text("Seller is Natural Person"),
    key: "seller_is_natural_person",
    width:200
  },
  {
    label: Text("Renovation Start Date"),
    key: "renovation_start_date",
    format: formatters.date,
    width:200
  },
  {
    label: Text("Renovation End Date"),
    key: "renovation_end_date",
    format: formatters.date,
    width:200
  },
  {
    label: Text("Commercialization Start Date"),
    key: "commercialization_start_date",
    format: formatters.date,
    width:220

  },
  {
    label: Text("Deadline Notarization"),
    key: "deadline_notarization_date",
    width:200,
    format: formatters.date
  },
  {
    label: Text("Notarization Date"),
    key: "notarization_date",
    format: formatters.date,
    width:200,
  },
  {
    label: Text("BO Accepted Date"),
    key: "sale_date",
    format: formatters.date,
    width: 150
  },
  {
    label: Text("Sale Price BO"),
    key: "sale_price",
    format: formatters.thousands,
    width:200,
  },
  {
    label: Text("Zip Code"),
    key: "zip_code",
    format: formatters.thousands,
    width: 150
  },
  {
    label: Text("Payment Method"),
    key: "payment_method",
    format: formatters.thousands,
    width: 150
  },


];

export const tableCashFlowTheta: any = [
  {
    label: "Real Estate ID",
    key: "real_estate_id",
    align: "center",
    width:100,
  },
  {
    label: "Transaction ID",
    key: "transaction_id",
    width:120,
  },
  {
    label: "Cash Flow Type",
    key: "cash_flow_type",
    width:150,
  },
  {
    label: "Cash Flow Amount",
    key: "payment_amount",
    format: formatters.thousands,
    width:150,
  },
  {
    label: "Cash Flow Currency",
    key: "payment_currency",
    width:140,
  },
  {
    label: "Cash Flow Date",
    key: "payment_date",
    format: formatters.date,
    width:140,
  },
  {
    label: "Payment Account",
    key: "payment_account",
    width:140,
  },
];

export const tableLoanByLoanSectionTheta :any = [
  {
    label: Text("Property ID"),
    key: "property_id",
    sortingName: "real_estate_id",
    width:150,
  },
  {
    label: Text("Property ID(M)"),
    key: "property_id_m",
    sortingName: "transaction_id",
    width:150,
  },
  {
    label: Text("Deal Type"),
    key: "deal_type",
    width: 100,
  },
  {
    label: Text("# Units"),
    key: "nr_units",
    width: 100,
  },
  {
    label: Text("Municipality"),
    key: "municipality",
    width: 100
  },
  {
    label: Text("Metropolitan Area"),
    key: "metropolitan_area",
    width: 150
  },
  {
    label: Text("SQM"),
    key: "sqm",
    format: formatters.thousands,
    width: 100
  },
  {
    label: Text("SQM (M)"),
    key: "sqm_m",
    format: formatters.thousands,
    width: 100,
  },
  {
    label: Text("Status"),
    key: "status",
    format: formatters.thousands,
    width: 100,
  },
  {
    label: Text("Tab"),
    key: "tab",
    format: formatters.thousands,
    width: 100,
  },
  {
    label: Text("Purchase Price"),
    key: "purchase_price",
    format: formatters.thousands,
    width: 140,
  },
  {
    label: Text("PP / DD"),
    key: "purchase_price_dd_price",
    format: formatters.thousands,
    width: 100,
  },
  {
    label: Text("Initial CAPEX / DD"),
    key: "initial_capex_dd_ratio",
    format: formatters.thousands,
    width: 140,
  },
  {
    label: Text("Total Borrowing Base"),
    key: "total_borrowing_base",
    format: formatters.thousands,
    width: 160,
  },
  {
    label: Text("New Borrowing Base"),
    key: "new_borrowing_base",
    format: formatters.thousands,
    width: 160,
  },
  {
    label: Text("Total Running BB Released"),
    key: "total_running_bb_released",
    format: formatters.thousands,
    width: 200,
  },
  {
    label: Text("Outstanding Borrowing Base"),
    key: "outstanding_borrowing_base",
    format: formatters.thousands,
    width: 200,
  },
  {
    label: Text("Current LTC Senior"),
    key: "current_ltc_senior",
    format: formatters.thousands,
    width: 150,
  },
  {
    label: Text("Current LTC Mezzanine"),
    key: "current_ltc_mezzanine",
    format: formatters.thousands,
    width: 180,
  },
  {
    label: Text("LTC Senior"),
    key: "ltc_senior",
    format: formatters.thousands,
    width: 140,
  },
  {
    label: Text("LTC Mezzanine"),
    key: "ltc_mezzanine",
    format: formatters.thousands,
    width: 140,
  },
  {
    label: Text("Total Senior Borrowing Base"),
    key: "total_senior_borrowing_base",
    format: formatters.thousands,
    width: 200,
  },
  {
    label: Text("Total Mezzanine Borrowing Base"),
    key: "total_mezzanine_borrowing_base",
    format: formatters.thousands,
    width: 240,
  },
  {
    label: Text("Outstanding Senior Borrowing Base"),
    key: "senior_outstanding",
    format: formatters.thousands,
    width: 250,
  },
  {
    label: Text("Outstanding Mezzanine Borrowing Base"),
    key: "outstanding_mezzanine_borrowing_base",
    format: formatters.thousands,
    width: 270,
  },
]

