import React, { useEffect, useState } from 'react';
//Other Libraries
import produce from "immer";
// Components
import { Col, Row, Slider } from "antd";
//Custom Components
import { NumberInput } from "@cardoai/components";
//Custom Helpers
import { addDays, addPercent, debounce, isEmpty, MathOp, sortRecords } from "../../../helpers";

/*Constants*/

const extremums: any = {
  0: "min",
  1: "max"
};

const operations = {
  multiply: function (value: any) {
    return MathOp.multiply(value, 100)
  },
  divide: function (value: any) {
    return MathOp.divide(value, 100)
  }
}


interface IScale {
  entity: string,
  axis: string | number,
  format: string,
  beginZero: boolean,
  axisLabel: string
}

interface ILimits {
  x?: any[],
  y?: any[],
}

export default (scaleConfig: IScale[]) => (Chart: React.FC<any>) => (props: any) => {
  const {data} = props;

  const [values, setValues] = useState<ILimits | any>({x: [], y: []});
  const [limits, setLimits] = useState<ILimits | any>({x: [], y: []});

  useEffect(() => {
    initializeLimits();
  }, [data]);

  useEffect(() => {
    setValues(limits);
  }, [limits]);


  const validate = (value: any) => {
    return value !== undefined && value !== null;
  }

  const prepareValue = (value: any, format: any, type: any) => {
    const valid = validate(value);

    if (!valid)
      return value;

    if (format === 'percent')
      value = operations.multiply(value);

    if (type === "floor")
      return Math.floor(value)

    if (type === "ceil")
      return Math.ceil(value);
  }


  const prepareRecords = (entity: any) => {
    return data.sort(sortRecords(entity, false));
  }


  const initializeLimits = () => {
    const updates: any = {};

    if (isEmpty(scaleConfig))
      return;

    scaleConfig.forEach((scale: IScale) => {
      const {entity, axis, format, beginZero} = scale;

      const records = prepareRecords(entity);

      const getValueByIndex = (index: any) => {
        if (index < records.length)
          return records[index][entity];
        return undefined;
      }

      let min = beginZero ? 0 : getValueByIndex(0), max = getValueByIndex(0);

      const lastIndex = records.length - 1;

      if (lastIndex > 0)
        max = getValueByIndex(lastIndex);

      updates[axis] = [
        prepareValue(min, format, 'floor'),
        prepareValue(max, format, 'ceil')
      ];

    })

    return setLimits(updates);
  };

  const getValue: any = (axis: any, type: string = "value", index: number) => {

    if (!axis)
      return undefined;

    if (index === undefined)
      return values[axis];

    switch (type) {
      case "value":
        return values[axis][index];
      case "limit":
        return limits[axis][index];
      default:
        break;
    }
  }


  const getLimits = () => {
    const limits: any = {};
    scaleConfig.forEach(({axis, format}: IScale) => {
        limits[axis] = {};
        for (let index in extremums) {
          const extremum = extremums[index];
          let value = getValue(axis, "value", index);
          if (format === 'percent')
            value = operations.divide(value);
          limits[axis][extremum] = value;
        }
      }
    )
    return limits
  }

  const handleInputChange = (axis: any, index: any) => debounce(250, (value: any) => {
    setValues(produce(values, (draft: any) => {
      const axisValues = draft[axis];
      let valid = true;
      const limit = getValue(axis, "limit", index);

      const extremum = extremums[index];

      if (extremum === "min") {
        const max = axisValues[index + 1];
        if ((value > max) || value < limit)
          valid = false;
      }

      if (extremum === "max") {
        const min = axisValues[index - 1];
        if ((value < min) || (value > limit))
          valid = false;
      }

      if (valid)
        axisValues[index] = value;
    }));
  })

  const handleRangeChange = (axis: any) => (event: any) => {
    setValues((previousValue: any) => {
      return {
        ...previousValue,
        [axis]: event
      }
    });
  }

  const tipFormatter = (format: any) => (event: any) => {
    switch (format) {
      case 'percent':
        return addPercent(event);
      case 'days':
        return addDays(event)
      default:
        break;
    }
    return null;
  }

  const generateVerticalScale = (scale: any) => {
    const {axis, format, axisLabel} = scale;

    const normalize = format !== "percent";

    return (
      <Row style={{flexFlow: "row nowrap"}}>
        <Col>
          <Row
            justify="space-between"
            align="middle"
            className="columnDirection"
            style={{
              height: props.height
            }}>
            <Col>
              <NumberInput
                width={70}
                type={format}
                normalizeValue={normalize}
                onChange={handleInputChange(axis, 1)}
                value={getValue(axis, 'value', 1)}/>
            </Col>
            <Col flex="auto">
              <div style={{transform: "rotate(-90deg)"}}
                   className="flexCenter bolder primary">
                {axisLabel}
              </div>
            </Col>
            <Col style={{paddingBottom: 18}}>
              <NumberInput
                width={70}
                type={format}
                normalizeValue={normalize}
                onChange={handleInputChange(axis, 0)}
                value={getValue(axis, 'value', 0)}/>
            </Col>
          </Row>
        </Col>
        <Col style={{height: props.height, paddingBottom: 30}}>
          <Slider
            range
            vertical
            step={0.1}
            className="fullWidth"
            value={getValue(axis)}
            onChange={handleRangeChange(axis)}
            tipFormatter={tipFormatter(format)}
            min={getValue(axis, "limit", 0)}
            max={getValue(axis, "limit", 1)}/>
        </Col>
      </Row>
    )
  }

  const generateHorizontalScale = (scale: any) => {
    const {axis, format, axisLabel} = scale;
    const normalize = format !== 'percent';
    return (
      <section className="mt16" style={{paddingLeft: 45, paddingRight: 15}}>
        <Slider
          range
          step={0.1}
          className="fullWidth"
          value={getValue(axis)}
          onChange={handleRangeChange(axis)}
          tipFormatter={tipFormatter(format)}
          min={getValue(axis, "limit", 0)}
          max={getValue(axis, "limit", 1)}/>
        <Row justify="space-between" align="middle">
          <Col>
            <NumberInput
              width={70}
              type={format}
              normalizeValue={normalize}
              onChange={handleInputChange(axis, 0)}
              value={getValue(axis, 'value', 0)}/>
          </Col>
          <Col flex="auto">
            <div className="textCenter bolder primary">
              {axisLabel}
            </div>
          </Col>
          <Col>
            <NumberInput
              width={70}
              type={format}
              normalizeValue={normalize}
              onChange={handleInputChange(axis, 1)}
              value={getValue(axis, 'value', 1)}/>
          </Col>
        </Row>
      </section>
    )
  }

  const [horizontalScale, verticalScale] = scaleConfig;

  return (
    <Row style={{flexFlow: "row nowrap"}}>
      {verticalScale && <Col xs={2}>
        <Row justify="center">
          <Col>
            {generateVerticalScale(verticalScale)}
          </Col>
        </Row>
      </Col>}
      <Col xs={verticalScale ? 22 : 24}>
        <Chart {...props} limits={getLimits()}/>
        {horizontalScale && generateHorizontalScale(horizontalScale)}
      </Col>
    </Row>
  )
};
