import React from 'react';
//Components
import { Button, message } from "antd";
//Icons
import { FileImageOutlined } from "@ant-design/icons";
//Other libraries
import html2canvas from 'html2canvas';
import { useTheme } from "styled-components";
//Styles
import Styles from "./styles";
//Constants
import notifications from "../../../components/notifications";
import { theme } from "../../../assets/theme/colors";

//Constants
const downloadButtonStyle = {
  position: 'absolute',
  top: -6,
  zIndex: 10,
  backgroundColor: theme.colors.white
}

const withDownload = (Content: any) => (config: any) => (props: any) => {
  const {
    loading, downloadVersionConfig, hiddenDownloadItems = ['header', 'ant-tooltip-inner'],
    changeDownloadContent = false, downloadPosition = 'right', downloadText = "Snapshot",
    downloadOnFocus = false
  } = props;

  const theme: any = useTheme();
  const reference: any = React.createRef();

  const [visible, setVisible] = React.useState(!downloadOnFocus);
  const [visibleMenu, setVisibleMenu] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);

  React.useEffect(() => {
    if (downloadOnFocus && visible)
      processDownload();
  }, [visible]);

  const downloadStyle: any = {};

  const disableSnapshot = () => {
    return !!(theme.darkMode && config.disableSnapshotOnDarkMode);
  }

  if (downloadVersionConfig && changeDownloadContent) {
    downloadStyle.width = downloadVersionConfig.width
    downloadStyle.height = downloadVersionConfig.height
  }

  const startDownload = () => {
    setDownloading(true);
    message.info("Download in progress. Please wait...")
  }

  const processDownload = () => {
    const node = reference.current;
    startDownload();
    setTimeout(() => {
      html2canvas(node, {
        ...downloadStyle,
        ignoreElements: (element) => {
          return hiddenDownloadItems.includes(element.className)
        }
      }).then(function (canvas) {
        toggleVisibility();
        const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const a = document.createElement("a");
        a.setAttribute("download", `Screenshot.png`);
        a.setAttribute("href", image);
        a.click();
        setDownloading(false);
        notifications.success("File has been downloaded");
      }).catch(error => {
        notifications.error("Unable to Capture Content.")
      }).finally(() => {
        setDownloading(false);
      });
    }, 700)
  }


  const toggleVisibility = () => {
    if (downloadOnFocus)
      setVisible(previous => !previous);
  }

  const handleMouseEnter = () => {
    if (downloadOnFocus && !visibleMenu)
      setVisibleMenu(true)
  }

  const handleMouseLeave = () => {
    if (downloadOnFocus && visibleMenu)
      setVisibleMenu(false)
  }


  const onDownload = () => {
    if (downloadOnFocus)
      toggleVisibility();
    else
      processDownload();
  };

  const getDownloadStyle = () => {
    const styles: any = Object.assign({}, downloadButtonStyle);
    if (downloadPosition === 'left')
      styles.left = 0;
    else if (downloadPosition === 'right')
      styles.right = 0;
    return styles;
  };

  const contentsProps: any = {
    current: {
      className: 'fullWidth',
      style: {paddingTop: 24}
    },
    hidden: {
      style: downloadStyle,
    }
  }

  if (changeDownloadContent)
    contentsProps.hidden.ref = reference;
  else
    contentsProps.current.ref = reference;

  let visibleDownload = true;

  if (downloadOnFocus)
    visibleDownload = !loading && visibleMenu;

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="relativePosition">
      {visibleDownload && (
        <div style={getDownloadStyle()}>
          <Button disabled={disableSnapshot()} loading={downloading} onClick={onDownload} icon={<FileImageOutlined/>}
                  size="small">
            {downloadText}
          </Button>
        </div>
      )}

      <div {...contentsProps.current} >
        <Content {...props}/>
      </div>

      {visible && changeDownloadContent && <Styles  {...contentsProps.hidden} id="far-far-away">
        <Content
          {...props}
          downloadVersion
          downloadVersionConfig={downloadVersionConfig}/>
      </Styles>}
    </div>
  )
};

export default withDownload;