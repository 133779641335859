import React from 'react';
//Constants
import {columns} from "./config";
//Utils
import {useQuery} from "../../helpers";
//Components
import SmartTable from "./design/table"
import Actions from "./components/actions";

const FundingManager = ({vehicleId}: any) => {
    const {data, fetch} = useQuery({url: `/main/notes_manager/?vehicle_id=${vehicleId}`});
    return (
        <SmartTable
            loading={!data}
            columns={[
                ...columns,
                {
                    key: "actions",
                    label: "Actions",
                    render: (_: any, record: any) => <Actions fetchData={fetch} vehicleId={vehicleId} record={record} data={data}/>
                }
            ]}
            dataSource={data}
            vehicleId={vehicleId}
        />
    )
};

export default FundingManager;

FundingManager.defaultProps = {
    vehicleId: 114
}
