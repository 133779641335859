import React from 'react';
//Custom Components
import { CustomImage, DefinitionList, MessageBox } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const DebtorDetails = () => {
  return (
    <div>
      <DefinitionList
        header="At the top it shows some general statistics for the debtor:"
        data={[
          {
            term: 'Current Balance',
            explanation: 'total balance of the outstanding loans of the debtor'
          },
          {
            term: 'Total Loans #',
            explanation: 'total number of loans of the debtor (outstanding and closed)'
          },
          {
            term: 'Loans Outstanding #',
            explanation: 'total number of loans outstanding of the debtor'
          },
          {
            term: 'Loans Repaid #',
            explanation: 'total number of loans of the debtor that have been repaid'
          },
          {
            term: 'Average Delay',
            explanation: 'average delinquency days of the debtor'
          },
          {
            term: 'Average SPV Delay',
            explanation: 'average delinquency days of the SPV where the loans are (in case of loans of the same ' +
              'debtor in different SPVs the average is referred to the aggregated average of the SPVs)'
          },
          {
            term: 'Average Region Delay',
            explanation: 'average delinquency days of the Region of the debtor'
          },
          {
            term: 'Average sector Delay',
            explanation: 'average delinquency days of the sector of the debtor'
          }
        ]}/>

      <CustomImage
        description="Then there is a graph of timely evolution of the performance (in terms of delinquency) of all
          the loans of the borrowers and the list of them."
        src={assets.images.loansModule.debtor}
        alt="Loans Debtor"
      />

      <MessageBox
        title="Debtor Information"
        description="At the bottom of the screen users can see all the loans of the borrower that are
        included in the statistics and graph above."/>
    </div>
  );
};

export default DebtorDetails;
