//Helpers
import { formatters } from "../../../../helpers";

export const config = [
  {key: "originator", label: "Originator", align: "center",width: 150,grouping:true},
  {key: "ndg", label: "NDG", align: "center"},
  {key: "borrower_name", width: 350, label: "Borrower Name", grouping:true},
  {key: "collected_amount", label: "Collected Amount", format: formatters.thousands},
  {key: "collection_date", label: "Collection Date", format: formatters.safeDate,  grouping:true},
  {key: "id_loan", label: "ID Loan", grouping:true},
  {key: "interim_cf", label: "Interim cf (Y/N)", grouping:true},
  {key: 'notes', label: 'Notes', width: 150},
];
