import React from 'react';

const Help = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00024 16.5C13.1424 16.5 16.5002 13.1421 16.5002 9C16.5002 4.85786 13.1424 1.5 9.00024 1.5C4.85811 1.5 1.50024 4.85786 1.50024 9C1.50024 13.1421 4.85811 16.5 9.00024 16.5Z" stroke="#736974" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.37402 8.375H8.99902V12.75H9.62402" stroke="#736974" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.84399 6.5C9.36176 6.5 9.78149 6.08027 9.78149 5.5625C9.78149 5.04473 9.36176 4.625 8.84399 4.625C8.32623 4.625 7.90649 5.04473 7.90649 5.5625C7.90649 6.08027 8.32623 6.5 8.84399 6.5Z" fill="#736974"/>
        </svg>
    );
};

export default Help;