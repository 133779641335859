import React from 'react';
import Filters from "../../../../../../../../design/filters";

const More = ({groups, setGroups, filters, updateFilters, onClose,selected, ...rest}: any) => {
    return (
        <Filters
            onChange={(event: any) => {
                onClose();
                updateFilters(event);
            }}
            config={[
                {
                    type: 'select',
                    multiple: true,
                    options: [
                        "Junior Bridge",
                        "Expected Acquisition",
                        "Actual Acquisition",
                        "Not Purchased",
                        "Residual"
                    ],
                    key: "acquisition_status",
                    label: "Acquisition  Status",
                    defaultValue: filters.acqusition_status
                },
                {
                    type: 'boolean',
                    key: "eligibility_breach",
                    label: "Ineligible Assets",
                    defaultValue: filters.eligibility_breach
                },
                {
                    type: 'boolean',
                    key: "waivered_breach",
                    label: "Waivered Assets",
                    defaultValue: filters.waivered_breach
                }
            ]}/>
    );
};

export default More;
