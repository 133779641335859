import React from 'react';
//Components
import { NestedPie } from "../../../../../components";
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { isEmpty } from "../../../../../helpers";
//Configurations
import { prepareDoughnutData, preparePieData } from "./config";

interface INestedNotesChart {
  data: any,
  parent: any
}

const NestedNotesChart = (props: INestedNotesChart) => {
  const {data, parent} = props;


  if (isEmpty(data))
    return <SimpleEmpty/>


  const parentWidth = parent?.current?.offsetWidth || 500;

  const NotesPie = preparePieData(data)
  const NotesDoughnut = prepareDoughnutData(data);

  return (
    <NestedPie
      pie={{
        dimension: 200,
        config: NotesPie
      }}
      doughnuts={{
        dimension: 260,
        spacing: 64,
        config: [
          NotesDoughnut
        ],
      }}
      displayPieLabels
      displayDoughnutLabels
      innerRadiusWidth={0.22}
      parentWidth={parentWidth}/>
  );
};

export default NestedNotesChart;
