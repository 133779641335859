import React from "react";
//Components
import { Card, Col, Row } from "antd";
//Configurations
import { SYNC_SELECTION } from "../../../configurations";
//Constants
//Other Libs
import { useAuthorization } from "../../../../../../utils/hooks";

const Meta = Card.Meta;

interface Props {
  vehicleId?: any,
  vehicle?: any,
  onTypeChange: (param?: any) => any
}

const SyncSelection = (props: Props) => {
  const {vehicle, vehicleId, onTypeChange} = props;


  const authorization: any = useAuthorization();
  const {functionalities_config} = vehicle;

  return (
    <div className="fullHeight">
      <Row justify="space-around" align="middle" gutter={[6, 16]}>
        {SYNC_SELECTION.map((type: any) => {
          const {key, permissionKey, description, icon, label} = type;
          let cardContent = null;

          const isAllowedByUser = authorization.canPerformAction(`create_${permissionKey}`, vehicleId);
          const isAllowedByVehicle = functionalities_config[`supports_${permissionKey}`]

          const displaySelection = !!(isAllowedByUser && isAllowedByVehicle);

          if (displaySelection) {
            cardContent = <Col key={key} span={12}>
              <Card
                bordered
                hoverable
                className='pointerCursor'
                style={{backgroundColor: '#fff'}}
                onClick={onTypeChange(key)}
                key={key}>
                <Meta

                  avatar={icon}
                  title={label}
                  description={description}/>
              </Card>
            </Col>
          }

          return (
            cardContent
          )
        })}
      </Row>
    </div>
  )
};

export default SyncSelection
