import React from "react"
//Components
import Loans from "../../../../../loans";

const NestedLoans = (props: any) => {
  const custom = {
    wrapContent: false,
    displayStatistics: false,
    hiddenFields: ["selectedVehicles"],
    filterFields: {displayVehicleFilter: false}
  };

  return (
    <Loans {...props} {...custom}/>
  )
}

export default NestedLoans;
