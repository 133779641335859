let Cache: any = {
  api: {}
};

export function clearViewsCache() {
  Cache.api = {}
}

export function verifyCache(key: any) {
  return Cache.api[key] && Cache.api[key].records;
}

export function clearItem(key: any) {
  delete Cache.api[key];
}

export function initializeCache(key?: any, records?: any) {
  Cache.api[key] = {
    records: records,
    duration: {
      h: 0,
      m: 30,
      s: 0
    },
    lastUpdated: Date.now()
  }}

export default Cache;