import React, { useEffect, useState } from 'react';
//Components
import { Tabs } from "@cardoai/components";
//Helpers
import { Analytics } from "../../../../helpers";
import config from "./config";

interface IDiversification {
  sectors?: any,
  countries?: any,
  vehicleId?: any,
  currency?: any
  redirectionRule?: any,
  redirectionSources?: any,
  selectedLender?: any,
}

const Diversification = (props: IDiversification) => {

  const {redirectionRule, redirectionSources, sectors, countries, vehicleId, currency, selectedLender} = props;

  const [tabKey, setTabKey] = useState<any>("breakdown");

  useEffect(() => {
    if (redirectionRule && redirectionRule.tabKey)
      setTabKey(redirectionRule.tabKey)
  }, [redirectionRule])


  const onTabChange = (key: any) => {
    Analytics.dispatchEvent({
      category: 'User',
      action: `Changed tabs at Stratification of HDR`,
      label: key
    });
    setTabKey(key);
  };


  return (
    <Tabs
      value={tabKey}
      config={config}
      sectors={sectors}
      countries={countries}
      vehicleId={vehicleId}
      onChange={onTabChange}
      view="virtualDataRoom"
      countryMode={false}
      currency={currency}
      selectedLender={selectedLender}
      redirectSourceGeography={redirectionSources.geography}
      redirectionSourceSector={redirectionSources.sector}
    />
  );
};

export default Diversification;