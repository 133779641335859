import React, { useEffect } from 'react';
//Components
import { Skeleton, Spin } from "antd";
import { FlatList } from "@cardoai/components";
//Constants
import { loansConfig } from "./config";
import { callApi, isVDRUser } from "../../../../helpers";
//Icons
import { InfoCircleTwoTone } from "@ant-design/icons";

interface IStatistics {
  filters?: any
  currency?: any
}

const Statistics = (props: IStatistics) => {
  const {filters, currency} = props;
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState<any>(false);
  const vdrUser = isVDRUser();

  useEffect(() => {
    requestStatistics();
  }, [filters]);

  const requestStatistics = () => {
    let queryString = "";
    if (filters)
      queryString += "&" + filters;

    callApi({
      url: `/main/sme_loan/statistics/?${queryString}`,
      setLoading: setLoading,
      onSuccess: (data: any) => setData(data),
      cancelPreviousCalls: true
    })

  }

  if (!data)
    return <Skeleton active/>;

  /*Remove Avg Price for VDR user from loan header statistics*/
  const headerConfig = vdrUser ? loansConfig(currency).filter(e => e.key !== "avg_loan_price") : loansConfig(currency);

  return (
    <Spin spinning={loading} size='large'>
      <div className="pointerCursor">
        <span className="size12">
          <InfoCircleTwoTone style={{fontSize: 12}} className="help-icon"/> Rearrange statistics by dragging them
        </span>
        <FlatList
          draggable
          data={data}
          size="medium"
          elementWidth={150}
          config={headerConfig}
          storageKey="loans_statistics_items"
        />
      </div>
    </Spin>
  );
};

export default Statistics;
