import React from "react";
//Other libs
import produce from "immer";
import moment from "moment";
//Components
import { Alert, Button, Col, DatePicker, Divider, Row } from "antd";
import FundingFields from "./funding_fields";
import { SimpleEmpty } from "@cardoai/components"
//Helpers
import notifications from "../../../../../../../../../../components/notifications";
import { callApi, formatServerDate, formatters } from "../../../../../../../../../../helpers";
import config from "../../../../../../../../../../constants/config";

const generateFunding = () => {
  return {fund_name: null, amount: null, coupon: null}
}

const getDefaultDate = (showDefaultValues?: any, date?: any) => {
  return showDefaultValues ? date : null;
}

const initialState = (showDefaultValues: any, data: any) => {
  const initialAmount = showDefaultValues ? data.amount : null
  return [
    {fund_name: null, amount: initialAmount, coupon: null}
  ]
}

interface IFundingDate {
  record?: any,
  onCancel?: any,
  getData?: any,
  vehicleId?: any,
  vehicle?: any,
  vehicleIdentifier?: any
}

const FundingDate = (props: IFundingDate) => {

  const {record, onCancel, getData, vehicleId, vehicleIdentifier} = props;

  const hasFundingView = config.hasOwnProperty(vehicleIdentifier) && config[vehicleIdentifier].notesManager.hasOwnProperty("fundingView");
  const showDefaultValues = config.hasOwnProperty(vehicleIdentifier) && config[vehicleIdentifier].notesManager.showDefaultValues;

  const {funding_info} = record;

  const reconPeriodDate = moment(record.current_recon_period_end);

  const [fundingDate, setFundingDate] = React.useState(getDefaultDate(showDefaultValues, reconPeriodDate))

  const [fields, setFields] = React.useState(initialState(showDefaultValues, funding_info));


  const onFieldsChange = (field: any, index: any) => (input: any) => {
    setFields(produce((draftState: any) => {
      draftState[index][field] = input;
    }))
  }

  const onFieldsRemove = (index: any) => () => {
    setFields(prevState => {
      let updatedState = [...prevState];
      updatedState.splice(index, 1);
      return updatedState;
    })
  }

  const handleFundAddition = () => {
    setFields(prevState => ([...prevState, generateFunding()]))
  }

  const onDateChange = (value: any) => {
    setFundingDate(value)
  };

  function prepareDate() {
    if (!fundingDate)
      return null
    return formatServerDate(fundingDate);
  }

  const params = {
    funding_date: prepareDate(),
    funding_data: fields
  }

  const validateAmounts = (amounts: any) => {
    let sum = 0;
    amounts.forEach(({amount}: any) => {
      sum += amount
    });

    return sum <= Math.ceil(funding_info.amount);
  };

  const onConfirmFunding = () => {

    if (!validateAmounts(fields)) {
      notifications.warning("Total funding is more than the allowed amount!");
      return;
    }

    if (!fundingDate) {
      notifications.warning("Please select a funding date!");
      return;
    }

    if (fundingDate) {
      callApi({
        url: `/main/notes_manager/${record.id}/fund_proposal/?vehicle_id=${vehicleId}`,
        body: params,
        method: "put",
        onSuccess: () => {
          onCancel();
          getData()
        },
      })
    } else
      notifications.error("Total funding is more than the allowed amount!");
  };

  const disabledFundingDate = (current: any) => {
    return current && current < reconPeriodDate;
  }


  const message = `The Funding Amount should not exceed ${formatters.currency({
    value: funding_info.amount,
    precision: 3
  })}`;

  const canAddFunding = Object.keys(config).find((item: any) => {
    return config[item].notesManager.addFunding
  })


  if (!hasFundingView)
    return <SimpleEmpty/>;

  return (
    <>
      {funding_info.amount && <>
        <Alert message={message} banner className="mb16 bold"/>
        <Divider/>
      </>}
      <Row align='middle' gutter={32} justify="start" className="mt24 mb16">
        <Col>
          <span className="mr8 bold">Funding Date:</span>
          <DatePicker
            allowClear={true}
            value={fundingDate}
            onChange={onDateChange}
            defaultValue={getDefaultDate()}
            disabledDate={disabledFundingDate}
          />
        </Col>
        {canAddFunding === vehicleIdentifier && <Col>
          <Button type='primary' size='small' onClick={handleFundAddition}>
            Add Funding
          </Button>
        </Col>}
      </Row>

      <FundingFields vehicleIdentifier={vehicleIdentifier} vehicleId={vehicleId} data={fields}
                     onFieldsChange={onFieldsChange} onFieldsRemove={onFieldsRemove} height={200}/>
      <Divider/>
      <Row align='middle' justify='end'>
        <Button disabled={!fundingDate} onClick={onConfirmFunding} type='primary'>
          Confirm
        </Button>
      </Row>
    </>
  )
}


export default FundingDate;