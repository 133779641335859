import UpdateCheckboxItem from "./update_checkbox_item";
import UpdateCalculationWavierDisplay from "./update_wavier_display";

export const columns: any = [
  {
    key: "checkbox_label",
    align: "center",
    width: 20,
    Component: UpdateCheckboxItem,
  },
  {
    key: "loan",
    label: "Loan ID",
    align: "center",
  },
  {
    key: "company_name",
    label: "Company Name",
    align: "center",
  },
  {
    key: "current_balance",
    label: "Current Balance",
    align: "center",
  },
  {
    key: "calculation_waiver",
    label: "Calculation Waiver",
    align: "center",
    Component: UpdateCalculationWavierDisplay,
  },
];
