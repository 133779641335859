import styled from "styled-components";
//Constants
import { theme } from "../../../../../../assets/theme/colors";

const ToggleButtonWrapper = styled.div`
  position: absolute;
  right: -16px;
  top: 18px;
  transform: scale(0.8);

  .ant-btn {
    background-color: ${theme.palette.secondary[5]};
    color: ${theme.colors.white};
  }
`;

export default ToggleButtonWrapper;
