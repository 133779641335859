import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Concentration = () => {
  return (
    <>
      <div>
        <p>
          This view presents the composition of the Collateral along 9 main dimensions: <b>Debtor Region, Loan Rating,
          Loan Size, Debtor Industry, Loan Type, Residual Maturity, Performance Status, Interest Rate Type,
          Modification.</b>
          Through the menu on the top right of each graph, the User can access a list of options including switching the
          labels from <b>percentage (%) to amounts (€) or to count (#)</b>, expand the “Other” category (clicking on
          “Ungroup Items”) and
          changing the style of the graph. The User can also view each doughnut chart in full screen as well as download
          them either as a PNG file or CSV file.
        </p>
      </div>
      <CustomImage
        alt="SPV Management Collateral Diversification"
        src={assets.images.spvModule.diversification}
      />
    </>
  );
};

export default Concentration;
