import React from 'react';
//Components
import { Card, Col, Row } from 'antd';
import { OperationButton } from "@cardoai/components";
import NegativeMessageBox from "./negative_message_box";


const FeedBack = () => {
  const [feedBackType, setFeedBackType] = React.useState<any>(null);

  const handleFeedback = (attr: any) => () => {
    setFeedBackType(attr);
  }

  let content;

  if (feedBackType === "yes")
    content = (<div>Thank you for your feedback!</div>);
  else if (feedBackType === "no")
    content = <NegativeMessageBox changeFeedback={handleFeedback}/>
  else
    content = (

      <Row gutter={16}>
        <Col>
          <OperationButton type="confirm" onClick={handleFeedback('yes')}>Yes</OperationButton>
        </Col>
        <Col>
          <OperationButton type="reject" onClick={handleFeedback('no')}>No</OperationButton>
        </Col>
      </Row>
    )

  return (
    <div style={{padding: 30}}>
      <Card
        bordered={false}
        size='small'
        style={{width: 300}}
        title={feedBackType === "no" ? "What went wrong?" : "Was this helpful?"}>
        {content}
      </Card>
    </div>
  );
};

export default FeedBack;