import React from "react";
//Other Components
import { Checkbox, Col, Modal, Row } from "antd";

export const SELECT_WAIVER_TYPE: any = {
  NOMINATOR: "Numerator",
  DENOMINATOR: "Denominator",
  BOTH: "Both",
}

interface Props {
  showModal?: any,
  onCancel?: any
}

const SelectWaiverType = (props: Props) => {

  const {showModal, onCancel} = props;
  const [value, setValue] = React.useState('');

  const onCheckFields = (selected: any[]) => {
    let value = "";
    if (selected.length === 2) value = "Both";
    else if (selected.length === 1) value = selected[0];
    setValue(value);
  };

  const onClose = () => {
    onCancel(value);
    setValue('');
  }

  return (
    <div>
      <Modal
        visible={showModal}
        title="Select the waiver from"
        footer={null}
        centered={true}
        closable={false}
        onCancel={onClose}
        width={400}
        destroyOnClose
      >
        <Row justify="center">
          <Col>
            <Checkbox.Group onChange={onCheckFields}>
              <Checkbox value={SELECT_WAIVER_TYPE.NOMINATOR}>NUMERATOR</Checkbox>
              <Checkbox value={SELECT_WAIVER_TYPE.DENOMINATOR}>DENOMINATOR</Checkbox>
            </Checkbox.Group>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SelectWaiverType;