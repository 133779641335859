import React from "react";
//Other Libs
//Components
import { List } from "antd";
//Custom Components
import { MessageListHeader } from "./message_list_header";
import { SingleMessage } from "./single_message";


export const MessageList = ({messages, currentUserId, vehicleId, topicId, fetchMessages}: any) => {
  return (
    <List
      dataSource={messages}
      header={<MessageListHeader messages={messages} fetchMessages={fetchMessages}/>}
      itemLayout="horizontal"
      renderItem={(item: any) =>(
        <SingleMessage
          key={item.id}
          item={item}
          currentUserId={currentUserId}
          vehicleId={vehicleId}
          topicId={topicId}
          fetchMessages={fetchMessages}
        />
      )}
    />
  );
};
