import React from 'react';
//Other Components
import { Button, Row } from "antd";
//Static Data
import staticData from "./real_estates.json";
//Configuration
import { columnGroups, config } from "./config";
//Icons
import { DownloadOutlined } from "@ant-design/icons";
//Custom Components
import { DownloadAsCSV, SmartTable } from "../../../../components";

const NPLRealEstate = ({onDownload}: any) => {
  return (
    <>
      <Row className="mr8" align="middle" justify="end">
        <DownloadAsCSV onDownload={onDownload(staticData, "Real Estates")}>
          <Button icon={<DownloadOutlined/>} size="small">Download</Button>
        </DownloadAsCSV>
      </Row>
      <SmartTable allowGrouping height={700} columnGroups={columnGroups} columns={config} groupingPosition="start"
                  externalData={staticData}/>
    </>
  );
};

export default NPLRealEstate;