import React, {useState} from 'react';
//Components
import {Row, Col, Divider} from "antd";
import Container from "../container";
import File from "../../../../../design/file";
import Uploader from "../../../../../design/uploader";
import {useProposal} from "../../../../../context";
import {callApi, createFileName, downloadClientFile} from "../../../../../../../helpers";
import useFileUpload, {formatBytes} from "../../../../../hooks/use_file_upload";

const Input = ({footer}: any) => {
    const {file, vehicleId, proposalId, fetchProposal, inProgress, toggleProgress, isFar} = useProposal();
    const [previousFile, setPreviousFile] = useState<any>(file);
    const {files, setFiles, removeFile, handleDragDropEvent}: any = useFileUpload();

    const handleFileSave = () => {

        if (!files.length && footer.canNavigate(footer.current + 1)) {
            footer.onNext();
            return;
        }

        const body = new FormData();
        body.append('input_data_file', files[0]);
        callApi({
            body: body,
            setLoading: toggleProgress,
            method: 'put',
            onSuccess: async () => {
                await fetchProposal();
                footer.onNext(true);
            },
            url: `/casavo/notes_manager/${proposalId}/update_account_info/?vehicle_id=${vehicleId}`
        })
    }


    const extraFooterProps: any = {
        disabled: true,
        nextLabel: "Next"
    }

    if (files.length) {
        extraFooterProps.disabled = false;
        extraFooterProps.nextLabel = "Compute"
    } else {
        if (!previousFile) {
            extraFooterProps.disabled = !file;
            extraFooterProps.nextLabel = file ? "Next" : "Compute"
        } else {
            extraFooterProps.disabled = false;
            extraFooterProps.nextLabel = "Next";
        }
    }


    return (
        <Container
            {...footer}
            {...extraFooterProps}
            loading={inProgress}
            onNext={handleFileSave}
        >
            <Row gutter={16}>
                <Col xs={11}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Row gutter={[8, 8]}>
                                <Col xs={24} style={{
                                    fontSize: 20,
                                    fontWeight: 600,
                                    color: '#151219',
                                }}>
                                    Download Form
                                </Col>
                                <Col xs={24} style={{
                                    fontWeight: 400,
                                    color: '#8F8796'
                                }}>
                                    Please complete the form below by downloading it and then upload.
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24}>
                            <File
                                fileSize="1 MB"
                                title="Theta - Manual Input Data"
                                onDownload={() => {
                                    callApi({
                                        url: `/casavo/notes_manager/get_input_file_template/?vehicle_id=${vehicleId}`,
                                        onSuccess: (response: any) => {
                                            downloadClientFile({
                                                link: true,
                                                url: response.url,
                                                filename: createFileName('manual_input_data', 'xlsx'),
                                            });
                                        }
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                    <Divider type="vertical" style={{height: 600}}/>
                </Col>
                <Col xs={11}>
                    <Row gutter={[16, 16]}>
                        {isFar  && <><Col xs={24}>
                            <Row gutter={[8, 8]}>
                                <Col xs={24} style={{
                                    fontSize: 20,
                                    fontWeight: 600,
                                    color: '#151219',
                                }}>
                                    Upload Form
                                </Col>
                                <Col xs={24} style={{
                                    fontWeight: 400,
                                    color: '#8F8796'
                                }}>
                                    Please make sure that form is completed.
                                </Col>
                            </Row>

                        </Col><Col xs={24}>
                            <Uploader
                              files={files}
                              setFiles={setFiles}
                              handleDragDropEvent={handleDragDropEvent}
                              isFar={isFar}/>
                        </Col></>}
                        <Col xs={24}>
                            {files.map((f: any) => {
                                return (
                                    <File
                                        file={f}
                                        removable
                                        title={f.name}
                                        canAnimate={true}
                                        fileSize={formatBytes(f.size)}
                                        onDownload={null}
                                        onRemove={() => {
                                            removeFile(0);
                                        }}

                                    />

                                )
                            })}
                            {(!files || !files.length) && previousFile && (
                                <File
                                    removable={isFar}
                                    fileSize={null}
                                    canAnimate={true}
                                    file={previousFile}
                                    title={"user_input_file"}
                                    onRemove={() => setPreviousFile(null)}
                                    onDownload={() => {
                                        downloadClientFile({
                                            link: true,
                                            url: previousFile,
                                            filename: createFileName('user_input_file', 'xlsx'),
                                        });
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Input;