//Helpers
import { formatters } from "../../../../../../../../../helpers";
//Components
import { EmojiBreach } from "../../../../../../../../../components";

export const sellerTable: any = (currency: any) => {
  return [
    {
      key: "seller_id",
      label: "Seller ID",
    },
    {
      key: "seller_name",
      label: "Seller Name",
    },
    {
      key: "value",
      label: "Threshold",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    {
      key: "fulfillment",
      label: "Realized",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    {
      key: "broken",
      label: "Check",
      Component: EmojiBreach
    },
    {
      key: "available_amount",
      label: "Available",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    }
  ]
}

export const debtorTable: any = (currency: any) => {
  return [
    {
      key: "debtor_id",
      label: "Debtor ID",
      align: "center",
    },
    {
      key: "debtor_name",
      label: "Debtor Name",
      align: "center",
    },
    {
      key: "debtor_revenue",
      label: "Revenues",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    {
      key: "revenue_cluster",
      label: "Revenues Cluster",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    {
      key: "debtor_rating_cluster",
      label: "Rating Updated",
    },
    {
      key: "debtor_rating_cluster_at_inception",
      label: "Rating at Inception",
    },
    {
      key: "value",
      label: "Threshold",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    {
      key: "fulfillment",
      label: "Realized",
      sortable: true,
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    },
    {
      key: "broken",
      label: "Check",
      Component: EmojiBreach,
    },
    {
      key: "available_amount",
      label: "Available",
      format: (value: any) => formatters.currency({value, precision: 0, currency})
    }
  ]
}

export const clusters: any = ['AAA', 'AA', 'A', 'BBB', 'BB', 'B', 'CCC', 'Other'];

export const sellerContent = (
  <div style={{maxWidth: 500}}>
    <p>A partire dalla prima Data di Valutazione dei Limiti di Acquisto (inclusa), con riferimento ai valori assunti dal
      relativo Portafoglio alla Data di Valutazione, l'Importo Dovuto dei Crediti ceduti da ciascun Cedente e ricompresi
      nel Portafoglio Aggregato, che deve intendersi includere i Crediti ricompresi nel Portafoglio offerto in cessione,
      non superi Euro 5,000,000.
    </p>
  </div>
);
export const debtorContent = (
  <div style={{maxWidth: 500}}>
    <p>Ad ogni Data di Valutazione dei Limiti di Acquisto, con riferimento alla relativa Data di Valutazione, l'Importo
      Dovuto dei Crediti, verso ogni singolo debitore, ricompresi nel Portafoglio Aggregato inclusi quelli ricompresi
      nel Portafoglio offerto in cessione, non superi l'importo in Euro riportato nella seguente tabella (Threshold)
      corrispondente alla Classe di rating CARDO e alla classe di fatturato del relativo Debitore.
    </p>
    <br/>
    <p>Nel caso in cui in una Data di Valutazione dei Limiti di Acquisto, venisse valutata per la cessione la fattura di
      un debitore già presente nel Portafoglio Aggregato con una fattura precedentemente ceduta e non ancora scaduta con
      classe Rating Cardo diversa rispetto alla fattura in valutazione, ai fini della determinazione dei limiti di
      concentrazione rispetto al singolo Debitore rileva la Classe di Rating della fattura già presente nel Portafoglio.
    </p>
  </div>
);
