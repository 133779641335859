import React from 'react';
//Components
import {Row, Col} from "antd";
import Card from "../../../../../../../design/card";

const List = ({config}: any) => {
    return (
        <Row gutter={[16, 16]} style={{paddingLeft: 16}}>
            {Object.keys(config).map((key: any) => {
                return (
                    <Col xs={10} key={key}>
                        <Card
                            title={key}
                            config={config[key]}/>
                    </Col>
                )
            })}
        </Row>
    );
};

export default List;