import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const DownloadDocuments = () => {
  return (
    <CustomImage
      fullWidth={false}
      alt="Document Download"
      src={assets.images.documentsModule.download}
      description="The download button opens the selected document (or download, depending on file format)."
    />
  );
};

export default DownloadDocuments;
