import React from "react";
//Components
import { Col, Row } from "antd";
//Helpers
import { getNested, validateValue } from "../../../../../../helpers";

interface IInvestorStatistics {
  data?: any,
  config?: any,
  investorName?: any
}

const InvestorStatistics = (props: IInvestorStatistics) => {
  const {data, config, investorName} = props;

  return (
    <Row align='middle' gutter={16}>
      <Col xs={2} className="textLeft">
        {investorName}
      </Col>
      <Col xs={7}>
        <Row justify='center' gutter={8}>
          {config.map(({format, label, key}: any) => {
            const statKey = getNested(data, key);
            let value = validateValue(statKey, format);
            return (
              <Col key={key} className="textCenter" style={{width: 120}}>
                <p className="bold">{value}</p>
                <small className="fw400">{label}</small>
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  )
};

export default InvestorStatistics;