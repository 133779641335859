import styled from "styled-components";

export default styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Poppins&family=Raleway:wght@400;700&display=swap");

  :root {
    --primary-clr: #1890ff;
    --secondary-clr: #ffffff;
    --text-clr: #ffffff;
    --background-clr: #ffffff;
  }

  .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    font-family: "Raleway", sans-serif;
  }

  .login {
    display: inline-block;
    background-color: var(--background-clr);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    width: 900px;
    max-width: 100%;
    min-height: 560px;
    -webkit-animation: fadeIn 800ms forwards ease;
    animation: fadeIn 800ms forwards ease;
  }

  .label {
    font-family: "Poppins", sans-serif;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
    font-size: 0.7rem;
    font-weight: bold;
    margin-top: 1em;
    letter-spacing: 1px;
  }

  .button {
    margin: 2.5em 0 0;
    border-radius: 6px;
    border: 1px solid var(--primary-clr);
    -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.05);
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 1em;
    letter-spacing: 1px;
    cursor: pointer !important;
    background-color: var(--secondary-clr);
    color: var(--primary-clr);
    -webkit-transition: -webkit-box-shadow 80ms ease-in;
    transition: -webkit-box-shadow 80ms ease-in;
    -o-transition: box-shadow 80ms ease-in;
    transition: box-shadow 80ms ease-in;
  }

  .button:hover {
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
  }

  .button--active {
    color: var(--secondary-clr);
    background-color: var(--primary-clr);
  }

  .inner-container {
    position: absolute;
    top: 0;
    height: 100%;
    -webkit-transition: all 600ms ease-in-out;
    -o-transition: all 600ms ease-in-out;
    transition: all 600ms ease-in-out;
  }

  .inner-container .title {
    width: 100%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    line-height: 2.3rem;
    margin: 0 !important;
  }

  .inner-container .subtitle {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 0.5px;
    margin: 20px 0;
    font-family: "Poppins", sans-serif;
  }

  .inner-container form {
    background-color: var(--background-clr);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 2.5em;
    height: 100%;
    text-align: center;
  }

  .inner-container form .button-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .inner-container form .button-container button,
  .inner-container form .button-container a {
    width: 48%;
    text-decoration: none !important;
  }

  .contact-us {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }

  .main-content {
    left: 0;
    width: 50%;
    z-index: 2;
  }

  .side-logo {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    -webkit-transition: -webkit-transform 600ms ease-in-out;
    transition: -webkit-transform 600ms ease-in-out;
    -o-transition: transform 600ms ease-in-out;
    transition: transform 600ms ease-in-out;
    z-index: 100;
  }

  .side-logo .logo-container {
    background-size: cover;
    background: -o-linear-gradient(left, var(--primary-clr), var(--primary-clr)) no-repeat center;
    background: -webkit-gradient(linear,
    left top,
    right top,
    from(var(--primary-clr)),
    to(var(--primary-clr))) no-repeat center;
    background: linear-gradient(to right, var(--primary-clr), var(--primary-clr)) no-repeat center;

    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    color: var(--text-clr);
    -webkit-transition: -webkit-transform 600ms ease-in-out;
    transition: -webkit-transform 600ms ease-in-out;
    -o-transition: transform 600ms ease-in-out;
    transition: transform 600ms ease-in-out;
    transition: transform 600ms ease-in-out, -webkit-transform 600ms ease-in-out;
  }

  .login-animation {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .login-animation img {
    margin-top: 25px;
    width: 250px;
  }

  .login--active .main-content {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  .login--active .contact-us {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    -webkit-animation: show 0.6s;
    animation: show 0.6s;
  }

  .login--active .side-logo {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .login--active .side-logo .logo-container {
    -webkit-transform: translateX(150%);
    -ms-transform: translateX(150%);
    transform: translateX(150%);
  }

  .login-animation {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .login-animation img {
    margin-top: 25px;
    width: 250px;
  }

  @-webkit-keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }
    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }
    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  @media only screen and (max-width: 1000px) {
    .login {
      width: 80vw;
    }

    .main-content {
      width: 70%;
    }

    .contact-us {
      width: 70%;
    }

    .side-logo {
      left: 70%;
    }

    .side-logo .logo-container {
      width: 160vw;
    }

    .login--active .main-content {
      -webkit-transform: translateX(50%);
      -ms-transform: translateX(50%);
      transform: translateX(50%);
    }

    .login--active .contact-us {
      -webkit-transform: translateX(45%);
      -ms-transform: translateX(45%);
      transform: translateX(45%);
      opacity: 1;
      z-index: 5;
      -webkit-animation: show 600ms;
      animation: show 600ms;
    }

    .login--active .side-logo {
      -webkit-transform: translateX(-180%);
      -ms-transform: translateX(-180%);
      transform: translateX(-180%);
    }

    .login--active .side-logo .logo-container {
      -webkit-transform: translateX(30%);
      -ms-transform: translateX(30%);
      transform: translateX(30%);
    }

    .login--active .side-logo .logo-container {
      -webkit-transform: translateX(30%);
      -ms-transform: translateX(30%);
      transform: translateX(30%);
    }
  }

  @media only screen and (max-width: 800px) {

    .main-content {
      width: 80%;
    }

    .contact-us {
      width: 80%;
    }

    .side-logo {
      left: 80%;
    }

    .login--active .main-content {
      opacity: 0;
      -webkit-transform: translateX(30%);
      -ms-transform: translateX(30%);
      transform: translateX(30%);
    }

    .login--active .contact-us {
      -webkit-transform: translateX(25%);
      -ms-transform: translateX(25%);
      transform: translateX(25%);
    }

    .login--active .side-logo {
      -webkit-transform: translateX(-220%);
      -ms-transform: translateX(-220%);
      transform: translateX(-220%);
    }
  }

  @media only screen and (max-width: 600px) {
    .main-content {
      width: 100%;
    }

    .contact-us {
      width: 100%;
      -webkit-transform: translateX(-60%);
      -ms-transform: translateX(-60%);
      transform: translateX(-60%);
    }

    .side-logo {
      left: 100%;
    }

    .login--active .main-content {
      -webkit-transform: translateX(60%);
      -ms-transform: translateX(60%);
      transform: translateX(60%);
    }

    .login--active .contact-us {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    .login--active .side-logo {
      -webkit-transform: translateX(-300%);
      -ms-transform: translateX(-300%);
      transform: translateX(-300%);
    }
  }

  @media only screen and (max-width: 450px) {
    .login {
      width: 100vw;
      height: 100vh;
    }
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
`