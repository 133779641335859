//Helpers
import {formatters, safeFormatDate} from "../../helpers";
//Custom Components
import Status from "./design/status";


const Empty = () =>{
    return <div>€ &emsp; - </div>
        }

export const columns = [
    {
        key: 'proposed_date',
        label: "Proposal Date",
        render: formatters.date,
    },
    {
        key: 'proposed_by',
        label: "Proposed By"
    },
    {
        key: "status",
        label: "Status",
        render: (value: any, record: any) => <Status record={record} value={value}/>
    },
    {
        key: "completed_date",
        label: "Validated At",
        render: formatters.date
    },
    {
        key: "funding_amount",
        label: "Funding Amount",
        render: (value: any, ) => value === null || value === 0 ? <Empty/> : formatters.staticThousandsCurrency(value)

    },
    {
        key: "funding_date",
        label: "Funding Date",
        render: (value: any) => formatters.date(value)
    }
];

export const statusName = {
    "Processing": "Processing",
    "Awaiting Approval": "Awaiting Approval",
    "Approved": "Approved",
    "Rejected": "Rejected",
    "Completed": "Completed",
    "Deleted": "Deleted",
    "Finished": "Finished",
    "Awaiting For Submission": "Awaiting For Submission",
    "Awaiting User Input": "Awaiting User Input",
    "Awaiting For Computation": "Awaiting For Computation",
}
