import React from 'react';
//Custom Components
import { LinkView } from "../../../../components";

const Diversification = () => {
  return (
    <div>
      This section shows the diversification of the Collateral pool along different measures. It is divided into 4
      tabs:
      <ul>
        <li><LinkView
          href='/documentation/spv-management/collateral-section/diversification-collateral/breakdown-concentration'>Breakdown</LinkView>
        </li>
        <li><LinkView
          href='/documentation/spv-management/collateral-section/diversification-collateral/geography-concentration'>Geography</LinkView>
        </li>
        <li><LinkView
          href='/documentation/spv-management/collateral-section/diversification-collateral/sector-concentration'>Sector</LinkView>
        </li>
        <li><LinkView
          href='/documentation/spv-management/collateral-section/diversification-collateral/debtor-concentration'>Debtor</LinkView>
        </li>
      </ul>
    </div>
  );
};

export default Diversification;
