import React from 'react';
//Components
import { Divider } from "antd";
import { SimpleEmpty } from "@cardoai/components";
//Other Libs
import cx from "classnames";
//Styles
import { StyledDiv, Table } from "../../../../../styles";
//Helpers
import withLimitDownload from "../../../../../components/hoc/with_limit_download";
import { formatters, isEmpty } from "../../../../../../../../../../../helpers";

const highlightedText = {
  total: "Total",
  residual: "Residual Cash Balance"
}

const DrawDownTable = (props: any) => {
  const {data, title, height, currency} = props;
  if (isEmpty(data))
    return <SimpleEmpty/>

  return (
    <StyledDiv height={height}>
      <Table className="fullWidth">
        <thead>
        <th className="primary">{title}</th>
        <th/>
        </thead>
        <tbody>
        {data.map((entry: any, index: number) => {
          const {name, fulfillment} = entry;

          let highlighted;
          if (name === highlightedText.total || name === highlightedText.residual)
            highlighted = " primary";

          const residualBalance = name === highlightedText.residual;

          return (
            <React.Fragment key={index}>
              {residualBalance && <Divider/>}
              <tr key={index}>
                <td className={cx("bold", highlighted)} style={{paddingLeft: 20}}>{name}</td>
                <td
                  className={cx("textCenter bold", highlighted)}>{formatters.currency({
                  value: fulfillment,
                  currency
                })}</td>
              </tr>
            </React.Fragment>
          )
        })}
        </tbody>
      </Table>
    </StyledDiv>
  )
};

export default withLimitDownload(DrawDownTable)({
  title: 'Drawdown',
  prepareData: function (data: any) {
    return data.map((record: any) => {
      return {
        "Name": record.name,
        "Realized": Number(record.fulfillment)
      }
    })
  }
});
