import React from 'react';
import {Row, Col, Empty} from "antd";

const Accounts = () => {
    return (
        <Row justify="center">
            <Col>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            </Col>
        </Row>
    );
};

export default Accounts;