import React from "react";
//Components
import { Col, Row } from "antd";
//Icons
import { ArrowRightOutlined } from "@ant-design/icons";
//Helpers
import { formatters, validateValue } from "../../../../../helpers";

interface ITBody {
  data?: any,
  colors?: any,
  onClick?: any,
  currency?: any
}

const Tbody = (props: ITBody) => {
  const {data, colors, onClick, currency} = props;

  if (!data)
    return null;

  const content = data.map((field: any, index: any) => {
    const color = colors && colors[index];
    const name = field.sector || field.name;
    const bulletStyle = {color: color, fontSize: 20};

    const handleClick = () => {
      if (!onClick)
        return;
      return onClick(field);
    }

    let className = "information-row";

    if (onClick)
      className += " pointerCursor";

    return (
      <tr
        key={index}
        onClick={handleClick}
        className={className}>
        <td title={name}>
          <Row gutter={16} align="middle">
            <Col style={bulletStyle}>•</Col>
            <Col>{name}</Col>
          </Row>
        </td>
        <td className='textCenter'>{validateValue(field.ratio, formatters.percent)}</td>
        <td className='textCenter'>{formatters.currency({value: field.balance, currency})}</td>
        <td className='textCenter'>{validateValue(field.gwar_open, formatters.percent)}</td>
        <td className='textCenter'>{validateValue(field.wam, formatters.years)}</td>
        <td className='textCenter'>{validateValue(field.avg_rating)}</td>
        <td>
          <ArrowRightOutlined
            style={{fontSize: 12}}
            className="pointerCursor"/>
        </td>
      </tr>
    )
  });

  return (
    <tbody>
    {content}
    </tbody>
  )
};

export default Tbody;