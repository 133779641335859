import { formatters, Text } from "../../../../helpers";

export const tableRealEstate: any = [
  {
    label: Text("Real Estate ID"),
    key: "real_estate_id",
    sortable: true,
    width:120
  },
  {
    label: Text("Transaction ID"),
    key: "transaction_id",
    sortable: true,
    width:120
  },
  {
    label: Text("Property Type"),
    key: "property_type",
    sortable: true,
    width:120
  },
  {
    label: Text("Country"),
    key: "country",
    sortable: true,
    width:100
  },
  {
    label: Text("City"),
    key: "city",
    sortable: true,
    width:100
  },
  {
    label: Text("Area SQM"),
    key: "net_square_metres",
    sortable: true,
    width:100
  },
  {
    label: Text("Units"),
    key: "number_of_units",
    sortable: true,
    width:100
  },
  {
    label: Text("Cadastral Category"),
    key: "cadastral_category",
    sortable: true,
    width:150
  },
  {
    label: Text("Original Valuation Amount"),
    key: "original_valuation_amount",
    width: 200,
    sortable: true,
    format: formatters.thousands
  },
  {
    label: Text("Status"),
    key: "asset_status",
    sortable: true,
    width:100
  },
  {
    label: Text("Property Form Of Title"),
    key: "property_form_of_title",
    width: 200,
    sortable: true,
  },
  {
    label: Text("Renovation Start Date"),
    key: "renovation_start_date",
    sortable: true,
    format: formatters.date,
    width:180
  },
  {
    label: Text("Renovation End Date"),
    key: "renovation_end_date",
    sortable: true,
    format: formatters.date,
    width:180
  },
  {
    label: Text("Initial Renovation Budget (Net)"),
    key: "renovation_budget_net",
    sortable: true,
    format: formatters.thousands,
    width:220
  },
  {
    label: Text("Total Purchase Price"),
    key: "purchase_price",
    sortable: true,
    format: formatters.thousands,
    width:220
  },
  {
    label: Text("SQM Price"),
    key: "sqm_price",
    sortable: true,
    format: formatters.thousands,
    width: 100
  },
  {
    label: Text("Acquisition Date"),
    key: "acquisition_date",
    sortable: true,
    format: formatters.date,
    width: 150
  },
  {
    label: Text("Deal Type"),
    key: "deal_type",
    sortable: true,
    width: 100,
  },
  {
    label: Text("Deed Type"),
    key: "deed_type",
    sortable: true,
    width: 150,
  },
  {
    label: Text("Commercialization Start Date"),
    key: "commercialization_start_date",
    sortable: true,
    format: formatters.date,
    width: 220
  },
  {
    label: Text("Deadline Notarization Date"),
    key: "deadline_notarization_date",
    width: 200,
    sortable: true,
    format: formatters.date,
  },
  {
    label: Text("Notarization Date"),
    key: "notarization_date",
    sortable: true,
    format: formatters.date,
    width: 150
  },
  {
    label: Text("Expected Sale Price"),
    key: "expected_sale_price",
    width: 150,
    sortable: true,
    format: formatters.thousands
  },
  {
    label: Text("Sale Price BO"),
    key: "sale_price",
    sortable: true,
    format: formatters.thousands,
    width: 150
  },
  {
    label: Text("BO Accepted Date"),
    key: "sale_date",
    sortable: true,
    format: formatters.date,
    width: 150
  },
  {
    label: Text("CAPEX/DD"),
    key: "cappex_dd",
    sortable: true,
    format: formatters.thousands,
    width: 100
  },
];

export const tableCashFlow: any = [
  {
    label: "Real Estate ID",
    key: "real_estate_id",
    align: "center"
  },
  {
    label: "Transaction ID",
    key: "transaction_id"
  },
  {
    label: "Cash Flow Type",
    key: "cash_flow_type"
  },
  {
    label: "Cash Flow Amount",
    key: "payment_amount",
    format: formatters.thousands,
    sortable: true,
  },
  {
    label: "Cash Flow Currency",
    key: "payment_currency"
  },
  {
    label: "Cash Flow Date",
    key: "payment_date",
    format: formatters.date
  },
  {
    label: "Payment Account",
    key: "payment_account"
  },
];

export const tableLoanByLoanSection :any = [
    {
    label: Text("Property ID"),
    key: "property_id",
    sortingName: "real_estate_id",
    width: 120
  },
  {
    label: Text("Property ID(M)"),
    key: "property_id_m",
    sortingName: "transaction_id",
    width: 120
  },
  {
    label: Text("Acquisition Date"),
    key: "acquisition_date",
    width: 150,
    sortable:true
  },
  {
    label: Text("Cadastral Category"),
    key: "cadastral_category",
    width: 150
  },
  {
    label: Text("Status"),
    key: "status",
    width: 100
  },
  {
    label: Text("Status EC (ACTIVE or NOT ACTIVE)"),
    key: "status_ec",
    width: 240
  },
  {
    label: Text("Intended Use"),
    key: "intended_use",
    width: 120
  },
  {
    label: Text("Deed Type"),
    key: "deed_type",
    width:200
  },
  {
    label: Text("Purchase Price"),
    key: "purchase_price",
    format: formatters.thousands,
    sortable:true,
    width: 140
  },
  {
    label: Text("Due Diligence Price"),
    key: "due_diligence_price",
    format: formatters.thousands,
    width: 160,
    sortable:true
  },
  {
    label: Text("Initial Renovation Budget (Net)"),
    key: "initial_renovation_budget",
    format: formatters.thousands,
    width:220,
    sortable:true
  },
  {
    label: Text("Initial Renovation Costs / DD Price (Initial CAPEX / DD)"),
    key: "initial_renovation_costs",
    format: formatters.percent,
    width: 380,
    sortable:true
  },
  {
    label: Text("Purchase Price / DD Price (PP / DD)"),
    key: "purchase_price_dd_price",
    format: formatters.percent,
    width: 240,
    sortable:true
  },
  {
    label: Text("LTC Senior"),
    key: "ltc_senior",
    format: formatters.thousands,
    sortable:true,
    width: 100
  },
  {
    label: Text("Total Borrowing Base"),
    key: "total_borrowing_base",
    format: formatters.thousands,
    width: 180,
    sortable:true
  },
  {
    label: Text("Total Senior Borrowing Base"),
    key: "total_senior_borrowing_base",
    format: formatters.thousands,
    width: 200,
    sortable:true
  },
  {
    label: Text("Senior Outstanding"),
    key: "senior_outstanding",
    format: formatters.thousands,
    sortable:true,
    width: 150
  },
  {
    label: Text("# Units"),
    key: "nr_units",
    format: formatters.thousands,
    width: 150
  },
  {
    label: Text("Senior BB Validation"),
    key: "senior_bb_validation",
    format: formatters.thousands,
    width: 150
  },
  {
    label: Text("Sale Time Calc"),
    key: "sale_time_calc",
    format: formatters.thousands,
    width: 150
  },
  {
    label: Text("Sale Time Positive"),
    key: "sale_time_positive",
    format: formatters.thousands,
    width: 150
  },
  {
    label: Text("Sale Time Validation"),
    key: "sale_time_validation",
    width: 150
  },
  {
    label: Text("LTC Calc"),
    key: "ltc_calc",
    format: formatters.percentTwoPrecision,
    width: 150
  },
  {
    label: Text("LTC Validation"),
    key: "ltc_validation",
    width: 150
  },
  {
    label: Text("Total BB Calc"),
    key: "total_bb_calc",
    format: formatters.thousands,
    width: 150
  },
  {
    label: Text("Total BB Validation"),
    key: "total_bb_validation",
    width: 150
  },
  {
    label: Text("Sold Validation"),
    key: "sold_validation",
    format: formatters.thousands,
    width: 150
  },
]

export const otherColumnsLoanByLoanSection: any = [
    {
      label: Text("Reference"),
      key: "reference",
      width: 120
    },
    {
      label: Text("Renovation Start Date"),
      key: "renovation_start_date",
      width: 200,
      sortable:true
    },
    {
      label: Text("Renovation End Date"),
      key: "renovation_end_date",
      width: 200,
      sortable:true
    },
    {
      label: Text("Commercialization Start Date"),
      key: "commercialization_start_date",
      sortable:true,
      width: 220
    },
    {
      label: Text("SQM"),
      key: "sqm",
      format: formatters.thousands,
      sortable:true,
      width: 100
    },
    {
      label: Text("SQM (M)"),
      key: "sqm_m",
      format: formatters.thousands,
      sortable:true,
      width: 100
    },
    {
      label: Text("Metropolitan Area"),
      key: "metropolitan_area",
      width: 150
    },
    {
      label: Text("# Units"),
      key: "units",
      sortable:true,
      render: (value: any, ) => Math.floor(value),
      width: 100
    },
    {
      label: Text("Is Vacant Property"),
      key: "is_vacant_property",
      width: 150
    },
    {
      label: Text("Deal Type"),
      key: "deal_type",
      width: 100,
    },
    {
      label: Text("Purchase Price - Surplus"),
      key: "purchase_price_surplus",
      width: 200,
      sortable:true
    },
    {
      label: Text("EC Purchase Price to Date"),
      key: "ec_purchase_price_to_date",
      width: 200,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("EC Purchase Price - Surplus to Date"),
      key: "ec_purchase_price_surplus_to_date",
      width: 250,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Renovation Company Costs to Date"),
      key: "renovation_company_costs_to_date",
      width: 250,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Renovation Partner Costs to Date"),
      key: "renovation_partner_costs_to_date",
      width: 250,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Extra Renovation Buyer Costs to Date"),
      key: "extra_renovation_buyer_costs_to_date",
      width: 250,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Extra Renovation Structural Costs to Date"),
      key: "extra_renovation_structural_costs_to_date",
      width: 280,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Notary at Acquisition Costs to Date"),
      key: "notary_at_acquisition_costs_to_date",
      width: 250,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Notary at Sale Costs to Date"),
      key: "notary_at_sale_costs_to_date",
      width: 200,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Transfer Tax to Date"),
      key: "transfer_tax_to_date",
      width: 180,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Insurance Costs to Date"),
      key: "insurance_costs_to_date",
      width: 180,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Property Management Costs to Date"),
      key: "property_management_costs_to_date",
      width: 260,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Marketing  Costs toDate"),
      key: "marketing_costs_to_date",
      width: 180,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Due Diligence Costs to Date"),
      key: "due_diligence_costs_to_date",
      width: 200,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Agency fee at Acquisition"),
      key: "agency_fee_at_acquisition",
      width: 200,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Agency fee at Sale"),
      key: "agency_fee_at_sale",
      width: 180,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Other Costs to Date"),
      key: "other_costs_to_date",
      width: 150,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("BO Date"),
      key: "bo_date",
      width: 100,
      sortable:true
    },
    {
      label: Text("BO Sale Price"),
      key: "bo_sale_price",
      width: 120,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Days since BO"),
      key: "days_since_bo",
      width: 120,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Sale Time"),
      key: "sale_time",
      format: formatters.thousands,
      sortable:true,
      width: 120
    },
    {
      label: Text("Cash-in Date"),
      key: "cash_in_date",
      width: 120,
      sortable:true
    },
    {
      label: Text("Cash-in Sale Price"),
      key: "cash_in_sale_price",
      width: 140,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Expected Sale Price"),
      key: "expected_sale_price",
      width: 150,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("Total Expected Sale Price"),
      key: "total_expected_sale_price",
      width: 190,
      format: formatters.thousands,
      sortable:true
    },
    {
      label: Text("RBI Actual (Sold only)"),
      key: "rbi_actual",
      width: 180,
      sortable:true
    },
  ];


