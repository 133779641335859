//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../assets/theme/colors";

export const Styles = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid ${theme.palette.grayscale[10]},
`