import React from "react";
//Components
import { Button, Col, Row } from "antd";
import { NumberInput } from "@cardoai/components";
//Icons
import { DeleteOutlined } from "@ant-design/icons";

interface IMCSContent {
  index?: any,
  hideDeleteButton?: any,
  onFieldsChange?: any,
  onFieldsRemove?: any,
  funding?: any
}

const MCSContent = (props: IMCSContent) => {
  const {index, hideDeleteButton, onFieldsChange, onFieldsRemove, funding} = props;
  const {amount} = funding;

  return (
    <Row gutter={8} align="middle" className="mt8 mb16">
      <Col xs={16}>
        <span className="bold">Fund Amount: </span>
        <NumberInput type='thousands' placeholder="Enter Fund Amount" value={amount}
                     onChange={onFieldsChange('amount', index)}/>
      </Col>
      <Col className="mt16" xs={8}>
        {!hideDeleteButton &&
          <Button shape="round" size="small" onClick={onFieldsRemove(index)} icon={<DeleteOutlined/>} danger/>}
      </Col>
    </Row>
  )
}

export default MCSContent;