import React, { useEffect, useState } from 'react';
//Other libs
import styled from "styled-components";
//Helpers
import notifications from "../../../../components/notifications";
//Components
import { Button, Card, Col, Input, Row } from "antd";
import Editor from "../editor";
import Report from "../editor/report";
import { FieldSet } from "@cardoai/components";
import { Loader } from "../../../../components";
import DatabaseConfiguration from "../database/database_configuration";
//Helpers
import { client } from "../../config";
//Icons
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  SaveOutlined,
  SettingOutlined
} from "@ant-design/icons";
//Constants
import { theme } from "../../../../assets/theme/colors";

//Styles
interface IDataSource {
  record?: any,
  selected?: any,
  onChange: (e: any) => any,
  setEditable?: any,
  activeAction?: any,
  state?: any,
  templateId?: any,
  parentValidation?: any,
  onSave: (id: any) => void,
  onDelete: () => any,
  onValidate: (id: any, value: any) => any

}

const styles = {
  container: {
    minHeight: 150,
    display: "flex",
    padding: "8px 0px",
    flexDirection: "column",
    justifyContent: "space-between"
  }
};

const Container = styled(Card)`
  .ant-card-head {
    background-color: #F8F8F8;
  }

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.22);
`

const DataSource = (props: IDataSource) => {
  const {record, selected, onChange, setEditable, activeAction, state, templateId, parentValidation} = props;

  const id = record.id;
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);

  useEffect(() => {
    if (activeAction)
      focusDatasource();
  }, [activeAction])

  const focusDatasource = () => {
    const card: any = document.querySelector(".ant-card.active");
    card.style.borderColor = theme.colors.primary;
    setTimeout(() => {
      card.style.borderColor = "#f0f0f0";
    }, 1500)
    card.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  const onValidate = () => {
    if (state.validate)
      return;

    setValidating(true);

    client({
      url: "/api/report/validate/",
      data: {
        expression: state.expression,
        db_choice: state.db_choice,
        configs: {
          host: state.host,
          port: state.port ? Number(state.port) : null,
          database: state.database,
          username: state.username,
          password: state.password
        },
      },
      method: 'post',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      setTimeout(() => {
        props.onValidate(id, true);
      }, 2000)
    }).catch((response: any) => {
      props.onValidate(id, false);
      notifications.error(response.data.message);
    })
      .finally(() => {
        setTimeout(() => {
          setValidating(false);
        }, 2000)

      })
  };

  const onDelete = () => {
    setDeleting(true);

    client({
      url: `/api/datasource/${id}`,
      method: 'delete',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      setTimeout(() => {
        props.onDelete();
      }, 2000)
    }).finally(() => {
      setTimeout(() => {
        setDeleting(false);
      }, 2000)
    })
  };

  const onSave = () => {

    setSaving(true);

    const params: any = {
      template_id: templateId
    };

    if (state.description !== record.description)
      params.description = state.description;

    if (state.expression !== record.expression)
      params.expression = state.expression;

    if (state.db_choice !== record.db_choice)
      params.db_choice = state.db_choice;

    if (record.configs) {
      params.configs = {};
      for (let key in record.configs) {
        if (record.configs.hasOwnProperty(key)) {
          let value = state[key];
          if (record[key] !== value) {
            if (key === "port")
              value = Number(value);
            params.configs[key] = value;
          }
        }
      }
    }


    client({
      url: `/api/datasource/${id}`,
      data: params,
      method: 'put',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      setTimeout(() => {
        props.onSave(id);
      }, 2000)
    }).finally(() => {
      setTimeout(() => {
        setSaving(false);
      }, 2000)
    })

  };

  const isValidating = validating || parentValidation;

  const showLoader = isValidating || deleting;

  const onEdit = (type: any) => () => {
    let editConfig = null;

    if (activeAction !== type)
      editConfig = {type: type, id: id};
    setEditable(editConfig)
  };

  const containerStyle: any = {
    ...styles.container
  }

  if (activeAction)
    containerStyle.minHeight = "50vh";

  return (
    <Container
      size="small"
      style={{
        cursor: 'default'
      }}
      hoverable={true}
      title={record.sheet_name}
      className={activeAction ? "active" : ""}>
      <section style={containerStyle}>
        <section>
          <FieldSet
            component={Input}
            placeholder="Enter"
            label="Query Description"
            value={state.description}
            onChange={onChange("description")}/>
          <section className="mt16">
            {saving && <Loader type="save" size={140}/>}
            {isValidating && <Loader type="database" size={140}/>}
            {deleting && <Loader type="delete" size={140}/>}
            {!showLoader && activeAction === "query" && (
              <Editor
                value={state.expression}
                onChange={onChange("expression")}/>
            )}
            {!showLoader && activeAction === "configurations" && (
              <DatabaseConfiguration
                state={state}
                onChange={onChange}
                selected={selected}
              />
            )}
            {!showLoader && activeAction === "report" && (
              <Report
                state={state}
                onChange={onChange}
                selected={selected}/>
            )}
          </section>
        </section>
        <section>
          <Row justify="space-between" gutter={16} className="mt16">
            <Col xs={12}>
              <Button
                onClick={onEdit("query")}
                icon={<EditOutlined/>}
                block shape="round">
                {activeAction === "query" ? "Close Query Editor" : "Edit Query"}
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                shape="round"
                icon={<SettingOutlined/>}
                onClick={onEdit("configurations")}>
                {activeAction === "configurations" ? "Close Configurations" : "Edit Configurations"}
              </Button>
            </Col>
          </Row>
          <Row justify="space-between" className="mt16">
            <Col>
              <Row gutter={8}>
                <Col>
                  <Button icon={<FileTextOutlined/>} shape="round" onClick={onEdit("report")}>
                    {activeAction === "report" ? "Close Report" : "Show Output"}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={onValidate}
                    type={state.validate ? "primary" : "default"}
                    icon={state.validate ? <CheckOutlined/> : <FileSyncOutlined/>} shape="round">
                    {state.validate ? "Validated" : "Validate"}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={8}>
                <Col>
                  <Button
                    shape="round"
                    onClick={onDelete}
                    icon={<DeleteOutlined/>}>
                    Delete
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={onSave}
                    icon={<SaveOutlined/>}
                    shape="round">
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </section>
    </Container>
  )
}

export default DataSource;