import React from 'react';
//Components
import { SimpleEmpty } from "@cardoai/components";
import { EmojiBreach } from "../../../../../../../../../../../components";
//Styles
import { StyledDiv, Table } from "../../../../../styles";
//HOC
import withLimitDownload from "../../../../../components/hoc/with_limit_download";
//Icons
import { formatters, isEmpty, validateValue } from "../../../../../../../../../../../helpers";


const MainIndustry = (props: any) => {
  const {data, currency} = props;

  if (isEmpty(data))
    return <SimpleEmpty/>

  return (
    <StyledDiv>
      <Table className="fullWidth" style={{textAlign: 'center'}}>
        <thead>
        <tr>
          <th className="textCenter primary">Main Industry</th>
          <th className="textCenter primary">Outstanding</th>
          <th className="textCenter primary">%</th>
          <th className="textCenter primary">Cap/Floor</th>
          <th className="textCenter primary">Check</th>
        </tr>
        </thead>
        <tbody>
        {data.map((record: any, index:number) => (
          <tr key={index}>
            <td className="pl24">{record.name}</td>
            <td className="pl24">{formatters.currency({value: record.fulfillment, currency})}</td>
            <td className="pl24">{validateValue(record.fulfillment_percent, formatters.percent)}</td>
            <td className="pl24">
              {validateValue(record.threshold_percent, formatters.percent)}
              <span className="bolder">
                    {record.threshold_type ? ` (${record.threshold_type})` : ""}
                  </span>
            </td>
            <td style={{textAlign: 'center'}}>
              <EmojiBreach record={record}/>
            </td>
          </tr>)
        )}
        </tbody>
      </Table>
    </StyledDiv>
  );
};

export default withLimitDownload(MainIndustry)({
  title: "Main Industry Limits",
  prepareData: function (data: any) {
    return data.map((record: any) => {
      const {name, fulfillment, fulfillment_percent, threshold_percent, broken} = record;
      return {
        "Main Industry": name,
        "Outstanding": fulfillment,
        "Percentage": fulfillment_percent,
        "Cap/Floor": threshold_percent,
        "Check": broken,
      }
    });
  }
});