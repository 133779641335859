import React, { useEffect, useState } from 'react';
//CardoUI Components
import { FlatList, Tabs, Visible } from "@cardoai/components";
//Custom Hooks
import { useTabsNavigation } from "../../../../../utils/hooks";
//Helpers
import KEYS from "../keys";
import { NAVIGATOR_HELPER } from "../helpers";
import { copyArray } from "../../../../../helpers";
//Configuration
import config, { TABS } from "../config";
//Constants
import { theme } from "../../../../../assets/theme/colors";

interface Props {
  headerWidth?: any,
  fields?: any,
  vehicle?: any,
  vehicleName?: any,
  vehicleId?: any,
  section?: any,
  currency?: any
}

const Notes = (props: Props) => {
  const navigator: any = useTabsNavigation();
  const [tabKey, setTabKey] = useState(KEYS.structure)
  const {vehicle, vehicleName, vehicleId, section, fields, currency, headerWidth} = props;

  useEffect(() => {
    const currentVehicle = NAVIGATOR_HELPER.extractVehicle(navigator);
    const currentSection = NAVIGATOR_HELPER.extractSection(navigator);
    const currentSectionTab = NAVIGATOR_HELPER.extractSectionTab(navigator);

    if (currentVehicle.label === vehicleName && currentSection.key !== KEYS.notes)
      setTabKey(KEYS.structure)

    if (currentVehicle.label === vehicleName && currentSection.key === KEYS.notes && currentSectionTab.key) {
      setTabKey(previousTabKey => {
        if (previousTabKey !== currentSectionTab.key)
          return currentSectionTab.key;
        return previousTabKey;
      })
    }

  }, [navigator]);

  const onTabChange = (event: any) => {
    const tabPosition = 2;
    const tab: any = config.notes.find((rec: any) => rec.key === event);
    const copyNavigatorState = copyArray(navigator.state);

    copyNavigatorState[tabPosition].key = tab.key;
    copyNavigatorState[tabPosition].label = tab.label;

    const history = [];

    for (let index = 0; index <= tabPosition; index++) {
      if (index === tabPosition) {
        const initial = TABS.extractNotes[0];
        history.push(initial)
      } else {
        history.push(copyNavigatorState[index]);
      }
    }

    copyNavigatorState[tabPosition].clear = history;

    navigator.set(copyNavigatorState);
    setTabKey(event)
  }


  return (
    <Visible when={section === KEYS.notes}>
      <Tabs
        value={tabKey}
        section={section}
        vehicle={vehicle}
        currency={currency}
        vehicleId={vehicleId}
        headerWidth={headerWidth}
        config={config.notes}
        onChange={onTabChange}
        extraContent={
          <FlatList
            data={vehicle}
            config={fields}
            marginBottom={0}
            elementWidth={125}
            background={theme.colors.lightblue}
          />
        }/>
    </Visible>
  );
};

export default Notes;
