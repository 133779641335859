import React from "react";
//Icons
import { EuroOutlined, PercentageOutlined } from "@ant-design/icons";

export const distributionOptions = [
  {
    tipDirection: 'left',
    tip: "Percentage",
    icon: <PercentageOutlined/>,
    key: 'percentage',
  },
  {
    tipDirection: 'left',
    tip: "Amount",
    icon: <EuroOutlined/>,
    key: 'amount'
  },
];