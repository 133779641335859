import React from 'react';
//Icons
import { DownloadOutlined } from "@ant-design/icons";
//Components
import { Button, Col, Row } from "antd";
//Custom Components
import SelectButton from "./select_button";
import NPLLoanViews from "./npl_loan_views";
//Helpers
import { getDelimiter } from "../../../helpers";
import { downloadArrayOfObjectsAsCSV } from "../../../../../helpers";
//Components
import { DownloadAsCSV } from "../../../../../components";
//Static Data
import stats from "../../stats.json"

const LoanStatisticVisualisation = (props: any) => {
  const {category, data} = props;

  const [selectedView, setSelectedView] = React.useState("Table");

  const onViewChange = (newView: any) => {
    setSelectedView(newView)
  }

  const onDownload = (_: any, {decimalSeparator}: any) => {
    try {
      const columnDelimiter = getDelimiter(decimalSeparator)
      downloadArrayOfObjectsAsCSV(data, category, true, {
        columnDelimiter,
        decimalSeparator,
        canConvertDecimalSeparator: true
      });
    } catch (e) {
      console.error('export error', e);
    }
  }

  return (
    <div style={{height: 500}}>
      <Row justify="end">
        <Col>
          <SelectButton selectedView={selectedView} onViewChange={onViewChange}/>
        </Col>
        <Col>
          <DownloadAsCSV onDownload={onDownload}>
            <Button icon={<DownloadOutlined/>} size="small"/>
          </DownloadAsCSV>
        </Col>
      </Row>
      <NPLLoanViews data={data} selectedView={selectedView} categoryName={category}/>
    </div>
  )
}

const LuzzattiHDRLoanStats = () => {

  return (
    <Row justify="space-between" align="middle" gutter={32}>
      {Object.entries(stats.loan_stats).map(([category, data]: any) => {
        return (
          <Col span={12}>
            <LoanStatisticVisualisation
              category={category}
              data={data}
            />
          </Col>
        )
      })}
    </Row>
  );
};

export default LuzzattiHDRLoanStats;