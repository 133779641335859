import React from "react";
//Components
import { Col, Row } from "antd";
//Custom Components
import StackedChart from "../stacked_chart";
//Colors
import { trancheChildColors } from "../../colors";
import { theme } from "../../../../../../assets/theme/colors";
//Helpers
import { formatMillions, formatPercent } from "../../../../../../helpers";


interface ITrancheChild {
  trancheType?: any,
  currenciesForTranche?: any,
  value?: any,
  percentage?: any,
  colors?: any,
  maxHeight?: any
}

const TrancheChild = (props: ITrancheChild) => {
  const {trancheType, currenciesForTranche, value, percentage} = props;

  const getChartColor = (type: string) => (index: number) => {
    if (trancheChildColors.hasOwnProperty(trancheType))
      return type === "tranche" ? trancheChildColors[trancheType].primary : trancheChildColors[trancheType].children[index];
    return theme.colors.grey;
  }

  return (
    <Row gutter={16} className="fullHeight">
      <Col xs={12}>
        <StackedChart
          fullHeight
          getColor={getChartColor("tranche")}
          title="Tranche Distribution"
          data={[
            {
              value: value,
              label: trancheType,
              percentage: percentage
            }
          ]}
          customLabel={{formatter: formatPercent, dataset: "percentage"}}/>
      </Col>
      <Col xs={12}>
        <StackedChart
          data={currenciesForTranche}
          getColor={getChartColor("currency")}
          title="Tranche Distribution by Currency"
          customLabel={{
            formatter: formatMillions, dataset: "value"
          }}
        />
      </Col>
    </Row>
  )
}

export default TrancheChild;