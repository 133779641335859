import React from "react";
//Google Analytics
import { Analytics } from "../../../../../../helpers";
//Components
import { Button, Card, Col, Row } from "antd";
import { FlatList } from "@cardoai/components";
import { TripleSwitch } from "../../../../../../components";
//Icons
import { FormOutlined, HomeOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
//Constants
import { statisticsFields } from "./statistics/config";
import { useAuthorization } from "../../../../../../utils/hooks";
import { routeKey } from "../../../../../../constants";

const tripleConfig = (canRenderNotes: boolean, canRenderCollateral: boolean): any => ({
  left: {
    title: <FormOutlined/>,
    key: "notes",
    disabled: !canRenderNotes,
  },
  center: {
    title: "",
    key: null
  },
  right: {
    title: <HomeOutlined/>,
    key: "collateral",
    disabled: !canRenderCollateral,
  }
});

interface Props {
  redirectionRule?: any,
  redirectionSource?: any,
  section?: any,
  currency?: any,
  isLocked?: any,
  vehicle?: any,
  vehicleId?: any,
  vehicleName?: any,
  onVehicleSelect?: any,
  singleVehicleView?: any,
  setSection?: any,
  extraSpace?: any,
  headerWidth?: any,
  children?: any,
}

const HeaderContent = (props: Props) => {
  const {
    isLocked,
    vehicle,
    onVehicleSelect,
    vehicleName,
    section,
    setSection,
    children,
    singleVehicleView,
    currency
  } = props;

  const auth: any = useAuthorization();
  const cardReference: any = React.useRef();
  let servicerData = statisticsFields(currency).servicer(vehicle.vehicle_identifier === "casavo_iota");

  const onToggleLock = () => {
    const value = isLocked ? null : vehicle.vehicle_id;
    onVehicleSelect(value);
    Analytics.dispatchEvent({
      category: 'User',
      action: 'Locked a vehicle',
      label: value
    });
  }

  const canRenderNotes = auth.canRenderRoute(routeKey.notesSpvManagement);
  const canRenderCollateral = auth.canRenderRoute(routeKey.collateralSpvManagement);

  return (
    <>
      {<div ref={cardReference}>
        <Card size='small'>
          <Row gutter={16} align="middle" justify="space-between">
            <Col xs={24} sm={12} md={8} lg={6}>
              {!singleVehicleView && <p className="bolder mb8">{vehicleName}</p>}
              <Row gutter={16} align="middle">
                <Col>
                  <TripleSwitch
                    value={section}
                    onChange={setSection}
                    config={tripleConfig(canRenderNotes, canRenderCollateral)} />
                </Col>
                {!singleVehicleView && <Col>
                  <Button
                    size="small"
                    onClick={onToggleLock} shape="round" type={isLocked ? 'primary' : 'default'}
                    icon={isLocked ? <LockOutlined/> : <UnlockOutlined/>}>
                    {isLocked ? "Unlock" : ""}
                  </Button>
                </Col>}
              </Row>
            </Col>
            <Col xs={24} sm={12} md={16} lg={18}>
              <FlatList
                data={vehicle}
                marginBottom={0}
                elementWidth={125}
                displayLabels={false}
                config={servicerData}
                containerClassName='flat-list-vehicle-header'
                childrenContainerClassName='flat-list-vehicle-header'
              />
            </Col>
          </Row>
          <div className="mt8">
            {children}
          </div>
        </Card>
      </div>}
    </>
  )
};

export default HeaderContent;
