import React from 'react';
//Components
import { Modal } from "antd";
import { CustomTable, InfoTag } from "@cardoai/components";
//Helpers
import { formatters } from "../../../../../../../helpers";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TrancheCoupon = (props: any) => {
  const {currency, coupons} = props;

  const [visible, setVisible] = React.useState(false);

  const toggleCoupons = () => {
    setVisible(prevState => !prevState);
  }

  return (
    <>
      Distribution by Coupons <InfoTag onClick={toggleCoupons}/>
      <Modal width={700} centered title="Distribution by Coupons" visible={visible} footer={null}
             onCancel={toggleCoupons} closable>
        <CustomTable className="size16" centerData centerHeaders>
          <thead>
          <th>Coupon</th>
          <th>Current Balance</th>
          </thead>
          <tbody>
          {coupons.map((coupon: any, index: number) => {
            const absValueCoupon = Math.abs(coupon.amount);
            return (
              <tr key={index}>
                <td className="size16">{formatters.percent(coupon.rounded_coupon_rate)}</td>
                <td className="size16">
                  <FontAwesomeIcon icon={faMoneyBillWave}/> {formatters.currency({value: absValueCoupon, currency})}
                </td>
              </tr>
            )
          })}
          </tbody>
        </CustomTable>
      </Modal>
    </>
  );
};

export default TrancheCoupon;