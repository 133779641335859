//Helpers
import { formatters } from "../../helpers";
//Components
import ActionCell from "./components/action_cell";

const columns: any = [
  {
    key: "name",
    label: "Name",
    width: 200,
    sortable: true,
    grouping: true
  },
  {
    key: "type",
    label: "Type",
    sortable: true,
    grouping: true
  },
  {
    key: "reference_date",
    label: "Reference Date",
    format: formatters.date,
    width: 200,
    sortable: true,
    grouping: true
  },
  {
    key: "uploaded_at",
    label: "Upload Date",
    format: formatters.date,
    width: 200,
    sortable: true,
    grouping: true
  },
  {
    key: "uploaded_by",
    label: "Uploaded By",
    sortable: true,
    grouping: true
  },
  {
    key: "confirmed",
    label: "Actions",
    sortable: false,
    Component: ActionCell,
    grouping: false
  },
];

const DOCUMENT_IDENTIFIER: any = {
  "deals": "deal_documents",
  "otherDocuments": "other_documents",
  "reports": "cardo_reports",
  "otherReports": "other_reports"
}

const config = {
  [DOCUMENT_IDENTIFIER.deals]: {
    type: '0',
    usesName: true,
    picker: "date",
    documentType: 'input',
    groupName: 'input',
    allowedFiles: [".csv", ".pdf", ".docx", ".doc", ".pptx", ".ppt", ".txt", ".xls", ".xlsx", ".zip", ".xml"],
  },
  [DOCUMENT_IDENTIFIER.otherDocuments]: {
    type: '1',
    usesName: true,
    picker: "date",
    documentType: 'input',
    groupName: 'input',
    allowedFiles: [".csv", ".pdf", ".docx", ".doc", ".pptx", ".ppt", ".txt", ".xls", ".xlsx", ".zip", ".xml"],
  },
  [DOCUMENT_IDENTIFIER.reports]: {
    type: '2',
    usesName: false,
    picker: "month",
    documentType: 'input',
    groupName: 'input',
    allowedFiles: [".csv", ".xls", ".xlsx", ".docx", ".doc", ".pdf", ".zip", ".xml"],
  },
  [DOCUMENT_IDENTIFIER.otherReports]: {
    type: '3',
    usesName: true,
    picker: "month",
    documentType: 'input',
    groupName: 'input',
    allowedFiles: [".csv", ".xls", ".xlsx", ".docx", ".doc", ".pdf", ".pptx", ".ppt", ".txt", ".zip", ".xml"],
  }
};

const PANEL_POSITION: any = {
  'left': 0,
  'right': 1,
  'both': 'both'
}

export {
  config, columns, DOCUMENT_IDENTIFIER, PANEL_POSITION
}



