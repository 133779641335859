import React, { useEffect, useState } from 'react';
//Other Libs
import queryString from "query-string";
//Components
import { Spin } from "antd";
import { SmartChart } from "../../../../../../../components";
import TabularFormat from "./tabular_format";
//Helpers
import {
  callApi,
  createFileName,
  downloadClientFile,
  formatServerDate,
  formatters
} from "../../../../../../../helpers";
import notifications from "../../../../../../../components/notifications"
//Constants
import { theme } from "../../../../../../../assets/theme/colors";

const OTHER_REALIZED_CASHFLOWS = ["fee", "repayment_adjustment"]

const cashFlow = {
  realized: 'realized',
  expected: 'expected',
  others: 'others',
}

function prepareData(data: any) {
  /**
   * This function is used to prepare the datasets of the chart by adding up the records of the same payment type, so
   * they will be displayed in a single vertical bar. There are 3 different payment types that will be shown side by
   * side for each date.
   *
   * @params: data -> BE data
   */

  const records = [];

  function createRecord(date: any) {
    return {
      date: date,
      [cashFlow.expected]: 0,
      [cashFlow.realized]: 0,
      [cashFlow.others]: 0
    };
  }

  for (let date in data) {
    if (data.hasOwnProperty(date)) {
      const values = data[date];
      const record = createRecord(date);
      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          const value = values[key];
          if (value !== null) {
            //Added new cash flows to realized stack
            if (OTHER_REALIZED_CASHFLOWS.includes(key))
              record[cashFlow.realized] += value;
            else
              for (let type in cashFlow) {
                if (cashFlow.hasOwnProperty(type) && key.startsWith(type))
                  record[type] = record[type] + value;
              }
          }
        }
      }
      records.push(record);
    }
  }

  return records;
}

interface Props {
  data?: any,
  vehicleId?: number,
  download?: any,
  dateRange?: any
  currency?: any
}

const CashFlowTimeLine = (props: Props) => {
  const {vehicleId, dateRange, currency, ...otherProps} = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<any>(false);

  const fetchChartData = () => {
    setLoading(true);
    const params: any = {};
    if (dateRange && dateRange.length) {
      const [fromDate, toDate] = dateRange;
      params.from_date = formatServerDate(fromDate);
      params.to_date = formatServerDate(toDate);
    }
    const queryParams = queryString.stringify(params);
    const apiUrl = `/main/investment_management_cashflows/cashflow_timeline/?vehicle_id=${vehicleId}&${queryParams}`;
    callApi({
      url: apiUrl,
      setLoading,
      onSuccess: (response: any) => setData(response),
      onError: (error: any) => notifications.error(),
      onFinish: () => setLoading(false)
    })
  };

  useEffect(() => {
    fetchChartData();
  }, [dateRange]);


  const onDownload = () => {
    downloadClientFile({
      url: `/main/investment_management_cashflows/download_cashflow_timeline/?vehicle_id=${vehicleId}`,
      filename: createFileName("cash_flow_timeline")
    })
  }


  const dataSets: any = [
    {
      data: [],
      identifier: "realized",
      label: "Realized CashFlow",
      backgroundColor: theme.palette.us[0],
    },
    {
      data: [],
      identifier: "expected",
      label: "Expected CashFlow",
      backgroundColor: "rgba(0,206,201,0.51)",
    },
    {
      data: [],
      identifier: "others",
      label: "Other Cashflows",
      backgroundColor: theme.palette.us[2],
    },
  ];

  const labels = [];
  for (let record of prepareData(data)) {
    labels.push(formatters.shortedDate(record.date));

    // @ts-ignore
    function populateDataSet({identifier, data}: any) {
      data.push(record[identifier])
    }

    dataSets.forEach(populateDataSet);
  }

  const formatter = (value: any) => {
    return formatters.currency({value, currency})
  }

  return (
    <Spin spinning={loading}>
      <SmartChart
        usesMenu
        loading={loading}
        allowExcelDownload
        displayLabels={false}
        innerDatasets={false}
        onExcelDownload={onDownload}
        types={['bar', 'horizontalBar', 'line']}
        menuOtherOptions={{
          tableFormat: {
            data: data,
            content: TabularFormat
          },
        }}
        extraStyle={{
          top: -25,
          right: -.5
        }}
        beginAtZero
        height={400}
        displayYLines
        displayLegend
        labels={labels}
        dataSets={dataSets}
        displayXLines={false}
        style={{paddingTop: 20}}
        legendPosition='bottom'
        format={formatter}
        {...otherProps}
      />
    </Spin>
  );
};

export default CashFlowTimeLine;




