import React, { useState } from 'react';
//Other Libraries
import { SVGMap } from "react-svg-map";
import { useTheme } from "styled-components";
//Components
import ToolTip from "./components/tooltip";
//Custom Helpers
import { replaceNonAlphaNumericChars } from "../../../helpers";
import { getLocationName } from "./helpers";
//Styles
import Styles from "./styles";
//Custom Components
import LegendScale from "../../charts/legend_scale";

interface Props {
  scale?: any,
  selected?: any,
  color?: any,
  colors?: any,
  onClick: any,
  map?: any,
  height?: number | any,
  countryMode?: any,
  ratingLabel?: any
  currency?: any
  labels?: string[]
}

const legendStyle: any = {
  position: 'absolute',
  bottom: 15,
  left: -5
}

const RegionsMap = (props: Props) => {
  const {scale, labels, selected, color, colors, onClick, map, height, countryMode, ratingLabel, currency} = props;

  const {darkMode, applyCss}: any = useTheme();

  const [tipPosition, setTipPosition] = useState<any>(null);
  const [activeRegion, setActiveRegion] = useState<any>(null);

  const getColorMap = () => {
    return selected.map((record: any, index: any) => {
      return {
        name: replaceNonAlphaNumericChars(record.name),
        color: colors[index]
      }
    })
  };


  React.useEffect(() => {
    if (darkMode) {
      let css = `.region-map-color-disabled{fill:#DDD;cursor:default;} `;

      const records = getColorMap();

      records.forEach((record: any) => {
        css += `.region-map-color-${record.name}{
            fill:${record.color} !important;
            cursor:pointer;
        }
        `;
      });
      applyCss(css);
    }
  }, [darkMode]);


  if (!map)
    return null;

  const isLocationSelected = (event: any) => {
    const regionNames = selected.map((region: any) => region.name);
    return regionNames.map((entity: any) => {
      const entityName = replaceNonAlphaNumericChars(entity);
      const location = map.locations.find((location: any) => replaceNonAlphaNumericChars(location.name) === entityName);
      return location ? location.id : null
    }).filter((entity: any) => !!entity).includes(event.id)
  }

  const getLocationClassName = (location: any) => {
    const locationName = replaceNonAlphaNumericChars(location.name);
    const exist = selected.find((record: any) => replaceNonAlphaNumericChars(record.name) === locationName);
    let className = `region-map-color-`;

    if (exist)
      className += locationName;
    else
      className += "disabled";

    return className;
  }

  const handleLocationMouseOver = (event: any) => {
    const name = getLocationName(event);
    const details = selected.find((record: any) => replaceNonAlphaNumericChars(record.name) === name);
    setActiveRegion(details);
  }

  const handleLocationMouseMove = (event: any) => {
    setTipPosition({
      top: event.clientY + 10,
      left: event.clientX - 100,
      zIndex: 1,
    })
  }

  const handleLocationMouseOut = () => {
    if (activeRegion)
      setActiveRegion(null);
  }

  const handleLocationClick = (event: any) => {
    const name = getLocationName(event);
    const location: any = map.locations.find((location: any) => replaceNonAlphaNumericChars(location.name) === name);

    if (onClick)
      onClick(location);
  }


  return (
    <div style={{position: 'relative'}} className="sr-reader">
      <Styles
        color={color}
        height={height}
        colorMap={getColorMap()}>
        <ToolTip
          data={activeRegion}
          visible={tipPosition}
          position={tipPosition}
          ratingLabel={ratingLabel}
          countryMode={countryMode}
          currency={currency}
          labels={labels}
        />
        <SVGMap
          map={map}
          selectedLocationIds={selected}
          onLocationClick={handleLocationClick}
          onLocationBlur={handleLocationMouseOut}
          isLocationSelected={isLocationSelected}
          locationClassName={getLocationClassName}
          onLocationFocus={handleLocationMouseOver}
          onLocationMouseOut={handleLocationMouseOut}
          onLocationMouseMove={handleLocationMouseMove}
          onLocationMouseOver={handleLocationMouseOver}/>
      </Styles>
      <div style={legendStyle} className="disable_color_invert">
        <LegendScale scale={scale}/>
      </div>
    </div>

  );
};
export default RegionsMap;
