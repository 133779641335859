import React from 'react';
import {Col, Row} from "antd";
import {FileAvatar} from "../file";

const Uploader = ({files, setFiles, handleDragDropEvent,disabled, isFar}: any) => {
    const reference = React.useRef<any>();
    return (
        <div
            onDragEnter={handleDragDropEvent}
            onDragOver={handleDragDropEvent}
            onClick={isFar ? () => reference.current.click() :  undefined}
            onDrop={(e) => {
                handleDragDropEvent(e);
                setFiles(e, 'w');
            }}

            style={{
                padding: 8,
                borderRadius: 8,
                cursor: 'pointer',
                backgroundColor: '#fff',
                border: '1px dashed #D4D3D4'
            }}>
            <div style={{
                width: '100%',
                minHeight: 96,
                height: '100%',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: ' #F5F8FE',
            }}>
                <Row justify="center" align="middle" gutter={[8, 8]} style={{flexDirection: 'column'}}>
                    <Col>
                        <FileAvatar/>
                    </Col>
                    <Col>
                        <Row gutter={8}>
                            <Col
                                style={{
                                    fontSize: 12,
                                    color: '#2D72EA',
                                }}
                            >
                                Click to upload
                                <input
                                    ref={reference}
                                    type="file"
                                    multiple
                                    style={{display: 'none'}}
                                    onChange={(e) => {
                                        setFiles(e, 'w');
                                        reference.current.value = null;
                                    }}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col style={{fontSize: 12, color: '#8F8796'}}>
                                or drag and drop XLS
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Uploader;