import React from "react";
//Components
import { Tabs } from "antd";
//Custom Components
import NPLCounterparty from "./npl_borrower";
import NPLGuarantee from "./npl_guarantee";
import NPLRealEstate from "./npl_real_estate";
import NPLLoans from "./npl_loans";
import NPLInterimCashflows from "./npl_interim_cashflows";
//Helpers
import { downloadArrayOfObjectsAsCSV } from "../../../helpers"
import { getDelimiter } from "../helpers";

const NPLModule = () => {

  const onDownload = (data: any, title: string) => (_: any, {decimalSeparator}: any) => {
    try {
      const columnDelimiter = getDelimiter(decimalSeparator)
      downloadArrayOfObjectsAsCSV(data, `${title}.csv`, true, {
        columnDelimiter,
        decimalSeparator,
        canConvertDecimalSeparator: true
      });
    } catch (e) {
      console.error('export error', e);
    }
  }

  return (
    <Tabs>
      <Tabs.TabPane key="npl-counterparty" tab="Borrower">
        <NPLCounterparty onDownload={onDownload}/>
      </Tabs.TabPane>
      <Tabs.TabPane key="npl-loans" tab="Loans">
        <NPLLoans onDownload={onDownload}/>
      </Tabs.TabPane>
      <Tabs.TabPane key="npl-real-estate" tab="Real Estates">
        <NPLRealEstate onDownload={onDownload}/>
      </Tabs.TabPane>
      <Tabs.TabPane key="npl-guarantee" tab="Guarantees">
        <NPLGuarantee onDownload={onDownload}/>
      </Tabs.TabPane>
      <Tabs.TabPane key="npl-interim-cashflows" tab="Interim Cashflows">
        <NPLInterimCashflows onDownload={onDownload}/>
      </Tabs.TabPane>
    </Tabs>
  )
}

export default NPLModule;
