import React from 'react';
//Config
import configuration from "../../../../../../../../../../constants/config";

const InputTab = (props: any) => {
  const {vehicleIdentifier} = props;

  let InputTabContent = <div/>;
  const hasCustomSubmitView = configuration[vehicleIdentifier].notesManager.hasOwnProperty("inputTabView") || null;

  if (hasCustomSubmitView) {
    const {inputTabView: Component} = configuration[vehicleIdentifier].notesManager
    InputTabContent = <Component {...props}/>
  }

  return InputTabContent;
};

export default InputTab;