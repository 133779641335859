import React, { useEffect, useState } from 'react';
//Other Libs
import styled from "styled-components";
//Components
import { FlatList, Tabs, Visible } from "@cardoai/components";
//Helpers
import { copyArray } from "../../../../../helpers";
import { NAVIGATOR_HELPER } from "../helpers";
import { useTabsNavigation } from "../../../../../utils/hooks";
//Constants
import KEYS from "../keys";
import config, { TABS } from "../config";
import { theme } from "../../../../../assets/theme/colors";

const Styles = styled.div`
  .ant-tabs-tab {
    text-align: center !important;
    margin: 0 0.8vw 0 0 !important;
  }
`;

const getCollateral = (functionalities: any): any[] => {
  const tabs: any[] = [ config.collateral.find((f: any) => f.key === KEYS.cashFlowCollateral) ];

  if (functionalities.supports_spv_management_performance)
    tabs.push(config.collateral.find((f: any) => f.key === KEYS.performance));

  if (functionalities.supports_spv_management_diversification)
    tabs.push(config.collateral.find((f: any) => f.key === KEYS.diversification));

  if (functionalities.supports_spv_management_loan)
    tabs.push(config.collateral.find((f: any) => f.key === KEYS.loans));

  return tabs;
}

interface Props {
  redirectionRule?: any,
  vehicle?: any,
  vehicleName?: any,
  section?: any,
  vehicleId?: any,
  fields?: any,
  currency?: any,
  headerWidth?: any,
  singleVehicleView?: any,
  redirectionSource?: any,
}

const Collateral = (props: Props) => {
  const {
    redirectionRule,
    vehicle,
    vehicleName,
    section,
    vehicleId,
    fields,
    currency,
    headerWidth,
    singleVehicleView,
  } = props;

  const navigator: any = useTabsNavigation();
  const [tabKey, setTabKey] = useState(KEYS.cashFlowCollateral);

  const collateral: any[] = getCollateral(vehicle.functionalities_config);

  useEffect(() => {
    if (redirectionRule && redirectionRule.tabKey)
      setTabKey(redirectionRule.tabKey)
  }, [redirectionRule])

  useEffect(() => {
    const currentVehicle = NAVIGATOR_HELPER.extractVehicle(navigator);
    const currentSection = NAVIGATOR_HELPER.extractSection(navigator);
    const currentSectionTab = NAVIGATOR_HELPER.extractSectionTab(navigator);

    if (currentVehicle.label === vehicleName && currentSection.key !== KEYS.collateral)
      setTabKey(KEYS.cashFlowCollateral)

    if (currentVehicle.label === vehicleName && currentSection.key === KEYS.collateral && currentSectionTab.key) {
      setTabKey(previousTabKey => {
        if (previousTabKey !== currentSectionTab.key)
          return currentSectionTab.key;
        return previousTabKey;
      })
    }

  }, [navigator]);


  const onTabChange = (event: any) => {
    const tabPosition = 2;
    const tab: any = collateral.find((rec: any) => rec.key === event);

    const previousState = navigator.state;
    const currentState = copyArray(navigator.state);

    currentState[tabPosition].key = tab.key;
    currentState[tabPosition].label = tab.label;

    const mainTab: any = previousState[tabPosition];

    const previouslySelected = mainTab && mainTab.key === event

    function updateNavigation(updateIndex: any, updateTab = tab) {
      currentState[updateIndex].key = updateTab.key;
      currentState[updateIndex].label = updateTab.label;
    }

    if (previouslySelected)
      return;

    currentState.splice(3, 1);

    updateNavigation(tabPosition);
    if (tab.key === KEYS.diversification) {
      const list = TABS.extractConcentration;
      const initial = list[0];

      const concentrationConfig = {
        ...initial,
        options: {
          initial: initial,
          list: list
        }
      }
      currentState.push(concentrationConfig)
    }

    /*Create Clear History for collateral_section section*/

    const history = [];

    for (let index = 0; index <= tabPosition; index++) {
      if (index === tabPosition)
        history.push(TABS.extractNotes[0])
      else
        history.push(currentState[index]);
    }

    currentState[tabPosition].clear = history;

    navigator.set(currentState);
    setTabKey(event)
  }

  return (
    <Visible when={section === KEYS.collateral}>
      <Styles>
        <Tabs
          value={tabKey}
          section={section}
          vehicle={vehicle}
          currency={currency}
          vehicleId={vehicleId}
          onChange={onTabChange}
          headerWidth={headerWidth}
          vehicleName={vehicleName}
          config={collateral}
          redirectionRule={redirectionRule}
          singleVehicleView={singleVehicleView}
          extraContent={
            <FlatList
              data={vehicle}
              config={fields}
              marginBottom={0}
              elementWidth={125}
              background={theme.colors.aliceblue}
            />
          }/>
      </Styles>
    </Visible>
  );
}

export default Collateral;
