import React from 'react';
import { statusName } from "../../config";

const config: any = {
  'pending': {
    'label': 'Pending',
    'style': {
      'color': '#F15929',
      'background': 'rgba(241, 89, 41, 0.1)',
    },
  },
  'approved': {
    'label': 'Approved',
    'style': {
      'color': '#2D72EA',
      'background': '#EAF1FD',
    },
  },
  'rejected': {
    'label': 'Rejected',
    'style': {
      'color': '#FF4C55',
      'background': '#FFE6E8',
    },
  },
  'waiting': {
    'label': 'Waiting',
    'style': {
      'color': '#736974',
      'background': '#F2F2F2',
    },
  },
  'processing': {
    'label': 'Processing',
    'style': {
      'color': '#736974',
      'background': '#F2F2F2',
    },
  }
}

const Status = (props: any) => {
  const {record} = props;
  let viewStatus;
  const value = record.status;
  if (value === statusName["Processing"])
    viewStatus = 'processing'
  if (value === statusName["Rejected"])
    viewStatus = 'rejected'

  if (statusName["Awaiting Approval"] === value)
    viewStatus = "pending"

  if ([
    statusName["Awaiting For Submission"],
    statusName["Awaiting User Input"],
    statusName["Awaiting For Computation"]
  ].includes(value))
    viewStatus = "waiting"

  if ([
    statusName["Approved"],
    statusName["Completed"],
    statusName["Deleted"],
    statusName["Finished"]
  ].includes(value))
    viewStatus = 'approved'

  if (!viewStatus || !config.hasOwnProperty(viewStatus))
    return null;

  const {label, style} = config[viewStatus];

  return (
    <div
      title={value}
      children={label}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '74px',
        padding: '6px 8px',
        borderRadius: '8px'
      }}
    />
  );
};

export default Status;