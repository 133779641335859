import React from 'react';
//Helpers
import { useQuery } from "../../../../../../../helpers";
//Components
import AccountsTable from "../../components/accounts_table";
import { Skeleton } from "antd";

interface Props {
  currencies: any[],
  vehicleId: number | string,
  date: any,
}

const AccountsCasavo = (props: Props) => {

  const configuration = useQuery({ url: `/casavo/collateral/accounts/?vehicle_id=${props.vehicleId}` });

  if (configuration.loading)
    return <Skeleton active />;

  return (
    <AccountsTable
      dataSource={configuration.data?.results ?? []}
      loading={configuration.loading}
    />
  );
};

export default AccountsCasavo;
