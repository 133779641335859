import React from 'react';

const TableHeaders = ({columns}: any) => {
  return (
    <thead>
    <th>Asset Sale #</th>
    {columns.map((column: any,index:number) => {
      return <th key={index}>{column.label}</th>
    })}
    </thead>
  )
};

export default TableHeaders;
