import React from 'react';
import { NavigationProvider } from "../../context/navigation_tabs_provider";

interface iNavigation {
  title?: string;
  children?: any;
}

const withNavigationTabsProvider = (config: iNavigation) => (Component: any) => (props: any) => {
  return (
    <NavigationProvider title={config.title}>
      <Component {...props}/>
    </NavigationProvider>
  )
}

export default withNavigationTabsProvider;


