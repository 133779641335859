//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../assets/theme/colors";

export default styled.div<any>`
  position: relative;
  ${props => {
    const records = props.colorMap;
    const classNames = [
      `.region-map-color-disabled{fill:${theme.colors.greyshade1};cursor:default;}`
    ];
    records.forEach((record: any) => {
      classNames.push(`.region-map-color-${record.name}{
    fill:${record.color}};
    cursor:pointer; 
    `);
    });
    return classNames;
  }};

  .svg-map {
    width: 100%;
    ${props => props.height && `height: ${props.height}px`};
    stroke: ${theme.colors.white};
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .svg-map__location:focus, .svg-map__location:hover {
    outline: 0;
  }

  .svg-map__location[aria-checked=true] {
    fill: ${props => props.fill ? `${props.fill}` : `${theme.colors.mossgreen}`};
  }

  .ant-card {
    opacity: 0.9;
    display: block;
    position: fixed;
    border-radius: 8px;
  }

  .ant-card-body {
    color: ${theme.colors.white};
    font-size: 12px;
    border-radius: 8px;
    padding: 8px !important;
    background-color: rgba(0, 0, 0, .85);
  }
`;
