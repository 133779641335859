import React, {useState} from 'react';
//Components
import {Avatar, Button, Col, Comment, Popconfirm, Row} from "antd";
//Icons
import { UserOutlined } from "@ant-design/icons";
//Helpers
import { callApi } from "../../../../../../../../../../helpers";
import { STATUS_TYPE } from "../../../../../../config";
import { notifications } from "../../../../../../../../../../components";
import Notes
  from "../../../../../../../../../spv/components/management/notes_section/limits/vehicles/casavo/components/criteria_table/breach_with_notes/notes";


interface IDetailsFooter {
  notes?: any,
  status?: any,
  onCancel?: any,
  record?: any,
  getData?: any,
  vehicleId?: any,
  canApprove?: any,
  canReject?: any
}

enum ActionType {
  'approve' = 'approve',
  'reject' = 'reject'
}

const showUserMessage = (action: string) => {
  if (action === ActionType.approve)
    notifications.success("Proposal Accepted!", "Approved");
  if (action === ActionType.reject)
    notifications.error("Proposal Rejected!", "Rejected");
}

const DetailsFooter = (props: IDetailsFooter) => {
  const {notes, status, onCancel, record, getData, vehicleId, canApprove, canReject} = props;

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');


  const handleCancel = () => {
    setOpen(false);
  };

  const onAction = (action: string) => () => {
    const body = action === ActionType.reject ? {rejected_invoices: input} : {};
    callApi({
      url: `/main/notes_manager/${record.id}/${action}/?vehicle_id=${vehicleId}`,
      method: "put",
      body: body,
      onSuccess: () => {
        showUserMessage(action);
        onCancel();
        getData();
      },
      onError: (response) => {
        notifications.error(response?.data?.detail);
      }
    })
  };

  const showActions = status === STATUS_TYPE.Awaiting_Approval;

  return (
    <>
      <Row style={{width: 900}} justify="space-between" align='middle' gutter={16}>
        <Col xs={14} className="mt8">
          <b>Notes</b>
          {notes ? <Comment
            content={notes}
            avatar={<Avatar size={30} icon={<UserOutlined/>}/>}/> : <p><i>There are no notes available!</i></p>}
        </Col>
      </Row>
      {showActions &&
          <Row justify='start' align='middle' gutter={16}>
            {canApprove &&
                <Col className="mt24">
                    <Button type='primary' onClick={onAction(ActionType.approve)}>Approve Purchase</Button>
                </Col>}
            {canReject &&
                <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    title={<Notes title="Please enter invoices that you want to reject divided by comma:"
                                  description="Add invoices:" input={input} onInputChange={setInput}/>}
                    onConfirm={onAction(ActionType.reject)}
                    onCancel={handleCancel}
                >
                    <Col className="mt24">
                        <Button danger>Reject Purchase</Button>
                    </Col>
                </Popconfirm>
            }
          </Row>
      }
    </>
  )
}

export default DetailsFooter;
