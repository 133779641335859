import React from "react";
//Components
import {Collapse} from "antd";
//Custom Components
import {
  LuzzattiHDRCollateralStats,
  LuzzattiHDRLoanStats,
  LuzzattiHDRMainStats,
  LuzzattiHDRSummaryStats
} from "./components";
//Configuration
import stats from "./stats.json";

const {Panel} = Collapse;

const LuzzattiHDR = () => {
  return (
    <Collapse bordered={false} defaultActiveKey={["main-statistics", "summary-statistics"]}>
      <Panel header="Main Statistics" key="main-statistics">
        <LuzzattiHDRMainStats/>
      </Panel>
      <Panel header="Summary Statistics" key="summary-statistics">
        <LuzzattiHDRSummaryStats data={stats.summary_stats}/>
      </Panel>
      <Panel header="Loan Statistics" key="loan-statistics">
        <LuzzattiHDRLoanStats/>
      </Panel>
      <Panel header="Collateral Statistics" key="collateral-statistics">
        <LuzzattiHDRCollateralStats/>
      </Panel>
    </Collapse>
  )
};


export default LuzzattiHDR;