import React from "react";
//Components
import { CustomTable } from "@cardoai/components";
//Helpers
import { formatters } from "../../../../../../../helpers";
import { theme } from "../../../../../../../assets/theme/colors";

interface Props {
  data?: any
}

const TabularFormat = (props: Props) => {
  const {data} = props;

  return (
    <CustomTable fullHeight={false} maxHeight={400}>
      <thead>
      <tr>
        <th/>
        <th colSpan={2} style={{background: theme.palette.secondary[1]}}>
          Expected
        </th>
        <th colSpan={4} style={{background: theme.palette.secondary[3]}}>
          Realized
        </th>
      </tr>
      <tr>
        <th style={{background: theme.palette.secondary[1]}}>
          Date
        </th>
        <th style={{background: theme.palette.secondary[1]}}>
          Interest
        </th>
        <th style={{background: theme.palette.secondary[1]}}>
          Principal
        </th>
        <th style={{background: theme.palette.secondary[3]}}>
          Interest
        </th>
        <th style={{background: theme.palette.secondary[3]}}>
          Principal
        </th>
        <th style={{background: theme.palette.secondary[3]}}>
          Late Fees
        </th>
        <th style={{background: theme.palette.secondary[3]}}>
          Fees
        </th>
      </tr>
      </thead>
      <tbody>
      {Object.entries(data).map(([date, record]: any, index: number) => {
        const currencySymbol = record.currency;
        return (
          <tr key={index}>
            <td>{formatters.date(date)}</td>
            <td>{formatters.currency({value: record.expected_interest, symbol: currencySymbol})}</td>
            <td>{formatters.currency({value: record.expected_principal, symbol: currencySymbol})}</td>
            <td>{formatters.currency({value: record.realized_interest, symbol: currencySymbol})}</td>
            <td>{formatters.currency({value: record.realized_principal, symbol: currencySymbol})}</td>
            <td>{formatters.currency({value: record.realized_late_fees, symbol: currencySymbol})}</td>
            <td>{formatters.currency({value: record.fee, symbol: currencySymbol})}</td>
          </tr>
        )
      })
      }
      </tbody>
    </CustomTable>
  )
}

export default TabularFormat;
