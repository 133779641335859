import React, { useEffect, useState } from 'react';
//Constants
import { Button, Card, Col, Row, Skeleton } from "antd";
//Helpers
import { client, downloadTemplate } from "../../config";
import notifications from "../../../../components/notifications";
//Icons
import { DeleteOutlined, DownloadOutlined, PlusOutlined } from "@ant-design/icons";

const Templates = (props: any) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    setLoading(true);
    client({
      url: `/api/template/`,
      method: 'get',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      const records = response.templates;
      if (records.length === 0)
        return props.onCreate();

      return setData(records);
    }).finally(() => {
      setLoading(false);
    })
  }

  const onDelete = (record: any) => (e: any) => {
    e.stopPropagation();
    const id = record.id;
    client({
      url: `/api/template/${id}`,
      method: 'delete',
      responseType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response: any) => {
      getData();
      notifications.success(`Template ${record.template_name} has been removed`)
    })
  };

  const onDownload = (record: any) => (e: any) => {
    e.stopPropagation();
    downloadTemplate(record.id);
  }

  const onEdit = (record: any) => () => {
    props.onEdit({id: record.id})
  };

  if (loading || !data)
    return <Skeleton active/>

  return (
    <>
      <Row
        gutter={16} style={{
        minHeight: '50vh'
      }}>
        {data.map((record: any) => {
          return (
            <Col
              className="mt16"
              style={{minWidth: 260}}>
              <Card
                hoverable
                size="small"
                onClick={onEdit(record)}
                title={record.template_name}
              >
                <div style={{
                  minHeight: 150,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between"
                }}>
                  <div>
                    {record.description}
                  </div>
                  <div>
                    <Row gutter={8} justify="space-between">
                      <Col>
                        <Button
                          size="small"
                          icon={<DownloadOutlined/>}
                          onClick={onDownload(record)}>
                          Download
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          danger
                          size="small"
                          icon={<DeleteOutlined/>}
                          onClick={onDelete(record)}>
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Col>
          )
        })}
        <Col
          className="mt16"
          style={{minWidth: 260}}>
          <Card hoverable onClick={props.onCreate}>
            <Row style={{
              height: 164,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <Col>
                <PlusOutlined/>
              </Col>
              <Col>
                Add Template
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Templates;
