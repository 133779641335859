import React, { useEffect } from 'react';
//Components
import { Col, Row } from 'antd';
import { SimpleEmpty } from "@cardoai/components";
//Styles
import Styles from "./styles";
//Constants
import { MODAL_TITLE } from "../../configurations";

interface ISelectOriginator {
  fields?: any,
  onSelect?: any
  changeModalTitle?: any,
}

const SelectOriginator = (props: ISelectOriginator) => {
  const {fields, changeModalTitle, onSelect} = props;

  const [hovered, setHovered] = React.useState(null);

  useEffect(() => {
    changeModalTitle(MODAL_TITLE.unselected);
  }, []);

  const onClick = (identifier: any) => (e: any) => {
    const record = fields.find((record: any) => record.originator_identifier === identifier);
    onSelect(record);
  };


  const onMouseHover = (identifier: any) => (e: any) => {
    if (hovered !== identifier)
      setHovered(identifier)
  }

  const onMouseLeave = (e: any) => {
    setHovered(null);
  }

  const visibleBlurry = Boolean(hovered);

  if (!fields || !fields[0])
    return <SimpleEmpty description="No Originator Available"/>


  return (
    <Row justify="space-around">
      {fields.map((field: any) => {
        const items = field.options;
        const identifier = field.originator_identifier;
        const containerStyle: any = {};

        if (visibleBlurry && identifier !== hovered)
          containerStyle.filter = "blur(1px)"

        return (
          <Col xs={10} key={identifier} onClick={onClick(identifier)}>
            <Styles>
              <div className="container"
                   style={containerStyle}
                   onMouseLeave={onMouseLeave}
                   onMouseEnter={onMouseHover(identifier)}>
                <div className="title">
                  {field.originator_label}
                </div>
                <div className="fields">
                  {items.map((item: any, index: number) => {
                    return (
                      <>
                        <Row justify="space-between">
                          <Col>{item.label}</Col>
                          <Col>{item.type}</Col>
                        </Row>
                        {index !== items.length - 1 && <div className="divider"/>}
                      </>
                    )
                  })}
                </div>
              </div>
            </Styles>
          </Col>
        )
      })}
    </Row>
  );
};

export default SelectOriginator;
