import React from 'react';

const DownIcon = (props:any) => {
  return (
      <svg {...props} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.25 1.56434V1.5C0.25 1.08579 0.585787 0.75 1 0.75H7C7.41421 0.75 7.75 1.08579 7.75 1.5V1.56434C7.75 1.76325 7.67098 1.95402 7.53033 2.09467L4.59467 5.03033C4.45402 5.17098 4.26325 5.25 4.06434 5.25H3.93566C3.73675 5.25 3.54598 5.17098 3.40533 5.03033L0.46967 2.09467C0.329018 1.95402 0.25 1.76325 0.25 1.56434Z" fill="#736974"/>
      </svg>
  );
};

export default DownIcon;
