//Other Libs
import queryString from "query-string";
import { getVehicleCurrencyById } from "../../helpers";

export function getVehicleId(query: string) {
  let splitVehicle: string | any[] = []
  if (query.length) {
    splitVehicle = query.split('&vehicle_id=')
  }
  return splitVehicle.slice(1);
}


export function getInitialQuery(vehicleId: any) {
  const query = queryString.stringify({vehicle_id: vehicleId});
  return "&" + query;
}

export const extractCurrency = (vehicles: any, queryStr: string) => {
  const vehicleId = getVehicleId(queryStr);
  return getVehicleCurrencyById(vehicles, vehicleId, "id");
}