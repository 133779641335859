import React from 'react';
import {Row, Col, Skeleton} from "antd";
import Sider from "./sider";
import List from "./list";
import {useQuery} from "../../../../../../../../helpers";
import {useProposal} from "../../../../../../context";

const createOptions = (options: any) => Object.keys(options).map((key: any) => ({key: key, label: key}));

const extractInitialSection = (data: any, category: any) => {
    const sections = Object.keys(data[category]);
    return sections[0];
}

const Collateral = (props: any) => {
    const {vehicleId, proposalId, fetchProposal} = useProposal();
    const [category, setCategory] = React.useState<any>(null);
    const [section, setSection] = React.useState<any>(null);

    const {data} = useQuery({
        query: {
            vehicle_id: vehicleId,
            proposal_id: proposalId
        },
        url: `/casavo/notes_manager/draw_down_and_waterfall/?`,
        onSuccess: ((response: any) => {
            const categories: any = Object.keys(response);
            const initialCategory = categories[3];
            setCategory(initialCategory);
            setSection(extractInitialSection(response, initialCategory));
        })
    });


    if (!data || !category || !section)
        return <Skeleton active/>

    const config = data[category][section];

    return (
        <Row align="top" gutter={[16, 16]}>
            <Col xs={4}>
                <Sider
                    section={section}
                    category={category}
                    onSectionChange={setSection}
                    onCategoryChange={(event: any) => {
                        setCategory(event);
                        setSection(extractInitialSection(data, event))
                    }}
                    categories={createOptions(data)}
                    sections={createOptions(data[category])}/>
            </Col>
            <Col xs={20}>
                <List
                    config={config}
                    section={section}
                    category={category}/>
            </Col>
        </Row>
    );
};

export default Collateral;