import React from 'react'
//Components
import { Button, Space } from "antd";
//Custom Components
import { CasavoTable } from "../../../../../../../../../../../components";
//Configurations
import { config, BB_TYPE } from "./config";
//Helpers
import { useQuery } from "../../../../../../../../../../../helpers";

const getFilteredConfig = (selectedBBType: string[]) => ({
  ...config,
  columns: config.columns.filter((record: any) => {
    return record.bb_type === undefined || selectedBBType.includes(record.bb_type);
  }),
});

const LTCTable = ({vehicleId, proposalId}: any) => {
  const [selectedBBTypes, setSelectedBBTypes] = React.useState<string[]>([BB_TYPE.STANDARD]);

  const filterOptions = useQuery({
    url: `/main/real_estate/real_estate_filter_options/`
  });

  if (!filterOptions.data)
    return null;

  const changeSelectedTypes = (bbType: string) => () => {
    setSelectedBBTypes((prev: string[]) => {
      if (prev.includes(bbType)) return prev.filter((option: string) => option !== bbType);
      return [ ...prev, bbType ];
    });
  }

  const getButtonType = (bbType: string) => {
    return selectedBBTypes.includes(bbType) ? 'primary' : 'ghost';
  }

  const finalConfig: any = getFilteredConfig(selectedBBTypes);

  return (
    <>
      <CasavoTable
        height={400}
        usesFilterByStatus
        usesFilters={false}
        tableConfig={finalConfig}
        vehicleId={vehicleId}
        proposalId={proposalId}
        filterOptions={filterOptions.data}
        extra={
          <Space>
            <Button onClick={changeSelectedTypes(BB_TYPE.STANDARD)} type={getButtonType(BB_TYPE.STANDARD)}>
              Standard
            </Button>
            <Button onClick={changeSelectedTypes(BB_TYPE.SENIOR)} type={getButtonType(BB_TYPE.SENIOR)}>
              Senior BB
            </Button>
            <Button onClick={changeSelectedTypes(BB_TYPE.JUNIOR)} type={getButtonType(BB_TYPE.JUNIOR)}>
              Junior BB
            </Button>
          </Space>
        }
      />
    </>
  );
}

export default LTCTable;
