import React from 'react';
//Components
import { Input, Tabs } from "antd";
import { AddTopicModal, CreateTopicButton, EmptyTopics, TopicHeader } from "../topic";
import { ChatContent } from "./chat_content";
//Helpers
import { useGetTopics } from "../../apis";
import { callApi, isEmpty } from "../../../../helpers";
import { useAuthorization } from "../../../../utils/hooks";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounce } from "@cardoai/utils";

const {TabPane} = Tabs;

export const ChatContainer = ({vehicle}: any) => {

  const { id: vehicleId } = vehicle;

  const [visibleAddNew, setVisibleAddNew] = React.useState<boolean>(false);
  const [searchKey, setSearchKey] = React.useState<string>('');
  const search = useDebounce(searchKey, 1000);

  const {user: currentUser} = useAuthorization();
  const allTopics = useGetTopics(vehicleId, search);

  const toggleVisibility = () => {
    setVisibleAddNew(prevState => !prevState)
  }

  const onTopicCreation = (newTopicName: any) => {
    callApi({
      url: `common/topic/?pk=${vehicleId}&model=vehicle`,
      method: "post",
      body: {
        name: newTopicName
      },
      onSuccess: () => {
        toggleVisibility();
        allTopics.fetch();
      }
    })
  }

  const handleSearch = (event: any) => {
    setSearchKey(event.target.value);
  }

  const searchInput = () => (
    <div className="mb16">
      <div className="bolder mb8">Filter by Topic Name:</div>
      <Input
        size="middle"
        autoFocus
        allowClear
        style={{width: 200}}
        value={searchKey}
        onChange={handleSearch}
        prefix={<SearchOutlined/>}
        placeholder="Search by Topic Name"
      />
    </div>
  );

  if (isEmpty(allTopics.data))
    return (
      <>
        {searchInput()}
        <EmptyTopics onClick={toggleVisibility}/>
        <AddTopicModal onChange={onTopicCreation} visible={visibleAddNew} onToggle={toggleVisibility}/>
      </>
    );

  return (
    <>
      {searchInput()}
      <Tabs tabBarExtraContent={<CreateTopicButton onClick={toggleVisibility}/>}>
        {allTopics.data?.map((topic: any) => (
          <TabPane key={topic.id} tab={topic.name}>
            <TopicHeader
              fetchTopics={allTopics.fetch}
              vehicleId={vehicleId}
              topic={topic}
              currentUserId={currentUser.id}
            />
            <ChatContent
              currentUserId={currentUser.id}
              vehicleId={vehicleId}
              topic={topic}
              fetchTopics={allTopics.fetch}
            />
          </TabPane>
        ))}
      </Tabs>
      <AddTopicModal
        onChange={onTopicCreation}
        visible={visibleAddNew}
        onToggle={toggleVisibility}
      />
    </>
  );
};
