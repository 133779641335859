import React from "react";
//Other Libraries
import { Route, Routes } from "react-router-dom";
//Containers
import App from "../containers/app";
import PrivateRoute from "./private";
import Login from '../containers/login';
import { CookieConsent } from "../containers/login/components";

const PublicRoutes = () => {
  return (
    <React.Suspense fallback={null}>
      <Routes>
        <Route path="/signin" element={<Login/>}/>
        <Route path="/cookie_consent" element={<CookieConsent/>}/>
        <Route path="*" element={
          <PrivateRoute redirectTo="/signin">
            <App/>
          </PrivateRoute>
        }/>
      </Routes>
    </React.Suspense>
  );
};

export default PublicRoutes