import React from 'react';
//Components
import { Button, Col, Row, Tooltip } from "antd";
//Icons
import { BarChartOutlined, DownloadOutlined, PieChartOutlined, TableOutlined } from "@ant-design/icons";

const views: any = {
  "Table": <TableOutlined/>,
  "Bar": <BarChartOutlined/>,
  "Pie": <PieChartOutlined/>
}

const SelectButton = (props: any) => {
  const {selectedView, extra, onViewChange} = props;

  const onClick = (newView: string) => () => {
    onViewChange(newView);
  }

  return (
    <Row>
      {extra && (
        <Col>
          <Tooltip title={`View As ${extra.title}`}>
            <Button icon={extra.icon} type={selectedView === extra.key ? "primary" : "default"} size="small"
                    onClick={onClick(extra.key)}/>
          </Tooltip>
        </Col>
      )}
      {Object.keys(views).map((view: any) => {
        return (
          <Col>
            <Tooltip title={`View As ${view}`}>
              <Button icon={views[view]} type={selectedView === view ? "primary" : "default"} size="small"
                      onClick={onClick(view)}/>
            </Tooltip>
          </Col>
        )
      })}
    </Row>
  );
};

export default SelectButton;