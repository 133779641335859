import React from 'react';

const Download = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.52813 7.84687C4.42619 7.74049 4.35698 7.60706 4.32872 7.46245C4.30047 7.31785 4.31436 7.16818 4.36875 7.03125C4.42468 6.89299 4.52055 6.77455 4.64413 6.69106C4.7677 6.60756 4.91336 6.5628 5.0625 6.5625H8.25V0.75C8.25 0.551088 8.32902 0.360322 8.46967 0.21967C8.61032 0.0790178 8.80109 0 9 0C9.19891 0 9.38968 0.0790178 9.53033 0.21967C9.67098 0.360322 9.75 0.551088 9.75 0.75V6.5625H12.9375C13.0866 6.5628 13.2323 6.60756 13.3559 6.69106C13.4794 6.77455 13.5753 6.89299 13.6312 7.03125C13.6856 7.16818 13.6995 7.31785 13.6713 7.46245C13.643 7.60706 13.5738 7.74049 13.4719 7.84687L9.53437 11.7844C9.39102 11.923 9.19941 12.0005 9 12.0005C8.80059 12.0005 8.60898 11.923 8.46563 11.7844L4.52813 7.84687ZM17.25 10.5C17.0511 10.5 16.8603 10.579 16.7197 10.7197C16.579 10.8603 16.5 11.0511 16.5 11.25V16.5H1.5V11.25C1.5 11.0511 1.42098 10.8603 1.28033 10.7197C1.13968 10.579 0.948912 10.5 0.75 10.5C0.551088 10.5 0.360322 10.579 0.21967 10.7197C0.0790178 10.8603 0 11.0511 0 11.25V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V11.25C18 11.0511 17.921 10.8603 17.7803 10.7197C17.6397 10.579 17.4489 10.5 17.25 10.5Z"
        fill="#8F8796"/>
    </svg>
  );
};

export default Download;