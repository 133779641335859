//Helpers
import { formatDate, formatters, humanString, formatDatetime } from "../../../../helpers";
import { ERROR_TYPE } from "../health_check/views/error_type";
//Icons
import { CloudUploadOutlined, FileSearchOutlined } from "@ant-design/icons";
//Custom Components
import Status from "./main_table_view/status";
import Actions from "./main_table_view/actions";
import Errors from "../health_check/views/error_data_label";
import ErrorLabel from "../health_check/views/error_label";
import Import from "../synchronization/user_input_fields";
import HealthCheck from "../health_check";
import Files from "../health_check/tabs/files";
import Details from "../health_check/tabs/details"

export default {
  syncReports: {
    url: '/main/sync_request/',
    title: "Import New Data Tape",
    columns: [
      {
        key: "reference_date",
        label: "Reference Date",
        format: formatters.date,
        width: 150,
        sortable: true,
        grouping: true
      },
      {
        key: "originator",
        label: "Originator",
        format: formatters.default,
        sortable: true,
        grouping: true
      },
      {
        width: 200,
        group: false,
        key: "status",
        label: "Status",
        Component: Status,
      },
      {
        key: "completed_at",
        label: "Completed At",
        width: 150,
        format: formatDatetime
      },
      {
        key: "blocking_errors",
        label: "Blocking Errors",
        Component: Errors.Blocking,
      },
      {
        key: "warning_errors",
        label: "Warning Errors",
        Component: Errors.Warning,
      },
      {
        width: 150,
        key: "file_labels",
        label: "File Types",
        grouping: true
      },
      {
        width: 200,
        key: "sync_request_type",
        label: "Sync Type",
        align: "center",
        grouping: true
      },
      {
        width: 200,
        key: "perform_dhc",
        label: "Perform DHC?",
        format: (value: any) => {
          if (value)
            return "Yes"
          else
            return "No"
        },
        grouping: true
      },
      {
        label: "Actions",
        sortable: false,
        Component: Actions,
        group: false
      },
    ],
    header: {
      main: [
        {
          key: "reference_date",
          label: "Reference Date",
          formatter: formatDate
        },
        {
          key: "originator_name",
          label: "Originator",
          formatter: humanString
        },
        {
          key: "vehicle_name",
          label: "Spv",
          formatter: humanString
        },
        {
          key: "status",
          label: "Status",
          Component: Status
        }
        ,
        {
          key: "warning_errors",
          label: "Warning Errors",
          Component: ErrorLabel,
          componentConfig: {
            type: ERROR_TYPE.warning
          }
        },
        {
          key: "blocking_errors",
          label: "Blocking Errors",
          Component: ErrorLabel,
          componentConfig: {
            type: ERROR_TYPE.blocking
          }
        }
      ],
      details: [
        {
          key: "title",
          label: "File Type",
        },
        {
          key: "totalMissing",
          label: "Missing Columns",
          Component: ErrorLabel,
          componentConfig: {
            type: ERROR_TYPE.missing
          }
        },
        {
          key: "totalWarning",
          label: "Warning Errors",
          Component: ErrorLabel,
          componentConfig: {
            type: ERROR_TYPE.warning
          }
        },
        {
          key: "totalBlocking",
          label: "Blocking Errors",
          Component: ErrorLabel,
          componentConfig: {
            type: ERROR_TYPE.blocking
          }
        }
      ],
    },
    modal: [
      {
        modalWidth: 750,
        content: Import,
        title: 'Import Settings',
        icon: CloudUploadOutlined
      },
      {
        modalWidth: 1200,
        content: HealthCheck,
        title: 'Data Health Check',
        icon: FileSearchOutlined
      },
    ],
    tabs: [
      {
        key: 'details',
        label: 'Details',
        content: Details
      },
      {
        key: 'files',
        label: 'Files',
        content: Files
      }
    ]
  }
}

export const COLUMNS_DETAILS = [
  {
    width: 240,
    key: "column",
    label: "Column",
  },
  {
    width: 100,
    key: "row",
    label: "Row",
  },
  {
    width: 100,
    key: "severity",
    label: "Severity",
    render: (value: any) => humanString(value)
  },
  {
    width: 300,
    key: "message",
    label: "Message"
  }
];