import React, { useState } from "react";
//Other Components
import { Button, Modal, Row } from "antd";
//Icons
import { ArrowLeftOutlined } from "@ant-design/icons";
//Constants
import { actionKey } from "../../../../../../../constants";
//Hooks
import { useAuthorization } from "../../../../../../../utils/hooks";
//Components
import CreateWaverRequest from "./create_waiver_request/create_waver_request";
import WaiverRequestHistory from "./waiver_request_history/waiver_request_history";

export enum LIMITS_WAIVER_VIEW {
  NONE,
  WAIVER,
  HISTORY,
  CREATE_WAIVER,
  CREATE_EXTENSION,
  CREATE_CALCULATION,
}

const getModalConfig = (
  activeView: LIMITS_WAIVER_VIEW
): { title: string; width: number } => {
  switch (activeView) {
    case LIMITS_WAIVER_VIEW.WAIVER:
      return { title: "Waiver", width: 600 };
    case LIMITS_WAIVER_VIEW.HISTORY:
      return { title: "Show Waiver History", width: 1300 };
    case LIMITS_WAIVER_VIEW.CREATE_WAIVER:
      return { title: "Waiver", width: 600 };
    case LIMITS_WAIVER_VIEW.CREATE_EXTENSION:
      return { title: "Suspension Waiver", width: 700 };
    case LIMITS_WAIVER_VIEW.CREATE_CALCULATION:
      return { title: "Calculation Waiver", width: 950 };
    default:
      return { title: "Waiver", width: 600 };
  }
};

const getView = (activeView: LIMITS_WAIVER_VIEW): LIMITS_WAIVER_VIEW => {
  switch (activeView) {
    case LIMITS_WAIVER_VIEW.CREATE_WAIVER:
      return LIMITS_WAIVER_VIEW.WAIVER;
    case LIMITS_WAIVER_VIEW.CREATE_EXTENSION:
    case LIMITS_WAIVER_VIEW.CREATE_CALCULATION:
      return LIMITS_WAIVER_VIEW.CREATE_WAIVER;
    case LIMITS_WAIVER_VIEW.HISTORY:
      return LIMITS_WAIVER_VIEW.WAIVER;
    default:
      return LIMITS_WAIVER_VIEW.NONE;
  }
};

interface Props {
  vehicleId?: any;
  isWaived?: any;
  id?: any;
  formatter?: any;
  timeExtensionWaiver?: any;
  fulfillment_percent?:any,
  currencyLimits?:any
}

const LimitsWaiver = (props: Props) => {
  const { vehicleId, id, formatter, timeExtensionWaiver, fulfillment_percent, currencyLimits} = props;

  const [activeView, setActiveView] = useState<LIMITS_WAIVER_VIEW>(
    LIMITS_WAIVER_VIEW.NONE
  );

  const { canPerformAction } = useAuthorization();
  const canCreateWaive = canPerformAction(
    actionKey.createLimitWaiver,
    vehicleId
  );
  const createWaive = canCreateWaive;

  const closeModal = () => {
    setActiveView(LIMITS_WAIVER_VIEW.NONE);
  };

  const goBack = () => {
    setActiveView(getView(activeView));
  };

  const displayWaiver = () => setActiveView(LIMITS_WAIVER_VIEW.WAIVER);
  const displayHistory = () => setActiveView(LIMITS_WAIVER_VIEW.HISTORY);
  const displayCreateWaiver = () =>
    setActiveView(LIMITS_WAIVER_VIEW.CREATE_WAIVER);
  const displayCreateExtension = () =>
    setActiveView(LIMITS_WAIVER_VIEW.CREATE_EXTENSION);
  const displayCreateCalculation = () =>
    setActiveView(LIMITS_WAIVER_VIEW.CREATE_CALCULATION);

  const modalConfig = getModalConfig(activeView);

  return (
    <div>
      <Button size="middle" onClick={displayWaiver}>
        Waiver
      </Button>
      <Modal
        centered={true}
        visible={activeView !== LIMITS_WAIVER_VIEW.NONE}
        onCancel={closeModal}
        title={
          <>
            {activeView !== LIMITS_WAIVER_VIEW.WAIVER && (
              <ArrowLeftOutlined className="mr8" onClick={goBack} />
            )}
            {modalConfig.title}
          </>
        }
        footer={null}
        width={modalConfig.width}
      >
        <Row align="middle" justify="space-around">
          {activeView === LIMITS_WAIVER_VIEW.WAIVER ? (
            <>
              {createWaive && (
                <Button
                  type="primary"
                  size="middle"
                  shape="round"
                  onClick={displayCreateWaiver}
                  disabled={timeExtensionWaiver}
                >
                  Create Waiver Request
                </Button>
              )}
              <Button
                onClick={displayHistory}
                type="primary"
                size="middle"
                shape="round"
              >
                Waiver Request History
              </Button>
            </>
          ) : activeView === LIMITS_WAIVER_VIEW.CREATE_WAIVER ||
            activeView === LIMITS_WAIVER_VIEW.CREATE_EXTENSION ||
            activeView === LIMITS_WAIVER_VIEW.CREATE_CALCULATION ? (
            <CreateWaverRequest
              id={id}
              activeView={activeView}
              vehicleId={vehicleId}
              displayExtension={displayCreateExtension}
              displayCalculation={displayCreateCalculation}
            />
          ) : (
            <WaiverRequestHistory
              id={id}
              vehicleId={vehicleId}
              formatter={formatter}
              fulfillment_percent={fulfillment_percent}
              currencyLimits={currencyLimits}
            />
          )}
        </Row>
      </Modal>
    </div>
  );
};

export default LimitsWaiver;
