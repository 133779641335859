//Helpers
import { formatters } from "../../../../../../../helpers";

export const generalTableColumns = [
  {
    key: 'notes',
    label: 'Notes'
  },
  {
    key: 'initial_balance',
    format: formatters.thousands,
    label: 'Initial Balance'
  },
  {
    key: 'total_cashflows',
    format: formatters.thousands,
    label: 'Total Cashflow'
  },
  {
    key: 'final_factor',
    format: formatters.percent,
    label: 'Final Factor'
  },
  {
    key: 'final_maturity',
    label: 'Final Maturity'
  },
  {
    key: 'wam',
    format: formatters.thousands,
    label: 'WAM (y)'
  },
  {
    key: 'interest',
    format: formatters.thousands,
    label: 'Interest'
  },
  {
    key: 'additional_return',
    format: formatters.thousands,
    label: 'Additional Return'
  },
  {
    key: 'irr',
    format: formatters.percent,
    label: 'IRR'
  }
];
export const detailedColumns = [
  {
    "group": " ",
    "key": "payment_date",
    "label": "Payment Date",
    "align": 'center',
    "format": formatters.date
  },
  {
    "group": " ",
    "key": "collateral_cash_flows",
    "label": "Collateral cash flows",
    "format": formatters.thousands
  },
  {
    "group": "Senior notes_section",
    "key": "senior_capital",
    "label": "Principal",
    "format": formatters.thousands
  },
  {
    "group": "Senior notes_section",
    "key": "senior_interest",
    "label": "Interest",
    "format": formatters.thousands
  },
  {
    "group": "Junior notes_section",
    "key": "junior_capital",
    "label": "Principal",
    "format": formatters.thousands
  },
  {
    "group": "Junior notes_section",
    "key": "junior_interest",
    "label": "Interest",
    "format": formatters.thousands
  },
  {
    "group": "Junior notes_section",
    "key": "junior_residual",
    "label": "Additional Return",
    "format": formatters.thousands
  }
]
