import React from 'react';
//Constants
import { CRITERIA_BREACH } from "../config";
import { callApi, formatDate, humanString } from "../../../../../../../../../../../helpers";

const getTitle = (waiveredValue: any) => {
  if (waiveredValue === CRITERIA_BREACH.COMPLIANT)
    return `Passed`;
  else if (waiveredValue === CRITERIA_BREACH.BROKEN)
    return `Failed`;
  else if (waiveredValue === CRITERIA_BREACH.WAVERED)
    return `EC Waived`;
}

const ECTitle = ({vehicleId, collateralLogId, criteriaType, waiveredValue}: any) => {

  const [details, setDetails] = React.useState<any>(null);

  React.useEffect(() => {
    if (waiveredValue === CRITERIA_BREACH.WAVERED)
      getWaiveredDetails()
  }, [waiveredValue])

  const getWaiveredDetails = () => {
    callApi({
      url: `casavo/collateral/waiver-criteria/retrieve_waiver/?vehicle_id=${vehicleId}&collateral_log_id=${collateralLogId}&criteria_type=${criteriaType}`,
      onSuccess: setDetails
    })
  }

  return (
    <div>
      <span>{getTitle(waiveredValue)}</span>
      {details && <div>
        <p>Date First Waivered: {formatDate(details.date_first_waivered)}</p>
        {/*<p>Criteria Type: {humanString(details.criteria_type)}</p>*/}
        <p>Reason: {details.reason}</p>
      </div>}
    </div>
  )
};

export default ECTitle;