const formatStatistics = (data: any) => {
  if (!data)
    return null;

  const formattedVehicles = data.vehicles.filter((vehicle: any) => vehicle.status !== "onboarding").map((vehicle: any) => {
    const vehicleID = vehicle.vehicle_id;
    const otherData = data.vehicle_data.find((field: any) => field.vehicle_id === vehicleID) || []
    return {
      ...vehicle,
      ...otherData,
    }
  })

  return {
    investor: data.investor,
    vehicles: formattedVehicles
  }
}

//Custom Helpers
const getKey = (key: any) => {
  return key ? String(key) : null;
}

export {
  getKey,
  formatStatistics
}