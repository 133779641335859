import React, { useState } from 'react';
//Components
import { Col, Radio, Row, Skeleton } from "antd";
//Custom Components
import CustomChart from "./custom_chart";
//Helpers
import { formatters, useQuery } from "../../../../helpers";

const charts: any = {
    daily: [
        {
            title: 'Monthly Default Vintage Analysis',
            key: 'vintage_default_analysis',
            tickFormat: (value: any) => value
        },
        {
            title: 'Cumulative Default Vintage Analysis',
            key: 'cumulative_default_vintage_analysis',
            tickFormat: (value: any) => value
        },
        {
            title: 'Monthly Prepayment Vintage Analysis',
            key: 'vintage_prepayment_analysis',
            tickFormat: (value: any) => value
        },
        {
            title: 'Cumulative Prepayment Vintage Analysis',
            key: 'cumulative_prepayment_vintage_analysis',
            tickFormat: (value: any) => value
        }
    ],
    monthly: [
        {
            title: 'Monthly Default Rate',
            key: 'monthly_default_rate',
            labelEntity: 'defaulted_month',
            valueEntity: 'cumulative_default_ratio',
            tickFormat: formatters.shortedDate
        },
        {
            cumulative: true,
            title: 'Cumulative Default',
            key: 'cumulative_default_rate',
            labelEntity: 'defaulted_month',
            valueEntity: 'cumulative_default_ratio',
            tickFormat: formatters.shortedDate
        },
        {
            title: 'Monthly Prepayment Rate',
            key: 'prepayments',
            labelEntity: 'closing_date',
            valueEntity: 'prepayment_ratio',
            tickFormat: formatters.shortedDate
        },
        {
            cumulative: true,
            title: 'Cumulative Prepayment',
            key: 'cumulative_prepayments',
            labelEntity: 'closing_date',
            valueEntity: 'cumulative_prepayment_ratio',
            tickFormat: formatters.shortedDate
        }
    ]
}


const DefaultPrepaymentDistribution = ({vehicleId}: any) => {
  const [selectedValue, setSelectedValue] = useState('monthly')

  const {data, loading} = useQuery({
    url: () => {
      let url = `/main/vehicle_timeline/default_prepayment_distribution/?vehicle_id=${vehicleId}&`
      if (selectedValue == 'daily')
        url += `displayed_graph=vintage_analysis`
      return url;
    },
    deps: [selectedValue]
  })

    const prepareDataset = ({dataset, valueEntity, labelEntity}: any) => {

        if (!valueEntity) {

            let largest;

            for (let year in dataset) {
                const getLength = (object: any) => Object.keys(object).length;
                if (!largest || getLength(dataset[year]) > getLength(largest))
                    largest = dataset[year];
            }

            if (largest) {
                for (let year in dataset) {
                    for (let day in largest) {
                        if (!dataset[year].hasOwnProperty(day))
                            dataset[year][day] = null
                    }
                }
            }
        }

        const newDataset: any = {};
        for (let year in dataset) {
            if (!newDataset.hasOwnProperty(year))
                newDataset[year] = [];

            if (Array.isArray(dataset[year])) {
                newDataset[year] = dataset[year].map((record: any) => {
                    return {
                        value: record[valueEntity],
                        label: record[labelEntity]
                    }
                })
            } else if (typeof dataset[year] === "object") {

                const labels = {};

                for (let label in dataset[year]) {
                    newDataset[year].push({
                        value: dataset[year][label],
                        label: Number(label)
                    })
                }
                newDataset[year].sort((a: any, b: any) => Number(a.label) - Number(b.label))
            }

        }


        if (selectedValue !== "monthly")
            console.log("THE NEW WE GET", newDataset);
        return newDataset;
    }

  const onChange = (e:any)=>{
    setSelectedValue(e.target.value)
  }

  return (
    <>
      <Row justify="end">
        <Radio.Group defaultValue="monthly" buttonStyle="solid">
          <Radio.Button value="monthly" onChange={onChange}>Standard mode</Radio.Button>
          <Radio.Button value="daily"  onChange={onChange}>Vintage mode</Radio.Button>
        </Radio.Group>
      </Row>
      {!data || loading ? (
        <Skeleton active/>
      ) : (
        <Row gutter={[16, 16]}>
          {charts[selectedValue].map((chart: any) => {
            return (
              <Col xs={12}>
                <CustomChart
                  cumulative={chart.cumulative}
                  title={chart.title}
                  data={
                    prepareDataset({
                      dataset: data[chart.key],
                      ...chart
                    })}
                  labelIdentifier='label'
                  datasetIdentifier="value"
                  tickFormat={chart.tickFormat}
                />
              </Col>
            )
          })}
        </Row>
      )}
    </>
  );
};

export default DefaultPrepaymentDistribution;