import React, { useCallback, useState } from 'react';
//Components
import { Select, Spin } from "antd";
//Other Libs
import queryString from "query-string";
//Helpers
import { callApi, debounce } from "../../../../helpers";

const {Option} = Select;

interface SelectWithRemoteSearchProps {
  value?: any,
  placeholder?: string,
  width?: number,
  className?: string,
  optionKeyAttr?: any,
  optionLabelAttr?: any,
  mode?: any,
  searchUrl?: any,
  searchAttr?: any,
  searchIncludesCount?: boolean,
  onChange: any,
  searchOptions?: any,
  onSearch: (param?: any) => any,
  formatResponse: any
}

const SelectWithRemoteSearch = (props: SelectWithRemoteSearchProps) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    value, placeholder, width, className, optionKeyAttr, optionLabelAttr, mode,
    searchUrl, searchAttr, searchIncludesCount, onChange, searchOptions, onSearch,
    formatResponse
  } = props;

  const handleChange = (records: any) => {
    records.forEach((id: any) => {
      //doesnt have id here
      let searchOption: any = searchOptions.find((option: any) => option.id == id);

      if (!searchOption) {
        //so it gets the record id => loan id
        // @ts-ignore
        searchOption = data && data.find((record: any) => record.id == id);
        if (searchOption) {
          onSearch(searchOption);
        }
      }
    })
    onChange(records);
  }

  const handleSearch = useCallback(debounce(150, (searchKey: any) => {
    if (!searchKey)
      setData([])
    else {
      setLoading(true);

      const params = queryString.stringify({[searchAttr]: searchKey})

      callApi({
        url: `${searchUrl}?${params}`,
        onSuccess: (response: any) => {
          let results = searchIncludesCount ? response.results : response;
          if (formatResponse)
            results = formatResponse(results, optionKeyAttr);
          setData(results);
        },
        onFinish: () => setLoading(false),
      })
    }
  }), []);

  const getSearchOptions = () => {
    let records = data || searchOptions;
    return records.map((record: any) => {
      // @ts-ignore
      return <Option key={record[optionKeyAttr]}>{record[optionLabelAttr]}</Option>
    })
  }

  return (
    <Select
      showSearch
      mode={mode}
      autoFocus={true}
      className={className}
      value={value}
      placeholder={placeholder}
      style={width ? {width: width} : undefined}
      filterOption={false}
      defaultActiveFirstOption={false}
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={loading ? <Spin size='large'/> : null}>
      {getSearchOptions()}
    </Select>
  );
};


SelectWithRemoteSearch.defaultProps = {
  placeholder: "Search...",
  optionKeyAttr: "id",
  optionLabelAttr: "name",
  mode: "default",
  searchAttr: "name",
  searchIncludesCount: false
};

export default SelectWithRemoteSearch;