//Icons
import { BarChartOutlined, CalendarOutlined, FieldTimeOutlined } from "@ant-design/icons";

const radioConfig: any = [
  {
    key: "amortization_plan",
    title: "Amortization Plan",
    label: <FieldTimeOutlined/>
  },
  {
    key: "graph",
    title: "Graph",
    label: <BarChartOutlined/>
  },
  {
    key: "events",
    title: "Events",
    label: <CalendarOutlined/>
  }
];


export {
  radioConfig
}