import React from 'react';
//Components
import {Tooltip} from "antd";
//Icons
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort} from '@fortawesome/free-solid-svg-icons'
import {CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined} from "@ant-design/icons"
//Custom Helpers
import {checkColumnSorting} from "../../helpers";

interface Props {
    id: any,
    group: any,
    sortable: any,
    onSort: any,
    sortingFields: any,
    column: any
}

const Sort = <FontAwesomeIcon icon={faSort}/>
const SortUp = <CaretUpOutlined/>
const SortDown = <CaretDownOutlined/>

const Info = ({title}: any) => (
    <Tooltip
        title={title}
        color='#006bd0'
        key={title}
    >
        <InfoCircleOutlined style={{padding: '0 6px', color: '#006bd0'}}/>
    </Tooltip>
);

const HeadCell = (props: Props) => {
    const {id, group, sortable, onSort, sortingFields, column} = props;
    const value = column.label;
    const info = column.info;

    const customStyle: any = {
        display: "flex",
        alignItems: "center",
        flexDirection: 'row',
        justifyContent: 'center',
        cursor: "pointer",
    }

    let cellStyles: any = {
        textAlign: 'center'
    };

    if (group && group.styles)
        cellStyles = group.styles;

    if (column.width)
        cellStyles = {...cellStyles, minWidth: column.width};

    if (sortable) {
        let icon;
        const columnSorting = checkColumnSorting(sortingFields, column);

        if (!columnSorting)
            icon = Sort;
        else if (columnSorting.direction === "asc")
            icon = SortUp;
        else if (columnSorting.direction === "desc")
            icon = SortDown;

        const onClick = () => {
            onSort(column);
            return;
        };


        return (
            <th
                style={cellStyles}
                onClick={onClick}
                className={`cell-${id}`}
            >
                <div style={customStyle}>
                  <span className="text">
                    {!!info && <Info title={info}/>}
                      {value}
                  </span>
                    <span style={{marginLeft: 8}}>{icon}</span>
                </div>
            </th>
        )
    }

    return (
        <th style={cellStyles} className={`cell-${id}`}>
      <span className="text">
        {!!info && <Info title={info}/>}
          {value}
      </span>
        </th>
    );
};

export default HeadCell;
