import React from 'react';
//Other Libraries
import styled from "styled-components";
//Components
import { Col, Modal, Row } from 'antd';
import { SimpleEmpty, Tabs } from "@cardoai/components";
import { CompanyInformation, CompanyLoans } from './tabs';
//Helpers
import { withData } from "../../../../utils";
//Custom  Styles
const Styles = styled.div`
  min-height: 500px;
`;

interface ICompanyDetailsContent {
  data?: any,
  companyId?: any,
  defaultRole?: any,
  sectors?: any,
  requestData?: any
}

interface ICompanyDetailsView {
  modalView?: any,
  sectors?: any,
  defaultRole?: any,
  countries?: any,
  platforms?: any,
  visible?: any,
  companyId: any,
  onClose: (param: any) => void
}

const CompanyDetailsContent = ({data, companyId, sectors, requestData}: ICompanyDetailsContent) => {
  return (
    <Styles>
      <Row justify="center">
        <Col className="bold">
          {data.company_name}
        </Col>
      </Row>
      <Tabs
        config={[
          {
            label: "Loans",
            key: "loans",
            props: {
              role: "debtor",
              companyId: companyId,
              companyData: data
            },
            content: CompanyLoans,
          },
          {
            label: "Company Information",
            key: "companyInformation",
            props: {
              fetchCompanyData: requestData,
              companyId: companyId,
              sectors: sectors,
              data: data
            },
            content: CompanyInformation,
          }
        ]
        }/>
    </Styles>
  )
};

CompanyDetailsContent.defaultProps = {
  defaultRole: "debtor"
};
const CompanyWrapper = withData({
  urlFormatter: function (config: { props: { companyId: any; }; }) {
    const {props: {companyId}} = config;
    return `/main/company/${companyId}/`
  },
  identifierParent: "companyDetails",
  identifierChild: "companyId",
  usesDateFilters: false,
  usesCache: false,
  showIntervals: false,
  initialState: {}
})(CompanyDetailsContent);

const views = {
  internal: CompanyWrapper,
  external: CompanyDetailsContent
};

const CompanyDetailsView = (props: ICompanyDetailsView) => {
  const {visible, companyId, modalView, onClose, ...remaining} = props;

  const hasExternalData = remaining.hasOwnProperty("data");

  if (modalView && !visible)
    return null;

  if (!companyId)
    return <SimpleEmpty description="No data."/>;

  const CompanyDetails = hasExternalData ? views.external : views.internal;

  const content = (
    <CompanyDetails
      companyId={companyId}
      {...remaining}
    />
  );

  if (modalView)
    return (
      <Modal
        visible={visible}
        onCancel={onClose}
        footer={null}
        width={1600}
        destroyOnClose
        bodyStyle={{
          maxHeight: "95vh",
          overflowY: "auto"
        }}
        style={{top: 40}}
        closable={false}>
        {content}
      </Modal>
    );
  return content;
};

CompanyDetailsView.defaultProps = {
  modalView: false,
};

export default CompanyDetailsView;
