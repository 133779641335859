import React from 'react';
//Components
import { Card, Col, Row, Skeleton } from "antd";
import { BoldText } from "@cardoai/components";
import { EmojiBreach } from "../../../../../../../../../../components";
//Helpers
import { generateUrl, useQuery } from "../../../../../../../../../../helpers";

const OverviewSection = ({urlParam, vehicleId, proposalId}: any) => {
  const {data} = useQuery({
    url: generateUrl(`/mcs/${urlParam}/summary`, {
      vehicle_id: vehicleId,
      proposal_id: proposalId
    })
  });

  if (!data)
    return <Skeleton/>

  return (
    <section>
      <BoldText>Limits Overview</BoldText>
      <Row align="middle" justify="center" gutter={[16, 8]}>
        {Object.keys(data).map(limit => {
          const limitName = limit;
          const isBroken = data[limit];
          return (
            <Col key={limit}>
              <Card size="small">
                <Row align="middle" justify="center">
                  <Col className="bolder">{limitName}</Col>
                  <Col><EmojiBreach record={{broken: isBroken}}/></Col>
                </Row>
              </Card>
            </Col>
          )
        })}
      </Row>
    </section>
  );
};

export default OverviewSection;
