import React from 'react';
//Components
import { Select } from "antd";
//Constants
const Option = Select.Option;
//Styles
const containerStyle = {
  width: '100%'
}

const ChartSelect =
  ({
     onChange,
     options,
     hidden,
     selected,
     maxSize,
     minSize
   }: any) => {

    return (
      <>
        <Select
          showArrow
          mode="multiple"
          value={selected}
          onChange={onChange}
          style={containerStyle}>
          {options.map(({key, label}: any) => {

            let disabled;

            if (selected.length === maxSize)
              disabled = hidden.includes(key)

            if (selected.length === minSize)
              disabled = selected.includes(key)

            return (
              <Option
                  key={key}
                value={key}
                selected={key}
                disabled={disabled}>
                {label}
              </Option>
            )
          })}
        </Select>
      </>
    );
  };

export default ChartSelect;