import React, { useContext, useState } from "react";

const WaiverContext = React.createContext({});

interface Loan {
  loan: number | string;
  exclusion_type: string;
}

const WaiverProvider = ({ children }: any) => {
  const [loans, setLoans] = React.useState<Loan[]>([]);
  const [initialLoan, setInitialLoan] = useState<Loan[]>([]);

  const updateCalculationWaiver = (value: any) => {
    setLoans((prev: Loan[]) =>
      prev.map((l: Loan) => {
        if (l.exclusion_type === "")
          return { loan: l.loan, exclusion_type: value };
        return { loan: l.loan, exclusion_type: l.exclusion_type };
      })
    );
  };

  const updateEditWaiver = (value: any) => {
    setInitialLoan((prev: Loan[]) =>
      prev.map((l: Loan) => {
        if (l.exclusion_type === "")
          return { loan: l.loan, exclusion_type: value };
        return { loan: l.loan, exclusion_type: l.exclusion_type };
      })
    );
  };

  const updateCheckedItems = (id: string | number, checked: boolean) => {
    if (checked)
      setLoans((prev: Loan[]) => [...prev, { loan: id, exclusion_type: "" }]);
    else setLoans((prev: Loan[]) => prev.filter((l: Loan) => l.loan !== id));
  };

  const initialLoanData = (item: Loan[]) => {
    setInitialLoan(item);
  };

  const updateEditCheckedItems = (id: string | number, checked: boolean) => {
    if (checked)
      setInitialLoan((prev: Loan[]) => [
        ...prev,
        { loan: id, exclusion_type: "" },
      ]);
    else
      setInitialLoan((prev: Loan[]) => prev.filter((l: Loan) => l.loan !== id));
  };

  const getLoans = () => {
    return loans.filter((loan: Loan) => loan.exclusion_type !== "");
  };

  return (
    <WaiverContext.Provider
      value={{
        loans,
        initialLoan,
        setInitialLoan,
        updateEditCheckedItems,
        getLoans,
        updateCheckedItems,
        updateCalculationWaiver,
        updateEditWaiver,
        initialLoanData,
      }}
    >
      {children}
    </WaiverContext.Provider>
  );
};

export default WaiverProvider;

export const useWaiverContext = () => {
  return useContext(WaiverContext);
};
