import React from 'react'
//Components
import { Collapse } from "antd";
import { SimpleEmpty } from "@cardoai/components";
//Custom Components
import TableView from "./table";
//Helpers
import { generateUrl, isEmpty } from "../../../../../../../../../../helpers";
import { withData } from "../../../../../../../../../../utils";
import withLimitDownload from "../../../../components/hoc/with_limit_download";
import { showThresholdType } from "../../../../helpers";


const Panel = Collapse.Panel;


interface ConcentrationTableProps {
  data?: any,
  urlParam?: any,
  vehicleIdentifier?: any,
  vehicleId?: any,
  proposalId?: any,
  currency?: any
  showHistoricalLimits?: boolean
}

const getDefaultActiveKeys = (data: string[]): string[] => data.map((_: string, idx: number) => idx.toString());

const ConcentrationTable = (props: ConcentrationTableProps) => {

  const {data, currency, vehicleId, showHistoricalLimits} = props;

  if (!data || isEmpty(data))
    return <SimpleEmpty/>

  const limitGroups = Object.keys(data);

  return (
    <Collapse ghost defaultActiveKey={getDefaultActiveKeys(limitGroups)}>
      {limitGroups.map((group: any, index) => (
        <Panel header={group} key={index}>
          <TableView
            showHistoricalLimits={showHistoricalLimits}
            vehicleId={vehicleId}
            data={data[group]}
            currency={currency}
          />
        </Panel>
      ))}
    </Collapse>
  );
}

const ConcentrationTableContent = withData({
  urlFormatter: function (config: any) {
    const {urlParam, vehicleId, proposalId} = config.props;
    return generateUrl(`/casavo/${urlParam}/concentration_limits`, {
      vehicle_id: vehicleId,
      proposal_id: proposalId
    })
  },
})(withLimitDownload(ConcentrationTable)({
  title: 'Concentration Limits',
  prepareData: (data: any[]) => {
    const categories = Object.keys(data);
    const newStructure: any[] = [];
    categories.map((category: any) => {
      const limits = data[category];
      return limits.map((item: any) => {
        newStructure.push({
          "Category": category,
          "Name": item.name,
          "Threshold": item.threshold,
          "Realized": item.realized,
          "Threshold Type": showThresholdType(item.condition),
          "Broken?": item.broken
        })
      })
    })
    return newStructure;
  }
}));

export default ConcentrationTableContent;


