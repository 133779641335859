import React from 'react';
//Components
import { Skeleton } from "antd";
//Custom Components
import { SimpleEmpty } from "@cardoai/components";
import Layout from "./layout";
import HeadCell from "./head_cell";
import BodyCell from "./body_cell";
import GroupCell from "./group_cell";
import FamilyCell from "./family_cell";
//Helpers
import { isEmpty, MathOp } from "../../../../helpers";
import { formatValue, prepareResults } from "../helpers";

interface Props {
  tableId?: any,
  data?: any,
  externalData?: any,
  loading?: boolean,
  columns?: any,
  columnGroups?: any,
  dataId?: any,
  onClick?: any,
  filters?: any,
  renderCallbacks?: any,
  allowGrouping?: boolean,
  Panel?: any,
  children?: any,
  groupBy?: any,
  sortingFields?: any,
  expandRowIndex?: any,
  onExpand?: any,
  onSort?: any,
  searchValue?: any,
  getData?: any,
  height?: any,
  showTooltipOnCell?: any,
  onCellClick?: any
}

const Table = (props: Props) => {
  const {
    tableId, data, externalData, loading, columns, columnGroups, dataId, onClick, filters,
    renderCallbacks, allowGrouping, Panel, children, groupBy, sortingFields, expandRowIndex,
    onExpand, onSort, searchValue, getData, onCellClick, showTooltipOnCell, ...other
  } = props;

  const singleGroup = (columnGroups.length === 1 && columnGroups[0].key === "General");

  const visibleFamily = columnGroups && columnGroups.length > 1;

  const createStickyColumns = () => {

    const id = tableId || "smart-table";
    const table: any = document.getElementById(id);

    if (!table) return;

    let count: any = 0;

    const stickyClasses = columns
      .filter((col: any) => col.sticky)
      .map((col: any) => `cell-${col.key}`);


    const cellStyle = {
      head: (width: any) => ({left: width, zIndex: 11, boxShadow: 'none'}),
      body: (width: any) => ({left: width, zIndex: 7, backgroundColor: '#fff'}),
    };

    const getPrimaryClassName = (cell: any) => {
      const classes = cell.className.split(" ");
      for (let c of classes) {
        if (c.startsWith("cell"))
          return c;
      }
      return null;
    }

    const makeSticky = (element: any, params: any) => {

      const styles = {
        ...params,
        position: "sticky",
        left: params.left + "px",
        boxShadow: params.boxShadow || "inset -1px 0px rgba(0, 0, 0, 0.08)",
      };

      for (let key in styles) {
        if (styles.hasOwnProperty(key))
          element.style[key] = styles[key];
      }
    };

    const resetStyle = (element: any) => {
      /*Resetting css style is necessary since we need to fix previous sticky cols*/
      element.style.zIndex = "auto";
      element.style.position = "static";
      element.style.boxShadow = "none";
      element.style.left = "auto";
    }

    const iterateRow = (cells?: any, param2?: any) => {
      if (visibleFamily && count === 0) return;

      const index = visibleFamily ? 1 : 0;

      const isHeaderRow: any = visibleFamily && count === 1 || !visibleFamily && count === 0;

      const computeStyle = count === index ? cellStyle.head : cellStyle.body;

      let width: any = 0;
      for (const cell of cells) {
        const className = getPrimaryClassName(cell);
        const sticky = stickyClasses.includes(className);

        if (sticky)
          makeSticky(cell, computeStyle(width));
        else if (!isHeaderRow)
          resetStyle(cell);

        width = MathOp.add(width, cell.offsetWidth)
      }
    };

    for (const row of table.rows) {
      iterateRow(row.cells, count);
      count++;
    }

  };

  React.useEffect(() => {
    createStickyColumns();
  }, [columns, columnGroups]);

  if (!data.records)
    return <Skeleton active/>;


  const results = prepareResults(data, columns, groupBy, searchValue);

  function createRows(rowData: any, firstColumnVisible: any = true, extraPadding: any = false) {
    return rowData.map((record: any, index: any) => {
      const recordId = dataId ? record[dataId] : index;


      let onClickFunction, className;

      if (onClick) {
        onClickFunction = onClick(recordId);
        className = "pointerCursor";
      }

      /* Render Cell */
      return (
        <tr key={String(index)} onClick={onClickFunction} className={className}>
          {columns.map((column: any, columnIndex: any) => {
            const cellKey = `cell-${index}-${columnIndex}`;
            const emptyCell = !firstColumnVisible && columnIndex === 0;
            const extraSpacing = extraPadding && columnIndex === 0;

            return (
              <BodyCell
                {...other}
                key={cellKey}
                id={column.key}
                column={column}
                record={record}
                getData={getData}
                recordIndex={index}
                emptyCell={emptyCell}
                onCellClick={onCellClick}
                columnIndex={columnIndex}
                callbacks={renderCallbacks}
                extraSpacing={extraSpacing}
                showTooltipOnCell={showTooltipOnCell}
              />
            )
          })}
        </tr>
      )
    })
  }

  function createFamily() {
    if (!columnGroups || singleGroup)
      return null;
    return (
      <tr className="noBorder">
        {columnGroups.map((group: any, index: any) => <FamilyCell key={String(index)} group={group}
                                                                  columns={columns}/>)}
      </tr>
    )
  }

  function createHeader() {
    return (
      <>
        {createFamily()}
        <tr>
          {columns.map((column: any) => {
            const sortable = column.sortable && !emptyRecords;
            const group = columnGroups && columnGroups.find((group: any) => group.key === column.group);
            return (
              <HeadCell
                id={column.key}
                group={group}
                column={column}
                onSort={onSort}
                key={column.key}
                sortable={sortable}
                sortingFields={sortingFields}/>
            )
          })}
        </tr>
      </>
    )
  }

  function createGroups() {
    const groups: any = {};

    function createGroup(entity: any) {
      groups[entity] = [];
    }

    function updateGroup(entity: any, record: any) {
      groups[entity].push(record);
    }

    results.forEach((record: any) => {

      const groupValue = record[groupBy];

      if (!groups.hasOwnProperty(groupValue))
        createGroup(groupValue);

      updateGroup(groupValue, record);
    });

    return groups;
  }

  const emptyRecords = isEmpty(results);

  let body: any;

  if (emptyRecords) {

    body = (
      <tr>
        {
          <td colSpan={100}>
            <SimpleEmpty/>
          </td>
        }
      </tr>
    )
  } else {
    if (!allowGrouping || !groupBy) {
      body = createRows(results);
    } else {
      const groups = createGroups();

      const groupKeys = Object.keys(groups);

      const shouldGroup = groupKeys.length !== results.length

      if (shouldGroup)
        body = groupKeys.map((groupType, groupIndex) => {

          const records = groups[groupType];

          const showMainRow = records.length > 1;

          const expanded = groupIndex === expandRowIndex;

          const showChildren = expanded || !showMainRow;

          const showFirstColumn = !showMainRow || allowGrouping && showMainRow && !expanded;

          const childrenRows = showChildren ? createRows(records, showFirstColumn, true) : null;

          const column = columns.find((col: any) => col.key === groupBy);

          const value = formatValue(groupType, column.format)

          const mainRow = (
            <tr>
              {columns.map((col: any, index: any) => (
                  <GroupCell
                    column={col}
                    key={String(index)}
                    value={value}
                    records={records}
                    empty={index !== 0}
                    getData={getData}
                    expand={!showChildren}
                    onClick={onExpand(groupIndex)}/>
                )
              )}
            </tr>
          );

          return (
            <React.Fragment key={String(groupIndex)}>
              {showMainRow && mainRow}
              {childrenRows}
            </React.Fragment>
          )
        });
      else
        body = createRows(results);
    }
  }

  return (
    <Layout
      {...other}
      body={body}
      loading={loading}
      getData={getData}
      children={children}
      head={createHeader()}
      fullHeight={emptyRecords}
      id={tableId || "smart-table"}
    />
  );
};

export default Table;
