import React from 'react';
//Components
import { SimpleEmpty } from "@cardoai/components";
import { SmartTable } from "../../../../../components";
//Helpers
import { formatters, isEmpty } from "../../../../../helpers";

const tableConfig = [
  {label: "Primary Collateral", key: "primary_collateral", align: 'center'},
  {label: "Collateral ID", key: "collateral_id",},
  {label: "Security Type Description", key: "security_type_description",},
  {label: "Collateral Type", key: "collateral_type",},
  {label: "Collateral Description", key: "collateral_description",},
  {
    label: "Collateral Current Valuation (€)",
    key: "collateral_current_valuation",
    format: formatters.thousands
  },
  {
    label: "Collateral Current Coverage (%)",
    key: "collateral_current_coverage",
    format: formatters.percentOnePrecision
  },
];

interface ICollateral {
  collateralData?: any
}


const Collateral = ({collateralData}: ICollateral) => {

  if (isEmpty(collateralData))
    return <SimpleEmpty/>

  return (
    <SmartTable
      columns={tableConfig}
      externalData={collateralData}
    />
  );
};

export default Collateral;
