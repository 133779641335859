import React from 'react';
//Components
import { Col, Row } from "antd";

const HDROverview = () => {

  return (
    <Row>
      <Col sm={16}>
        <div style={{textAlign: 'justify'}}>
          <div>
            A Hyper Data Room, or HDR, is an extremely secure cloud-based environment that enables users to
            convenient share, store, and collaborate on sensitive data and files.
          </div>
          <br/>
          <div>
            Businesses typically use virtual data rooms to
            comply with industry standards, share private data with clients, or exchange documents with other
            businesses during large-scale, private transactions. While security is key for data rooms,
            their fundamental purpose is to share information.
          </div>
          <br/>
          <div>
            So, all HDRs will give users the ability to manage their documents within their
            interface,as well as manage who can access and edit the different documents and folders. Equalizer will
            provide for its users’ secure access to their transaction’s information, and execute a handful of
            operations in order to properly manage their investments.
          </div>
          <br/>
          <div>
            CardoAI HDR allows access to the best technologies and thus helps to reduce DD time by providing data
            to be used instantly, keeps data updated via an automatic sync process and provides analytics tools to
            support the assessment process.
            advanced
          </div>
          <br/>
          <div>
            Thanks to the CardoAI HDR, originators and arrangers of securitizations are able to:
            <ul>
              <li> Ensure quality, accessibility and reliability of data in all the stages of the transaction.</li>
              <li> Offer to potential investors a fully digital Due Diligence experience.</li>
              <li> Keep data always up-to-date to grant full transparency.</li>
            </ul>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HDROverview;