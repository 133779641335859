import MCS from "../containers/spv/components/management/notes_section/limits/vehicles/mcs";
import AspireLimits from "../containers/spv/components/management/notes_section/limits/vehicles/aspire";
import AspireProposalLimits from "../containers/spv/components/management/notes_section/limits/vehicles/aspire/components/proposal_limits";
//CASAVO
import CasavoLimits from "../containers/spv/components/management/notes_section/limits/vehicles/casavo"
import CasavoProposalLimits from "../containers/spv/components/management/notes_section/limits/vehicles/casavo/components/proposal_limits"
import InputTabDetails from "../containers/spv/components/management/notes_section/limits/vehicles/casavo/components/proposal_limits/input_tab";
import {
  ApolloContent,
  MCSContent
} from "../containers/notes_manager/components/table/components/table_actions/components/modal_content/views/funding/components";


const configuration: any = {
  "mcs": {
    default: {
      urlParam: "limits",
      component: MCS
    },
    notesManager: {
      id: "mcs",
      urlParam: "proposal_limits",
      tableColumns: ['senior_eur'],
      showDefaultValues: true,
      fundingView: MCSContent,
      component: MCS,
    }
  },
  "mcs_demo": {
    default: {
      urlParam: "limits",
      component: MCS
    },
    notesManager: {
      id: "mcs",
      urlParam: "proposal_limits",
      tableColumns: ['senior_eur'],
      showDefaultValues: true,
      fundingView: MCSContent,
      component: MCS,
    }
  },
  "compartment_c": {
    default: {
      component: AspireLimits,
    },
    notesManager: {
      id: "compartment_c",
      addFunding: true,
      showDefaultValues: false,
      tableColumns: ['senior_usd', 'senior_sgd', 'junior_usd', 'junior_sgd'],
      fundingView: ApolloContent,
      component: AspireProposalLimits
    }
  },
  "casavo_iota": {
    default: {
      component: CasavoLimits,
    },
    notesManager: {
      tableColumns: [],
      showDefaultValues: false,
      component: CasavoProposalLimits,
      inputTabView: InputTabDetails
    }
  },
  "casavo_iota_demo": {
    default: {
      component: CasavoLimits,
    },
    notesManager: {
      tableColumns: [],
      showDefaultValues: false,
      component: CasavoProposalLimits,
      inputTabView: InputTabDetails
    }
  }
}

export default configuration;