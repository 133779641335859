import React from 'react';
//Components
import { Skeleton, Spin } from "antd";

interface Props {
  data?: any,
  loading?: boolean,
  queryParams?: any,
  initialLoading?: any,
  Child?: any,
  requestData?: any
}

const Content = (props: Props) => {

  const {data, loading, queryParams, initialLoading, Child, requestData} = props;

  if (initialLoading) {
    if (!data)
      return <Skeleton active/>;
  }

  return (
    <Spin spinning={loading} size="large">
      <Child
        {...props}
        data={data}
        loading={loading}
        queryParams={queryParams}
        requestData={requestData}/>
    </Spin>
  );
};

export default Content;
