import React from 'react';
//Components
import { Col, Row } from "antd";
//Helpers
import { getNested, validateValue } from "../../../../../../helpers";

interface IVehicleStatistics {
  data: any,
  config: any,
  styles: any,
  currency?: any
}

const VehicleStatistics = (props: IVehicleStatistics) => {
  const {data, config, styles} = props;

  const name = data.vehicle_name;
  const currency = data.currency;

  const inlineStyle = Object.assign({}, styles);
  inlineStyle.width = 580;


  return (
    <Row align="middle" justify="space-between">
      <Col xs={6} className="textLeft" style={{whiteSpace: "normal"}}>
        {name}
      </Col>
      <Col xs={18}>
        <Row gutter={8} justify="center">
          {config(currency).map(({format, label, key}: any) => {
            const statKey = getNested(data, key);
            let value = validateValue(statKey, format);
            return (
              <Col xs={8} key={key} className="textCenter">
                <p className="bold">{value}</p>
                <small className="fw400">{label}</small>
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  )
};

export default VehicleStatistics;