import React from 'react';
//Components
import { Skeleton } from "antd";
import { SmartTable } from "../../../../../../../components";
//Config
import { reconciliation_table } from "./config";

const IncomLendAccounts = (props: any) => {
  const {vehicleId, date} = props;

  if (!date)
    return <Skeleton active/>

  return (
    <SmartTable
      url={`/main/notes/accounts/`}
      columns={reconciliation_table}
      filters={`&vehicle_id=${vehicleId}&reference_date=${date}`}
    />
  );
};

export default IncomLendAccounts;