import React from 'react';

interface ISection {
  header: any,
  body: any
}

const Section = (props: ISection) => {
  const {header, body} = props;
  return (
    <>
      <section>
        <h3>{header}</h3>
        <p>{body}</p>
      </section>
      <br/>
    </>
  );
};
Section.defaultProps = {
  header: "",
  body: ""
};

export default Section;
