import React from 'react';
import {Modal, Row, Col, Button} from "antd";

const styles: any = {
    centered: {
        textAlign: 'center'
    },
    title: {
        fontSize: 16,
        color: '#151219',
        fontWeight: 600,
        textAlign: 'center'
    },
    subTitle: {
        fontSize: 14,
        color: '#736974',
        fontWeight: 500
    }
}

const WarningModal = ({title, open, onCancel, onOk, ...rest}: any) => {
    return (
        <Modal
            {...rest}
            open={open}
            footer={null}
            onCancel={onCancel}
            bodyStyle={{
                padding: 16,
                paddingTop: 32,
                minHeight: 160
            }}>
            <Row gutter={[32, 32]}>
                <Col xs={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} style={styles.title}>
                            Warning
                        </Col>
                        <Col xs={24} style={styles.centered}>
                            <p style={styles.subTitle}>{title}</p>
                            <p style={styles.subTitle}>Are you sure you want to proceed?</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24}>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Button onClick={onCancel}>
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => {
                                onOk();
                                onCancel()
                            }} type='primary'>
                                Yes I'm Sure
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default WarningModal;