import React from "react";
//Components
import { Col } from "antd";
//Custom Components
import { ChartMenu, ChartTitle } from "../../../../index";

interface ChartHeaderProps {
  chartTitle?: any,
  selected?: any,
  usesMenu?: any,
  onChange?: any,
  chartMenu?: any,
  downloadOptions?: any,
  menuOptions?: any,
  fullscreenOptions?: any
}

const ChartHeader = (props: ChartHeaderProps) => {

  const {chartTitle, selected, usesMenu, onChange, chartMenu, downloadOptions, menuOptions, fullscreenOptions} = props;

  return (
    <div className="header">
      <ChartTitle showPlaceholder>
        {chartTitle}
      </ChartTitle>
      {usesMenu && <Col className="menu-actions">
        <ChartMenu
          selected={selected}
          onChange={onChange}
          chartMenu={chartMenu}
          {...downloadOptions}
          {...fullscreenOptions}
          {...menuOptions}
        />
      </Col>}
    </div>
  )
};


export default ChartHeader;