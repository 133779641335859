import React from 'react';
//Custom Components
import { CustomMenu } from "../../../../../index";
//Constants
import { charts, table } from "./menu_constants";

interface ChartMenuProps {
  allowDistribution: any,
  allowGrouping: any,
  allowSnapshot: any,
  fullscreen: any,
  onSnapshotDownload: () => any,
  allowExcelDownload: any,
  onExcelDownload: any,
  chartMenu: any,
  tableFormat: any,
  style: any,
  options: any,
  selected: any,
  onChange: () => any,
  onCSVDownload: () => any,
  allowCSVDownload: any,
}

const ChartMenu = (props: ChartMenuProps) => {
  const {
    allowDistribution, allowGrouping, allowSnapshot, fullscreen, onSnapshotDownload, allowExcelDownload,
    onExcelDownload, chartMenu, tableFormat, style, options, selected, onChange, onCSVDownload,
    allowCSVDownload, ...rest
  } = props;

  let finalChartMenu = options;

  if (chartMenu)
    finalChartMenu = finalChartMenu.filter((option: any) => chartMenu.includes(option.key))

  if (tableFormat)
    finalChartMenu.push(table);

  return (
    <CustomMenu
      charts={finalChartMenu}
      allowDistribution={allowDistribution}
      allowGrouping={allowGrouping}
      allowSnapshot={allowSnapshot}
      allowCSVDownload={allowCSVDownload}
      onSnapshotDownload={onSnapshotDownload}
      allowExcelDownload={allowExcelDownload}
      onExcelDownload={onExcelDownload}
      onCSVDownload={onCSVDownload}
      selected={selected}
      onChange={onChange}
      fullscreen={fullscreen}
      {...rest}
    />
  );
};

ChartMenu.defaultProps = {
  options: charts,
}

export default ChartMenu;


