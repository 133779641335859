import React from 'react';
//Logo
import logo from "../../../../../assets/images/logo/large.png";

const SideLogo = () => {
  return (
    <div className="side-logo">
      <div className="logo-container">
        <div className="login-animation">
          <img src={logo} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default SideLogo;