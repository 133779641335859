import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import WorldMap from "../../index";
import { CountryCard } from "../../../../index";
//Styles
import { ManagementStyles } from "./styles";

interface Props {
  continent: string,
  view: any,
  countries: any,
  onClick: any,
  showCountryCards: boolean
  currency?: any
}

const CountryView = (props: Props) => {
  const {continent, view, countries, onClick, showCountryCards, currency} = props;

  const mapContent = <Col xs={24} lg={showCountryCards ? 12 : 24}>
    <WorldMap currency={currency} view={view} data={countries} onClick={onClick} continent={continent}/>
  </Col>

  if (showCountryCards) return <Row gutter={8}>
    {mapContent}
    <Col xs={24} lg={12}>
      <ManagementStyles>
        <Row>
          {countries.map((country: any) => {
            return <Col span={24 / countries.length}>
              <CountryCard
                onClick={onClick}
                country={country}
                currency={currency}/>
            </Col>
          })}
        </Row>
      </ManagementStyles>
    </Col>
  </Row>

  return <Row>{mapContent}</Row>
};

export default CountryView;

CountryView.defaultProps = {
  continent: "world",
  view: "dashboard",
  showCountryCards: true
}
