import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import TrancheChild from "./tranche_child";
import NotesDashedBorder from "./notes_dashed_border";
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { isEmpty } from "../../../../../../helpers";
import { notesChartColors as chartColors } from "../../colors";


const NotesChart = ({data}: any) => {

  if (isEmpty(data))
    return <SimpleEmpty description="No Data for Notes"/>

  const notes = data.map((record: any, index: number) => {

    const {label, currencies, percentage, value} = record;

    const lastElement = index + 1 === data.length;
    const height = `${percentage * 100}%`;

    return (
      <Col key={index} style={{height: height, position: 'relative'}}>
        <TrancheChild
          value={value}
          trancheType={label}
          percentage={percentage}
          currenciesForTranche={currencies}
          colors={chartColors.notes_list_junior}/>
        {!lastElement && <NotesDashedBorder/>}
      </Col>
    )
  })

  return (
    <Row gutter={16} className="columnDirection" style={{height: '100%'}}>
      {notes}
    </Row>
  );
};

export default NotesChart;