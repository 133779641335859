import React from 'react';
//Components
import { SimpleEmpty } from "@cardoai/components";
import DrawdownAndWaterfallTab from "./table_content";
//Helpers
import { generateUrl, isEmpty, useQuery } from "../../../../../../../../../../../helpers";

interface Props {
  currency?: any,
  vehicleId?: any,
  proposalId?: any
}

const DrawdownAndWaterfall = (props: Props) => {
  const {currency, vehicleId, proposalId} = props

  const drawdown = useQuery({
    url: generateUrl(`/casavo/notes_manager/draw_down_and_waterfall`, {
      vehicle_id: vehicleId,
      proposal_id: proposalId
    })
  });

  const data = drawdown.data;

  if (!data || isEmpty(data))
    return <SimpleEmpty description="No Data for Drawdown and Waterfall"/>

  return (
    <DrawdownAndWaterfallTab
      data={data}
      currency={currency}
      vehicleId={vehicleId}
      proposalId={proposalId} />
  )
}

export default DrawdownAndWaterfall;
