export const worldRegions: any = {
  "dashboard": [
    {
      key: 'world',
      label: 'Worldwide',
      config: {
        projectionScale: 75,
        projectionTranslation: [0.5, 0.65]
      }
    },
    {
      key: 'europe',
      label: 'Europe',
      config: {
        projectionScale: 350,
        projectionTranslation: [0.3, 1.3]
      }
    },
    {
      key: 'middleEast',
      label: 'Middle East',
      config: {
        projectionScale: 350,
        projectionTranslation: [-0.05, 1]
      }
    },
    {
      key: 'centralAsia',
      label: 'Central Asia',
      config: {
        projectionScale: 300,
        projectionTranslation: [-0.5, 0.8]
      }
    },
    {
      key: 'farEast',
      label: 'Far East Asia',
      config: {
        projectionScale: 200,
        projectionTranslation: [-0.5, 0.8]
      }
    },
    {
      key: 'north_america',
      label: 'North America',
      config: {
        projectionScale: 200,
        projectionTranslation: [1.5, 0.9]
      }
    },
    {
      key: 'south_america',
      label: 'South America',
      config: {
        projectionScale: 250,
        projectionTranslation: [1.3, 0.2]
      }
    },
    {
      key: 'africa',
      label: 'Africa',
      config: {
        projectionScale: 250,
        projectionTranslation: [0.35, 0.5]
      }
    },
    {
      key: 'australia',
      label: 'Australia',
      config: {
        projectionScale: 300,
        projectionTranslation: [-1.5, 0.1]
      }
    },
  ],
  "management": [
    {
      key: 'world',
      label: 'Worldwide',
      config: {
        projectionScale: 120,
        projectionTranslation: [0.5, 0.65]
      }
    },
    {
      key: 'europe',
      label: 'Europe',
      config: {
        projectionScale: 350,
        projectionTranslation: [0.3, 1.3]
      }
    },
    {
      key: 'middleEast',
      label: 'Middle East',
      config: {
        projectionScale: 400,
        projectionTranslation: [0.15, 0.95]
      }
    },
    {
      key: 'asia',
      label: 'Asia',
      config: {
        projectionScale: 320,
        projectionTranslation: [-0.1, 0.8]
      }
    },
    {
      key: 'north_america',
      label: 'North America',
      config: {
        projectionScale: 250,
        projectionTranslation: [0.8, 1]
      }
    },
    {
      key: 'south_america',
      label: 'South America',
      config: {
        projectionScale: 250,
        projectionTranslation: [0.8, 0.2]
      }
    },
    {
      key: 'africa',
      label: 'Africa',
      config: {
        projectionScale: 350,
        projectionTranslation: [0.35, 0.5]
      }
    },
    {
      key: 'australia',
      label: 'Australia',
      config: {
        projectionScale: 400,
        projectionTranslation: [-0.35, 0.1]
      }
    },
  ],
  "virtualDataRoom": [
    {
      key: 'world',
      label: 'Worldwide',
      config: {
        projectionScale: 120,
        projectionTranslation: [0.5, 0.65]
      }
    },
    {
      key: 'europe',
      label: 'Europe',
      config: {
        projectionScale: 350,
        projectionTranslation: [0.3, 1.3]
      }
    },
    {
      key: 'middleEast',
      label: 'Middle East',
      config: {
        projectionScale: 400,
        projectionTranslation: [0.15, 0.95]
      }
    },
    {
      key: 'asia',
      label: 'Asia',
      config: {
        projectionScale: 320,
        projectionTranslation: [-0.1, 0.8]
      }
    },
    {
      key: 'north_america',
      label: 'North America',
      config: {
        projectionScale: 250,
        projectionTranslation: [0.8, 1]
      }
    },
    {
      key: 'south_america',
      label: 'South America',
      config: {
        projectionScale: 250,
        projectionTranslation: [0.8, 0.2]
      }
    },
    {
      key: 'africa',
      label: 'Africa',
      config: {
        projectionScale: 350,
        projectionTranslation: [0.35, 0.5]
      }
    },
    {
      key: 'australia',
      label: 'Australia',
      config: {
        projectionScale: 400,
        projectionTranslation: [-0.35, 0.1]
      }
    },
  ],
};


export const legendConfig: any = [
  {
    anchor: 'bottom-left',
    direction: 'column',
    justify: true,
    translateX: 4,
    translateY: 0,
    itemsSpacing: 0,
    itemWidth: 92,
    itemHeight: 18,
    itemDirection: 'left-to-right',
    itemOpacity: 0.85,
    symbolSize: 18
  }
]

export const countryMap: any = {
  "United Kingdom": "UK",
  "United States": "USA",
  "Russian Federation": "Russia",
  "Viet Nam": "Vietnam",
  "United States of America": "USA"
}
