//Colors
import { theme } from "../../../../assets/theme/colors";
//Helpers
import { formatters } from "../../../../helpers";

export const config: any = [
  {key: "originator", width: 100, label: "Originator", group: "Guarantee Details", grouping: true},
  {key: "borrower_id", label: "Borrower ID",width: 100, group: "Guarantee Details", grouping: true},
  {key: "borrower_name", width: 350, label: "Borrower Name", group: "Guarantee Details", grouping: true},
  {key: "loan_id", label: "Loan ID", group: "Guarantee Details", grouping: true},
  {key: "guarantee_id", label: "Guarantee ID", group: "Guarantee Details", grouping: true},
  // {key: "guarantor_name", label: "Guarantor Name", group: "Guarantee Details"},
  {key: "guarantor_type", width: 150, label: "Guarantor Type", group: "Guarantee Details", grouping: true},
  {key: "guarantor_vat", width: 150, label: "Guarantor VAT / Tax Number", group: "Guarantee Details"},
  {key: "guaranteed_amount", label: "Guaranteed Amount", group: "Guarantee Details", format: formatters.thousands},
  {
    key: "activation_of_guarantee",
    width: 150,
    label: "Activation of Guarantee (Y/N)",
    group: "Guarantee Details",
    grouping: true
  },
  {
    key: "activation_date_of_guarantee",
    width: 150,
    label: "Activation Date of Guarantee",
    group: "Guarantee Details",
    format: formatters.safeDate,
  },
  {key: "type", label: "Type", group: "Guarantee Details",width: 100},
  {key: "procedure_status", width: 150, label: "Procedure Status", group: "Guarantee Details", grouping: true},
  {key: "rge", label: "RGE", group: "Enforcement Status",width:100 },
  {key: "court", label: "Court", group: "Enforcement Status", grouping: true,width:100},
  {key: 'notes', label: 'Notes', width: 150, group: "Additional Information", grouping: true},
  // {key: "hdr", label: "HDR(x)", group: "Enforcement Status", grouping: true},
];

export const columnGroups: any = [
  {
    key: "Guarantee Details",
    styles: {
      background: theme.palette.border[2],
      borderColor: theme.colors.white,
      color: 'rgba(0, 0, 0, 0.65)'
    },
  },
  {
    key: "Enforcement Status",
    styles: {
      background: theme.palette.border[3],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Additional Information",
    styles: {
      background: theme.palette.border[9],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
];
