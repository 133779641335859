import React from "react";
//Custom Components
import ScaleChart from "./index";
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { theme } from "../../../../../assets/theme/colors";
import { formatters, generateUrl, humanString, isEmpty, useQuery } from "../../../../../helpers";
//Configurations
import { chartConfig } from "./config";

interface IContributorsChart {
  entity: string | undefined,
  queryStr: string | undefined,
}

const ContributorsChart = ({entity, queryStr}: IContributorsChart) => {

  const url = generateUrl("/main/loan_distribution/contributors_to_return", {entity: entity});

  const chartRequest = useQuery({
    url: queryStr ? url + queryStr : url,
  })

  React.useEffect(() => {
    chartRequest.updateQuery({entity: entity})
  }, [entity, queryStr])

  if (isEmpty(chartRequest.data))
    return <SimpleEmpty/>

  const title = humanString(entity);
  const scaleChartConfig = Object.assign(chartConfig, {title: title});

  return (
    <ScaleChart
      beginZero
      height={400}
      title={title}
      yAxisLabel="WAR"
      types={['bubble']}
      formatXAxis={false}
      tipFormatXAxis='days'
      showAxisLabels={false}
      data={chartRequest.data}
      xAxisLabel="Delinquence"
      config={scaleChartConfig}
      customColors={theme.palette.us}
      format={formatters.percentNoPrecision}/>
  );
};


export default ContributorsChart;
