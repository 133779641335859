import React from 'react';

const More = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.21631 12.749H18.2163" stroke="#8F8796" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.46631 8.24902H21.9663" stroke="#8F8796" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.96631 17.249H14.4663" stroke="#8F8796" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default More;