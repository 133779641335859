import React from 'react';
//Components
import { Skeleton } from "antd";
import { useQuery } from "../../../../../../../../../../../helpers";
import AccountsTable from "../../../../../../components/accounts_table";

interface Props {
  currency: any,
  vehicleId: any,
  proposalId: any,
  id?:any
}

const Accounts = (props: Props) => {
  const { id, vehicleId } = props

  const configuration = useQuery({ url: `/casavo/collateral/${id}/proposal_accounts/?vehicle_id=${vehicleId}` });

  if (configuration.loading) return <Skeleton active />;

  return (<AccountsTable dataSource={configuration.data?.results ?? []} loading={configuration.loading} hideFilter/>)
};

export default Accounts;
