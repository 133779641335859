import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage, DefinitionList } from "../../../../components";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../../assets";

const HDRSummaryStatistics = () => {
  return (
    <Row style={{paddingLeft: '20px'}}>
      <Col sm={22}>
        <CustomImage
          description={<div>
            Summary Statistics
          </div>}
          alt="Summary Stats"
          src={assets.images.HDRModule.summaryStatistics}
        />
        <div style={{textAlign: 'justify'}}>
          <DefinitionList
            data={[
              {
                term: "Original Principal(€)",
                explanation: "Total investment amount at the beginning.",
              },
              {
                term: 'Nr.loans (#)',
                explanation: 'Total numbers of the loans issued.'
              },
              {
                term: 'Average Loan Size',
                explanation: 'Average loan amount calculated as total investments for that year divided by the number of respective loans issued.'
              },
              {
                term: 'Average Term at Inception(months)',
                explanation: 'Average duration of loan residual life at the moment it is issued, in months.'
              },
              {
                term: 'Average Term Adjusted',
                explanation: 'Average Term adjusted after the COVID Loan Freeze and Prepayments, in months.'
              },
              {
                term: 'Principal Outstanding(€)',
                explanation: 'Expected principal balance to be paid.'
              },
              {
                term: 'Principal Outstanding(% of original)',
                explanation: 'Expected principal shown as a % of the initial amount.'
              },
              {
                term: 'Arrears(€)',
                explanation: 'Amount that is owed but which should have been paid earlier.'
              },
              {
                term: 'Arrears Rate',
                explanation: 'Unpaid amounts from the rating year shown as a percentage.'
              },
              {
                term: 'Cumulative Default(€)',
                explanation: 'Total amount of defaulted loans in that period.'
              },
              {
                term: 'Cumulative Default Rate',
                explanation: 'The percentage of defaulted loans, calculated from the division of defaulted loans by the investment amount for that period.'
              },
              {
                term: 'Expected Recovery',
                explanation: 'Amount expected to be recovered from the loans issued in the event of a default or bankruptcy.'
              },
              {
                term: 'Expected Recovery Rate',
                explanation: 'Estimated percent of a loan or obligation that will still be repaid to creditors in the event of a default or bankruptcy.'
              },
              {
                term: 'Borrower APR',
                explanation: 'Annual cost of a loan to a borrower, which can include other charges or fees such as mortgage insurance, most closing costs, discount points and loan origination fees.'
              },
              {
                term: 'Investor APR',
                explanation: 'Annual cost of an investment to an investor.',
              },
              {
                term: 'IRR-Upside Case',
                explanation: 'Net Returns before expenses, discounting future cash flows calculated assuming 70% of expected losses.',
              },
              {
                term: 'IRR-Base Case',
                explanation: 'Net Returns before expenses, discounting future cash flows calculated assuming 100% of expected losses.',
              },
              {
                term: 'IRR-Downside Case',
                explanation: 'Net Returns before expenses, discounting future cash flows calculated assuming 130% of expected losses.',
              },
              {
                term: 'Average Guarantee(weighted on lending amount)',
                explanation: 'average size of guarantee as a percentage, calculated over initial lending amount.',
              },
              {
                term: 'Average Guarantee(weighted on outstanding amount)',
                explanation: 'average size of guarantee as a percentage, calculated over the current outstanding amount.',
              },
              {
                term: 'Average Annual Interest Rate',
                explanation: 'Annual percentage on average, that the lender charges on its original principal amount.',
              },
              {
                term: 'Average Borrower Upfront Fee',
                explanation: 'Average fees paid to a lender by a borrower as consideration for issuing a new loan, calculated as fees divided by the issued amount.',
              },
            ]}
          />
        </div>
      </Col>
    </Row>
  );
};
export default HDRSummaryStatistics;
