import React from 'react';
//Components
import { Button, Col, Row, Space } from "antd";
//Custom Components
import Sync from "../../sync_table/main_table_view/actions/sync";
import Delete from "../../sync_table/main_table_view/actions/delete";
//Icons
import { SaveOutlined } from "@ant-design/icons";

interface Props {
  loading?: boolean,
  enableSave?: any,
  onSave?: any,
  id?: any,
  status?: any,
  fileType?: any,
  makeRequest: any,
  vehicleId?: any
}

const Footer = (props: Props) => {
  const {loading, enableSave, onSave} = props;

  return (
    <Row justify="end">
      <Col>
        <Space>
          <Button
            type="primary"
            onClick={onSave}
            loading={loading}
            size='small'
            disabled={!enableSave}
            icon={<SaveOutlined/>}>
            Save Changes
          </Button>
          <Sync {...props}/>
          <Delete {...props}/>
        </Space>
      </Col>
    </Row>
  );
};

export default Footer;