import React, { useState } from "react";
//Components
import { Popconfirm, Tooltip } from "antd";
//Custom Components
import Notes from "./notes";
import ECTitle from "./ec_title";
//Helpers
import { CRITERIA_BREACH } from "../config";
import { callApi } from "../../../../../../../../../../../helpers";
import { useAuthorization } from "../../../../../../../../../../../utils/hooks";
import { actionKey } from "../../../../../../../../../../../constants";
import { STATUS_TYPE } from "../../../../../../../../../../notes_manager/components/table/config";

const getColor = (waiveredValue: any) => {
  if (waiveredValue === CRITERIA_BREACH.COMPLIANT)
    return "green";
  else if (waiveredValue === CRITERIA_BREACH.BROKEN)
    return "red"
  else if (waiveredValue === CRITERIA_BREACH.WAVERED)
    return "orange";
}

const BreachWithNotes = ({
                           waiveredValue,
                           refreshData,
                           criteriaType,
                           transactionId,
                           proposalId,
                           vehicleId,
                           status,
                           collateralLogId
                         }: any) => {

  const [input, setInput] = useState('');

  const {canPerformAction} = useAuthorization();

  const canWaiveProposal = canPerformAction(actionKey.casavoCreateWaiverCriteria, vehicleId)


  const onConfirm = () => {
    callApi({
      url: `/casavo/collateral/waiver-criteria/?vehicle_id=${vehicleId}`,
      method: "POST",
      body: {
        reason: input,
        criteria_type: criteriaType,
        original_collateral_identifier: transactionId,
        proposal: proposalId,
      },
      onSuccess: refreshData,
      successMessage: "EC Waived successfully!"
    })
  }

  const color = getColor(waiveredValue);
  const rotate = color === "red" ? 'rotate(180deg) translateY(5px)' : "";

  const Component = <Tooltip
    title={<ECTitle vehicleId={vehicleId} criteriaType={criteriaType}
                    waiveredValue={waiveredValue} collateralLogId={collateralLogId}/>}>
    <div style={{transform: rotate}}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="30" height="30" viewBox="0 0 1000.000000 1000.000000"
           preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)"
           fill={color} stroke="none">
          <path d="M4850 7444 c-407 -35 -748 -139 -1058 -323 -653 -386 -1076 -1051
                  -1166 -1831 -9 -74 -16 -163 -16 -197 l0 -63 353 0 354 0 12 128 c21 234 74
                   427 171 627 233 479 647 805 1166 916 97 21 134 24 339 24 200 -1 243 -4 332
                  -23 750 -163 1282 -785 1341 -1567 l7 -100 354 0 354 0 -2 85 c-6 245 -74 567
                  -172 812 -324 815 -1034 1373 -1897 1492 -112 16 -387 27 -472 20z"/>
          <path d="M4000 5360 l0 -330 325 0 325 0 0 330 0 330 -325 0 -325 0 0 -330z"/>
          <path d="M5352 5363 l3 -328 325 0 325 0 0 325 0 325 -328 3 -327 2 2 -327z"/>
          <path d="M2740 4195 c0 -56 145 -373 245 -534 334 -541 893 -933 1510 -1062
                   227 -47 541 -63 760 -39 801 89 1483 556 1858 1271 48 90 131 287 152 357 l6
                   22 -790 0 -791 0 -82 -61 c-375 -278 -863 -272 -1234 16 l-57 45 -789 0 c-704
                   0 -788 -2 -788 -15z"/>
        </g>
      </svg>
    </div>
  </Tooltip>

  if (![STATUS_TYPE.Awaiting_Submission, STATUS_TYPE.Awaiting_User_Input].includes(status))
    return Component;

  if (canWaiveProposal)
    return Component;

  return (
    <Popconfirm
      okText="Yes"
      cancelText="No"
      onConfirm={onConfirm}
      title={<Notes title="Are you sure you want to waive this limit ?" description="Add Note:" input={input} onInputChange={setInput}/>}
      disabled={[CRITERIA_BREACH.WAVERED].includes(waiveredValue)}>
      {Component}
    </Popconfirm>
  )
}

export default BreachWithNotes;
