import React from 'react';
//Components
import { Checkbox, Col, Row } from "antd";
import CookieDeclaration from "./cookie_declaration";
//Configurations
import { COOKIE_INFORMATION, COOKIE_TYPES } from "../config";
import { capitalize } from "../../../../../helpers";

//Custom Style
const style = {
  label: {cursor: 'pointer', fontSize: 12, paddingLeft: 10},
  column: {display: 'block'}
}

const CookieSettings = (props: any) => {

  const {onConsentChange} = props;

  const [cookieInfo, setCookieInfo] = React.useState(COOKIE_TYPES.necessary);

  const showCookieInfo = (cookieType: any) => (e: any) => {
    setCookieInfo(cookieType);
  }

  const onCheckboxChange = (event: any) => {
    onConsentChange(event.target.checked);
  }

  return (
    <Row>
      <Col span={4}>
        <Row style={style.column}>
          {Object.keys(COOKIE_INFORMATION).map(cookie => {
            const disabled = COOKIE_INFORMATION[cookie].disabled;
            const cookieQty = COOKIE_INFORMATION[cookie].table.length;
            return (
              <Col>
                <Checkbox defaultChecked disabled={disabled} onChange={onCheckboxChange}/>
                <span style={style.label} className={cookieInfo === cookie ? "bolder" : ''}
                      onClick={showCookieInfo(cookie)}>{capitalize(cookie)} ({cookieQty})</span>
              </Col>
            )
          })}
        </Row>
      </Col>
      <Col span={20}>
        <Row style={style.column}>
          <Col>
            {cookieInfo && <CookieDeclaration cookieType={cookieInfo}/>}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CookieSettings;
