//Other Libs
import chroma from "chroma-js";
//Colors
import { theme } from "../../../../assets/theme/colors";
//Maps
import { France, Germany, HongKong, Italy, Spain, Sweden, UK, } from "../../../../components/maps/regions/list";
//Custom Helpers
import { formatters, MathOp } from "../../../../helpers";

export const computeColorIntensity = (records: any, entity: string, colors = [
  theme.colors.lilywhite,
  theme.colors.dogerblue,
  theme.colors.royalblue,
  theme.colors.purplejackson,
]) => {
  const scale: any = chroma.scale(colors);

  function getExtremum(type: any) {
    // @ts-ignore
    return Math[type](...records.map((item: any) => item[entity]));
  }

  const min = getExtremum("min");

  const max = getExtremum("max");

  const classNumber = 4


  const calculateColorIntensity = (value: number | null) => {
    const intensity = MathOp.divide(value, max);
    return scale(intensity).hex()
  }

  const prepareLegend = () => {
    const prepareData = [];
    const diff = MathOp.subtract(max, min);

    const range = MathOp.divide(diff, classNumber);

    let tempMin = min;
    let tempMax;
    while (tempMin < max) {
      tempMax = tempMin + range;
      const average = MathOp.divide((tempMin + tempMax), 2);

      prepareData.push({
        color: calculateColorIntensity(average),
        label: `${formatters.percent(tempMin, 1)} - ${formatters.percent(tempMax, 1)}`
      })
      tempMin = tempMax;
    }
    return prepareData;
  };


  return {
    records: records.map((record: any) => {
      const value = record[entity];
      return {
        ...record,
        color: calculateColorIntensity(value)
      }
    }),
    legend: prepareLegend()
  }

}

const prepareCountryData = (data: any) => {

  return data.map((country: any) => {
    let countryStatistics: any = {};
    let finalRatio = 0;
    let currentBalance = 0;

    if (country.hasOwnProperty("regions")) {
      const allRegions = country.regions;
      allRegions.forEach((region: any) => {
        countryStatistics = {
          id: country.id,
          name: country.name,
          ratio: finalRatio += region.ratio,
          balance: currentBalance += region.balance,
          gwar_open: region.gwar_open,
          wam: region.wam,
          avg_rating:region.avg_rating
        }
      })
    }
    return countryStatistics;
  })
}

const prepareRegionsData = (data: any) => {
  return (data.length) ? computeColorIntensity(data[0].regions, "ratio") : [];
};

const MAPS: any = {
  "UK": UK,
  "United Kingdom": UK,
  "Spain": Spain,
  "Italy": Italy,
  "France": France,
  "Germany": Germany,
  "Sweden": Sweden,
  "Hong Kong": HongKong
}

export {
  prepareRegionsData,
  prepareCountryData,
  MAPS
}