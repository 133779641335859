//Helpers
import { withData } from "../../../../utils";
import { getRangePeriod } from "../../../../helpers";
import { prepareVehicleContentUrl } from "../../../spv/components/management/helpers";
//Components
import ChartContent from "./components/chart_content";

export default withData({
  urlFormatter: prepareVehicleContentUrl("/main/vehicle_timeline/delinquent_timeline"),
  usesDateFilters: true,
  showIntervals: true,
  endOfMonthFormat: false,
  fullDateRange: true,
  // initialInterval: 'max',
  // initialState: {
  //   period: getRangePeriod(3, 'months')
  // },
  extraParams: ["queryStr"],
  headerPosition: "between"
})(ChartContent);


