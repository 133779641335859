import React from 'react';
//Components
import { Col, Row, Switch } from "antd";
//Other Libraries
import { useTheme } from "styled-components";
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

const sunIcon = <FontAwesomeIcon spin icon={faSun}/>
const moonIcon = <FontAwesomeIcon icon={faMoon}/>

const CustomSwitch = ({collapsed}: any) => {
  const {darkMode, switchThemeMode}: any = useTheme();

  return (
    <Row gutter={8} align="middle" justify="center" style={{marginTop: 8}}>
      <Col>
        <Switch
          checked={darkMode}
          onChange={switchThemeMode}
          checkedChildren={moonIcon}
          unCheckedChildren={sunIcon}/>
      </Col>
      <Col>
        {!collapsed && <span> {darkMode ? "Dark" : "Light"} Mode </span>}
      </Col>
    </Row>
  );
};

export default CustomSwitch;

