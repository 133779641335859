import React from "react";
//Components
import { Col, DatePicker, Row, Select } from "antd";
//Custom Components
import { NumberInput } from "@cardoai/components";
import RatingSelection from "./rating_selection";

const Option: any = Select.Option;
const operators = ['=', '>', '>=', '<', '<=', 'Between'];

interface Props {
  data?: any,
  properties?: any,
  type?: string,
  handleChange: (param?: any) => any,
  placeholder?: any,
  ratings?: any,
  attr: any,
  oneVehicleSelected?: boolean
}

const Operation = (props: Props) => {
  const {data, attr, properties, type, handleChange, placeholder, ratings, oneVehicleSelected} = props;
  const {value, operator, min, max} = data;

  let availableOperators;
  if (attr === "loanRating")
    availableOperators = oneVehicleSelected ? operators : [operators[0]];
  else
    availableOperators = operators;

  const onChange = (entity: any) => (value: any) => {
    let validUpdate = true;
    const updatedData = {...data};
    if (type === 'date') {
      if (entity === "between") {
        const [min, max] = value;
        updatedData.min = min;
        updatedData.max = max;
      } else {
        updatedData[entity] = value;
      }
    } else {
      if (entity === "min" && value > max) {
        if (max === null)
          validUpdate = true;
        else if (value > max)
          validUpdate = false;
      } else if (entity === "max") {
        if (min === null)
          validUpdate = true;
        else if (value < min)
          validUpdate = false;
      }
      updatedData[entity] = value;
    }

    if (validUpdate)
      return handleChange(updatedData)
  };

  let content;

  if (type === "select") {
    if (operator === "Between") {
      content = (
        <RatingSelection min={min} max={max} value={value} placeholder={placeholder} ratings={ratings}
                         onChange={onChange}/>
      )
    } else {
      content = (
        <RatingSelection singleValue min={min} max={max} value={value} placeholder={placeholder} ratings={ratings}
                         onChange={onChange}/>
      )
    }
  } else if (type === 'date') {
    if (operator === "Between") {
      const period: any = [min, max];


      content = (
        <Row justify="end">
          <Col>
            <DatePicker.RangePicker
              value={period}
              onChange={onChange("between")}/>
          </Col>
        </Row>
      )
    } else {
      content = (
        <Row align="middle" gutter={16}>
          <Col xs={24} lg={6} className="bold">
            Value:
          </Col>
          <Col xs={24} lg={18}>
            <DatePicker className="fullWidth"
                        allowClear={false}
                        disabled={!operator}
                        value={value}
                        onChange={onChange("value")}
                        placeholder="Select Date"/>
          </Col>
        </Row>
      )
    }
  } else {
    if (operator === "Between") {
      content = (
        <Row gutter={16} align="middle" justify="space-between">
          <Col xs={12}>
            <Row align="middle" justify="space-between">
              <Col xs={24} lg={8} className="bold">
                Min:
              </Col>
              <Col xs={24} lg={16}>
                <NumberInput
                  type={type}
                  value={min}
                  disabled={!operator}
                  autoFocus={operator}
                  placeholder="Enter Min Value"
                  onChange={onChange("min")}
                  {...properties}
                  fullWidth
                  min={0}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row align="middle" justify="space-between">
              <Col xs={24} lg={8} className="bold">
                Max:
              </Col>
              <Col xs={24} lg={16}>
                <NumberInput
                  type={type}
                  value={max}
                  {...properties}
                  disabled={!operator}
                  autoFocus={operator}
                  placeholder="Enter Max Value"
                  onChange={onChange("max")}
                  fullWidth
                  min={0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )
    } else {
      content = (
        <Row align="middle" gutter={16}>
          <Col xs={24} lg={6} className="bold">
            Value:
          </Col>
          <Col xs={24} lg={18}>
            <NumberInput
              type={type}
              {...properties}
              value={value}
              disabled={!operator}
              autoFocus={operator}
              placeholder="Enter Value"
              onChange={onChange("value")}
              fullWidth
              min={0}
            />
          </Col>
        </Row>
      )
    }
  }

  return (
    <Row align="middle" gutter={16} className="mt8 fade-in">
      <Col xs={24} lg={10}>
        <Row align="middle" gutter={16}>
          <Col xs={24} lg={10} className="bold">
            Operator:
          </Col>
          <Col xs={24} lg={14}>
            <Select value={operator || undefined}
                    className="fullWidth"
                    placeholder={"Select Operator"}
                    onChange={onChange("operator")}>
              {availableOperators.map(operator => (
                <Option key={operator} value={operator}>{operator}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={14}>
        {content}
      </Col>
    </Row>
  )
};
export default Operation;


Operation.defaultProps = {
  type: "euro",
  properties: {}
};
