import React from "react";
//Other Libs
import queryString from "query-string";
//Components
import { Button, Col, Input, Row, Tooltip } from "antd";
import { SmartChart, SmartTable } from "../../../../../../../../../../../../components";
//Icons
import { FileExcelOutlined } from "@ant-design/icons";
//Helpers
import { sellerTable } from "../../../config";
import { theme } from "../../../../../../../../../../../../assets/theme/colors";
import {
  Analytics,
  callApi,
  createFileName,
  downloadClientFile,
  formatters
} from "../../../../../../../../../../../../helpers";

interface Props {
  proposalId?: any,
  vehicleId?: any
  urlParam?: any
  currency?: any
}

const SellerDetails = ({
                         proposalId,
                         vehicleId,
                         urlParam,
                         currency
                       }: Props) => {
  const [sellerGraphData, setSellerGraphData] = React.useState([]);
  const [sellerLimitSearch, setSellerLimitSearch] = React.useState('');

  const generalQuery = queryString.stringify({
    proposal_id: proposalId,
    vehicle_id: vehicleId
  });

  const sellerLimitsUrl = `/mcs/${urlParam}/seller_limits/?${generalQuery}&seller=${sellerLimitSearch}&`;


  React.useEffect(() => {
    getSellerGraphData();
    return () => {
      setSellerLimitSearch('');
    };
  }, []);


  const getSellerGraphData = () => {
    callApi({
      url: `/mcs/${urlParam}/seller_concentration/?${generalQuery}`,
      method: 'get',
      onSuccess: (response) => {
        setSellerGraphData(response);
      },
    })
  }
  const downloadSellerLimits = () => {
    downloadClientFile({
      url: `/mcs/${urlParam}/download_seller_limits/?${generalQuery}`,
      filename: createFileName("seller")
    })
    Analytics.dispatchEvent({
      category: 'Download',
      action: 'Downloaded file of seller details at proposal limits'
    })
  }
  const handleSellerLimitChange = (event: any) => {
    const searchedValue = event.target.value;

    setSellerLimitSearch(searchedValue);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: 'Filtered seller table at limits',
      label: searchedValue
    })
  }

  return (
    <Row gutter={40}>
      <Col xs={12}>
        <SmartChart
          showLegend
          height={250}
          hoverOnLegend={false}
          title="Seller Limits"
          data={sellerGraphData}
          colors={theme.palette.us}
          valueEntity="realized_amount"
          labelEntity="seller_name"
          types={['pie']}
          format={formatters.currencyNoPrecision}/>
      </Col>
      <Col xs={12}>
        <Row justify={"end"} align="middle" gutter={8}>
          <Col>
            <Input placeholder="Search All" type="text" value={sellerLimitSearch} style={{width: 200}}
                   onChange={handleSellerLimitChange}/>
          </Col>
          <Col>
            <Tooltip title="Download Excel" placement='top'>
              <Button icon={<FileExcelOutlined/>} shape='circle' onClick={downloadSellerLimits}/>
            </Tooltip>
          </Col>
        </Row>
        <SmartTable
          maxHeight={400}
          url={sellerLimitsUrl}
          columns={sellerTable(currency)}
        />
      </Col>
    </Row>
  )
}

export default SellerDetails;