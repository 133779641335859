import React, { useState } from 'react';
//Components
import { Button, Card, Col, Divider, Input, Popconfirm, Row } from "antd";
import EditTemplate from "./edit_template";
import { FieldSet, FileUpload } from "@cardoai/components"
import UploadMessage from "../../../../components/upload_files/upload/upload_message";
import UploadLoader from "../../../../components/upload_files/upload_loader";
//Icons
import { CloudUploadOutlined, DeleteOutlined, } from "@ant-design/icons";
//Helpers
import { MathOp } from "../../../../helpers";
import notifications from "../../../../components/notifications";
import excelImage from "../../../../assets/images/files/excel.png";
//Constants
import { client } from "../../config";
import { theme } from "../../../../assets/theme/colors";


interface IConfirmButton {
  showConfirm: any,
  children?: any,
  okText: any,
  cancelText: any,
  disabled: any,
  onConfirm: any,
  placement: any,
  title: any
}

interface IFile {
  file: any,
  onRemove: any
}

interface ICreateTemplate {
  onCancel: any
}

const containerStyles = {
  layout: {
    padding: 8,
    paddingTop: 20,
    minHeight: 340
  },
  file: {
    minHeight: 200
  },
  form: {
    marginTop: 4
  },
  upload: {
    marginTop: 8,
  },
  icon: {
    fontSize: 32,
    marginBottom: 4,
    color: theme.colors.blue
  }
}

const ConfirmButton = (props: IConfirmButton) => {
  const {showConfirm, children, ...rest} = props;
  if (showConfirm) {
    return (
      <Popconfirm {...rest}>
        {children}
      </Popconfirm>
    )
  }
  return children;
}

const File = (props: IFile) => {
  const {file, onRemove} = props
  return (
    <div onClick={event => event.stopPropagation()}>
      <Card size="small">
        <Row justify="space-between" align="middle">
          <Col>
            <img src={excelImage}
                 height={50}
                 style={{
                   borderRadius: '50%'
                 }}
                 alt=""/>
          </Col>
          <Col style={{color: theme.colors.primary}}>
            {file.name}
          </Col>
          <Col>
            <Button
              onClick={onRemove}
              icon={<DeleteOutlined/>}/>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

const CreateTemplate = (props: ICreateTemplate) => {
  const [fields, setFields] = useState<any>({
    files: null,
    name: '',
    description: ''
  });

  const [percent, setPercent] = useState<any>(0);
  const [template, setTemplate] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpload = () => {
    setLoading(true);
    const form = new FormData();
    form.append('uploaded_file', fields.file);
    form.append('template_name', fields.name);
    form.append('description', fields.description);
    client({
      data: form,
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      responseType: 'json',
      url: '/api/template/',
      onUploadProgress: function (progressEvent: any) {
        const percent = MathOp.divide(progressEvent.loaded, progressEvent.total);
        setPercent(percent);
      },
    }).then((response: any) => {
      setTemplate(response)
    }).catch((error: any) => {
      if (error.response)
        notifications.warning("An error happened. Please Try Again!");
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    })
  };

  const onFieldChange = (attr: any) => (event: any) => {
    const value = event.target.value;
    setFields((prevState: any) => {
      return {
        ...prevState,
        [attr]: value
      }
    })
  };

  const handleFileRemove = () => {
    setFields((prevState: any) => {
      return {
        ...prevState,
        file: null
      }
    })
  };

  const onFileChange = (files: any) => {
    const file = files[0];
    setFields((prevState: any) => {
      return {
        ...prevState,
        file: file
      }
    })
  };

  const uploadConfig = {
    required: true,
    multiple: false,
    file: fields.file,
    onChange: onFileChange,
    onError: () => {
      notifications.warning("You cannot upload this type of file")
    },
  }

  if (loading) {
    return (
      <UploadLoader
        percent={percent}/>
    )
  }

  if (template) {
    return (
      <EditTemplate
        type="create"
        templateId={template.id}/>
    )
  }

  return (
    <>
      <div style={containerStyles.layout}>
        <Row gutter={32} justify="space-between" style={containerStyles.form}>
          <Col xs={12}>
            <FieldSet
              autoFocus
              label="Name"
              component={Input}
              value={fields.name}
              placeholder="Enter Name"
              onChange={onFieldChange("name")}/>
          </Col>
          <Col xs={12}>
            <FieldSet
              component={Input}
              label="Description"
              value={fields.description}
              placeholder="Enter Description"
              onChange={onFieldChange("description")}/>
          </Col>
        </Row>
        {!template && (
          <>
            <Divider style={{fontWeight: 400}}>
              File
            </Divider>
            <div title="Click or drag file to this area for upload" className="pointerCursor">
              <FileUpload {...uploadConfig} style={containerStyles.upload}>
                {!fields.file ? (
                  <UploadMessage/>
                ) : (
                  <div style={containerStyles.file}>
                    <File
                      file={fields.file}
                      onRemove={handleFileRemove}/>
                  </div>
                )}
              </FileUpload>
            </div>
          </>
        )}
        <Divider style={{marginBottom: 8}}/>
        <Row gutter={8} justify="end">
          <Col>
            <ConfirmButton
              okText="Yes"
              cancelText="No"
              disabled={!fields.file}
              onConfirm={handleUpload}
              placement='topRight'
              title="Are you sure to upload these file?" showConfirm={true}>
              <Button
                icon={<CloudUploadOutlined/>}
                type='primary'>
                Upload
              </Button>
            </ConfirmButton>
          </Col>
        </Row>
      </div>
    </>
  );
};


export default CreateTemplate;