//Helpers
import { formatters } from "../../../../helpers";
//Colors
import { theme } from "../../../../assets/theme/colors";

export const config: any = [
  // {key: "portfolio", width: 150, label: "Portfolio", group: "General"},
  {key: "originator", width: 100, label: "Originator", group: "General",grouping:true},
  {key: "cutoff_date", width: 100, label: "Cutoff Date", group: "General", format: formatters.safeDate},
  // {key: "group_id", label: "Group ID", group: "General"},
  {key: "borrower_id",width: 100, label: "Borrower ID", group: "General", grouping: true},
  {key: "borrower_name", width: 350, label: "Borrower Name", group: "General", grouping: true},
  {key: "loan_id", label: "Loan ID", group: "General", grouping: true,width: 100},
  {key: "id_mortgage", label: " ID Mortgage", group: "General", grouping: true,width: 100},
  {key: "asset_id", label: "Asset ID", group: "General", grouping: true},
  // {key: "gbv_asset", label: "GBV x Asset", group: "General"},
  {key: "nr_lot", label: "# Lot", group: "General"},
  {key: "property_type", width: 150, label: "Property Type", group: "General", grouping: true},
  {key: "property_description", width: 150, label: "Property Description", group: "General", grouping: true},
  {key: "foglio", label: "Foglio", group: "General",width: 150},
  {key: "particella", width: 150, label: "Particella", group: "General"},
  {key: "sub", label: "Sub", group: "General",width: 150},
  {key: "cadastrial_type", label: "Cadastrial Type", group: "General", grouping: true},
  {key: "property_country", label: "Property Country", group: "General", grouping: true},
  {key: "property_region", label: "Property Region", group: "General", grouping: true,width:150},
  {key: "property_city", width: 150, label: "Property City", group: "General", grouping: true},
  {key: "property_province", label: "Property Province", group: "General", grouping: true,},
  {key: "address", label: "Address", group: "General",  width: 160,grouping: true,},
  // {key: "property_address", label: "Property Address", group: "General"},
  // {key: "civic_number", label: "Civic Number", group: "General"},
  {key: "year_built", label: "Year Built", group: "General"},
  {key: "year_of_refurbishment", label: "Year of Refurbishment", group: "General"},
  {key: "total_sqm", label: "Total SQM", group: "General", format: formatters.thousands,},
  {key: "sqm_commercial_area", width: 150, label: "SQM Commercial Area", group: "General"},
  {key: "sqm_residential_area", width: 150, label: "SQM Residential Area", group: "General"},
  {key: "sqm_other_area", width: 150, label: "SQM Other Area", group: "General"},
  {key: "nr_units", label: "# Units", group: "General", grouping: true},
  // {key: "cadastral_category", label: "Cadastral Category", group: "General"},
  // {key: "cadastral_map", label: "Cadastral Map", group: "General"},
  {key: "energy_performance", label: "Energy Performance", group: "General"},
  {key: "energy_performance_provider", width: 150, label: "Energy Performance Provider", group: "General"},
  {key: "deadline_mortgage", label: "Deadline Mortgage", group: "Mortgage Details",width: 200,format: formatters.safeDate},
  {key: "lien", label: "Lien", group: "Mortgage Details", grouping: true, width: 200},
  {key: "amount_of_mortgage", label: "Amount of Mortgage", group: "Mortgage Details", format: formatters.thousands, width: 200},
  {key: "occupancy_status", label: "Occupancy Status", group: "Occupancy Details", grouping: true},
  {key: "tenant_name", label: "Tenant Name", group: "Occupancy Details"},
  {key: "rent", label: "Rent", group: "Occupancy Details"},
  {key: "expire_date", label: "Expire Date", group: "Occupancy Details"},
  {key: "valuation_date_at_inception", width: 150, label: "Valuation Date at Inception", group: "Valuation",format: formatters.safeDate},
  {key: "valuation_at_inception", label: "Valuation at Inception", group: "Valuation", format: formatters.thousands},
  {key: "valuation_method_at_inception", width: 150, label: "Valuation Method at Inception", group: "Valuation"},
  {
    key: "valuation_date_updated",
    width: 150,
    label: "Valuation Date Updated",
    group: "Valuation",
    format: formatters.safeDate,
  },
  // {key: "valuation_updated", label: "Valuation Updated", group: "Valuation"},
  // {key: "valuation_method_updated", width: 150, label: "Valuation Method Updated", group: "Valuation"},
  {key: "most_recent_rev", label: "Most Recent REV", group: "Valuation", format: formatters.thousands,},
  {key: "omv", label: "OMV", group: "Valuation", format: formatters.thousands},
  {key: "jmv", label: "JMV", group: "Valuation", format: formatters.thousands},
  {key: "ctu_amount", label: "CTU Amount", group: "Valuation", format: formatters.thousands},
  {key: "ctu_date", label: "CTU Date", group: "Valuation", format: formatters.safeDate, width: 150},
  {key: "broker_appointed", width: 150, label: "Broker Appointed (Y/N)", group: "Strategy", grouping: true},
  {key: "broker", label: "Broker", group: "Strategy"},
  {key: "art_41", width: 150, label: "Art. 41 (Y/N)", group: "Strategy"},
  {key: "estimated_capex", label: "Estimated Capex", group: "Strategy"},
  {key: "court", label: "Court", group: "Strategy", grouping: true},
  {key: "rge", label: "RGE", group: "Strategy",width: 10,},
  {key: "legal_procedure_status", width: 150, label: "Legal Procedure Status", group: "Strategy", grouping: true},
  {key: "legal_seniority", width: 150, label: "Seniority on legal procedure or SoW", group: "Strategy", grouping: true},
  {key: "higher_claim", width: 150, label: "Higher ranking claim amount", group: "Strategy", grouping: true, format: formatters.thousands},
  {key: "enforcement_status", width: 150, label: "Enforcement Status", group: "Strategy", grouping: true},
  {key: "nr_auctions_failed", label: "# Auctions Failed", group: "Strategy"},
  {key: "last_auction_date", width: 150, label: "Last Auction Date", group: "Strategy"},
  {key: "last_auction_base_price", width: 150, label: "Last Auction Base Price / Min Bid", group: "Strategy"},
  {key: "next_auction_date", width: 150, label: "Next Auction Date", group: "Strategy",},
  {
    key: "next_auction_estimated_base_price",
    width: 200,
    label: "Next Auction Estimated Base Price / Min Bid",
    group: "Strategy",
    format: formatters.thousands
  },
  {key: "property_status", label: "Property Status", group: "Strategy",width:150},
  {key: "sale_price", label: "Sale Price", group: "Strategy"},
  {key: "sale_currency", label: "Sale Currency", group: "Strategy"},
  {key: "sale_date", label: "Sale Date", group: "Strategy"},
  {key: 'notes', label: 'Notes', width: 150, group: 'Additional Information'},
  // {key: "hdr", label: "HDR (x)", group: "Strategy"},
];

export const columnGroups: any = [
  {
    key: "General",
    styles: {
      background: theme.palette.border[2],
      borderColor: theme.colors.white,
      color: 'rgba(0, 0, 0, 0.65)'
    },
  },
  {
    key: "Mortgage Details",
    styles: {
      background: theme.palette.border[3],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Occupancy Details",
    styles: {
      background: theme.palette.border[2],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Valuation",
    styles: {
      background: theme.palette.border[5],
      borderColor: theme.colors.white,
      color: 'rgba(0, 0, 0, 0.65)'
    },
  },
  {
    key: "Strategy",
    styles: {
      background: theme.palette.border[6],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
  {
    key: "Additional Information",
    styles: {
      background: theme.palette.border[8],
      borderColor: theme.colors.white,
      color: "rgba(0, 0, 0, 0.65)"
    },
  },
];
