import React from 'react';
//Components
import { Space } from "antd";
import { InvestorStatistics, OverviewContent } from "./components";
//Helpers
//Context
import { Consumer } from "../../context"
//Configurations
import { fields } from "./config";
import { systemCurrency } from "../../../../settings";

interface IConcentrationContent {
  sectors?: any,
  countries?: any,
  statistics?: any,
  redirectionRule?: any,
  setRedirection?: any,
  selectedVehicle?: any,
  setSelectedVehicle?: any,
  investorName?: any,
}

const getInvestorCurrency = (data: any) => {
  return data.length === 1 ? data[0].currency : systemCurrency;
}

const ConcentrationContent = (props: IConcentrationContent) => {

  const {
    countries,
    statistics,
    setRedirection,
    selectedVehicle,
    setSelectedVehicle,
    investorName,
  } = props;

  return (
    <Space direction="vertical" className="full-width">
      <InvestorStatistics
        data={statistics.investor}
        investorName={investorName}
        config={fields(getInvestorCurrency(statistics.vehicles))}/>
      <OverviewContent
        countries={countries}
        statistics={statistics}
        vehicleId={selectedVehicle}
        setRedirection={setRedirection}
        setSelectedVehicle={setSelectedVehicle}
        redirectSourceGeography={{
          path: '/dashboard',
          config: {
            rules: {selectedVehicle: selectedVehicle}
          }
        }}
      />
    </Space>
  );
};

export default Consumer(ConcentrationContent)