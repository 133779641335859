import React from 'react';
//Components
import { Tabs } from "antd";
import Scenarios from "./components/scenarios";
import Statistics from "./components/statistics"
import { Break, Panel } from "@cardoai/components";
//Utils
import { useAuthorization, useGlobalApi } from "../../utils/hooks";
import { demoVehicleNameDictionary } from "../../constants/demo";

const TabPane = Tabs.TabPane;

const SimulationManagement = () => {
  const {vehicles} = useGlobalApi();
  const authorization = useAuthorization();

  return (
    <Tabs>
      {vehicles.map((vehicle: any) => {
        const vehicleId = vehicle.id;
        const vehicleName = vehicle.name;

        let tab = vehicleName;

        if (authorization.isDemoUser() && demoVehicleNameDictionary.hasOwnProperty(vehicleName))
          tab = demoVehicleNameDictionary[vehicleName]


        return (
          <TabPane tab={tab} key={vehicleId}>
            <Panel defaultOpen header="Main Statistics">
              <Statistics vehicleId={vehicleId}/>
            </Panel>
            <Break size='large'/>
            <Panel defaultOpen header="Scenarios">
              <Scenarios vehicleId={vehicleId}/>
            </Panel>
          </TabPane>
        )
      })}
    </Tabs>
  );
};


export default SimulationManagement;