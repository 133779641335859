import React from "react";
//Components
import { Col, Row, Select } from "antd";

const FilterByYears = (props: any) => {
  const {selectedYear, optionsConfig, handleYearSelect}: any = props;
  return (
    <>
      <Row justify='end' align='middle' gutter={8} className="mb16">
        <Col className="bolder size14">
          Filter by Year/s:
        </Col>
        <Col>
          <Select
            autoFocus
            allowClear
            size='small'
            placeholder="Choose year/s"
            mode="multiple"
            style={{minWidth: 120}}
            value={selectedYear}
            onChange={handleYearSelect}
            className="fullWidth">
            {optionsConfig.map((key: any) => (<Select.Option value={key} key={key}>{key}</Select.Option>))}
          </Select>
        </Col>
      </Row>
    </>
  )
}

export default FilterByYears;

FilterByYears.defaultProps = {
  size: 'small'
}

