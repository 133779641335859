//Other Libs
import styled from "styled-components";
//Constants
import { theme } from "../../../../../assets/theme/colors";

export const userManagementStyle: any = {
  top: 16,
  right: 16,
  zIndex: 99999999,
  position: "absolute",
}

export default styled.div`
  .role-wrapper {
    width: 100%;
    height: 100vh;
    z-index: 99999999999;
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    display: grid;
    grid-template-columns: 300px 300px 300px 300px;
    grid-gap: 50px;
    align-content: center;
    justify-content: center;
    padding: 100px;
    transition: .3s;

    > div {
      background-color: white;
      border-radius: 5px;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

      svg {
        width: 45px;
        height: 45px;
        margin-bottom: 15px;
      }
    }

    .close-role {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${theme.colors.primary};
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: white;
        width: 15px;
        height: 15px;
        margin-bottom: 0;
      }
    }
  }

  .role-wrapper-active {
    opacity: 1;
    pointer-events: auto;
  }

  .white-fill-svg {
    svg {
      fill: white !important;
      width: 45px !important;
      height: 45px !important;
      margin-bottom: 15px;
    }
  }
`