import React, { useState } from "react";
//Other Libraries
import { storage } from "../../helpers";
import { disable, enable, exportGeneratedCSS as collectCSS, setFetchMethod } from 'darkreader';
//Constants
setFetchMethod(window.fetch);

const config = {
  brightness: 100,
  contrast: 95,
  sepia: 0,
};

const IGNORE_INLINE_STYLE = [
  '.disable_color_invert *',
  ".chartjs-size-monitor *"
];

const defaultFixes: any = {
  invert: [],
  css: `
  ::-webkit-scrollbar-thumb {
    background-color:#616161 !important;
    -webkit-border-radius: 100px;
  }
  `,
  ignoreInlineStyle: IGNORE_INLINE_STYLE,
};

const LocalStorageKey = 'darkMode'


export default () => {
  const [darkMode, setDarkMode] = useState(() => {

    const isDarkMode = storage.get(LocalStorageKey) === "true";

    if (isDarkMode)
      enable(config, defaultFixes);

    return isDarkMode
  });

  const handleUpdate = (previous: any) => {
    storage.set(LocalStorageKey, String(!previous))
    return !previous
  };

  const enableDarkMode = () => {
    enable(config, defaultFixes);
    setDarkMode(handleUpdate);
  };

  const disableDarkMode = () => {
    disable();
    setDarkMode(handleUpdate);
  };

  const switchThemeMode = () => {
    if (darkMode)
      disableDarkMode();
    else
      enableDarkMode();
  };

  const applyCss = (cssFixes: any) => {
    enable(config, {
      invert: [],
      css: cssFixes,
      ignoreInlineStyle: IGNORE_INLINE_STYLE,
      disableStyleSheetsProxy: false,
      ignoreImageAnalysis: [],
    });
  };


  return {
    darkMode,
    collectCSS,
    enableDarkMode,
    disableDarkMode,
    switchThemeMode,
    applyCss
  }
}