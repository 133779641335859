import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const Dashboard = () => {
  return (
    <>
      <CustomImage
        alt="Dashboard Full View"
        src={assets.images.dashboardModule.fullView}
        description="Dashboard provides the User (Investor) with a high level summary view of the SPVs in his portfolio."/>
      <div>
        <DefinitionList
          header="At the top of the screen, next to the Investor’s name, the section shows:"
          data={[
            {
              term: 'SPV #',
              explanation: 'Number of SPVs in Investor’s portfolio (among those managed by Cardo AI SP)'
            },
            {
              term: 'Notes Total Current Balance',
              explanation: 'Total amount of the notes of the relevant SPVs'
            },
            {
              term: 'WAR (Notes)',
              explanation: 'Weighted Average Return recorded by the notes of the SPVs in the last reporting period'
            }
          ]}
        />
        <CustomImage
          description="Below, the User can see the details of the different SPVs in his portfolio including
        Asset Class, Notes Current Balance and Total Overcollateralization."
          alt="SPV Details"
          fullWidth={false}
          src={assets.images.dashboardModule.spvDetails}
        />
      </div>
    </>
  );
};

export default Dashboard;