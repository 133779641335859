import React from 'react';
import { Table } from "antd";

const AntTable = ({columns, total, pagination, height = '100%', ...rest}: any) => {
  return (
    <Table
      size="small"
      {...rest}
      scroll={{
        x: 'max-content',
      }}
      pagination={pagination ? {
        ...pagination,
        position: ['bottomRight']
      } : false}
      columns={columns.map((c: any) => ({
        ...c,
        title: c.label,
        dataIndex: c.key
      }))}
    />
  );
};

export default AntTable;