import { Analytics } from "../../../../../../helpers";

export const trackDateFilters = (queryStr: string) => {
  Analytics.dispatchEvent({
    category: 'Filters',
    action: 'Applied Filters at Cashflows',
    label: queryStr
  });
}

export const trackCashflowSelection = (cashFlowTypes: any) => {
  Analytics.dispatchEvent({
    category: 'Filters',
    action: 'Choosing cashflow types',
    label: cashFlowTypes
  });
}

export const trackDownloadExcel = (cashFlowFormat: string) => {
  Analytics.dispatchEvent({
    category: 'Download',
    action: `Downloaded cashflow file for collaterals in ${cashFlowFormat} format`,
  })
}

export const trackSearchOfCashflows = (searchValue: string) => {
  Analytics.dispatchEvent({
    category: 'Filters',
    action: 'Searched cashflows',
    label: searchValue
  })
}

export const trackCashflowFormat = (cashflowFormat: string) => {
  Analytics.dispatchEvent({
    category: 'Filters',
    action: 'Changed cashflow format',
    label: cashflowFormat
  });
}