import React from 'react';
//Components
import { Row, Col, Button, Drawer, Tooltip } from 'antd';
//Custom Components
import CreateFlow from "../flows/create";
import StartedFlow from "../flows/started"
//Icons
import DownloadIcon from "../../icons/download"
import HandshakeIcon from "../../icons/hand_shake";
import {CloseOutlined} from "@ant-design/icons"
//Config
import {statusName} from "../../config";
import styled from "styled-components";
import {createFileName, downloadClientFile} from "../../../../helpers";
import queryString from "query-string";
import {useTableFilters} from "../../design/table/helpers";

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    min-width: calc(100vw - 250px)
  }
`;

const Actions = ({record, vehicleId, fetchData}: any) => {
    const {filters} = useTableFilters();
    const [visible, setVisible] = React.useState<boolean>(false);
    const handleOpen = () => setVisible(true);

    const handleClose = () => {
        fetchData();
        setVisible(false)
    };

    const handleDownload = () => {
        downloadClientFile({
            filename: createFileName('General FAR', 'xlsx'),
            url: `/casavo/collateral/${record.id}/download_facility_report/?${queryString.stringify({
                ...filters,
                vehicle_id: vehicleId,
            })}`,
        })
    }
    let Stepper: any;

    switch (record.status) {
        case statusName["Awaiting Approval"]:
            Stepper = StartedFlow;
            break;
        case statusName["Approved"]:
            Stepper = StartedFlow;
            break;
        case statusName["Rejected"]:
            Stepper = StartedFlow
            break;
        case statusName["Completed"]:
            Stepper = StartedFlow;
            break;
        case statusName["Deleted"]:
            Stepper = StartedFlow;
            break;
        case statusName["Finished"]:
            Stepper = StartedFlow;
            break;
        case statusName["Awaiting For Submission"]:
            Stepper = CreateFlow;
            break;
        case statusName["Awaiting User Input"]:
            Stepper = CreateFlow;
            break;
        case statusName["Awaiting For Computation"]:
            Stepper = CreateFlow;
            break
        default:
            Stepper = null;
    }

    return (
        <>
            <Row justify="space-around">
                <Col span={12}>
                    <Tooltip title={record.status === statusName["Processing"] ? "Proposal is being processed" : ""}>
                        <Button
                          ghost
                          type="link"
                          onClick={handleOpen}
                          icon={<HandshakeIcon/>}
                          disabled={!Stepper || record.status === statusName["Processing"]}
                        />
                    </Tooltip>
                </Col>
                <Col span={12}>
                    <Tooltip title={record.status === statusName["Processing"] ? "Proposal is being processed" : ""}>
                        <Button
                          ghost
                          type="link"
                          icon={<DownloadIcon/>}
                          onClick={handleDownload}
                          disabled={!Stepper || record.status === statusName["Processing"]}
                        />
                    </Tooltip>
                </Col>
            </Row>
            {visible && (
                <StyledDrawer
                    open={visible}
                    closable={false}
                    placement="right"
                    onClose={handleClose}
                    bodyStyle={{
                        minWidth: 'calc(100vw-128px)'
                    }}
                    title={
                        <Row gutter={8} onClick={handleClose} style={{cursor: 'pointer'}}>
                            <Col>
                                <CloseOutlined/>
                            </Col>
                            <Col style={{fontWeight: 700}}>
                                Cancel
                            </Col>
                        </Row>}>
                    <Stepper
                        proposal={record}
                        onClose={handleClose}
                        vehicleId={vehicleId}
                        status={record.status}
                        proposalId={record?.id}
                        proposalDate={record?.proposed_date}/>
                </StyledDrawer>
            )}
        </>
    );
};

export default Actions;