import React from "react";
//Config
const tableHeaders = [
  "Distribution",
  "Current Balance",
  "WAR",
  "WAM",
];

const Headers = (props: any) => {
  const {title, ratingLabel} = props;
  return (
    <thead>
    <tr>
      <th key="0" className="sticky">{title}</th>
      {tableHeaders.map((header, index) => <th key={String(index)} className="textCenter sticky">{header}</th>)}
      <th className="textCenter sticky">{ratingLabel}</th>
      <th key="sticky" className="sticky"/>
    </tr>
    </thead>
  )
};

export default Headers;
