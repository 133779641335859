import React from "react";
//Components
import { Popover } from "antd";
//Helpers
import { isEmpty } from "../../../../../../helpers";

interface LegendItemProps {
  value?: any,
  label?: any,
  color?: string,
  onClick?: any,
  showHover?: any
}

interface LegendItemsProps {
  records?: any,
  formatter?: any,
  others?: any,
  onClick?: any,
  showHover?: any,
  showOthers?: any,
  extraSpace?: any,
  title?: any
  height?: any
}

const LegendItem = (props: LegendItemProps) => {
  const {value, label, color, onClick, showHover} = props;

  const parentStyle: any = {
    color: color
  };

  if (onClick && showHover)
    parentStyle.cursor = "pointer";

  const handleClick = () => {
    if (!onClick)
      return null;
    return onClick(label);
  }

  return (
    <li style={parentStyle} onClick={handleClick}>
        <span style={{color: 'rgba(0, 0, 0, 0.75)', fontSize: 12}}>
        <span>{label}</span>:
          {' '} <span style={{fontWeight: 500}}>
          {value}
        </span>
       </span>
    </li>
  )
};


LegendItem.defaultProps = {
  showHover: true
}

const LegendItems = (props: LegendItemsProps) => {
  const {records, formatter, others, onClick, showHover, showOthers, extraSpace, height} = props;
  let otherRecord: any = null;

  if (showOthers)
    otherRecord = records.pop();

  const content = records.map((item: any, index: any) => {
    const {label, value, color} = item;
    return (
      <LegendItem
        key={index}
        label={label}
        color={color}
        onClick={onClick}
        showHover={showHover}
        value={formatter(value)}
      />
    )
  });

  return (
    <ul className="fade-in" style={extraSpace ? {} : {marginLeft: -30, marginBottom: -5}}>
      {content}
      {showOthers && !isEmpty(others) && <li style={{color: otherRecord.color}}>
        <Popover
          content={
            <div style={{height: height, overflow: "auto"}}>
              <LegendItems
                title="Others"
                records={others}
                onClick={onClick}
                extraSpace={false}
                showOthers={false}
                showHover={showHover}
                formatter={formatter}/>
            </div>
          }>
          <span className="pointerCursor" style={{color: 'rgba(0, 0, 0, 0.75)', fontSize: 12}}>
            Others: <span style={{fontWeight: 500}}>{formatter(otherRecord.value)}</span>
          </span>
        </Popover>
      </li>}
    </ul>
  )
};

export default LegendItems;