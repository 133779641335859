import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Limits = () => {
  return (
    <CustomImage
      alt="SPV Management Notes Accounts"
      src={assets.images.spvModule.accounts}
      description="This section provides a monitoring of the contractual limits applicable to the transaction.
            Limits are listed together with the type of threshold (min, max, etc.), contractual threshold and recorded values
            (in green if the threshold has been met, in red otherwise)."
    />
  );
};

export default Limits;
