import React, { useState } from "react";
//Components
import { Button, Checkbox, Col, DatePicker, Modal, Popconfirm, Row } from "antd";
//Icons
import { DeleteOutlined } from "@ant-design/icons";
//Helpers
import { callApi, formatServerDate } from "../../../../helpers";

const RemoveCollateralSyncs = ({vehicleId, onUpdateTable, validDates, vehicleName,vehicleIdentifier}: any) => {

  const [visible, setVisible] = useState<boolean>(false);
  const [syncDate, setSyncDate] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkResyncLastRequest, setCheckResyncLastRequest] = useState<boolean>(true);
  const [checkResetInitialSync, setCheckResetInitialSync] = useState<boolean>(false);

  const displayResetInitialSyncInDemo = vehicleIdentifier.includes("demo")

  const toggleVisibility = () => {
    setVisible(prevState => !prevState)
  }

  const onDateChange = (value: any) => {
    setSyncDate(value)
  }

  const onCheckResyncLastRequest = (e: any) => {
    setCheckResyncLastRequest(e.target.checked)
  }

  const onCheckResetForInitialSync = (e: any) => {
    setCheckResetInitialSync(e.target.checked)
  }

  const onConfirm = () => {
    callApi({
      url: `/main/originator_instance/${vehicleId}/remove_collateral_syncs/`,
      method: "POST",
      body: {
        "last_valid_sync_date": formatServerDate(syncDate),
        "re_sync_last_valid_request": checkResyncLastRequest,
        "reset_for_initial_sync": checkResetInitialSync
      },
      onSuccess: () => {
        toggleVisibility()
        onUpdateTable()
      },
      setLoading,
      successMessage: "Files removed successfully",
    })
  }

  const calculateDisabledDates = (currentDate: any) => !validDates.includes(formatServerDate(currentDate))

  const description = <div>The date selected is before the last report of DoD and we will have to resync DoD data as
    well. <br/>
    Are you sure to proceed?
  </div>

  return (
    <>
      <Button className="floatR mt16 mr8" icon={<DeleteOutlined/>} onClick={toggleVisibility} >
        Remove Collateral Syncs
      </Button>
      <Modal visible={visible} onCancel={toggleVisibility} title="Remove Collateral Syncs" width={550}
             centered footer={<Popconfirm
        title={vehicleName === "Invoice Be-Tech" ? description : "Are you sure to perform this operation?"}
        onConfirm={onConfirm} disabled={!syncDate && !checkResetInitialSync}>
        <Button type="primary" loading={loading} disabled={!syncDate && !checkResetInitialSync}>Remove</Button></Popconfirm>}>
        <Row align="middle">
          <Col span={10}>
            Last valid sync request date:
          </Col>
          <Col span={14}>
            <DatePicker disabledDate={calculateDisabledDates} className="fullWidth" value={syncDate}
                        onChange={onDateChange} placeholder="Select Date"/>
          </Col>
        </Row>
        <Row align="middle" className="mt8">
          <Col span={10}>
            Resync last valid request?
          </Col>
          <Col span={14}>
            <Checkbox onChange={onCheckResyncLastRequest} checked={checkResyncLastRequest}/>
          </Col>
        </Row>
        {displayResetInitialSyncInDemo && <Row align="middle" className="mt8">
          <Col span={10}>
            Reset for initial sync?
          </Col>
          <Col span={14}>
            <Checkbox onChange={onCheckResetForInitialSync} checked={checkResetInitialSync} />
          </Col>
        </Row>}
      </Modal>
    </>
  )
}

export default RemoveCollateralSyncs;