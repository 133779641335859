//Helpers
import { formatters, Text } from "../../../../../../../helpers";

const casavoIgnoredKeys: string[] = [];

const casavoNotesIgnore: string[] = [
  "current_oc_percentage", "current_oc_eur", "initial_oc_percentage", "initial_oc_eur", "reserve_balance", "xs", "wam",
  "final_maturity", "loans_outstanding", "current_loan_balance", "revolving_period_end", "fees",
];

const casavoCollateralIgnore: string[] = [
  "reference_date", "initial_balance", "current_loan_balance", "delinquent_percentage", "avg_ticket_size", "wala",
  "wam", "gwar", "average_realized_maturity_dso", "loans_repaid", "loans_outstanding",
];

export const statisticsFields = (currency?: any) => {
  return {
    servicer: (isCasavo?: boolean) => {
      const data = [
        {
          key: "asset_class",
          label: Text("Asset \n Class"),
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "issue_date",
          label: Text("Issue \n Date"),
          format: formatters.date,
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "notes_balance",
          label: Text("Notes \n Balance"),
          format: (value: any) => formatters.currency({value, shortFormat: true, currency}),
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "current_loan_balance",
          label: Text("Collateral \n Balance"),
          format: (value: any) => formatters.currency({value, shortFormat: true, currency}),
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "current_oc_percentage",
          label: Text("Current \n OC (%)"),
          format: formatters.percent,
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "current_oc_eur",
          label: Text("Current \n OC (€)"),
          format: (value: any) => formatters.currency({value, shortFormat: true, currency}),
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "xs",
          label: Text("XS\n(%)"),
          format: formatters.percent,
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "loans_outstanding",
          label: Text("Loans\nOutstanding #"),
          format: formatters.thousandsShorted,
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "wam",
          label: Text("WAM\n(yrs)"),
          format: formatters.years,
          showHistory: false,
          hideLabel: true,
        },
        {
          key: "final_maturity",
          label: Text("Final\n Maturity"),
          format: formatters.date,
          showHistory: false,
          hideLabel: true,
        },
      ];

      return isCasavo ? data.map((record: any, index: number) => {
        if (casavoIgnoredKeys.includes(record.key))
          return {...record, key: `${index}x`}
        return record;
      }) : data;
    },
    sections: {
      notes: (isCasavo?: boolean) => {
        const data: any[] = [
          {
            key: "collection_period_start_date",
            format: formatters.date,
            label: Text("Collection \n Period Start"),
            showHistory: false
          },
          {
            key: "collection_period_end_date",
            format: formatters.date,
            label: Text("Collection \n Period End"),
            showHistory: false
          },
          {
            key: "notes_balance",
            format: (value: any) => formatters.currency({value, shortFormat: true, currency}),
            label: Text("Notes \n Balance"),
            showHistory: false
          },
          {
            key: "factor",
            format: formatters.percent,
            label: Text("Factor\n (%)"),
            showHistory: false
          },
          {
            key: "initial_oc_percentage",
            format: formatters.percent,
            label: Text("Initial \n OC (%)"),
            showHistory: false
          },
          {
            key: "initial_oc_eur",
            format: (value: any) => formatters.currency({value, shortFormat: true, currency}),
            label: Text("Initial \n OC (€)"),
            showHistory: false
          },
          {
            key: "current_oc_percentage",
            format: formatters.percent,
            label: Text("Current \n OC (%)"),
            showHistory: false
          },
          {
            key: "current_oc_eur",
            format: (value: any) => formatters.currency({value, shortFormat: true, currency}),
            label: Text("Current \n OC (€)"),
            showHistory: false
          },
          {
            key: "reserve_balance",
            format: (value: any) => formatters.currency({value, shortFormat: true, currency}),
            label: Text("Reserve \n Balance"),
            showHistory: false
          },
          {
            key: "fees",
            format: formatters.percent,
            label: Text("Fees\n(%)"),
            showHistory: false
          },
          {
            key: "xs",
            format: formatters.percent,
            label: Text("XS\n(%)"),
            showHistory: false
          },
          {
            key: "revolving_period_end",
            format: formatters.date,
            label: Text("Revolving \n  Period End"),
            showHistory: false
          },
          {
            key: "last_distribution_date",
            format: formatters.date,
            label: Text("Last \nDistribution Date"),
            showHistory: false
          }
        ];

        return isCasavo ? data.map((record: any, index: number) => {
          if (casavoNotesIgnore.includes(record.key))
            return {...record, key: `${index}x`}
          return record;
        }) : data;
      },
      collateral: (isCasavo?: boolean) => {
        const data: any[] = [
          {
            key: "reference_date",
            format: formatters.date,
            label: Text("Reporting  \n Date"),
            showHistory: false
          },
          {
            key: "initial_balance",
            format: (value: any) => formatters.currency({value, type: 'k', currency}),
            label: Text("Initial \n Balance"),
            showHistory: false
          },
          {
            key: "current_loan_balance",
            format: (value: any) => formatters.currency({value, type: 'k', currency}),
            label: Text("Current \n Balance"),
            showHistory: false
          },
          {
            key: "delinquent_percentage",
            label: Text("Delinquent\n(%)"),
            format: formatters.percent,
            showHistory: false
          },
          {
            key: "loans_repaid",
            label: Text("Loans\nRepaid #"),
            format: formatters.thousandsShorted,
            showHistory: false
          },
          {
            key: "loans_outstanding",
            label: Text("Loans\nOutstanding #"),
            format: formatters.thousandsShorted,
            showHistory: false
          },
          {
            key: "avg_ticket_size",
            label: Text("Average \n Loan Size"),
            format: (value: any) => formatters.currency({value, type: 'k', currency}),
            showHistory: false
          },
          {
            key: "wala",
            label: Text("WALA\n(yrs)"),
            format: formatters.years,
            showHistory: false
          },
          {
            key: "wam",
            label: Text("WAM\n(yrs)"),
            format: formatters.years,
            showHistory: false
          },
          {
            key: "gwar",
            label: Text("WAR\n(%)"),
            format: formatters.percent,
            showHistory: false
          },
          {
            key: "average_realized_maturity_dso",
            label: Text("Avg. Realized Maturity / \nDSO (yrs)"),
            format: formatters.years,
            showHistory: false
          }
        ];

        return isCasavo ? data.map((record: any, index: number) => {
          if (casavoCollateralIgnore.includes(record.key))
            return {...record, key: `${index}x`}
          return record;
        }) : data;
      }
    }
  }
}
