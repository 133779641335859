import React from "react";
//Components
import { Col, DatePicker, Input, Row, Select, Spin } from "antd";
import ActionCell from "../../action_cell";
//Helpers
import { formatDate } from "../../../../../helpers";
//ka-table
import { ICellTextProps, IHeadCellProps } from "ka-table/props";
import {
  search,
  selectRow,
  deselectRow,
  selectRowsRange,
  selectAllFilteredRows,
  deselectAllFilteredRows
} from "ka-table/actionCreators";
import { kaPropsUtils } from "ka-table/utils";
import { DataType } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import { ITableProps, kaReducer, Table } from "ka-table";
import { FileSearchOutlined } from "@ant-design/icons";
//Other
import moment from "moment";

interface Props {
  tableProps: ITableProps,
  setTableProps: DispatchFunc
  vehicleId: number | string,
  selectedSearchGroup: string,
  setSelectedSearchGroup: (param: string) => void,
  onDateChange?: (date: any) => void,
  loading?: boolean,
  callbacks: any,
}

const GroupedTable = ({
                        tableProps,
                        setTableProps,
                        vehicleId,
                        selectedSearchGroup,
                        setSelectedSearchGroup,
                        onDateChange,
                        loading,
                        callbacks,
                      }: Props) => {

  const dispatch: DispatchFunc = (action) => {
    setTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };

  const onSearch = (event: any) => {
    dispatch(search(event.target.value));
  }

  return (
    <>
      <Row justify='end' gutter={[8, 8]} className="mb8">
        <Col>
          <Input
            placeholder="Search"
            size="small"
            style={{ width: 220 }}
            suffix={<FileSearchOutlined />}
            onChange={onSearch} />
        </Col>
        <Col>
          <Select
            placeholder="Select Group"
            size="small" style={{ width: 160 }}
            value={selectedSearchGroup}
            onChange={setSelectedSearchGroup}
          >
            <Select.Option value="type">
              Originator
            </Select.Option>
            <Select.Option value="group">
              Borrower
            </Select.Option>
          </Select>
        </Col>
        <Col>
          <DatePicker
            size="small"
            placeholder="Upload Date"
            onChange={onDateChange}
            disabledDate={(date: any) => date > moment()}
          />
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Table
          {...tableProps}
          childComponents={{
            headCell: {
              content: getHeaderSectionCell(tableProps)
            },
            cellText: {
              content: getCell({ records: tableProps.data, vehicleId, callbacks }),
            },
          }}
          dispatch={dispatch}
        />
      </Spin>
    </>
  );
}

const getCell = (data: any) => (props: any) => {
  const {records, vehicleId, callbacks} = data;
  const {rowData, value, column} = props;

  if (column?.key === 'actions') return (
    <ActionCell
      record={records.find((r: any) => r.id === rowData.id)}
      vehicleId={vehicleId}
      callbacks={callbacks}
    />
  );
  else if (column?.key === 'selection-cell') return (
    <SelectionCell {...props} />
  );
  return (
    <span>
      {column?.dataType === DataType.Date ? formatDate(value) : value?.toString()}
    </span>
  );
}

const SelectionCell: React.FC<ICellTextProps> = ({
  rowKeyValue, dispatch, isSelectedRow, selectedRows
}) => {
  return (
    <input
      type='checkbox'
      checked={isSelectedRow}
      onChange={(event: any) => {
        if (event.nativeEvent.shiftKey) {
          dispatch(selectRowsRange(rowKeyValue, [...selectedRows].pop()));
        } else if (event.currentTarget.checked) {
          dispatch(selectRow(rowKeyValue));
        } else {
          dispatch(deselectRow(rowKeyValue));
        }
      }}
    />
  );
};

const getHeaderSectionCell = (tableProps: ITableProps) => (props: any) => {
  if (props.column.key === 'selection-cell') {
    return (
      <SelectionHeader
        {...props}
        areAllRowsSelected={kaPropsUtils.areAllFilteredRowsSelected(tableProps)}
      />
    );
  }
}

const SelectionHeader: React.FC<IHeadCellProps> = ({ dispatch, areAllRowsSelected }) => (
  <input
    type='checkbox'
    checked={areAllRowsSelected}
    onChange={(event) => {
      if (event.currentTarget.checked) dispatch(selectAllFilteredRows());
      else dispatch(deselectAllFilteredRows());
    }}
  />
);

export default GroupedTable;
