import React from "react";
//Components
import { SmartChart } from "../../../../../../components";
//Utils
import { theme } from "../../../../../../assets/theme/colors";
//Helpers
import { formatDate, formatters } from "../../../../../../helpers";
import { useCashFlowGraphAPI } from "../../../../apis";

const prepareGraphData = (data: any) => {
  const keys: any = [];
  const dates = Object.keys(data);

  /*Identify datasets*/
  for (const date of dates) {
    for (let key in data[date]) {
      if (!keys.includes(key))
        keys.push(key);
    }
  }
  /*Create datasets configuration*/
  const datasets = keys.map((key: any, index: number) => {
    return {
      id: key,
      data: [],
      label: key,
      backgroundColor: theme.palette.us[index]
    }
  })

  /*Populate datasets*/
  for (let date of dates) {
    const record: any = data[date];
    datasets.forEach((dataset: any) => {
      dataset.data.push(record[dataset.id]);
    })
  }

  return {
    datasets: datasets,
    labels: dates.map((label: any) => formatDate(label))
  }
}

const CashFlowGraph = ({filters}: any) => {

  const cashFlowAPI = useCashFlowGraphAPI(filters);

  if (!cashFlowAPI.data)
    return null;

  const {labels, datasets} = prepareGraphData(cashFlowAPI.data);

  return (
    <div className="p16">
      <SmartChart
        height={550}
        usesMenu={false}
        labels={labels}
        innerDatasets={false}
        displayLabels={false}
        dataSets={datasets}
        types={['bar']}
        format={formatters.thousands}
      />
    </div>
  )
}

export default CashFlowGraph;