import React, { useState } from "react";
//Components
import { Button, Col, Row } from "antd";
//Other Libs
import moment from "moment";
//Components
import { LIMITS_WAIVER_VIEW } from "../index";
import CreateWaiverContent from "./create_waiver_content";
import CalculationExtension from "../calculation_waiver/calculation_extension";
//Utils
import { mediatorSend } from "@cardoai/utils";
//Helpers
import { callApi } from "../../../../../../../../helpers";

export const WAIVER_TYPE: any = {
  SUSPENSION: "Suspension",
  CALCULATION_EXCLUSION: "Calculation Exclusion",
};

interface Props {
  id?: any;
  vehicleId?: any;
  activeView?: any;
  displayExtension?: any;
  displayCalculation?: any;
}

const CreateWaverRequest = (props: Props) => {
  const { id, vehicleId, activeView, displayExtension, displayCalculation } =
    props;

  const [input, setInput] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");

  const onChangeValue = (e: any) => {
    setInput(e.target.value);
  };

  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };

  const onConfirm = () => {
    callApi({
      url: `main/limit_waiver/?vehicle_id=${vehicleId}`,
      method: "POST",
      body: {
        limit: id,
        reason: input,
        waiver_type: WAIVER_TYPE.SUSPENSION,
        loans: [],
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      },
      successMessage: "Waiver submitted for approval!",
      onSuccess: () => {
        // todo replace with invalidate query
        mediatorSend("Limits", "refreshLimits", undefined);
      },
    });
  };

  return (
    <div className="fullWidth">
      {activeView === LIMITS_WAIVER_VIEW.CREATE_WAIVER ? (
        <>
          <Row align="middle" justify="space-around">
            <Col>
              <Button
                type="primary"
                size="middle"
                shape="round"
                onClick={displayExtension}
              >
                Suspension
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                size="middle"
                shape="round"
                onClick={displayCalculation}
              >
                Calculation Waiver
              </Button>
            </Col>
          </Row>
        </>
      ) : activeView === LIMITS_WAIVER_VIEW.CREATE_EXTENSION ? (
        <CreateWaiverContent
          input={input}
          onChangeValue={onChangeValue}
          startDate={startDate}
          onChangeStartDate={onChangeStartDate}
          endDate={endDate}
          onChangeEndDate={onChangeEndDate}
          onConfirm={onConfirm}
          requiredDataValidation={true}
        />
      ) : (
        <CalculationExtension
          vehicleId={vehicleId}
          input={input}
          onChangeValue={onChangeValue}
          startDate={startDate}
          onChangeStartDate={onChangeStartDate}
          endDate={endDate}
          onChangeEndDate={onChangeEndDate}
          id={id}
        />
      )}
    </div>
  );
};

export default CreateWaverRequest;
