import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const LoanDetails = () => {
  return (
    <CustomImage
      alt="Loan Details"
      src={assets.images.loansModule.amortizationPlan}
      description="By using the Search functionality, the User can look up information for any single loan.
        By clicking on one of the loans on the table, a pop-up window will open to display general information
        for that selected loan. More details are provided in the tabs inside the pop-up window."/>
  );
};

export default LoanDetails;
