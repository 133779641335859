import React, { useState } from 'react';
//Components
import { Button, Col, Row } from "antd";
//Custom Components
import SelectButton from "../loan_stats/select_button";
import NPLCollateralViews from "./npl_collateral_views";
import GeographyMap from "./geography_map";
//Components
import { DownloadAsCSV } from "../../../../../components";
//Static Data
import stats from "../../stats.json"
//Helpers
import { getDelimiter } from "../../../helpers";
import { downloadArrayOfObjectsAsCSV } from "../../../../../helpers";
//Icons
import { DownloadOutlined, PushpinOutlined } from "@ant-design/icons";

const CollateralStatisticVisualisation = (props: any) => {
  const {category, data} = props

  const [selectedView, setSelectedView] = useState(() => {
    return category === "Property Concentration" ? "map" : "Table"
  })


  const onViewChange = (newView: any) => {
    setSelectedView(newView)
  }

  const onDownload = (_: any, {decimalSeparator}: any) => {
    try {
      const columnDelimiter = getDelimiter(decimalSeparator)
      downloadArrayOfObjectsAsCSV(data, category, true, {
        columnDelimiter,
        decimalSeparator,
        canConvertDecimalSeparator: true
      });
    } catch (e) {
      console.error('export error', e);
    }
  }

  return (
    <div style={{height: 500}}>
      <Row justify="end">
        <Col>
          <SelectButton
            extra={category === "Property Concentration" ? {
              key: 'map',
              title: 'Map',
              icon: <PushpinOutlined/>
            } : null}
            selectedView={selectedView}
            onViewChange={onViewChange}/>
        </Col>
        <Col>
          <DownloadAsCSV onDownload={onDownload}>
            <Button icon={<DownloadOutlined/>} size="small"/>
          </DownloadAsCSV>
        </Col>
      </Row>

      {selectedView === "map" && category === "Property Concentration" ? (
        <GeographyMap data={data}/>
      ) : (
        <NPLCollateralViews selectedView={selectedView} categoryName={category} data={data}/>
      )}
    </div>
  );
};

const LuzzattiHDRCollateralStats = () => {

  return (
    <Row justify="space-between" align="middle" gutter={32}>
      {Object.entries(stats.collateral_stats).map(([category, data]) => {
        return (
          <Col span={12}>
            <CollateralStatisticVisualisation category={category} data={data}/>
          </Col>
        )
      })}
    </Row>
  )
}

export default LuzzattiHDRCollateralStats;