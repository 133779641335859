import React from "react";
//Helpers
import { formatters} from "../../../helpers";
import DownloadButton from "./download_button";


export const columns: any = [
  {
    key: "topic_name",
    label: "Topic Name",
    width: 150,
    sortable:true,
    grouping: true
  },
  {
    key: "file_name",
    label: "File Name",
    sortable:true,
    width: 150,
    grouping: true
  },
  {
    key: "file",
    label: "File",
    width: 150,
    grouping: true,
    render: (value: any, uploadData: any) => {
      return <DownloadButton file={uploadData.record.file} filename={uploadData.record.file_name}/>
    }
  },
  {
    key: "message_id",
    label: "Message ID",
    width: 150,
    grouping: true
  },
  {
    key: "created_at",
    label: "Date",
    width: 150,
    sortable: true,
    grouping: true,
    format: formatters.safeDate
  }
]