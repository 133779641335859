import styled from "styled-components";
//Constants
import { theme } from "../../../../../assets/theme/colors";

export default styled.div`
  .container {
    margin: 4px;
    padding: 4px;
    min-width: 100%;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.palette.grayscale[10]};
    justify-content: space-between;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    :hover {
      cursor: pointer;
      transform: scale(1.05);
      border-color: ${theme.colors.primary};

      .title {
        color: ${theme.colors.primary};
      }
    }
  }

  .title {
    margin-left: 4px;
    font-weight: 500;
    font-size: 14px;
  }

  .fields {
    flex: 1;
    margin-left: 4px;
    display: flex;
    font-size: 13px;
    flex-direction: column;
    justify-content: flex-end;
  }

  .divider {
    height: 0.5px;
    width: 100%;
    background-color: ${theme.palette.grayscale[10]};
  }
`

