import React from "react";
//Components
import { DelaysChart, LossChart } from "../../../../../common/charts";
import { HorizontalCollapse } from "@cardoai/components";
//Styles
import { Styles } from "./styles";

interface Props {
  vehicle?: any,
  vehicleId?: any
  redirectionSource?: any
  currency?: any
}

const DelaysAndLoss = (props: Props) => {
  const {vehicle, vehicleId, currency} = props;

  const redirectSourceDelays = {
    path: '/spv-management',
    rules: {
      vehicleId: vehicleId,
      tabKey: "performance",
      nestedTabKey: '',
      section: "collateral"
    }
  }

  return (
    <Styles>
      <HorizontalCollapse
        height={500}
        activePanel="both"
        nodes={[
          {
            text: "Delays",
            content: <DelaysChart currency={currency} redirectSourceDelays={redirectSourceDelays} vehicle={vehicle}
                                  vehicleId={vehicleId}/>
          },
          {
            text: "Loss Curve",
            content: <LossChart vehicleId={vehicleId}/>
          }
        ]}/>
    </Styles>
  )
}

export default DelaysAndLoss;
