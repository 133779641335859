//Components
import ChartContent from "./chart_content";
//Utils
import { withData } from "../../../../utils";
import { generateUrl } from "../../../../helpers";

export default withData({
  extraParams: ['vehicleId', 'selectedLender'],
  urlFormatter: (config: any) => {
    const {props} = config;
    const {vehicleId, selectedLender} = props;
    let url = generateUrl("/main/loan_distribution/region_diversification", {
      vehicle_id: vehicleId,
    });
    if (selectedLender)
      url = `${url}&lender=${selectedLender}`

    return url;
  },
})(ChartContent);
