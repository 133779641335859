import {routeKey} from "./auth";
import QuestionsAndAnswers from "../containers/questions_and_answers";
import {QuestionCircleOutlined} from "@ant-design/icons";
import React from "react";

export const routeConfiguration = [
    {
        key: routeKey.dashboard,
        title: "Dashboard",
        path: "dashboard",
        displayTitle: false,
    },
    {
        key: routeKey.spvManagement,
        title: "SPV Management",
        path: "spv-management",
        displayTitle: true,
    },
    {
        key: routeKey.notesManager,
        title: "Notes Manager",
        path: "notes-manager",
        displayTitle: true,
    },
    {
        key: routeKey.realEstateManagement,
        title: "Real Estate Management",
        path: "real-estate-management",
        displayTitle: true,
    },
    {
        key: routeKey.collateral,
        title: "Collateral Management",
        path: "collateral-management",
        displayTitle: true,
    },
    {
        key: routeKey.hyperDataRoom,
        title: "Hyper Data Room",
        path: "hyper-data-room",
        displayTitle: true,
    },
    {
        key: routeKey.loans,
        title: "Loans Management",
        path: "loans",
        displayTitle: true,
    },
    {
        key: routeKey.analytics,
        title: "Analytics",
        path: "analytics",
        displayTitle: true,
    },
    {
        key: routeKey.simulation,
        title: "Cashflow Simulation",
        path: "simulation",
        displayTitle: true,
    },
    {
        key: routeKey.collectionManagement,
        title: "Collection Management",
        path: "collection-management",
        displayTitle: true,
    },
    {
        key: routeKey.excelGenerator,
        title: "Excel Generator",
        path: "excel-generator",
        displayTitle: true,
    },
    {
        key: routeKey.documents,
        title: "Documents",
        path: "documents",
        displayTitle: true,
    },
    {
        key: routeKey.hdrDocuments,
        title: "HDR Documents",
        path: "hdr-documents",
        displayTitle: true,
    },
    {
        key: routeKey.synchronization,
        title: "Syncs",
        path: "syncs",
        displayTitle: true,
        visibleOnDebug: true,
        visibleOnDev: true,
    },
    {
        key: routeKey.casavo_theta_notes_manager,
        title: "Funding Manager",
        path: "funding_manager",
        displayTitle: true,
        visibleOnDebug: true,
        visibleOnDev: true
    },
    {
        key: routeKey.documentation,
        title: "Help",
        path: "documentation",
        displayTitle: true,
    },
    {
        key: routeKey.hdrDocumentation,
        title: "Hyper Data Room Help",
        path: "hdr-documentation",
        displayTitle: true,
    },
    {
        key: routeKey.nplHDR,
        title: "NPE HDR",
        path: "npe-hdr",
        displayTitle: true,
    },
    {
        key: routeKey.nplModule,
        title: "NPE Portfolio",
        path: "npe-module",
        displayTitle: true,
    },
    {
        key: routeKey.questionsAndAnswers,
        title: "Q & A",
        path: "questions-and-answers",
        displayTitle: true,
    },
    {
        key: routeKey.syncReports,
        title: "Sync Reports",
        path: "sync-reports",
        displayTitle: true,
    },
];
