import React from 'react';
//Components
import { Button, Col, Modal, Row, Space } from "antd";
import { notifications } from "../../../../../components";
//Helpers
import { callApi } from "../../../../../helpers";
//Config
import { passwordConfig } from "./config";
import { idpBaseUrl } from "../../../../../settings";


const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const PasswordChange = ({visible, toggleVisibility, onLogout}: any) => {

  const [passwordInput, setPasswordInput] = React.useState<any>(initialState);
  const [loading, setLoading] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);

  const onInputChange = (key: any) => (e: any) => {
    const value = e.target.value;
    setPasswordInput((prevState: any) => ({
      ...prevState,
      [key]: value
    }))
  }

  const isValidPassword = () => passwordInput.newPassword === passwordInput.confirmPassword;

  const changePassword = () => {
    if (isValidPassword()) {
      setErrorStatus(false)
      callApi({
        url: `${idpBaseUrl}change_password/`,
        method: "POST",
        body: {
          current_password: passwordInput.currentPassword,
          new_password: passwordInput.newPassword
        },
        setLoading,
        onFinish: () => {
          setErrorStatus(false)
        },
        onSuccess: () => {
          notifications.success("Please sign in again")
          onLogout();
        }
      })
    } else {
      notifications.error("Passwords do not match!", "Try Again")
      setErrorStatus(true)
    }
  }

  const inputHasError = (key: any) => {
    if (errorStatus && ["newPassword", "confirmPassword"].includes(key))
      return "error";
    return null;
  }

  return (
    <Modal visible={visible} onCancel={toggleVisibility} title="Change your password" footer={null}>
      <Space size="large" direction="vertical" className="fullWidth">
        {passwordConfig.map((config: any) => {
          const {label, key, Component} = config;
          return (
            <Row  key={key} align="middle" justify="space-between">
              <Col className="bold">
                {label}
              </Col>
              <Col>
                <Component status={inputHasError(key)} placeholder="Enter password" type="password"
                           value={passwordInput[key]} onChange={onInputChange(key)}/>
              </Col>
            </Row>
          )
        })}
        <Row align="middle" justify="center">
          <Button disabled={loading} loading={loading} type="primary" onClick={changePassword}>
            Change Password
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default PasswordChange;