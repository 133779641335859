import React from "react";
//Components
import { Button, Col, Popover, Row, Skeleton } from "antd";
import { SmartTable } from "../../../../../../../../../../../components";
//Icons
import { InfoCircleOutlined, SnippetsOutlined } from "@ant-design/icons";
//Helpers
import { debtorContent } from "../../config";
import { legendOfCellColors, prepareColumns, prepareRows } from "./helpers";
import { Analytics, generateUrl, useQuery } from "../../../../../../../../../../../helpers";

interface Props {
  data?: any,
  currency?: any
  urlParam?: any,
  vehicleId?: any,
  proposalId?: any,
  activeSection?: any,
  onSectionChange?: any,
  updateQueryParams?: any
}

const DebtorMatrix = ({
                        urlParam,
                        vehicleId,
                        proposalId,
                        activeSection,
                        onSectionChange,
                        updateQueryParams,
                        currency
                      }: Props) => {

  const {data} = useQuery({
    url: generateUrl(`/mcs/${urlParam}/debtor_limits_matrix`, {
      vehicle_id: vehicleId,
      proposal_id: proposalId
    })
  })

  if (!data)
    return <Skeleton/>

  const onClick = (record: any, column: any) => {
    const {category} = record;
    const {key} = column;
    updateQueryParams({
      rating_cluster: key,
      revenue_cluster: category
    })
    onSectionChange("debtor");
  }

  const handleSectionChange = () => {
    if (activeSection === 'debtor')
      return onSectionChange(null);

    Analytics.dispatchEvent({
      category: 'User',
      action: 'Clicked `Show More` button at Proposal Limits',
      label: "debtor"
    })

    return onSectionChange('debtor');
  }

  const buttonType = (activeSection === 'debtor') ? "primary" : 'default'

  return (
    <>
      <Row justify="space-between">
        <Col className="flexDisplay">
          <Row gutter={24}>
            <Col>
              <h3>Debtor</h3>
              <Popover content={debtorContent} title="Debtor Limit" trigger="click">
                <InfoCircleOutlined className="ml8" style={{color: '#1890FF'}}/>
              </Popover>
            </Col>
            <Col>
              <h3>Legend</h3>
              <Popover content={legendOfCellColors} title="Legend" trigger="click">
                <InfoCircleOutlined className="ml8" style={{color: '#1890FF'}}/>
              </Popover>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button icon={<SnippetsOutlined/>} onClick={handleSectionChange} size="small" type={buttonType}>
            Show more
          </Button>
        </Col>
      </Row>
      <SmartTable
        bordered={true}
        onCellClick={onClick}
        usePagination={false}
        columns={prepareColumns(currency)}
        showTooltipOnCell={false}
        externalData={prepareRows(data)}/>
    </>
  )
}

export default DebtorMatrix;