import React from 'react';
//Custom Components
import { CustomImage, MessageBox } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const LoansMngmt = () => {
  return (
    <>
      <div>
        <CustomImage
          alt="Loans Management Full View"
          src={assets.images.loansModule.fullView}
          description="Loans Management section allows the User to access the full list of Loans across all the
          SPVs in his portfolio and it produces several aggregated statistics on top of these loans."
        />
      </div>
      <MessageBox
        title="Reminder"
        description="Refer to Glossary for detailed information on the columns of the table."
      /><br/>
      <div>
        <p>The User can perform the following actions:</p>
        <ul>
          <li>Search for Specific Loans (by Loan ID) or Borrowers (Company Name)</li>
          <li>Apply quick filters by SPV Name or Pool Addition Date</li>
          <li>Filter/Sort/Download</li>
          <li>Create Aggregated Statistics</li>
          <li>View and Manage Single Loan Details:
            <ul>
              <li>Loan Timeline (Amortization Plan)</li>
              <li>Debtor details (all the loans with the specific debtor)</li>
              <li>Collateral type and additional description</li>
              <li>Details of the loan at the date it was added to the pool</li>
              <li>Take Notes</li>
              <li>Access files (e.g. covenant certificate, loan agreement, financial, etc.) available</li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LoansMngmt;
