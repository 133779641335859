import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const ContributorsToReturn = () => {
  return (
    <>
      <div>
        <p>
          This graph shows the distribution of the loans in the relevant parameters by Average Delinquency Days (X axis)
          and Return (Gross Weighted Average Return – Y axis).
        </p>
      </div>
      <CustomImage
        alt="Analytics Contributors to Return"
        src={assets.images.analyticsModule.contributors}
      />
      <br/>
      <div>
        <p>
          Loans are grouped in bubbles based on the dimension selected among those available (Sector, Rating
          (available to be selected on single SPV level only), Region and Loan Type – top right) and the size is
          linked to the current balance of the loans within that category. The user can rescale the graph both on the
          horizontal and vertical axis zooming in and out, either by using the slider or by directly inputting the
          desired values in the boxes.
        </p>
      </div>
    </>
  );
};

export default ContributorsToReturn;
