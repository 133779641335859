import Base64 from "base-64";

import { authStorage } from "./api";

const extractParameterFromQuery = (name: any, query: any) => {
  let match = RegExp('[?&]' + name + '=([^&]*)').exec(query);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

const createSession = (query: any) => {
  if (!query)
    return;

  /* Temporary Access Link to the platform */
  const key = extractParameterFromQuery("apiKey", query);

  if (key) {
    try {
      const decoded = Base64.decode(key);
      const [access, refresh] = decoded.split(";");

      authStorage.setAccessToken(access);
      authStorage.setRefreshToken(refresh);

      window.history.replaceState(null, "Securitization", '/')
    } catch (error) {
      console.error("Unable to establish session")
    }
  }
}

export const subscribeToHistory = (history: any) => {

  createSession(history.location.search);

  history.listen((location: any) => {
    if (location.state && location.state.hasOwnProperty("from"))
      createSession(location.state.from.search);
  });
}

