import React from 'react';
//Other Components
import {Checkbox} from "antd";
//Context
import {useWaiverContext} from "../calculation_waiver/calculation_waiver_provider";

const CheckboxItem = ({record}: any) => {

  const waiverContext: any = useWaiverContext();

  const onChange = (e: any) => {
    waiverContext.updateCheckedItems(record.id, e.target.checked)
  };

  const selectedLoans = waiverContext.loans;
  const loan = selectedLoans.find((l: any) => l.loan === record.id);

  return <Checkbox checked={!!loan} onChange={onChange} />;
};

export default CheckboxItem;
