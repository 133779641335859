import React, { useState } from "react";
//Components
import { Col, Divider, Row } from "antd";
import DetailSection from "./detail_section";
import SellerLimits from "./seller_limits";
import DebtorMatrix from "./debtor_matrix";

//Helpers

interface Props {
  proposalId?: any,
  vehicleId?: any
  urlParam?: any
  currency?: any
}

const SellerAndDebtorPanel = ({proposalId, vehicleId, urlParam, currency}: Props) => {

  const [activeSection, setActiveSection] = useState(null);
  const [debtorQueryParams, setDebtorQueryParams] = useState(null);

  const updateQueryParams = (queryParams: any) => {
    setDebtorQueryParams(queryParams)
  }

  return (
    <Row gutter={40}>
      <Col xs={12}>
        <SellerLimits
          currency={currency}
          urlParam={urlParam}
          proposalId={proposalId}
          vehicleId={vehicleId}
          onSectionChange={setActiveSection}
          activeSection={activeSection}/>
      </Col>
      <Col xs={12}>
        <DebtorMatrix urlParam={urlParam}
                      currency={currency}
                      vehicleId={vehicleId}
                      proposalId={proposalId}
                      updateQueryParams={updateQueryParams}
                      onSectionChange={setActiveSection}
                      activeSection={activeSection}/>
      </Col>
      <Divider/>
      {activeSection !== null &&
        <Col xs={24}>
          <DetailSection
            currency={currency}
            urlParam={urlParam}
            vehicleId={vehicleId}
            proposalId={proposalId}
            section={activeSection}
            debtorQueryParams={debtorQueryParams}
            updateQueryParams={updateQueryParams}
          />
        </Col>
      }
    </Row>
  )
}

export default SellerAndDebtorPanel;