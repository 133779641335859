import React from 'react';
//Other Libs
import { ResponsivePie } from "@nivo/pie";
import { useTheme } from "styled-components";
//Helpers
import { formatters } from "../../../../../helpers";
//Constants
import defaultProperties from "./config";
import { theme } from "../../../../../assets/theme/colors";

interface DoughnutProps {
  config?: any,
  displayLabels?: any,
  innerRadius?: any,
  width?: any,
  height?: any,
  parentWidth?: any
}

const Doughnut = (props: DoughnutProps) => {
  const {darkMode}: any = useTheme();

  const {config, displayLabels, innerRadius, width, height, parentWidth} = props;

  if (!config || !config.length)
    return null;

  const chartData: any = config.map((record: any, index: any) => ({
    ...record,
    id:  index,
  }))

  const params = {
    borderColor: {from: 'color', modifiers: [['darker', 0.2]]}
  }

  if (darkMode) {
    params.borderColor.modifiers[0] = ['darker', 0.0]
  }

  return (
    <div style={{width: parentWidth || width, height: height}}>
      {  // @ts-ignore
        <ResponsivePie
          data={chartData}
          {...defaultProperties}
          {...params}
          isInteractive={false}
          innerRadius={innerRadius}
          enableArcLinkLabels={displayLabels}
          colors={chartData.map((r: any) => r.color)}
          valueFormat={formatters.percentOnePrecision}
          arcLinkLabelsTextColor={displayLabels ? theme.colors.black : theme.colors.white}
          arcLabelsTextColor={(r) => r.label === "remaining" ? theme.colors.white : theme.colors.darkgrey}
          margin={{top: 50, right: 50, bottom: 50, left: 50}}
          arcLinkLabelsDiagonalLength={12}
          arcLinkLabelsStraightLength={12}
        />
      }
    </div>
  );
};

export default Doughnut;
Doughnut.defaultProps = {
  innerRadius: 0.85,
  displayLabels: false,
}
