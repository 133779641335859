import React from 'react';
//Containers
import { Provider } from "./context";
import { useQuery } from "../../helpers";
//Custom Components
import Vehicles from "./components/vehicles";
import { Skeleton } from "antd";
//Custom Hooks
import { useGlobalApi, useRedirection } from "../../utils/hooks";
import { withNavigationTabsProvider } from "../../utils";

const useVehicles = () => {
  return useQuery({
    url: '/main/investor/get_investor_vehicles/'
  })
}

export const useCashFlows = () => {
  return useQuery({
    url: '/main/cash_flows/grouped_cash_flows/get_cash_flow_types/'
  })
}

const SPVManagement = () => {
  const {rules, source}: any = useRedirection();

  const vehicles = useVehicles();
  const cashFlows = useCashFlows();
  const {sectors, countries} = useGlobalApi();


  if (!vehicles.data) return <Skeleton/>

  return (
    <Provider sectors={sectors} countries={countries} cashflowList={cashFlows.data}>
      <Vehicles
        extraSpace
        data={vehicles.data}
        redirectionRule={rules}
        redirectionSource={source}/>
    </Provider>
  );
};


export default withNavigationTabsProvider({
  title: 'SPV Management'
})(SPVManagement);