import React from 'react';
import {Row, Col} from "antd";
import queryString from "query-string";
import {groupedColumns} from "./config";
import Actions from "./actions";
import SmartTable from "../../../../../../design/table";
import {useProposal} from "../../../../../../context";
import {useTableFilters} from "../../../../../../design/table/helpers";
import {createFileName, downloadClientFile, useQuery} from "../../../../../../../../helpers";

const LtcAndOutstanding = (props: any) => {
    const {vehicleId, proposalId, fetchProposal} = useProposal();
    const {filters, updateFilters, updateFilter, setPage} = useTableFilters();
    const [groups, setGroups] = React.useState<string[]>(["standard", "junior", "senior"])

    const eligibility = useQuery({
        url: () => {
            const query = queryString.stringify({
                ...filters,
                page_size: 16,
                proposal_id: proposalId,
                vehicle_id: vehicleId,
            })
            return `/casavo/notes_manager/ltc_and_bb/?${query}`
        },
        deps: [filters]
    });

    const handleDownload = () => {
        downloadClientFile({
            filename: createFileName("ltc_and_bb", "xlsx"),
            url: `/casavo/notes_manager/download_ltc_and_bb/?${queryString.stringify({
                ...filters,
                vehicle_id: vehicleId,
                proposal_id: proposalId
            })}`,
        })
    }

    const memoizedColumns = React.useMemo(() => {
        const columns: any = [];

        for (let group of groups)
            groupedColumns[group].forEach((col: any) => columns.push(col))

        return [
            {
                label: 'Real Estate ID',
                key: 'real_estate_id',
                fixed: 'left',
            },
            {
                label: 'Transaction ID',
                key: 'real_estate_id',
                fixed: 'left',
            },
            ...columns
        ]
    }, [groups])


    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Row justify="end">
                    <Col>
                        <Actions
                            groups={groups}
                            filters={filters}
                            setGroups={setGroups}
                            updateFilter={updateFilter}
                            updateFilters={updateFilters}
                            handleDownload={handleDownload}/>
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <SmartTable
                            pagination={{
                                pageSize: 16,
                                current: filters.page,
                                showSizeChanger: false,
                                total: eligibility?.data?.count,
                                onChange: (event: any) => setPage(event)
                            }}
                            columns={memoizedColumns}
                            loading={eligibility?.loading}
                            dataSource={eligibility?.data?.results}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default LtcAndOutstanding;