import {Button, Col, Popconfirm, Row} from "antd";
import React from "react";

const StepperFooter = (props: any) => {
    const {canGoBack, backLabel, nextLabel, onBack, onNext, disabled, confirmLabel = null, loading = false} = props;
    return (
        <Row
            gutter={16}
            justify="end"
            style={{
                left: 0,
                bottom: 0,
                zIndex: 1001,
                width: '100%',
                position: 'fixed',
                paddingTop: 16,
                paddingBottom: 16,
                backdropFilter: 'blur(4px)',
                borderTop: '1px solid rgba(0, 0, 0, 0.06)',
            }}>

            {canGoBack && (
                <Col>
                    <Button onClick={onBack}>
                        {`Back to ${backLabel}`}
                    </Button>
                </Col>
            )}
            <Col>
                {confirmLabel ? (
                    <Popconfirm
                        okText="Yes"
                        cancelText="No"
                        onConfirm={onNext}
                        title={confirmLabel}>
                        <Button disabled={disabled} loading={loading} type="primary">
                            {nextLabel}
                        </Button>
                    </Popconfirm>
                ) : (
                    <Button disabled={disabled} loading={loading} onClick={onNext} type="primary">
                        {nextLabel}
                    </Button>
                )}

            </Col>
        </Row>
    )
};

const StepperContainer = ({children, ...rest}: any) => {
    return (
        <>
            {children}
            <StepperFooter {...rest}/>
        </>
    )
};

export default StepperContainer;