//Helpers & Configurations
import KEYS from './keys';
import { capitalize } from "../../../../helpers";
import withDownload from "../../../../utils/hocs/with_download";
//Custom Components
import Limits from "./notes_section/limits";
import Loans from "./collateral_section/loans";
import Accounts from './notes_section/accounts'
import Structure from "./notes_section/structure";
import DelaysAndLoss from "./collateral_section/delays_and_loss";
import Concentration from "./collateral_section/diversification";
import { CashFlowCollateral, CashFlowNotes } from "./common/cashflows";
//General Components
import { Debtor, Diversification, GeographyContent, SectorChart } from "../../../common";

const sections: any = [
  {
    key: KEYS.notes,
    label: "Notes",
  },
  {
    key: KEYS.collateral,
    label: "Collateral",
  }
];

const notes: any = [
  {
    key: KEYS.structure,
    label: "Structure",
    content: Structure
  },
  {
    key: KEYS.cashFlowNotes,
    label: "Cash Flow",
    props: {
      type: KEYS.notes,
    },
    content: CashFlowNotes
  },
  {
    key: KEYS.limits,
    label: "Limits",
    content: Limits
  },
  {
    key: KEYS.accounts,
    label: "Accounts",
    content: Accounts
  }
];

const collateral = [
  {
    label: "Cash Flow",
    key: KEYS.cashFlowCollateral,
    props: { type: KEYS.collateral },
    content: CashFlowCollateral
  },
  {
    key: KEYS.performance,
    label: "Performance",
    content: DelaysAndLoss
  },
  {
    key: KEYS.diversification,
    label: "Diversification",
    content: Concentration
  },
  {
    key: KEYS.loans,
    label: "Loans",
    content: Loans
  }
];

const diversification: any = [
  {
    key: KEYS.breakdown,
    label: "General",
    content: Diversification,
  },
  {
    key: KEYS.geography,
    label: "Geography",
    content: withDownload(GeographyContent)({
      disableSnapshotOnDarkMode: true
    }),
  },
  {
    key: KEYS.sector,
    label: "Sector",
    content: withDownload(SectorChart)({disableSnapshotOnDarkMode: false})
  },
  {
    key: KEYS.debtor,
    label: "Debtor",
    content: Debtor
  }
];

function formatTabs(records: any[]) {
  return records.map(record => {
    return {
      key: record.key,
      label: record.label
    }
  })
}

function getVehicleOptions(vehicles: any[]) {
  return vehicles.map(vehicle => ({
    key: vehicle.vehicle_name,
    label: vehicle.vehicle_name
  }));
}

function getSectionsOptions(section: string, subSection = null) {
  let list: any[] = [];

  if (section === KEYS.notes)
    list = TABS.extractNotes;

  if (section === KEYS.collateral)
    list = TABS.extractCollateral

  let initialOption = list[0];

  if (subSection)
    initialOption = {
      key: subSection,
      label: capitalize(subSection)
    }

  return {
    clear: [],
    key: initialOption.key,
    label: initialOption.label,
    options: {
      list: list,
      initial: list[0]
    }
  }
}

function getNestedTypes() {
  return TABS.extractSections.map((record: { key: any; }) => {
    const key = record.key;
    return {
      ...record,
      child: OPTIONS.extractSectionOptions(key)
    }
  })
}

function prepareTabs(sections: any[]) {
  return sections.map(section => {

    const object: any = {
      key: section.key,
      label: section.label,
      clearRest: section.key !== KEYS.diversification
    };

    if (section.key === KEYS.diversification) {
      const list = formatTabs(diversification);
      const initial = list[0];
      object.child = {
        ...initial,
        options: {
          list: list,
          initial: initial
        }
      };
    }

    return object;
  })
}

const extractNotes = prepareTabs(notes);
const extractCollateral = prepareTabs(collateral);
const extractConcentration = extractCollateral.find(tab => tab.key === KEYS.diversification)?.child.options.list;


export const TABS = {
  extractSections: sections,
  extractNotes: extractNotes,
  extractCollateral: extractCollateral,
  extractConcentration: extractConcentration
};


export const OPTIONS = {
  extractVehicleOptions: getVehicleOptions,
  extractSectionOptions: getSectionsOptions,
  extractNestedTypes: getNestedTypes
};


export default {
  notes,
  collateral,
  diversification,
}
