import React from 'react';
//Components
import { Col, Row } from "antd";
import { SimpleEmpty } from "@cardoai/components";
//Custom Components
import Headers from "./components/headers";
import Tbody from "./components/table_body";
//Styles
import Styles from "./styles";
//Helpers
import { isEmpty } from "../../../../helpers";

interface ILegendTable {
  centered?: boolean,
  title?: string,
  data?: any,
  colors?: any,
  onClick: (param: any) => any,
  children?: any,
  fill?: boolean,
  height?: any,
  ratingLabel?: any,
  currency?: any
}

const LegendTable = (props: ILegendTable) => {
  const {centered, title, data, colors, onClick, children, fill, height, ratingLabel, currency} = props;
  const containerStyle: any = {};
  const alignRow: any = centered ? "middle" : "start"

  if (height) {
    containerStyle.maxHeight = height;
    containerStyle.overflow = "auto";
  }

  if (isEmpty(data))
    return <SimpleEmpty/>

  return (
    <Styles>
      <Row gutter={8} align={alignRow}>
        {children && <Col lg={7} md={24} sm={24}>
          {children}
        </Col>}
        <Col lg={fill ? 24 : 17} md={24} sm={24}>
          <div style={containerStyle}>
            <table className="fullWidth customTable">
              <Headers ratingLabel={ratingLabel} title={title}/>
              <Tbody
                data={data}
                colors={colors}
                currency={currency}
                onClick={onClick}/>
            </table>
          </div>
        </Col>
      </Row>
    </Styles>
  );
};

LegendTable.defaultProps = {
  centered: false,
  fill: false
};

export default LegendTable;