import React from 'react';
//Components
import { Col, Row, Select } from "antd";
import { SmartChart } from "../../../../components";
//Helpers
import { Analytics, formatters } from "../../../../helpers";
//Configurations
import { withData } from "../../../../utils";
import { prepareVehicleContentUrl } from "../../../spv/components/management/helpers";
import { dataSetsConfig } from "./config";

interface IIrrGraph {
  data?: any
}

const IrrGraph = ({data}: IIrrGraph) => {

  const years = Object.keys(data);
  const [selectedYear, setSelectedYear] = React.useState<any>(years);

  const onYearSelect = (event: any) => {
    setSelectedYear(event);
    Analytics.dispatchEvent({
      category: 'User',
      action: '(HDR user): Selected years at IRR Graph',
      label: event
    });
  }

  const datasets: any = dataSetsConfig.map((_: any) => ({..._, data: []}))


  for (let year of selectedYear) {
    const record = data[year];
    datasets.forEach((item: any) => {
      const key = item.key;
      if (record.hasOwnProperty(key)) {
        item.data.push(record[key])
      }
    })
  }

  return (
    <>
      <Row justify='end' align='middle' gutter={8} className="mb16">
        <Col className="bolder size14">
          Filter by Year/s:
        </Col>
        <Col>
          <Select autoFocus size='small' allowClear placeholder="Choose year/s" mode="multiple"
                  value={selectedYear}
                  className="fullWidth"
                  style={{minWidth: 120}}
                  onChange={onYearSelect}>
            {Object.keys(data).map((key: any) => (<Select.Option value={key} key={key}>{key}</Select.Option>))}
          </Select>
        </Col>
      </Row>
      <SmartChart
        usesMenu
        height={400}
        title="IRR Graph"
        innerDatasets={false}
        displayLabels={false}
        types={['bar', 'line']}
        labels={selectedYear}
        dataSets={datasets}
        format={formatters.percent}/>
    </>
  )
}

export default withData({
  urlFormatter: prepareVehicleContentUrl('/main/vdr/irr-graph'),
  usesDateFilters: false,
  showIntervals: false,
})(IrrGraph);
