import React from 'react';
//Custom Components
import { CountryView } from "../../../../../components/maps/world/components/country_view";

interface Props {
  data?: any,
  view?: any,
  onClick: (param: any) => any,
  showCountryCards?: any
  currency?: any
}

const CountriesComponent = (props: Props) => {
  const {data, view, onClick, showCountryCards, currency} = props;

  return <CountryView view={view} countries={data} onClick={onClick} continent={"world"} currency={currency}
                      showCountryCards={showCountryCards}/>
};

export default CountriesComponent;
