import React from 'react';
//Components
import { MinusOutlined } from "@ant-design/icons";
//CardoUI Components
import { FullScreenButton, SimpleEmpty } from "@cardoai/components";
//Custom Components
import Table from "./table";
import { CellLink, CollateralCell, NotesCell, TrancheCoupon } from './components'
import WaterfallChart from "./waterfall_chart/chart_content";
import { Ratings } from "../../../../../common";
import Legend from "../../../../../../components/charts/static_legend";
//Custom Helpers
import { prepareData } from "./config";
import { prepareVehicleContentUrl } from "../../helpers";
import { withData, withFullscreen } from "../../../../../../utils";
import { formatters, isEmpty, MathOp, Text, validateValue } from "../../../../../../helpers";
//Constants
import datasetConfig from "./waterfall_chart/constants";
import { theme } from "../../../../../../assets/theme/colors";

interface Props {
  vehicle?: any,
  data?: any,
  headerHeight?: any,
  chartHeight?: any,
  openFullscreen?: any,
  fullscreen?: any,
  fullscreenHeight?: any,
  currency?: any
}

function formatValues(value: number, isLoanType: boolean) {
  return (isLoanType && !value) ? <MinusOutlined/> : value;
}

const StructureContent = (props: Props) => {
  const {vehicle, data, headerHeight, chartHeight, openFullscreen, fullscreen, fullscreenHeight} = props;
  const isCasavo = vehicle.vehicle_identifier === 'casavo_iota';

  const finalHeight = fullscreen ? 6 / 10 * fullscreenHeight : chartHeight;

  if (isEmpty(data.details))
    return <SimpleEmpty/>;

  if (!finalHeight)
    return null;

  const chartContentHeight = finalHeight - headerHeight;

  const formattedData = prepareData(data);

  const rowInformation: any = formattedData.details;

  function calculateHeight(value: any): any {
    return MathOp.multiply(value, chartContentHeight)
  }

  function calculateCollateralHeight(value: any) {
    const rowSpan = rowInformation.length;
    const totalHeight = chartContentHeight + rowSpan * 80;
    return MathOp.multiply(value, totalHeight);
  }

  function isItSameTrancheClass(next: any, current: any) {
    return !!(next && next.trancheClass === current);
  }

  const rows = rowInformation.map((note: any, index: number) => {
    const {
      name,
      value,
      trancheCurrency: currency,
      trancheClass: currentClass,
      type,
      attachmentPoint,
      current_coupon: currentCoupon
    } = note;

    let hideAttachmentLink = false;
    const isLoanType = type === "Loan";
    const height = calculateHeight(value) + 80;
    const exists = rowInformation.length >= index;

    if (exists)
      hideAttachmentLink = isItSameTrancheClass(rowInformation[index + 1], currentClass)

    const hasSingleCoupon = typeof currentCoupon === "number";

    if (vehicle.vehicle_identifier === "aurora" && note.ooc === 102.07142857142857) note.ooc = 0

    return (
      <tr style={{height}} key={String(index)}>
        {!isCasavo &&
          <CollateralCell
            width={120}
            key={String(index)}
            visible={index === 0}
            rowSpan={rowInformation.length}
            content={formattedData.collateral}
            calculateHeight={calculateCollateralHeight}/>}
        {!isCasavo &&
          <CellLink
            height={height}
            name={type}
            display={!hideAttachmentLink}
            value={attachmentPoint}/>}
        <NotesCell
          width={120}
          height={height}
          name={name}
          type={type}
          borderLess={index !== data.length - 1}/>
        <td>
          {formatters.currency({
            type: 'k', currency,
            value: note.current_balance
          })}
          <br/>
          {formatters.currency({
            type: 'k', currency,
            value: formatValues(note.issue_balance, isLoanType)
          })}
        </td>
        {!isCasavo &&
          <td>
            {validateValue(formatValues(note.coc, isLoanType), formatters.percentNoPrecision)}
            <br/>
            {validateValue(formatValues(note.ooc, isLoanType), formatters.percentNoPrecision)}
          </td>}
        <td>{validateValue(formatValues(note.factor, isLoanType), formatters.percentNoPrecision)}</td>
        {!isCasavo &&
          <td>{hasSingleCoupon
            ? <>{validateValue(formatValues(currentCoupon, isLoanType), formatters.percent)}</>
            : <TrancheCoupon coupons={currentCoupon} currency={currency}/>}
          </td>}
        <td>
          <Ratings
            height={height}
            detailed={false}
            data={note.ratings}
            hideRatings={isLoanType}/>
        </td>
        <td style={{
          width: 900,
          borderRight: `1px solid ${theme.palette.grayscale[2]}`
        }}>
          <WaterfallChart
            height={height}
            vehicle={vehicle}
            currency={currency}
            data={note.repayment}/>
        </td>
      </tr>
    )
  });

  return (
    <div className="disable_color_invert">
      <Table>
        <thead>
        <tr style={{height: headerHeight}}>
          {!isCasavo &&
            <th className="bold">
              Collateral
            </th>}
          {!isCasavo && <th/>}
          <th className="noBorder bold">
            Notes
          </th>
          <th className="bold">
            {Text("C.Balance  \n O.Balance")}
          </th>
          {!isCasavo &&
            <th className="bold" style={{width: 50}}>
              {Text("C.OC (%)  \n O.OC (%)")}
            </th>}
          <th className="bold">Factor</th>
          {!isCasavo && <th className="bold">Coupon</th>}
          <th className="bold">Ratings</th>
          <th className="bold" style={{position: 'relative'}}>
            Payment Waterfall
            {!fullscreen && <div style={{position: 'absolute', right: 0, top: 14}}>
              <FullScreenButton onClick={openFullscreen} title="Open Fullscreen"/>
            </div>}
            <span><Legend staticDisplay disableSelection config={datasetConfig}/></span>
          </th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    </div>
  );
};

StructureContent.defaultProps = {
  chartHeight: 570,
  headerHeight: 50,
};

export default withData({
  urlFormatter: prepareVehicleContentUrl("/main/notes/structure")
})(withFullscreen(StructureContent));
