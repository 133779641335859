import React from "react";
//Other Libs
import queryString from "query-string";
//Components
import { Button, Col, Input, Row, Tag, Tooltip } from "antd";
import { SmartChart, SmartTable } from "../../../../../../../../../../../../components";
//Icons
import { FileExcelOutlined } from "@ant-design/icons";
//Helpers
import { debtorTable } from "../../../config";
import { theme } from "../../../../../../../../../../../../assets/theme/colors";
import {
  Analytics,
  callApi,
  createFileName,
  downloadClientFile,
  formatters
} from "../../../../../../../../../../../../helpers";

interface Props {
  proposalId?: number,
  vehicleId?: number,
  debtorQueryParams?: any,
  updateQueryParams?: any
  urlParam?: any
  currency?: any
}

const DebtorDetails = ({
                         proposalId,
                         vehicleId,
                         debtorQueryParams,
                         updateQueryParams,
                         urlParam,
                         currency
                       }: Props) => {

  const [debtorGraphData, setDebtorGraphData] = React.useState([]);
  const [debtorLimitSearch, setDebtorLimitSearch] = React.useState('');

  const generalQuery = queryString.stringify({
    proposal_id: proposalId,
    vehicle_id: vehicleId
  })

  let debtorLimitsUrl = `/mcs/${urlParam}/debtor_limits/?${generalQuery}&debtor=${debtorLimitSearch}&`;

  if (debtorQueryParams) {
    let queryParams = queryString.stringify(debtorQueryParams)
    debtorLimitsUrl += `${queryParams}&`
  }

  React.useEffect(() => {
    getDebtorGraphData();
    return () => {
      setDebtorLimitSearch('');
    };
  }, [])

  const getDebtorGraphData = () => {
    callApi({
      url: `/mcs/${urlParam}/debtor_concentration/?${generalQuery}`,
      method: 'get',
      onSuccess: (response) => {
        setDebtorGraphData(response)
      },
    })
  }

  const handleDebtorLimitChange = (event: any) => {
    const searchValue = event.target.value;

    setDebtorLimitSearch(searchValue);
    Analytics.dispatchEvent({
      category: 'Filters',
      action: 'Filtered debtor table at proposal limits',
      label: searchValue
    })
  }

  const downloadDebtorsLimits = () => {
    downloadClientFile({
      url: `/mcs/${urlParam}/download_debtor_limits/?${generalQuery}`,
      filename: createFileName("debtor")
    })
    Analytics.dispatchEvent({
      category: 'Download',
      action: 'Downloaded file of debtor details at proposal limits'
    })
  }

  const onRemoveFilters = () => {
    updateQueryParams(null)
  }

  return (
    <Row>
      <Col xs={12}>
        <SmartChart
          showLegend
          height={250}
          data={debtorGraphData}
          hoverOnLegend={false}
          title="Debtor Limits"
          colors={theme.palette.us}
          valueEntity="realized_amount"
          labelEntity="debtor_name"
          types={['pie']}
          format={formatters.currencyNoPrecision}/>
      </Col>
      <Col xs={12}>
        <Row justify="end" align="middle" gutter={8}>
          {debtorQueryParams && <Col>
            <Tag color="blue" closable onClose={onRemoveFilters}>
              Rating Cluster: {debtorQueryParams.rating_cluster} and
              Revenue Cluster: {debtorQueryParams.revenue_cluster}
            </Tag>
          </Col>}
          <Col>
            <Input placeholder="Search All" type="text" value={debtorLimitSearch} style={{width: 200}}
                   onChange={handleDebtorLimitChange}/>
          </Col>
          <Col>
            <Tooltip title="Download Excel" placement='top'>
              <Button icon={<FileExcelOutlined/>} shape='circle' onClick={downloadDebtorsLimits}/>
            </Tooltip>
          </Col>
        </Row>
        <SmartTable
          maxHeight={400}
          url={debtorLimitsUrl}
          columns={debtorTable(currency)}
          initialSort={['-fulfillment']}
        />
      </Col>
    </Row>
  )
}

export default DebtorDetails;