import React from "react";
import { Button } from "antd";
//Helpers
import { capitalize, formatPercent, MathOp } from "../../../../helpers";
//Styles
import styles from "./styles";

interface IUploadButton {
  label?: string,
  disabled?: any,
  loading?: any,
  onClick?: () => void,
  percent?: any,
  fileName?: string,
  className?: string,
  type?: string
}

const UploadButton = (props: IUploadButton) => {
  const {label, disabled, loading, onClick, percent, fileName, className} = props;

  if (!loading) {
    return (
      <Button
        type="primary"
        onClick={onClick}
        disabled={disabled}
        className={className}>
        {label}
      </Button>
    )
  }

  if (!fileName)
    return null;

  const childStyle: any = Object.assign({}, styles.percentageItem);
  const finalPercent = MathOp.multiply(percent, 100)
  childStyle.width = `${finalPercent}%`;
  const barLabel = `Uploading ${capitalize(fileName)} (${formatPercent(percent, 0)})`;

  return (
    <div style={styles.container}>
      <div style={styles.labelItem}>
        {barLabel}
      </div>
      <div style={childStyle}/>
    </div>
  )
};

export default UploadButton;

UploadButton.defaultProps = {
  label: 'Upload'
};