import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Geography = () => {
  return (
    <>
      <div>
        <p className="bold">
          Shows the distribution of the loans of the Collateral pool by Country and/or Region.
        </p><br/>
        <p>
          Clicking on a Country/Region
          on the map or on the list on the right, the User is redirected to the Loans Management module with the list of
          all the loans with the parameters selected (i.e. Advanced Filters set for the relevant SPV and Country/Region,
          see Loans Management for additional details) – by clicking on the Go Back button <img
          src={assets.images.others.goBack} alt="Go Back Button"/> (top left) the User is redirected to the SPV
          Management/Geography tab.
        </p>
      </div>
      <CustomImage
        alt="SPV Management Collateral Diversification Geography"
        src={assets.images.spvModule.geography}
      />
    </>
  );
};

export default Geography;
