import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";


const LoanPurchaseDetails = () => {
  return (
    <CustomImage
      alt="Loan Purchase Details"
      src={assets.images.notesManagerModule.loanPurchaseDetails}
      description="When clicking on the Details button on a specific loan purchase on the main table the following
                pop-up will be displayed. It holds information like  new loa eligibility criteria check and drawdown."
    />
  );
};
export default LoanPurchaseDetails;
