import React from "react";
//Components
import { Col, Row } from "antd";
//Colors
import { currencyColors } from "../../colors";
//Helpers
import { formatMillions } from "../../../../../../helpers";

interface ICurrencies {
  data?: any
}

const getCurrencyStyle = (index: number) => {
  return {
    padding: 4,
    color: '#fff',
    marginBottom: 8,
    fontWeight: 500,
    borderRadius: 4,
    textAlign: 'center',
    fontSize: 10,
    height: 25,
    backgroundColor: currencyColors[index]
  }
}

const CashReserves = ({data}: ICurrencies) => {
  return (
    <Row className="columnDirection">
      {data.map(({value, label}: any, index: number) => {

        const lastElement = index + 1 === data.length;
        const currencyStyle: any = getCurrencyStyle(index);

        if (lastElement) {
          currencyStyle.marginBottom = 0;
          currencyStyle.borderBottomLeftRadius = 0;
          currencyStyle.borderBottomRightRadius = 0;
        }
        return (
          <Col style={currencyStyle} key={index}>
            Cash Reserve - {formatMillions(value, 2)} {label}
          </Col>
        )
      })}
    </Row>
  )
}

export default CashReserves;