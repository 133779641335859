import React from "react";
//Components
import { Layout } from 'antd';
//Settings
import { siteConfig } from "../../../../settings";
import appInfo from "../../../../../package.json";
import { useAuthorization } from "../../../../utils/hooks";

interface Props {
  onClick: () => any
}

export default ({onClick}: Props) => {
  const {user} = useAuthorization();

  //TODO: Luzzatti -> Remove later
  const isLuzzatti = ["Luzzati", "Luzzatti"].includes(user.username)

  return (
    <Layout.Footer className="footer">
      {!isLuzzatti && <span className="pointerCursor" onClick={onClick}>
              {siteConfig.footerText} - v{appInfo.version}
      </span>}
      {isLuzzatti && <span style={{fontSize: 10}}>
        All the data, information, stratification and analyses reported are merely for
        representation purpose. Any considerations or decision regarding the
        exposures herein should be exclusively based on the information contained in
        the legal documentation.
      </span>}
    </Layout.Footer>
  )
}
