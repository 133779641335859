import React from 'react';
import {useWaiverContext} from "../calculation_waiver/calculation_waiver_provider";

const CalculationWavierDisplay = ({record}:any) => {
  const waiverContext: any = useWaiverContext();
  const loanExists = waiverContext.loans.find((l: any)=> l.loan === record.id);

  return (
    <>
      {loanExists ? loanExists.exclusion_type : '-'}
    </>
  );
};

export default CalculationWavierDisplay;