import React from "react";
//Helpers
import { clusters } from "../../config";
import { MathOp } from "../../../../../../../../../../../helpers";
//Components
import { Tooltip } from "antd";
import OtherCluster from "./other_cluster";
import MatrixCells from "./matrix_cells";
import { SmartTable } from "../../../../../../../../../../../components";

const legendOfCellColors = () => {

  const data: any = [
    {
      id: '1',
      title: '0% - 60%',
      color: '',
    },
    {
      id: '2',
      title: '60% - 75%',
      color: '#95DE64',
    },
    {
      id: '3',
      title: '75% - 90%',
      color: '#FFFB8F',
    },
    {
      id: '4',
      title: '90% - 100%',
      color: '#FFD666',
    },
    {
      id: '5',
      title: '> 100%',
      color: '#f8cbad',
    },
  ];

  const prepareCols: any = [
    {
      key: 'title',
      label: 'Realized Percentage',
      render: (title: any) => {
        return (
          <>
            <div style={{
              marginLeft: 50
            }}>
              {title}
            </div>
          </>
        )
      }
    },
    {
      key: 'color',
      label: 'Colors',
      render: (color: any) => {
        return (
          <>
            <div style={{
              backgroundColor: color,
              marginLeft: 15,
              marginRight: 15,
              padding: 5
            }}/>
          </>
        )
      }
    },
  ]

  return (
    <div style={{width: '250px'}}>
      <SmartTable externalData={data} columns={prepareCols}/>
    </div>
  )
}

const colorCell = (ratio: any) => {
  if (ratio <= 0.6)
    return ''
  else if (ratio <= 0.75)
    return '#95DE64'
  else if (ratio <= 0.9)
    return '#FFFB8F'
  else if (ratio < 1)
    return '#FFD666'
  else if (ratio >= 1)
    return '#f8cbad'
  else
    return null;
}

const prepareRows = (categories: any) => {
  const objects = [];
  for (let category in categories) {
    const object: any = {
      category: category
    }
    Object.keys(categories[category]).forEach(key => {
      object[key] = categories[category][key]
    })
    objects.push(object);
  }
  return objects;
}

const prepareColumns = (currency: any): any => {
  return [
    {
      key: 'category',
      label: 'Top debtor (Revenues/Rating)',
      align: 'center'
    },
    ...clusters.map((item: any) => ({
        key: item,
        label: item,
        render: (value: any) => {
          if (!value)
            return null;

          let ratio = null;

          if (value.threshold !== 0)
            ratio = MathOp.divide(value.realized, value.threshold);

          const customStyle: any = {
            backgroundColor: colorCell(ratio)
          }

          return (
            <Tooltip title="Filter by this cluster">
              <div style={customStyle}>
                {item === "Other" ? <OtherCluster currency={currency} data={value}/> :
                  <MatrixCells currency={currency} data={value}/>}
              </div>
            </Tooltip>
          )
        },
        width: 130
      })
    )
  ]
}

export {
  prepareRows,
  prepareColumns,
  legendOfCellColors
}