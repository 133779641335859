import React from 'react';

const SearchIcon = ({active, ...rest}: any) => {
    const stroke = active ? "#2D72EA" : "#8F8796"
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M14.875 22.75C19.2242 22.75 22.75 19.2242 22.75 14.875C22.75 10.5258 19.2242 7 14.875 7C10.5258 7 7 10.5258 7 14.875C7 19.2242 10.5258 22.75 14.875 22.75Z"
                stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.4434 20.4434L24.9996 24.9996" stroke={stroke} stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    );
};

export default SearchIcon;