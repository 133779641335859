import React from 'react';
//Custom Components
import RegionsMap from "../../../../../../components/maps/regions";
//Helpers
import { MAPS } from "../../../../../common/charts/geography/helpers";
import { computeColorIntensity } from "../../../../../common/charts/geography/helpers";


const GeographyMap = ({data}: any) => {

  const formatRecords = (records:any) => {
    return records.map((record: any) => {
      const state: any = {};

      for (let key in record) {
        let value = record[key];

        if (value === "-")
          value = null;
        state[key] = value;
      }
      return state;
    })
  }

  const {records, legend}: any = computeColorIntensity(formatRecords(data), "balance_percent");

  return (
    <RegionsMap
      height={380}
      map={MAPS.Italy}
      scale={legend}
      selected={records}
      onClick={() => {

      }}
      countryMode={false}
      ratingLabel={''}
      currency={{}}
      labels={['name', 'balance']}
      colors={records.map((item: any) => item.color)}/>
  );
};

export default GeographyMap;
