import { authStorage } from "./api";

export function isVDRUser() {
  const userRole: any = authStorage.getRole();
  return userRole.includes("VDR");
}

export function isVDRInvestor() {
  const userRole = authStorage.getRole();
  return userRole === "InvestorVDR";
}
