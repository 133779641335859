import React from "react";
//Components
import { Col, Row } from "antd";

interface IRotateCell {
  index?: any,
  children?: any
}

const RotateCell = (props: IRotateCell) => {
  const {index} = props;
  return (
    <th className="borderRight" style={{width: 40}} key={index}>
      <Row justify='center'>
        <Col className="textRotate">
          {props.children}
        </Col>
      </Row>
    </th>
  )
}


export default RotateCell;