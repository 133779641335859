import React from 'react';
import {Button, Popover, Row, Col} from "antd";
import {useToggle} from "@cardoai/utils";
import Filters from "../../../../../../../design/filters";
import MoreIcon from "../../../../../../../icons/more";
import {useQuery} from "../../../../../../../../../helpers";
import DownloadIcon from "../../../../../../../icons/download";
import ChooseColumns from "../../../../../../../design/choose_columns";
import ColumnChooserIcon from "../../../../../../../icons/column_chooser";
import SmartSearch from "../../../../../../../design/smart_search";

const Actions = ({groups, filters, updateFilters, setGroups, handleDownload}: any) => {
    const [moreVisible, toggleMore]: any = useToggle(false);
    const [columnsChooser, toggleColumnsChooser]: any = useToggle(false);
    const filterOptions = useQuery({url: `/main/real_estate/real_estate_filter_options/`});

    return (
        <Row align="bottom" gutter={8}>
            <Col>
                <SmartSearch
                    width={450}
                    onChange={(event: any) => {
                        updateFilters(event);
                    }}
                    config={[
                        {
                            type: 'input',
                            defaultOpen: true,
                            key: "real_estate_id",
                            label: "Real Estate ID",
                            defaultValue: filters.real_estate_id
                        },
                        {
                            type: 'input',
                            key: "transaction_id",
                            label: "Transaction ID",
                            defaultValue: filters.transaction_id
                        }
                    ]}/>
            </Col>
            <Col>
                <Button
                    type="link"
                    onClick={handleDownload}
                    ghost icon={<DownloadIcon/>}/>
            </Col>
            <Col>
                <Popover
                    trigger="click"
                    open={columnsChooser}
                    placement="bottomRight"
                    onOpenChange={toggleColumnsChooser}
                    content={(
                        <ChooseColumns
                            value={groups}
                            onChange={setGroups}
                            onClose={toggleColumnsChooser}
                            config={[
                                {key: "standard", label: "Standard"},
                                {key: "junior", label: "Junior"},
                                {key: "senior", label: "Senior"}
                            ]}
                        />
                    )}>
                    <Button type="link" ghost icon={<ColumnChooserIcon active={!!columnsChooser}/>}/>
                </Popover>
            </Col>
            <Col>
                <Popover
                    trigger="click"
                    showArrow={false}
                    open={moreVisible}
                    placement="bottomRight"
                    onOpenChange={toggleMore}
                    content={(
                        <Filters
                            onChange={(event: any) => {
                                toggleMore();
                                updateFilters(event);
                            }}
                            config={[
                                {
                                    multiple: true,
                                    type: 'select',
                                    key: "acquisition_status",
                                    label: "Acquisition Status",
                                    defaultValue: filters.acquisition_status,
                                    options: filterOptions?.data?.acquisition_status
                                },
                                {
                                    multiple: true,
                                    type: 'select',
                                    key: "asset_status",
                                    label: "Select status as reported",
                                    defaultValue: filters.asset_status,
                                    options: filterOptions?.data?.asset_status
                                }
                            ]}/>
                    )}
                >
                    <Button type="link" ghost icon={<MoreIcon/>}/>
                </Popover>
            </Col>
        </Row>
    );
};

export default Actions;