import React, { useEffect, useState } from 'react';
//Components
import { Button, Col, Row, Skeleton } from "antd";
import { BoldText } from "@cardoai/components";
//Helpers
import { userInput } from "./config";
//Icons
import { FileDoneOutlined } from "@ant-design/icons";
//Helpers
import { callApi } from "../../../../../../../../../../../helpers";
import { notifications } from "../../../../../../../../../../../components";

const InputTabDetails = (props: any) => {

  const {getData, onCancel, proposalId, vehicleId} = props;
  const [userChanges, setUserChanges] = useState([]);
  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingButton, setLoadingButton] = React.useState<any>(false)

  useEffect(() => {
    getInputsValue()
  }, [])

  const getInputsValue = () => {
    callApi({
      url: `/casavo/notes_manager/${proposalId}/get_account_info/?vehicle_id=${vehicleId}`,
      method: "get",
      setLoading: setLoadingContent,
      onSuccess: (response: any) => setUserChanges(response),
      onError: (response: any) => {
        notifications.error(response?.data?.detail)
      }
    })
  }

  const onInputChange = (key: string) => (event: any) => {
    setUserChanges((prevState: any) => ({
      ...prevState,
      [key]: event
    }))
  }

  const onSubmitProposal = () => {
    callApi({
      url: `/casavo/notes_manager/${proposalId}/update_account_info/?vehicle_id=${vehicleId}`,
      method: "put",
      body: userChanges,
      setLoading: setLoadingButton,
      onSuccess: () => {
        onCancel();
        getData();
      },
      onError: (response: any) => {
        notifications.error(response?.data?.detail)
      }
    })
  }

  if (loadingContent)
    return <Skeleton active/>

  return (
    <>
      {userInput.map((inputElement: any) => {
        const {label, key, Component, props} = inputElement
        return (
          <Row align="middle" justify="space-between" gutter={[16, 16]} className="mb8" key={key}>
            <Col><BoldText>{label}</BoldText></Col>
            <Col><Component value={userChanges[key] || 0} onChange={onInputChange(key)} {...props}/></Col>
          </Row>
        )
      })}
      <Row justify="center">
        <Button loading={loadingButton} icon={<FileDoneOutlined/>} type="primary" onClick={onSubmitProposal}>
          Save
        </Button>
      </Row>
    </>
  );
};

export default InputTabDetails;