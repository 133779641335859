import React, { useEffect } from 'react';
//External Libs
import { useTheme } from "styled-components";
//Utils
import { theme } from "../../../../../assets/theme/colors";
import { isVDRUser } from "../../../../../helpers";
//Config
//Icons
import { CloseOutlined } from "@ant-design/icons";
//Custom Styles
import Styles from "./styles";

const ChooseRole = ({toggleVisibility, visible, role, changeRole, userRoles}: any) => {
  const {darkMode, enableDarkMode}: any = useTheme();


  useEffect(() => {
    if (!darkMode && isVDRUser())
      enableDarkMode();
  }, [role])

  const onRoleChange = (record: any) => () => {
    setTimeout(() => {
      toggleVisibility(false)
    }, 600)

    changeRole(record.name);
  }

  return (
    <>
      {visible && (
        <Styles>
          <div className="role-wrapper-active role-wrapper">
            {userRoles.map((record: any, index: number) => {

              const active = role === record.name;

              const cardStyle: any = {};

              if (active) {
                if (darkMode)
                  cardStyle.border = `1px solid ${theme.colors.primary}`
                else
                  cardStyle.border = `2px solid ${theme.colors.primary}`
              }

              return (
                <div
                  key={String(index)}
                  style={cardStyle}
                  onClick={onRoleChange(record)}
                  className={darkMode ? "disable_color_invert white-fill-svg" : "disable_color_invert"}>
                  {record.image}
                  <h2>{record.title}</h2>
                </div>
              )
            })}
            <div className="close-role" onClick={toggleVisibility}>
              <CloseOutlined/>
            </div>
          </div>
        </Styles>
      )}
    </>
  );
};

export default ChooseRole;
