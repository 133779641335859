import React from 'react';
//Components
import { Badge, Col, Row } from "antd";
//Icons
import { DownOutlined, RightOutlined } from "@ant-design/icons";
//Constants
import { theme } from "../../../../../assets/theme/colors";

interface Props {
  expand?: any,
  value?: any,
  column?: any,
  empty?: boolean,
  records?: any,
  onClick?: any,
  getData?: any
}

const cellStyle = {
  fontSize: 12
}

const GroupCell = (props: Props) => {
  const {expand, value, column, empty, records, onClick} = props;
  const style = {backgroundColor: theme.colors.darkwhite};

  if (empty)
    return <td style={style}/>;

  const Icon = expand ? <RightOutlined style={cellStyle}/> : <DownOutlined style={cellStyle}/>;

  return (
    <td style={style}
        onClick={onClick}
        className="pointerCursor fade-in">
      <Row className="fullWidth"
           style={{
             flexWrap: 'nowrap'
           }}
           gutter={8}>
        <Col>
          {Icon}
        </Col>
        <Col className="bolder alignLeft">
          <Row gutter={8} style={{flexWrap: 'nowrap'}}>
            <Col style={{
              maxWidth: column.width,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
              {value}
            </Col>
            <Col>
              <Badge count={records.length} className="site-badge-count-static"/>
            </Col>
          </Row>
        </Col>
      </Row>
    </td>
  );
};

export default GroupCell;
