import React from 'react';
//Components
import { Col, Row, Skeleton } from "antd";
import NotesChart from "./components/notes_chart";
import CollateralChart from "./components/collateral_chart";
//Helpers
import { isEmpty, useQuery } from "../../../../helpers";

const computeNotesSum = (notes: any) => {
  let sum = 0;
  if (!isEmpty(notes))
    notes.forEach((note: any) => sum += note.value);
  return sum;
}

interface INotesCharts {
  maxHeight?: any
  vehicle?: any,
  vehicleId?: any,
}

const NotesManagerCharts = (props: INotesCharts) => {
  const {vehicleId, maxHeight} = props;

  const {data} = useQuery({
    url: `/main/notes/tranche_composition/?vehicle_id=${vehicleId}`
  })

  if (!data)
    return <Skeleton/>

  const notes = data.notes;

  /*Get the Sum of notes and collateral separately*/
  const collateralTotal = data.collateral.total_collateral_amount;
  const notesTotal = computeNotesSum(notes);

  return (
    <>
      <Row gutter={32}>
        <Col xs={12} className="textCenter bolder">
          Collateral
        </Col>
        <Col xs={12} className="textCenter bolder">
          Notes
        </Col>
      </Row>
      <Row gutter={32} style={{height: maxHeight}}>
        <Col xs={12}>
          <CollateralChart maxHeight={maxHeight} data={data.collateral} notesTotal={notesTotal}
                           collateralTotal={collateralTotal}/>
        </Col>
        <Col xs={12}>
          <NotesChart data={data.notes}/>
        </Col>
      </Row>
    </>
  );
};

export default NotesManagerCharts;
