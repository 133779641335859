import React from "react";
//Other Libs
import { Debounce } from "react-throttle";
import { ThemeProvider } from "styled-components";
import WindowResizeListener from "react-window-size-listener";
//Constants
import { theme as defaultTheme} from "./colors";
//Custom Hooks
import { useDarkMode } from "../../utils/hooks";
//Custom Styles
import GlobalStyles from "./global_styles";
import Content from "./content";
interface Props{
  children?:any
}

export default ({children}:Props) => {
  const customTheme = useDarkMode();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const onResize = (event:any) => {
    setHeight(event.windowHeight);
    setWidth(event.windowWidth)
  }

  return (
    <ThemeProvider theme={{
      width: width,
      height: height,
      ...customTheme,
      ...defaultTheme
    }}>
      <Debounce time="200" handler="onResize">
        <WindowResizeListener onResize={onResize}/>
      </Debounce>
      <GlobalStyles/>
      <Content>
        {children}
      </Content>
    </ThemeProvider>
  )
};




