import React, {Component} from 'react';
//Other Libraries
import html2canvas from "html2canvas";
import {getElementAtEvent} from "react-chartjs-2";
//Components
import {Col, message, Row} from "antd";
//Utils
import {withFullscreen} from "../../../../../utils";
import {charts, defaultMultiSetData, defaultProperties, multipleDataSets, singleDataSets} from "./helpers/config";
//Custom CSS
import Styles from './helpers/graph_style';
//Components
import ChartLayout from "./helpers/chart_layout";
import ChartContent from './chart_content';
import {ChartMenu} from "../../../../index";
import GraphPlaceholder from "./helpers/graph_placeholder";
//Helpers
import {formatters, isEmpty} from "../../../../../helpers";
import {theme} from "../../../../../assets/theme/colors";
//Chart Import
import 'chart.js/auto';
//Plugins


//Custom Helpers
function getLayout(type: any, layoutPadding: any) {

    const finalLayout: any = {
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined
    };


    if (!layoutPadding) {
        switch (type) {
            case 'pie':
            case 'doughnut':
                if (!layoutPadding)
                    layoutPadding = 45;
                break;
            case 'horizontalBar':
                layoutPadding = {
                    right: 60,
                };
                break;
            default:
                layoutPadding = 0;
        }
    }


    for (let direction in finalLayout) {
        finalLayout[direction] = typeof layoutPadding === "object" ? layoutPadding[direction] : layoutPadding;
    }

    return {
        padding: {
            left: finalLayout.left,
            right: finalLayout.right,
            top: finalLayout.top,
            bottom: finalLayout.bottom
        }
    }
}

const chartFormatters = (format: any) => {
    if (!format)
        return formatters.default;

    if (typeof format === "function")
        return format;

    if (formatters.hasOwnProperty(format)) {
        return formatters[format];
    }
    return null;
}


const prepareNewAnnotations = (annotations: any, format: any) => {
    const config: any = {}

    annotations.forEach((annotation: any, index: number) => {
        let currentIndex = index + 1;
        const annotationId = `line${currentIndex++}`
        config[annotationId] = {
            type: 'line',
            yMin: annotation.value,
            yMax: annotation.value,
            borderColor: annotation.borderColor || 'rgb(255, 99, 132)',
            borderWidth: annotation.borderWidth || 2,
            label: {
                backgroundColor: annotation.color || 'rgb(255, 99, 132)',
                enabled: annotation.enabled,
                content: annotation.content === "labelOnly" ? annotation.label : `${annotation.label}: ${format(annotation.value)}`
            }
        }
    })
    return config;
}


interface IProps {
    type?: any,
    xLabelMaxChars?: any,
    labelConf?: any,
    borderColor?: any,
    borderWidth?: any,
    align?: any,
    offset?: any
    title?: any,
    customColors?: any,
    subTitle?: any,
    labels?: any,
    dataSets?: any,
    formatStatsType?: any,
    stacked?: any,
    format?: any,
    displayLegend?: any,
    legendPosition?: any,
    height?: any,
    fixedHeight?: any,
    beginAtZero?: any,
    displayLabels?: any,
    displayTooltips?: any,
    displayXTicks?: any,
    displayYTicks?: any,
    displayXLines?: any,
    displayYLines?: any,
    xLabelFontSize?: any,
    xAxisPosition?: any,
    xAxisLabel?: any,
    suggestedYMax?: any,
    annotations?: any,
    labelXAdjust?: any,
    contentType?: any,
    value?: any,
    color?: any,
    yAxisPosition?: any,
    yAxisLabel?: any,
    displayXBorder?: any,
    displayYBorder?: any,
    onPointClick?: any,
    tooltipExtraInformation?: any,
    layoutPadding?: any,
    labelCallback?: any,
    filterLegend?: any,
    extra?: any,
    menu?: any,
    extraStyle?: any,
    lineTension?: any,
    limits?: any,
    formatXAxis?: any,
    maxXTicksLimit?: any,
    customTip?: any,
    disableLegend?: any,
    allowSnapshot?: any,
    allowDistribution?: any,
    allowExcelDownload?: any,
    allowFullscreen?: any,
    draggable?: any,
    onDrag?: any,
    hideDots?: any,
    initialDistribution?: any,
    showEmptyPlaceholder?: any,
    customFormat?: any,
    menuOtherOptions?: any,
    types?: any,
    tooltipMultiple?: any,
    dataSetStack?: any,
    showAxisLabels?: any,
    tooltipIndexMode?: any,
    usesMenu?: any,
    onExcelDownload?: any,
    openFullscreen?: any,

    [x: string]: any
}

class Graph extends Component<IProps, any> {
    contentReference: any;
    chartRef: any;
    static defaultProps: any;
    static propTypes: any;

    constructor(props: any) {
        super(props);
        this.state = {
            selected: {
                chart: props.types[0],
                distribution: props.initialDistribution
            }
        };
        this.contentReference = React.createRef();
        this.chartRef = React.createRef();
    }

    onSnapshotDownload = () => {
        const node = this.contentReference.current;
        message.info("Download in progress. Please wait...")
        setTimeout(() => {
            html2canvas(node, {
                ignoreElements: (element) => {
                    return element.className === "ant-col extra-content-table";
                }
            }).then(function (canvas) {
                const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
                const a = document.createElement("a");
                a.setAttribute("download", `Screenshot.png`);
                a.setAttribute("href", image);
                a.click();
            });
        }, 500)
    }

    handleDistributionChange = (previous: any, current: any) => {
        const {onDistributionChange}: any = this.props;
        if (previous.distribution !== current.distribution)
            onDistributionChange(current.distribution);
    }

    setSelected = (func: any) => {
        const {onDistributionChange}: any = this.props;
        const handleDistributionChange = this.handleDistributionChange;
        return this.setState((prevState: any) => {
            const newState: any = Object.assign({}, prevState);
            newState.selected = func(prevState.selected);
            if (onDistributionChange)
                handleDistributionChange(prevState.selected, newState.selected)
            return newState;
        })
    }

    formatValue = (value: any) => {
        const {customFormat, format} = this.props;
        let formatter;

        if (customFormat)
            formatter = customFormat({state: this.state, props: this.props})
        else
            formatter = chartFormatters(format);

        return formatter(value);
    };

    scatterTooltip = (tooltipItem: any) => {
        const datasetLabel = tooltipItem.label;
        let {label, x: xLabel, y: yLabel} = tooltipItem.raw;

        const {xAxisLabel, yAxisLabel, tipFormatXAxis, formatXAxis}: any = this.props;
        let labels = [];

        labels.push(`${datasetLabel}: ${label}`);

        if (formatXAxis)
            xLabel = this.formatValue(xLabel);

        if (tipFormatXAxis)
            xLabel = chartFormatters(tipFormatXAxis)(xLabel);

        yLabel = this.formatValue(yLabel);

        if (xAxisLabel)
            labels.push(`${xAxisLabel}: ${xLabel}`);

        if (yAxisLabel)
            labels.push(`${yAxisLabel}: ${yLabel}`);

        return labels;
    };

    tooltipFormatter = (tooltipItem: any) => {
        const {format, customTip} = this.props;
        const {dataset, raw} = tooltipItem;
        const label = dataset.label;

        if (customTip)
            return customTip({tooltipItem})
        else
            return `${label}: ${format(raw)}`;

    };

    onPointClick = (event: any) => {
        const {onPointClick} = this.props;
        const element = getElementAtEvent(this.chartRef.current, event);

        if (onPointClick && element.length) {
            if (!element.length) return;

            const {index, datasetIndex} = element[0];

            this.props.onPointClick({
                dataSetIndex: datasetIndex,
                index: index,
            });
        }
    };


    getMenuConfig = () => {
        const {menuOtherOptions} = this.props;
        if (typeof menuOtherOptions === "object")
            return menuOtherOptions;
        return {}
    }

    getLimits = (axis: any) => {
        let min, max, limits = this.props.limits;
        if (limits.hasOwnProperty(axis)) {
            const limit = limits[axis];
            min = limit.min;
            max = limit.max;
        }
        return {min, max}
    }

    render() {
        const {
            types,
            dataSets,
            customColors,
            title,
            subTitle,
            labels,
            displayLegend,
            legendPosition,
            stacked,
            fixedHeight,
            height,
            format,
            labelConf,
            beginAtZero,
            displayTooltips,
            displayLabels,
            tooltipMultiple,
            displayXTicks,
            displayYTicks,
            displayXLines,
            displayYLines,
            xAxisLabel,
            xAxisPosition,
            xLabelFontSize,
            yAxisPosition,
            yAxisLabel,
            annotations,
            displayYBorder,
            displayXBorder,
            onPointClick,
            tooltipExtraInformation,
            layoutPadding,
            dataSetStack,
            labelCallback,
            filterLegend,
            extra,
            extraStyle,
            lineTension,
            limits,
            showEmptyPlaceholder,
            formatXAxis,
            maxXTicksLimit,
            showAxisLabels,
            suggestedYMax,
            customTip,
            initialDistribution,
            disableLegend,
            tooltipIndexMode,
            customFormat,
            menuOtherOptions,
            draggable,
            onDrag,
            hideDots,
            ...others
        } = this.props;

        const {
            usesMenu, allowDistribution, allowExcelDownload, onExcelDownload,
            allowSnapshot, allowFullscreen, openFullscreen
        } = this.props;

        const {selected} = this.state;

        const {chart: type} = selected;


        const xAxisLimit = this.getLimits("x");

        const yAxisLimit = this.getLimits("y")

        const headers = (
            <>
                {title}
                {subTitle && <p className="textCenter greyText">{subTitle}</p>}
            </>
        );


        let graphData: any = {labels};

        const toolTipDefaultConfig = {
            titleFontSize: 12,
            bodyFontSize: 14,
            displayColors: true,
        };

        const Chart = charts[type];
        if (multipleDataSets.includes(type)) {
            graphData.datasets = dataSets.map((dataSet: any) => ({
                ...defaultMultiSetData,
                lineTension: lineTension,
                label: dataSet.label,
                data: dataSet.data,
                type: dataSet.type,
                fill: dataSet.fill || false,
                stack: dataSet.stack,
                customLabel: dataSet.customLabel,
                backgroundColor: dataSet.backgroundColor,
                borderWidth: dataSet.borderWidth || 1.5,
                borderColor: dataSet.borderColor || dataSet.backgroundColor,
                pointBorderColor: dataSet.pointBorderColor || dataSet.backgroundColor,
                pointHoverBackgroundColor: dataSet.backgroundColor,
                pointHoverBorderColor: dataSet.backgroundColor,
                pointBackgroundColor: dataSet.pointBackgroundColor,
                pointRadius: dataSet.pointRadius,
                showLine: dataSet.showLine,
                borderDash: dataSet.borderDash,
                // Custom attributes
                hideLabel: dataSet.hideLabel,
                totals: dataSet.totals,
                hidden: dataSet.hidden,
            }));
        } else {
            graphData.datasets = [{
                ...dataSets[0],
                lineTension: lineTension,
                backgroundColor: customColors,
                hoverBackgroundColor: customColors,
                hoverBorderColor: customColors,
                pointHoverBackgroundColor: customColors,
                pointHoverBorderColor: customColors,
            }];
        }

        const legendOptions: any = {
            display: displayLegend,
            position: legendPosition,
            labels: {
                boxWidth: 10,
                filter: filterLegend
            }
        };

        if (disableLegend)
            legendOptions.onClick = null;

        const dragData: any = {}

        if (draggable) {
            dragData.onDragStart = function (e: any) {
            }
            dragData.onDrag = function (e: any, datasetIndex: any, index: any, value: any) {
                const label = labels[index];
                onDrag({index, value, label, datasetIndex,})
            }
            dragData.onDragEnd = function (e: any, datasetIndex: any, index: any, value: any) {
                const label = labels[index];
                onDrag({index, value, label, datasetIndex,})
            }

            dragData.hover = {
                onHover: function (e: any) {
                    // indicate that a datapoint is draggable by showing the 'grab' cursor when hovered
                    const point = this.getElementAtEvent(e)
                    if (point.length) e.target.style.cursor = 'grab'
                    else e.target.style.cursor = 'default'
                }
            }
        }

        const options = {
            maintainAspectRatio: fixedHeight,
            responsive: !fixedHeight,
            layout: getLayout(type, layoutPadding),
            plugins: {
                datalabels: {
                    display: displayLabels ? 'auto' : false,
                    color: ['line', 'scatter', 'bubble'].includes(type) ? theme.colors.transparent : labelConf.color || theme.colors.darkgrey,
                    formatter: (value: any, data: any) => {
                        const formattedValue = this.formatValue(value);
                        if (labelCallback)
                            return labelCallback(value, data);
                        if (type === 'doughnut')
                            return formattedValue;
                        if (singleDataSets.includes(type))
                            return `${labels[data.dataIndex]}\n${formattedValue}`;
                        return formattedValue;
                    },
                    font: {
                        size: labelConf.fontSize || 10,
                        style: 'normal',
                    },
                    borderColor: labelConf.borderColor,
                    borderWidth: labelConf.borderWidth,
                    clamp: false,
                    align: labelConf.align || 'end',
                    anchor: labelConf.anchor || 'end',
                    offset: labelConf.offset || 5,
                    padding: labelConf.padding || {
                        top: 4,
                        bottom: 4,
                        right: 4,
                        left: 4,
                    }
                },
                legend: legendOptions,
                tooltip: toolTipDefaultConfig,
                dragData: draggable ? dragData : undefined
            },
        } as any;

        if (hideDots) {
            options.elements = {
                point: {
                    radius: 0.3
                }
            }
        }

        if (!displayTooltips)
            options.plugins.tooltip = {
                ...toolTipDefaultConfig,
                enabled: false
            };

        else {
            // Custom tooltip for scatter
            if (['scatter', 'bubble'].includes(type)) {
                options.plugins.tooltip = {
                    ...toolTipDefaultConfig,
                    callbacks: {
                        label: this.scatterTooltip
                    },
                }
            } else {
                options.plugins.tooltip = {
                    ...toolTipDefaultConfig,
                    callbacks: {
                        label: this.tooltipFormatter,
                    },
                    mode: tooltipMultiple ? 'x-axis' : 'nearest'
                };
            }

            if (options.plugins.tooltip && tooltipExtraInformation)
                options.plugins.tooltip.callbacks.afterBody = tooltipExtraInformation;

        }

        if (['bar', 'line', 'scatter', 'bubble'].includes(type)) {
            options.scales = {
                y: {
                    position: yAxisPosition,
                    beginAtZero: beginAtZero,
                    min: yAxisLimit.min,
                    max: yAxisLimit.max,
                    suggestedMax: suggestedYMax,
                    grid: {
                        display: displayYLines,
                        drawBorder: displayYBorder
                    },
                    title: {
                        display: Boolean(showAxisLabels && yAxisLabel),
                        text: yAxisLabel
                    },
                    ticks: {
                        callback: (value: any) => {
                            return this.formatValue(value);
                        },
                        display: displayYTicks
                    }
                },
                x: {
                    position: xAxisPosition,
                    beginAtZero: beginAtZero,
                    min: xAxisLimit.min,
                    max: xAxisLimit.max,
                    ticks: {
                        fontSize: xLabelFontSize,
                        maxTicksLimit: maxXTicksLimit,
                        display: displayXTicks,
                    },
                    grid: {
                        display: displayXLines,
                        drawBorder: displayXBorder
                    },
                    title: {
                        display: Boolean(showAxisLabels && xAxisLabel),
                        text: xAxisLabel
                    },
                }
            };
            if (!dataSetStack) {
                options.scales.y.stacked = ['bar'].includes(type) ? stacked : false;
                options.scales.x.stacked = ['bar'].includes(type) ? stacked : false;
            }
        }


        if (annotations && annotations.length) {
            options.plugins.annotation = {
                annotations: prepareNewAnnotations(annotations, format)
            }
        }


        if (isEmpty(labels))
            return <GraphPlaceholder showEmptyPlaceholder={showEmptyPlaceholder} component={Chart}
                                     height={height} options={options} headers={headers}/>;

        let chartContent = (
            <ChartLayout
                type={type}
                data={graphData}
                options={options}
                component={Chart}
                reference={this.chartRef}
                onClick={onPointClick ? this.onPointClick : undefined}/>
        );

        if (type === 'table' && menuOtherOptions && menuOtherOptions.tableFormat) {
            const {content: Table, data} = menuOtherOptions.tableFormat
            chartContent = (
                <Table
                    data={data}
                />
            )
        }

        return (
            <Styles {...others.style} ref={this.contentReference}>
                <div className="header">
                    <div className="header-title">
                        {headers}
                    </div>
                    <div className="header-extra" style={extraStyle}>
                        <Row gutter={16}>
                            {usesMenu && <Col>
                                <ChartMenu
                                    chartMenu={types}
                                    selected={selected}
                                    onChange={this.setSelected}
                                    menuOtherOptions={menuOtherOptions}
                                    allowDistribution={allowDistribution}
                                    allowExcelDownload={allowExcelDownload}
                                    onExcelDownload={onExcelDownload}
                                    allowSnapshot={allowSnapshot}
                                    allowFullscreen={allowFullscreen}
                                    onFullscreen={openFullscreen}
                                    onSnapshotDownload={this.onSnapshotDownload}
                                    {...this.getMenuConfig()}
                                />
                            </Col>}
                        </Row>
                        {extra}
                    </div>
                </div>
                <ChartContent height={height}>
                    {chartContent}
                </ChartContent>
            </Styles>
        );
    }
}

Graph.defaultProps = defaultProperties;

export default withFullscreen(Graph);
