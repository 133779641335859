import React from 'react';
//Components
import { Dropdown, Menu } from "antd";
//Constants
import { distributionOptions } from "./constants";
//Icons
import {
  FileExcelOutlined,
  FileImageOutlined,
  FileTextOutlined,
  FullscreenOutlined,
  MenuOutlined
} from "@ant-design/icons";
//Custom Helpers
import { prepareKeys } from "./helpers";
//Components
const {SubMenu, Divider, Item} = Menu;

interface MenuItemProps {
  visible: any,
  icon: any,
  entity: any,
  onChange: any,
  children: any,
}

interface CustomMenuProps {
  fullscreen?: any,
  selected?: any,
  onChange?: any,
  charts?: any,
  allowDistribution?: any,
  allowGrouping?: any,
  allowFullscreen?: any,
  onFullscreen?: any,
  allowSnapshot?: any,
  onSnapshotDownload: () => any,
  allowExcelDownload?: any,
  onExcelDownload?: any,
  onCSVDownload?: any,
  allowCSVDownload?: any
}

const MenuItem = (props: MenuItemProps) => {
  const {visible, icon, entity, onChange, children, ...rest} = props;
  if (!visible)
    return null;

  return (
    <Item {...rest} icon={icon} key={entity} onClick={onChange(entity)}>
      {children}
    </Item>
  )

}

const CustomMenu = (props: CustomMenuProps) => {
  const {
    fullscreen, selected, onChange, charts, allowDistribution, allowGrouping, allowFullscreen, onFullscreen,
    allowSnapshot, onSnapshotDownload, allowExcelDownload, onExcelDownload, onCSVDownload, allowCSVDownload
  } = props;

  const [visible, setVisible] = React.useState(false);

  if (!selected)
    return null;

  const updateSelected = (key: any, value: any) => {
    onChange((previousSelected: any) => {
      const updatedSelection = Object.assign({}, previousSelected);
      if (key === "grouping")
        updatedSelection.grouping = !selected.grouping;
      else
        updatedSelection[key] = value;
      return updatedSelection;
    })
  }

  const onMenuChange = (entity: any) => (event: any) => {
    if (entity.startsWith("download"))
      setVisible(false);

    switch (entity) {
      case "downloadPNG":
        return onSnapshotDownload();
      case "downloadExcel":
        return onExcelDownload();
      case "downloadCSV":
        return onCSVDownload();
      case "fullScreen":
        return onFullscreen();
    }
    /*General Case*/
    updateSelected(entity, event.key);
  }

  const onSubMenuChange = (key: any) => (event: any) => {
    updateSelected(key, event.key);
  };

  const renderSubMenu = (key: any, records: any, title: any) => {
    return records &&
        <SubMenu
            title={title}
            key={key}
        >
          {records.map((record: any) => (
              <Item
                  key={record.key}
                  icon={record.icon}
                  onClick={onSubMenuChange(key)}>
                {record.tip}
              </Item>
          ))}
    </SubMenu>
  };

  const onVisibleChange = (flag: any) => {
    setVisible(flag);
  }

  const showDivider = allowSnapshot || allowCSVDownload || allowExcelDownload

  return (
    <Dropdown.Button
      trigger={['click']}
      size='small'
      visible={visible}
      icon={<MenuOutlined/>}
      placement="bottomLeft"
      onVisibleChange={onVisibleChange}
      overlay={
        <Menu
          onClick={onMenuChange}
          selectedKeys={prepareKeys(selected)}>

          {renderSubMenu('chart', charts, "View As")}

          {allowDistribution && renderSubMenu('distribution', distributionOptions, "Show by")}

          {allowGrouping &&
            <Item key="grouping" onClick={onMenuChange("grouping")}>
              {selected.grouping ? "Ungroup Items" : "Group Items"}
            </Item>}

          {showDivider && <Divider/>}

          <MenuItem key="downloadPNG" icon={<FileImageOutlined/>} entity="downloadPNG" visible={allowSnapshot} onChange={onMenuChange}>
            Download as PNG
          </MenuItem>

          <MenuItem key="downloadExcel" icon={<FileExcelOutlined/>} entity="downloadExcel" visible={allowExcelDownload}
                    onChange={onMenuChange}>
            Download as Excel
          </MenuItem>

          <MenuItem key="downloadCSV" icon={<FileTextOutlined/>} entity="downloadCSV" visible={allowCSVDownload} onChange={onMenuChange}>
            Download as CSV
          </MenuItem>

          {allowFullscreen && <Divider/>}
          <MenuItem key="fullScreen" icon={<FullscreenOutlined/>} entity="fullScreen" visible={allowFullscreen && !fullscreen}
                    onChange={onMenuChange}>
            Fullscreen View
          </MenuItem>
        </Menu>
      }
    />
  )
};

export default CustomMenu;

