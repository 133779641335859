//Components
import CheckboxDebtor from "./checkbox_debtor";
import CalculationWaiver from "./calculation_waiver";

export const columns :any = [
  {
    key:"checkbox_label",
    Component: CheckboxDebtor
  },
  {
    key:"company_name",
    label:"Company Name",
    align: "center",
    width:200
  },
  {
    key:"vat",
    label:"Vat",
    width: 200,
    align: "center"
  },
  {
    key:"calculation_waiver",
    label:"Calculation Waiver",
    width: 100,
    align: "center",
    Component: CalculationWaiver
  }
];
