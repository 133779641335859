import React from 'react';
import { Col, Input, Row } from "antd";

const {TextArea} = Input;

interface Props{
  input?:any,
  onInputChange?:any
  title?:string,
  description?:string
}
const Notes = ({input, onInputChange,title,description}: Props) => {

  const onChangeValue = (e: any) => {
    onInputChange(e.target.value)
  }

  return (
    <>
      <Row className="mt8 bolder">
        {title}
      </Row>
      <Row align="middle" className="mt8">
        <Col span={6} className={"size12"}>
          {description}
        </Col>
        <Col span={18}>
          <TextArea rows={2} allowClear value={input} onChange={onChangeValue}/>
        </Col>
      </Row>
    </>
  );
};

export default Notes;