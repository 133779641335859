//Constants
import { theme } from "../../../../assets/theme/colors";

/*Cash Reserve Colors*/
export const currencyColors: any = [
  "#C60AB0",
  "#DC67CE",
  ...theme.palette.us
];

export const collateralChartColors: any = {
  notes_list: [
    "#3F9886",
    "#55A494",
    "#75B5A8",
    "#9ABD97",
    "#D0F1BF",
    "#B2D6CF",
    "#D9EAE7",
    "#B6D7B9",
    "#646536",
    "#035D14",
  ],
  currencies_list: [
    "#55A494",
    "#75B5A8",
    "#B2D6CF",
    "#D9EAE7",
    ...theme.palette.us
  ]
}


/*notes_section Colors*/
export const notesChartColors: any = {
  notes_list: [
    "#4565F8",
    "#00B0F0"
  ],
  notes_list_junior: [
    "#4565F8",
    "#00B0F0"
  ],
  currencies_list: [
    "#768DFA",
    "#9AACFB",
    "#ABE5FA",
    "#5FCDF6",
  ]
};

export const trancheChildColors: any = {
  "Senior Note": {
    primary: '#4565F8',
    children: [
      '#768DFA',
      '#9AACFB',
    ]
  },
  "Mezzanine Note": {
    primary: '#00B0F0',
    children: [
      '#ABE5FA',
      '#5FCDF6'
    ]
  },
  "Junior Note": {
    primary: '#00B0F0',
    children: [
      '#ABE5FA',
      '#5FCDF6'
    ]
  },
  "Class A": {
    primary: '#4565F8',
    children: [
      '#768DFA',
      '#9AACFB',
    ]
  },
  "Loan": {
    primary: '#00B0F0',
    children: [
      '#ABE5FA',
      '#5FCDF6'
    ]
  }
}