import React from 'react';
//Components
import { Col, Row } from "antd";
import SmartChart from "../../../../../../components/charts/datasets/single/chart";
//Helpers
import { formatters } from "../../../../../../helpers";
import { theme } from "../../../../../../assets/theme/colors";
import { useAuthorization } from "../../../../../../utils/hooks";

const BACKGROUND_COLORS = [
  theme.colors.beige,
  theme.colors.lightcyan,
  ...theme.palette.us
];

interface ChartProps {

  data?: any,
  displayLegend?: any,
  height?: any,
  types?: any,
  scenarios?: any,
  format?: any,
  title?: any,
  baseAttr?: any,
  datasetAttr?: any,
}


const Chart = (props: ChartProps) => {
  const {datasetAttr, baseAttr, scenarios, data, ...rest} = props;

  const authorization = useAuthorization();
  const getTitle = (labels: any) => {
    return authorization.isDemoUser() ? labels.demo : labels.default;
  }

  const options: any = {
    labels: [],
    dataSets: [
      {
        data: [],
        label: getTitle({default: "Contractual", demo: "Business Plan"}),
        backgroundColor: theme.colors.grey,
        datasetKey: 'base_dataset'
      }
    ]
  }

  scenarios.forEach((scenario: any, index: number) => {
    let name = scenario.name;

    if (!name || name === "")
      name = `Scenario ${index + 1}`

    options.dataSets.push({
      data: [],
      label: name,
      datasetKey: name,
      backgroundColor: BACKGROUND_COLORS[index]
    })
  })

  data.forEach((item: any) => {
    const date = item.date;
    const records = item.records;

    options.labels.push(formatters.shortedDate(date));

    const initialRecord: any = Object.values(records)[0];

    options.dataSets.forEach((dataset: any) => {
      const key = dataset.datasetKey;
      const value: any = key === "base_dataset" ? initialRecord[baseAttr] : records[key][datasetAttr];
      dataset.data.push(value)
    })
  });

  return (
    <SmartChart
      {...rest}
      {...options}
      hideDots
      innerDatasets={false}
    />
  );
}


interface ChartsProp {
  data?: any,
  scenarios?: any,
}

const Charts = (props: ChartsProp) => {
  const {data, scenarios} = props;

  const authorization = useAuthorization();

  const getTitle = (labels: any) => {
    return authorization.isDemoUser() ? labels.demo : labels.default;
  }

  return (
    <Row>
      <Col xs={24} lg={12} className="mt16">
        <Chart
          displayLegend
          data={data}
          height={500}
          types={['line']}
          scenarios={scenarios}
          format={formatters.thousands}
          title={getTitle({demo: "Recovery Simulation", default: "Principal Simulation"})}
          baseAttr="contractual_capital_installment"
          datasetAttr="cumulated_capital_installment"/>
      </Col>
      <Col xs={24} lg={12} className="mt16">
        <Chart
          data={data}
          height={500}
          displayLegend
          scenarios={scenarios}
          types={['line']}
          title={getTitle({demo: "Expenses Simulation", default: "Interest Simulation"})}
          format={formatters.thousands}
          baseAttr="contractual_interest_installment"
          datasetAttr="cumulated_interest_installment"/>
      </Col>
    </Row>
  );
};

export default Charts;
