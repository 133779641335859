import React from 'react';
//Components
import { FlatList } from "@cardoai/components";
//Helpers
import { statisticsFields } from "../../config";
import { theme } from "../../../../assets/theme/colors";
import { generateUrl, useQuery } from "../../../../helpers";
import { Skeleton } from "antd";

interface Props {
  vehicleId?: any
}

const Statistics = ({vehicleId}: Props) => {
  const {data} = useQuery({
    url: generateUrl('/main/vehicle_stats/investment_management_statistics', {
      vehicle_id: vehicleId
    })
  });

  if (!data)
    return <Skeleton/>

  return (
    <FlatList
      data={data}
      size='medium'
      scrollable={false}
      config={statisticsFields}
      background={theme.colors.lightblue}/>
  );
};

export default Statistics;