import React from "react";
//Custom Components
import Layout from "./layout";
import LegendItems from "./legend_items";
//Custom Helpers
import { isEmpty } from "../../../../../../helpers";

interface LegendProps {
  records?: any,
  height?: any,
  formatter?: any,
  onClick?: any,
  showHover?: any
}

const Legend = (props: LegendProps) => {
  const {records, height, formatter, onClick, showHover} = props;
  const {top, others} = records;
  return (
    <Layout
      height={height}
      showHover={showHover}>
      <LegendItems
        records={top}
        others={others}
        onClick={onClick}
        formatter={formatter}
        showHover={showHover}
        extraSpace={false}
        height={height}
        showOthers={!isEmpty(others)}/>
    </Layout>
  )
};

export default Legend;
