import React from 'react';
//Utils
import {useToggle} from "@cardoai/utils";
//Custom Icons
import DownIcon from "../../icons/down";
//Components
import {Row, Col, Checkbox, Button} from "antd";
//Custom Components
import InputOperator from "./components/input";
import SelectOperator from "./components/select";
import BooleanOperator from "./components/boolean";

const types = {
    'input': 'input',
    'select': 'select',
    'boolean': 'boolean',
};

const components: any = {
    [types.input]: InputOperator,
    [types.select]: SelectOperator,
    [types.boolean]: BooleanOperator
};


const Filter = ({label, type, selected, onSelect, defaultSelected, ...rest}: any): any => {
    const [open, toggleOpen] = useToggle(false);

    const containerStyles: any = {
        cursor: 'pointer',
        padding: "4px 8px"
    };

    const iconStyles: any = {
        transition: 'transform .25s'
    };

    if (open) {
        iconStyles.transform = 'rotate(-90deg)'
        containerStyles.borderRadius = 8;
        containerStyles.backgroundColor = '#F5F8FE'
    }

    if (!components.hasOwnProperty(type)) {
        console.error('Invalid Component Type');
        return null;
    }

    const Component = components[type];

    return (
        <>
            <Row
                align="middle"
                onClick={() => {


                    if (!selected && onSelect) {
                        if (!defaultSelected) {
                            onSelect();
                        }
                    }
                    toggleOpen();
                }}
                justify="space-between"
                style={containerStyles}>
                <Col>
                    <Row gutter={16}>
                        <Col style={{minWidth: 50}} onClick={(e: any) => {
                            e.stopPropagation()
                        }}>
                            <Checkbox onClick={() => {
                                if (!selected && !open)
                                    toggleOpen();

                                if (!defaultSelected)
                                    onSelect();
                            }} checked={defaultSelected || selected}/>
                        </Col>
                        <Col>
                            {label}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Button type="link" ghost icon={<DownIcon style={iconStyles}/>}/>
                </Col>
            </Row>
            {open && (
                <div style={{marginTop: 8}}>
                    <Component {...rest}/>
                </div>
            )}
        </>
    )
}

const Filters = ({config, onChange}: any) => {

    const [state, setState] = React.useState<any>(() => {
        const initialState: any = {}
        config.forEach((record: any) => initialState[record.key] = {
            value: record.defaultValue,
            selected: record.defaultSelected
        })
        return initialState;
    });

    const handleApply = () => {

        const changes: any = {};

        const hasChanges = (object: any) => Object.keys(object).length >= 1;

        for (const key in state) {
            const record = config.find((r: any) => r.key === key);
            const value = state[key].value;
            if (String(record.defaultValue) !== String(value)) {
                if (record.onChange)
                    record.onChange(value);
                else
                    changes[record.key] = value;
            }
        }

        if (!hasChanges(changes))
            return;

        onChange(changes);
    };

    const handleSelect = (key: any) => () => {
        setState((previous: any) => {
            const selected = !previous[key].selected;
            return {
                ...previous,
                [key]: {
                    ...previous[key],
                    selected: selected,
                    value: selected ? previous[key].value : null
                }
            }
        })
    }

    const handleChange = (key: any) => (value: any) => {
        setState((previous: any) => {
            return {
                ...previous,
                [key]: {
                    ...previous[key],
                    value: value
                }
            }
        })
    };

    return (
        <Row style={{width: 400}} gutter={[8, 8]}>
            {config.map((record: any) => {
                const current = state[record.key];
                return (
                    <Col xs={24}>
                        <Filter
                            {...record}
                            key={record.key}
                            value={current?.value}
                            selected={current?.selected}
                            onChange={handleChange(record.key)}
                            onSelect={handleSelect(record.key)}/>
                    </Col>
                )
            })}
            <Col xs={24}>
                <Row justify="end">
                    <Col>
                        <Button type="primary" onClick={handleApply}>
                            Apply
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

Filter.types = types;
Filter.components = components;
export default Filters;
