//Other Libs
import styled from "styled-components";

export default styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 80px;

  .clx-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    border: 1px solid #d9d9d9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;


    .night-progress-percentage {
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      font-size: 12px;
      color: #f5f5f5;
      padding: 8px;
      display: flex;
      align-items: flex-end;
    }

    .night-progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      flex-direction: row;
      background-image: linear-gradient(to right, #108ee9, #87d068);
    }

    h2, h3 {
      position: relative;
      z-index: 2;
      font-size: 16px;
    }
  }

  > div:nth-child(4) {
    border: none;

    h2 {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4472c4;
      border: 2px solid #31538f;
      border-radius: 50%;
      color: white;
    }

  }`
