//Constants
import { theme } from "../../../../../../assets/theme/colors";
//Helpers
import { getRandomInt, MathOp } from "../../../../../../helpers";

export //Constants
function prepareData(data: any) {
  /**
   * This is a helper function that modifies the current BE structure of data to a structure that is easier to build
   * the vertical bar charts. The main purpose is to get some information from the "data.summary", add the current
   * balance for all the notes, so we can later derive the value of each note by dividing the current_balance of each
   * with the total sum
   * @params: data
   */

  let sum = 0;

  data.details.forEach((item: any) => sum += item.current_balance);

  return {
    details: data.details.map((record: any) => {
      const summaryRecord = data.summary.find((item: any) => item.tranche === record.name);
      return {
        ...record,
        attachmentPoint: summaryRecord.value,
        value: sum ? MathOp.divide(record.current_balance, sum) : undefined,
        repayment: summaryRecord.repayment_graph,
        trancheCurrency: summaryRecord.currency,
        trancheClass: summaryRecord.tranche_class
      }
    }),
    collateral: data.loan_structure.loan_status
  }
}

const notesColor: any = [
  "#1890ff",
  "#40a9ff",
  "#105EA8",
  "#2f54eb",
  "#096dd9",
  "#91d5ff",
  "#0050b3",
];

export const getRandomNotesColor = () => {
  const randomIndex = getRandomInt(0, notesColor.length)
  return notesColor[randomIndex]
}

const chartOptions: any = {
  borderRadius: 6,
  labelSize: 12,
  labelColor: theme.colors.white,
  dataSets: {
    notes: {
      "Senior": {
        color: theme.palette.structureChartColors[0]
      },
      "Super Senior 1": {
        color: "#4990FF"
      },
      "Super Senior 2": {
        color: "#3F51B5"
      },
      "Mezzanine": {
        color: theme.palette.structureChartColors[1]
      },
      "Junior": {
        color: theme.palette.structureChartColors[2]
      },
      "Equity": {
        color: theme.palette.structureChartColors[3]
      },
      "Loan": {
        hideTypeLabel: true,
        color: theme.palette.structureChartColors[4]
      }
    },
    collateral: {
      "performing": {
        color: theme.palette.greenColors[0],
        tip: "Performing"
      },
      "1 days - 29 days": {
        color: theme.palette.greenColors[1],
        tip: "DPD: 1 - 29"
      },
      "30 days- 59 days": {
        color: theme.palette.greenColors[2],
        tip: "DPD: 30 - 59"
      },
      "60 days - 89 days": {
        color: theme.palette.greenColors[3],
        tip: "DPD: 60 - 89"
      },
      "90 days - 119 days": {
        color: theme.palette.greenColors[4],
        tip: "DPD: 90 - 119"
      },
      ">= 120 days": {
        color: theme.palette.greenColors[5],
        tip: "DPD: >= 120"
      },
      'default': {
        color: '#529e8f',
        tip: 'Default'
      },
      'dissesto': {
        color: '#387a6d',
        tip: 'Dissesto'
      },
      'grace_period': {
        color: '#27b299',
        tip: 'Grace Period'
      }
    }
  }
}

export default chartOptions;
