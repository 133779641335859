import React from "react";
//Components
import { Tooltip } from "antd";
//Configurations
import chartOptions, { getRandomNotesColor } from "../config";
import { prepareCellContentStyle } from "./config";

interface Props {
  name?: string,
  type?: any,
  borderLess?: any,
  height?: any,
  title?: string,
  width?: any
}

const NotesCell = (props: Props) => {
  const {name, type, borderLess, width, height} = props;

  const notesDefaultDatasets: any = chartOptions.dataSets.notes;
  const notesDataset: any = notesDefaultDatasets.hasOwnProperty(type) ? notesDefaultDatasets[type] : null;

  const color: any = notesDataset ? notesDataset.color : getRandomNotesColor();
  const hideTypeLabel: boolean = notesDataset ? notesDataset.hideTypeLabel : false;

  let finalClassName = "fade-in ";

  if (borderLess)
    finalClassName += "noBorder";

  const content = (
    <div style={prepareCellContentStyle(true, color)}>
      {name} <br/>{!hideTypeLabel && `(${type})`}
    </div>
  );

  return (
    <td style={{width, height}} className={finalClassName}>
      <Tooltip placement='right' title={name}>{content}</Tooltip>
    </td>
  )
};


NotesCell.defaultProps = {
  borderLess: false,
  borderColor: null
};


export default NotesCell;