import React from "react";
//Custom Components
import { InfoTag } from "@cardoai/components";
//Helpers
import { formatters, Text } from "../../../../helpers";

export const getYear = (date: any) => {
  return date ? date.split('-')[0] : "Cumulative"
}

export const sortedHeaders = [
  {
    key: 'original_principal',
    label: 'Original Principal (€)',
    format: formatters.thousands,
    order: 0,
  },
  {
    key: 'nr_loans',
    label: 'Nr. Loans (#)',
    format: formatters.thousandsShorted,
    order: 1,
  },
  {
    key: 'average_loan_size',
    label: 'Average Loan Size',
    format: formatters.thousands,
    order: 2,
  },
  {
    key: 'average_term',
    label: 'Average Term at Inception (months)',
    format: formatters.thousandsShorted,
    order: 3
  },
  {
    key: 'average_term_adjusted',
    label: (
      <InfoTag
        children={Text("Average Term Adjusted")}
        title="Average Term Adjusted with COVID Loan Freeze and Prepayment (months)"/>
    ),
    format: formatters.thousandsShorted,
    order: 4
  },
  {
    key: 'principal_outstanding',
    label: 'Principal Outstanding (€)',
    format: formatters.thousands,
    order: 5

  },
  {
    key: 'principal_outstanding_rate',
    label: 'Principal Outstanding (% of original)',
    format: formatters.percent,
    order: 6

  },
  {
    key: 'arrears_balance',
    label: 'Arrears (€)',
    format: formatters.thousands,
    order: 7

  },
  {
    key: 'arrears_rate',
    label: 'Arrears Rate',
    format: formatters.percent,
    order: 8
  },
  {
    key: 'cumulative_default',
    label: 'Cumulative Default (€)',
    format: formatters.thousands,
    order: 9
  },
  {
    key: 'cummulative_default_rate',
    label: 'Cumulative Default Rate',
    format: formatters.percent,
    order: 10

  },
  {
    key: 'expected_recovery',
    label: 'Expected Recovery (€)',
    format: formatters.thousands,
    order: 11
  },
  {
    key: 'expected_recovery_rate',
    label: 'Expected Recovery Rate',
    format: formatters.percent,
    order: 12
  },
  {
    key: 'borrower_apr',
    label: 'Borrower APR',
    format: formatters.percent,
    order: 13
  },
  {
    key: 'investor_apr',
    label: 'Investor APR',
    format: formatters.percent,
    order: 14
  },
  {
    key: 'irr_upside',
    label: <InfoTag children={Text('IRR - Upside Case')}
                    title="IRR: Net Returns before expenses
                      Upside Case: Future cash flows calculated assuming 70% of expected losses based on Opyn's internal rating (monthly PD*EAD*LGD)"/>,
    format: formatters.percent,
    order: 15
  },
  {
    key: 'irr_base',
    label: <InfoTag children={Text('IRR - Base Case')}
                    title="IRR: Net Returns before expenses
                      Base Case: Future cash flows calculated assuming 100% of expected losses based on Opyn's internal rating"/>,
    format: formatters.percent,
    order: 16
  },
  {
    key: 'irr_downside',
    label: <InfoTag children={Text('IRR - Downside Case')}
                    title="IRR: Net Returns before expenses
                      Downside Case: Future cash flows calculated assuming 130% of expected losses based on Opyn's internal rating"/>,
    format: formatters.percent,
    order: 17
  },
  {
    key: 'average_guarantee_lending',
    label: 'Average Guarantee (weighted on lending amount)',
    format: formatters.percent,
    order: 18
  },
  {
    key: 'average_guarantee_outstanding',
    label: 'Average Guarantee (weighted on outstanding amount)',
    format: formatters.percent,
    order: 19

  },
  {
    key: 'average_annual_interest_rate',
    label: 'Average Annual Interest Rate',
    format: formatters.percent,
    order: 20
  },
  {
    key: 'average_borrower_upfront_fee',
    label: 'Average Borrower Upfront Fee',
    format: formatters.percent,
    order: 21
  },
]