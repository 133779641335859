import React from 'react';
//Custom Components
import Form from "../form";
import Upload from "../../../../../../components/upload_files/upload";

const UploadFilesFields = (props: any) => {
  const {form, fields, onFileChange} = props;

  const onFileClear = (field: any) => () => {
    onFileChange(field)(null)
  };

  const prepareFileType = (type: any) => {
    return `.${type}`;
  };

  return (
    <>
      {fields.map(({key, label, required, type}: any) => {
        return (
          <Form key={key} label={label} required={required}>
            <Upload
              label="Choose File"
              file={form[key]}
              required={required}
              onClear={onFileClear(key)}
              onChange={onFileChange(key)}
              accept={prepareFileType(type)}/>
          </Form>
        )
      })}
    </>
  );
};

export default UploadFilesFields;