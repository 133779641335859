import React from 'react';
//Components
import {Row, Col} from 'antd';
//Custom Components
import Steps from "./steps";
import {statusName} from "../../../config";
import PendingHeader from "./headers/pending";
import RejectedHeader from "./headers/rejected";
import { ProposalProvider } from '../../../context';

const Started = ({vehicleId, proposalId, status, onClose,proposalDate}: any) => {
    let Header: any;

    if (status === statusName["Awaiting Approval"])
        Header = PendingHeader;
    else if (status === statusName["Rejected"])
        Header = RejectedHeader;

    return (
        <ProposalProvider onClose={onClose} vehicleId={vehicleId} proposalId={proposalId} >
            <Row gutter={[16, 16]}>
                {Header && (
                    <Col xs={24}>
                        <Header/>
                    </Col>
                )}
                <Col xs={24}>
                    <Steps proposalDate={proposalDate} />
                </Col>
            </Row>
        </ProposalProvider>
    );
};

export default Started;
