import React from "react";
//Components
import SelectTitle from "./select_title";
import { Graph } from "../../../../../components";
//Helpers
import { datasetSelection } from "../config";
import { theme } from "../../../../../assets/theme/colors";
import { formatters, humanString } from "../../../../../helpers";


interface ILossChart {
  data?: any,
  queryStr?: any,
  showLine?: boolean
  height?: any,
}

const getChartFormatter = (selection: any) => {
  const currentDataset = datasetSelection.find((dataset: any) => dataset.identifier === selection);
  return currentDataset ? currentDataset.format : formatters.percent;
}

const LossChartContent = (props: ILossChart) => {

  const {data, height, showLine} = props;
  const [selection, setSelection] = React.useState<any>("gross_default_rate");

  const configurations: any = {
    labels: [],
    annotations: [],
    dataSets: datasetSelection.map(dataset => {
      return {
        ...dataset,
        data: []
      }
    }),
  };

  for (let record of data) {
    configurations.labels.push(formatters.date(record.reference_date));
    for (let key in record) {
      if (record.hasOwnProperty(key)) {
        configurations.dataSets.forEach((dataset: any) => {
          if (dataset.identifier === key) {
            dataset.data.push(record[key]);
          }
        })
      }
    }
    if (showLine) {
      datasetSelection.forEach(dataset => {
        const annotations = dataset.annotations || [];
        if (selection === dataset.identifier) {
          annotations.forEach(({key, label, color}) => {
            const value = record[key];
            if (value !== null && value !== undefined) {
              configurations.annotations.push({
                label: label,
                value: value,
                color: color,
                enabled: true,
                borderColor: color,
                type: 'line',
                contentType: 'labelOnly',
              })
            }
          })
        }
      })
    }
  }
  configurations.dataSets = configurations.dataSets.filter((dataset: any) => dataset.identifier === selection);

  const handleCustomTooltip = (tooltip: any) => {
    const stringLabel = humanString(selection);
    const numberLabel = tooltip.tooltipItem.raw;
    const tip = [];
    tip.push(`${stringLabel}: ${numberLabel}`)
    return tip;
  }

  return (
    <Graph
      usesMenu
      dataSetStack
      displayYLines
      allowSnapshot
      height={height}
      tooltipIndexMode
      lineTension={0.5}
      displayXLines={false}
      displayLegend={false}
      customTip={handleCustomTooltip}
      extraStyle={{top: -30, right: 0}}
      customColors={theme.palette.us}
      legendPosition='bottom'
      format={getChartFormatter(selection)}
      types={['line', 'bar']}
      title={<SelectTitle selection={selection} setSelection={setSelection}/>}
      {...configurations}
    />
  );
};

LossChartContent.defaultProps = {
  height: 400,
  showLine: true
}
export default LossChartContent;


