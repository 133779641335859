import { createAuthStorage } from "@cardoai/helpers";
import {
  assignLoaderInterceptor,
  assignStartInterceptor,
  assignSuccessInterceptor,
  createCallApi,
  createClient,
  createController,
  createDownload,
  createUseQuery,
} from "@cardoai/utils";
//Services
import Nprogress from "nprogress";
import { idpBaseUrl, serverBaseUrl } from "../settings";
import notifications from "../components/notifications";
import { accessTokenKey, refreshTokenKey } from "../constants";

export const client = createClient({
  baseURL: serverBaseUrl,
});

export const controller = createController({
  client: client
});

export const authStorage = createAuthStorage({
  accessKey: accessTokenKey,
  refreshKey: refreshTokenKey
})

export const callApi = createCallApi({
  client: client,
  controller: controller,
  notification: notifications,
})


export const callIdpApi = createCallApi({
  client: client,
  baseURL: idpBaseUrl,
  controller: controller,
  notification: notifications,
})

export const useQuery = createUseQuery({
  client: client,
  controller: controller,
  notification: notifications
})

export const downloadClientFile = createDownload({
  client: client,
  baseURL: serverBaseUrl,
})

/*Interceptors*/

assignStartInterceptor({
  client: client,
  getToken: authStorage.getAccessToken
})

assignSuccessInterceptor({
  client: client
})

assignLoaderInterceptor({
  client: client,
  end: Nprogress?.done,
  start: Nprogress?.start
})
