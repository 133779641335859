import React from 'react';
//Components
import { Modal } from "antd";
//Custom Styles
import "./styles.css";

const reference: any = document.body;

const withFullscreen = (Content: any) => (props: any) => {
  const [fullscreen, setFullscreen] = React.useState(false);
  const [fullscreenHeight, setFullscreenHeight] = React.useState<any>(null);

  const openFullscreen = () => {
    const title = (props.title) ? props.title : "structure section";

    if (reference.requestFullscreen) {
      reference.requestFullscreen();
    } else if (reference.mozRequestFullScreen) {
      reference.mozRequestFullScreen();
    } else if (reference.webkitRequestFullscreen) {
      reference.webkitRequestFullscreen();
    } else if (reference.msRequestFullscreen) {
      reference.msRequestFullscreen();
    }
    setFullscreen(true);
  }

  const cancelFullscreen = () => {
    const document: any = window.document;
    const cancellFullScreen = document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen;
    cancellFullScreen.call(document);
  }

  const onFullscreenChange = (event: any) => {
    if (document.fullscreenElement)
      setFullscreenHeight(document.body.clientHeight)
    else
      setFullscreen(false);
  }

  React.useEffect(() => {
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
  }, []);

  if (!fullscreen)
    return (
      <Content
        {...props}
        openFullscreen={openFullscreen}/>
    )

  if (!fullscreenHeight)
    return null;

  return (
    <Modal
      visible={fullscreen}
      className="modal-fullscreen"
      title={null}
      footer={null}
      style={{
        position: 'fixed',
        top: 0,
      }}
      onCancel={cancelFullscreen}>
      <Content
        {...props}
        fullscreen={fullscreen}
        fullscreenHeight={fullscreenHeight}
        cancelFullscreen={openFullscreen}/>
    </Modal>
  );
};

export default withFullscreen;