import React, { useState } from 'react';
//Other Libs
import { ResponsiveChoropleth } from '@nivo/geo';
//Custom Components
import { CountryToolTip, RegionSelect } from "./components/country_view";
//Constants
import countries from './json/countries_config.json'
//Configurations of maps
import { countryMap, legendConfig, worldRegions } from "./config";
//Constants
import { theme } from "../../../assets/theme/colors";

interface Props {
  data?: any,
  continent?: any,
  view?: any,
  onClick: (param?: any) => any,
  projection?: any,
  currency?: any,
}

const styles: any = {
  container: {
    height: 500,
    minHeight: 500,
    overflow: "hidden",
    position: "relative"

  },
  menu: {
    top: '0px',
    right: '0px',
    zIndex: 100,
    position: 'absolute',
  }
}

const getMax = (records: any) => {
  return Math.max(...records.map((record: any) => record.ratio));
}

const prepareWorldData = (data: any, currency?: any) => {
  const mapConfig: any = {
    max: getMax(data),
    values: []
  };
  data.forEach((record: any) => {
    const value = record.ratio;
    let countryName: any = record.name;

    if (countryMap.hasOwnProperty(countryName))
      countryName = countryMap[countryName];

    const validCountryCode = countries.features.find(country => {
      return country.properties.name === countryName
    });

    mapConfig.values.push({
      id: validCountryCode && validCountryCode.id,
      value: value,
      record: {...record, currency}
    })
  })

  return mapConfig;
}

const WorldMap = (props: Props) => {
  const {data, continent, view, onClick, currency} = props;
  const [region, setRegion] = useState<any>(continent);

  const mapProjection = worldRegions[view].find((record: any) => record.key === region).config;
  const finalData = prepareWorldData(data, currency);

  const handleClick = (feature: any) => {
    let record;

    if (feature.data && feature.data.hasOwnProperty("record"))
      record = feature.data.record;

    if (!record) return null;
    onClick(record)
  }

  return (
    <div style={styles.container}>
      <div style={styles.menu}>
        <RegionSelect
          view={view}
          value={region}
          onChange={setRegion}/>
      </div>
      <ResponsiveChoropleth
        data={finalData.values}
        features={countries.features}
        margin={{top: 0, right: 0, bottom: 0, left: 0}}
        domain={[0, finalData.max]}
        unknownColor={theme.palette.grayscale[10]}
        label="properties.name"
        colors='nivo'
        valueFormat='.0%'
        projectionScale={mapProjection.projectionScale}
        projectionTranslation={mapProjection.projectionTranslation}
        projectionRotation={[0, 0, 0]}
        graticuleLineWidth={0}
        graticuleLineColor={theme.colors.greyshade1}
        borderColor="#6c7889"
        borderWidth={0.5}
        isInteractive={true}
        tooltip={CountryToolTip}
        legends={legendConfig}
        onClick={handleClick}
      />
    </div>
  );
};

WorldMap.defaultProps = {
  projection: 'world',
};
export default WorldMap;
