import React from 'react';
//Components
import { Col, Row, Skeleton } from "antd";
import { Ratings } from "../../../../common";
import StructureChart from "../structure_chart";
import { SimpleEmpty } from "@cardoai/components";
import { BodyCell, HeaderCell, Record, RotateCell } from './components'
import Legend from '../../../../../components/charts/static_legend';
//Helpers
import { formatters, getVehicleCurrencyById, isEmpty, Text, useQuery, validateValue } from "../../../../../helpers";
//Custom Styles
import Styles from "./styles";
//Utils
import { config, prepareData, setStripes } from "./configurations";
import { TrancheCoupon } from "../../../../spv/components/management/notes_section/structure/components";

interface IPerformance {
  vehicles?: any
  vehicleId?: any
}

const initialLegendValues = () => {
  let values: any = {};
  config.entities.forEach((entity: any) => {
    values[entity.key] = true
  })
  return values;
};

const Performance = (props: IPerformance) => {
  const {vehicles, vehicleId} = props;

  const ref = React.useRef();
  const [selected, setSelected] = React.useState(initialLegendValues);

  const {data} = useQuery({
    url: `/main/notes/structure/?vehicle_id=${vehicleId}`,
    deps: [vehicleId]
  })

  if (!data)
    return <Skeleton/>

  if (!prepareData(data))
    return <Skeleton active/>

  const formattedData = prepareData(data);
  const currency = getVehicleCurrencyById(vehicles, vehicleId);
  /**
   * The labels are the dates that are shown on the header of the notes table.
   * Through iteration in the BE data, the dates are added as keys in the object "labels" so that repetitive dates are
   * only shown once.
   * After populating the "labels" object with the dates, they are sorted from the earliest to the oldest date and
   * "labels" object has been transformed instead to an array of dates.
   */

  let labels: any = {};
  formattedData.forEach((record: any) => record.repayment.forEach((item: any) => labels[item.date] = null));
  labels = Object.keys(labels).sort((a: any, b: any) => new Date(a).valueOf() - new Date(b).valueOf())


  const handleSelect = (attr: string | number) => {
    setSelected((previousSelected: { [x: string]: any; }) => {
      return {
        ...previousSelected,
        [attr]: !previousSelected[attr]
      }
    })
  }

  let tbodyContent;

  if (isEmpty(formattedData)) {
    tbodyContent = (
      <tr>
        <td colSpan={100}>
          <SimpleEmpty description="No Data."/>
        </td>
      </tr>
    )
  } else {
    tbodyContent = formattedData.map((record: any, index: number): any => {

      const cells = labels.map((label: any, index: any) => {
        let type: any;

        /*Match the payment amount and type to the date it happened, so it shows under the correct cell*/
        const payment = record.repayment.find((payment: { date: any; }) => payment.date === label);

        const updatedPayment = {...payment};

        let showNoPayment = true;

        /**
         * Match the payment type from the data to the dataset to display
         * If all the values in the data are null, it means there is no payment on that date
         */
        for (let dataset of config.entities) {
          const key = dataset.key;
          const value = updatedPayment[key];
          if (value !== null && value !== undefined) {
            showNoPayment = false;
            break;
          }
        }

        /*Create the "No Payment" dataset with 0*/
        if (showNoPayment)
          updatedPayment.no_payment = 0;

        /*Set the dataset type for other payment types; break the loop if the match is found*/
        for (let record of config.entities) {
          if (updatedPayment && updatedPayment[record.key] !== null) {
            type = record.key;
            break;
          }
        }

        const visible = type && selected[type];
        let value = null, color;

        if (updatedPayment)
          value = type ? updatedPayment[type] : null;

        const showRecord = updatedPayment && type && updatedPayment.hasOwnProperty(type) && visible && value !== null;

        if (showRecord)
          color = config.entities.find((record: any) => record.key === type).color;


        return (
          <td key={index} className="borderBottom borderRight">
            <Row justify='center'>
              <Col>
                {!showRecord && <div style={{height: 18}}/>}
                {showRecord && <Record
                  color={color}
                  type='box'
                  title={"Factor " + formatters.percent(payment.factor, 0)}/>}
              </Col>
            </Row>
          </td>
        )
      })

      const currentCoupon = record.current_coupon;
      const hasSingleCoupon = typeof currentCoupon === "number";
      
      return (
        <tr key={String(index)} style={{backgroundColor: setStripes(index)}}>
          <BodyCell index={0}>
            {record.name}
          </BodyCell>
          <BodyCell index={1}>
            {formatters.currency({value: record.current_balance, currency})}
          </BodyCell>
          <BodyCell index={2}>
            {validateValue(record.factor, formatters.percentNoPrecision)}
          </BodyCell>
          <BodyCell index={3}>
            {validateValue(record.coc, formatters.percentNoPrecision)}
          </BodyCell>
          <BodyCell index={4}>
            {hasSingleCoupon ? <>{validateValue(currentCoupon, formatters.percentNoPrecision)}</>
              :
              <TrancheCoupon coupons={currentCoupon} currency={currency}/>
            }
          </BodyCell>
          <BodyCell index={5}>
            <Ratings data={record.ratings}/>
          </BodyCell>
          <BodyCell index={6}>
            {validateValue(record.tranche_return, formatters.percent)}
          </BodyCell>
          <BodyCell index={7}>
            {validateValue(record.type)}
          </BodyCell>
          {cells}
        </tr>
      )
    })
  }

  return (
    <Row align='middle'>
      <Col span={16}>
        <Styles>
          <table>
            <thead>
            <tr>
              <HeaderCell index={0}>Name</HeaderCell>
              <HeaderCell index={1}>{Text("Notes Current \n Balance")}</HeaderCell>
              <HeaderCell index={2}>Factor</HeaderCell>
              <HeaderCell index={3}>OC</HeaderCell>
              <HeaderCell index={4}>Coupon</HeaderCell>
              <HeaderCell index={5}>Rating</HeaderCell>
              <HeaderCell index={7}>Return</HeaderCell>
              <HeaderCell index={6}>Tranche </HeaderCell>
              {labels.map((label: any, index: any) =>
                <RotateCell key={index}>{formatters.shortedDate(label)}</RotateCell>)}
            </tr>
            </thead>
            <tbody>
            {tbodyContent}
            </tbody>
          </table>
        </Styles>
        <Legend
          customClick
          className="mt16"
          selected={selected}
          config={config.entities}
          onSelect={handleSelect}/>
      </Col>
      <Col ref={ref.current} span={7}>
        <Row justify='center'>
          <Col>
            <StructureChart parent={ref} data={data}/>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

export default Performance;