import React from 'react';
//Components
import { Card, Col, Modal, Row, Tag } from "antd";
//Custom Components
import CollateralContent from "../collateral_content";
//Icons
import { HomeOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";

const {Meta} = Card;

const CollateralDescription = ({collateral}: any) => {
  return (
    <>
      <Row align="middle" justify="space-between">
        <Col>
          <HomeOutlined/> {collateral.type}
        </Col>
        <Col>
          €{collateral.amount}
        </Col>
        <Col>
          {collateral.returns}
        </Col>
      </Row>
      <Row>
        <Col>
          <FontAwesomeIcon icon={faMapMarkedAlt}/> {collateral.location}
        </Col>
      </Row>
    </>
  )
}

const CollateralCard = ({collateral}: any) => {
  const [visibleModal, setVisibleModal] = React.useState(false);

  const toggleModal = () => {
    setVisibleModal(prevState => !prevState);
  }

  const cardTitle = <Row align="middle" justify="space-between">
    <Col>{collateral.title}</Col>
    <Col><Tag color="blue">ID: {collateral.id}</Tag></Col>
  </Row>

  return (
    <>
      <Card
        hoverable
        onClick={toggleModal}
        style={{width: 300}}
        cover={
          <img
            height={200}
            alt="collateral"
            src={collateral.image}
          />
        }>
        <Meta title={cardTitle} description={<CollateralDescription collateral={collateral}/>}/>
      </Card>
      <Modal width={1000} onCancel={toggleModal} footer={null} destroyOnClose visible={visibleModal}>
        <CollateralContent/>
      </Modal>
    </>
  )
};

export default CollateralCard;