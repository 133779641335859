import React from 'react';
//Components
import {Row, Col, Steps} from "antd";
//Custom Components
import InputStep from "./components/input";
import SubmitStep from "../common/submit";
import SpvLimits from "../common/spv_limits";
import EligibilityCriteriaStep from "./components/eligibility_criteria";
//Helpers
import {statusName} from "../../../config";
import {ProposalProvider, useProposal} from "../../../context";

const components = [
    {
        key: 'input',
        label: "Input",
        module: InputStep,
        isDisabled: (status: any) => {
            return !status
        }
    },
    {
        key: 'eligibility_criteria',
        label: "Eligibility Criteria",
        module: EligibilityCriteriaStep,
        isDisabled: (status: any) => {
            if (status === statusName["Awaiting User Input"])
                return true
            return !status
        }
    },
    {
        key: 'spv_limits',
        label: "SPV Limits",
        module: SpvLimits,
        isDisabled: (status: any) => {
            if (status === statusName["Awaiting User Input"])
                return true
            else if (status === statusName["Awaiting For Computation"])
                return true
            return !status
        }
    },
    {
        key: 'submit',
        label: "Waterfall & Submit",
        module: SubmitStep,
        isDisabled: (status: any) => {
            if (status === statusName["Awaiting User Input"])
                return true
            else if (status === statusName["Awaiting For Computation"])
                return true
            return !status
        }
    }
];


const Stepper = (props: any) => {
    const {proposal,isFar} = useProposal();
    const [current, setCurrent] = React.useState<number | any>(0);

    const canNavigate = (index: number) => {
        const step = components[index];
        return !step.isDisabled(proposal?.status)
    }

    const handleBack = (forceRedirect = false) => {
        const validAction = canNavigate(current - 1);
        if (forceRedirect || validAction)
            setCurrent((previous: any) => previous - 1)
    }

    const handleNext = (forceRedirect = false) => {
        const validAction = canNavigate(current + 1);
        if (forceRedirect || validAction)
            setCurrent((previous: any) => previous + 1)
    }
    React.useEffect(() => {
        if (current > components.length - 1)
            setCurrent(0);
    }, [current])

const test =  components.filter((item:any) => item.key !== "spv_limits")
    return (
        <Row gutter={[16, 32]} key={current}>
            <Col xs={24}>
                    <Steps
                      size="default"
                      type="navigation"
                      current={current}
                      onChange={setCurrent}
                      className="site-navigation-steps">
                        {!isFar ? components.filter((item:any) => item.key !== "spv_limits").map((c: any) => <Steps.Step disabled={c.isDisabled(proposal?.status)}
                                                                title={c.label}/>) : components.map((c: any) => <Steps.Step disabled={c.isDisabled(proposal?.status)}
                                                                                                                                                                            title={c.label}/>)}
                    </Steps>
            </Col>

            {
              !isFar ? components.filter((item:any) => item.key !== "spv_limits").map((c: any, index) => {
                const Component = c.module;
                const visible = current === index;

                const style: any = {
                    display: 'none'
                };

                if (visible) {
                    style.display = 'block';
                    style.paddingBottom = 32;
                    style.overflowY = 'auto';
                }
                return (
                    <Col key={String(index)} xs={24} style={style}>
                        <Component
                            {...props}
                            footer={{
                                onBack: handleBack,
                                onNext: handleNext,
                                current: current,
                                canGoBack: current !== 0,
                                canNavigate: canNavigate,
                                nextLabel: current === components.length - 1 ? "Submit to Investor/s" : "Next",
                                backLabel: current === 0 ? undefined : `${components[current - 1].label}`
                            }}/>
                    </Col>
                )
            })
              :
              components.map((c: any, index) => {
                  const Component = c.module;
                  const visible = current === index;

                  const style: any = {
                      display: 'none'
                  };

                  if (visible) {
                      style.display = 'block';
                      style.paddingBottom = 32;
                      style.overflowY = 'auto';
                  }
                  return (
                    <Col key={String(index)} xs={24} style={style}>
                        <Component
                          {...props}
                          footer={{
                              onBack: handleBack,
                              onNext: handleNext,
                              current: current,
                              canGoBack: current !== 0,
                              canNavigate: canNavigate,
                              nextLabel: current === components.length - 1 ? "Submit to Investor/s" : "Next",
                              backLabel: current === 0 ? undefined : `${components[current - 1].label}`
                          }}/>
                    </Col>
                  )
              })
            }
        </Row>
    );
};

export default (props: any) => (
    <ProposalProvider {...props}>
        <Stepper/>
    </ProposalProvider>
)