import React from 'react';
//Config
import { detailedColumns, generalTableColumns } from "./config";
//Components
import Charts from "./chart";
import { Panel } from "@cardoai/components"
import { SmartTable } from "../../../../../../../components";
//Constants
import { theme } from "../../../../../../../assets/theme/colors";
//Group Styles
const groupStyles = {
  borderColor: theme.colors.white,
  background: theme.palette.secondary[3],
  color: "rgba(0, 0, 0, 0.65)"
};

const columnGroups = [
  {
    key: " ",
    styles: groupStyles
  },
  {
    key: "Senior Notes",
    styles: groupStyles
  },
  {
    key: "Junior Notes",
    styles: groupStyles
  }]


interface Props {
  data?: any,
  name?: string
}

const Scenario = ({data, name}: Props) => {
  return (
    <Panel padding={0} defaultOpen header={name}>
      <SmartTable
        columns={generalTableColumns}
        externalData={data.notes_summary}/>
      <Panel padding={0} defaultOpen={false} header="Details">
        <Charts data={data.notes}/>
        <SmartTable
          bordered
          centerData
          height={600}
          centerHeaders
          externalData={data.notes}
          columns={detailedColumns}
          columnGroups={columnGroups}/>
      </Panel>
    </Panel>
  );
};

export default Scenario;