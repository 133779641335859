import React from 'react';
//Components
import { Col, Row } from "antd";
import StackedChart from "../stacked_chart";
import CashReserves from '../cash_reserves';
//Helpers
import { collateralChartColors as chartColors } from "../../colors";
import { formatMillions, formatPercent, formatThousands, isEmpty } from "../../../../../../helpers";


function hasEmptyDatasets(object: any) {
  for (let key in object) {
    if (object.hasOwnProperty(key) && isEmpty(object[key]))
      return true;
  }
  return false;
}

interface ICollateralChart {
  data?: any,
  maxHeight?: any,
  notesTotal?: any,
  collateralTotal?: any
}

const CollateralChart = (props: ICollateralChart) => {
  const {data, maxHeight, notesTotal, collateralTotal} = props;


  const differenceOfCharts = collateralTotal - notesTotal;

  const getColor = (customColors: string[]) => (index: any) => {
    return customColors[index];
  }

  const isEmptyChartData = hasEmptyDatasets(data.charts);
  const chartHeight = maxHeight - (25 * data.cash_reserve.length);

  return (
    <Row className="columnDirection fullHeight">
      <Col style={{height: maxHeight}}>
        <Row className="columnDirection fullHeight">
          <Col>
            <CashReserves data={data.cash_reserve}/>
          </Col>
          <Col style={{height: chartHeight}}>
            <Row gutter={16} className="fullHeight">
              <Col xs={12}>
                <StackedChart
                  title="Collateral by Currency"
                  getColor={getColor(chartColors.currencies_list)}
                  data={data.charts.collateral_by_currency}
                  customLabel={{formatter: formatPercent, dataset: "percentage"}}
                />
              </Col>
              <Col xs={12}>
                <StackedChart
                  title="Collateral by Performance"
                  getColor={getColor(chartColors.notes_list)}
                  data={data.charts.collateral_by_performance}
                  customLabel={{formatter: formatMillions, dataset: "value"}}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {!isEmptyChartData && <Col>
        <div style={{backgroundColor: "#F3F3F3", fontSize: 10, fontWeight: 'bold'}} className="fullHeight textCenter">
          Difference:{" " + formatThousands(differenceOfCharts, 2)}</div>
      </Col>}
    </Row>
  );
};

export default CollateralChart;