import React from 'react';
//Cardo AI components
import { BoldText, CustomTable } from "@cardoai/components";
//Helpers
import { formatPercent, formatThousands } from "../../../../../../helpers";

const tableHeader: any = {
  "Property Stratifications": "Property Type",
  "Average Property Size": "Size",
  "Appraisal Seasoning": "Year",
  "Loan to value (LTV)": "LTV",
  "Property concentration": "Region"
}

const CollateralStatsTable = (props: any) => {
  const {categoryName, data} = props;


  let thName;

  switch (categoryName){
    case "Average Property Size":
      thName = "Size"
      break;
    case "Property Stratifications":
      thName = "Property type"
      break;
    case "Property concentration":
      thName = "Region"
      break;
  }

  return (
    <div>
      <BoldText className="textCenter">{categoryName}</BoldText>
      <CustomTable height={400}>
        <thead>
          <tr>
            <th>{thName}</th>
            <th>Real Estate Value</th>
            <th>Value (%)</th>
          </tr>
        </thead>
        <tbody>
        {data.map((record: any) => {
          return (
            <tr>
              <td>{record.name}</td>
              <td>{formatThousands(record.balance,0)}</td>
              <td>{formatPercent(record.balance_percent,0)}</td>
            </tr>
          )
        })}
        </tbody>
      </CustomTable>
    </div>
  );
};

export default CollateralStatsTable;