import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState, } from "react";
//Configuration
import { columns } from "./update_config";
//Components
import { Button, Card, Col, DatePicker, Input, Row } from "antd";
//Helpers
import {formatVehicleLimits} from "../../../helpers";
import { callApi, generateUrl } from "../../../../../../../../../helpers";
//Custom Components
import { SmartTable } from "../../../../../../../../../components";
import { mediatorSend } from "@cardoai/utils";
import SelectWaiverType from "../../calculation_waiver/select_waiver_type";
import moment, { Moment } from "moment";
import { useWaiverContext, } from "../../calculation_waiver/calculation_waiver_provider";
import { details_columns } from "./details_config";
import { STATUSES_ITEMS } from "../helpers";
import { disableEndDate, disableStartDate, } from "../../create_waiver_request/date_validation";
import cx from "classnames";
import { withData } from "../../../../../../../../../utils";
import withLimitDownload from "../../../components/hoc/with_limit_download";

interface tFinalData {
  reason: string;
  start_date: Moment;
  end_date: Moment;
  loan_waivers: any;
  original_value: number;
  status: string;
  original_broken_flag: boolean;
}

interface Props {
  data: any,
  finalData: tFinalData;
  limitId?: number;
  formatter?: any;
  editable: boolean;
  id: number;
  vehicleId: number;
  setVisibleUpdateModal: Dispatch<SetStateAction<boolean>>;
  fulfillment_percent?:any,
  currencyLimits?:any
}

const {TextArea} = Input;

const initialDate = (date: string) => {
  if (date == null) return moment();
  return moment(date, "YYYY-MM-DD");
};

const DetailsWaiver = (props: Props) => {
  const waiverContext: any = useWaiverContext();

  const {
    data,
    vehicleId,
    id,
    formatter,
    editable,
    setVisibleUpdateModal,
    currencyLimits,
    fulfillment_percent
  } = props;

  const [displayModal, setDisplayModal] = React.useState<boolean>(false);
  const [input, setInput] = useState<string>(data.reason);
  const [startDate, setStartDate] = useState<Moment | null>(() => initialDate(data.start_date));
  const [endDate, setEndDate] = useState<Moment | null>(() => initialDate(data.end_date));

  useEffect(() => {
    waiverContext.initialLoanData(data.loan_waivers);
  }, []);

  const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const onUpdate = () => {
    callApi({
      url: `main/limit_waiver/${id}/?vehicle_id=${vehicleId}`,
      method: "PATCH",
      body: {
        reason: input,
        loans: waiverContext.initialLoan,
        start_date: startDate && moment(startDate).format("YYYY-MM-DD"),
        end_date: endDate && moment(endDate).format("YYYY-MM-DD"),
      },
      successMessage: "Waiver submitted for approval!",
      onSuccess: () => {
        setVisibleUpdateModal(false);
        // todo replace with invalidate query
        mediatorSend(
          "Limits",
          "refreshLimits",
          undefined
        );
        mediatorSend(
          "WaiverRequestHistory",
          "refreshDataWaiverHistory",
          undefined
        );
      },
    });
  };

  const onConfirmType = () => {
    setDisplayModal(true);
  };

  const onChangeStartDate = (value: Moment | null) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: Moment | null) => {
    setEndDate(value);
  };

  const onCancel = (value: any) => {
    waiverContext.updateEditWaiver(value);
    setDisplayModal(false);
  };

  const statusColor = STATUSES_ITEMS[data.status].backgroundColor;

  const preWaiverLimitColor = data.original_broken_flag;

  const getPreWaiverColors = (preWaiverLimitColor: boolean) => {
    if (preWaiverLimitColor) return "redText";
    return "greenText";
  };

  const fulfillmentClassPreWaiver = getPreWaiverColors(preWaiverLimitColor);

  return (
    <div>
      <Row>
        <Col span={10}>
          <Card bordered={false}>
            <h4 className={editable ? 'mb16' : 'mb10'}>
              Pre-waiver Limit: {" "}
              <span className={cx(fulfillmentClassPreWaiver)}>
                 {formatVehicleLimits(data.original_value, fulfillment_percent,formatter,currencyLimits)}
              </span>
            </h4>
            <h4 className="mb16">
              Status:{" "}
              <span style={{color: statusColor}}>{data.status}</span>
            </h4>
          </Card>
        </Col>
        <Col span={14}>
          {!editable ? (
            <Card bordered={false}>
              <div className="mb10">
                <h4>
                  Start Date:
                  {data.start_date &&
                  moment(data.start_date).format("YYYY-MM-DD")}
                </h4>
              </div>
              <h4>
                End Date:
                {data.start_date &&
                moment(data.end_date).format("YYYY-MM-DD")}
              </h4>
            </Card>
          ) : (
            <Card bordered={false}>
              <Row className="ant-row-no-wrap mb10" gutter={8} >
                <Col span={4} className="bolder">
                  Start date:
                </Col>
                <Col span={8}>
                  <DatePicker
                    allowClear={false}
                    value={startDate}
                    onChange={onChangeStartDate}
                    disabledDate={disableStartDate}
                    placeholder="Select Start Date"
                  />
                </Col>
              </Row>
              <Row className="ant-row-no-wrap" gutter={8}>
                <Col span={4} className="bolder">
                  End date:
                </Col>
                <Col span={8}>
                  <DatePicker
                    allowClear={false}
                    disabledDate={disableEndDate}
                    value={endDate}
                    onChange={onChangeEndDate}
                    placeholder="Select End Date"
                  />
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
          <Row style={{marginLeft:23}}>
            <Col span={2} className="mr8">
              <h4>Description:</h4>
            </Col>
            <Col span={editable ? 14 : 12}>
              <TextArea rows={2} value={editable ? input : data.reason} onChange={editable ? onChangeValue : undefined}/>
            </Col>
          </Row>
      <SmartTable
        columns={editable ? columns : details_columns}
        externalData={data.loan_waivers}
        height={400}
      />
      {editable && (
        <Row justify="space-between" className="mt16">
          <Button
            onClick={onConfirmType}
            type="primary"
            disabled={data.loan_waivers.length === 0}
          >
            Confirm
          </Button>
          {displayModal && (
            <SelectWaiverType showModal={displayModal} onCancel={onCancel}/>
          )}
          <Button
            onClick={onUpdate}
            type="primary"
          >
            Submit
          </Button>
        </Row>
      )}
    </div>
  );
};

export default withData({
  urlFormatter: (config: any) => {
    const {
      props: { vehicleId, limitId ,id},
    } = config;
    return generateUrl(`main/limit_waiver/${id}`, {
      vehicle_id: vehicleId,
      limit_id: limitId,
    });
  },
})(
  withLimitDownload(DetailsWaiver)({
    title:`Waiver_Details_${moment().format("MMM DD, YYYY")}`,
    prepareData: (data: any) => {
      return data.loan_waivers.map((item:any)=>{
        return {
          "Loan ID":item.loan,
          "Company Name":item.company_name,
          "Current Balance":item.current_balance,
          "Calculation Waiver":item.exclusion_type
        }
      })
    },
  })
);


