import React from 'react';
//Custom Components
import { CustomImage } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";


const NotesManager = () => {
  return (
    <CustomImage
      alt="Notes Manager Full View"
      src={assets.images.notesManagerModule.fullView}
      description="This module is available only for specific users. Notes Manager is a tool that allows users to
        manage the notes on the vehicle as well as provide loan proposals through a certain procedure within the platform."
    />
  );
};
export default NotesManager;
