import React from 'react';
//Components
import { Col, Row } from "antd";
import File from "../file";

interface IFiles {
  files?: any[],
  handleFileRemove?: any
}

const Files = (props: IFiles) => {
  const {files, handleFileRemove} = props;

  if (!files || !files.length)
    return null;

  return (
    <div style={{minHeight: 250}}>
      <Row gutter={16}>
        {files.map((file: any, index: number) => {
          return (
            <Col xs={12} key={index}>
              <File
                content={file}
                onRemove={handleFileRemove(index)}/>
            </Col>
          )
        })}
      </Row>
    </div>
  );
};

export default Files;