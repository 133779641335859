import React from 'react';

interface IWrapContent {
  wrap?: any,
  view?: any,
  children?: any,
}

const WrapContent = (props: IWrapContent) => {
  const {wrap, view: View, children, ...rest} = props;
  if (!wrap)
    return children;
  return (
    <View {...rest}>
      {children}
    </View>
  );
};

export default WrapContent;
