import React from 'react';
import {Avatar} from "antd";

const AvatarGroup = (props: any) => {
    return (
      <Avatar.Group maxCount={4} {...props}>
          <Avatar style={{ backgroundColor: '#1677ff' }}>E</Avatar>
          <Avatar style={{ backgroundColor: '#1677ff' }}>M</Avatar>
          <Avatar style={{ backgroundColor: '#1677ff' }}>E</Avatar>
          <Avatar style={{ backgroundColor: '#1677ff' }}>M</Avatar>
          <Avatar style={{ backgroundColor: '#1677ff' }}>E</Avatar>
          <Avatar style={{ backgroundColor: '#1677ff' }}>M</Avatar>
          <Avatar style={{ backgroundColor: '#1677ff' }}>K</Avatar>
      </Avatar.Group>
    )
};

export default AvatarGroup;