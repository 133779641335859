import React from "react";
//Configurations
import { setSticky } from "../configurations";

interface IHeaderCell {
  index?: any,
  children?: any,
  position?: any
}

const HeaderCell = (props: IHeaderCell) => {
  const {index} = props;
  return (
    <th key={index} style={{position: setSticky(index), left: index * 100}}>
      <div className="flexCenter">
        {props.children}
      </div>
    </th>
  )
}


export default HeaderCell;