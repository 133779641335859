import React from "react";
//Google Analytics
import { Analytics } from "../../../../helpers";
//Components
import { Col, Input, Row } from "antd";
import { SmartTable } from "../../../../components";
//Constants
import { DOD_Columns } from "./columns";
//Icons
import { SearchOutlined } from "@ant-design/icons";

interface IDODTable {
  queryStr?: string,
  currency?: any
}

const DODTable = (props: IDODTable) => {
  const {queryStr, currency} = props;

  const [searchField, setSearchField] = React.useState<any>(null);

  const handleSearch = (event: any) => setSearchField(event.target.value);

  const getFilters = () => {
    let filters = queryStr;

    if (searchField) {
      Analytics.dispatchEvent({
        category: 'Filters',
        action: 'Used Search at DOD Table',
        label: searchField
      });

      filters += `&search=${searchField}`
    }
    return filters;
  }

  return (
    <>
      <Row justify='end' align='middle'>
        <Col>
          <Input
            autoFocus
            allowClear
            style={{width: 300}}
            value={searchField}
            onChange={handleSearch}
            prefix={<SearchOutlined/>}
            placeholder="Search by debtor CF / Debtor Name"/>
        </Col>
      </Row>
      <SmartTable
        simpleFetch
        height={350}
        pageSize={10}
        usePagination
        url={`/dod/dod/`}
        filters={getFilters()}
        columns={DOD_Columns(currency)}/>
    </>
  )
}


export default DODTable;