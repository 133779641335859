import React from "react";
//Components
import { Col, Row } from "antd";
import Vehicle_statistics from "./vehicle_statistics";
//HOC
import withVerticalList from "./with_vehicle_selection";
//Configurations
import { Analytics } from "../../../../../../helpers";
import { vehicleFieldConfig } from "../../config";

//Custom  Styles
interface IStatistics {
  statistics: any,
  vehicleId: any,
  setSelectedVehicle?: any,
}

const contentStyle: any = {
  flex: '1 0 0',
  overflowX: 'auto',
  paddingLeft: 12
};

const VehicleStatistics = withVerticalList(Vehicle_statistics);

export default (Component: any) => (props: IStatistics) => {
  const {statistics, vehicleId, setSelectedVehicle, ...config} = props;

  const isVehicleActive = (key: any) => {
    return key == vehicleId;
  }

  const onVehicleSelection = (key: any, name: string) => {
    setSelectedVehicle(key);
    Analytics.dispatchEvent({
      category: 'User',
      action: 'Selected Vehicle',
      label: name
    });
  }

  return (
    <Row gutter={8} justify="space-between" className="noWrap">
      <Col lg={8} md={24} sm={24} style={{marginTop: 16}}>
        <VehicleStatistics
          height={450}
          keyAttr="vehicle_id"
          vehicleId={vehicleId}
          isActive={isVehicleActive}
          data={statistics.vehicles}
          config={vehicleFieldConfig}
          onChange={onVehicleSelection}
        />
      </Col>
      <Col lg={16} md={24} style={contentStyle}>
        <Component
          vehicleId={vehicleId}
          {...config}/>
      </Col>
    </Row>
  )
};