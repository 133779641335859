import React from 'react';
//Components
import Breadcrumbs from "../../context/navigation_tabs_provider/breadcrumbs";
//Custom Helpers
import {copyArray} from "../../../helpers";

//Constants
const initialNavigation: any = [];

const NavigationContext = React.createContext<any>({
    navigation: []
});

interface iNavigation {
    title?: string;
    children?: any;
}

const NavigationProvider = (props: iNavigation) => {
    const {title} = props;

    const [navigation, setNavigation] = React.useState<any>(initialNavigation);

    const onClear = () => {
        setNavigation(initialNavigation);
    }

    const onSelect = (index: any, value: any, options: any[]) => () => {

        let cloneNavigatorState: any = copyArray(navigation);

        const updateNavigation = (updateIndex: string | number, record: any) => {
            cloneNavigatorState[updateIndex].key = record.key;
            cloneNavigatorState[updateIndex].label = record.label;
        }

        const clearChildren = (currentIndex: number) => {
            const shouldClear = cloneNavigatorState.length !== currentIndex + 1;
            if (shouldClear)
                cloneNavigatorState = cloneNavigatorState.filter((_: any, index: number) => index <= currentIndex);
        }

        /*Search Options if any*/
        const record = options.find(rec => rec.key === value);

        /*Update with_navigation with_navigation*/
        updateNavigation(index, record);

        if (record && record.clearRest)
            clearChildren(index);

        if (record.child) {
            clearChildren(index);
            cloneNavigatorState.push(record.child);
        }

        setNavigation(cloneNavigatorState);
    }

    return (
        <NavigationContext.Provider value={{
            clear: onClear,
            state: navigation,
            set: setNavigation
        }}>
            <Breadcrumbs
                title={title}
                onSelect={onSelect}
                navigation={navigation}
                setNavigation={setNavigation}/>
            {props.children}
        </NavigationContext.Provider>
    )

}


export {
    NavigationContext,
    NavigationProvider,
};


