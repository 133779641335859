import React from 'react'
///Components
import { Button, Space } from "antd";
//Custom Components
import Sync from "./sync";
import Delete from "./delete";
//Icons
import { FileSyncOutlined } from "@ant-design/icons";
//Constants
import { useAuthorization } from "../../../../../../utils/hooks";
import { actionKey } from "../../../../../../constants";
import DataHealthCheck from "../../../health_check";

interface Props {
  record?: any,
  vehicleId?: any,
  callbacks?: any
}

const Actions = (props: Props) => {
  const {record, vehicleId, callbacks} = props;
  const [visible, setVisible] = React.useState(false);

  const authorization: any = useAuthorization();
  const canPerformSynchronization = authorization.canPerformAction(actionKey.executeSync, vehicleId);

  const fileType = record.sync_request_type;


  const onToggle = () => {
    setVisible(prevState => !prevState);
  };

  const params: any = {
    id: record.id,
    fileType: fileType,
    vehicleId: vehicleId,
    status: record.status
  };

  if (callbacks)
    params.requestReports = callbacks.makeRequest;

  return (
    <Space>
      {visible && <DataHealthCheck onClose={onToggle} documentId={record.id} {...params}/>}
      <Button size='small' onClick={onToggle} icon={<FileSyncOutlined/>}>
        Details
      </Button>
      {canPerformSynchronization && <Sync {...params}/>}
      <Delete {...params} />
    </Space>
  );
};

export default Actions;
