import React from 'react';
//Components
import Reports from "./reports";
import Contracts from "./contracts";
//Config
import { PANEL_POSITION } from "../config";

interface IManagement {
  vehicle: any,
  vehicleId: any
}

const Management = (props: IManagement) => {
  const {vehicle, vehicleId} = props;

  const collapseReference = React.useRef();

  const triggerCollapse = (key: any) => () => {
    if (window.innerWidth < 1750)
      return;
    const collapseAPI: any = collapseReference && collapseReference.current;
    const position = PANEL_POSITION[key];
    collapseAPI.updateChildPanel(position);
  }

  return (
    <section>
      <Contracts collapseReference={collapseReference} triggerCollapse={triggerCollapse} vehicleId={vehicleId}
                 vehicle={vehicle}/>
      <br/>
      <Reports collapseReference={collapseReference} triggerCollapse={triggerCollapse} vehicle={vehicle}
               vehicleId={vehicleId}/>
    </section>
  )

};

export default Management;
