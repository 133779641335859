import React from 'react';
//Components
import { Col, Row } from "antd";
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const LoanTimeline = () => {
  return (
    <>
      <div>
        <CustomImage
          alt="Loan Timeline"
          src={assets.images.loansModule.amortizationGraph}
          description="Shows the timeline of the instalments for the specific loan. Instalments are split by
        Expected (based on amortisation plan) and Realized (i.e. actual cash flows) and further divided by Principal,
        Interest and Late Fees. The time span of the instalments depends on the availability of amortisation plans."/>
        <p>
          With the control on the upper right part of the screen, the User can switch the view from Graph to Table
          (showing the graph information on table format) or Events (allowing to switch between realized and expected
          cash flows).
        </p>
      </div>
      <Row>
        <Col lg={12}>
          <CustomImage src={assets.images.loansModule.eventsRealized}/>
        </Col>
        <Col lg={12}>
          <CustomImage src={assets.images.loansModule.eventsExpected}/>
        </Col>
      </Row>
    </>
  );
};

export default LoanTimeline;
