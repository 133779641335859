//Constants
import { theme } from "../../../../assets/theme/colors";

const styles: any = {
  container: {
    marginTop: 8,
    height: 30,
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    border: "1px solid transparent",
    boxShadow: "0 2px 0 rgba(0, 0, 0, 0.015)",
    fontSize: 12,
    borderRadius: 2,
    color: "rgba(0, 0, 0, 0.65)",
    background: theme.palette.grayscale[10],
    borderColor: theme.palette.grayscale[10],
  },
  labelItem: {
    width: "100%",
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 99999999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.65)",
  },
  percentageItem: {
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.palette.structureChartColors[5],
    overflow: 'hidden',
    zIndex: 9,
    whiteSpace: 'nowrap',
  }
}
export default styles;
