import React from 'react';
import {Col, Row} from "antd";

const Menu = ({value, config, onChange}: any) => {
    return (
        <Row gutter={[8, 8]}>
            {config.map((section: any) => {
                const active = section.key === value;

                const styles: any = {};

                if (active) {
                    styles.background = '#F5F8FE';
                    styles.color = '#2D72EA';
                }

                return (
                    <Col xs={24}
                         key={section.key}
                         onClick={() => {
                             onChange(section.key)
                         }}
                         style={{
                             height: 40,
                             fontWeight: 400,
                             paddingLeft: 16,
                             color: '#736974',
                             cursor: 'pointer',
                             display: 'flex',
                             alignItems: 'center',
                             borderRadius: '16px',
                             ...styles
                         }}
                    >
                        {section.label}
                    </Col>
                )
            })}
        </Row>
    );
};

export default Menu;