import React from 'react';
//Other libs
import ReactDOM from "react-dom";
import styled from "styled-components";
//Components
import { Breadcrumb, Menu } from "antd";
//Icons
import { HomeOutlined } from "@ant-design/icons";
//Constants
import { theme } from "../../../assets/theme/colors";
//Styles
const Styles = styled.div`
  span {
    font-size: 15px;
  }

  span:last-child .ant-breadcrumb-overlay-link {
    border-bottom: 2px solid ${theme.colors.primary};
  }
`

const Breadcrumbs = (props: any) => {
    const {title, onSelect, navigation, setNavigation} = props;

    const getNode = (el: string) => document.getElementById(el);

    React.useEffect(() => {
        const updateTitle = (title: string) => {
            const node = getNode('header-layout-title');
            if (node) node.innerHTML = title;
        }
        updateTitle(navigation.length ? "" : title);
    }, [navigation]);

    const node = getNode('header-layout');

    if (!node)
        return null;

    return ReactDOM.createPortal(
      <Styles>
          <Breadcrumb separator=">">
              {!!navigation.length && (
                <>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {title}
                    </Breadcrumb.Item>
                </>
              )}
              {navigation.map((record: any, index: number) => {
                  const {clear, options, key} = record;

                  const params: any = {
                      className: 'pointerCursor'
                  };

                  let clearHistory: any;

                  if (clear)
                      clearHistory = clear;



                  let items: any;

                  if (Array.isArray(options)) {
                      items = options;
                  } else {
                      items = options.list;

                      clearHistory = [];

                      const filteredState = [];

                      for (let i = 0; i <= index; i++) {
                          filteredState.push(navigation[i])
                      }

                      const length = filteredState.length;

                      filteredState.forEach((el, index) => {
                          if (index === length - 1) {
                              clearHistory.push({
                                  ...options.initial,
                                  options: options
                              })
                          } else {
                              clearHistory.push(el);
                          }
                      })
                  }

                  if (clearHistory)
                      params.onClick = () => setNavigation(clearHistory)


                  if (items)
                      params.overlay = (
                        <Menu style={{maxHeight: 700, overflow: "auto"}} selectedKeys={[key]}>
                            {items.map((option: any) => {
                                const key = option.key;
                                return (
                                  <Menu.Item key={key} onClick={onSelect(index, key, items)}>
                                      {option.label}
                                  </Menu.Item>
                                )
                            })}
                        </Menu>
                      )

                  return (
                    <Breadcrumb.Item {...params}  key={key}>
                        {record.label}
                    </Breadcrumb.Item>
                  )
              })}
          </Breadcrumb>
      </Styles>,
      node,
    );

};

export default Breadcrumbs;