import React, { useState } from 'react';
//Components
import { Button } from "antd";

const styles: any = {
  container: {
    marginTop: 8,
    marginBottom: 16,
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    margin: 3,
    minWidth: 180,
    width: "10%"
  }
}

//Custom Helpers
function getInitialSelection(data: any) {
  const state: any = {};
  for (let record of data) {
    const id = record.id
    state[id] = false;
  }
  return state;
}

function cloneState(object: any) {
  return Object.assign({}, object);
}

const FundSelection = (props: any) => {
  const {data} = props;
  const [selected, setSelected] = useState(getInitialSelection(data));

  const onClick = (id: any) => () => {
    setSelected((previousSelection: any) => {
      const newSelection = cloneState(previousSelection);

      function setAllEntities() {
        for (let key in newSelection) {
          if (newSelection.hasOwnProperty(key))
            newSelection[key] = false;
        }
      }

      function setCustomEntity() {
        newSelection[id] = !newSelection[id];
      }

      function checkSelection() {
        let total = 0;
        for (let key in newSelection) {
          if (newSelection.hasOwnProperty(key) && newSelection[key])
            total++;
        }

        if (total === 0 || total === data.length) {
          setAllEntities();
        }
      }

      if (id === "all") {
        setAllEntities();
      } else {
        setCustomEntity();
        checkSelection();
      }
      return newSelection;
    })
  }


  const getButtonType = (value: any) => {
    let isSelected = true;

    if (value === "all") {
      for (let record of data) {
        if (selected[record.id])
          isSelected = false;
      }
    } else {
      isSelected = selected[value];
    }

    return isSelected ? 'primary' : 'default';
  }

  return (
    <section style={styles.container}>
      <Button
        style={styles.button}
        onClick={onClick("all")}
        type={getButtonType("all")}>
        All
      </Button>
      {data.map((record: any, index: number) => {
        const id = record.id;
        const label = record.label;
        return (
          <Button
            key={String(index)}
            style={styles.button}
            onClick={onClick(id)}
            type={getButtonType(id)}>
            {label}
          </Button>
        )
      })}
    </section>
  );
};

export default FundSelection;