import React from 'react';
//Components
import { Button, Col, Row, Tooltip } from "antd";
//Icons
import { DownloadOutlined } from "@ant-design/icons";
//Helpers
import { createFileName, downloadClientFile } from "../../../../../../helpers";
//CardoUI Components
import { FileUpload } from "@cardoai/components";
//Images
import pdfImage from "../../../../../../assets/images/files/pdf.png";
import wordImage from "../../../../../../assets/images/files/word.png";
import excelImage from "../../../../../../assets/images/files/excel.png";
//Constants
import { theme } from "../../../../../../assets/theme/colors";

const styles = {
  container: {
    width: 200,
    height: 200,
    borderRadius: 4,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    border: `1px dashed ${theme.palette.grayscale[3]}`,
    justifyContent: 'space-between',
  },
  image: {
    flex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    padding: 6,
    fontSize: 12,
    flexBasis: 32,
    fontWeight: 400,
    backgroundColor: theme.colors.darkwhite,
    borderBottom: `1px solid ${theme.palette.grayscale[8]}`
  },
  icon: {
    fontSize: 14,
    cursor: 'pointer'
  },
  uploadBtn: {
    fontSize: 14,
    backgroundColor: theme.palette.grayscale[10]
  }
};

const fileImages = {
  pdf: pdfImage,
  docx: wordImage,
  xlsx: excelImage
};

const dropZoneConfig = {
  multiple: false,
  clickable: true,
  className: 'files-dropzone',
  accepts: ['.xlsx', '.xls', '.csv'],
  dropActiveClassName: 'files-dropzone-active',
};

interface Props {
  editable?: any,
  record?: any,
  onFileChange: (param1?: any, param2?: any) => any
}

const File = (props: Props) => {
  const {editable, record, onFileChange} = props;

  const type = record.type;
  const src = fileImages["xlsx"];
  const updated = !!record.override;

  const onUpload = (files: any) => {
    if (editable) {
      const file = files[0];
      if (file)
        onFileChange(type, file)
    }
  };

  const onDownload = () => {
    downloadClientFile({
      link: true,
      url: record.url,
      filename: createFileName(`Document ${record.name}`)
    })
  };

  const uploadConfig = {
    ...dropZoneConfig,
    onChange: onUpload
  };

  if (!editable) {
    uploadConfig.accepts = [];
    uploadConfig.clickable = false;
    uploadConfig.dropActiveClassName = 'files-dropzone';
  }

  const containerStyle: any = {
    ...styles.container
  };

  const labelStyle: any = {};

  if (updated) {
    labelStyle.color = theme.colors.primary;
    containerStyle.borderColor = theme.colors.primary;
  }

  return (
    <div style={containerStyle}>
      <FileUpload {...uploadConfig} style={styles.image}>
        <img height={80} src={src} alt="Image"/>
      </FileUpload>
      <div style={styles.label}>
        <Row justify="space-between" align='bottom'>
          <Col>
            <Row align='middle' gutter={8}>
              <Col>
                <img height={18} src={src} alt="Image"/>
              </Col>
              <Col style={labelStyle}>
                {record.name}
              </Col>
            </Row>
          </Col>
          {!updated && <Col>
            <Button onClick={onDownload} size='small' icon={<DownloadOutlined style={styles.icon}/>}/>
          </Col>}
        </Row>
      </div>
      {editable &&
        <Row>
          <Col xs={24}>
            <FileUpload {...uploadConfig}>
              <Tooltip placement='bottom' title="Upload new file">
                <Button type="text" block style={styles.uploadBtn}>
                  Override
                </Button>
              </Tooltip>
            </FileUpload>
          </Col>
        </Row>}
    </div>
  )
};

const Files = (props: any) => {
  const {onFileChange, editFiles, form} = props;
  return (
    <Row justify="space-between" style={{paddingBottom: '36px'}}>
      {Object.keys(form).map(key => (
        <Col>
          <File
            record={form[key]}
            editable={editFiles}
            onFileChange={onFileChange}/>
        </Col>
      ))}
    </Row>
  );
};

export default Files;