import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const CashFlow = () => {
  return (
    <CustomImage
      alt="SPV Management Notes Cash Flow"
      src={assets.images.spvModule.notesCashFlow}
      description="This section shows the cash flows of all the tranches of the Notes as recorded at each reporting date.
            The User can select the relevant time range to be displayed or Search by cash flow type with the commands above the table."
    />
  );
};

export default CashFlow;
