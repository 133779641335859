//Helpers
import { capitalize, MathOp } from "../../../../../helpers";

const pieChartColors = [
  "#13c2c2",
  "#b5f5ec",
  "#eaff8f",
  "#b7eb8f",
  "#8CE5D3",
  "#a0d911",
  "#5cdbd3",
  "#5b8c00",
  "#d9f7be",
  "#f4ffb8",
  "#237804",
  "#52c41a",
];
const doughnutColors = [
  "#4565f8",
  "#4990FF",
  "#7babff",
  "#b3d7ff",
  "#cee6ff",
  "#4990FF"
];

//Configurations
export const preparePieData = (data: any) => {
  if (!data.loan_structure)
    return [];

  const collateral = data.loan_structure.loan_status;
  const Labels = Object.keys(collateral);

  return Labels.map((label, index) => {
    return {
      label: capitalize(label),
      value: collateral[label],
      color: pieChartColors[index]
    }
  })
};

export const prepareDoughnutData = (data: any) => {
  const tranches = data.details;
  let sum = 0;
  tranches.forEach((tranche: { current_balance: number; }) => sum += tranche.current_balance);

  if (sum === 0)
    return [];
  else
    return tranches.map((record: any, index: any) => {
      return {
        id: record.type,
        label: record.name,
        value: MathOp.divide(record.current_balance, sum),
        color: doughnutColors[index]
      }
    });
}