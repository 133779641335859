import React from "react";
//Components
import { Skeleton } from "antd";
import { SimpleEmpty } from "@cardoai/components";
import RegionsComponent from "./components/regions_component";
import CountriesComponent from "./components/countries_component";
//Hooks
import { useRedirection } from "../../../../utils/hooks";
//Helpers
import { isEmpty } from "../../../../helpers";
import { MAPS, prepareCountryData, prepareRegionsData } from "./helpers";

interface IGeography {
  data?: any,
  height?: any,
  showTable?: boolean,
  vehicleId?: any,
  showCountryCards?: boolean,
  view?: any,
  singleVehicleView?: boolean,
  redirectSourceGeography?: any,
  currency?: any
}

const isCountryMode = (data: any) => {
  const filteredCountries = data.filter((country: any) => country.name.toUpperCase() !== "OTHER")
  return filteredCountries && filteredCountries.length > 1;
}

const Geography = (props: IGeography) => {
  const {setRedirection}: any = useRedirection();

  const {
    data,
    view,
    vehicleId,
    redirectSourceGeography,
    showTable,
    height,
    showCountryCards,
    singleVehicleView,
    currency
  } = props;

  if (!data)
    return <Skeleton active/>

  if (isEmpty(data))
    return <SimpleEmpty/>;



  const countryMode = isCountryMode(data);

  const countryId = !countryMode && data[0].id;
  const countryName = !countryMode && data[0].name;

  let mapRecords: any = [], legendColorDistribution = [];

  if (countryMode) {
    mapRecords = prepareCountryData(data)
  } else {
    const allRegionsData: any = prepareRegionsData(data);
    mapRecords = allRegionsData.records;
    legendColorDistribution = allRegionsData.legend;
  }

  const redirectionConfig = (selectedGeography: any) => {
    return {
      country: {
        rules: {
          country: {
            selected: true,
            value: [selectedGeography.id]
          },
        },
        source: redirectSourceGeography
      },
      regions: {
        rules: {
          region: {
            selected: true,
            value: [selectedGeography.name]
          },
          country: {
            selected: true,
            value: [countryId]
          },
        },
        source: redirectSourceGeography
      }
    }

  }

  const onClick = (record: any) => {

    const selectedGeography = mapRecords.find((region: any) => region.name === record.name);
    if (selectedGeography && selectedGeography.name !== "OTHER") {

      let config: any;

      if (countryMode)
        config = redirectionConfig(selectedGeography).country
      else
        config = redirectionConfig(selectedGeography).regions;

      if (!singleVehicleView) {
        config.rules.selectedVehicles = {
          selected: true,
          value: [vehicleId]
        }
      }

      setRedirection({
        path: '/loans',
        config: config
      })
    }
  };

  return (
    <>
      {countryMode ?
        <CountriesComponent data={mapRecords} view={view} onClick={onClick} currency={currency}
                            showCountryCards={showCountryCards}/> :
        <RegionsComponent map={MAPS[countryName]} onClick={onClick} countryMode={countryMode} data={mapRecords}
                          scale={legendColorDistribution} showTable={showTable} height={height} currency={currency}/>}
    </>
  );
};
Geography.defaultProps = {
  showTable: true,
  showCountryCards: true
}
export default Geography;