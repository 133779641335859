import React from 'react';
//Custom Components
import { CustomImage, DefinitionList } from "../../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../../assets";

const Notes = () => {
  return (
    <>
      <CustomImage
        alt="SPV Management Notes Full View"
        src={assets.images.spvModule.notesFullView}
      />
      <div>
        <DefinitionList
          header="The section shows details of the Notes, starting from some summary statistics:"
          data={[
            {
              term: 'Collection Period Start',
              explanation: 'first day of the last collection period'
            },
            {
              term: 'Collection Period End',
              explanation: 'last day of the last collection period'
            },
            {
              term: 'Notes Balance',
              explanation: 'current outstanding amount of the notes'
            },
            {
              term: 'Factor (%)',
              explanation: 'repayment factor as % (ratio between Initial Balance and Current Balance of the notes)'
            },
            {
              term: 'Initial OC (%)',
              explanation: 'initial overcollateralization of the transaction as % of the initial balance of the notes'
            },
            {
              term: 'Initial OC (€)',
              explanation: 'initial overcollateralization of the transaction in € amount'
            },
            {
              term: 'Current OC (%)',
              explanation: 'current overcollateralization as % of the outstanding notes'
            },
            {
              term: 'Current OC (€)',
              explanation: 'current overcollateralization of the transaction in € amount'
            },
            {
              term: 'Reserve Balance',
              explanation: 'current amount of the reserves hold by the SPV (cash reserve, payment reserve, etc. – it ' +
                'might include amounts available for further purchases depending on the characteristics of the transaction)'
            },
            {
              term: 'Fees (%)',
              explanation: 'fees paid during the last payment period as % of the notes outstanding'
            },

            {
              term: 'XS (%)',
              explanation: 'excess spread of the transaction as per last reporting period'
            },
            {
              term: 'Revolving Period End',
              explanation: 'end of the revolving period as per contractual provision'
            },
            {
              term: 'Last Distribution Date',
              explanation: 'date of the last distribution to the noteholders'
            },

          ]}
        />
      </div>
    </>
  );
};

export default Notes;
