import React from 'react';
//Other Components
import { Divider, Tabs } from "antd";
//Components
import LoanWaiver from "./loan_waiver/loan_waiver";
import DebtorWaiver from "./debtor_waiver/debtor_waiver";
import CreateWaiverContent from "../create_waiver_request/create_waiver_content";
//Context
import WaiverProvider from "./calculation_waiver_provider";
import DebtorWaiverProvider from "./debtor_waiver/debtor_waiver_provider";

const TabPane = Tabs.TabPane;

interface Props {
  id: number | string,
  vehicleId: number | string,
  onChangeValue?: any,
  input?: any,
  endDate?: any,
  onChangeEndDate?: any,
  onChangeStartDate?: any,
  startDate?: any,
}

const CalculationExtension = (props: Props) => {
  const { vehicleId, id } = props;

  const [reasonInput, setReasonInput] = React.useState<string>('');
  const [startDateInput, setStartDateInput] = React.useState<any>('');
  const [endDateInput, setEndDateInput] = React.useState<any>('');

  const onChangeReason = (e: any) => {
    setReasonInput(e.target.value);
  }
  const onChangeDateStart = (value: any) => {
    setStartDateInput(value);
  }
  const onChangeDateEnd = (value: any) => {
    setEndDateInput(value);
  }

  return (
    <>
      <CreateWaiverContent
        input={reasonInput}
        startDate={startDateInput}
        endDate={endDateInput}
        onChangeValue={onChangeReason}
        onChangeEndDate={onChangeDateEnd}
        onChangeStartDate={onChangeDateStart}
        useSubmitButton={false}
      />
      <Divider/>
      <Tabs>
        <TabPane
          key="loan_waiver"
          tab="Loan Waiver"
        >
          <WaiverProvider>
            <LoanWaiver
              limitId={id}
              vehicleId={vehicleId}
              reason={reasonInput}
              startDate={startDateInput}
              endDate={endDateInput}
            />
          </WaiverProvider>
        </TabPane>
        <TabPane
          key="debtor_waiver"
          tab="Debtor Waiver"
        >
          <DebtorWaiverProvider>
            <DebtorWaiver
              limitId={id}
              vehicleId={vehicleId}
              reason={reasonInput}
              startDate={startDateInput}
              endDate={endDateInput}
            />
          </DebtorWaiverProvider>
        </TabPane>
      </Tabs>
    </>
  );
};

export default CalculationExtension;
