import React from 'react';
//Custom Components
import { CustomImage } from "../../../../components";
//Components
import { Col, Row } from "antd";
//Configurations
import { HDR_ASSETS_CONFIG as assets } from "../../../../assets";

const HDRDefaultDistribution = () => {

  const description =
    <div>
      Default/ Prepayment Distribution <br/>
      Shows separately the graphs of Default Rate and Prepayment Rate, by month and cumulative. The user has
      the option of filtering the data visualized in the graph by year in order to view closely specific information.
    </div>

  return (
    <Row style={{paddingLeft: '20px'}}>
      <Col sm={22}>
        <CustomImage
          description={description}
          alt="Default Distribution"
          src={assets.images.HDRModule.defaultDistribution}
        />
      </Col>
    </Row>
  );
};

export default HDRDefaultDistribution;