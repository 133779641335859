import React from "react";
//Components
import { Button } from "antd";
//Icons
import { PlusOutlined } from "@ant-design/icons";

export const CreateTopicButton = ({onClick}: any) => {
  return (
    <Button onClick={onClick} icon={<PlusOutlined/>} size="small">Create New</Button>
  )
}