import React, { useEffect, useState } from 'react';
//Custom Components
import Performance from "./components/notes_content/performance";
import ConcentrationContent from "./components/concentration_content";
//Components
import { Skeleton } from "antd";
//Custom Utils
import { formatStatistics, getKey } from "./helpers";
//Context
import { Provider } from "./context";
//Custom Hooks
import { callApi, isEmpty } from "../../helpers";
import { useAuthorization, useGlobalApi, useRedirection } from "../../utils/hooks"

interface Statistics {
  vehicles?: any,
  investor?: any
}

const Dashboard = () => {
  const {sectors, countries} = useGlobalApi();

  const [statistics, setStatistics] = useState<Statistics>({});
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null);

  const {user} = useAuthorization();
  const {rules, setRedirection}: any = useRedirection();

  useEffect(() => {
    callApi({
      url: '/main/investor/investor_details/',
      onSuccess: (response: any) => {
        const formattedData: any = formatStatistics(response);
        setStatistics(formattedData);

        if (!isEmpty(formattedData)) {
          if (rules && rules.selectedVehicle)
            setSelectedVehicle(getKey(rules.selectedVehicle))
          else {
            const vehicles = formattedData.vehicles;
            if (vehicles.length > 0)
              setSelectedVehicle(getKey(vehicles[0].vehicle_id));
          }
        }
      },
    })
  }, []);

  if (!selectedVehicle)
    return <Skeleton active/>

  return (
    <Provider
      sectors={sectors}
      countries={countries}
      statistics={statistics}
      redirectionRule={rules}
      setRedirection={setRedirection}
      selectedVehicle={selectedVehicle}
      setSelectedVehicle={setSelectedVehicle}
      investorName={`${user.first_name} ${user.last_name}`}>
      <ConcentrationContent/>
      <Performance vehicles={statistics.vehicles} vehicleId={selectedVehicle}/>
    </Provider>
  );
};

export default Dashboard;