//Utils
import { withData } from "../../../../utils";
import { generateUrl } from "../../../../helpers";
//Components
import SectorContent from "./chart_content";

export default withData({
  extraParams: ['vehicleId', 'selectedLender'],
  urlFormatter: (config: any) => {
    const {props} = config;
    const {vehicleId, selectedLender} = props;
    let url = generateUrl("/main/loan_distribution/sector_diversification", {
      vehicle_id: vehicleId,
    });
    if (selectedLender)
      url = `${url}&lender=${selectedLender}`

    return url;
  },
})(SectorContent);
