import React from "react";
//Helpers
import { formatters } from "../../../../../../../../../../../helpers";
//Other Libs
import cx from "classnames";

const MatrixCells = ({data, currency}: any) => {
  return (
    <div>
      <div
        className="size12 bolder">Threshold: {formatters.currency({
        value: data.threshold,
        precision: 0,
        currency
      })}</div>
      <div
        className="size12 bolder">Realized: {formatters.currency({value: data.realized, precision: 0, currency})}</div>
      <div className={cx("size12 bolder", {redText: data.available ? data.available <= 0 : false})}>
        Available: {formatters.currency({value: data.available, precision: 0, currency})}
      </div>
    </div>
  );
}
export default MatrixCells;