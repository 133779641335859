import React, { Component } from "react";
//Other Libraries
import styled from "styled-components";
//Icons
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
//Components
import { Button, Checkbox, Col, Row } from "antd";
//Custom Components
import { Operation } from "./index";
import CheckBoxButtonsGroup from "./checkbox_buttons_group";
import SelectWithRemoteSearch from "./select_with_remote_search";
//Constants
import { theme } from "../../../assets/theme/colors";

interface Props {
  visible?: any,
  attr?: any,
  valueConfig?: any,
  ratings?: any,
  config?: any,
  onUpdate: any,
  searchOptions?: any,
  onSearch?: any,
  oneVehicleSelected?: boolean
}

const Wrapper = styled.section`
  .customCheckBox {
    .ant-checkbox {
      margin-right: 8px !important;
    }
  }

  .details {
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 8px;
    margin-top: 8px;
  }
`;

//Custom helpers
function hasContent(filterType: any) {
  return filterType !== "boolOperator";
}

function getDetails(showDetails: any) {
  let config: any = {};
  if (showDetails) {
    config.name = "OK";
    config.icon = <CheckOutlined/>;
  } else {
    config.name = "Modify";
    config.icon = <EditOutlined/>
  }
  return config;
}

const FilterContent = (props: Props) => {
  const {visible, attr, valueConfig, ratings, config, onUpdate, searchOptions, onSearch, oneVehicleSelected} = props;
  const {type, placeholder, options, optionsValueAttr, optionsLabelAttr, mode, searchUrl, searchAttr} = config;

  let content = null;

  if (!visible)
    return content;

  if (type === "checkbox") {
    const params: any = {};

    if (config.hasOwnProperty("customRenderer"))
      params.customRenderer = config.customRenderer;

    if (config.mode === "multiple")
      params.selectedItems = valueConfig.value;
    else
      params.selectedItem = valueConfig.value;

    content = (
      <CheckBoxButtonsGroup
        mode={mode}
        items={options}
        title={placeholder}
        attr={optionsValueAttr}
        labelAttr={optionsLabelAttr}
        updateSelected={onUpdate}
        color={theme.colors.primary} sm={6} lg={8}
        {...params}
      />
    )
  } else if (type === "selectWithSearch") {
    content = (
      <SelectWithRemoteSearch
        mode={mode}
        onSearch={onSearch}
        onChange={onUpdate}
        className="fullWidth"
        searchUrl={searchUrl}
        searchAttr={searchAttr}
        placeholder={placeholder}
        value={valueConfig.value}
        optionLabelAttr={searchAttr}
        searchOptions={searchOptions[attr]}
        {...config}
      />
    )
  } else {
    content = (
      <Operation
        attr={attr}
        ratings={ratings}
        data={valueConfig}
        handleChange={onUpdate}
        type={config.operatorType}
        placeholder={config.placeholder}
        properties={config.operatorProps}
        oneVehicleSelected={oneVehicleSelected}
      />
    )
  }

  return (
    <section className="details">
      {content}
    </section>
  );
};

interface IState {
  showDetails?: boolean
}

interface IProps {
  attr?: any,
  label?: string,
  onUpdate?: () => any,
  handleToggle?: () => any,
  valueConfig?: any,
  config?: any,
  searchOptions?: () => any,
  onSearch?: () => any,
  ratings?: any,
  oneVehicleSelected?: boolean
}

class Filter extends Component<IProps, IState> {


  state = {
    showDetails: false
  };

  toggleDetails = () => {
    this.setState((prevState: any) => ({
      showDetails: !prevState.showDetails
    }))
  };

  handleFilterChange = (event: any) => {
    const {handleToggle, checked}: any = this.props;
    this.setState(({showDetails: !checked}), () => handleToggle(event))
  };

  render() {
    const {showDetails} = this.state;
    const {
      attr,
      label,
      onUpdate,
      valueConfig,
      config,
      searchOptions,
      onSearch,
      ratings,
      oneVehicleSelected
    } = this.props;
    const {type} = config;

    const checked = valueConfig.selected;
    const selected = showDetails && valueConfig.selected;
    const validContent = hasContent(type);
    const visible = validContent && checked && showDetails;
    const details = getDetails(selected);

    return (
      <Wrapper>
        <Row justify="space-between" align="middle" className="mt8">
          <Col>
            <Checkbox value={attr}
                      checked={checked}
                      onChange={this.handleFilterChange}
                      className="customCheckBox">{label}</Checkbox>
          </Col>
          {validContent && <Col>
            <Button type='primary'
                    icon={details.icon}
                    disabled={!checked}
                    onClick={this.toggleDetails}>{details.name}</Button>
          </Col>}
        </Row>
        <FilterContent
          attr={attr}
          config={config}
          visible={visible}
          ratings={ratings}
          onUpdate={onUpdate}
          valueConfig={valueConfig}
          onSearch={onSearch}
          searchOptions={searchOptions}
          oneVehicleSelected={oneVehicleSelected}/>
      </Wrapper>
    )
  }
}

export default Filter;
