import React from 'react';
//Styles
import { Styles } from './styles';

interface LegendScaleProps {
  scale: any
}

const LegendScale = (props: LegendScaleProps) => {

  const {scale} = props;

  return (
    <div className="legendScale">
      {scale.map((item: { color: any; label: any }, index:number) => {

        return (
          <div key={index} style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'start',
          }}>
            <Styles style={{backgroundColor: item.color}}/>
            <div style={{paddingLeft: 6}} className="size12">{item.label}</div>
          </div>
        )
      })}
    </div>
  );
};

export default LegendScale;