import React from "react";
//Components
import { Col, Row, Tooltip } from "antd";
//Configurations
import { shapes } from "../configurations";

interface IRecord {
  type?: any,
  title?: any,
  color?: any,
  scale?: any
}

const Record = (props: IRecord) => {
  const {type, title, color, scale} = props;
  if (!shapes.hasOwnProperty(type))
    return null;

  const Shape: any = shapes[type];

  return (
    <Row justify='center'>
      <Col>
        <Tooltip title={title}>
          <Shape scale={scale} color={color}/>
        </Tooltip>
      </Col>
    </Row>
  );
};


export default Record;