import React from 'react';
//Other Libraries
import clone from "clone";
//Components
import { Checkbox, Col, Divider, Row, Select, Skeleton, Tag } from "antd";
import { Break, FadeIn, SimpleEmpty } from "@cardoai/components";
import InputPicker from "./input_picker";
//Helpers
import { formatPercent } from "../../../../../../../helpers";
import { theme } from "../../../../../../../assets/theme/colors";
import { emptyFieldList, emptyGroupRecord, FORM_FIELD } from "../../../../../config";
import { useAuthorization } from "../../../../../../../utils/hooks";
import { demoRatingDictionary } from "../../../../../../../constants/demo";
//Styles
const Styles = {
  mediumText: {
    color: theme.palette.border[9],
    marginBottom: 12
  },
  button: {
    minWidth: 120
  },
  input: {
    width: 160
  }
};

interface FormProps {
  title?: any,
  count?: any,
  group?: any,
  options?: any,
  performance?: any,
  showTitle?: any,
  fields?: any,
  updateFields?: any,
  checked?: any,
  optionsSelected?: any,
  allOptionsSelected?: any,
  onCheck?: () => any,
  animationDelay?: any,
  onReset?: () => any,
  defaultSelected?: any,
};


const FormRow = (props: FormProps) => {
  let {
    title, count, options, performance, showTitle, fields, updateFields, checked, optionsSelected,
    allOptionsSelected, onCheck, animationDelay, onReset, defaultSelected, group
  } = props;

  const isRatingGroup = group === "Rating";

  const authorization = useAuthorization();

  const commonProps = {
    min: 0,
    max: 100,
    options: options,
    showTitle: showTitle,
    normalizeValue: true,
    type: 'percent'
  }

  const getTitle = (labels: any) => {
    return authorization.isDemoUser() ? labels.demo : labels.default;
  }

  const getValue = (attr: any, withPeriod = false) => {
    if (fields.hasOwnProperty(attr)) {

      if (!withPeriod)
        return fields[attr];

      const periods = fields[attr];

      if (!periods)
        return null;

      const values = Object.values(periods);

      if (!values.length)
        return null;

      const same = values.every(v => v === values[0]);

      return same ? values[0] : null;
    }
  };

  const getPlaceholder = (attr: string, defaultPlaceholder: string) => {
    if (fields.hasOwnProperty(attr)) {

      const periods = fields[attr];

      if (!periods)
        return defaultPlaceholder;

      const values = Object.values(periods);

      if (!values.length)
        return defaultPlaceholder;

      const same = values.every(v => v === values[0]);

      return same ? defaultPlaceholder : "Custom input";
    }
    return defaultPlaceholder;
  }

  const getFields = (attr: string) => {
    if (!fields)
      return {};
    return fields[attr];
  }

  const checkboxParams: any = {};

  if (title === "All") {
    if (defaultSelected) {
      checkboxParams.checked = true;
    } else if (allOptionsSelected) {
      checkboxParams.checked = false;
    } else if (optionsSelected) {
      checkboxParams.onChange = onReset;
      checkboxParams.indeterminate = true;
    } else {
      checkboxParams.checked = true;
    }
  } else {
    checkboxParams.checked = checked;
    checkboxParams.onChange = onCheck;
  }

  if (isRatingGroup && authorization.isDemoUser() && demoRatingDictionary.hasOwnProperty(title))
    title = demoRatingDictionary[title];

  return (
    <FadeIn delay={animationDelay}>
      <Row align="middle" gutter={8} className="mt24">
        <Col xs={4} className="bolder">
          <Checkbox {...checkboxParams}>{title}</Checkbox>
          {!authorization.isDemoUser() && <Row justify="space-between" className="mt8">
              <Col>
                  <Tag color="blue">Loans #: {count}</Tag>
              </Col>
              <Col>
                  <Tag color="blue">Weight: {formatPercent(performance)}</Tag>
              </Col>
          </Row>}
        </Col>
        <Col xs={24}>
          <Row align="middle" justify="space-around" gutter={16} className="mt8">
            <Col>
              <InputPicker
                min={0}
                max={100}
                propagate={false}
                clickable={false}
                normalizeValue={true}
                showTitle={showTitle}
                title="Recovery Rate"
                placeholder="Recovery Rate %"
                type='percent'
                updateFields={updateFields(FORM_FIELD.recovery_rate)}
                value={getValue(FORM_FIELD.recovery_rate, false)}/>
            </Col>
            <Col>
              <InputPicker
                propagate={false}
                clickable={false}
                showTitle={showTitle}
                updateFields={updateFields(FORM_FIELD.recovery_lag)}
                value={getValue(FORM_FIELD.recovery_lag, false)}
                title={getTitle({default: "Recovery Lag", demo: "Time to Recovery"})}
                placeholder={getTitle({default: "Recovery Lag (months)", demo: "Time to Recovery (months)"})}
              />
            </Col>
            <Col>
              <InputPicker
                propagate
                {...commonProps}
                title={getTitle({default: "Delinquency", demo: "Cash Release"})}
                fields={getFields(FORM_FIELD.delinquency)}
                updateFields={updateFields(FORM_FIELD.delinquency)}
                value={getValue(FORM_FIELD.delinquency, true)}
                placeholder={getPlaceholder(FORM_FIELD.delinquency, getTitle({
                  demo: "Cash Release %",
                  default: "New Delinquent %"
                }))}
              />
            </Col>
            <Col>
              <InputPicker
                propagate
                {...commonProps}
                fields={getFields(FORM_FIELD.cdr)}
                updateFields={updateFields(FORM_FIELD.cdr)}
                value={getValue(FORM_FIELD.cdr, true)}
                title={getTitle({default: "Default", demo: "Expenses"})}
                placeholder={getPlaceholder(FORM_FIELD.cdr, getTitle({
                  demo: "Expenses",
                  default: "Default Rate (%)"
                }))}/>
            </Col>
            <Col>
              <InputPicker
                propagate
                {...commonProps}
                fields={getFields(FORM_FIELD.cpr)}
                updateFields={updateFields(FORM_FIELD.cpr)}
                value={getValue(FORM_FIELD.cpr, true)}
                placeholder={getPlaceholder(FORM_FIELD.cpr, getTitle({
                  demo: "Time to Expenses %",
                  default: "CPR %"
                }))}
                title={getTitle({default: "Prepayment", demo: "Time to Expenses"})}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider/>
    </FadeIn>
  );
};

interface Props {
  group?: any,
  options?: any,
  selected?: any,
  handleSelected?: any,
  resetRestSelected?: any,
  records?: any,
  onChange?: any,
  loading?: any,
  selectRecord?: any
}

const Form = (props: Props) => {
  const {group, options, selected, handleSelected, resetRestSelected, records, onChange, loading} = props;

  const authorization = useAuthorization();

  const onSelect = (value: any) => {
    onChange((stateGroup: any) => {
      stateGroup.selection = value;
      stateGroup.records = [
        {
          "label": "All",
          "field_list": emptyFieldList()
        }
      ]
    });
  };

  const onUpdate = (label: string) => (key: string | number) => (fields: any) => {
    handleSelected(label, "add")();

    onChange((stateGroup: any) => {

      const exist = stateGroup.records.find((rec: any) => rec.label === label);

      if (!exist) {
        const emptyGroup = emptyGroupRecord(label);
        stateGroup.records.push(emptyGroup);
      }

      const currentRecord = stateGroup.records.find((rec: any) => rec.label === label);
      //Whole fields
      currentRecord.field_list[key] = fields
    });
  };

  const withStatistics = (data: any) => {
    const [primaryRecord, ...rest] = data;
    const statistics = clone(primaryRecord);
    const keys = ["performance", "loan_count"];

    rest.forEach((rec: any) => {
      const label = rec.label;
      if (selected.includes(label)) {
        keys.forEach(key => {
          if (statistics.hasOwnProperty(key))
            statistics[key] -= rec[key];
        })
      }
    });

    let currentSelected = 0

    rest.forEach((rec: any) => {
      if (selected.includes(rec.label))
        currentSelected++;
    })


    return [
      statistics,
      ...rest
    ]
  }

  if (!group)
    return null;

  if (loading)
    return <Skeleton active/>;

  const option = options.find((option: any) => option.label === group);

  const list = option && option.records;

  if (!list || !list.length)
    return <SimpleEmpty/>;


  const optionsSelected = selected.filter((item: any) => item !== "All").length;

  let allOptionsSelected = optionsSelected === list.length - 1;

  const defaultSelected = group === "All";

  return (
    <>
      <Row align="middle" gutter={16}>
        <Col style={{minWidth: 45}}/>
        <Col>
          Group By
        </Col>
        <Col>
          <Select
            value={group}
            onChange={onSelect}
            style={Styles.input}
            placeholder="Select Group">
            {options && options.map((option: any) => {
              let optionLabel: string = option.label;

              if (authorization.isDemoUser()) {
                switch (optionLabel) {
                  case "Rating":
                    optionLabel = "Collateral Type";
                    break;
                  case "Credit Status":
                    optionLabel = "Legal Procedure";
                    break;
                }
              }

              return (
                <Select.Option key={option.label} value={option.label}>
                  {optionLabel}
                </Select.Option>
              )
            })}
          </Select>
        </Col>
      </Row>
      <Break/>
      <Break/>
      {(withStatistics(list)).map((record, index) => {
        const label = record.label;

        const onCheck = handleSelected(label);
        const checked = selected.includes(label);

        let fields = {};

        const recordDetails = records.find((item: any) => item.label === label);

        if (recordDetails)
          fields = recordDetails.field_list;

        const showTitle = index === 0;

        const animationDelay = index === 0 ? 200 : index * 300;

        return (
          <FormRow
            key={label}
            group={group}
            fields={fields}
            checked={checked}
            onCheck={onCheck}
            title={record.label}
            showTitle={showTitle}
            options={record.period}
            count={record.loan_count}
            onReset={resetRestSelected}
            updateFields={onUpdate(label)}
            animationDelay={animationDelay}
            performance={record.performance}
            optionsSelected={optionsSelected}
            defaultSelected={defaultSelected}
            allOptionsSelected={allOptionsSelected}/>
        )
      })}
    </>
  )
};


export default Form;
