import React from 'react';
//Custom Components
import { CustomImage, MessageBox } from "../../../components";
//Configurations
import { GENERAL_HELP_ASSETS_CONFIG as assets } from "../../../assets";

const AdvancedFiltering = () => {
  return (
    <>
      <div>
        <p>The User can choose one or multiple filters among those available:</p>
        <ul>
          <li>SPV</li>
          <li>Company</li>
          <li>Asset Class</li>
          <li>Performance Status</li>
          <li>Loan Type</li>
          <li>Sector</li>
          <li>Country / Region / City</li>
          <li>Current Balance</li>
          <li>Maturity Date</li>
          <li>Days Delinquent</li>
          <li>Loan Gross return</li>
          <li>Loan Rating</li>
          <li>Pool Addition Date</li>
          <li>Loan Status</li>
          <li>Seller Name</li>
          <li>Seller VAT</li>
          <li>Modified Loan</li>
        </ul>
        <MessageBox
          description="Advanced filters for components like Current Balance, Maturity Date, Days Delinquent, Loan Gross Return,
              Rating, allow the User to select desired ranges using one of the Operators and fill in the relevant values."/><br/>
        <CustomImage
          src={assets.images.loansModule.allFilters}
          alt="Loans Filters Full View"
        />
      </div>
      <MessageBox
        title="Note"
        description="Once back to the Loans Management main page, only loans matching filters criteria are shown and the
          aggregated statistics section will be recalculated, showing  cumulative statistics according to the selected filters."
      />
    </>
  );
};

export default AdvancedFiltering;
