import React from 'react';
//Components
import { Radio } from 'antd';
//Constants
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

interface Props {
  showMax: any,
  onChange: any,
  value: any,
  intervals: any,
  unit: any
}

const Intervals = (props: Props) => {
  const {showMax, onChange, value, intervals} = props;

  const content = intervals?.current.map((interval: any) => (
    <RadioButton key={interval.key} value={interval.key}>
      {interval.label}
    </RadioButton>
  ));

  return (
    <RadioGroup size='small' buttonStyle="solid" value={value} onChange={onChange}>
      {showMax && <RadioButton key="max" value="max">Max</RadioButton>}
      {content}
    </RadioGroup>
  )
};

export default Intervals;
