import React from "react";
//Components
import { Button, Col, Popconfirm, Row } from "antd";
//Configurations
import { STATUS_TYPE } from "../../config";
//Helpers
import { callApi } from "../../../../../../helpers";
import { actionKey } from "../../../../../../constants";
import { useAuthorization } from "../../../../../../utils/hooks";
//Icons
import {
  CalendarOutlined,
  ContainerOutlined,
  DeleteOutlined,
  DeliveredProcedureOutlined,
  FileSearchOutlined
} from "@ant-design/icons";
import ModalContent from "./components/modal_content";


interface ITableActions {
  record?: any,
  vehicleId?: any,
  getData?: any,
  vehicleIdentifier?: any
  vehicle?: any,
  proposalId?: any
}

const SubmitButton = (props: any) => {
  const {displayWarning, enableSubmit, onSubmit} = props;

  if (!displayWarning)
    return <Button size="small" type="primary" disabled={!enableSubmit} onClick={onSubmit}
                   icon={<DeliveredProcedureOutlined/>}>Submit</Button>

  return (
    <Popconfirm disabled={!enableSubmit} okText="Yes" cancelText="No" onConfirm={onSubmit} placement="right"
                title={<div style={{width:400}}>
                  {`${displayWarning} Do you want to submit the proposal anyway?`}
                </div>}>
      <Button size="small" type="primary" disabled={!enableSubmit}
              icon={<DeliveredProcedureOutlined/>}>Submit</Button>
    </Popconfirm>
  )
}

const TableActions = (props: ITableActions) => {
  const {record, vehicleId, getData, vehicleIdentifier, vehicle} = props;

  const {functionalities_config} = vehicle;
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [viewType, setViewType] = React.useState<any>(null);
  const {canPerformAction}: any = useAuthorization();

  const status = record.status;
  const disabledDetails = status === STATUS_TYPE.Processing;
  const disabledDelete = status === STATUS_TYPE.Completed;
  const disabledFundingDate = status !== STATUS_TYPE.Approved || status === STATUS_TYPE.Completed;
  const enableInputTab = status === STATUS_TYPE.Awaiting_User_Input || status === STATUS_TYPE.Awaiting_Submission;
  const enableSubmit = status === STATUS_TYPE.Awaiting_Submission;

  const visibleFunding = canPerformAction(actionKey.fundProposal, vehicleId);
  const visibleDelete = canPerformAction(actionKey.deleteProposal, vehicleId);
  const visibleInputTab = functionalities_config.hasOwnProperty(actionKey.showInputTab) && functionalities_config[actionKey.showInputTab] === true;
  const displayWarning = record.hasOwnProperty("warnings_on_submission") && record.warnings_on_submission;


  const onDelete = () => {
    callApi({
      url: `/main/notes_manager/${record.id}/delete_proposal/?vehicle_id=${vehicleId}`,
      method: "delete",
      onSuccess: () => {
        getData();
      }
    })
  }

  const onSubmit = () => {
    callApi({
      url: `/main/notes_manager/${record.id}/submit/?vehicle_id=${vehicleId}`,
      method: "put",
      onSuccess: () => {
        getData();
      }
    })
  }

  const toggleModalVisibility = (type: string) => () => {
    setViewType(type);
    setVisibleModal(true);
  }

  const hideModal = () => {
    setVisibleModal(false);
  }

  return (
    <>
      <Row justify='center' className="ant-row-no-wrap" gutter={8}>
        <Col>
          <Button size="small" disabled={disabledDetails} onClick={toggleModalVisibility("details")}
                  icon={<FileSearchOutlined/>}>Details</Button>
        </Col>
        {visibleFunding && <Col>
          <Button size="small" disabled={disabledFundingDate} onClick={toggleModalVisibility("funding_date")}
                  icon={<CalendarOutlined/>}>Funding Date</Button>
        </Col>}
        {visibleInputTab && <Col>
          <Button size="small" type="primary" disabled={!enableInputTab} onClick={toggleModalVisibility("input_tab")}
                  icon={<ContainerOutlined/>}>Input Tab</Button>
        </Col>}
        <Col>
          <SubmitButton  displayWarning={displayWarning} onSubmit={onSubmit} enableSubmit={enableSubmit} />
        </Col>
        {visibleDelete && <Col>
          <Popconfirm okText="Yes"
                      cancelText="No" onConfirm={onDelete}
                      title="Are you sure you want to delete this proposal?">
            <Button danger size="small" disabled={disabledDelete}
                    icon={<DeleteOutlined/>}>Delete</Button>
          </Popconfirm>
        </Col>}
      </Row>
      <ModalContent visible={visibleModal} vehicleId={vehicleId} record={record} status={status} getData={getData}
                    viewType={viewType} onCancel={hideModal}
                    vehicle={vehicle} vehicleIdentifier={vehicleIdentifier}/>
    </>
  )
};

export default TableActions;