//Helpers
import chartOptions from "../config";

export const prepareCellContentStyle: any = (centerContent?: boolean, color?: any) => {
  const common: any = {
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    color: chartOptions.labelColor,
    fontSize: chartOptions.labelSize
  };

  if (color)
    common.backgroundColor = color;
  if (centerContent) {
    common.alignItems = 'center';
    common.justifyContent = 'center';
  }
  return common;
};