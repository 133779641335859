import React from 'react';
//Components
import Scenario from "./scenario";
import { SimpleEmpty } from "@cardoai/components";
//Helpers
import { isEmpty } from "../../../../../../helpers";

interface Props {
  data?: any
}

const SimulationResults = (props: Props) => {
  const {data} = props;

  if (!data)
    return null;

  if (isEmpty(data))
    return <SimpleEmpty/>

  return data.map((record: any) => (
    <Scenario
      data={record}
      name={record.name}/>
  ))
}

export default SimulationResults;
