import React from "react";
//Components
import { Col, Row } from "antd";
import AtecoSection from "./ateco_section";
import RatingLimitSection from "./rating_limit_section";

const ATECOAndRating = ({proposalId, vehicleId, urlParam, currency}: any) => {

  return (
    <Row gutter={16}>
      <Col xs={12}>
        <AtecoSection
          currency={currency}
          urlParam={urlParam}
          vehicleId={vehicleId}
          proposalId={proposalId}
        />
      </Col>
      <Col xs={12}>
        <RatingLimitSection
          currency={currency}
          urlParam={urlParam}
          vehicleId={vehicleId}
          proposalId={proposalId}
        />
      </Col>
    </Row>
  )
}
export default ATECOAndRating;